import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const EllipsisIcon = ({ size, className }) => (
  <Svg viewBox="0 0 24 24" size={size} className={className}>
    <path
      d="M4.8 12.4a2.4 2.4 0 11-4.8 0 2.4 2.4 0 014.8 0zM14.4 12.4a2.4 2.4 0 11-4.8 0 2.4 2.4 0 014.8 0zM21.6 14.8a2.4 2.4 0 100-4.8 2.4 2.4 0 000 4.8z"
      fill="currentColor"
    />
  </Svg>
);

EllipsisIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
};

EllipsisIcon.defaultProps = {
  size: 24,
  className: null,
};

import { PermGuard } from 'components/Guards/PermGuard';
import { BarsIcon } from 'components/Icons/BarsIcon';
import { WidgetDivider } from 'components/WidgetDivider/WidgetDivider';
import { WidgetHeader } from 'components/WidgetHeader/WidgetHeader';
import { WidgetItem } from 'components/WidgetItem/WidgetItem';
import { WidgetUser } from 'components/WidgetUser/WidgetUser';
import { Permissions } from 'lib/permissions';
import { resolveUserInitials } from 'lib/resolve-user-initials';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AccountService from 'services/AccountService';
import AdminService from 'services/AdminService';
import { Avatar } from 'components/Avatar';
import { getInitials } from 'lib/generalFunctions';
import { useGlobalStyles } from 'styles';
import { Button, Typography } from '@mui/material';
import { ModalInviteAccountLevelAdmin } from 'smartComponents/ModalInviteAccountLevelAdmin';
import { ModalCreditsPurchasing } from 'smartComponents/ModalCreditsPurchasing';
import { v4 } from 'uuid';
import { useStyles } from './styles';

export const AdminInfoWidget = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const { fname, lname, email } = useSelector((state) => state.auth);
  const history = useHistory();
  const [adminsData, setAdminsData] = useState([]);
  const [accountOrg, setAccountOrg] = useState('');
  const [inviteAdminModalState, setInviteAdminModalState] = useState(false);
  const [creditsPurchasingModal, setCreditsPurchasingModal] = useState(false);

  useEffect(() => {
    const getAdminsDetails = async () => {
      try {
        const responseAdmins = await AdminService.getAdmins({ include: 'roles' }); // , page_size: 3
        setAdminsData(responseAdmins.data.data);
      } catch (err) {
        throw new Error(err);
      }

      const orgAccount = await AccountService.getAccount();
      setAccountOrg(orgAccount.data.data);
    };
    getAdminsDetails();
  }, []);

  const onBuyCredits = async () => setCreditsPurchasingModal(true);

  return (
    <div className={classes.userInfo}>
      <WidgetUser
        userTitle={accountOrg.name ? `${accountOrg.name} Administrator` : `${fname} ${lname}`}
        userSubTitle={`${fname} ${lname}`}
        initials={resolveUserInitials(
          accountOrg ? { firstName: accountOrg.name } : { firstName: fname, lastName: lname, email }
        )}
        border
      />
      <WidgetDivider margin />
      <div className={classes.container}>
        <WidgetItem title="Processing Used" subHeader="" />
        <WidgetItem
          title={(accountOrg.yearlyProcessingLimit - accountOrg.yearlyProcessingUsed).toFixed(2) ?? 0}
          subHeader=""
        />
        <WidgetItem title="Current Total Storage" subHeader="" />
        <WidgetItem title={(accountOrg.storageLimit - accountOrg.storageUsed).toFixed(2) ?? 0} subHeader="" />
        <WidgetItem title="Remaining Seats" subHeader="" />
        <WidgetItem title={(accountOrg.seatLimit - adminsData.length).toFixed(2) ?? 0} subHeader="" />
        <WidgetItem title="Contract Expiration" subHeader="" />
        <WidgetItem
          title={
            new Date(accountOrg.contractDate).toLocaleDateString('en-us', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            }) ?? new Date().toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' })
          }
          subHeader=""
        />
        <WidgetItem title="Remaining Credits" subHeader="" />
        <WidgetItem title={(accountOrg.creditLimit - accountOrg.creditsUsed).toFixed(2) ?? 0} subHeader="" />
        <Button color="secondary" variant="contained" onClick={onBuyCredits} className={classes.purchaseCreds}>
          Purchase Credits
        </Button>
      </div>

      <PermGuard scopes={[Permissions.ACCOUNT_USER_GRANT]}>
        <WidgetDivider margin />
        <WidgetHeader
          title="Your Account Admin Team"
          subTitle=""
          smallText={`These are the people helping you manage ${
            accountOrg.name ? accountOrg.name : `${fname} ${lname}`
          }’s account at the hightest level.`}
          link
          actionIcon={BarsIcon}
          actionTitle="View All"
          actionClick={() => {
            history.push('/admin/admins');
          }}
        />
        <div style={{ marginTop: '3rem', scrollBehavior: 'smooth', overflowY: 'scroll', height: 300 }}>
          {adminsData.length === 0 ? (
            <div className={classes.textContainer}>
              <Typography variant="body">
                You have no Account Admin Team yet.
                <br />
                Why not{' '}
                <Button color="secondary" onClick={() => setInviteAdminModalState(true)}>
                  create one now
                </Button>
                ?
              </Typography>
            </div>
          ) : (
            adminsData.map((person) => (
              <div
                className={globalClasses.nameContainer}
                style={{ marginTop: '10px' }}
                key={`${v4()}${new Date().getMilliseconds()}`}
              >
                <Avatar key={person.id} initials={getInitials(`${person.firstName} ${person.lastName}`)} size={40} />
                <div>
                  <Typography>{`${person.firstName} ${person.lastName}`}</Typography>
                  <Typography className={globalClasses.greyTypography}>{person.roles[0].displayName}</Typography>
                </div>
              </div>
            ))
          )}
        </div>
        {creditsPurchasingModal &&
          (process.env.REACT_APP_HIDE_BILLING === false || process.env.REACT_APP_HIDE_BILLING === 'false') && (
            <ModalCreditsPurchasing
              isOpen={creditsPurchasingModal}
              onConfirm={() => setCreditsPurchasingModal(false)}
              onCancel={() => setCreditsPurchasingModal(false)}
            />
          )}
        {inviteAdminModalState && (
          <ModalInviteAccountLevelAdmin
            isOpen={inviteAdminModalState}
            onConfirm={() => setInviteAdminModalState(false)}
            onCancel={() => setInviteAdminModalState(false)}
          />
        )}
      </PermGuard>
    </div>
  );
};

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '40vh',
    borderRadius: 8,
    overflow: 'hidden',
    '& .loader-component': {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      position: 'absolute',
      zIndex: 101,
      background: theme.palette.modalBackground,
    },
  },
  map: {
    height: '100%',
    width: '100%',
    padding: 0,
    margin: 0,
  },
}));

import React from 'react';
import PropTypes from 'prop-types';

export const TabPanel = ({ value, index, children, className }) =>
  value === index && <div className={className}>{children}</div>;

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  // eslint-disable-next-line react/require-default-props
  className: PropTypes.object,
};

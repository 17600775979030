import { FormInput } from 'components/FormInput';
import { SidebarPanel } from 'components/SidebarPanel';
import { SidebarPanelSection } from 'components/SidebarPanelSection';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSearch } from 'slices/projectContentReducer';

export const SearchPanel = () => {
  const dispatch = useDispatch();
  const search = useSelector((state) => state.projectContent.filters.search || '');
  return (
    <SidebarPanel title="Content Details">
      <SidebarPanelSection title="Search" accordion>
        <FormInput
          placeholder="Enter search term"
          value={search}
          onChange={(e) => {
            dispatch(setSearch(e.target.value));
          }}
        />
      </SidebarPanelSection>
    </SidebarPanel>
  );
};

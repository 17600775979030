import { Avatar } from 'components/Avatar';
import { ContextMenuButton } from 'components/ContextMenuButton';
import { Typography } from 'components/Typography';
import styled from 'styled-components';

export const CardContainer = styled.div`
  background-color: ${(props) => props.theme.card.colors.base};
  border-radius: ${(props) => props.theme.spacing(0.3)};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: none;

  & > div:first-child {
    min-height: 200px;
  }

  &:hover {
    border: 2px solid orange;
  }

  &:active,
  &:checked {
    border: 4px solid orange;
  }

  ${(props) => (props.isSelected ? `border-color: ${props.theme.palette.secondary.base}` : null)};
`;

export const CardImage = styled.img`
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  width: 100%;
  max-height: 200px;
  object-fit: cover;
`;

export const StyledContextMenuButton = styled(ContextMenuButton)`
  position: absolute;
  top: ${(props) => props.theme.spacing(1)};
  right: ${(props) => props.theme.spacing(1)};
  opacity: 70%;
  transform: rotate(90deg);
`;

export const CardContent = styled.div`
  position: relative;
  padding: ${(props) => props.theme.spacing(1)};
`;

export const CardDetails = styled(Typography)`
  color: ${(props) => props.theme.card.colors.text};
  text-transform: capitalize;
`;

export const CardProcessingText = styled(Typography)`
  margin-top: ${(props) => props.theme.spacing(-3)};
  text-align: center;
`;

export const CardFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  margin-top: ${(props) => props.theme.spacing(1)};
`;

export const CardButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  bottom: ${(props) => props.theme.spacing(1)};
  right: ${(props) => props.theme.spacing(1)};
  min-width: 60px;
  border: 1px solid ${(props) => props.theme.card.colors.border};
  padding: ${(props) => props.theme.spacing(0.3)} ${(props) => props.theme.spacing(0.5)};
  margin-left: ${(props) => props.theme.spacing(0.5)};
  border-radius: ${(props) => props.theme.spacing(2)};
`;

export const CardFileContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.card.colors.text};
`;

export const CardTitle = styled(Typography)`
  text-transform: capitalize;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.palette.secondary.base};
    text-decoration: underline;
  }
`;

export const StyledAvatar = styled(Avatar)`
  margin: 0 ${(props) => props.theme.spacing(0.1)};
`;

export const AvatarAdds = styled(Avatar)`
  margin: 0 ${(props) => props.theme.spacing(0.1)};
  background-color: ${(props) => props.theme.palette.success.base};
  border-color: ${(props) => props.theme.palette.success.base};
`;

export const AvatarRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InfoContainer = styled.div`
  background-color: ${(props) => props.theme.palette.primary.disabled};
  opacity: 0.9;
  border: 1px solid ${(props) => props.theme.card.colors.border};
  position: absolute;
  top: ${(props) => props.theme.spacing(0.7)};
  right: ${(props) => props.theme.spacing(1)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  width: ${(props) => props.theme.spacing(1.5)};
  height: ${(props) => props.theme.spacing(1.5)};
  &:hover {
    background-color: ${(props) => props.theme.palette.primary.base};
    border: 1px solid ${(props) => props.theme.palette.secondary.base};
  }
`;

export const FileAttached = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CardHeader = styled.div`
  position: relative;
`;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormRadioGroup } from 'components/FormRadioGroup';
import { SidebarPanel, cleanObjectFilters } from 'components/SidebarPanel';
import { preloadFilterDatasets, setFilters } from 'slices/organizationReducer';
import { FormControl, FormLabel } from '@mui/material';

import { ClearFilterAction } from 'components/SidebarPanel/ClearFilterAction';
import { SearchList } from 'components/SidebarPanel/SearchList/SearchList';

export const SearchPanel = () => {
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.organizations.filters);
  const nameSort = useSelector((state) => state.organizations.filters.sort_by.name || '');
  const dateSort = useSelector((state) => state.organizations.filters.sort_by.createdAt || '');
  const userId = useSelector((state) => state.organizations.filters.userId || '');
  const teamId = useSelector((state) => state.organizations.filters.teamId || '');
  const projectId = useSelector((state) => state.organizations.filters.projectId || '');
  const { users, teams, projects, loaded } = useSelector((state) => state.organizations.panels.search);
  const [filterActions, setFiltersActions] = useState([]);
  const [localFilters, setLocalFilters] = useState({
    sort_by: {},
    userId: '',
    teamId: '',
    projectId: '',
  });

  // clear sort
  const clearSort = (param) => {
    const auxFilters = { ...localFilters };
    delete auxFilters.sort_by[param];
    auxFilters.sort_by = cleanObjectFilters(auxFilters.sort_by);
    setLocalFilters(auxFilters);
  };

  // update filters
  const updateFIlter = (auxFilters) => {
    setLocalFilters({ ...auxFilters });
  };

  // update radio buttons
  const updateNormalFilters = (value, type) => {
    const auxFilters = { ...localFilters };
    auxFilters[type] = value;
    setLocalFilters({ ...auxFilters });
  };

  // -------------- clear filters
  const handleClearFilters = () => {
    const auxFilters = { filters };
    if (cleanObjectFilters(auxFilters)?.filters) {
      dispatch(
        setFilters({
          sort_by: {},
          userId: '',
          teamId: '',
          projectId: '',
          activated: false,
        })
      );
    }
  };

  // -------------- apply filters
  const handleApplyFilters = () => {
    const auxFilters = { ...localFilters };
    auxFilters.sort_by = cleanObjectFilters(auxFilters.sort_by);
    dispatch(setFilters({ ...auxFilters, allOrgByProjectId: true, activated: filterActions.length > 0 }));
  };

  const createClearFilters = (clearFilters) => {
    const auxFilters = [];
    if (clearFilters.sort_by?.name) auxFilters.push({ text: 'Sort By Name', callBack: () => clearSort('name') });
    if (clearFilters.sort_by?.createdAt)
      auxFilters.push({ text: 'Sort By Created At', callBack: () => clearSort('createdAt') });
    if (clearFilters?.userId?.length > 0)
      auxFilters.push({ text: 'Clear By User', callBack: () => updateNormalFilters(null, 'userId') });
    if (clearFilters?.teamId?.length > 0)
      auxFilters.push({ text: 'Clear By Team', callBack: () => updateNormalFilters(null, 'teamId') });
    if (clearFilters?.projectId?.length > 0)
      auxFilters.push({ text: 'Clear By Project', callBack: () => updateNormalFilters(null, 'projectId') });
    setFiltersActions(auxFilters);
    return auxFilters;
  };

  useEffect(() => {
    createClearFilters(localFilters);
  }, [localFilters]);

  useEffect(() => {
    createClearFilters(filters);
    setLocalFilters({
      sort_by: {
        name: nameSort,
        createdAt: dateSort,
      },
      userId,
      teamId,
      projectId,
    });
  }, [filters]);

  useEffect(() => {
    dispatch(preloadFilterDatasets());
  }, []);

  return (
    <SidebarPanel
      title="Filters: Organizations"
      subTitle="Apply filters to table data."
      filters
      handleClearFilters={handleClearFilters}
      handleApplyFilters={handleApplyFilters}
    >
      <div className="searchBody">
        <ClearFilterAction actions={filterActions} />
        <FormControl>
          <FormLabel>Sort</FormLabel>
          <FormRadioGroup
            name="name"
            value={localFilters.sort_by.name}
            onChecked={(checkedVal) => {
              const sortBy = { ...localFilters.sort_by };
              sortBy.name = checkedVal;
              updateNormalFilters(sortBy, 'sort_by');
            }}
            options={[
              { label: 'A - Z', value: '+name', disabled: false },
              { label: 'Z - A', value: '-name', disabled: false },
              { label: 'None', value: '', disabled: false },
            ]}
            view="horizontal"
          />
          <FormRadioGroup
            name="createdAt"
            value={localFilters.sort_by.createdAt}
            onChecked={(checkedVal) => {
              const sortBy = { ...localFilters.sort_by };
              sortBy.createdAt = checkedVal;
              updateNormalFilters(sortBy, 'sort_by');
            }}
            options={[
              { label: 'Newest', value: '-createdAt', disabled: false },
              { label: 'Oldest', value: '+createdAt', disabled: false },
              { label: 'None', value: '', disabled: false },
            ]}
            view="horizontal"
          />
        </FormControl>
        <SearchList
          title="Membership"
          fullList={users}
          filter="userId"
          localFilters={localFilters}
          onChangeSelect={updateFIlter}
          loaded={loaded}
        />
        <SearchList
          title="Team"
          fullList={teams}
          filter="teamId"
          localFilters={localFilters}
          onChangeSelect={updateFIlter}
          loaded={loaded}
        />
        <SearchList
          title="Project"
          fullList={projects}
          filter="projectId"
          localFilters={localFilters}
          onChangeSelect={updateFIlter}
          loaded={loaded}
        />
      </div>
    </SidebarPanel>
  );
};

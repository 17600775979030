/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form } from 'lib/form';
import { ModalHeaderIcon } from 'components/ModalHeaderIcon/ModalHeaderIcon';
import { Button, Dialog, DialogActions, DialogContent, Grid, InputLabel, TextField, Typography } from '@mui/material';
import ExternalService from 'services/ExternalService';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import { DialogMessage } from 'components/DialogMessage';
import ProjectService from 'services/ProjectService';
import {
  roles as UserProjectRoles,
  UserProjectRoleOptions,
  validateOptions as validateUserProjectOptions,
} from 'components/UserProjectRoleOptions/UserProjectRoleOptions';
import {
  roles as UserTeamRoles,
  UserTeamRoleOptions,
  validateOptions as validateUserTeamOptions,
} from 'components/UserTeamRoleOptions/UserTeamRoleOptions';
import TeamService from 'services/TeamService';
import { addExternalType, externalDataType, externalGrants } from 'lib/external-data';
import { CheckCircleBroken } from 'components/Icons';

const validationSchema = Yup.object({
  uuid: Yup.string().min(1).required('UUID is required'),
});

export const AddUuid = ({ type, handleClose, variant, item }) => {
  const [data, setData] = useState({ id: null, name: '', account: '' });
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [checkBoxValidation, setCheckBoxValidation] = useState(false);

  // --------------------------------- send data to API
  const submitFormData = async (values) => {
    // get get type of external data from API
    const auxType =
      variant === addExternalType.ADD_USER_TO_PROJ || variant === addExternalType.ADD_USER_TO_TEAM
        ? externalDataType.USER
        : variant === addExternalType.ADD_PROJ_TO_TEAM
        ? externalDataType.PROJ
        : variant === addExternalType.ADD_TEAM_TO_PROJ
        ? externalDataType.TEAM
        : type;
    if (data?.id) {
      if (variant || data.existent) {
        // ------------- add existent external user to project
        const validation =
          variant === addExternalType.ADD_USER_TO_TEAM
            ? validateUserTeamOptions(values)
            : variant === addExternalType.ADD_USER_TO_PROJ
            ? validateUserProjectOptions(values)
            : false;
        if (validation) {
          setCheckBoxValidation(true);
          return;
        }
      }
      setIsLoading(true);
      try {
        if (!variant || !data.existent) {
          // json to validate if is adding an object
          const body = {
            id: values.uuid,
            ...((variant === addExternalType.ADD_USER_TO_PROJ ||
              variant === addExternalType.ADD_TEAM_TO_PROJ ||
              variant === addExternalType.ADD_PROJ_TO_TEAM) && {
              objectId: item.id,
              type: externalGrants.PROJ,
            }),
            ...(variant === addExternalType.ADD_USER_TO_TEAM && {
              objectId: item.id,
              type: externalGrants.TEAM,
            }),
          };
          // add new external data to API
          await ExternalService.postExternalData(body, auxType);
        }
        // adding relations between users, teams and project to API
        if (variant === addExternalType.ADD_USER_TO_PROJ) {
          setIsLoading(true);
          await ProjectService.updateUserOrTeamRoles(item.id, data.id, UserProjectRoles(values));
        }
        if (variant === addExternalType.ADD_USER_TO_TEAM) {
          setIsLoading(true);
          await TeamService.addTeamMember(item.id, data.id, UserTeamRoles(values));
        }
        if (variant === addExternalType.ADD_PROJ_TO_TEAM || variant === addExternalType.ADD_TEAM_TO_PROJ) {
          setIsLoading(true);
          await ProjectService.updateUserOrTeamRoles(
            variant === addExternalType.ADD_TEAM_TO_PROJ ? item.id : data.id,
            variant === addExternalType.ADD_TEAM_TO_PROJ ? data.id : item.id,
            [13, 14, 15]
          );
        }

        handleClose(!variant);
      } catch (error) {
        setIsLoading(false);
        if (variant === addExternalType.ADD_USER_TO_TEAM && error?.response?.status === 400) {
          setOpenDialog(
            error?.response?.data?.message
              ? error?.response?.data?.message
              : 'An error occurred while trying to edit the user.'
          );
        } else {
          setOpenDialog(`An error occurred while trying to add the ${auxType}.`);
        }
      }
    } else {
      setIsLoading(true);
      try {
        // json to validate if is adding an object
        const body = {
          ...((variant === addExternalType.ADD_USER_TO_PROJ ||
            variant === addExternalType.ADD_TEAM_TO_PROJ ||
            variant === addExternalType.ADD_PROJ_TO_TEAM) && {
            objectId: variant === addExternalType.ADD_PROJ_TO_TEAM ? values.uuid : item.id,
            type: externalGrants.PROJ,
          }),
          ...(variant === addExternalType.ADD_USER_TO_TEAM && {
            objectId: item.id,
            type: externalGrants.TEAM,
          }),
        };
        // get info from API
        const result = await ExternalService.getExternalData(values.uuid, auxType, body);
        const auxExternalData = result.data.data;
        let auxData = {};
        if (auxExternalData[auxType === externalDataType.ASSET ? 'projectAsset' : auxType]?.id) {
          const apiData = auxExternalData[auxType === externalDataType.ASSET ? 'projectAsset' : auxType];
          // get existent
          auxData.id = apiData.id;
          auxData.name = auxType === externalDataType.USER ? `${apiData.firstName} ${apiData.lastName}` : apiData.name;
          if (variant) {
            auxData.account =
              auxType === externalDataType.USER
                ? apiData.accounts[0]
                : auxType === externalDataType.ASSET
                ? apiData.project.account
                : apiData.account;
            setData({ ...auxData, existent: true });
          } else {
            setOpenDialog(`You already added the ${auxType} ${auxData.name}.`);
          }
        } else {
          auxData = {
            name:
              auxType === externalDataType.USER
                ? `${auxExternalData?.firstName} ${auxExternalData?.lastName}`
                : auxExternalData.name,
            id: auxExternalData.id,
            account: auxType === externalDataType.ASSET ? auxExternalData.project?.account : auxExternalData.account,
          };
          // get new
          setData(auxData);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setOpenDialog(`An error occurred while trying to get the ${auxType} data.`);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      uuid: '',
      contributor: false,
      collaborator: false,
      viewOnly: false,
      admin: false,
      coordinator: false,
      participant: false,
      role: 0,
    },
    validationSchema,
    onSubmit(values) {
      submitFormData(values);
    },
  });

  const { values, handleChange, handleBlur, handleSubmit } = formik;

  return (
    <Dialog open onClose={() => handleClose(false)} maxWidth="xs">
      <DialogContent>
        <ModalHeaderIcon icon={CheckCircleBroken} text="Add via UUID" />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography mt={1}>
              {!data?.id
                ? `Please paste the ${item?.textDescription || type} UUID emailed to you here to view its information.`
                : `You have been granted access to ${data.name} from ${
                    data.account?.name || 'no has account'
                  }. To utilize ${data.name}, please click on "Confirm".`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>UUID *</InputLabel>
            <TextField
              variant="outlined"
              id="uuid"
              name="uuid"
              value={values.uuid}
              {...Form.fieldErrorHelper(formik, 'uuid')}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={data?.id}
              error={formik.touched.uuid && formik.errors.uuid}
              helperText={formik.touched.uuid && formik.errors.uuid ? formik.errors.uuid : null}
              required
              fullWidth
            />
            {variant === addExternalType.ADD_USER_TO_PROJ && data?.id && (
              <UserProjectRoleOptions
                formik={formik}
                checkBoxValidation={checkBoxValidation}
                handleChange={(label, value) => formik.handleChange(label)(value)}
              />
            )}
            {variant === addExternalType.ADD_USER_TO_TEAM && data?.id && (
              <UserTeamRoleOptions
                formik={formik}
                checkBoxValidation={checkBoxValidation}
                handleChange={(label, value) => formik.handleChange(label)(value)}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button color="primary" variant="contained" onClick={() => handleClose(false)} size="large" fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button color="secondary" variant="contained" onClick={handleSubmit} size="large" fullWidth>
              {!data?.id ? 'Get Information' : 'Confirm'}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
      <DialogMessage
        title={openDialog}
        isOpen={openDialog}
        confirmText="Ok"
        onConfirm={() => {
          setOpenDialog(false);
        }}
      />
      <LoadingOverlay loading={isLoading} />
    </Dialog>
  );
};

AddUuid.propTypes = {
  type: PropTypes.string,
  handleClose: PropTypes.func,
  variant: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.any,
};

AddUuid.defaultProps = {
  type: '',
  handleClose: () => {},
  variant: '',
  item: null,
};

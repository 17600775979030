import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const ArrowDownIcon = ({ size, className, onClick }) => (
  <Svg viewBox="0 0 24 24" size={size} className={className} onClick={onClick}>
    <path
      d="M23.293 12.707a1 1 0 0 0 0-1.414l-.586-.586a1 1 0 0 0-1.414 0L13.5 18.5V1a1 1 0 0 0-1-1h-1a1 1 0 0 0-1 1v17.5l-7.793-7.793a1 1 0 0 0-1.414 0l-.586.586a1 1 0 0 0 0 1.414l10.586 10.586a1 1 0 0 0 1.414 0l10.586-10.586Z"
      fill="currentColor"
    />
  </Svg>
);

ArrowDownIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

ArrowDownIcon.defaultProps = {
  size: 24,
  className: '',
  onClick: () => {},
};

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
// import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Time } from 'lib/time';
import { Avatar } from 'components/Avatar/Avatar';
import { BarsIcon, ClockIcon } from 'components/Icons';
import { WidgetContent } from 'components/WidgetContent/WidgetContent';
import { WidgetHeader } from 'components/WidgetHeader/WidgetHeader';
import { resolveUserInitials } from 'lib/resolve-user-initials';
import AssetService from 'services/AssetService';
import { useGlobalStyles } from 'styles';
import { Typography } from '@mui/material';
import { useStyles as useAdminStyles } from '../../Admin/Dashboard/styles';

export const LastUploadsWidget = () => {
  const adminClasses = useAdminStyles();
  const globalClasses = useGlobalStyles();
  const params = useParams();
  // const history = useHistory();
  const [teamContentData, setTeamContentData] = useState([]);
  // const navigateToTeamProjects = () => {
  //   history.push(`/teams/${params.teamId}/teams`);
  // };

  useEffect(() => {
    const getTeamDetails = async () => {
      const responseTeam = await AssetService.getAssets({
        teamId: params.teamId,
        include: ['uploader'],
        sort_by: '-createdAt',
        page_size: 7,
      });
      setTeamContentData(responseTeam.data.data);
    };
    getTeamDetails();
  }, []);

  return (
    <div className={adminClasses.generalContent}>
      <WidgetHeader
        title="Latest Uploads"
        subTitle="Recent uploads from your team."
        actionIcon={BarsIcon}
        actionTitle="View All"
        border
        link
      />
      <WidgetContent>
        {teamContentData &&
          teamContentData.map((file) => (
            <div className={globalClasses.nameContainer} style={{ marginTop: '10px' }}>
              <div className={globalClasses.nameContainer}>
                <ClockIcon />
                <div className={globalClasses.nameContainer}>
                  <div>
                    <Typography>
                      {file.type === 'VIDEO' ? Time.humanReadable({ seconds: file.timeLength }) : file.type}
                    </Typography>
                    <Typography className={globalClasses.greyTypography}>
                      {moment(file.date).format('MM/DD/YYYY')}
                    </Typography>
                  </div>
                  <Avatar
                    image={file?.user?.photo ? file?.user?.photo : null}
                    initials={resolveUserInitials({ firstName: file?.user?.firstName, lastName: file?.user?.lastName })}
                    size={40}
                  />
                </div>
              </div>
              <div className={adminClasses.shortText}>
                <Typography>{file.name}</Typography>
                {file?.user && (
                  <Typography
                    className={globalClasses.greyTypography}
                  >{`${file?.user?.firstName} ${file?.user?.lastName}`}</Typography>
                )}
              </div>
            </div>
          ))}
      </WidgetContent>
    </div>
  );
};

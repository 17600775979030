import { Button } from 'components/Button/Button';
import styled from 'styled-components';

import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  dashContent: {
    height: '100vh',
    overflow: 'auto',
  },
  userInfo: {
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    padding: theme.spacing(1),
    borderRadius: '8px',
    background: theme.palette.primary[1400],
    height: 'calc(100% - 2rem)',
  },
  purchaseCreds: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    justifyItems: 'center',
    '& > div': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      // paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      '&:nth-child(even)': {
        textAlign: 'right',
      },
    },
  },
  imgTitle: {
    background: theme.palette.primary[900],
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    padding: theme.spacing(1),
    borderRadius: '8px',
  },
  generalContent: {
    margin: theme.spacing(0.5),
    padding: theme.spacing(1),
    borderRadius: '8px',
    border: `1px solid ${theme.palette.primary.light}`,
  },
  fullHeight: {
    height: 'calc(100% - 2rem)',
  },
  flexCards: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  actionButtonContent: {
    backgroundColor: theme.palette.primary[1300],
    border: `1px solid ${theme.palette.primary[800]}`,
    width: '100%',
    padding: theme.spacing(0.7),
    borderRadius: 8,
    marginBottom: theme.spacing(0.1),
    height: '100%',
  },
  actionButtonTextContent: {
    borderBottom: `1px solid ${theme.palette.primary[400]}`,
  },
  actionButtonFooter: {
    marginTop: theme.spacing(0.3),
    textAlign: 'right',
  },
  orgSection: {
    '& .MuiTypography-root': {
      color: 'white',
    },
    '& .text': {
      fontWeight: 400,
    },
  },
  shortText: {
    overflow: 'hidden',
    // whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});

export const UploadFilesOuterContainer = styled.div`
  border: 1px solid #fff;
  border-radius: 10px;
  background-color: #515151;
  width: 90%;
  left: 5%;
  right: 5%;
  margin-top: 50px;
  height: 30%;
  position: relative;
  display: block;
  padding: 50px;
`;

export const UploadAreaContainer = styled.div`
  background-color: orange;
  border: 1;
  border-style: none;
  border-radius: 10px;
  top: 30%;
  position: relative;
  left: 0%;
  display: inline-block;
`;

export const AddNew = styled(Button)`
  position: fixed;
  right: 20px;
  top: 70px;
  z-index: 200;
`;

export const StyledPlus = styled.div`
  border: 0px;
  border-radius: 50px;
  border-color: white;
  border-style: solid;
  font-size: 20px;
  padding-right: 5px;
  display: inline-block;
`;

export const StyledPlusText = styled.div`
  font-size: 18px;
  font-weight: 300;
  display: inline-block;
`;

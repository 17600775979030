import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  addContentContainer: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  filters: {
    display: 'flex',
    marginTop: theme.spacing(0.5),
    alignItems: 'center',
  },
  input: {
    width: 'auto!important',
    flex: 1,
  },
  filterButton: {
    marginLeft: `${theme.spacing(0.5)}!important`,
    height: 40,
    '& svg': {
      marginRight: `${theme.spacing(0.2)}!important`,
    },
  },
  button: {
    // height: '40px',
    // width: '44px',
    padding: '8px 14px',
    '& svg': {
      marginRight: '5px',
    },
  },
  activatedFilter: {
    color: theme.palette.secondary[500],
    border: `1px solid ${theme.palette.secondary[500]}`,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
    paddingTop: theme.spacing(0.5),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  assetsTable: {
    height: '100%',
  },
  titleContent: {
    display: 'flex',
    gap: 10,
    alignItems: 'center',
  },
  copyShareButtons: {
    display: 'flex',
    gap: '5px',
  },
  tableIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: '23px',
    height: '23px',
  },
  succesIcon: {
    color: theme.palette.success[1100],
    backgroundColor: theme.palette.success[500],
  },
  errorIcon: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.danger[600],
  },
  transcodingIcon: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.warning[400],
  },
  archivedIcon: {
    color: theme.palette.primary.main,
    backgroundColor: '#53b1fd',
  },
  assetDetail: {
    padding: `${theme.spacing(0.5)} 0`,
    '& .MuiTabs-root': {
      width: '100%',
    },
    '& .MuiTabs-flexContainer': {
      gap: '8px',
    },
    '& .MuiTab-root': {
      borderRight: 'none',
      padding: '8px 12px!important',
      '&.Mui-selected': {
        background: `var(--gray-800, ${theme.palette.primary[1200]})`,
        borderRadius: '6px',
      },
    },
  },
  assetBodyDetail: {
    padding: theme.spacing(0.5),
  },
  fullScreen: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderLeft: `1px solid ${theme.palette.secondary[700]}`,
    zIndex: 800,
    transform: `translateX(110%)`,
    transition: 'transform 0.3s ease-out',
    background: `var(--gray-800, ${theme.palette.primary[1200]})`,
    '& .mapControls': {
      backgroundColor: theme.palette.primary[1400],
    },
    '& .mapDetailContainer': {
      height: '60vh!important',
    },
  },
  openFullScreen: {
    padding: `0 ${theme.spacing(0.5)}`,
    transform: `translateX(0%)`,
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '100%',
  },
  openMapFullScreen: {
    height: '60vh!important',
  },
  fullScreenBtn: {
    padding: '5px!important',
  },
}));

import React, { useContext, useEffect } from 'react';
import { SidebarContext } from 'components/SidebarProvider/SidebarProvider';
import { useDispatch, useSelector } from 'react-redux';
import { preloadFilterDatasets, setFilterValue, setFilters } from 'slices/adminTeamsReducer';
import { preloadExternalFilterDatasets, setExternalFilterValue, setExternalFilters } from 'slices/externalTeamReducer';
import { SearchPanelBody, searchPanelSection } from './SearchPanelBody';

export const SearchPanel = () => {
  const dispatch = useDispatch();
  const ctx = useContext(SidebarContext);
  const external = ctx?.config?.search?.external;
  const reduce = ctx?.config?.search?.external ? 'externalTeams' : 'adminTeams';
  const nameSort = useSelector((state) => state[reduce].filters.sort_by.name || '');
  const dateSort = useSelector((state) => state[reduce].filters.sort_by.createdAt || '');
  const orgSort = useSelector((state) => state[reduce].filters.sort_by.org || '');
  const accountSort = useSelector((state) => state[reduce].filters.sort_by.account || '');
  const acctId = useSelector((state) => state[reduce].filters.acctId || '');
  const organizationId = useSelector((state) => state[reduce].filters.organizationId || '');
  const userRole = useSelector((state) => state[reduce].filters.userRole || '');
  const userId = useSelector((state) => state[reduce].filters.userId || '');
  const projectId = useSelector((state) => state[reduce].filters.projectId || '');
  const { users, accounts, organizations, projects, loaded } = useSelector((state) => state[reduce].panels.search);

  useEffect(() => {
    dispatch(external ? preloadExternalFilterDatasets() : preloadFilterDatasets());
  }, []);

  return (
    <SearchPanelBody
      loaded={loaded}
      users={users}
      projects={projects}
      accounts={accounts}
      organizations={organizations}
      filters={{
        sort_by: {
          name: nameSort,
          createdAt: dateSort,
          org: orgSort,
          account: accountSort,
        },
        acctId,
        organizationId,
        userRole,
        userId,
        projectId,
      }}
      setFilterValue={external ? setExternalFilterValue : setFilterValue}
      setFilters={external ? setExternalFilters : setFilters}
      section={external ? searchPanelSection.EXTERNALTEAMS : searchPanelSection.ADMINTEAMS}
    />
  );
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InspectionService from 'services/InspectionService';
import { DialogMessage, dialogMessageAction } from 'components/DialogMessage';
import { InfoIcon } from 'components/Icons';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import { useDispatch, useSelector } from 'react-redux';
import { getInspections } from 'slices/inspectionReducer';
import { getProjectInspections } from 'slices/projectInspectionReducer';
import { inspectionType } from '../helpers';

export const DeleteInspection = ({ inspection, actionType, handleClose }) => {
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { filters } = useSelector((state) => state.inspections);
  const { filters: projectFilters } = useSelector((state) => state.projectInspections);

  const deleteInspection = async () => {
    setIsLoading(true);
    try {
      await InspectionService.deleteInspection(
        inspection.id,
        inspectionType.PROJECT ? { projectId: inspection.projectId } : {}
      );
      setIsLoading(false);
      handleClose(true);
      // reload inspections after delete one
      if (actionType === inspectionType.PROJECT) {
        dispatch(
          getProjectInspections({ ...projectFilters, projectId: inspection.projectId, include: ['uploader', 'type'] })
        );
      } else {
        dispatch(getInspections(filters));
      }
    } catch (error) {
      setIsLoading(false);
      setOpenDialog('An error occurred while trying to delete the inspection template.');
    }
  };

  return (
    <>
      <DialogMessage
        title={`Delete ${inspection.name}`}
        content={`Are you sure you want to delete this ${inspection.name}? This action cannot be undone`}
        isOpen
        onConfirm={deleteInspection}
        onCancel={handleClose}
        action={dialogMessageAction.delete}
      />
      <DialogMessage
        title={openDialog}
        isOpen={openDialog}
        icon={InfoIcon}
        confirmText="Ok"
        onConfirm={() => {
          setOpenDialog(false);
        }}
      />
      <LoadingOverlay loading={isLoading} />
    </>
  );
};

DeleteInspection.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  inspection: PropTypes.object,
  actionType: PropTypes.string,
  handleClose: PropTypes.func,
};

DeleteInspection.defaultProps = {
  inspection: { id: null, name: '' },
  handleClose: () => {},
  actionType: '',
};

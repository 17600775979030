import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { DialogMessage } from 'components/DialogMessage';
import { InfoIcon } from 'components/Icons';
import { Grants, Permissions } from 'lib/permissions';
import { StringRoles } from 'lib/roles';
import { PermissionsValidator, getPermissionType } from 'lib/permValidator';

export const ViewRoleValidation = ({ action, data, callBack, handleClose }) => {
  const auth = useSelector((state) => state.auth);
  const [openDialog, setOpenDialog] = useState(false);
  const hasPermissions = PermissionsValidator.validate({
    itemId: data?.id,
    scopes: action.length > 0 ? action.filter((item) => item !== Permissions.PROJ_MODIFY_CONTENT_SHARE_COPY) : [],
  });

  const close = () => {
    handleClose();
    setOpenDialog(false);
  };

  const allowAction = (flag) => {
    callBack(flag);
    close();
  };

  const copyShareValidation = () => {
    if (action.find((item) => item === Permissions.PROJ_MODIFY_CONTENT_SHARE_COPY)) {
      const share = action.find((item) => item === Permissions.PROJ_SHARE);
      const copy = action.find((item) => item === Permissions.PROJ_COPY);
      if ((data.isSharingNotAllowed && share) || (data.isCopyingNotAllowed && copy)) {
        setOpenDialog(
          copy
            ? 'This project does not allow copying. The project administrator must change the setting.'
            : 'This project does not allow sharing. The project administrator must change the setting.'
        );
        return false;
      }
    }
    return true;
  };

  // ------------------------ validate only view role and roles
  useEffect(() => {
    const { permissions } = auth;
    // validate user has permission
    if (hasPermissions) {
      if (!copyShareValidation()) return;
      allowAction();
      return;
    }
    const dataType = getPermissionType(action[0]);
    // validate users as project contributor can edit the asset that they uploaded
    if (
      Grants.PROJ === dataType &&
      permissions[dataType] &&
      data?.id &&
      permissions[dataType][data.id] &&
      permissions[dataType][data.id].role === StringRoles.PROJ_CNTRB &&
      (action.find((item) => item === Permissions.PROJ_MODIFY_CONTENT) ||
        action.find((item) => item === Permissions.PROJ_DELETE_CONTENT))
    ) {
      const findUploader = data?.asset?.uploaders?.find((item) => item.id === auth.userId);
      if (findUploader?.id) {
        if (!copyShareValidation()) return;
        allowAction();
        return;
      }
    }
    // validate is open project
    if (
      Grants.PROJ === dataType &&
      data.isOpen &&
      !(Object.keys(auth.permissions.orgs[data?.orgId]).length < 2 && auth.viewOnlyRole) &&
      (action.find((item) => item === Permissions.PROJ_MODIFY_CONTENT_SHARE_COPY) ||
        action.find((item) => item === Permissions.PROJ_COPY_UUID))
    ) {
      if (!copyShareValidation()) return;
      allowAction();
      return;
    }
    // validate add user by themself in project
    if (
      Grants.PROJ === dataType &&
      data?.isOpen &&
      data?.isVisible &&
      action.find((item) => item === Permissions.PROJ_USER_MODIFY)
    ) {
      allowAction(true);
      return;
    }
    // validate general permission
    if (!data && !action && !(Object.keys(auth.permissions.orgs[data?.orgId]).length < 2 && auth.viewOnlyRole)) {
      allowAction();
      return;
    }
    // validate if admins want to delete a project that dont have permission
    if (
      Grants.PROJ === dataType &&
      permissions[dataType] &&
      data?.currentProject &&
      permissions[dataType][data.currentProject] &&
      (permissions[dataType][data.currentProject].role === StringRoles.PROJ_ADMIN ||
        permissions[dataType][data.currentProject].role === StringRoles.PROJ_CONTENT_COORD ||
        permissions[dataType][data.currentProject].role === StringRoles.PROJ_PART_COORD) &&
      action.find((item) => item === Permissions.PROJ_DELETE_CONTENT)
    ) {
      setOpenDialog(
        "This is a shared piece of content from another project. You do not have authorization to delete it but you can 'Remove' the asset from your project."
      );
      return;
    }
    setOpenDialog('You do not have the appropriate permissions for this action');
  }, []);

  return (
    <DialogMessage title={openDialog} isOpen={openDialog} icon={InfoIcon} confirmText="Ok" onConfirm={() => close()} />
  );
};

ViewRoleValidation.propTypes = {
  action: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
  callBack: PropTypes.func,
  handleClose: PropTypes.func,
};

ViewRoleValidation.defaultProps = {
  action: [],
  data: null,
  callBack: () => {},
  handleClose: () => {},
};

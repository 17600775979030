import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { If } from 'components/If';
import { Loader } from 'components/Loader';
import { Button, IconButton, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { SidebarDetailContext } from 'components/SidebarProviderDetail/SidebarProviderDetail';
import { SidebarContext } from '../SidebarProvider/SidebarProvider';
import { useStyles } from './styles';

export const SidebarPanel = ({
  title,
  subTitle,
  loading,
  children,
  header,
  filters,
  detail,
  noHeader,
  cancelButton,
  handleClearFilters,
  handleApplyFilters,
}) => {
  // eslint-disable-next-line no-unused-vars
  const { setOpen, open, handleClose, config, activePanel, setActivePanel } = useContext(
    detail ? SidebarDetailContext : SidebarContext
  );
  const classes = useStyles();

  return (
    <div className={classes.panelContainer}>
      {!noHeader && (
        <div className={classes.panelHeader}>
          {header || (
            <>
              <div>
                <Typography variant="h3">{title}</Typography>
                <Typography className={classes.subTitle}>{subTitle}</Typography>
              </div>
              <IconButton
                className={classes.closeButton}
                onClick={() => {
                  setOpen(!open);
                  handleClose();
                }}
              >
                <FontAwesomeIcon icon={faXmark} size="lg" color="white" />
              </IconButton>
            </>
          )}
        </div>
      )}
      <div className={classes.panelBody}>
        <If cond={loading}>
          <div className={classes.panelLoaderContainer}>
            <Loader height={50} loading />
          </div>
        </If>
        <If cond={!loading}>{children}</If>
      </div>
      {filters && (
        <div className={classes.footer}>
          <Button
            color="primary"
            className={classes.clearButton}
            onClick={() => {
              setOpen(!open);
              handleClose();
              handleClearFilters();
            }}
          >
            Clear All Filters
          </Button>
          <div className={classes.buttons}>
            {cancelButton && (
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setOpen(!open);
                  handleClose();
                }}
              >
                Cancel
              </Button>
            )}
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                setOpen(!open);
                handleClose();
                handleApplyFilters();
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export const cleanObjectFilters = (obj) => {
  const cleaned = {};
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const key in obj) {
    const value = obj[key];
    if (value !== null && value !== '') {
      if (typeof value === 'object' && !Array.isArray(value)) {
        const cleanedValue = cleanObjectFilters(value);
        if (Object.keys(cleanedValue).length > 0) {
          cleaned[key] = cleanedValue;
        }
      } else {
        cleaned[key] = value;
      }
    }
  }
  return cleaned;
};

SidebarPanel.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.node,
  header: PropTypes.node,
  filters: PropTypes.bool,
  detail: PropTypes.bool,
  noHeader: PropTypes.bool,
  cancelButton: PropTypes.bool,
  handleClearFilters: PropTypes.func,
  handleApplyFilters: PropTypes.func,
};

SidebarPanel.defaultProps = {
  title: '',
  subTitle: '',
  loading: false,
  children: null,
  header: null,
  filters: false,
  detail: false,
  noHeader: false,
  cancelButton: true,
  handleClearFilters: () => {},
  handleApplyFilters: () => {},
};

import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'components/Card';
import { useStyles } from './styles';

export const Widget = ({ children }) => {
  const classes = useStyles();

  return (
    <Card noHeight>
      <div className={classes.styledWidget}>{children}</div>
    </Card>
  );
};

Widget.propTypes = {
  children: PropTypes.node.isRequired,
};

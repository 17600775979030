import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { ModalEditProject } from 'smartComponents/ModalEditProject';
import { ProjectVisibility } from './ProjectVisibility/ProjectVisibility';
import { ProjectSharing } from './ProjectSharing/ProjectSharing';
import { ProjectRetention } from './ProjectRetention/ProjectRetention';
import { useStyles } from './styles';

export const ProjectSettings = ({ project }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={0} className={classes.content}>
      <Grid item lg={4} md={4} sm={12} xs={12} className={classes.leftColumn}>
        <ProjectVisibility project={project} />
      </Grid>
      <Grid item lg={8} md={8} sm={12} xs={12} className={classes.rightColumn}>
        <Grid container spacing={1}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <ProjectSharing project={project} />
            <ProjectRetention project={project} />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <ModalEditProject project={project} detail />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ProjectSettings.propTypes = {
  project: PropTypes.object,
};

ProjectSettings.defaultProps = {
  project: { id: null, name: '' },
};

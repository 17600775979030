import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import InspectionService from 'services/InspectionService';

export const getFindings = createAsyncThunk('findings/getFindings', async (filters) => {
  const newFilters = {
    strict: true,
    include: ['inspection', 'point', 'projectAsset', 'uploader'],
    ...filters,
  };
  delete newFilters.requestId;
  delete newFilters.paiId;
  try {
    const response = await InspectionService.getProjectAssetInspectionFindings(filters.paiId, newFilters);
    return response.data;
  } catch (e) {
    throw new Error('Error during request for findings...');
  }
});

const initialState = {
  projAssetInspection: null,
  error: null,
  loading: false,
  data: [],
  totalCount: 0,
  detail: {},
  panels: {
    projId: null,
    loading: true,
    detail: {},
    search: {
      loaded: false,
    },
  },
  filters: {
    page: 1,
    page_size: 1000,
    sort_by: {},
    search: '',
  },
};

const findingSlice = createSlice({
  name: 'findings',
  initialState,
  reducers: {
    resetFinding: () => initialState,
    setProjAssetInspection(state, action) {
      state.projAssetInspection = action.payload;
    },
    setPageFilter(state, action) {
      state.filters.page = action.payload;
    },
    setSearch(state, action) {
      state.filters.search = action.payload;
    },
    setAcctId(state, action) {
      state.filters.acctId = action.payload.value;
    },
    setOrganizationId(state, action) {
      state.filters.organizationId = action.payload.value;
    },
    setSortBy(state, { payload }) {
      const { label, value } = payload;

      if (value === '' || value === null) {
        delete state.filters.sort_by[label];
      } else {
        state.filters.sort_by[label] = value;
      }
    },
    setFilterValue(state, { payload }) {
      const { param, value } = payload;
      if ((value === '' || value === null) && param !== 'date_range') {
        delete state.filters[param];
        return;
      }
      state.filters[param] = value;
    },
    setDateRange(state, { payload }) {
      state.filters.date_range = payload;
    },
    setFilters(state, action) {
      const filter = { ...state.filters, ...action.payload, page: 1 };
      if (!filter?.participantLevel) delete filter?.participantLevel;
      state.filters = filter;
    },
    setFindings(state, action) {
      state.data = action.payload;
    },
    setFinding(state, action) {
      state.detail = action.payload;
    },
  },
  extraReducers: (builder) => {
    // get findings for proj asset
    builder.addCase(getFindings.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFindings.rejected, (state) => {
      state.data = [];
      state.totalCount = 0;
      state.error = 'There was an error during the request.';
      state.loading = false;
    });
    builder.addCase(getFindings.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.totalCount = action.payload.meta.totalCount;
      state.error = null;
      state.loading = false;
    });
  },
});

export const {
  setSearch,
  setProjAssetInspection,
  setAcctId,
  setOrganizationId,
  setSortBy,
  setPageFilter,
  setFilterValue,
  setDateRange,
  resetFinding,
  setFilters,
  setFindings,
  setFinding,
  // eslint-disable-next-line prettier/prettier
} = findingSlice.actions;

export default findingSlice.reducer;

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  selectAssign: {
    position: 'relative',
    '& .defult-assign': {
      display: 'flex',
      gap: 8,
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '& .danger': {
      color: theme.palette.danger.main,
    },
    '& .MuiButtonBase-root': {
      color: theme.palette.primary[100],
    },
    '& .items': {
      position: 'absolute',
      top: '100%',
      backgroundColor: theme.palette.primary.dark,
      borderRadius: '8px',
      maxHeight: '12vh',
      overflowY: 'auto',
      zIndex: 10,
      '& .MuiMenuItem-root': {
        borderBottom: `1px solid ${theme.palette.primary[400]}`,
        fontSize: '14px',
        cursor: 'default',
      },
      '& .MuiMenuItem-root:last-child': {
        borderBottom: 'none',
      },
    },
  },
  table: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

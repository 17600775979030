import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import OrganizationService from 'services/OrganizationService';
import UserService from 'services/UserService';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { Permissions } from 'lib/permissions';
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WidgetDivider } from 'components/WidgetDivider/WidgetDivider';
import { ModalHeaderIcon } from 'components/ModalHeaderIcon/ModalHeaderIcon';
import { UserPlusIcon } from 'components/Icons/UserPlusIcon';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import { DialogMessage } from 'components/DialogMessage';
import { InfoIcon } from 'components/Icons';

const validationSchema = Yup.object({
  firstName: Yup.string()
    .trim()
    .min(1, 'First Name must contain at least one character')
    .matches(/^[A-Za-z. ]+$/, 'Please enter valid First Name')
    .required('First name is required'),
  lastName: Yup.string()
    .trim()
    .min(1, 'Last Name must contain at least one character')
    .matches(/^[A-Za-z. ]+$/, 'Please enter valid Last Name')
    .required('Last name is required'),
  email: Yup.string()
    .matches(/^\S*$/, 'Email can not contain space')
    .email('Invalid email address')
    .required('Email is required'),
  organization: Yup.string().required('Organization is required'),
  customMessage: Yup.string(),
});

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  organization: '',
  role: 'view',
  customMessage: '',
};

export const ModalNewUser = ({ onCancel, onConfirm, isOpen }) => {
  const [loading, setLoading] = React.useState(false);
  const [orgs, setOrgs] = React.useState([]);
  const [submitError, setSubmitError] = React.useState('');
  const userPermCollection = useSelector((state) => state.auth.permissions);
  const organizations = useSelector((state) => state.auth.organizations);
  const [openDialog, setOpenDialog] = React.useState(false);

  const closeConfirm = () => {
    onConfirm();
    onCancel();
  };

  const addNewUser = async (values) => {
    setLoading(true);
    const data = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      message: values.customMessage,
      orgId: values.organization,
    };
    try {
      const userResponse = await UserService.createUser(data);
      await OrganizationService.addUser(values.organization, {
        userId: userResponse.data.data.id,
        roles: [values.role === 'view' ? 18 : 9],
      });
      setLoading(false);
      setOpenDialog('User invite was successfully created');
    } catch (err) {
      const result = err.response.data.message;
      setSubmitError(result);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit(values) {
      addNewUser(values);
    },
  });

  const responseParser = (auxOrgs) =>
    auxOrgs.map((org) => ({
      value: org.id,
      label: org.name,
    }));

  const fetchOrg = async () => {
    const response = await OrganizationService.getOrganizations({ page_size: 500 });
    let auxOrgs = response.data.data;
    if (!userPermCollection.userPerms[Permissions.ACCOUNT_USER_GRANT] && organizations.length > 0) {
      auxOrgs = auxOrgs.filter((item) => item.id === organizations[0].id);
    }
    setOrgs(responseParser(auxOrgs));
  };

  React.useEffect(() => {
    fetchOrg();
  }, []);

  const text =
    'User will only be able to view content they have access to. They will NOT be able create projects, create teams, create observations, upload, edit, or delete content.';

  return (
    <Dialog
      open={isOpen}
      maxWidth="sm"
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <ModalHeaderIcon icon={UserPlusIcon} text="Add Users" color="warning" />
        <Typography mb={2}>Add new users to the Account</Typography>
        <WidgetDivider color="secondary" />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <InputLabel>First Name *</InputLabel>
            <TextField
              variant="outlined"
              placeholder="First Name"
              name="firstName"
              {...formik.getFieldProps('firstName')}
              error={!!(formik.touched.firstName && formik.errors.firstName)}
              helperText={formik.touched.firstName && formik.errors.firstName ? formik.errors.firstName : null}
              required
              fullWidth
            />
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <InputLabel>Last Name *</InputLabel>
            <TextField
              variant="outlined"
              placeholder="Last Name"
              name="lastName"
              disabled={loading}
              {...formik.getFieldProps('lastName')}
              error={!!(formik.touched.lastName && formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName ? formik.errors.lastName : null}
              required
              fullWidth
            />
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <InputLabel>Organization *</InputLabel>
            <FormControl error={formik.touched.organization && formik.errors.organization} fullWidth>
              <Autocomplete
                options={orgs}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select"
                    onBlur={formik.handleBlur}
                    required
                    error={!!(formik.touched.organization && formik.errors.organization)}
                  />
                )}
                onChange={(e, value) => {
                  formik.handleChange('organization')(value?.value ? value?.value : '');
                }}
              />
              <FormHelperText>
                {formik.touched.organization && formik.errors.organization ? formik.errors.organization : null}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Email *</InputLabel>
            <TextField
              variant="outlined"
              placeholder="Email"
              name="email"
              type="email"
              disabled={loading}
              {...formik.getFieldProps('email')}
              error={!!(formik.touched.email && formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email ? formik.errors.email : null}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom>Account Role</Typography>
          </Grid>
        </Grid>
        <FormGroup>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Checkbox color="secondary" />}
                disabled={loading}
                label="Regular User"
                value="regular"
                name="role"
                color="secondary"
                onChange={() => {
                  formik.setFieldValue('role', 'regular');
                }}
                checked={formik.values.role === 'regular'}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Checkbox color="secondary" />}
                disabled={loading}
                label={
                  <InputLabel
                    onClick={() => formik.setFieldValue('role', 'view')}
                    sx={{ cursor: 'pointer', marginBottom: 0 }}
                  >
                    View Only *
                    <Tooltip title={text}>
                      <IconButton>
                        <FontAwesomeIcon icon={faCircleInfo} />
                      </IconButton>
                    </Tooltip>
                  </InputLabel>
                }
                value="view"
                name="role"
                color="secondary"
                onChange={() => {
                  formik.setFieldValue('role', 'view');
                }}
                checked={formik.values.role === 'view'}
              />
            </Grid>
          </Grid>
        </FormGroup>
        <InputLabel sx={{ marginTop: 1 }}>Custom Message</InputLabel>
        <TextField
          variant="outlined"
          placeholder="Custom message here, if desired."
          name="customMessage"
          disabled={loading}
          type="text"
          {...formik.getFieldProps('customMessage')}
          error={!!(formik.touched.customMessage && formik.errors.customMessage)}
          helperText={formik.touched.customMessage && formik.errors.customMessage ? formik.errors.customMessage : null}
          fullWidth
        />
        {submitError && <Typography color="error">{submitError}.</Typography>}
        <DialogMessage
          title={openDialog}
          isOpen={openDialog}
          icon={InfoIcon}
          confirmText="Ok"
          onConfirm={closeConfirm}
        />
        <LoadingOverlay loading={loading} />
      </DialogContent>

      <DialogActions>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button color="primary" variant="contained" onClick={onCancel} disabled={loading} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button color="secondary" variant="contained" disabled={loading} onClick={formik.handleSubmit} fullWidth>
              Send invites
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

ModalNewUser.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  isOpen: PropTypes.bool,
};

ModalNewUser.defaultProps = {
  isOpen: false,
  onConfirm: null,
  onCancel: null,
};

import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalStyles } from 'styles';
import { Avatar } from 'components/Avatar';

export const InfoPanelBodyHeader = ({ image, name }) => {
  const globalClasses = useGlobalStyles();

  return (
    <div className={globalClasses.infoPanelBodyHeader}>
      <img alt="" src={image} />
      <div className={globalClasses.infoPanelBodyHeaderAvatar}>
        <Avatar initials={name} size="72" />
      </div>
    </div>
  );
};

InfoPanelBodyHeader.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
};

InfoPanelBodyHeader.defaultProps = {
  image: '',
  name: '',
};

import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePagination, useSortBy, useTable } from 'react-table';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { Time } from 'lib/time';
import { Card } from 'components/Card';
import { Loader } from 'components/Loader';
import { ProfileSettingsContainer } from 'components/ProfileSettingsContainer/ProfileSettingsContainer';
import { getProfileInspections, setPageFilter, setSearch } from 'slices/profileInspectionsReducer';
import { LinearProgressWithLabel } from 'components/ProjectDetail/ProjectActiveInspections/ProjectAssetInspectionDetail';
import { GeneralTable } from 'components/GeneralTable/GeneralTable';
import { TablePagination } from 'components/TablePagination/TablePagination';
import { VideoIcon, PhotoIcon } from 'components/Icons';
import { ContextMenu } from 'components/ContextMenu';
import { ContextMenuButton } from 'components/ContextMenuButton';
import { ContextMenuItem } from 'components/ContextMenuItem';
import { ContextMenuList } from 'components/ContextMenuItems';
import { ExportFindings } from 'components/Findings/ExportFindings/ExportFindings';
import { ProjectAssetInspectionFindings } from 'components/ProjectDetail/ProjectAssets/ProjectAssetDetail/ProjectAssetInspections/ProjectAssetInspectionFindings/ProjectAssetInspectionFindings';
import { setProjAssetInspection } from 'slices/findingReducer';
import { TopBar } from 'components/TopBar/TopBar';
import { useGlobalStyles } from 'styles';
import { useSidebar } from 'components/SidebarProvider/SidebarProvider';
import { InfoPanel } from 'pages/Admin/Content/InfoPanel';
import { getSingleContent } from 'slices/contentAdminReducer';
import { setContentDetail } from 'slices/contentReducer';
import { ContentDetail } from 'components/ContentDetail/ContentDetail';
import { SidebarDetailContext } from 'components/SidebarProviderDetail/SidebarProviderDetail';
import { getProjectAsset } from 'slices/profileProjectAssetReducer';
import { SearchPanel } from './SearchPanel';
import { useStyles } from './styles';

const tableColumns = (globalClasses, classes) => [
  {
    Header: 'Content Title',
    accessor: (rowData) => {
      const { name, createdAt } = rowData.projectAsset;
      return (
        <div className={globalClasses.nameContainer}>
          <div className={classes.assetType}>
            {rowData.projectAsset?.sequence?.asset?.type === 'VIDEO' ? (
              <VideoIcon size={24} />
            ) : (
              <PhotoIcon size={24} />
            )}
          </div>
          <div>
            {name}
            <Typography>
              {rowData.projectAsset?.sequence?.asset?.type === 'VIDEO'
                ? Time.humanReadable({ seconds: rowData.projectAsset?.sequence?.asset?.timeLength })
                : rowData.projectAsset?.sequence?.asset?.type}
            </Typography>
            <Typography className={globalClasses.greyTypography}>{moment(createdAt).format('MM/DD/YYYY')}</Typography>
          </div>
        </div>
      );
    },
  },
  {
    Header: 'Project',
    accessor: (rowData) => rowData?.projectAsset?.project.name || '',
  },
  {
    Header: 'Insp. Name',
    accessor: (rowData) => {
      const { inspection } = rowData;
      return inspection?.id ? inspection.name : '';
    },
  },
  {
    Header: 'Assigned',
    accessor: (rowData) => {
      const { assignment } = rowData;
      return assignment?.assignee?.updatedAt
        ? moment(assignment?.assignee?.updatedAt).format('MM/DD/YY')
        : 'date not found';
    },
  },
  {
    Header: '% Complete',
    accessor: (rowData) => {
      const { progress } = rowData;
      return <LinearProgressWithLabel value={progress?.id ? progress.percent : 0} label="%" />;
    },
  },
];

const actions = {
  EXPORT_FINDINGS: 'export_findings',
};

let debounceTimeout = null;
export const ProfileInspections = () => {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const { fname, lname, userId } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { loading, data, filters, totalCount } = useSelector((state) => state.profileInspections);
  const search = useSelector((state) => state.profileInspections.filters.search || '');
  const handleSearch = (e) => dispatch(setSearch(e.target.value));
  const columns = React.useMemo(() => tableColumns(globalClasses, classes), []);
  const [action, setAction] = useState(null);
  const { setActivePanel, setFullWidth, setHeaderPanels: setHeaderPanelsDetail } = useContext(SidebarDetailContext);

  // header on section
  const getHeaderText = () => {
    const header = fname === null || lname === null ? 'Profile Dashboard' : `${fname} ${lname}'s Dashboard`;
    return header.toUpperCase();
  };

  // close modals
  const onCancel = () => {
    setAction(null);
  };

  const table = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: filters.page - 1,
        pageSize: filters.page_size,
      },
      pageCount: Math.ceil(totalCount / filters.page_size),
      totalCount,
      pageLength: data.length,
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  const {
    state: { pageIndex, pageSize },
  } = table;

  const {
    displayPanel,
    setHeaderPanels,
    setFullWidth: setNormalFullWidth,
  } = useSidebar({
    open: false,
    config: {
      search: {
        show: true,
        component: SearchPanel,
      },
      header: [],
    },
  });

  // show content clicking on row
  const handleRowClick = (rowData) => {
    dispatch(
      getSingleContent({
        projectId: rowData.original.projectAsset?.projId,
        id: rowData.original.projectAsset?.id,
        user: rowData.original.projectAsset?.asset?.user,
      })
    );
    setHeaderPanels([
      {
        name: 'info',
        component: InfoPanel,
      },
    ]);
    setNormalFullWidth(false);
    displayPanel('info');
  };

  // view content detail
  const viewContent = (rowData) => {
    dispatch(setContentDetail({ ...rowData.projectAsset, inspectionSection: true }));
    dispatch(getProjectAsset({ projId: rowData.projectAsset?.projId, assetId: rowData.projectAsset?.id }));
    setHeaderPanelsDetail([
      {
        name: 'contentDetail',
        component: ContentDetail,
      },
    ]);
    setFullWidth(true);
    setActivePanel('contentDetail');
  };

  // get data
  const loadData = () => {
    const sendFilters = {
      ...filters,
      sort_by: Object.values(filters.sort_by),
      assigneeId: userId,
      include: ['inspection', 'assignment', 'progress', 'projectAsset', 'asset', 'project'],
    };
    dispatch(getProfileInspections(sendFilters));
  };

  useEffect(() => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      loadData();
    }, 150);
  }, [filters]);

  useEffect(() => {
    dispatch(setPageFilter(pageIndex + 1));
  }, [pageIndex, pageSize]);

  if (loading && data.length === 0) {
    return (
      <ProfileSettingsContainer title={getHeaderText()} showSidebar>
        <Loader loading={loading} height={250} />
      </ProfileSettingsContainer>
    );
  }

  const viewInspectionFindings = (rowData) => {
    dispatch(setProjAssetInspection({ ...rowData }));
    setHeaderPanelsDetail([
      {
        name: 'inspectionFindigs',
        component: ProjectAssetInspectionFindings,
      },
    ]);
    setFullWidth(true);
    setActivePanel('inspectionFindigs');
  };

  const ProfileInspectionActions = ({ row }) => (
    <ContextMenu>
      <ContextMenuButton />
      <ContextMenuList position="left">
        <ContextMenuItem onClick={() => viewContent(row.original)}>Continue Inspection</ContextMenuItem>
        <ContextMenuItem onClick={() => viewInspectionFindings({ ...row.original })}>
          View Inspection Findings
        </ContextMenuItem>
        <ContextMenuItem
          onClick={() =>
            setAction({
              action: actions.EXPORT_FINDINGS,
              data: row.original,
            })
          }
        >
          Export Findings
        </ContextMenuItem>
      </ContextMenuList>
    </ContextMenu>
  );

  ProfileInspectionActions.propTypes = {
    row: PropTypes.object.isRequired,
  };

  const Content = () => (
    <>
      <GeneralTable table={table} handleRowClick={handleRowClick} rowComponent={ProfileInspectionActions} />
      <TablePagination table={table} />
      {action?.action === actions.EXPORT_FINDINGS && (
        <ExportFindings handleClose={onCancel} projAssetInspection={action.data} />
      )}
    </>
  );

  return (
    <ProfileSettingsContainer title={getHeaderText()} showSidebar actions={[]}>
      <Card noPadding>
        <TopBar searchValue={search} handleSearch={handleSearch} activatedFilter={filters.activated} />
        {Content()}
      </Card>
    </ProfileSettingsContainer>
  );
};

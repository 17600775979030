import { Typography } from 'components/Typography/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { StyledButton } from './styles';

const StyledButtonText = styled(Typography)`
  display: inline-block;
  ${(props) => {
    if (props.hasIcon && !props.append) {
      return css`
        margin-left: 10px};
      `;
    }
    if (props.hasIcon && props.append) {
      return css`
        margin-right: 10px;
      `;
    }

    return css``;
  }}
`;

export const Button = React.forwardRef(
  (
    {
      kind,
      size,
      icon: IconComponent,
      iconOnly,
      appendIcon,
      type,
      children,
      disabled,
      fullWidth,
      spacingTop,
      spacingRight,
      spacingBottom,
      spacingLeft,
      ...rest
    },
    ref
  ) => {
    let iconSize;
    switch (size) {
      case 'small':
        iconSize = 12;
        break;
      case 'medium':
        iconSize = 15;
        break;
      case 'large':
        iconSize = 16;
        break;
      default:
        iconSize = 15;
    }

    iconSize = iconOnly ? 100 : iconSize;

    const hasIcon = !!IconComponent;
    const shouldPrepend = hasIcon && !appendIcon;
    const shouldAppend = hasIcon && appendIcon;

    const styledButtonText = (
      <StyledButtonText variant="button" as="span" hasIcon={hasIcon} append={shouldAppend}>
        {children}
      </StyledButtonText>
    );
    return (
      <StyledButton
        kind={kind}
        size={size}
        type={type}
        iconOnly={iconOnly}
        disabled={disabled}
        fullWidth={fullWidth}
        spacingTop={spacingTop}
        spacingRight={spacingRight}
        spacingBottom={spacingBottom}
        spacingLeft={spacingLeft}
        {...rest}
        ref={ref}
      >
        {shouldPrepend && <IconComponent size={iconSize} />}
        {!iconOnly && styledButtonText}
        {shouldAppend && <IconComponent size={iconSize} />}
      </StyledButton>
    );
  }
);

Button.propTypes = {
  /**
   * Determines style/color of button
   */
  kind: PropTypes.oneOf(['primary', 'secondary', 'danger', 'success']),
  /**
   * Determines size of button, one of three standards
   */
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  /**
   * Function that renders icon to be displayed inside button
   */
  icon: PropTypes.func,
  /**
   * Sets button to display only the icon or not
   */
  iconOnly: PropTypes.bool,
  /**
   * Icon added after text if true, before text if false
   */
  appendIcon: PropTypes.bool,
  /**
   * Type of button to be rendered, standard html prop
   */
  type: PropTypes.string,

  /**
   * Top margin
   */
  spacingTop: PropTypes.number,

  /**
   * Right margin
   */
  spacingRight: PropTypes.number,

  /**
   * Bottom margin
   */
  spacingBottom: PropTypes.number,

  /**
   * Left margin
   */
  spacingLeft: PropTypes.number,

  /**
   * Should teh button display block
   */
  fullWidth: PropTypes.bool,

  /**
   * Renders children of component
   */
  children: PropTypes.node,
  /**
   * If button is to be disabled
   */
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  kind: 'primary',
  size: 'medium',
  type: 'button',
  iconOnly: false,
  appendIcon: false,
  spacingTop: 0,
  spacingRight: 0,
  spacingBottom: 0,
  spacingLeft: 0,
  fullWidth: false,
  icon: null,
  children: null,
  disabled: false,
};

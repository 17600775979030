import { DialogMessage } from 'components/DialogMessage';
import { InfoIcon } from 'components/Icons';
import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import { FormRadioGroup } from 'components/FormRadioGroup';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from '@mui/material';
import { v4 } from 'uuid';

export const RoleDialog = ({ isOpen, title, subTitle, roles, onSave, radioButton, isLoading }) => {
  if (!isOpen) return null;

  const existViewRole = () => !!roles.find((item) => item.permId === 18);

  const [stateRoles, setStateRoles] = useState(
    roles.map((role) => ({
      ...role,
      value: role.permId || role.id,
      label: role.name,
      disabled: role.disabled,
      checked: role.isChecked,
    }))
  );
  const [openDialog, setOpenDialog] = useState(false);

  const handleOnChange = ({ checked, value }) => {
    setStateRoles((oldState) =>
      oldState.map((role) => {
        const newRole = { ...role };
        newRole.checked = radioButton ? false : role.checked;
        if (newRole.value === value) {
          newRole.checked = checked;
        }
        return newRole;
      })
    );
  };

  const handleOnSave = () => {
    const checkedRoleLookup = stateRoles.reduce((acc, role) => {
      if (role.checked) acc[role.value] = true;
      return acc;
    }, {});
    const newRoles = roles.filter((role) => checkedRoleLookup[role.permId || role.id]);
    if (newRoles?.length <= 0) {
      setOpenDialog(true);
      return;
    }
    onSave(newRoles);
  };

  return (
    <>
      <Dialog maxWidth="sm" open={isOpen} onClose={handleOnSave}>
        <DialogTitle sx={{ m: 0, p: 1 }}>
          <Typography variant="h4">{title}</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={0.5}>
            {subTitle && (
              <Grid item xs={12}>
                <Typography variant="h5">{subTitle}</Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <ul>
                {radioButton ? (
                  <FormRadioGroup
                    name="role"
                    options={stateRoles}
                    onChecked={(value) => handleOnChange({ checked: true, value })}
                    disabled={isLoading}
                  />
                ) : (
                  <FormGroup>
                    {stateRoles.map((role) => (
                      <FormControlLabel
                        key={`${v4()}${new Date().getMilliseconds()}`}
                        control={<Checkbox color="secondary" />}
                        label={role.label}
                        value={role.value}
                        checked={role.checked}
                        disabled={role.disabled}
                        name={role.name}
                        readOnly
                        onChange={(event) => handleOnChange({ checked: event.target.checked, value: role.value })}
                      />
                    ))}
                  </FormGroup>
                )}
              </ul>
            </Grid>
            <Grid item xs={12}>
              {existViewRole() && (
                <Typography variant="caption">
                  View Only **If Checked - this will override other checked permissions and limit a user to view only**
                </Typography>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={1}>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <Button color="secondary" variant="contained" onClick={handleOnSave} disabled={isLoading} fullWidth>
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <DialogMessage
        title="Users must have at least one role. Please, select one."
        isOpen={openDialog}
        icon={InfoIcon}
        confirmText="Ok"
        onConfirm={() => {
          setOpenDialog(false);
        }}
      />
    </>
  );
};

RoleDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  radioButton: PropTypes.bool,
  isLoading: PropTypes.bool,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      isChecked: PropTypes.bool,
      disabled: PropTypes.bool,
      name: PropTypes.string,
    })
  ).isRequired,
};

RoleDialog.defaultProps = {
  radioButton: false,
  isLoading: false,
};

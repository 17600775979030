import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  content: () => ({
    fontFamily: theme.typography.fontFamily,
    borderLeft: `1px solid ${theme.palette.secondary[700]}`,
    padding: theme.spacing(0.5),
    height: '100vh',
    width: '100%',
    background: theme.palette.primary.main,
    '& > div': {
      background: theme.palette.primary.main,
    },
    '& .mapDetailContainer': {
      height: '60vh',
    },
  }),
}));

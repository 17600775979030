import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation, useParams } from 'react-router';
import ProjectService from 'services/ProjectService';
import { CardPagination } from 'components/CardPagination/CardPagination';
import { DialogMapExtentFilter } from 'components/DialogMapExtentFilter/DialogMapExtentFilter';
import { DialogMessage, dialogMessageAction } from 'components/DialogMessage';
import { DialogProjectCopy } from 'components/DialogProjectCopy';
import { DialogProjectShare } from 'components/DialogProjectShare';
import { FileIcon, InfoIcon, ObservationIcon, PlusIcon } from 'components/Icons';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import { ProjectCard } from 'components/ProjectCard';
import { useSidebar } from 'components/SidebarProvider/SidebarProvider';
import { useDispatch, useSelector } from 'react-redux';
import { getProject } from 'slices/adminProjectReducer';
import { ModalEditAsset } from 'smartComponents/ModalEditAsset';
import {
  deleteAsset,
  getProjectAsset,
  getProjectAssets,
  getFilteredProjectAssets,
  setMapExtent,
  setPageFilter,
  setAssetsData,
  setPageSize,
} from 'slices/profileProjectAssetReducer';
import { AssetsTable } from 'components/AssetsTable';
import { WidgetSplash } from 'components/WidgetSplash/WidgetSplash';
import SplashImage from 'images/ContentSplash.png';
import { ObservationsList } from 'components/Observation/ObservationsList/ObservationsList';
import { ViewRoleValidation } from 'components/ViewRoleValidation/ViewRoleValidation';
import { CopyUuidAlert } from 'components/AddUuid/CopyUuidAlert/CopyUuidAlert';
import { Permissions } from 'lib/permissions';
import { DeleteProjectRelation } from 'components/ManageSharedAssets/DeleteProjectRelation';
import { ContextMenuItem } from 'components/ContextMenuItem';
import { InfoPanel } from '../InfoPanel';
import { SearchPanel } from '../SearchPanel';
import { ProjectsContainer } from '../styles';
import { MapContainer } from '../MapContainer/MapContainer';

export const ProjectContent = ({ projectData, selectPath, tableToogle, assetData, assetCallBack }) => {
  const [openContentNotReady, setOpenContentNotReady] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openDialogProjectShare, setDialogProjectShare] = React.useState(false);
  const [openDialogProjectCopy, setDialogProjectCopy] = React.useState(false);
  const [currentAsset, setCurrentAsset] = React.useState([]);
  const [titleContentNotReady, setTitleContentNotReady] = React.useState('');
  const [shareAsset, setShareAsset] = React.useState(null);
  const [openEditAsset, setOpenEditAsset] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const mapExtent = useSelector((state) => state.search.filters.map_extent || []);
  const [selectedAsset, setSelectedAsset] = useState(assetData);
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const { data, loading, filters, totalCount, refetchLoading } = useSelector((state) => state.profileProjectAssets);
  const [mapExtentDialogOpen, setMapExtentDialogOpen] = React.useState(false);
  const [validateRole, setValidateRole] = useState({ open: false, callBack: () => {} });
  const location = useLocation();
  const [uuidCopied, setUuidCopied] = React.useState(false);
  const [openRemove, setOpenRemove] = useState(false);

  const navigateToContent = (asset, openType) => {
    if (!asset.isTranscodingComplete || asset.hasError || !asset.uploadComplete) {
      let msg = '';

      if (!asset.uploadComplete) {
        msg = `This content (${asset.name}) is uploading.`;
      } else if (!asset.isTranscodingComplete) {
        msg = `This content (${asset.name}) is transcoding.`;
      } else if (asset.hasError) {
        msg = 'We encountered an error while transcoding this content.';
      }

      setTitleContentNotReady(msg);
      setOpenContentNotReady(true);
      return;
    }
    const projectRoute = location.pathname.includes('external') ? `external/projects` : 'projects';
    if (openType === 'same-window') {
      if (selectPath === 'projects') {
        history.push(`/projects/${params.projId}/content/${asset.id}`);
      } else {
        history.push(`/${selectPath}/${projectRoute}/${params.projId}/content/${asset.id}`);
      }
    } else {
      window.open(`/projects/${params.projId}/content/${asset.id}`, '_blank');
    }
  };

  const navigateToUpload = () => {
    const currentUrl = window.location.href;
    const currentPage = currentUrl.split('/')[3];

    if (currentPage === 'projects') {
      history.push(`/projects/${params.projId}/content/create`);
    } else if (currentPage === 'admin') {
      const projectRoute = location.pathname.includes('external') ? `external/projects` : 'projects';
      history.push(`/${currentPage}/${projectRoute}/${params.projId}/content/create`);
    } else if (currentPage === 'profile') {
      history.push(`/${currentPage}/projects/${params.projId}/content/create`);
    } else {
      history.push(`/admin/users`);
    }
  };

  // Methods to handle share project
  const onClickShare = (asset) => {
    setShareAsset(asset);
    setDialogProjectShare(true);
  };

  const distanceInKm = (lon1, lat1, lon2, lat2) => {
    const deg2rad = (deg) => deg * (Math.PI / 180);
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1); // deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
  };

  const handleProjectShare = (projId) => {
    const updateProject = async () => {
      await ProjectService.ShareAssetWithProject(shareAsset.project.id, shareAsset.id, {
        shareWithProjectId: projId,
        assetId: shareAsset.id,
      });
      setDialogProjectShare(false);
    };

    updateProject();
  };

  const onProjectSelectForShare = (projId) => {
    handleProjectShare(projId);
  };

  // Methods to handle copy project
  const onClickCopy = (asset) => {
    setShareAsset(asset);
    setDialogProjectCopy(true);
  };

  const handleProjectCopy = (projId) => {
    const updateProject = async () => {
      await ProjectService.CopyAssetWithProject(shareAsset.project.id, shareAsset.id, projId);

      setDialogProjectCopy(false);
    };

    updateProject();
  };

  const onProjectSelectForCopy = (projId) => {
    handleProjectCopy(projId);
  };

  const handleExtentConfirm = (newBounds) => {
    dispatch(setMapExtent(newBounds));
    setMapExtentDialogOpen(false);
  };

  const handleClearMapExtent = () => {
    dispatch(setMapExtent([]));
    setMapExtentDialogOpen(false);
  };

  const filterAssets = async (limits, defaultAsset) => {
    // send the request to the new service
    ProjectService.getExtentProjectAssets(
      params.projId,
      limits.minLat,
      limits.minLon,
      limits.maxLat,
      limits.maxLon
    ).then((res) => {
      const idsToExport = [];
      const assetsIds = res.data.data;
      if (assetsIds.length > 0) {
        assetsIds.map((asset) => idsToExport.push(asset.past_id));

        // Get assets data
        dispatch(getFilteredProjectAssets({ filters, projId: params.projId, idsToExport }));
        setIsLoading(false);
      } else {
        const distanceToMin = distanceInKm(
          selectedAsset.longitude,
          selectedAsset.latitude,
          limits.minLon,
          limits.minLat
        );
        const distanceToMax = distanceInKm(
          selectedAsset.longitude,
          selectedAsset.latitude,
          limits.maxLon,
          limits.maxLat
        );
        if (distanceToMin >= 10 || distanceToMax >= 10) {
          setSelectedAsset([]);
          dispatch(setAssetsData([]));
        } else {
          dispatch(setAssetsData([defaultAsset]));
          dispatch(setPageSize(1));
          setIsLoading(false);
        }
      }
    });
  };

  const handleGetExtent = (coords) => {
    if (coords.length > 0) {
      setIsLoading(true);
      const finalCoords = { minLat: 999, maxLat: -999, minLon: 999999999999999, maxLon: 0 };
      coords.map((coor) => {
        if (coor.lat < finalCoords.minLat) finalCoords.minLat = coor.lat;
        if (coor.lat > finalCoords.maxLat) finalCoords.maxLat = coor.lat;
        if (Math.abs(coor.long) < Math.abs(finalCoords.minLon)) finalCoords.minLon = coor.long;
        if (Math.abs(coor.long) > Math.abs(finalCoords.maxLon)) finalCoords.maxLon = coor.long;
        return coor;
      });
      filterAssets(finalCoords, selectedAsset);
    } else {
      setIsLoading(false);
    }
  };

  const handleDeleteConfirm = async () => {
    await dispatch(deleteAsset({ projectId: currentAsset?.project?.id, assetId: currentAsset.id }));
    dispatch(getProjectAssets({ projId: params.projId, filters }));
    setOpenDelete(false);
  };

  const handleDeleteCancel = () => {
    setOpenDelete(false);
  };

  const handleEditAsset = async () => {
    dispatch(getProjectAssets({ projId: params.projId, filters }));
    setOpenEditAsset(false);
  };

  const handleCancelEditAsset = () => {
    setOpenEditAsset(false);
  };

  const { displayPanel, setHeaderPanels } = useSidebar({
    open: false,
    config: {
      onClose: () => {
        // do nothing
      },
      search: {
        show: true,
        component: SearchPanel,
      },
      header: [],
      actions: [
        {
          icon: PlusIcon,
          onClick: () =>
            setValidateRole({
              open: true,
              action: [Permissions.PROJ_UPLOAD_CONTENT],
              callBack: () => navigateToUpload(),
            }),
        },
      ],
      nav: [
        {
          icon: ObservationIcon,
          size: 24,
          name: 'observationList',
          component: ObservationsList,
        },
      ],
    },
  });

  const handleRowClick = (assetId) => {
    // dispatch redux action to load user data
    // set sidebar active panel to user info and open if necessary
    if (selectedAsset.id === assetId) return;
    assetCallBack(data.find((item) => item.id === assetId));
    setSelectedAsset(data.find((item) => item.id === assetId));
    displayPanel(false);
  };

  const openSideBar = (assetId) => {
    dispatch(getProjectAsset({ projId: params.projId, assetId, lat: 'lat', long: 'long' }));
    dispatch(getProject(params.projId));
    setHeaderPanels([
      {
        name: 'info',
        component: InfoPanel,
      },
    ]);
    displayPanel('info');
  };

  const contextMenuItems = (asset) => (
    <>
      <ContextMenuItem
        onClick={() =>
          setValidateRole({
            open: true,
            action: [Permissions.PROJ_COPY_UUID],
            data: { ...asset.project, asset },
            callBack: () => setUuidCopied(asset.id),
          })
        }
      >
        Copy UUID
      </ContextMenuItem>

      <ContextMenuItem onClick={() => navigateToContent(asset, 'new-window')}>Open Asset in new window</ContextMenuItem>

      <ContextMenuItem key="detail" onClick={() => openSideBar(asset.id)}>
        Info Panel
      </ContextMenuItem>

      <ContextMenuItem key="detail" onClick={() => navigateToContent(asset, 'same-window')}>
        Detailed View
      </ContextMenuItem>

      <ContextMenuItem
        onClick={() =>
          setValidateRole({
            open: true,
            action: [
              Permissions.PROJ_MODIFY_CONTENT,
              Permissions.PROJ_MODIFY_CONTENT_SHARE_COPY,
              Permissions.PROJ_COPY,
            ],
            data: { ...asset.project, asset },
            callBack: () => onClickCopy(asset),
          })
        }
      >
        Copy
      </ContextMenuItem>

      <ContextMenuItem
        onClick={() =>
          setValidateRole({
            open: true,
            data: { ...asset.project, asset },
            action: [
              Permissions.PROJ_MODIFY_CONTENT,
              Permissions.PROJ_MODIFY_CONTENT_SHARE_COPY,
              Permissions.PROJ_SHARE,
            ],
            callBack: () => onClickShare(asset),
          })
        }
      >
        Share
      </ContextMenuItem>
      {asset?.project?.id !== params.projId && (
        <ContextMenuItem
          onClick={() =>
            setValidateRole({
              open: true,
              data: projectData,
              action: [Permissions.PROJ_MODIFY_CONTENT],
              callBack: () => setOpenRemove({ ...projectData, asset }),
            })
          }
        >
          Remove
        </ContextMenuItem>
      )}

      <ContextMenuItem
        onClick={() =>
          setValidateRole({
            open: true,
            data: { ...asset.project, asset, currentProject: params.projId },
            action: [Permissions.PROJ_DELETE_CONTENT],
            callBack: () => {
              setOpenDelete(true);
              setCurrentAsset(asset);
            },
          })
        }
      >
        Delete
      </ContextMenuItem>

      <ContextMenuItem
        onClick={() =>
          setValidateRole({
            open: true,
            data: { ...asset.project, asset },
            action: [Permissions.PROJ_MODIFY_CONTENT],
            callBack: () => {
              setCurrentAsset(asset);
              setOpenEditAsset(true);
            },
          })
        }
      >
        Edit Info
      </ContextMenuItem>
    </>
  );

  const EmptyProjectsContainer = () => (
    <WidgetSplash alt="Projects Splash" title="There are currently no assets" image={SplashImage} />
  );

  return (
    <>
      <div>{isLoading}</div>
      <LoadingOverlay loading={(!loading && refetchLoading) || isLoading} />
      {(!data || (data && data.length === 0)) && <EmptyProjectsContainer />}
      {data && <MapContainer assetId={selectedAsset.id} projectData={projectData} handleChange={handleGetExtent} />}
      {data && data.length > 0 && !tableToogle && (
        <ProjectsContainer>
          {data.map((asset) => {
            let thumbnail;
            if (asset.thumbnails) thumbnail = asset.thumbnails['400'];
            else thumbnail = '';
            return (
              <ProjectCard
                key={asset.id}
                image={thumbnail}
                projectName={asset.name}
                originProject={asset.project?.name}
                projectCreator={
                  asset.uploaders.length > 0
                    ? `${asset.uploaders[0].firstName} ${asset.uploaders[0].lastName}`
                    : 'Uploader not found'
                }
                projectDate={asset.updatedAt ? asset.updatedAt : asset.createdAt}
                contextMenuItems={contextMenuItems(asset)}
                avatars={[]}
                panelHandler={() => handleRowClick(asset.id, asset.latitude, asset.longitude)}
                attachedFiles={[{ fileId: 1, icon: FileIcon, numberOfFiles: asset.assetSeries, iconColor: 'default' }]}
                navigateToContent={() => navigateToContent(asset)}
                isTranscodingComplete={asset.isTranscodingComplete}
                isUploadComplete={asset.uploadComplete}
                lat={asset.latitude}
                long={asset.longitude}
                testId={asset.id}
                openSideBar={() => openSideBar(asset.id)}
                selectedRow={selectedAsset.id}
                observations={asset.observations?.length || 0}
              >
                <div>{asset.description}</div>
              </ProjectCard>
            );
          })}
        </ProjectsContainer>
      )}
      {data && data.length > 0 && tableToogle && (
        <AssetsTable
          assets={data}
          selectedRow={selectedAsset.id}
          contextMenuItems={contextMenuItems}
          handleRowClick={handleRowClick}
          detailedView={navigateToContent}
          openSideBar={openSideBar}
          updateSelected={(value) => handleRowClick(value.id)}
        />
      )}
      <div>
        <CardPagination
          page={filters.page}
          pageSize={filters.page_size}
          pageLength={data.length}
          totalCount={totalCount}
          onPageChange={(page) => {
            dispatch(setPageFilter(page));
          }}
        />
      </div>
      {openDelete && (
        <DialogMessage
          title={
            currentAsset?.projectSharedWith
              ? 'This asset is being shared to another project. Are you sure you want to cut off access? If not, copy the content to the other project before deleting.'
              : 'Are you sure you want to delete?'
          }
          isOpen={openDelete}
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
          action={dialogMessageAction.delete}
        />
      )}
      <DialogMessage
        title={titleContentNotReady}
        isOpen={openContentNotReady}
        icon={InfoIcon}
        confirmText="Ok"
        onConfirm={() => {
          setOpenContentNotReady(false);
        }}
      />
      {openDialogProjectShare && (
        <DialogProjectShare
          title="Select a project to share this content with."
          isOpen={openDialogProjectShare}
          onConfirm={onProjectSelectForShare}
          onCancel={(flagRefresh) => {
            if (flagRefresh) dispatch(getProjectAssets({ projId: params.projId, filters }));
            setDialogProjectShare(false);
          }}
          asset={shareAsset}
        />
      )}
      {openDialogProjectCopy && (
        <DialogProjectCopy
          title="Select a project to copy this content with."
          isOpen={openDialogProjectCopy}
          onConfirm={onProjectSelectForCopy}
          onCancel={() => {
            setDialogProjectCopy(false);
          }}
          asset={shareAsset}
        />
      )}
      {openEditAsset && (
        <ModalEditAsset
          isOpen={openEditAsset}
          onConfirm={handleEditAsset}
          onCancel={handleCancelEditAsset}
          asset={currentAsset}
          projectId={currentAsset?.project?.id}
        />
      )}
      <DialogMapExtentFilter
        open={mapExtentDialogOpen}
        bounds={mapExtent}
        onCancel={handleClearMapExtent}
        onConfirm={handleExtentConfirm}
      />
      {openRemove && (
        <DeleteProjectRelation
          project={openRemove}
          asset={openRemove.asset}
          simpleRemove
          handleCallBack={() => {
            dispatch(getProjectAssets({ projId: params.projId, filters }));
            setOpenRemove(false);
          }}
          handleCancel={() => setOpenRemove(false)}
        />
      )}
      {validateRole.open && (
        <ViewRoleValidation
          action={validateRole.action}
          data={validateRole?.data?.id ? validateRole?.data : projectData}
          callBack={validateRole.callBack}
          handleClose={() => setValidateRole({ open: false, callBack: () => {} })}
        />
      )}
      {uuidCopied && <CopyUuidAlert id={uuidCopied} handleClose={() => setUuidCopied(false)} />}
    </>
  );
};

ProjectContent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  projectData: PropTypes.object,
  tableToogle: PropTypes.bool,
  assetData: PropTypes.string,
  selectPath: PropTypes.string,
  assetCallBack: PropTypes.func,
};

ProjectContent.defaultProps = {
  projectData: null,
  assetData: { name: '', id: null },
  selectPath: '',
  tableToogle: true,
  assetCallBack: () => {},
};

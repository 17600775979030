import Point from '@arcgis/core/geometry/Point';
import { webMercatorToGeographic } from '@arcgis/core/geometry/support/webMercatorUtils';
import Graphic from '@arcgis/core/Graphic';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import ArcGISMap from '@arcgis/core/Map';
import SimpleMarkerSymbol from '@arcgis/core/symbols/SimpleMarkerSymbol';
import MapView from '@arcgis/core/views/MapView';
import BasemapGallery from '@arcgis/core/widgets/BasemapGallery';
import Expand from '@arcgis/core/widgets/Expand';
import Search from '@arcgis/core/widgets/Search';
import { Button } from 'components/Button';
import { DialogMessage } from 'components/DialogMessage';
import { InfoIcon } from 'components/Icons';
import {
  createMapGeoFeatureLayer,
  fetchMapGeoData,
  observationPoints,
} from 'components/MapFeatureLayer/MapFeatureLayer';
import {
  getProjectAssets,
  unsetMapFilterApplied,
  setMapFilterApplied,
  setSelectedSequence,
} from 'slices/profileProjectAssetReducer';
import { ObservationPopup } from 'components/Observation/ObservationPopup';
import localforage from 'localforage';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { StyledMap } from './styles';
import './styles.css';

const markerSymbol = {
  type: 'simple-marker',
  color: [250, 168, 0, 1],
  outline: {
    color: [250, 168, 0],
    width: 0,
  },
};

// eslint-disable-next-line no-unused-vars
export const MapSimple = ({ points, projectData, assetId, onExtentChange, onClickGetExtent }) => {
  const mapDiv = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [mapView, setMapView] = useState(null);
  const [extentCoords, setExtentCoords] = useState([]);
  const [observation, setObservation] = useState(false);
  const [extentFiltered, setExtentFiltered] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const { observations } = useSelector((state) => state.assetObservations);
  const { filters } = useSelector((state) => state.profileProjectAssets);
  const { mapExtentApplied } = useSelector((state) => state.profileProjectAssets);
  const mapGeoLayer = useMemo(() => new GraphicsLayer({ id: 'mainLine' }), []);
  const observationLayer = useMemo(() => new GraphicsLayer({ id: 'observationPoints' }), []);
  let myPoint = null;
  const graphicsLayer = useMemo(
    () =>
      new GraphicsLayer({
        graphics: [],
      }),
    []
  );
  const lastPoint = points.length - 1;
  const clearExtent = () => {
    dispatch(unsetMapFilterApplied());
    setExtentFiltered(false);
    setExtentCoords([]);
    onClickGetExtent([]);
    // eslint-disable-next-line
    dispatch(getProjectAssets({ projId: projectData.id, filters: filters }));
  };

  useEffect(() => {
    const show = mapExtentApplied;
    setExtentFiltered(show);
  }, []);

  useEffect(() => {
    if (mapExtentApplied === true) {
      if (extentCoords.length > 0) {
        dispatch(setMapFilterApplied());
        setExtentFiltered(true);
        onClickGetExtent(extentCoords);
      } else {
        setOpenDialog(true);
      }
    } else {
      clearExtent();
      dispatch(unsetMapFilterApplied());
      setExtentFiltered(false);
    }
  }, [mapExtentApplied]);

  const gpsMarker = new SimpleMarkerSymbol({
    color: [255, 165, 0, 1],
    size: '8px',
    outline: {
      // autocasts as new SimpleLineSymbol()
      color: [217, 140, 0],
      width: 0.5, // points
    },
  });

  const setMapViewTarget = (mapViewInstance, featureLayerData, flagMap) => {
    if (mapViewInstance) {
      let target = {};
      /* eslint-disable react/prop-types */
      const middlePoint = points.length > 2 ? Math.ceil(points.length / 2) : 0;
      if (points.length === 0) {
        // US geographic Center
        const point = new Point({
          latitude: 37.09024,
          longitude: -95.712891,
        });
        target = { target: point, zoom: 4 };
      } else if (
        (points[0] === undefined && points[0] === undefined) ||
        (points[0].location[1] === null && points[0].location[0] === null)
      ) {
        // US geographic Center
        const point = new Point({
          latitude: 37.09024,
          longitude: -95.712891,
        });
        target = { target: point, zoom: 4 };
      } else {
        const point = new Point({
          longitude: points[middlePoint].location[0],
          latitude: points[middlePoint].location[1],
        });
        target = { target: point, zoom: 19 };
      }
      mapViewInstance.when(
        mapViewInstance
          .goTo(target, { animate: true, easing: 'ease', duration: 200 })
          .then(() => {
            // -------------------- call map geometry data
            if (flagMap) fetchMapGeoData(featureLayerData);
          })
          .catch((error) => {
            if (error.name !== 'AbortError') {
              // eslint-disable-next-line
              console.error(error);
            }
          })
      );
    }
  };

  const currentSymbol = {
    type: 'simple-marker',
    color: [22, 247, 78, 1],
    outline: {
      color: [0, 0, 0],
      width: 2,
    },
  };

  const generatePoints = () => {
    // This function maps through the spatial points on the backend, and creates a collection of orange dot graphics.
    const linePointGraphic = points.map((point, index) => {
      // currentPointLine
      if (points.length < 2) {
        // currentPointLine
        const mapPoint = {
          type: 'point',
          longitude: point.location[0],
          latitude: point.location[1],
        };
        const firstPointGraphic = new Graphic({
          geometry: mapPoint,
          symbol: currentSymbol,
          attributes: index,
        });
        return firstPointGraphic;
      }

      if (index === 1) {
        const mapPoint = {
          type: 'point',
          longitude: point.location[0],
          latitude: point.location[1],
        };
        const firstPointGraphic = new Graphic({
          geometry: mapPoint,
          symbol: gpsMarker,
          attributes: index,
        });
        return firstPointGraphic;
      }

      if (index === lastPoint) {
        const mapPoint = {
          type: 'point',
          longitude: point.location[0],
          latitude: point.location[1],
        };
        const firstPointGraphic = new Graphic({
          geometry: mapPoint,
          symbol: gpsMarker,
          attributes: index,
        });
        return firstPointGraphic;
      }

      const mapPoint = {
        type: 'point',
        longitude: point.location[0],
        latitude: point.location[1],
      };

      const firstPointGraphic = new Graphic({
        geometry: mapPoint,
        symbol: gpsMarker,
        attributes: index,
      });

      return firstPointGraphic;
    });

    const pointGraphics = points.map((point, index) => {
      const mapPoint = {
        type: 'point',
        longitude: point.location[0],
        latitude: point.location[1],
      };

      // TODO: can I add an onclick to a new Graphic
      const graphic = new Graphic({
        geometry: mapPoint,
        symbol: markerSymbol, // lat === point.location[1] && long === point.location[0] ? currentSymbol :
        attributes: index,
        popupTemplate: {
          title: point.name, // Data attribute names
          content: `<div>${point.description}</div>`,
          actions: [
            {
              id: 'video',
              title: 'View Asset',
            },
            {
              id: 'line',
              title: 'View Telemetry',
            },
          ],
        },
      });
      return graphic;
    });

    // graphicsLayer.removeAll();

    graphicsLayer.addMany(pointGraphics);
    graphicsLayer.addMany(linePointGraphic);

    setMapViewTarget(mapView);
  };

  // Assigns the spatial points on load to the map extent.
  useEffect(() => {
    generatePoints();
  }, [points]); // lat, long,

  // ------------------- update observations points
  useEffect(() => {
    observationLayer.removeAll();
    observationLayer.addMany(observationPoints(observations));
  }, [observations]);

  // on page load
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    dispatch(setSelectedSequence(0));
    if (mapDiv.current) {
      graphicsLayer.removeAll();
      /**
       * Initialize application
       */
      const map = new ArcGISMap({
        basemap: 'hybrid',
      });

      const view = new MapView({
        map,
        container: mapDiv.current,
      });

      setMapView(null);
      setMapView(view);

      // create the map selector
      const basemapGallery = new BasemapGallery({
        view,
        container: document.createElement('div'),
      });

      const bgExpand = new Expand({
        view,
        content: basemapGallery.container,
        expandIconClass: 'esri-icon-basemap',
      });

      // ------------------------ add the feature layer to the map
      const mapGeoFeatureLayer = createMapGeoFeatureLayer('point', { id: 'inital-points-1' });
      const mapGeoFeatureLayerLines = createMapGeoFeatureLayer('polyline', { id: 'inital-lines-1' });
      map.add(mapGeoFeatureLayerLines);
      map.add(mapGeoLayer);
      map.add(mapGeoFeatureLayer, 0);

      // create the layer for the obsrvation points
      map.add(observationLayer);

      const searchWidget = new Search({
        view,
      });

      view.ui.add(searchWidget, {
        position: 'top-right',
        index: 2,
      });

      // ----------------------------------------------- zoom in point after searching
      searchWidget.goToOverride = (view2, goToParams) => {
        goToParams.options.duration = 300;
        const target = {
          ...goToParams.target,
          ...(goToParams.target.target?.attributes?.ObjectID &&
            goToParams.target.target?.layer?.uid === mapGeoFeatureLayer.uid && { zoom: 19 }),
        };
        return view.goTo(target, goToParams.options);
      };

      view.ui.add([bgExpand], 'top-left');

      const featureLayerData = {
        view,
        mapGeoFeatureLayer,
        mapGeoFeatureLayerLines,
        mapGeoLayer,
        searchWidget,
        geometryOrgId: projectData?.orgId,
        includeAccountGeometry: false,
      };

      if (typeof view.popup.on === 'function') {
        view.when(() => {
          view.popup.on('trigger-action', (ev) => {
            if (ev.action.id === 'line') {
              setMapViewTarget(view, featureLayerData, true);
            }
            if (ev.action.id === 'video') {
              dispatch(setSelectedSequence(myPoint));
              /* eslint-disable react/prop-types */
              history.push({ pathname: points[0].url.replace('assets', 'content'), state: { data: myPoint } });
            }
          });
        });
      }

      // create the layer for the spatial points and the associating markers
      generatePoints();

      map.add(graphicsLayer);

      // When the Map finishes loading, zoom to the video point path
      if (process.env.NODE_ENV !== 'production') {
        setMapViewTarget(view, featureLayerData, true);
      } else {
        view.on('layerview-create', () => {
          if (view.layerViews.length === map.layers.length) {
            setMapViewTarget(view, featureLayerData, true);
          }
        });
      }

      const convertExtentToCoords = (extent) => {
        const { xmin, ymin, xmax, ymax } = webMercatorToGeographic(extent);
        const newExtentCoords = [
          {
            lat: ymax,
            long: xmin,
          },
          {
            lat: ymax,
            long: xmax,
          },
          {
            lat: ymin,
            long: xmax,
          },
          {
            lat: ymin,
            long: xmin,
          },
        ];
        localforage.setItem('coords', JSON.stringify({ coords: newExtentCoords }));
        setExtentCoords(newExtentCoords, extent);
        onExtentChange(newExtentCoords, extent);
      };

      view.on('drag', (event) => {
        if (event.action === 'end') {
          convertExtentToCoords(view.extent);
          // -------------------- call map geometry data
          fetchMapGeoData(featureLayerData);
        }
      });

      view.on('mouse-wheel', () => {
        convertExtentToCoords(view.extent);
        // -------------------- call map geometry data
        fetchMapGeoData(featureLayerData);
      });

      view.on('click', (event) => {
        view
          .hitTest(event, [view.extent])
          .then((response) => {
            const second = response.results[0].graphic.attributes;
            if (second !== undefined && typeof second !== 'object') {
              myPoint = second;
            }
            if (second.id && second.projectAssetSequenceId) {
              setObservation(second);
            }
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error);
          });
        // eslint-disable-next-line no-underscore-dangle
      });
      return () => {
        graphicsLayer.removeAll();
        observationLayer.removeAll();
        map.remove(graphicsLayer);
        map.remove(mapGeoFeatureLayerLines);
        map.remove(mapGeoLayer);
        map.remove(mapGeoFeatureLayer);
        map.remove(observationLayer);
      };
    }
  }, []);

  return (
    <>
      {extentFiltered === true && (
        <Button
          onClick={() => {
            clearExtent();
            dispatch(unsetMapFilterApplied());
            setExtentFiltered(false);
          }}
          kind="secondary"
          style={{
            position: 'absolute',
            zIndex: '100',
            bottom: '20px',
            right: '145px',
            border: 'none',
          }}
        >
          Clear
        </Button>
      )}
      {extentCoords.length > 0 || extentFiltered ? (
        <Button
          onClick={() => {
            dispatch(setMapFilterApplied());
            setExtentFiltered(true);
            onClickGetExtent(extentCoords);
          }}
          style={{
            position: 'absolute',
            zIndex: '100',
            bottom: '20px',
            right: '5px',
            border: 'none',
          }}
          disabled={extentFiltered === true}
        >
          Get Extent
        </Button>
      ) : (
        <Button
          onClick={() => setOpenDialog(true)}
          style={{
            position: 'absolute',
            zIndex: '100',
            bottom: '20px',
            right: '5px',
            border: 'none',
            backgroundColor: '#CCC',
            color: '#FFF',
          }}
        >
          Get Extent
        </Button>
      )}
      <DialogMessage
        title="For - Get Extent - to be enabled you must zoom in or out on the map, to set the coordinates of the area to filter the assets with."
        isOpen={openDialog}
        icon={InfoIcon}
        confirmText="Ok"
        onConfirm={() => {
          setOpenDialog(false);
        }}
      />
      <ObservationPopup observation={observation} handleObservationClose={() => setObservation(false)} />
      <StyledMap className="mapDiv" id="mapContainer" ref={mapDiv} />
    </>
  );
};
MapSimple.propTypes = {
  points: PropTypes.arrayOf(
    PropTypes.shape({
      assetId: PropTypes.string.isRequired,
      projectId: PropTypes.string.isRequired,
      lat: PropTypes.number.isRequired,
      long: PropTypes.number.isRequired,
    })
  ),
  assetId: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  projectData: PropTypes.object,
  onExtentChange: PropTypes.func,
  onClickGetExtent: PropTypes.func,
};

MapSimple.defaultProps = {
  points: [],
  assetId: null,
  projectData: { id: null, orgId: null, name: '' },
  onExtentChange: () => {},
  onClickGetExtent: () => {},
};

import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const DragIcon = ({ size, className }) => (
  <Svg viewBox="0 0 24 24" size={size} className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.4 4.8a2.4 2.4 0 1 0 0-4.8 2.4 2.4 0 0 0 0 4.8Zm0 9.6a2.4 2.4 0 1 0 0-4.8 2.4 2.4 0 0 0 0 4.8Zm12-12a2.4 2.4 0 1 1-4.8 0 2.4 2.4 0 0 1 4.8 0Zm-2.4 12a2.4 2.4 0 1 0 0-4.8 2.4 2.4 0 0 0 0 4.8Zm-7.2 7.2a2.4 2.4 0 1 1-4.8 0 2.4 2.4 0 0 1 4.8 0ZM12 24a2.4 2.4 0 1 0 0-4.8 2.4 2.4 0 0 0 0 4.8Z"
      fill="currentColor"
    />
  </Svg>
);

DragIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
};

DragIcon.defaultProps = {
  size: 24,
  className: '',
};

import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';

export const useStyles = makeStyles({
  follow: {
    position: 'absolute',
    margin: '6px',
    zIndex: 100,
    bottom: '20px',
    right: '0',
    padding: '2px',
    '& span': {
      fontSize: '20px',
    },
  },
});

export const FollowButton = ({ locateIcon, onClick, title, active }) => {
  const classes = useStyles();

  return (
    <Button
      id="follow"
      color={active ? 'secondary' : 'primary'}
      variant="contained"
      className={classes.follow}
      onClick={() => onClick()}
      title={title}
      size="small"
    >
      <span id="followSpan" className={locateIcon} title={title} />
    </Button>
  );
};
FollowButton.propTypes = {
  locateIcon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.node.isRequired,
  active: PropTypes.bool.isRequired,
};

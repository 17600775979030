import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

const useStyles = makeStyles({
  divider: (props) => ({
    backgroundColor: !props.color ? theme.palette.primary[800] : theme.palette[props.color].main,
    ...(props.margin && {
      marginTop: props.margin !== true ? theme.spacing(props.margin) : theme.spacing(1),
      marginBottom: props.margin !== true ? theme.spacing(props.margin) : theme.spacing(1),
    }),
  }),
});

export const WidgetDivider = ({ color, margin }) => {
  const classes = useStyles({ color, margin });

  return <Divider className={classes.divider} />;
};

WidgetDivider.propTypes = {
  color: PropTypes.string,
  margin: PropTypes.bool,
};

WidgetDivider.defaultProps = {
  color: '',
  margin: false,
};

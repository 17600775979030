import { FormHelperText } from 'components/FormHelperText/FormHelperText';
import { FormLabel } from 'components/FormLabel/FormLabel';
import { If } from 'components/If/If';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { theme } from 'theme';
import { StyledWrapper } from './styles';

const StyledReactSelect = styled(Select)``;

const customStyles = (error) => ({
  control(styles, { isDisabled }) {
    let selectBorder = theme.palette.primary[700];

    if (isDisabled) {
      selectBorder = theme.palette.primary.disabled;
    } else if (error) {
      selectBorder = theme.palette.danger.base;
    }
    return {
      ...styles,
      backgroundColor: theme.palette.primary.base,
      borderColor: selectBorder,
      minHeight: '40px',
      paddingBottom: '1px',
      transition: 'none',
      marginBottom: '0',
      marginTop: '0',

      '&:hover': {
        borderColor: error ? theme.palette.danger.base : theme.palette.primary[700],
      },
    };
  },
  indicatorSeparator(styles, { isDisabled }) {
    let selectBorder = theme.palette.primary[700];

    if (isDisabled) {
      selectBorder = theme.palette.primary.disabled;
    }
    return {
      ...styles,
      backgroundColor: selectBorder,
    };
  },
  dropdownIndicator(styles, { isDisabled }) {
    let selectBorder = theme.palette.primary[700];

    if (isDisabled) {
      selectBorder = theme.palette.primary.disabled;
    }
    return {
      ...styles,
      color: selectBorder,
    };
  },
  singleValue(styles) {
    return {
      ...styles,
      color: 'white',
    };
  },
  menu(styles) {
    return {
      ...styles,
      backgroundColor: theme.palette.primary[900],
      border: `1px solid ${theme.palette.primary[500]}`,
      marginTop: 0,
    };
  },
  option(styles, state) {
    return {
      ...styles,
      backgroundColor: state.isSelected ? theme.palette.secondary.base : 'inherit',
      '&:hover': {
        ...styles['&:hover'],
        backgroundColor: theme.palette.secondary.base,
      },
      ':active': {
        ...styles[':active'],
        backgroundColor: theme.palette.secondary.base,
      },
    };
  },
  multiValue(styles) {
    return {
      ...styles,
      backgroundColor: theme.palette.primary[200],
    };
  },
  multiValueRemove(styles) {
    return {
      ...styles,
      color: theme.palette.primary.base,
      backgroundColor: theme.palette.secondary.base,
      borderTopLeftRadius: '0',
      borderBottomLeftRadius: '0',
      '&:hover': {
        color: theme.palette.primary.base,
        backgroundColor: theme.palette.secondary[400],
      },
    };
  },
});
export const FormSelect = ({ label, helpText, error, disabled, required, onChange, ...rest }) => (
  <StyledWrapper>
    <If cond={label !== null}>
      <FormLabel error={error} disabled={disabled} required={required}>
        {label}
      </FormLabel>
    </If>
    <StyledReactSelect {...rest} styles={customStyles(error)} isDisabled={disabled} onChange={onChange} />
    <If cond={helpText !== null}>
      <FormHelperText error={error} disabled={disabled}>
        {helpText}
      </FormHelperText>
    </If>
  </StyledWrapper>
);

FormSelect.propTypes = {
  /**
   * Set the label value of the input
   */
  label: PropTypes.string,

  /**
   * Set the help text or error message for the input
   */
  helpText: PropTypes.string,

  /**
   * If true the label with display with an error format. Help text will display red
   */
  error: PropTypes.bool,

  /**
   * If true the input will be disabled
   */
  disabled: PropTypes.bool,

  /**
   * HTML required
   */
  required: PropTypes.bool,
  onChange: PropTypes.func,
  /**
   * Accepts all react-select props and passes them to nested component.
   * https://react-select.com/props
   */
};

FormSelect.defaultProps = {
  label: null,
  helpText: null,
  error: false,
  disabled: false,
  required: false,
  onChange: () => {},
};

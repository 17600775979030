import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import libPhone, { isPossiblePhoneNumber, isValidPhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import UserService from 'services/UserService';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { ModalHeaderIcon } from 'components/ModalHeaderIcon/ModalHeaderIcon';
import { UserIcon } from 'components/Icons';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';

// eslint-disable-next-line func-names
Yup.addMethod(Yup.string, 'phoneNumber', function (errorMessage) {
  // eslint-disable-next-line func-names
  return this.test('test-number-valid', errorMessage, function (value) {
    const { path, createError } = this;

    if (!value) {
      return true;
    }

    const strValue = value === null ? '' : String(value);

    const isValidLength = validatePhoneNumberLength(strValue, 'US');

    if (isValidLength === 'TOO_SHORT') {
      return createError({ path, message: `${path} is too short` });
    }
    if (isValidLength === 'TOO_LONG') {
      return createError({ path, message: `${path} is too long` });
    }

    const isValid = isValidPhoneNumber(strValue, 'US');
    if (!isValid) {
      return createError({ path, message: `${path} does not have a correct format` });
    }

    const isPossible = isPossiblePhoneNumber(strValue, 'US');
    if (!isPossible) {
      return createError({ path, message: `${path} is not a possible number` });
    }

    const phoneNumber = libPhone(strValue, 'US');
    if (phoneNumber) {
      return true;
    }

    return createError({ path, message: errorMessage });
  });
});

const validationSchema = Yup.object({
  firstName: Yup.string()
    .max(40)
    .trim()
    .min(1, 'First Name must contain at least one character')
    .matches(/^[A-Za-z. ]+$/, 'Please enter valid First Name')
    .required('First Name is required'),
  lastName: Yup.string()
    .max(40)
    .trim()
    .min(1, 'First Name must contain at least one character')
    .matches(/^[A-Za-z. ]+$/, 'Please enter valid Last Name')
    .required('Last Name is required'),
  description: Yup.string().max(255).nullable(),
  phone: Yup.string().phoneNumber().nullable(),
});

export const ModalEditUser = ({ onCancel, onConfirm, isOpen, userId }) => {
  const [loading, setLoading] = React.useState(false);
  const [userData, setUserData] = React.useState({});
  const { userId: authUserId } = useSelector((state) => state.auth);
  const [submitError, setSubmitError] = React.useState('');

  const editUserInfo = async (values) => {
    setLoading(true);
    const phone = !values.phone ? null : libPhone(values.phone, 'US').formatInternational();

    const data = {
      firstName: values.firstName,
      lastName: values.lastName,
      phone,
      description: values.description,
    };

    try {
      const response = await UserService.updateAccountUser(userId, data);
      onConfirm(response.data.data);
    } catch (err) {
      const result = err.response.data.message;
      setSubmitError(result);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: userData.firstName || '',
      lastName: userData.lastName || '',
      description: userData.description || null,
      phone: userData.phone ? libPhone(userData.phone, 'US').formatNational() : null,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit(values) {
      editUserInfo(values);
    },
  });

  const fetchUserAccount = async () => {
    const getUser = async (filters) => {
      const response = await UserService.getUser(filters);
      setUserData(response.data.data);
    };
    getUser(userId);
  };

  React.useEffect(() => {
    fetchUserAccount();
  }, []);

  return (
    <Dialog open={isOpen} maxWidth="sm" onClose={onCancel}>
      <DialogTitle>
        <ModalHeaderIcon
          icon={UserIcon}
          text={authUserId === userId ? 'Update Your Information' : `Update ${userData.firstName} ${userData.lastName}`}
          color="warning"
        />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <InputLabel>First Name *</InputLabel>
            <TextField
              variant="outlined"
              placeholder="First Name"
              name="firstName"
              disabled={loading}
              type="text"
              {...formik.getFieldProps('firstName')}
              error={!!(formik.touched.firstName && formik.errors.firstName)}
              helperText={formik.touched.firstName && formik.errors.firstName ? formik.errors.firstName : null}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Last Name *</InputLabel>
            <TextField
              variant="outlined"
              placeholder="Last Name"
              name="lastName"
              type="text"
              disabled={loading}
              {...formik.getFieldProps('lastName')}
              error={!!(formik.touched.lastName && formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName ? formik.errors.lastName : null}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Bio</InputLabel>
            <TextField
              variant="outlined"
              placeholder="Bio"
              name="description"
              disabled={loading}
              type="text"
              {...formik.getFieldProps('description')}
              error={!!(formik.touched.description && formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description ? formik.errors.description : null}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Primary Phone</InputLabel>
            <TextField
              variant="outlined"
              placeholder="Primary Phone"
              name="phone"
              disabled={loading}
              type="text"
              {...formik.getFieldProps('phone')}
              error={!!(formik.touched.phone && formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone ? formik.errors.phone : null}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {submitError && <Typography color="error">{submitError}.</Typography>}
        </Grid>
        <LoadingOverlay loading={loading} />
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button color="primary" variant="contained" onClick={onCancel} disabled={loading} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button color="secondary" variant="contained" disabled={loading} onClick={formik.handleSubmit} fullWidth>
              Save
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

ModalEditUser.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  isOpen: PropTypes.bool,
  userId: PropTypes.string.isRequired,
};

ModalEditUser.defaultProps = {
  isOpen: false,
  onConfirm: null,
  onCancel: null,
};

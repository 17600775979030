import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  content: {
    '& .MuiTable-root': {
      borderTop: 'none',
      '& tbody tr': {
        cursor: 'default',
      },
    },
  },
});

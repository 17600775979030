import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import ExternalService from 'services/ExternalService';
import ProjectService from 'services/ProjectService';
import { formatDateRange } from './helpers';

export const getExternalContents = createAsyncThunk('exteralContents/getExternalContents', async (filterOpts) => {
  const filtersOpts = {
    ...filterOpts,
    sort_by: Object.values(filterOpts.sort_by),
    ...formatDateRange(filterOpts.date_range),
    include: ['uploader', 'project'],
    strict: true,
  };
  delete filtersOpts.teamId;
  delete filtersOpts.userId;
  delete filtersOpts.date_range;
  delete filtersOpts.activated;
  try {
    const response = await ExternalService.getExternalList(filtersOpts, 'assets');
    return response.data;
  } catch (e) {
    throw new Error('Error during request for content...');
  }
});

export const preloadFilterDatasets = createAsyncThunk(
  'exteralContents/preloadFilterDatasets',
  async () => {
    try {
      const [projects, accounts] = await Promise.all([
        await ProjectService.getProjects({ page_size: 1000 }),
        await ExternalService.getAssetsAccounts(),
      ]);

      return {
        projects: projects.data.data,
        accounts: accounts.data.data,
      };
    } catch (err) {
      throw new Error('error loading filter data');
    }
  },
  {
    condition: (_, { getState }) => getState().externalContents.panels.search.loaded === false,
  }
);

const orderByName = (array) =>
  array.sort((a, b) => {
    const nameA = a.label.toUpperCase(); // ignore upper and lowercase
    const nameB = b.label.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

const initialState = {
  error: null,
  loading: true,
  data: [],
  panels: {
    loading: true,
    details: [],
    user: [],
    search: {
      loaded: false,
      accounts: [],
      projects: [],
    },
  },
  filters: {
    page: 1,
    page_size: 10,
    sort_by: {},
    date_range: null,
  },
};

const externalContentSlice = createSlice({
  name: 'exteralContents',
  initialState,
  reducers: {
    resetExternalContents: () => initialState,
    setExternalPageFilter(state, action) {
      state.filters.page = action.payload;
    },
    setExternalSearch(state, action) {
      state.filters.search = action.payload;
    },
    setExternalSortBy(state, { payload }) {
      const { label, value } = payload;
      if (value === '' || value === null) {
        delete state.filters.sort_by[label];
      } else {
        state.filters.sort_by[label] = value;
      }
    },
    shareContent(state, action) {
      const oldItem = action.payload;
      const currentItem = state.data.find((item) => item.id === oldItem.id);
      if (!currentItem) {
        state.data = [];
        state.error = 'Content not found.';
      } else {
        currentItem.sharingEnabled = !currentItem.sharingEnabled;
      }
    },
    copyContent(state, action) {
      const oldItem = action.payload;
      const currentItem = state.data.find((item) => item.id === oldItem.id);
      if (!currentItem) {
        state.data = [];
        state.error = 'Content not found.';
      } else {
        currentItem.copyingEnabled = !currentItem.copyingEnabled;
      }
    },
    accessContent(state, action) {
      const oldItem = action.payload;
      const currentItem = state.data.find((item) => item.id === oldItem.id);
      if (!currentItem) {
        state.data = [];
        state.error = 'Content not found.';
      } else {
        currentItem.sharingEnabled = false;
        currentItem.copyingEnabled = false;
      }
    },
    revokeContent(state, action) {
      const oldItem = action.payload;
      const currentItem = state.data.find((item) => item.id === oldItem.id);
      if (!currentItem) {
        state.data = [];
        state.error = 'Content not found.';
      } else if (currentItem.accessEnabled === true) {
        currentItem.accessEnabled = false;
        currentItem.sharingEnabled = false;
        currentItem.copyingEnabled = false;
      } else {
        currentItem.accessEnabled = true;
      }
    },
    setFilterValue(state, { payload }) {
      const { param, value } = payload;
      if ((value === '' || value === null) && param !== 'date_range') {
        delete state.filters[param];
        return;
      }
      state.filters[param] = payload.value;
    },
    setDateRange(state, { payload }) {
      state.filters.date_range = payload;
    },
    setFilters(state, action) {
      const filter = { ...state.filters, ...action.payload, page: 1 };
      if (!filter?.participantLevel) delete filter?.participantLevel;
      state.filters = filter;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getExternalContents.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getExternalContents.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.assetCount = action.payload.meta.totalCount;
      state.error = null;
      state.loading = false;
    });
    builder.addCase(getExternalContents.rejected, (state) => {
      state.content = [];
      state.error = 'There was an error during the request.';
      state.loading = false;
    });
    builder.addCase(preloadFilterDatasets.pending, (state) => {
      state.panels.search.loaded = false;
    });
    builder.addCase(preloadFilterDatasets.rejected, (state) => {
      state.panels.search.users = [];
      state.panels.search.teams = [];
      state.panels.search.projects = [];
      state.panels.search.accounts = [];
      state.panels.search.loaded = false;
    });
    builder.addCase(preloadFilterDatasets.fulfilled, (state, action) => {
      const { projects, accounts } = action.payload;
      state.panels.search.loaded = true;
      let newProjects = projects.map((proj) => ({
        label: proj.name,
        value: proj.id,
      }));
      let newAccounts = accounts.map((proj) => ({
        label: proj.name,
        value: proj.id,
      }));
      newAccounts = orderByName(newAccounts);
      newProjects = orderByName(newProjects);
      state.panels.search.projects = newProjects;
      state.panels.search.accounts = newAccounts;
    });
  },
});

export const {
  setExternalPageFilter,
  contentActions,
  setExternalSearch,
  setExternalSortBy,
  accessContent,
  shareContent,
  copyContent,
  revokeContent,
  resetExternalContents,
  setFilterValue,
  setDateRange,
  setFilters,
} = externalContentSlice.actions;

export const dateRangeSelector = createSelector(
  (state) => state.externalContents.filters,
  (state) => {
    const dates = state.date_range;

    if (!dates) {
      return dates;
    }
    return dates.map((date) => (date ? new Date(date) : null));
  }
);

export default externalContentSlice.reducer;

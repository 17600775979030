import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  filterForm: {
    'padding-bottom': '10px',
  },
  inputPadding: {
    padding: '0px 10px',
  },
  buttonContainer: {
    padding: '10px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: '1px solid white',
  },
  scrollDiv: {
    width: '100%',
    overflow: 'auto',
    flex: 1,
    padding: '10px',
  },
});

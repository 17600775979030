import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Card } from 'components/Card';
import { LockIcon } from 'components/Icons';
import { ProfileSettingsContainer } from 'components/ProfileSettingsContainer/ProfileSettingsContainer';
import UserService from 'services/UserService';
import { Button, Grid, InputLabel, TextField, Typography } from '@mui/material';

const validationSchema = Yup.object({
  oldPassword: Yup.string().required('Please enter your password'),
  password: Yup.string()
    .required('Please enter your password')
    .min(8, 'Password must be at least 8 characters long')
    .matches(/^(?=.*[a-z]).{8,}$/, 'Password must contain lowercase letters')
    .matches(/^(?=.*[A-Z]).{8,}$/, 'Password must contain uppercase letters')
    .matches(/^(?=.*\d).{8,}$/, 'Password must contain numbers')
    .matches(
      /^(?=.*[#$^+=!*()@%&]).{8,}$/,
      'Password must contain at least 1 special character: #, $, ^, +, =, !, *, (, ), @, %, &'
    ),
  passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export const ProfileSecurity = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const { fname, lname, userId } = useSelector((state) => state.auth);

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      password: '',
      passwordConfirm: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit(values) {
      /* eslint-disable-next-line no-use-before-define */
      submitSettings(values);
    },
  });

  const { values, handleChange, handleBlur, handleSubmit } = formik;

  const submitSettings = async ({ oldPassword, password, passwordConfirm }) => {
    try {
      if (oldPassword === password) {
        formik.setFieldError('oldPassword', 'Your new password connot be the same as your current password');
      } else {
        await UserService.updateAccountPassword(userId, {
          oldPassword,
          password,
          passwordConfirm,
        });
        history.push('/profile');
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 400) {
        const messages = err.response.data.error.extra;
        Object.keys(messages).forEach((key) => {
          formik.setFieldError(key, messages[key][0].message);
        });
      } else {
        formik.setFieldError('oldPassword', 'You have entered an invalid password');
      }
    }
  };

  const getHeaderText = () => {
    const header = fname === null || lname === null ? 'Profile Dashboard' : `${fname} ${lname}'s Dashboard`;
    return header.toUpperCase();
  };

  return (
    <ProfileSettingsContainer title={getHeaderText()}>
      <Card>
        <Typography style={{ fontSize: '18px' }}>Change Password</Typography>
        <form onSubmit={handleSubmit} noValidate>
          <Grid container spacing={1} style={{ marginTop: '1rem' }}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <InputLabel>Current Password*</InputLabel>
              <TextField
                disabled={loading}
                type="password"
                name="oldPassword"
                icon={LockIcon}
                value={values.oldPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={formik.touched.oldPassword && formik.errors.oldPassword ? formik.errors.oldPassword : null}
                error={!!(formik.touched.oldPassword && formik.errors.oldPassword)}
                required
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ marginTop: '1rem' }}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <InputLabel>New Password*</InputLabel>
              <TextField
                disabled={loading}
                type="password"
                name="password"
                icon={LockIcon}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={formik.touched.password && formik.errors.password ? formik.errors.password : null}
                error={!!(formik.touched.password && formik.errors.password)}
                required
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ marginTop: '1rem' }}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <InputLabel>Confirm New Password*</InputLabel>
              <TextField
                disabled={loading}
                type="password"
                name="passwordConfirm"
                icon={LockIcon}
                value={values.passwordConfirm}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={
                  formik.touched.passwordConfirm && formik.errors.passwordConfirm ? formik.errors.passwordConfirm : null
                }
                error={!!(formik.touched.passwordConfirm && formik.errors.passwordConfirm)}
                required
                fullWidth
              />
            </Grid>
          </Grid>
          <Button type="submit" disabled={loading} variant="contained" color="secondary" style={{ marginTop: '2rem' }}>
            Update Settings
          </Button>
        </form>
      </Card>
    </ProfileSettingsContainer>
  );
};

import PropTypes from 'prop-types';
import React from 'react';
import { Table as MuiTable, TableContainer } from '@mui/material';
import { useStyles } from './styles';

export const Table = ({ children, className, height }) => {
  const classes = useStyles();

  return (
    <TableContainer
      component="div"
      sx={{ overflowX: 'auto', ...(height && { flex: 1, height: '100vh', overflowY: 'auto' }) }}
    >
      <MuiTable className={className || classes.table}>{children}</MuiTable>{' '}
    </TableContainer>
  );
};

Table.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  className: PropTypes.string,
  height: PropTypes.bool,
};

Table.defaultProps = {
  className: '',
  height: false,
};

/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { usePagination, useSortBy, useTable } from 'react-table';
import { AccountSettingsContainer } from 'components/AccountSettingsContainer/AccountSettingsContainer';
import { Avatar } from 'components/Avatar/Avatar';
import { ContextMenu } from 'components/ContextMenu/ContextMenu';
import { ContextMenuButton } from 'components/ContextMenuButton';
import { ContextMenuList } from 'components/ContextMenuItems/ContextMenuList';
import { DialogMessage, dialogMessageAction } from 'components/DialogMessage';
import { Loader } from 'components/Loader';
import { RoleDialog } from 'components/RoleDialog/RoleDialog';
import { useSidebar } from 'components/SidebarProvider/SidebarProvider';
import { TablePagination } from 'components/TablePagination/TablePagination';
import EnumService from 'services/EnumService';
import ProjectService from 'services/ProjectService';
import {
  fetchProjectOrgs,
  fetchProjectTeams,
  fetchProjectUsers,
  setPageFilter,
  updateParticipantRoles,
} from 'slices/projectPermissionsReducer';
import { DescriptionRoles, Roles } from 'lib/roles';
import { ViewRoleValidation } from 'components/ViewRoleValidation/ViewRoleValidation';
import { Permissions } from 'lib/permissions';
import { DialogProjectInvite, typeInviteData } from 'components/DialogProjectInvite';
import { Typography } from '@mui/material';
import { ContextMenuItem } from 'components/ContextMenuItem';
import { getInitials, getNameRole } from 'lib/generalFunctions';
import { useGlobalStyles } from 'styles';
import { GeneralTable } from 'components/GeneralTable/GeneralTable';
import { Card } from 'components/Card';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { CirclePlusIcon, LayersIcon } from 'components/Icons';
import { ModalHeaderIcon } from 'components/ModalHeaderIcon/ModalHeaderIcon';
import { externalActionType } from 'lib/external-data';
import { CopyUuidAlert } from 'components/AddUuid/CopyUuidAlert/CopyUuidAlert';
import { WidgetDivider } from 'components/WidgetDivider/WidgetDivider';
import { ModalEditUser } from 'smartComponents/ModalEditUser';
import { getUser } from 'slices/userReducer';
import { InfoPanel as UserInfoPanel } from 'pages/Admin/Users/InfoPanel';
import { fetchTeam } from 'slices/adminTeamsReducer';
import { InfoPanel as TeamInfoPanel } from 'pages/Admin/Teams/InfoPanel';
import { InfoPanel as OrgInfoPanel } from 'pages/Admin/Organizations/InfoPanel';
import { getSingleOrg } from 'slices/organizationReducer';
import { PermissionsValidator } from 'lib/permValidator';
import SplashImage from 'images/404.png';
import { Widget } from 'components/Widget';
import { WidgetSplash } from 'components/WidgetSplash/WidgetSplash';
import { TopBar } from './TopBar/TopBar';
import { SearchPanel } from './SearchPanel';

// View Team Permissions Page
const teamColumns = (globalClasses) => [
  {
    Header: 'Team Name',
    accessor: ({ name }) => (
      <div className={globalClasses.nameContainer}>
        <Avatar image="" size={30} initials={getInitials(name)} />
        <Typography>{name}</Typography>
      </div>
    ),
  },
  {
    Header: 'Role',
    accessor: (rowData) => rowData.roles && getNameRole([...rowData.roles]),
  },
  {
    Header: 'Members',
    accessor: (props) => props.userCount,
  },
  {
    Header: 'Description',
    accessor: (row) => row.description,
  },
  {
    Header: 'Organization',
    accessor: (props) => props.org?.name,
  },
];

// View Individual Permissions Page
const individualColumns = (globalClasses) => [
  {
    Header: 'Name',
    accessor: ({ firstName, lastName }) => (
      <div className={globalClasses.nameContainer}>
        <Avatar image="" size={30} initials={getInitials(`${firstName} ${lastName}`)} />
        <Typography>{`${firstName} ${lastName}`}</Typography>
      </div>
    ),
  },
  {
    Header: 'Role',
    accessor: (rowData) => rowData.roles && getNameRole([...rowData.roles]),
  },
  {
    Header: 'Organization',
    accessor: (rowData) => rowData.organizations && rowData.organizations.map((org) => org.name).join(', '),
  },
  {
    Header: 'Contributions',
    accessor: (rowData) => rowData.contributions?.length || 0,
  },
];

// View Orgs Permissions Page
const orgColumns = (globalClasses) => [
  {
    Header: 'Organization Name',
    accessor: ({ name }) => (
      <div className={globalClasses.nameContainer}>
        <Avatar image="" size={30} initials={getInitials(name)} />
        <Typography>{name}</Typography>
      </div>
    ),
  },
  {
    Header: 'Role',
    accessor: (rowData) => rowData.roles && getNameRole([...rowData.roles]),
  },
  {
    Header: 'Account',
    accessor: (props) => (props.account ? props.account?.name : ''),
  },
];

let debounceTimeout = null;
export const ParticipantCoordination = ({ project, detail }) => {
  const globalClasses = useGlobalStyles();
  const history = useHistory();
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectUserId, setSelectUserId] = React.useState(null);
  const [projectsRoles, setProjectsRoles] = React.useState([]); // Projects Roles
  const [projectTitle, setProjectsTitle] = React.useState(''); // Project Title
  const [defaultSelections, setDefaultSelections] = React.useState([]);
  const [openProjectInvite, setOpenProjectInvite] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [currentAsset, setCurrentAsset] = React.useState(null);
  const [validateRole, setValidateRole] = React.useState({ open: false, callBack: () => {} });
  const { participants, participantType, filters, participantCount, loading } = useSelector(
    (state) => state.projectPermissions
  );
  const dispatch = useDispatch();
  const params = useParams();
  const [columns, setColumns] = useState(individualColumns(globalClasses));
  const [openUuid, setOpenUuid] = useState(false);
  const [openEditUser, setOpenEditUser] = useState(false);

  const hasPermission = PermissionsValidator.validate({
    itemId: project?.id || params.projId,
    scopes: [Permissions.PROJ_USER_ADD, Permissions.PROJ_USER_GRANT],
  });

  // To get Projects
  useEffect(() => {
    const getRoles = async () => {
      const projResponse = await EnumService.getProjectRoles();
      setProjectsRoles(projResponse.data.data);
      let projTitleResponse = project;
      if (!project?.id) {
        projTitleResponse = await ProjectService.getProject(params.projId);
        projTitleResponse = projTitleResponse.data.data;
      }
      setProjectsTitle(projTitleResponse.name);
    };
    getRoles();
  }, []);

  // To get Projects
  useEffect(() => {
    setColumns(
      () =>
        // eslint-disable-next-line no-nested-ternary
        participantType === 'teams'
          ? teamColumns(globalClasses)
          : participantType === 'orgs'
          ? orgColumns(globalClasses)
          : individualColumns(globalClasses),
      []
    );
  }, [participantType]);

  const table = useTable(
    {
      columns,
      data: participants,
      initialState: {
        pageIndex: filters.page - 1,
        pageSize: filters.page_size,
      },
      pageCount: Math.ceil(participantCount / filters.page_size),
      totalCount: participantCount,
      pageLength: participants.length,
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  const {
    state: { pageIndex, pageSize },
  } = table;

  const { displayPanel, setHeaderPanels, setFullWidth } = useSidebar({
    open: false,
    config: {
      search: {
        show: true,
        component: SearchPanel,
        background: detail,
      },
      header: [],
      actions: [],
    },
  });

  const loadData = () => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      const auxFilters = { ...filters };
      if (filters.viewOption === 'individual') {
        auxFilters.sort_by = { firstName: filters.sort_by.name, ...filters.sort_by };
        delete auxFilters.sort_by.name;
      }
      const newFilters = {
        ...auxFilters,
        sort_by: Object.values(auxFilters.sort_by),
        projectId: project?.id || params.projId,
      };

      if (filters.viewOption === 'teams') {
        dispatch(fetchProjectTeams({ ...newFilters, participantCoordinator: true }));
      } else if (filters.viewOption === 'individual') {
        dispatch(fetchProjectUsers(newFilters));
      } else if (filters.viewOption === 'orgs') {
        dispatch(fetchProjectOrgs(newFilters));
      }
    }, 150);
  };

  useEffect(() => {
    displayPanel('');
    loadData();
  }, [filters]);

  useEffect(() => {
    displayPanel('');
    dispatch(setPageFilter(pageIndex + 1));
  }, [pageIndex, pageSize]);

  const handleOpenProjectInviteUser = () => {
    setOpenProjectInvite(true);
  };

  const handleProjectInviteClose = (refreshFlag) => {
    setOpenProjectInvite(false);
    if (refreshFlag) {
      loadData();
    }
  };

  const openParticipantModal = () => {
    handleOpenProjectInviteUser();
  };

  const viewOptions = [
    { value: 'teams', label: 'Teams' },
    { value: 'orgs', label: 'Organizations' },
    { value: 'individual', label: 'Individuals' },
  ];

  const sortProjectRoles = (arr) => {
    if (arr?.length > 2) {
      if (arr.length <= 1) {
        let k = 1 - arr.length + 1;
        // eslint-disable-next-line no-plusplus
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(1, 0, arr.splice(2, 1)[0]);
    }
    const descriptions = Object.values(DescriptionRoles);
    arr.forEach((element, i) => {
      element.info = descriptions[i] || '';
    });
    return arr;
  };

  const handleOnOpen = (row) => {
    setIsOpen(true);
    const userRoles = row.original.roles;
    const holder = projectsRoles.map((role) => ({
      ...role,
      isChecked: !!userRoles.find((user) => role.id === user.roleId),
    }));
    setDefaultSelections(sortProjectRoles(holder));
    setSelectUserId(row.original.id);
  };

  const handleOnSave = (newRoles) => {
    const updateProject = async () => {
      setIsLoading(true);
      const response = await ProjectService.updateUserOrTeamRoles(
        project?.id || params.projId,
        selectUserId,
        newRoles.map((role) => role.id)
      );

      // update state with new roles
      dispatch(
        updateParticipantRoles({
          partId: selectUserId,
          roles: response.data.data,
        })
      );
      setIsLoading(false);
      setIsOpen(false);
    };
    updateProject();
  };

  const handleDeleteConfirm = async () => {
    const updateProject = async () => {
      await ProjectService.updateUserOrTeamRoles(project?.id || params.projId, currentAsset.id, []);
      setOpenProjectInvite(false);
    };
    updateProject();

    let timeout;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      loadData();
    }, 500);

    setOpenDelete(false);
  };

  const handleDeleteCancel = () => {
    setOpenDelete(false);
  };

  // confirm edit user info
  const handleEditUserConfirm = (response) => {
    const user = participants.find((item) => item.id === response.id);
    // update state with new data
    dispatch(
      updateParticipantRoles({
        partId: user.id,
        roles: user.roles,
        user: { ...user, firstName: response.firstName, lastName: response.lastName },
      })
    );
    setOpenEditUser(false);
  };

  // open participan detail
  const handleRowClick = async (rowData) => {
    // dispatch redux action to load user data
    // set sidebar active panel to user info and open if necessary
    if (filters.viewOption === 'teams') {
      dispatch(fetchTeam(rowData.original.id));
    } else if (filters.viewOption === 'individual') {
      dispatch(getUser(rowData.original.id));
    } else if (filters.viewOption === 'orgs') {
      dispatch(getSingleOrg({ orgId: rowData.original.id, orgRole: Roles.ORG_ADMIN }));
    }
    setHeaderPanels([
      {
        name: 'info',
        component:
          filters.viewOption === 'teams'
            ? TeamInfoPanel
            : filters.viewOption === 'individual'
            ? UserInfoPanel
            : OrgInfoPanel,
      },
    ]);
    setFullWidth(false);
    displayPanel('info');
  };

  const ItemActions = ({ row }) => (
    <ContextMenu>
      <ContextMenuButton />
      <ContextMenuList position="bottom-right">
        {filters.viewOption !== 'orgs' && (
          <ContextMenuItem
            onClick={() =>
              setValidateRole({
                open: true,
                action: [filters.viewOption === 'individual' ? Permissions.PROJ_USER_GRANT : Permissions.TEAM_MODIFY],
                data: row.original,
                callBack: () => setOpenUuid({ id: row.original.id, type: externalActionType.COPY }),
              })
            }
          >
            Copy UUID
          </ContextMenuItem>
        )}
        {filters.viewOption === 'individual' && (
          <ContextMenuItem
            onClick={() =>
              setValidateRole({
                open: true,
                action: [Permissions.ACCOUNT_USER_MODIFY],
                callBack: () => {
                  setOpenEditUser(row.original);
                },
              })
            }
          >
            Edit User
          </ContextMenuItem>
        )}
        <ContextMenuItem
          onClick={() =>
            setValidateRole({
              open: true,
              action: [Permissions.PROJ_USER_ADD, Permissions.PROJ_USER_GRANT],
              callBack: () => {
                handleOnOpen(row);
                setCurrentAsset(row.original);
              },
            })
          }
        >
          Edit Project Role
        </ContextMenuItem>
        <WidgetDivider />
        <ContextMenuItem
          onClick={() =>
            setValidateRole({
              open: true,
              action: [Permissions.PROJ_USER_ADD, Permissions.PROJ_USER_GRANT],
              callBack: () => {
                setOpenDelete(true);
                setCurrentAsset(row.original);
              },
            })
          }
        >
          Remove from Project
        </ContextMenuItem>
      </ContextMenuList>
    </ContextMenu>
  );

  ItemActions.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    row: PropTypes.object.isRequired,
  };

  const Content = () => (
    <>
      <GeneralTable table={table} rowComponent={ItemActions} selectRow={false} handleRowClick={handleRowClick} />
      <TablePagination table={table} />
    </>
  );

  if (!hasPermission && detail) {
    return (
      <Card noPadding>
        <div style={{ display: 'grid', height: '100%', placeItems: 'center', overflowY: 'auto' }}>
          <div>
            <Typography variant="h1" align="center">
              401
            </Typography>
            <Typography mt={0.5} align="center">
              Oops. Page not allowed.
            </Typography>
            <Widget>
              <WidgetSplash alt="Page not allowed." image={SplashImage} button={false} />
            </Widget>
          </div>
        </div>
      </Card>
    );
  }
  if (!hasPermission && !detail) {
    history.push(`/401`);
  }

  if (loading && participants.length === 0) {
    return (
      <Card noPadding>
        <Loader loading={loading} height={250} />
      </Card>
    );
  }

  return (
    <>
      {detail ? (
        <Card noPadding>
          <TopBar
            viewOption={filters.viewOption}
            viewOptions={viewOptions}
            detail={detail}
            openParticipantModal={() => openParticipantModal()}
            projectId={project?.id || params.projId}
          />
          <Content />
        </Card>
      ) : (
        <AccountSettingsContainer
          actions={[
            {
              icon: CirclePlusIcon,
              text: 'Add New',
              onClick: () =>
                setValidateRole({
                  open: true,
                  action: [Permissions.PROJ_USER_ADD, Permissions.PROJ_USER_GRANT],
                  callBack: () => openParticipantModal(),
                }),
            },
          ]}
          title={
            <>
              <button type="button" onClick={() => history.goBack()}>
                PROJECTS{' > '}
              </button>
              {projectTitle}
            </>
          }
          showSidebar
          hideMenu
        >
          <Card noPadding>
            <TopBar
              viewOption={filters.viewOption}
              viewOptions={viewOptions}
              detail={detail}
              openParticipantModal={() => openParticipantModal()}
              projectId={project?.id || params.projId}
            />
            <Content />
          </Card>
        </AccountSettingsContainer>
      )}
      {openProjectInvite && (
        <DialogProjectInvite
          isOpen={openProjectInvite}
          handleClose={handleProjectInviteClose}
          projectId={project?.id || params.projId}
          type={
            // eslint-disable-next-line no-nested-ternary
            participantType === 'individual'
              ? typeInviteData.USER
              : participantType === 'teams'
              ? typeInviteData.TEAM
              : typeInviteData.ORG
          }
        />
      )}
      {openDelete && (
        <DialogMessage
          title={`Delete ${
            participantType === 'individual' ? `${currentAsset.firstName} ${currentAsset.lastName}` : currentAsset.name
          }`}
          content={`Are you sure you want to delete this ${
            participantType === 'individual' ? `${currentAsset.firstName} ${currentAsset.lastName}` : currentAsset.name
          } from this ${projectTitle}?`}
          isOpen={openDelete}
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
          action={dialogMessageAction.delete}
        />
      )}
      {isOpen && (
        <RoleDialog
          isOpen={isOpen}
          title={
            <ModalHeaderIcon
              icon={LayersIcon}
              text={`Edit ${
                participantType === 'individual'
                  ? `${currentAsset.firstName} ${currentAsset.lastName}`
                  : currentAsset.name
              } permissions to ${projectTitle}`}
            />
          }
          roles={defaultSelections}
          onSave={handleOnSave}
          radioButton
          isLoading={isLoading}
        />
      )}
      {openUuid?.id && openUuid?.type === externalActionType.COPY && (
        <CopyUuidAlert id={openUuid.id} handleClose={() => setOpenUuid(false)} />
      )}
      {openEditUser?.id && (
        <ModalEditUser
          isOpen
          onConfirm={handleEditUserConfirm}
          onCancel={() => setOpenEditUser(false)}
          userId={openEditUser.id}
        />
      )}
      {validateRole.open && (
        <ViewRoleValidation
          action={validateRole.action}
          data={{ id: project?.id || params.projId }}
          callBack={validateRole.callBack}
          handleClose={() => setValidateRole({ open: false, callBack: () => {} })}
        />
      )}
    </>
  );
};

ParticipantCoordination.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  project: PropTypes.object,
  detail: PropTypes.bool,
};

ParticipantCoordination.defaultProps = {
  project: null,
  detail: false,
};

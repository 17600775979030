import { Button, Grid, Tab, Tabs, Typography } from '@mui/material';
import { Loader } from 'components/Loader';
import React, { useEffect, useState } from 'react';
import InspectionService from 'services/InspectionService';
import { ObservationMap } from 'components/Observation/ObservationMap';
import { CloseIcon } from 'components/Icons';
import { useStyles as projectUseStyles } from '../../../../styles';
import { useStyles } from './styles';

export const ProjectAssetInspFindingSideBar = ({ findingId, fixed, handleClose }) => {
  const classes = useStyles({ open: findingId, fixed: !!fixed });
  const projectClasses = projectUseStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [dataUrl, setDataUrl] = useState(null);
  const [finding, setFinding] = useState(null);
  const [tab, setTabValue] = useState(0);

  const getFindingData = async () => {
    try {
      setIsLoading(true);
      const result = await InspectionService.getFinding(findingId, findingId);
      const auxFinding = { ...result?.data?.data };
      auxFinding.responses = JSON.parse(auxFinding.responses).map((response) => {
        const object = { ...response };
        JSON.parse(auxFinding.projectAssetInspection?.inspection?.questions)?.forEach((question) => {
          if (question.id === response.id) {
            object.name = question.name;
            object.description = question.description;
            object.deletedAt = question.deletedAt;
          }
        });
        return object;
      });
      setFinding(auxFinding);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  // ----------------------- get finding image
  const fetchImage = async () => {
    try {
      const result = await InspectionService.getFindingImage(findingId, findingId);
      setDataUrl(URL.createObjectURL(result.data));
    } catch (err) {
      // do something with err
    }
  };

  useEffect(() => {
    if (findingId) {
      getFindingData();
      fetchImage();
    }
  }, [findingId]);

  return (
    <div className={classes.bar}>
      {isLoading ? (
        <Loader height={10} loading size={32} />
      ) : (
        finding && (
          <div className={projectClasses.containerDetail}>
            <div className={`${projectClasses.topButton} ${classes.topButton}`}>
              <Typography variant="h1">Finding Detail</Typography>
              <div className={projectClasses.closeButtonGrapper}>
                <Button
                  onClick={() => handleClose()}
                  color="primary"
                  variant="contained"
                  size="small"
                  sx={{ padding: '8px 14px' }}
                >
                  <CloseIcon size={20} /> Close
                </Button>
              </div>
            </div>
            <Grid container spacing={0} className={`${projectClasses.mapDetailContainer} mapDetailContainer`}>
              <Grid item lg={8} md={8} sm={12} xs={12} className={projectClasses.fullHeight}>
                <img src={dataUrl} alt="" className={projectClasses.imageContent} />
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12} className={projectClasses.fullHeight}>
                <ObservationMap
                  observation={finding}
                  point={finding.spatialPoint}
                  enableEdit={false}
                  fullHeight
                  findingIcon
                />
              </Grid>
            </Grid>
            <div className={projectClasses.tabsDetail}>
              <Tabs value={tab} className={projectClasses.headerTabs}>
                <Tab label="Overview" onClick={() => setTabValue(0)} />
              </Tabs>
              <div className={projectClasses.bodyDetail}>
                <Typography variant="h4">{finding.name}</Typography>
                <Typography mb={0.5}>Finding description: {finding.description}</Typography>
                <Typography mt={0.5} variant="h5">
                  Source Name: {finding.projectAssetInspection.projectAsset?.name}
                </Typography>
                {finding.responses.length && (
                  <Grid container spacing={0}>
                    {finding.responses
                      .filter((item) => !item.deletedAt)
                      .map((response) => (
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                          <Typography mt={0.5}>
                            {response.name}: {response.response}
                          </Typography>
                        </Grid>
                      ))}
                  </Grid>
                )}
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

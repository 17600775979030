import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useSortBy, useTable } from 'react-table';
import { Grid } from '@mui/material';
import { GeneralTable } from 'components/GeneralTable/GeneralTable';
import { WidgetSplash } from 'components/WidgetSplash/WidgetSplash';
import SplashImage from 'images/ContentSplash.png';
import { ProjectObservationDetail } from './ProjectObservationDetail';
import { ProjectObservationsFilters } from './ProjectObservationsFilters';
import { useStyles } from '../styles';

const tableColumns = () => [
  {
    Header: 'Point Title',
    accessor: (rowData) => rowData.name,
  },
  {
    Header: 'Content Source',
    accessor: (rowData) => rowData?.asset?.name,
  },
];

// eslint-disable-next-line no-unused-vars
export const ProjectObservations = ({ project }) => {
  const classes = useStyles();
  const { projectObservationsFilter, projectObservations, isLoading } = useSelector((state) => state.assetObservations);
  const columns = React.useMemo(() => tableColumns(), []);
  const [observationData, setObservationData] = useState(null);
  const [point, setPoint] = useState(null);

  const table = useTable(
    {
      columns,
      data: projectObservationsFilter,
    },
    useSortBy
  );

  // ----------------------------- open observation modal
  const handleViewObservationOpen = (observation) => {
    setPoint(null);
    if (observation?.spatialPoint?.id) {
      setPoint(observation?.spatialPoint);
    } else {
      const { sequence } = observation;
      if (sequence?.spatialPoints) {
        let auxPoint = null;
        if (sequence.asset.type === 'PHOTO') auxPoint = { ...sequence.spatialPoints[0] };
        else auxPoint = sequence.spatialPoints.find((item) => +item.time === +observation.time - 1);
        setPoint(auxPoint);
      }
    }
    setObservationData({ ...observation, projectId: project.id });
  };

  useEffect(() => {
    if (projectObservationsFilter?.length) {
      handleViewObservationOpen(projectObservationsFilter[0]);
    }
  }, [projectObservationsFilter]);

  return (
    <Grid container spacing={0} className={classes.fullHeight}>
      {!projectObservations?.length ? (
        <WidgetSplash alt="Project Observations Splash" title="There are no observations" image={SplashImage} />
      ) : (
        <>
          <Grid item lg={4} md={4} sm={12} xs={12} className={classes.listContainer}>
            <ProjectObservationsFilters />
            <div className={classes.tableContainer}>
              <GeneralTable
                table={table}
                handleRowClick={(row) => handleViewObservationOpen(row.original)}
                selectRow={false}
                autoHeight
                selectedRow={observationData?.id}
              />
            </div>
          </Grid>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            {observationData?.id && (
              <ProjectObservationDetail
                observation={observationData}
                point={point}
                observations={projectObservations}
                isObservationLoading={isLoading}
              />
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};

ProjectObservations.propTypes = {
  project: PropTypes.object,
};

ProjectObservations.defaultProps = {
  project: null,
};

import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  calendarContent: {
    '& .rdrDateRangePickerWrapper': {
      width: '100%',
    },
    '& .rdrDefinedRangesWrapper, & .rdrDateDisplayWrapper': {
      display: 'none',
    },
    '& .rdrCalendarWrapper': {
      backgroundColor: 'transparent!important',
    },
    '& .rdrDay .rdrDayNumber span': {
      color: theme.palette.primary[400],
    },
    '& .rdrDayPassive .rdrDayNumber span': {
      color: theme.palette.primary[800],
    },
    '& .rdrMonthName': {
      color: theme.palette.primary[100],
      textAlign: 'center',
    },
    '& .rdrMonthAndYearPickers select': {
      color: theme.palette.primary[800],
    },
    '& .rdrDay.rdrDayToday > span': {
      borderRadius: '20px',
      border: '1px solid #155EEF',
    },
    // '& .rdrDay.rdrDayHovered > span': {
    //   borderRadius: '20px',
    //   backgroundColor: theme.palette.secondary[700],
    //   border: `1px solid ${theme.palette.secondary[700]}`,
    // },
    '& .rdrDayToday .rdrDayNumber span:after': {
      background: 'transparent!important',
    },
    '& .rdrStartEdge, & .rdrEndEdge': {
      backgroundColor: theme.palette.secondary[700],
    },
    '& .rdrInRange': {
      backgroundColor: theme.palette.primary[1200],
      color: theme.palette.secondary[900],
    },
    '& .rdrNextPrevButton.rdrPprevButton, & .rdrNextPrevButton.rdrNextButton': {
      background: 'transparent!important',
    },
    '& .rdrNextPrevButton.rdrNextButton': {
      '& i': {
        borderColor: `transparent transparent transparent ${theme.palette.primary[400]}`,
      },
    },
    '& .rdrNextPrevButton.rdrPprevButton': {
      '& i': {
        borderColor: `transparent ${theme.palette.primary[400]} transparent transparent`,
      },
    },
    '& .rdrMonths.rdrMonthsHorizontal > .rdrMonth': {
      borderBottom: `1px solid${theme.palette.primary[300]}`,
    },
    '& .rdrMonths.rdrMonthsHorizontal > .rdrMonth:first-child': {
      borderRight: `1px solid${theme.palette.primary[300]}`,
    },
    '& .rdrMonthAndYearWrapper': {
      borderBottom: `1px solid${theme.palette.primary[300]}`,
    },
  },
});

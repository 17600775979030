import { WidgetSplash } from 'components/WidgetSplash/WidgetSplash';
import SplashImage from 'images/error_500.png';
import React from 'react';
import { useHistory } from 'react-router';
import { Grid, Typography, Button } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

export const ServerError = () => {
  const history = useHistory();
  return (
    <Grid container spacing={1} style={{ height: '100%', backgroundColor: '#303132', marginTop: 5, marginLeft: 5 }}>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ padding: 80, marginTop: 200 }}>
        <Typography variant="h1" component="h1">
          We seem to have gremlins...
        </Typography>
        <Typography
          variant="h4"
          component="h2"
          style={{ color: '#ccc', fontWeight: '200', marginTop: 20, marginBottom: 20, lineHeight: 1.5 }}
        >
          Our servers have timed out. Please go back and give it another try. If all else fails, please contact us at
          support@cartovid.com
        </Typography>
        <Button variant="contained" color="secondary" onClick={() => history.goBack()} style={{ marginRight: 20 }}>
          <KeyboardBackspaceIcon /> Go Back
        </Button>
        <Button variant="contained" onClick={() => history.goBack()}>
          Take me home
        </Button>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ marginTop: 200 }}>
        <WidgetSplash alt="Page not found Splash" image={SplashImage} button={false} />
      </Grid>
    </Grid>
  );
};

import PropTypes from 'prop-types';
import React from 'react';

import { Svg } from './Svg';

export const PictureIcon = ({ size }) => (
  <Svg viewBox="0 0 24 24" size={size}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.636 4.395H10.91v6.592h8.727V4.395zM21.818 0H2.182C.982 0 0 .989 0 2.197V17.58c0 1.208.982 2.175 2.182 2.175h19.636c1.2 0 2.182-.967 2.182-2.175V2.197A2.196 2.196 0 0021.818 0zm0 17.59H2.182V2.175h19.636V17.59z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.636 4.395H10.91v6.592h8.727V4.395zM21.818 0H2.182C.982 0 0 .989 0 2.197V17.58c0 1.208.982 2.175 2.182 2.175h19.636c1.2 0 2.182-.967 2.182-2.175V2.197A2.196 2.196 0 0021.818 0zm0 17.59H2.182V2.175h19.636V17.59z"
      fill="currentColor"
    />
  </Svg>
);

PictureIcon.propTypes = {
  size: PropTypes.number,
};

PictureIcon.defaultProps = {
  size: 24,
};

import { SearchPanelBody, searchPanelSection } from 'components/Inspections/SearchPanelBody/SearchPanelBody';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  dateRangeSelector,
  preloadFilterDatasets,
  setFilterValue,
  setFilters,
} from 'slices/projectAssetActiveInspectionReducer';

export const SearchPanel = () => {
  const dispatch = useDispatch();
  const nameSort = useSelector((state) => state.projectAssetActiveInspections.filters.sort_by.name || '');
  const dateSort = useSelector((state) => state.projectAssetActiveInspections.filters.sort_by.createdAt || '');
  const inspectionIds = useSelector((state) => state.projectAssetActiveInspections.filters.inspectionIds || '');
  const assigneeId = useSelector((state) => state.projectAssetActiveInspections.filters.assigneeId || '');
  const projectIds = useSelector((state) => state.projectAssetActiveInspections.filters.projectIds || '');
  const pastIds = useSelector((state) => state.projectAssetActiveInspections.filters.pastIds || '');
  const type = useSelector((state) => state.projectAssetActiveInspections.filters.type || '');
  const dateRange = useSelector(dateRangeSelector);
  const { assets, inspections, users, loaded } = useSelector(
    (state) => state.projectAssetActiveInspections.panels.search
  );
  const { projectAsset } = useSelector((state) => state.profileProjectAssets);

  useEffect(() => {
    dispatch(preloadFilterDatasets(projectAsset?.projId));
  }, []);

  return (
    <SearchPanelBody
      loaded={loaded}
      assets={assets}
      inspections={inspections}
      users={users}
      filters={{
        sort_by: {
          name: nameSort,
          createdAt: dateSort,
        },
        inspectionIds,
        projectIds,
        assigneeId,
        pastIds,
        type,
        date_range: dateRange,
      }}
      setFilterValue={setFilterValue}
      setFilters={setFilters}
      section={searchPanelSection.PROJECT}
    />
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import HTMLRenderer from 'react-html-renderer';

export const ActivityOptions = ({ selectedActivityOption }) => (
  <div>
    <br />
    <span className={{ backgroundColor: 'gray' }} id="markdown-gis-content">
      <div style={{ textAlign: 'left', marginLeft: '-40px' }}>
        <HTMLRenderer html={selectedActivityOption} />
      </div>
    </span>
  </div>
);

ActivityOptions.propTypes = {
  selectedActivityOption: PropTypes.func.isRequired,
};

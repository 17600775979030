import PropTypes from 'prop-types';
import styled from 'styled-components';

export const Typography = styled.div((props) => ({
  ...props.theme.typography[props.variant],
  marginBottom: props.theme.spacing(props.spacing),
}));

Typography.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'subtitle',
    'body',
    'bold',
    'small',
    'buttonText',
    'buttonTextSmall',
    'linkText',
  ]),
  as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'p', 'span', 'div', 'label']),
  spacing: PropTypes.number,
};

Typography.defaultProps = {
  variant: 'body',
  as: 'div',
  spacing: 0,
};

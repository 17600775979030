import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  thumbnailRow: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    '& div': {
      display: 'flex',
      flexDirection: 'column',
      width: '23%',
      margin: theme.spacing(0.2),
      borderRadius: '5px',
      overflow: 'hidden',
    },
    '& div > div': {
      width: '100%',
      backgroundColor: '#3f4751',
      margin: 0,
      padding: `${theme.spacing(0.2)} ${theme.spacing(0.7)}`,
      borderRadius: 0,
    },
  },
});

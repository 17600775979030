import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import { CloseIcon, EyeIcon } from 'components/Icons';
import { ObservationMap } from 'components/Observation/ObservationMap';
import { Loader } from 'components/Loader';
import moment from 'moment';
import { usePagination, useSortBy, useTable } from 'react-table';
import { GeneralTable } from 'components/GeneralTable/GeneralTable';
import InspectionService from 'services/InspectionService';
import { SidebarContext } from 'components/SidebarProvider/SidebarProvider';
import { WidgetSplash } from 'components/WidgetSplash/WidgetSplash';
import SplashImage from 'images/UsersSplash.png';
import { getFindings, resetFinding, setFinding } from 'slices/findingReducer';
import { SaveProjectAssetInspectionFinding } from '../SaveProjectAssetInspectionFinding/SaveProjectAssetInspectionFinding';
import { useStyles } from './styles';
import { useStyles as projectUseStyles } from '../../../../styles';
import { DeleteFinding } from '../SaveProjectAssetInspectionFinding/DeleteFinding';
import { FindingSearch } from './FindingSearch';
import { SidebarDetailContext } from 'components/SidebarProviderDetail/SidebarProviderDetail';

const tableColumns = () => [
  {
    Header: 'Date',
    id: 'createdAt',
    accessor: (rowData) => {
      const { createdAt } = rowData;
      return createdAt ? moment(createdAt).format('MM/DD/YY') : 'date not found';
    },
  },
  {
    Header: 'Findings',
    accessor: (rowData) => (
      <>
        <Typography>{rowData?.name}</Typography>
        <Typography>Description of finding. {rowData?.description}</Typography>
      </>
    ),
  },
  {
    Header: 'Created By',
    accessor: (rowData) => {
      const { uploader } = rowData;
      return uploader ? `${uploader?.firstName} ${uploader?.lastName}` : 'user not found';
    },
  },
];

let debounceTimeout = null;

export const ProjectAssetInspectionFindings = () => {
  const dispatch = useDispatch();
  const { setActivePanel, setFullWidth, setHeaderPanels } = useContext(SidebarContext);
  const { activePanel, setActivePanel: setActiveDetailPanel } = useContext(SidebarDetailContext);
  const { data, filters, loading, totalCount, projAssetInspection } = useSelector((state) => state.findings);
  const classes = useStyles({ open: !!projAssetInspection?.id });
  const projectClasses = projectUseStyles();
  const columns = React.useMemo(() => tableColumns(classes), []);
  const [dataUrl, setDataUrl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [point, setPoint] = useState(null);

  const table = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: filters.page - 1,
        pageSize: filters.page_size,
      },
      pageCount: Math.ceil(totalCount / filters.page_size),
      totalCount,
      pageLength: data.length,
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  // ----------------------- get finding image
  const fetchImage = async (auxFinding) => {
    try {
      InspectionService.getFindingImage(auxFinding.paiId, auxFinding.id)
        .then((result) => {
          setDataUrl(URL.createObjectURL(result.data));
        })
        .catch((err) => console.error('ERROR:', err));
    } catch (err) {
      // do something with err
    }
  };

  const updateSelected = (selected) => {
    fetchImage(selected);
    setSelectedRow(selected);
    setPoint({ latitude: selected.point?.latitude, longitude: selected.point?.longitude });
  };

  const handleViewFindingOpen = (e, row) => {
    e.stopPropagation();
    setHeaderPanels([
      {
        name: 'inspection',
        component: SaveProjectAssetInspectionFinding,
        data: { action: 'edit' },
      },
    ]);
    setFullWidth(true);
    setActivePanel('inspection');
    dispatch(setFinding(row));
  };

  const handleRowClick = (row) => {
    updateSelected(row.original);
  };

  useEffect(() => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      if (projAssetInspection?.id) dispatch(getFindings({ ...filters, paiId: projAssetInspection?.id }));
    }, 150);
  }, [filters]);

  useEffect(() => {
    if (data.length) {
      updateSelected(data[0]);
    }
  }, [data]);

  useEffect(() => {
    if (projAssetInspection?.id) dispatch(getFindings({ ...filters, paiId: projAssetInspection?.id }));
  }, [projAssetInspection?.id]);

  useEffect(
    () => () => {
      dispatch(resetFinding());
    },
    []
  );

  const FindingActions = ({ row }) => (
    <div className={classes.findingIcons}>
      <IconButton onClick={(e) => handleViewFindingOpen(e, row.original)}>
        <EyeIcon size={20} />
      </IconButton>
      <DeleteFinding finding={row.original} />
    </div>
  );

  FindingActions.propTypes = {
    row: PropTypes.object.isRequired,
  };

  const EmptyData = () => (
    <WidgetSplash alt="Findings Splash" title="There are not inspection findings." image={SplashImage} />
  );

  const Content = () => (
    <>
      {!data.length && filters.search ? (
        EmptyData()
      ) : (
        <Grid container spacing={0} className={`${projectClasses.mapDetailContainer} mapDetailContainer`}>
          <Grid item lg={8} md={8} sm={12} xs={12} className={projectClasses.fullHeight}>
            <img src={dataUrl} alt="" className={projectClasses.imageContent} />
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12} className={projectClasses.fullHeight}>
            <ObservationMap
              observation={selectedRow?.id && selectedRow}
              point={point}
              enableEdit={false}
              fullHeight
              findingIcon
              // imgHandler={(view) => setMap(view)}
            />
          </Grid>
        </Grid>
      )}
      <div className={projectClasses.tabsDetail}>
        <div className={classes.filter}>
          <Button color="primary" variant="contained" className="title">
            Findings
          </Button>
          <FindingSearch />
        </div>
        {loading && filters.search ? (
          <Loader height={50} loading size={32} />
        ) : (
          <div
            className={`${projectClasses.bodyDetail} ${classes.table}`}
            style={{ paddingLeft: 0, paddingRigth: 0, maxHeight: '30vh' }}
          >
            <GeneralTable
              table={table}
              rowComponent={FindingActions}
              selectRow={false}
              autoHeight
              handleRowClick={handleRowClick}
              selectedRow={selectedRow?.id}
            />
          </div>
        )}
      </div>
    </>
  );

  if (loading && !filters.search) {
    return (
      <div className={classes.inspectionDetail}>
        <Loader height={50} loading size={32} />
      </div>
    );
  }

  const onClose = () => {
    dispatch(resetFinding());
    if (activePanel === 'inspectionFindigs') {
      setActiveDetailPanel('');
    }
  };

  return (
    <div className={classes.inspectionDetail}>
      {projAssetInspection?.id && (
        <div className={projectClasses.containerDetail}>
          <div className={projectClasses.topButton}>
            <div className={projectClasses.obsCloseButtonGrapper} style={{ alignItems: 'center' }}>
              <Button onClick={onClose} color="primary" variant="contained" size="small" sx={{ padding: '8px 14px' }}>
                <CloseIcon size={18} />
              </Button>
              <Typography variant="h5">
                Findings: {projAssetInspection?.inspection?.name} / {projAssetInspection?.projectAsset?.name}
              </Typography>
            </div>
          </div>
          {!data.length && !filters.search ? EmptyData() : Content()}
        </div>
      )}
    </div>
  );
};

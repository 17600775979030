import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit';
import InspectionService from 'services/InspectionService';
import { formatDateRange, formatSortBy } from './helpers';

export const getProjectInspections = createAsyncThunk('projectInspections/getProjectInspections', async (filters) => {
  const newFilters = {
    strict: true,
    ...filters,
    ...formatSortBy(filters.sort_by),
    ...formatDateRange(filters.date_range),
  };
  delete newFilters.date_range;
  delete newFilters.activated;
  try {
    const response = await InspectionService.getInspections(newFilters);
    return response.data;
  } catch (e) {
    throw new Error('Error during request for project asset inspections...');
  }
});

export const preloadFilterDatasets = createAsyncThunk(
  'projectInspections/preloadFilterDatasets',
  async (arg) => {
    try {
      const users = await InspectionService.getInspectionUsers({ projectId: arg });
      return {
        users: users.data,
      };
    } catch (err) {
      throw new Error('error loading filter data');
    }
  },
  {
    condition: (_, { getState }) => getState().projectInspections.panels.search.loaded === false,
  }
);

const orderByName = (array) =>
  array.sort((a, b) => {
    const nameA = a.label.toUpperCase(); // ignore upper and lowercase
    const nameB = b.label.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

const initialState = {
  error: null,
  loading: false,
  data: [],
  totalCount: 0,
  panels: {
    project: null,
    loading: true,
    detail: {},
    search: {
      loaded: false,
      users: [],
    },
  },
  filters: {
    page: 1,
    page_size: 10,
    sort_by: {},
    search: '',
    date_range: null,
  },
};

const projectInspectionsSlice = createSlice({
  name: 'projectInspections',
  initialState,
  reducers: {
    resetInspections: () => initialState,
    setProject(state, action) {
      state.panels.project = action.payload;
    },
    setPageFilter(state, action) {
      state.filters.page = action.payload;
    },
    setSearch(state, action) {
      state.filters.search = action.payload;
    },
    setAcctId(state, action) {
      state.filters.acctId = action.payload.value;
    },
    setOrganizationId(state, action) {
      state.filters.organizationId = action.payload.value;
    },
    setSortBy(state, { payload }) {
      const { label, value } = payload;

      if (value === '' || value === null) {
        delete state.filters.sort_by[label];
      } else {
        state.filters.sort_by[label] = value;
      }
    },
    setFilterValue(state, { payload }) {
      const { param, value } = payload;
      if ((value === '' || value === null) && param !== 'date_range') {
        delete state.filters[param];
        return;
      }
      state.filters[param] = value;
    },
    setDateRange(state, { payload }) {
      state.filters.date_range = payload;
    },
    setFilters(state, action) {
      const filter = { ...state.filters, ...action.payload, page: 1 };
      if (!filter?.participantLevel) delete filter?.participantLevel;
      state.filters = filter;
    },
  },
  extraReducers: (builder) => {
    // get inspection list
    builder.addCase(getProjectInspections.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProjectInspections.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.totalCount = action.payload.meta.totalCount;
      state.error = null;
      state.loading = false;
    });

    builder.addCase(getProjectInspections.rejected, (state) => {
      state.data = [];
      state.error = 'There was an error during the request.';
      state.loading = false;
    });

    builder.addCase(preloadFilterDatasets.pending, (state) => {
      state.panels.search.users = [];
      state.panels.search.loaded = false;
    });
    builder.addCase(preloadFilterDatasets.rejected, (state) => {
      state.panels.search.users = [];
      state.panels.search.loaded = false;
    });
    builder.addCase(preloadFilterDatasets.fulfilled, (state, { payload }) => {
      const { users } = payload;
      state.panels.search.loaded = true;
      const newUsers = users.map((user) => ({
        label: `${user?.firstName} ${user?.lastName}`,
        value: user.id,
      }));
      state.panels.search.users = orderByName(newUsers);
    });
  },
});

export const {
  setSearch,
  setProject,
  setAcctId,
  setOrganizationId,
  setSortBy,
  setPageFilter,
  setFilterValue,
  setDateRange,
  updateProject,
  resetInspections,
  setFilters,
  // eslint-disable-next-line prettier/prettier
} = projectInspectionsSlice.actions;

export const dateRangeSelector = createSelector(
  (state) => state.projectInspections.filters,
  (state) => {
    const dates = state.date_range;

    if (!dates) {
      return dates;
    }

    return dates.map((date) => (date ? new Date(date) : null));
  }
);

export default projectInspectionsSlice.reducer;

import { FormInput } from 'components/FormInput';
import { FormTextArea } from 'components/FormTextArea';
import { Form } from 'lib/form';
import { DialogContent, Grid, InputLabel, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import React from 'react';

export const ExternalMap = ({ loading, formik }) => (
  <form>
    <DialogContent style={{ minWidth: 'xs', maxWidth: 'xs', marginRight: '-20px' }}>
      <Grid container>
        <Grid item xs={12} style={{ marginTop: '-15px' }}>
          <InputLabel>
            <Typography mt={0.5}>Name</Typography>
          </InputLabel>
          <FormInput
            placeholder="Name"
            name="name"
            id="name"
            value={formik?.values?.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            {...Form.fieldErrorHelper(formik, 'name')}
            disabled={loading}
            required
            fullWidth
          />
        </Grid>

        <Grid item xs={12} style={{ marginTop: '-15px' }}>
          <InputLabel>
            <Typography mt={0.5}>Map URL (root)</Typography>
          </InputLabel>
          <FormTextArea
            label="Map URL (root)"
            placeholder="The URL of the map you're adding."
            name="url"
            value={formik?.values?.url}
            id="url"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            {...Form.fieldErrorHelper(formik, 'url')}
            disabled={loading}
            required
            multiline={'true'.toString()}
            rows={3}
          />
        </Grid>

        <Grid item xs={12} style={{ marginTop: '5px' }}>
          <InputLabel>
            <Typography mt={0.5}>Description</Typography>
          </InputLabel>
          <FormInput
            placeholder="Description"
            name="desc"
            value={formik?.values?.desc}
            id="desc"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            {...Form.fieldErrorHelper(formik, 'desc')}
            disabled={loading}
            required
            fullWidth
          />
        </Grid>

        <Grid item xs={12} style={{ marginTop: '5px' }}>
          <InputLabel>
            <Typography mt={0.5}>ArcGIS Map ID</Typography>
          </InputLabel>
          <FormInput
            placeholder="ArcGIS Map ID"
            name="webMapId"
            value={formik?.values?.webMapId}
            id="webMapId"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            {...Form.fieldErrorHelper(formik, 'webMapId')}
            disabled={loading}
            required
            fullWidth
          />
        </Grid>
      </Grid>
    </DialogContent>
  </form>
);

ExternalMap.propTypes = {
  loading: PropTypes.bool.isRequired,
  formik: PropTypes.shape({
    values: PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
      desc: PropTypes.string,
      service: PropTypes.string,
    }),
    errors: PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
      desc: PropTypes.string,
      service: PropTypes.string,
    }),
    touched: PropTypes.shape({
      name: PropTypes.bool,
      url: PropTypes.bool,
      desc: PropTypes.bool,
      service: PropTypes.bool,
    }),
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
  }).isRequired,
};

import { useSelector } from 'react-redux';

export const getPermissionType = (perm) => {
  let [type] = perm ? perm.split(':') : [];
  if (type === 'proj') {
    type = 'projects';
  }
  if (type === 'team') {
    type = 'teams';
  }
  if (type === 'org') {
    type = 'orgs';
  }
  return type;
};
export class PermissionsValidator {
  static validate = ({ itemId, scopes }) => {
    const tgAdmin = useSelector((state) => state.auth.tgAdmin);
    const userPermCollection = useSelector((state) => state.auth.permissions);
    const userPerms = useSelector((state) => state.auth.permissions.userPerms);

    if (tgAdmin) return true;

    const hasPermission = (perm) => {
      const type = getPermissionType(perm);
      const perms = userPermCollection[type];

      if (perms === undefined) return false;

      if (itemId) return perms[itemId] && perms[itemId][perm];

      return userPerms[perm];
    };

    return scopes.some((perm) => hasPermission(perm));
  };
}

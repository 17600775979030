import styled from 'styled-components';

export const SidebarPanelButton = styled.button`
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.palette.primary.disabled};
  border: none;
  color: #fff;
  text-align: left;
  padding: ${(props) => props.theme.spacing(1)};
  cursor: pointer;
  line-height: 5px;
`;

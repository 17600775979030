import React, { useContext, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Typography, IconButton } from '@mui/material';
import { usePagination, useSortBy, useTable } from 'react-table';
import { Loader } from 'components/Loader';
import { GeneralTable } from 'components/GeneralTable/GeneralTable';
import { EyeIcon } from 'components/Icons';
import { useDispatch, useSelector } from 'react-redux';
import { getFindings, resetFinding, setFinding } from 'slices/findingReducer';
import { SidebarContext } from 'components/SidebarProvider/SidebarProvider';
import { DeleteFinding } from '../ProjectAssets/ProjectAssetDetail/ProjectAssetInspections/SaveProjectAssetInspectionFinding/DeleteFinding';
import { SaveProjectAssetInspectionFinding } from '../ProjectAssets/ProjectAssetDetail/ProjectAssetInspections/SaveProjectAssetInspectionFinding/SaveProjectAssetInspectionFinding';
import { useStyles } from './styles';

const tableColumns = () => [
  {
    Header: 'Date',
    id: 'createdAt',
    accessor: (rowData) => {
      const { createdAt } = rowData;
      return createdAt ? moment(createdAt).format('MM/DD/YY') : 'date not found';
    },
  },
  {
    Header: 'Findings',
    accessor: (rowData) => (
      <>
        <Typography>{rowData?.name}</Typography>
        <Typography>Description of finding. {rowData?.description}</Typography>
      </>
    ),
  },
  {
    Header: 'Created By',
    accessor: (rowData) => {
      const { uploader } = rowData;
      return uploader ? `${uploader?.firstName} ${uploader?.lastName}` : 'user not found';
    },
  },
];

let debounceTimeout = null;

export const Findings = ({ projAssetInspection }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { setActivePanel, setFullWidth, setHeaderPanels } = useContext(SidebarContext);
  const columns = React.useMemo(() => tableColumns(), []);
  const { data, filters, loading, totalCount } = useSelector((state) => state.findings);

  const table = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: filters.page - 1,
        pageSize: filters.page_size,
      },
      pageCount: Math.ceil(totalCount / filters.page_size),
      totalCount,
      pageLength: data.length,
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  const handleViewFindingOpen = (e, row) => {
    e.stopPropagation();
    setHeaderPanels([
      {
        name: 'inspection',
        component: SaveProjectAssetInspectionFinding,
        data: { action: 'edit' },
      },
    ]);
    setFullWidth(true);
    setActivePanel('inspection');
    dispatch(setFinding(row));
  };

  useEffect(() => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      if (projAssetInspection?.id) dispatch(getFindings({ ...filters, paiId: projAssetInspection?.id }));
    }, 150);
  }, [filters]);

  useEffect(() => {
    if (projAssetInspection?.id) dispatch(getFindings({ ...filters, page_size: 1000, paiId: projAssetInspection?.id }));
  }, [projAssetInspection?.id]);

  useEffect(
    () => () => {
      dispatch(resetFinding());
    },
    []
  );

  const FindingActions = ({ row }) => (
    <div className={classes.findingIcons}>
      <IconButton onClick={(e) => handleViewFindingOpen(e, row.original)}>
        <EyeIcon size={20} />
      </IconButton>
      <DeleteFinding finding={row.original} />
    </div>
  );

  FindingActions.propTypes = {
    row: PropTypes.object.isRequired,
  };

  return (
    <>
      {loading && filters.search ? (
        <Loader height={50} loading size={32} />
      ) : (
        <div style={{ paddingLeft: 0, paddingRigth: 0, maxHeight: '30vh' }}>
          <GeneralTable
            table={table}
            rowComponent={FindingActions}
            selectRow={false}
            autoHeight
            // handleRowClick={handleRowClick}
            // selectedRow={selectedRow?.id}
          />
        </div>
      )}
    </>
  );
};

Findings.propTypes = {
  projAssetInspection: PropTypes.object.isRequired,
};

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SidebarPanel } from 'components/SidebarPanel';
import headerImg from 'images/userDetail.png';
import { resolveUserInitials } from 'lib/resolve-user-initials';
import { InfoPanelBodyHeader } from 'components/InfoPanelBodyHeader/InfoPanelBodyHeader';
import { Button, Tab, Tabs, Typography } from '@mui/material';
import { UuidButton } from 'components/UuidButton/UuidButton';
import { TabPanel } from 'components/TabPanel';
import { useGlobalStyles } from 'styles';

export const InfoPanel = () => {
  const history = useHistory();
  const [value, setValue] = useState(0);
  const globalClasses = useGlobalStyles();
  const { loading, detail, roles } = useSelector((state) => state.adminAdmins.panels);

  const navigateToPermissions = () => {
    history.push(`/admin/admins/${detail.id}/permissions`);
  };

  return (
    <SidebarPanel title="Account Administrator" loading={loading}>
      <InfoPanelBodyHeader
        image={headerImg}
        name={resolveUserInitials({
          firstName: detail.firstName,
          lastName: detail.lastName,
          email: detail.email,
        })}
      />
      <div className={globalClasses.infoPanelBody}>
        <Typography mb={1} variant="h1" className={globalClasses.infoPanelName}>
          {detail.firstName} {detail.lastName}
        </Typography>
        <Typography mb={0.5} variant="h5">
          {detail.email}
        </Typography>
        {detail.phone && <Typography mb={0.5}>{detail.phone}</Typography>}
        <UuidButton data={detail} />
        <Button
          color="primary"
          variant="contained"
          onClick={navigateToPermissions}
          size="large"
          sx={{ marginTop: '2rem' }}
          className={globalClasses.openDetail}
        >
          Edit Account Role
        </Button>
        <Typography mt={1} className={globalClasses.infoPanelAboutText}>
          About
        </Typography>
        <Typography mb={1} variant="body2" className={globalClasses.infoPanelDescription}>
          {detail.description}
        </Typography>
        <Tabs value={value} className={globalClasses.infoPanelTabs} sx={{ width: '100%' }}>
          <Tab label="Account Role" onClick={() => setValue(0)} sx={{ width: '100%' }} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <div className={globalClasses.infoTabPanel}>
            {(roles.accounts || []).map((acct) =>
              acct.roles.map((role) => (
                <Typography mb={1} mt={0.5}>
                  {role.displayName}
                </Typography>
              ))
            )}
          </div>
        </TabPanel>
      </div>
    </SidebarPanel>
  );
};

import { DialogMessage } from 'components/DialogMessage';
import { InfoIcon } from 'components/Icons';
import { If } from 'components/If/If';
import { Typography } from 'components/Typography';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { TableRow } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { services } from 'components/CreateContent/StorageServiceConst';
import { GooglePicker } from 'components/StorageServices/GooglePicker';

import {
  // CloseTD,
  DragDiv,
  ReadOnlyText,
  // StyledCloseIcon,
  StyledDragIcon,
  StyledInput,
  StyledNameContent,
  StyledRow,
  StyledCell,
  StyledSelect,
  StyledTD,
  StyledTypography,
} from './styles';

export const UploadVideoRow = ({
  file,
  index,
  cameraTypes,
  onDelete,
  onChange,
  isDraggable,
  isSeriesChild,
  isGroupChild,
  storageAuth,
}) => {
  const cameraTypeLookup = React.useMemo(
    () =>
      cameraTypes.reduce((acc, cameraType) => {
        acc[cameraType.value] = cameraType;

        return acc;
      }, {}),
    [cameraTypes]
  );
  const [accept, setAccept] = useState('');
  // const [storageFile, setStorageFile] = useState('-');
  const [openModalError, setOpenModalError] = useState(false);
  const inputRef = React.useRef(null);

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const handleChange = (key, value) => {
    const newFile = { ...file, [key]: value };
    if (key === 'cameraType') {
      const input = inputRef.current;
      if (input !== null) input.value = null;
      delete newFile.externalTelemetry;
      delete newFile.telemetryName;
      newFile.telemetryStorage = false;
      switch (value) {
        case 8:
        case 10:
          setAccept('.csv');
          break;
        case 11:
          setAccept('.gpx');
          break;
        case 14:
          setAccept('.srt');
          break;
        default:
          break;
      }
    } else if (key === 'externalTelemetry') {
      newFile.telemetryStorage = false;
    }

    onChange(index, newFile);
  };

  const handleDelete = (idx) => {
    onDelete(idx);
  };

  const handleStorage = (data) => {
    const newFile = {
      ...file,
      externalTelemetry: {
        id: data.docs[0].id,
        name: data.docs[0].name,
      },
      telemetryStorage: true,
    };
    // setStorageFile(data.docs[0].name);
    onChange(index, newFile);
  };

  const telemetryUpload = (e) => {
    if (e.target.files.length > 0) {
      const telemetryFile = e.target.files[0];
      let type = telemetryFile.type ? `.${telemetryFile.type.split('/')[1]}` : null; // validate for type from file object
      if (!type || type === null)
        type = `.${telemetryFile.name.split('.')[telemetryFile.name.split('.').length - 1]}`.toLowerCase(); // validate for name
      if (accept !== type) {
        e.target.value = null;
        setOpenModalError('File not accepted');
      } else {
        handleChange('externalTelemetry', telemetryFile);
      }
    }
  };

  return (
    <>
      <Draggable key={file.data.name} draggableId={file.metaId} index={index} isDragDisabled={!isDraggable}>
        {(pro, snap) => (
          <TableRow
            {...pro.draggableProps}
            style={getItemStyle(snap.isDragging, pro.draggableProps.style)}
            id={`tr_${file.metaId}`}
            ref={pro.innerRef}
          >
            {/* <CloseTD onClick={() => handleDelete(file.metaName)}>
              <StyledCloseIcon size={20} />
            </CloseTD> */}
            {isDraggable ? (
              <StyledTD>
                <StyledRow>
                  <DragDiv {...pro.dragHandleProps}>
                    <StyledDragIcon sx={{ fontSize: 30 }} />
                  </DragDiv>
                </StyledRow>
              </StyledTD>
            ) : null}
            <StyledTD>
              <StyledRow>
                <StyledNameContent isDraggable={isDraggable}>
                  {isSeriesChild || isGroupChild ? (
                    <ReadOnlyText>{index > 0 ? `${file.metaName} (${index})` : file.metaName}</ReadOnlyText>
                  ) : (
                    <StyledInput
                      type="text"
                      disabled={isSeriesChild || isGroupChild}
                      onChange={(e) => handleChange('metaName', e.target.value)}
                      value={file.metaName}
                    />
                  )}

                  <Typography style={{ marginLeft: '8px' }} variant="small" spacing={0.5}>
                    ({file.data.name})
                  </Typography>
                </StyledNameContent>
              </StyledRow>
            </StyledTD>
            <StyledTD>
              {isSeriesChild || isGroupChild ? (
                <ReadOnlyText>{file.description}</ReadOnlyText>
              ) : (
                <StyledInput
                  type="text"
                  disabled={isSeriesChild || isGroupChild}
                  value={file.description}
                  placeholder="Give this content a meaningful description."
                  onChange={(e) => handleChange('description', e.target.value)}
                />
              )}
            </StyledTD>
            <StyledTD>
              {file.metaType === 'VIDEO' && cameraTypes ? (
                <StyledSelect
                  name="cameraType"
                  id="cameraType"
                  value={file.cameraType}
                  disabled={isSeriesChild}
                  onChange={(e) => handleChange('cameraType', Number(e.target.value))}
                >
                  {cameraTypes.map((type) => (
                    <option value={type.value} key={type.value}>
                      {type.description}
                    </option>
                  ))}
                </StyledSelect>
              ) : null}
            </StyledTD>
            <StyledTD>
              <If cond={cameraTypeLookup[file.cameraType].hasExternalTelemetry}>
                <If cond={!file.service}>
                  <StyledInput
                    type="file"
                    accept={accept}
                    id={`telemetry_${file.metaId}`}
                    onChange={telemetryUpload}
                    disabled={isSeriesChild}
                    ref={inputRef}
                  />
                </If>
                <If cond={file.service !== undefined}>
                  <StyledCell>
                    <If cond={file.service === services.GOOGLE}>
                      <GooglePicker
                        onCallback={handleStorage}
                        mini
                        authExist={storageAuth[services.GOOGLE]}
                        disabled={false}
                        multiselect={false}
                      />
                      <StyledInput
                        type="file"
                        accept={accept}
                        id={`telemetry_${file.metaId}`}
                        onChange={telemetryUpload}
                        disabled={isSeriesChild}
                        ref={inputRef}
                      />
                    </If>
                  </StyledCell>
                  <If cond={file.externalTelemetry !== undefined}>
                    <StyledTypography>{`Selected ${
                      file.externalTelemetry ? file.externalTelemetry.name : 'file'
                    }`}</StyledTypography>
                  </If>
                </If>
              </If>
              <If cond={!cameraTypeLookup[file.cameraType].hasExternalTelemetry}>
                <StyledTypography>n/a</StyledTypography>
              </If>
            </StyledTD>
            <StyledTD>
              <IconButton aria-label="location" color="secondary" onClick={() => {}}>
                <LocationOnIcon fontSize="large" />
              </IconButton>
            </StyledTD>
            <StyledTD>
              <StyledInput
                type="date"
                value={moment(file.metaAcqDate).format('YYYY-MM-DD')}
                onChange={(e) => handleChange('metaAcqDate', e.target.value)}
              />
            </StyledTD>
            <StyledTD>
              <IconButton aria-label="delete" color="secondary" onClick={() => handleDelete(file.metaName)}>
                <DeleteIcon fontSize="large" />
              </IconButton>
            </StyledTD>
          </TableRow>
        )}
      </Draggable>
      <DialogMessage
        title={openModalError}
        isOpen={openModalError}
        icon={InfoIcon}
        confirmText="Ok"
        onConfirm={() => {
          setOpenModalError(false);
        }}
      />
    </>
  );
};

UploadVideoRow.propTypes = {
  file: PropTypes.shape({
    metaId: PropTypes.string.isRequired,
    cameraType: PropTypes.number.isRequired,
    metaType: PropTypes.string.isRequired,
    metaName: PropTypes.string.isRequired,
    metaAcqDate: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    externalTelemetry: PropTypes.instanceOf(File),
    data: PropTypes.instanceOf(File),
  }).isRequired,
  cameraTypes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
      hasExternalTelemetry: PropTypes.bool.isRequired,
    })
  ).isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  storageAuth: PropTypes.any,
  isSeriesChild: PropTypes.bool,
  isGroupChild: PropTypes.bool,
  isDraggable: PropTypes.bool,
};

UploadVideoRow.defaultProps = {
  isSeriesChild: false,
  isGroupChild: false,
  isDraggable: false,
  storageAuth: {},
};

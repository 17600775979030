import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const ExpandIcon = ({ size }) => (
  <Svg viewBox="0 0 24 24" size={size}>
    <path
      id="Icon"
      d="M16 8L21 3M21 3H16M21 3V8M8 8L3 3M3 3L3 8M3 3L8 3M8 16L3 21M3 21H8M3 21L3 16M16 16L21 21M21 21V16M21 21H16"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </Svg>
);

ExpandIcon.propTypes = {
  size: PropTypes.number,
};

ExpandIcon.defaultProps = {
  size: 24,
};

import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const VideoIcon = ({ size }) => (
  <Svg viewBox="0 0 129 129" size={size}>
    <path
      d="m10.5,122.5h21.8 64.3 21.8c2.3,0 4.1-1.8 4.1-4.1v-107.9c0-2.3-1.8-4.1-4.1-4.1h-107.9c-2.3,0-4.1,1.8-4.1,4.1v107.9c0,2.3 1.9,4.1 4.1,4.1zm90.2-107.9h13.6v18.8h-13.6v-18.8zm0,27h13.6v18.8h-4.3-9.4v-18.8zm.1,27h9.4 4.3v18.8h-13.6v-18.8zm-.1,27h13.6v18.8h-13.6v-18.8zm-64.3-81h56.1v45.8h-56.1v-45.8zm0,54h56.1v45.8h-56.1v-45.8zm-21.8-54h13.6v18.8h-13.6v-18.8zm0,27h13.6v18.8h-13.6v-18.8zm0,27h13.6v18.8h-13.6v-18.8zm0,27h13.6v18.8h-13.6v-18.8z"
      fill="currentColor"
    />
  </Svg>
);

VideoIcon.propTypes = {
  size: PropTypes.number,
};

VideoIcon.defaultProps = {
  size: 24,
};

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, IconButton, Typography } from '@mui/material';
import { faCircleCheck, faClose } from '@fortawesome/free-solid-svg-icons';
import { cutUuidText } from 'lib/generalFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGlobalStyles } from 'styles';

export const CopyRole = ({ value, handleClose }) => {
  const classes = useGlobalStyles();

  // ---------------- copy my uuid
  useEffect(() => {
    navigator.clipboard.writeText(value);
  }, []);

  return (
    <Dialog open onClose={handleClose} maxWidth="xs">
      <DialogContent className={classes.copyBox}>
        <div className={classes.copyIcons}>
          <FontAwesomeIcon icon={faCircleCheck} size="lg" />
          <IconButton onClick={handleClose}>
            <FontAwesomeIcon icon={faClose} size="lg" />
          </IconButton>
        </div>
        <Typography className={classes.copyTitle}>Value {cutUuidText(value)} Copied!</Typography>
        <Typography className={classes.copyText}>
          This value for the role has been copied to your clipboard. Paste it into the value of the Azure AD Application
          role.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

CopyRole.propTypes = {
  value: PropTypes.string,
  handleClose: PropTypes.func,
};

CopyRole.defaultProps = {
  value: null,
  handleClose: () => {},
};

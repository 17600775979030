import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ProjectService from 'services/ProjectService';
import { useDispatch } from 'react-redux';
import { deleteObservation, setObservation } from 'slices/observationsReducer';
import { DialogMessage, dialogMessageAction } from 'components/DialogMessage';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import { ViewRoleValidation } from 'components/ViewRoleValidation/ViewRoleValidation';
import { Permissions } from 'lib/permissions';
import { Button, IconButton } from '@mui/material';
import { InfoIcon, TrashIcon } from 'components/Icons';

export const DeleteButton = ({ observation, loading, icon, handleClose }) => {
  const dispatch = useDispatch();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validateRole, setValidateRole] = useState({ open: false, callBack: () => {} });

  const deletePoint = async () => {
    setOpenConfirm(false);
    setIsLoading(true);
    try {
      await ProjectService.deleteObservationPoint(observation.projectId, observation.id);
      setIsLoading(false);
      handleClose();
      dispatch(deleteObservation(observation));
      dispatch(setObservation({}));
    } catch (error) {
      setIsLoading(false);
      setOpenDialog('An error occurred while trying to delete the observation.');
    }
  };

  return (
    <>
      {icon ? (
        <IconButton
          onClick={() =>
            setValidateRole({
              open: true,
              action: [Permissions.PROJ_OBSERVATION],
              callBack: () => setOpenConfirm(true),
            })
          }
        >
          <TrashIcon size={20} />
        </IconButton>
      ) : (
        <Button
          color="error"
          onClick={() =>
            setValidateRole({
              open: true,
              action: [Permissions.PROJ_OBSERVATION],
              callBack: () => setOpenConfirm(true),
            })
          }
          disabled={loading}
          variant="contained"
          size="small"
        >
          Delete Point
        </Button>
      )}
      <DialogMessage
        title={`Delete ${observation.name}`}
        content={`Are you sure you want to delete this ${observation.name}? This action cannot be undone`}
        isOpen={openConfirm}
        onConfirm={deletePoint}
        onCancel={() => setOpenConfirm(false)}
        action={dialogMessageAction.delete}
      />
      <DialogMessage
        title={openDialog}
        isOpen={openDialog}
        icon={InfoIcon}
        confirmText="Ok"
        onConfirm={() => {
          setOpenDialog(false);
        }}
      />
      {validateRole.open && (
        <ViewRoleValidation
          action={validateRole.action}
          data={{ id: observation.projectId }}
          callBack={validateRole.callBack}
          handleClose={() => setValidateRole({ open: false, callBack: () => {} })}
        />
      )}
      <LoadingOverlay loading={isLoading} />
    </>
  );
};

DeleteButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  observation: PropTypes.object,
  loading: PropTypes.bool,
  icon: PropTypes.bool,
  handleClose: PropTypes.func,
};

DeleteButton.defaultProps = {
  observation: { id: null, name: '' },
  loading: false,
  icon: false,
  handleClose: () => {},
};

import React, { useContext } from 'react';
import { SidebarDetailContext } from 'components/SidebarProviderDetail/SidebarProviderDetail';
import { useStyles } from '../Sidebar/styles';

export const SideBarDetail = () => {
  const { panels, activePanel, open, visible, fullWidth } = useContext(SidebarDetailContext);
  const classes = useStyles({ open, fullWidth, visible, detail: true });
  const Panel = panels[activePanel];

  return <div className={classes.sidebarPanelContainer}>{Panel && <Panel />}</div>;
};

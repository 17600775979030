import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useStyles } from './styles';

export const SecondaryNavItem = ({ active, children, ...rest }) => {
  const classes = useStyles({ active });

  return (
    <div className={classes.styledLi}>
      <NavLink className={`${classes.styledLink} `} {...rest}>
        {children}
      </NavLink>
    </div>
  );
};

SecondaryNavItem.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
};

SecondaryNavItem.defaultProps = {
  children: null,
  active: false,
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Permissions } from 'lib/permissions';
import { UuidButton } from 'components/UuidButton/UuidButton';
import { Button, Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material';
import { LinkedIcon, PencilIcon, AlertTriangleIcon } from 'components/Icons';
import { ViewRoleValidation } from 'components/ViewRoleValidation/ViewRoleValidation';
import { ModalEditAsset } from 'smartComponents/ModalEditAsset';
import { DialogMessage, dialogMessageAction } from 'components/DialogMessage';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAsset, setAssetsData, ignorePerms } from 'slices/profileProjectAssetReducer';
import { DeleteProjectRelation } from 'components/ManageSharedAssets/DeleteProjectRelation';
import { ObservationExport } from 'components/Observation/ObservationExport/ObservationExport';
import { useStyles as useProjectStyles } from '../../styles';

const actions = {
  DELETE: 'delete',
  EDIT: 'edit',
  REMOVE: 'remove',
  IGNORE: 'ignore',
};

export const ProjectAssetSettings = ({ asset, project }) => {
  const dispatch = useDispatch();
  const projectClasses = useProjectStyles();
  const { data } = useSelector((state) => state.profileProjectAssets);
  const permissions = useSelector((state) => state.auth.permissions);
  const tgAdmin = useSelector((state) => state.auth.tgAdmin);
  const { observations, isLoading } = useSelector((state) => state.assetObservations);
  const [validateRole, setValidateRole] = useState({ open: false, callBack: () => {} });
  const [action, setAction] = useState('');

  const updateList = (editedAsset) => {
    const auxData = [...data];
    const index = auxData.findIndex((element) => element.id === asset.id);
    if (index !== -1) {
      if (action === actions.DELETE || action === actions.REMOVE) {
        auxData.splice(index, 1);
      } else if (action === actions.EDIT) {
        auxData[index] = { ...asset, name: editedAsset.name, description: editedAsset.description };
      }
      dispatch(setAssetsData(auxData));
    }
  };

  const handleDeleteConfirm = async () => {
    await dispatch(deleteAsset({ projectId: asset?.project?.id, assetId: asset.id }));
    updateList();
    setAction('');
  };

  const handleIgnorePerms = async () => {
    await dispatch(ignorePerms({ projectId: asset?.project?.id, assetId: asset.id }));
    updateList();
    setAction('');
  };

  const handleClose = () => {
    setAction('');
  };

  const formatDate = (dateStr) => {
    const d = new Date(dateStr);
    return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
  };

  const canUpdateArchiveRole = () => {
    let found = false;
    if (tgAdmin) {
      found = true;
    } else if (
      permissions.account &&
      permissions.account[project.accountId] &&
      permissions.account[project.accountId].role
    ) {
      found = ['acct:admin'].indexOf(permissions.account[project.accountId].role) >= 0;
    } else if (permissions.orgs && permissions.orgs[project.orgId] && permissions.orgs[project.orgId].role) {
      found = ['org:admin'].indexOf(permissions.orgs[project.orgId].role) >= 0;
    } else if (permissions.projects && permissions.projects[project.id] && permissions.projects[project.id].role) {
      found = ['proj:admin', 'proj:content_coord'].indexOf(permissions.projects[project.id].role) >= 0;
    }
    return found;
  };

  return (
    <>
      <Grid container spacing={0.5}>
        {asset.archived && canUpdateArchiveRole() && (
          <>
            {!asset.ignorePerms && (
              <Grid item xs={12}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() =>
                    setValidateRole({
                      open: true,
                      data: { asset },
                      action: [Permissions.PROJ_MODIFY_CONTENT],
                      callBack: () => {
                        setAction(actions.IGNORE);
                      },
                    })
                  }
                >
                  Grant all Users Temporary Access
                </Button>
              </Grid>
            )}
            {asset.ignorePerms && (
              <Grid item xs={12}>
                <Button color="primary">
                  Users can view this archived element until {formatDate(asset.ignorePermsDate)}
                </Button>
              </Grid>
            )}
          </>
        )}
        <Grid item xs={12}>
          <UuidButton data={asset} permissions={[Permissions.PROJ_COPY_UUID]} buttonType />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Button
            className={projectClasses.buttonGrapper}
            onClick={() =>
              setValidateRole({
                open: true,
                data: { ...asset.project, asset },
                action: [Permissions.PROJ_MODIFY_CONTENT],
                callBack: () => {
                  setAction(actions.EDIT);
                },
              })
            }
          >
            <Button color="secondary" variant="contained" className={projectClasses.smallButton} size="small">
              <PencilIcon size={15} />
            </Button>
            Edit Name or Description
          </Button>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <ObservationExport observations={observations} isLoading={isLoading} simpleButton />
        </Grid>
        {asset?.project?.id !== project.id && (
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Button
              className={projectClasses.buttonGrapper}
              onClick={() =>
                setValidateRole({
                  open: true,
                  data: project,
                  action: [Permissions.PROJ_MODIFY_CONTENT],
                  callBack: () => setAction(actions.REMOVE),
                })
              }
            >
              <Button color="secondary" variant="contained" className={projectClasses.smallButton} size="small">
                <LinkedIcon size={15} />
              </Button>
              Remove
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <FormGroup row style={{ width: 'max-content' }}>
            <FormControlLabel control={<Checkbox checked name="share" />} label="Sharing" />
            <FormControlLabel control={<Checkbox checked name="copy" />} label="Copying" />
          </FormGroup>
        </Grid>
        <Grid item xs={12} align="right">
          <Button
            color="danger"
            variant="contained"
            onClick={() =>
              setValidateRole({
                open: true,
                data: { ...asset.project, asset, currentProject: project.id },
                action: [Permissions.PROJ_DELETE_CONTENT],
                callBack: () => {
                  setAction(actions.DELETE);
                },
              })
            }
          >
            Delete Asset
          </Button>
        </Grid>
      </Grid>

      {validateRole.open && (
        <ViewRoleValidation
          action={validateRole.action}
          data={validateRole.data}
          callBack={validateRole.callBack}
          handleClose={() => setValidateRole({ open: false, callBack: () => {} })}
        />
      )}
      {action === actions.EDIT && (
        <ModalEditAsset
          isOpen
          onConfirm={(editedAsset) => {
            updateList(editedAsset);
            handleClose();
          }}
          onCancel={handleClose}
          asset={asset}
          projectId={asset?.project?.id}
        />
      )}
      {action === actions.REMOVE && (
        <DeleteProjectRelation
          project={project}
          asset={asset}
          simpleRemove
          handleCallBack={() => {
            updateList();
            handleClose();
          }}
          handleCancel={handleClose}
        />
      )}
      {action === actions.DELETE && (
        <DialogMessage
          title={
            asset?.projectSharedWith
              ? 'This asset is being shared to another project. Are you sure you want to cut off access? If not, copy the content to the other project before deleting.'
              : 'Are you sure you want to delete?'
          }
          isOpen
          onConfirm={handleDeleteConfirm}
          onCancel={handleClose}
          action={dialogMessageAction.delete}
        />
      )}
      {action === actions.IGNORE && (
        <DialogMessage
          icon={AlertTriangleIcon}
          title="Grant all Users Temporary Access"
          content={
            <>
              <Typography paragraph align="justify">
                By clicking yes you will permit all users to be able to view archived content (a credit using process)
                for 7 days regardless of their project or organization role.
              </Typography>
              <Typography paragraph>Do you wish to proceed?</Typography>
            </>
          }
          isOpen
          confirmText="Yes"
          iconColor="warning"
          onConfirm={handleIgnorePerms}
          onCancel={handleClose}
        />
      )}
    </>
  );
};

ProjectAssetSettings.propTypes = {
  asset: PropTypes.object,
  project: PropTypes.object,
};

ProjectAssetSettings.defaultProps = {
  asset: null,
  project: null,
};

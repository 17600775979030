import styled from 'styled-components';

const getColor = (theme, error, disabled) => {
  if (disabled) {
    return theme.inputs.text.disabled.borderColor;
  }

  if (error) {
    return theme.inputs.text.error.borderColor;
  }

  return theme.inputs.text.default.borderColor;
};

export const StyledDiv = styled.div`
  position: relative;
  display: block;
  margin-bottom: ${(props) => props.theme.spacing(1)};
`;

export const StyledInputElement = styled.input`
  margin: 0;
  border: 1px solid;
  display: block;
  border-color: ${(props) => getColor(props.theme, props.error, props.disabled)};
  background: #303132;
  color: white;
  height: 40px;
  width: 100%;
  ${(props) => (props.prepend ? 'padding-left: 36px' : 'padding-left: 12px')};

  padding-right: ${(props) => (props.hasIcon ? '42px' : '12px')};
  border-radius: 5px;
  &:focus {
    outline: none;
  }
`;

export const StyledIcon = styled.span`
  position: absolute;
  ${(props) => (props.prepend ? 'left: 10px' : '  right: 10px;')};

  top: ${(props) => (props.label ? '36px' : '12px')};
`;

import { SearchPanelBody, searchPanelSection } from 'components/Inspections/SearchPanelBody/SearchPanelBody';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dateRangeSelector, preloadFilterDatasets, setFilterValue, setFilters } from 'slices/projectInspectionReducer';

export const SearchPanelInspections = () => {
  const dispatch = useDispatch();
  const nameSort = useSelector((state) => state.projectInspections.filters.sort_by.name || '');
  const dateSort = useSelector((state) => state.projectInspections.filters.sort_by.createdAt || '');
  const userId = useSelector((state) => state.projectInspections.filters.userId || '');
  const filterIsActive = useSelector((state) =>
    state.projectInspections.filters.filterIsActive === null || state.projectInspections.filters.filterIsActive === ''
      ? null
      : state.projectInspections.filters.filterIsActive
  );
  const dateRange = useSelector(dateRangeSelector);
  const { users, loaded } = useSelector((state) => state.projectInspections.panels.search);
  const { id } = useSelector((state) => state.projectInspections.panels.project);

  useEffect(() => {
    dispatch(preloadFilterDatasets(id));
  }, []);

  return (
    <SearchPanelBody
      loaded={loaded}
      users={users}
      filters={{
        sort_by: {
          name: nameSort,
          createdAt: dateSort,
        },
        userId,
        date_range: dateRange,
        filterIsActive,
      }}
      setFilterValue={setFilterValue}
      setFilters={setFilters}
      section={searchPanelSection.INSPECTIONS}
    />
  );
};

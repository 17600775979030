import PropTypes from 'prop-types';
import React from 'react';

import { Svg } from './Svg';

export const BarsIcon = ({ size }) => (
  <Svg viewBox="0 0 24 24" size={size}>
    <path
      d="M23 2H1a1 1 0 000 2h22a1 1 0 100-2zM1 11h22a1 1 0 110 2H1a1 1 0 110-2zm22 9H1a1 1 0 100 2h22a1 1 0 100-2z"
      fill="currentColor"
    />
  </Svg>
);

BarsIcon.propTypes = {
  size: PropTypes.number,
};

BarsIcon.defaultProps = {
  size: 24,
};

import ApiService from './ApiService';

export default class AuthService {
  static login = (loginInfo) => ApiService.post(`/auth/login`, loginInfo);

  static authMe = () => ApiService.get(`/auth/me`);

  static ssoAccounts = () => ApiService.get(`/sso/accounts`);

  static loginAD = (token, account) => ApiService.post(`/sso/loginAD/${account}`, { authorization: `Bearer ${token}` });

  static getSingleProjectGrants = ({ projId }) => ApiService.get(`/grants/projects`, { projId });

  static getAllProjectGrants = () => ApiService.get(`/grants/projects`);

  static getAllOrgGrants = () => ApiService.get(`/grants/orgs`);

  static getSingleTeamGrants = ({ teamId }) => ApiService.get(`/grants/teams`, { teamId });

  static getAllTeamsGrants = () => ApiService.get(`/grants/teams`);

  static getAccountGrants = () => ApiService.get(`/grants/account`);

  static getSingleOrgGrant = ({ orgId }) => ApiService.get(`/grants/orgs`, { orgId });

  static getAccountGrants = () => ApiService.get(`/grants/account`);

  /**
   *
   * @param {string} userId
   * @param {string} token
   * @returns
   */
  static validateInviteToken = (userId, token) => ApiService.get(`/auth/validate-invite-token/${userId}/${token}`);

  /**
   *
   * @param {string} userId
   * @param {{
   *  token: string;
   *  firstName: string;
   *  lastName: string;
   *  password: string;
   *  passwordConfirm: string;
   * }} data
   * @returns
   */
  static acceptInvitation = async (userId, data) => ApiService.post(`/auth/${userId}/accept-invite`, data);

  /**
   *
   * @param {{
   *   token: string;
   *   userId: string;
   *   password: string;
   *   passwordConfirm: string;
   * }} data
   * @returns
   */
  static passwordReset = async ({ token, userId, password, passwordConfirm }) =>
    ApiService.post(`/auth/password-reset/${token}`, {
      userId,
      password,
      passwordConfirm,
    });

  /**
   *
   * @param {{
   *   token: string;
   *   userId: string;
   *   password: string;
   *   passwordConfirm: string;
   * }} data
   * @returns
   */
  static passwordResetRequest = async ({ email }) =>
    ApiService.post(`/auth/password-reset/`, {
      email,
    });
}

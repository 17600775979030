import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  modalContent: {
    width: '900px',
  },
  mapSection: {
    display: 'flex',
  },
  topBar: {
    backgroundColor: theme.palette.primary.light,
    display: 'flex',
    justifyContent: 'end',
    padding: '4px 6px',
  },
}));

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AccountSettingsContainer } from 'components/AccountSettingsContainer/AccountSettingsContainer';
import { AddUuid } from 'components/AddUuid/AddUuid';
import { AdminHeaderTitle } from 'components/AdminHeaderTitle/AdminHeaderTitle';
import { Card } from 'components/Card/Card';
import { ContextMenu } from 'components/ContextMenu';
import { ContextMenuList } from 'components/ContextMenuItems';
import { DialogTeamInviteUser } from 'components/DialogTeamInviteUser';
import { PermGuard } from 'components/Guards/PermGuard';
import { CirclePlusIcon, PlusIcon } from 'components/Icons';
import { Loader } from 'components/Loader';
import { useSidebar } from 'components/SidebarProvider/SidebarProvider';
import { TablePagination } from 'components/TablePagination/TablePagination';
import { WidgetSplash } from 'components/WidgetSplash/WidgetSplash';
import SplashImage from 'images/TeamsSplash.png';
import { Permissions } from 'lib/permissions';
import { PermissionsValidator } from 'lib/permValidator';
import { usePagination, useSortBy, useTable } from 'react-table';
import { fetchTeam, fetchTeams, setPageFilter, setSearch } from 'slices/adminTeamsReducer';
import { ModalNewTeam } from 'smartComponents/ModalNewTeam';
import { getExternalTeams, setExternalPageFilter } from 'slices/externalTeamReducer';
import { DeleteUuid } from 'components/AddUuid/DeleteUuid/DeleteUuid';
import { CopyUuidAlert } from 'components/AddUuid/CopyUuidAlert/CopyUuidAlert';
import { addExternalType, externalActionType, externalDataType } from 'lib/external-data';
import { DialogProjectInvite, typeInviteData } from 'components/DialogProjectInvite';
import { ViewRoleValidation } from 'components/ViewRoleValidation/ViewRoleValidation';
import { GeneralTable } from 'components/GeneralTable/GeneralTable';
import { TopBar } from 'components/TopBar/TopBar';
import { externalTeamsOptions, teamOptions } from 'lib/topBarOptions';
import { ContextMenuButton } from 'components/ContextMenuButton';
import { ContextMenuItem } from 'components/ContextMenuItem';
import { WidgetDivider } from 'components/WidgetDivider/WidgetDivider';
import { AdminPanel } from './AdminPanel';
import { InfoPanel } from './InfoPanel';
import { SearchPanel } from './SearchPanel';

const tableColumns = (external) => [
  {
    Header: 'Name',
    accessor: (row) => `${external ? row.team?.name : row.name}`, // accessor is the "key" in the data
  },
  {
    Header: 'Description',
    accessor: (row) => (external ? row.team?.description : row.description),
  },
  {
    Header: external ? 'Leading Account' : 'Root Org.',
    accessor: (row) => (external ? row.team?.account?.name : row.org?.name),
  },
  {
    Header: 'Members',
    accessor: (row) => {
      const { users } = external ? row.team : row;
      return users?.length || 0;
    },
  },
];

let debounceTimeout = null;

export const Teams = ({ external }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const { teams, loading, filters, teamCount } = useSelector(
    (state) => state[external ? 'externalTeams' : 'adminTeams']
  );
  const [openTeamInviteProject, setOpenTeamInviteProject] = useState(false);
  const [openTeamInviteUser, setOpenTeamInviteUser] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  const history = useHistory();
  const [selectedTeamId, setSelectedTeamId] = useState('');
  const [openUuid, setOpenUuid] = useState(false);
  const columns = React.useMemo(() => tableColumns(external), []);
  const adminScopes = [Permissions.ACCOUNT_USER_GRANT];
  const [validateRole, setValidateRole] = useState({ open: false, callBack: () => {} });
  const search = filters.search || '';
  const handleSearch = (e) => dispatch(setSearch(e.target.value));

  // ----------------------- get teams from API
  const loadData = () => {
    const sendFilters = {
      ...filters,
      sort_by: Object.values(filters.sort_by),
    };
    dispatch(external ? getExternalTeams(sendFilters) : fetchTeams(sendFilters));
  };

  const handleConfirm = () => {
    setOpen(false);
    loadData();
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const navigateToDashboard = (rowData) => {
    history.push(`/teams/${external ? rowData.original.team.id : rowData.original.id}`);
  };

  const table = useTable(
    {
      columns,
      data: teams,
      initialState: {
        pageIndex: filters.page - 1,
        pageSize: filters.page_size,
      },
      pageCount: Math.ceil(teamCount / filters.page_size),
      totalCount: teamCount,
      pageLength: teams.length,
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  const {
    state: { pageIndex, pageSize },
  } = table;

  useEffect(() => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      loadData();
    }, 150);
  }, [filters]);

  useEffect(() => {
    dispatch(external ? setExternalPageFilter(pageIndex + 1) : setPageFilter(pageIndex + 1));
  }, [pageIndex, pageSize]);

  const { displayPanel, setHeaderPanels } = useSidebar({
    open: false,
    config: {
      onClose: () => {
        // do nothing
      },
      search: {
        show: true,
        component: SearchPanel,
        external,
      },
      actions: [
        {
          icon: PlusIcon,
          onClick: () => {
            if (external) {
              setOpenUuid({ type: externalActionType.ADD });
            } else {
              setOpen(true);
            }
          },
        },
      ],
    },
  });

  const teamsScopes = [Permissions.TEAM_MODIFY];

  const isTeamAdmin = PermissionsValidator.validate({ scopes: teamsScopes });

  const handleRowClick = async (rowData) => {
    // dispatch redux action to load user data
    // set sidebar active panel to user info and open if necessary
    setSelectedRow(rowData.id);
    dispatch(fetchTeam(external ? rowData.original.team.id : rowData.original.id));
    if (isTeamAdmin) {
      setHeaderPanels([
        {
          name: 'info',
          component: InfoPanel,
        },
        {
          name: 'admin',
          component: AdminPanel,
        },
      ]);
    } else {
      setHeaderPanels([
        {
          name: 'info',
          component: InfoPanel,
        },
      ]);
    }
    displayPanel('info');
  };

  const EmptyTeamsContainer = () => (
    <WidgetSplash
      alt="Teams Splash"
      title="You have no team yet."
      image={SplashImage}
      cta="Create your first team now."
      onClick={() => (external ? setOpenUuid({ type: externalActionType.ADD }) : setOpen(true))}
    />
  );

  const handleTeamInviteProjectClose = (refreshFlag) => {
    setOpenTeamInviteProject(false);
    if (refreshFlag) {
      const sendFilters = {
        ...filters,
        sort_by: Object.values(filters.sort_by),
      };
      dispatch(fetchTeams(sendFilters));
    }
  };

  const handleOpenTeamInviteUser = (teamId) => {
    setSelectedTeamId(teamId);
    setOpenTeamInviteUser(true);
  };

  // ------------------------------- UUID functions
  const handleCloseUuid = (flag) => {
    setOpenUuid(false);
    if (flag) loadData(filters);
  };

  const TeamActions = ({ row }) => (
    <ContextMenu>
      <ContextMenuButton />
      <ContextMenuList position="left">
        <ContextMenuItem onClick={() => navigateToDashboard(row)}>Go to Team Dashboard</ContextMenuItem>
        {!external && (
          <ContextMenuItem
            onClick={() =>
              setValidateRole({
                open: true,
                action: [Permissions.TEAM_MODIFY],
                data: row.original,
                callBack: () => setOpenUuid({ id: row.original.id, type: externalActionType.COPY }),
              })
            }
          >
            Copy UUID
          </ContextMenuItem>
        )}
        <WidgetDivider />
        <PermGuard
          scopes={[Permissions.TEAM_MODIFY, Permissions.TEAM_USER_REMOVE, Permissions.TEAM_USER_GRANT]}
          itemId={row.original.id}
        >
          <ContextMenuItem onClick={() => setOpenTeamInviteProject(external ? row.original.team : row.original)}>
            Add Team to Project
          </ContextMenuItem>
        </PermGuard>
        <PermGuard
          scopes={[
            Permissions.TEAM_USER_GRANT,
            Permissions.TEAM_USER_ADD,
            Permissions.TEAM_USER_REMOVE,
            Permissions.TEAM_USER_MODIFY,
          ]}
          itemId={row.original.id}
        >
          <ContextMenuItem
            onClick={() =>
              handleOpenTeamInviteUser(
                external ? row.original.team.id : row.original.id,
                external ? row.original.team.orgId : row.original.orgId
              )
            }
          >
            Add Member to Team
          </ContextMenuItem>
        </PermGuard>
        {!external && (
          <>
            <PermGuard scopes={adminScopes}>
              <ContextMenuItem
                onClick={() =>
                  setOpenUuid({
                    ...row.original,
                    type: externalActionType.ADD,
                    variant: addExternalType.ADD_PROJ_TO_TEAM,
                    textDescription: externalDataType.PROJ,
                  })
                }
              >
                Add Project by UUID
              </ContextMenuItem>
            </PermGuard>
            <PermGuard
              scopes={[
                Permissions.TEAM_USER_GRANT,
                Permissions.TEAM_USER_ADD,
                Permissions.TEAM_USER_REMOVE,
                Permissions.TEAM_USER_MODIFY,
              ]}
              itemId={row.original.id}
            >
              <ContextMenuItem
                onClick={() =>
                  setOpenUuid({
                    ...row.original,
                    type: externalActionType.ADD,
                    variant: addExternalType.ADD_USER_TO_TEAM,
                    textDescription: externalDataType.USER,
                  })
                }
              >
                Add User by UUID
              </ContextMenuItem>
            </PermGuard>
          </>
        )}
        {external && (
          <>
            <WidgetDivider />
            <ContextMenuItem onClick={() => setOpenUuid({ ...row.original.team, type: externalActionType.REMOVE })}>
              Remove from Account
            </ContextMenuItem>
          </>
        )}
      </ContextMenuList>
    </ContextMenu>
  );

  TeamActions.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    row: PropTypes.object.isRequired,
  };

  const TeamsContainer = () => (
    <>
      <GeneralTable
        table={table}
        selectedRow={selectedRow}
        handleRowClick={handleRowClick}
        rowComponent={TeamActions}
      />
      <TablePagination table={table} />
      {openTeamInviteProject?.id && (
        <>
          <DialogProjectInvite
            isOpen={openTeamInviteProject?.id}
            handleClose={handleTeamInviteProjectClose}
            objectId={openTeamInviteProject.id}
            type={typeInviteData.PROJET}
            itemName={openTeamInviteProject.name}
            textDescription="Team"
          />
        </>
      )}

      {openTeamInviteUser && (
        <DialogTeamInviteUser
          isOpen={openTeamInviteUser}
          onCancel={() => setOpenTeamInviteUser(false)}
          title="User"
          teamId={selectedTeamId}
        />
      )}
      {openUuid?.id && openUuid?.type === externalActionType.REMOVE && (
        <DeleteUuid externalData={openUuid} type={externalDataType.TEAM} handleClose={handleCloseUuid} />
      )}
      {openUuid?.id && openUuid?.type === externalActionType.COPY && (
        <CopyUuidAlert id={openUuid.id} handleClose={() => setOpenUuid(false)} />
      )}
      {validateRole.open && (
        <ViewRoleValidation
          action={validateRole.action}
          data={validateRole.data}
          callBack={validateRole.callBack}
          handleClose={() => setValidateRole({ open: false, callBack: () => {} })}
        />
      )}
    </>
  );

  if (loading && teams.length === 0) {
    return (
      <AccountSettingsContainer
        title={<AdminHeaderTitle buttonName="ADMINISTRATION" section={external ? 'EXTERNAL TEAMS' : 'TEAMS'} />}
        actions={[
          {
            icon: CirclePlusIcon,
            text: external ? 'Add Via UUID' : 'Create New',
            onClick: () => {
              if (external) {
                setOpenUuid({ type: externalActionType.ADD });
              } else {
                setOpen(true);
              }
            },
          },
        ]}
        showSidebar
      >
        <Loader loading={loading} height={250} />
        {open && <ModalNewTeam isOpen={open} onConfirm={handleConfirm} onCancel={handleCancel} />}
        {openUuid?.type === externalActionType.ADD && (
          <AddUuid
            handleClose={handleCloseUuid}
            type={externalDataType.TEAM}
            variant={openUuid.variant}
            item={openUuid}
          />
        )}
      </AccountSettingsContainer>
    );
  }
  return (
    <AccountSettingsContainer
      title={<AdminHeaderTitle buttonName="ADMINISTRATION" section={external ? 'EXTERNAL TEAMS' : 'TEAMS'} />}
      actions={[
        {
          icon: CirclePlusIcon,
          text: external ? 'Add Via UUID' : 'Create New',
          onClick: () => {
            if (external) {
              setOpenUuid({ type: externalActionType.ADD });
            } else {
              setOpen(true);
            }
          },
        },
      ]}
      showSidebar
    >
      <Card noPadding>
        <TopBar
          options={external ? externalTeamsOptions : teamOptions}
          searchValue={search}
          handleSearch={handleSearch}
          activatedFilter={filters.activated}
        />
        {teams.length > 0 ? <TeamsContainer /> : <EmptyTeamsContainer />}
      </Card>
      {open && <ModalNewTeam isOpen={open} onConfirm={handleConfirm} onCancel={handleCancel} />}
      {openUuid?.type === externalActionType.ADD && (
        <AddUuid
          handleClose={handleCloseUuid}
          type={externalDataType.TEAM}
          variant={openUuid.variant}
          item={openUuid}
        />
      )}
    </AccountSettingsContainer>
  );
};

Teams.propTypes = {
  external: PropTypes.bool,
};

Teams.defaultProps = {
  external: false,
};

import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  pagination: {
    position: 'relative',
  },
  paginationContent: (props) => ({
    padding: '1rem',
    display: 'inline-grid',
    width: '100%',
    gridTemplateColumns: '[col1] auto [col2] auto',
    borderTop: `1px solid ${theme.palette.primary[800]}`,
    backgroundColor: theme.palette.primary[1300],
    ...(props.overflowX && { overflowX: 'auto' }),
  }),
  text: {
    display: 'flex',
    alignItems: 'center',
  },
  tablePagination: {
    display: 'flex',
    marginTop: '10px',
    width: '100%',
    justifyContent: 'end',
  },
  pages: {
    gridColumn: 'col2',
    display: 'inline-flex',
    justifySelf: 'center',
    gap: '4px',
    margin: '0 5px',
  },
  number: {
    padding: '0px 2px 0px 2px',
  },
  next: {
    gridColumn: 'col3',
  },
  moreButton: {
    padding: '4px 8px',
    letterSpacing: '2px',
    fontWeight: 900,
    position: 'relative',
  },
  morePages: (props) => ({
    position: 'absolute',
    bottom: props.overflowX ? '85%' : '120%',
    ...(props.overflowX && { left: '25%' }),
    right: props.overflowX ? '25%' : 0,
    borderRadius: '6px',
    border: `1px solid ${theme.palette.primary.light}`,
    padding: theme.spacing(0.3),
    background: theme.palette.primary.dark,
    maxHeight: '20vh',
    overflowY: 'auto',
    ...(!props.overflowX && { width: '200px' }),
    '& > button': {
      margin: '5px',
    },
  }),
  button: {
    padding: '1px 10px',
  },
});

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TeamService from 'services/TeamService';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { ModalHeaderIcon } from 'components/ModalHeaderIcon/ModalHeaderIcon';
import { UsersIcon } from 'components/Icons';

export const ModalEditTeamDescription = ({ handleConfirm, handleCancel, isOpen, teamId }) => {
  const [name, setName] = useState('');
  const [desc, setDesc] = useState('');
  const [submitError, setSubmitError] = React.useState('');

  useEffect(() => {
    const getTeam = async () => {
      const newResult = await TeamService.getTeam(teamId);
      setName(newResult.data.data.name);
      setDesc(newResult.data.data.description);
    };
    getTeam();
  }, []);

  const onSubmitProject = () => {
    const updateTeam = async () => {
      try {
        await TeamService.updateTeam(teamId, {
          name,
          description: desc,
        });
      } catch (err) {
        const result = err.response.data.message;
        setSubmitError(result);
      }
      // update state with new roles
      handleConfirm();
    };
    updateTeam();
  };

  return (
    <Dialog open={isOpen} maxWidth="sm" onClose={handleCancel}>
      <DialogTitle>
        <ModalHeaderIcon icon={UsersIcon} />
        <Typography variant="h4">Edit {name}</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <InputLabel>Name *</InputLabel>
            <TextField
              placeholder="Current Name"
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Description *</InputLabel>
            <TextField
              placeholder="Team Description"
              value={desc}
              onChange={(event) => {
                setDesc(event.target.value);
              }}
              required
              fullWidth
            />
          </Grid>
        </Grid>
        {submitError && <Typography color="error">{submitError}.</Typography>}
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button color="primary" variant="contained" onClick={handleCancel} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button color="secondary" variant="contained" onClick={() => onSubmitProject()} fullWidth>
              Save
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

ModalEditTeamDescription.propTypes = {
  handleConfirm: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  teamId: PropTypes.string.isRequired,
};

import PropTypes from 'prop-types';
import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import { ModalHeaderIcon } from 'components/ModalHeaderIcon/ModalHeaderIcon';
import { AlertTriangleIcon } from 'components/Icons';

export const DialogUnsaved = ({ isOpen, onCancel, onConfirm, activeBtn, content }) => {
  // ---------------- validate close
  const close = () => {
    if (onCancel && onConfirm) {
      onCancel();
    } else if (onCancel && !onConfirm) {
      onCancel();
    } else if (onConfirm && !onCancel) {
      onConfirm();
    }
  };

  return (
    <Dialog open={isOpen} maxWidth="xs" onClose={close}>
      <DialogContent sx={{ paddingBottom: 0 }}>
        <ModalHeaderIcon icon={AlertTriangleIcon} text="Progress Not Saved" color="warning" />
        <Typography mt={1}>{content}</Typography>
      </DialogContent>

      <DialogActions>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            {onCancel && (
              <Button color="primary" variant="contained" onClick={onCancel} fullWidth>
                No, Continue
              </Button>
            )}
          </Grid>
          <Grid item xs={6}>
            <Button color="warning" variant="contained" onClick={onConfirm} disabled={activeBtn} fullWidth>
              Yes, Save Now
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
DialogUnsaved.propTypes = {
  /**
   * is a boolean that checks if the modal is open or not
   */
  isOpen: PropTypes.any,
  /**
   * this is what action is needed to be handled when clicking confirm
   */
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  activeBtn: PropTypes.bool,
  content: PropTypes.node,
};

DialogUnsaved.defaultProps = {
  isOpen: false,
  onConfirm: null,
  onCancel: null,
  activeBtn: false,
  content: null,
};

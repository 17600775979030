import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  changeBack: {
    backgroundColor: theme.palette.primary.dark,
    '& .MuiDialogContent-root': {
      padding: 0,
      paddingTop: 0,
    },
  },
  form: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary[1600],
    borderRadius: 8,
    height: 'calc(100% - 4rem)',
    display: 'flex',
    flexDirection: 'column',
    '& .MuiTableContainer-root': {
      maxHeight: '30vh',
      overflowY: 'auto',
      '& td, & th': {
        padding: '4px 15px',
      },
    },
  },
  fields: {
    padding: theme.spacing(1),
    backgroundColor: 'rgba(35, 38, 42, 0.85)',
    borderRadius: 8,
    margin: theme.spacing(1),
    marginLeft: 0,
    height: 'calc(100% - 4rem)',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
      marginTop: 0,
    },
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1),
      marginTop: 0,
    },
  },
  headerTabs: {
    margin: `${theme.spacing(1)} 0`,
    width: '100%',
    '& .MuiTabs-flexContainer': {
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
    '& .MuiButtonBase-root': {
      width: '50%',
      padding: '8px 12px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        display: 'block',
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        display: 'block',
      },
    },
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.light,
    },
  },
  grayText: {
    color: theme.palette.primary[300],
    fontWeight: 400,
  },
  reorderButton: {
    color: theme.palette.primary[300],
    padding: 2,
  },
  buttonActions: {
    display: 'flex',
    alignItems: 'end',
    flex: 1,
    '& .actionsContent': {
      width: '100%',
    },
  },
}));

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { OpenContext } from './NavContext';
import { useStyles } from './styles';

export const Nav = ({ children }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <OpenContext.Provider value={{ open, setOpen }}>
      <div className={`${classes.sidebar} ${open ? classes.open : ''}`}>
        {false && (
          <div className={classes.navHeader}>
            <button
              className={classes.navButton}
              type="button"
              onClick={() => {
                setOpen(!open);
              }}
              aria-label="Open"
            >
              <FontAwesomeIcon icon={faBars} size="2x" />
            </button>
          </div>
        )}
        <div className={classes.navBody}>{children}</div>
      </div>
    </OpenContext.Provider>
  );
};

Nav.propTypes = {
  children: PropTypes.node,
};

Nav.defaultProps = {
  children: null,
};

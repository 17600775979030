import axios from 'axios';
import ApiService from './ApiService';

export default class AccountService {
  static getTransactions = async (acctEmail) =>
    axios.get(`https://api.stripe.com/v1/checkout/sessions?customer_details[email]=${acctEmail}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_STRIPE_API_KEY}`,
      },
    });

  static getInvoiceDetails = async (invoiceId) =>
    axios.get(`https://api.stripe.com//v1/invoices/${invoiceId}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_STRIPE_API_KEY}`,
      },
    });

  static getCreditUsage = async (filters) =>
    ApiService.get(`/credits/all`, { page: filters.page, page_size: filters.page_size, search: filters.search });

  static getCreditAvg = async () => ApiService.get('credits/average');

  static getCreditGraph = async () => ApiService.get('credits/graph');

  static getPaymentResult = async (transactionId) => ApiService.get(`/billing/${transactionId}`);

  static addBill = async (data) => ApiService.post(`/billing`, data);

  static getPaymentsPerAccount = async (acct) => ApiService.get(`/billing/${acct}`);

  static getAllPayments = async () => ApiService.get(`/billing`);

  static updateBill = async (data) => ApiService.patch(`/billing`, data);
}

/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ViewRoleValidation } from 'components/ViewRoleValidation/ViewRoleValidation';
import { usePagination, useSortBy, useTable } from 'react-table';
import ProjectService from 'services/ProjectService';
import { Permissions } from 'lib/permissions';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Loader } from 'components/Loader';
import { GeneralTable } from 'components/GeneralTable/GeneralTable';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import { setAssetSharing } from 'slices/profileProjectAssetReducer';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteProjectRelation } from './DeleteProjectRelation';
import { useStyles } from './styles';

export const Headers = [
  {
    Header: 'Share With',
    accessor: (rowData) => {
      const { name } = rowData.project;
      return name || '';
    },
  },
  {
    Header: 'Organization',
    accessor: (rowData) => rowData?.project?.org?.name || '',
  },
  {
    Header: 'Began On',
    accessor: (rowData) => {
      const { createdAt } = rowData;
      return createdAt ? moment(createdAt).format('MM/DD/YY') : 'date not found';
    },
  },
];

export const ManageSharedAssets = ({ asset, modalContent, external, handleClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [validateRole, setValidateRole] = useState({ open: false, callBack: () => {} });
  const [openRemove, setOpenRemove] = useState(false);
  const [deleteOne, setDeleteOne] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { projId } = useParams();
  const { assetSharing } = useSelector((state) => state.profileProjectAssets);

  const table = useTable(
    {
      columns: Headers,
      data: assetSharing,
    },
    useSortBy,
    usePagination
  );

  // function to refres or not the assets in content page
  const handlePreClose = () => {
    handleClose(deleteOne);
  };

  // get list of project from API
  const handleOpen = async () => {
    setIsLoading(true);
    const response = await ProjectService.getSharedProjectAsset(asset.project?.id, asset.id, external);
    setIsLoading(false);
    dispatch(setAssetSharing(response.data.data));
    setOpen(true);
  };

  // handle list of project after delete a project
  const handleCallBackDelete = (item) => {
    setOpenRemove(false);
    const auxProjects = [...assetSharing];
    const index = assetSharing.findIndex((element) => element.id === item.id);
    if (index !== -1) {
      auxProjects.splice(index, 1);
      dispatch(setAssetSharing({ data: assetSharing[index], action: 'delete' }));
    }
    // refresh list of assets
    if (!modalContent) {
      handleClose(projId && projId !== asset.project?.id && !auxProjects.find((proj) => proj.project.id === projId));
    } else {
      setDeleteOne(true);
    }
  };

  useEffect(() => {
    handleOpen();
  }, []);

  const ShareActions = ({ row }) => (
    <Checkbox
      checked
      color="secondary"
      onChange={() =>
        setValidateRole({
          open: true,
          action: [Permissions.PROJ_MODIFY_CONTENT],
          callBack: () => setOpenRemove(row.original),
        })
      }
    />
  );

  ShareActions.propTypes = {
    row: PropTypes.object.isRequired,
  };

  const EmptyProjects = () => (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <Typography mt={2} mb={1} variant="h5">
        This asset has not been shared yet.
      </Typography>
    </div>
  );

  const TableContent = () => (
    <div className={classes.content}>
      <GeneralTable
        table={table}
        rowComponent={ShareActions}
        selectRow={false}
        actionHeaderText="Uncheck to Revoke"
        autoHeight
      />
    </div>
  );

  return (
    <>
      {!modalContent &&
        (isLoading ? <Loader height={10} loading /> : assetSharing?.length > 0 ? <TableContent /> : <EmptyProjects />)}
      {modalContent && (
        <>
          <Dialog open={open} onClose={handlePreClose} maxWidth="sm">
            <DialogTitle>Manage Access</DialogTitle>
            <DialogContent>{assetSharing?.length > 0 ? <TableContent /> : <EmptyProjects />}</DialogContent>
            <DialogActions>
              <Button color="primary" variant="contained" onClick={handlePreClose} size="large">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      {validateRole.open && (
        <ViewRoleValidation
          action={validateRole.action}
          data={{ ...asset.project, asset }}
          callBack={validateRole.callBack}
          handleClose={() => setValidateRole({ open: false, callBack: () => {} })}
        />
      )}
      {openRemove && (
        <DeleteProjectRelation
          project={openRemove}
          asset={asset}
          isIcon
          handleCallBack={handleCallBackDelete}
          handleCancel={() => setOpenRemove(false)}
        />
      )}
      {modalContent && <LoadingOverlay loading={isLoading} />}
    </>
  );
};

ManageSharedAssets.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  asset: PropTypes.object,
  modalContent: PropTypes.bool,
  external: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
};

ManageSharedAssets.defaultProps = {
  asset: null,
  modalContent: false,
  external: false,
};

import React from 'react';
import PropTypes from 'prop-types';
import { ModalHeaderIcon } from 'components/ModalHeaderIcon/ModalHeaderIcon';
import { Typography } from '@mui/material';
import { PencilIcon, SquareCheckIcon } from 'components/Icons';
import { actions } from '../helpers';

export const Header = ({ action, openAddFields, tab }) => (
  <>
    <ModalHeaderIcon
      icon={action === actions.CREATE ? SquareCheckIcon : PencilIcon}
      text={!tab ? `${action === actions.CREATE ? 'Create' : 'Edit'} New Inspection` : 'Add Fields'}
    />
    <Typography variant="body2" mt={0.5} mb={openAddFields && 0.5}>
      {!tab
        ? 'By default, an inspection will include the ability to zoom into points of interest, capture the exact Lat/Long and set a markers, title and comment on the findings and add tags.'
        : "Click the 'Add New' button below to add your own, unique fileds to the inspection. You will set the fields's parameters in the column to the right. To make adjustments, click the field name below."}
    </Typography>
  </>
);

Header.propTypes = {
  openAddFields: PropTypes.bool.isRequired,
  action: PropTypes.string.isRequired,
  tab: PropTypes.number.isRequired,
};

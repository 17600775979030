import React from 'react';
import { Typography } from '@mui/material';

export const DisclaimerExporting = () => (
  <Typography variable="caption" style={{ textAlign: 'justify', fontSize: '8px' }}>
    <strong style={{ marginRight: '5px' }}>Disclaimer:</strong>
    Please be aware that when you export or email images and text from CartoVid observations, this information becomes
    accessible outside of the CartoVid network to anyone who has the file. This is because exported files are designed
    to be self-contained and accessible without requiring access to the CartoVid platform.
  </Typography>
);

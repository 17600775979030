import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import ProjectService from 'services/ProjectService';
import UserService from 'services/UserService';
import TeamService from 'services/TeamService';
import { formatDateRange, formatSortBy } from './helpers';

export const getProjects = createAsyncThunk('allProjects/getProjects', async (filters) => {
  const newFilters = {
    strict: true,
    ...filters,
    ...formatSortBy(filters.sort_by),
    ...formatDateRange(filters.date_range),
    include: ['organization', 'collaborators', 'account'],
  };

  delete newFilters.date_range;
  delete newFilters.activated;
  if (newFilters.isOpen === undefined || newFilters.isOpen === null) delete newFilters.isOpen;
  try {
    const response = await ProjectService.getProjects(newFilters);
    return response.data;
  } catch (e) {
    throw new Error('Error during request for projects...');
  }
});

export const getProject = createAsyncThunk(
  'allProjects/getProject',
  async (projId) => {
    try {
      const [projDetails, projUsers, projTeams] = await Promise.all([
        await ProjectService.getProject(projId),
        await UserService.getUsers({ projectId: projId }),
        await TeamService.getTeams({ projectId: projId }),
      ]);
      return { projDetails, projUsers, projTeams };
    } catch (error) {
      throw new Error('Error during request for single project...');
    }
  },
  {
    condition: (projId, { getState }) => getState().adminProjects.panels.projId !== projId,
  }
);

export const getShareProjects = createAsyncThunk('allProjects/getShareProjects', async (filters) => {
  const newFilters = {
    ...filters,
    participantLevel: 'assetsAdminister',
    page_size: 100,
  };
  try {
    const response = await ProjectService.getProjects(newFilters);
    return response.data;
  } catch (e) {
    throw new Error('Error during request for projects...');
    // return 'Error during request for projects';
  }
});

export const getProjectUsers = createAsyncThunk('allProjects/getProjectUsers', async (projId) => {
  try {
    const response = await ProjectService.getProjectUsers(projId);
    return response.data;
  } catch (e) {
    throw new Error('Error during request for projects...');
    // return 'Error during request for projects';
  }
});

export const preloadFilterDatasets = createAsyncThunk(
  'allProjects/preloadFilterDatasets',
  async (arg, { getState }) => {
    try {
      const [projects, accounts, organizations, users, teams] = await Promise.all([
        await ProjectService.getProjects({ page_size: 500, participantLevel: 'administer' }),
        await ProjectService.getProjectAccounts({ participantLevel: 'all' }),
        await ProjectService.getProjectOrganizations({ participantLevel: 'all' }),
        await UserService.getUsers({ page_size: 500 }),
        await TeamService.getTeams({ page_size: 500, userId: getState().auth.userId }).catch(() => ({
          data: { data: [] },
        })),
      ]);
      return {
        accounts: accounts.data.data,
        projects: projects.data.data,
        organizations: organizations.data.data,
        users: users.data.data,
        teams: teams.data.data,
      };
    } catch (err) {
      throw new Error('error loading filter data');
    }
  },
  {
    condition: (_, { getState }) => getState().allProjects.panels.search.loaded === false,
  }
);

const orderByName = (array) =>
  array.sort((a, b) => {
    const nameA = a.label.toUpperCase(); // ignore upper and lowercase
    const nameB = b.label.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

const initialState = {
  error: null,
  loading: false,
  data: [],
  shares: [],
  users: [],
  loadingShare: false,
  totalCount: 0,
  panels: {
    projId: null,
    loading: true,
    detail: {},
    projUsers: [],
    projTeams: [],
    search: {
      loaded: false,
      projects: [],
      accounts: [],
      organizations: [],
      users: [],
      teams: [],
    },
  },
  filters: {
    page: 1,
    page_size: 10,
    sort_by: {},
    search: '',
    acctId: '',
    organizationId: '',
    date_range: null,
    isOpen: null,
  },
};

const projectSlice = createSlice({
  name: 'allProjects',
  initialState,
  reducers: {
    resetAllProjects: () => initialState,
    setProject(state, action) {
      state.panels.detail = action.payload;
    },
    setPageFilter(state, action) {
      state.filters.page = action.payload;
    },
    setSearch(state, action) {
      state.filters.search = action.payload;
      state.filters.page = 1;
    },
    setAcctId(state, action) {
      state.filters.acctId = action.payload.value;
    },
    setOrganizationId(state, action) {
      state.filters.organizationId = action.payload.value;
    },
    setSortBy(state, { payload }) {
      const { label, value } = payload;

      if (value === '' || value === null) {
        delete state.filters.sort_by[label];
      } else {
        state.filters.sort_by[label] = value;
      }
    },
    setFilterValue(state, { payload }) {
      const { param, value } = payload;
      if ((value === '' || value === null) && param !== 'date_range') {
        delete state.filters[param];
        return;
      }
      state.filters[param] = value;
    },
    setDateRange(state, { payload }) {
      state.filters.date_range = payload;
    },
    setFilters(state, action) {
      const filter = { ...state.filters, ...action.payload, page: 1 };
      if (!filter?.participantLevel) delete filter?.participantLevel;
      state.filters = filter;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProjects.pending, (state) => {
      state.loading = true;
      state.panels.userId = null;
    });
    builder.addCase(getProjects.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.totalCount = action.payload.meta.totalCount;
      state.error = null;
      state.loading = false;
    });

    builder.addCase(getProjects.rejected, (state) => {
      state.data = [];
      state.totalCount = 0;
      state.error = 'There was an error during the request.';
      state.loading = false;
    });
    builder.addCase(getProject.pending, (state) => {
      state.panels.loading = true;
      state.panels.projId = null;
      state.panels.projUsers = [];
      state.panels.projTeams = [];
    });
    builder.addCase(getProject.fulfilled, (state, action) => {
      state.panels.detail = action.payload.projDetails.data.data;
      state.error = null;
      state.panels.loading = false;
      state.panels.projId = action.payload.projDetails.data.data.id;
      state.panels.projUsers = action.payload.projUsers.data.data;
      state.panels.projTeams = action.payload.projTeams.data.data;
    });
    builder.addCase(getProject.rejected, (state) => {
      state.panels.detail = null;
      state.panels.loading = null;
      state.panels.projId = null;
      state.panels.projUsers = [];
      state.panels.projTeams = [];
    });
    builder.addCase(getShareProjects.pending, (state) => {
      state.loadingShare = true;
    });
    builder.addCase(getShareProjects.fulfilled, (state, action) => {
      state.loadingShare = false;
      state.shares = action.payload.data;
    });
    builder.addCase(getShareProjects.rejected, (state) => {
      state.loadingShare = false;
      state.shares = null;
    });
    builder.addCase(getProjectUsers.pending, (state) => {
      state.loadingUsers = true;
    });
    builder.addCase(getProjectUsers.fulfilled, (state, action) => {
      state.loadingUsers = false;
      state.users = action.payload.data;
    });
    builder.addCase(getProjectUsers.rejected, (state) => {
      state.loadingUsers = false;
      state.users = [];
    });
    builder.addCase(preloadFilterDatasets.pending, (state) => {
      state.panels.search.loaded = false;
    });
    builder.addCase(preloadFilterDatasets.rejected, (state) => {
      state.panels.search.users = [];
      state.panels.search.teams = [];
      state.panels.search.projects = [];
      state.panels.search.accounts = [];
      state.panels.search.organizations = [];
      state.panels.search.loaded = false;
    });
    builder.addCase(preloadFilterDatasets.fulfilled, (state, { payload }) => {
      const { projects, accounts, organizations, users, teams } = payload;
      state.panels.search.loaded = true;
      const newProjects = projects.map((proj) => ({
        label: proj.name,
        value: proj.id,
      }));
      let newAccounts = accounts.map((proj) => ({
        label: proj.name,
        value: proj.id,
      }));
      let newOrganizations = organizations.map((proj) => ({
        label: proj.name,
        value: proj.id,
      }));
      newAccounts = orderByName(newAccounts);
      newOrganizations = orderByName(newOrganizations);
      state.panels.search.projects = newProjects;
      state.panels.search.accounts = newAccounts;
      state.panels.search.organizations = newOrganizations;
      const newTeams = teams.map((team) => ({
        label: team.name,
        value: team.id,
      }));
      state.panels.search.teams = newTeams;
      const newUsers = users.map((user) => ({
        label: `${user.firstName} ${user.lastName}`,
        value: user.id,
      }));
      state.panels.search.users = newUsers;
    });
  },
});

export const {
  setSearch,
  setProject,
  setAcctId,
  setOrganizationId,
  setSortBy,
  setPageFilter,
  setFilterValue,
  setDateRange,
  updateProject,
  resetAllProjects,
  setFilters,
  // eslint-disable-next-line prettier/prettier
} = projectSlice.actions;

export const dateRangeSelector = createSelector(
  (state) => state.allProjects.filters,
  (state) => {
    const dates = state.date_range;

    if (!dates) {
      return dates;
    }

    return dates.map((date) => (date ? new Date(date) : null));
  }
);

export default projectSlice.reducer;

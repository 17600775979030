import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { SearchIcon } from 'components/Icons';
import { Button, IconButton, TextField } from '@mui/material';
import { setSearch } from 'slices/findingReducer';
import { useStyles } from './styles';

export const FindingSearch = ({ small }) => {
  const classes = useStyles({ small });
  const dispatch = useDispatch();
  const search = useSelector((state) => state.findings.filters.search || '');
  const [open, setOpen] = useState(false);

  return (
    <>
      {!open ? (
        <Button
          color="primary"
          variant="contained"
          onClick={() => setOpen(!open)}
          className={small ? classes.small : ''}
        >
          <SearchIcon size={15} />
        </Button>
      ) : (
        <TextField
          variant="outlined"
          placeholder="Search Notes by Name, Desc and Creator"
          value={search}
          className={classes.findingSearch}
          InputProps={{
            startAdornment: (
              <IconButton onClick={() => setOpen(false)}>
                <SearchIcon size={15} />
              </IconButton>
            ),
          }}
          onChange={(e) => dispatch(setSearch(e.target.value))}
        />
      )}
    </>
  );
};

FindingSearch.propTypes = {
  small: PropTypes.bool,
};

FindingSearch.defaultProps = {
  small: false,
};

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SearchIcon } from 'components/Icons';
import { Button, IconButton, TextField } from '@mui/material';
import { setAssetFilter, setObservationsFilter } from 'slices/observationsReducer';
import { useStyles } from '../styles';

export const ProjectAssetObservationsSearch = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { observations, assetFilters } = useSelector((state) => state.assetObservations);
  const [open, setOpen] = useState(false);

  const filter = (value) => {
    dispatch(setAssetFilter({ search: value }));
    let auxObservations = [...observations];
    if (value) {
      auxObservations = [...auxObservations].filter(
        (item) =>
          item.name.toLowerCase().includes(value.toLowerCase()) ||
          item.description.toLowerCase().includes(value.toLowerCase())
      );
    }
    dispatch(setObservationsFilter(auxObservations));
  };

  return (
    <>
      {!open && !assetFilters?.search ? (
        <Button color="primary" variant="contained" onClick={() => setOpen(!open)} className={classes.openButton}>
          <SearchIcon size={15} />
        </Button>
      ) : (
        <TextField
          variant="outlined"
          placeholder="Search"
          value={assetFilters?.search}
          className={classes.observationSearch}
          InputProps={{
            startAdornment: (
              <IconButton onClick={() => setOpen(false)}>
                <SearchIcon size={15} />
              </IconButton>
            ),
          }}
          onChange={(e) => filter(e.target.value)}
        />
      )}
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { SidebarPanel } from 'components/SidebarPanel';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectInspections, resetInspections } from 'slices/projectInspectionReducer';
import { useSidebar } from 'components/SidebarProvider/SidebarProvider';
import { SaveInspection } from '../SaveInspection/SaveInspection';
import { actions, inspectionType } from '../helpers';
import { Inspections } from '../Inspections/Inspections';
import { InspectionsHeader } from './InspectionsHeader';
import { SearchPanelInspections } from './SearchPanelInspections/SearchPanelInspections';

let debounceTimeout = null;

export const ProjectInspections = () => {
  const dispatch = useDispatch();
  const { loading, data, filters } = useSelector((state) => state.projectInspections);
  const { id: projectId, name } = useSelector((state) => state.projectInspections.panels.project);
  const [action, setAction] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const { displayPanel, setFullWidth } = useSidebar({
    open: false,
    config: {
      onClose: () => {},
      search: {
        show: true,
        component: SearchPanelInspections,
        background: true,
      },
    },
  });

  const onCancel = () => {
    setAction(null);
  };

  useEffect(() => {
    clearTimeout(debounceTimeout);
    const newFilters = {
      ...filters,
      projectId,
      sort_by: Object.values(filters.sort_by),
      include: ['uploader', 'type'],
    };
    debounceTimeout = setTimeout(() => {
      dispatch(getProjectInspections(newFilters));
    }, 300);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [filters]);

  useEffect(
    () => () => {
      dispatch(resetInspections());
    },
    []
  );

  return (
    <SidebarPanel
      title="Project Inspections"
      header={<InspectionsHeader handleCreation={() => setAction({ type: actions.CREATE })} projectName={name} />}
      detail
      loading={loading && !data.length}
    >
      <Inspections type={inspectionType.PROJECT} />
      {action?.type === actions.CREATE && (
        <SaveInspection
          onCancel={onCancel}
          onConfirm={onCancel}
          action={action?.type}
          inspection={action.inspection}
          actionType={inspectionType.PROJECT}
          objectId={projectId}
        />
      )}
    </SidebarPanel>
  );
};

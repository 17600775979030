import React, { useState } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Table } from 'components/Table';
import { useFormik } from 'formik';
import { Form } from 'lib/form';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { v4 } from 'uuid';

const validationSchema = Yup.object({
  email: Yup.string().email().required('Email is required'),
  name: Yup.string().min(4).required('Name is required'),
});

export const EmailDialog = ({ list, emails, type, handleChangeEmail, handleRemove }) => {
  const [open, setOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
    },
    validationSchema,
    onSubmit(values) {
      handleChangeEmail(`${type}s`, { value: values.email, label: values.name });
      formik.handleChange('name')('');
      formik.handleChange('email')('');
    },
  });

  const { handleChange, handleBlur, handleSubmit, values } = formik;

  return (
    <>
      <Button color="secondary" variant="contained" onClick={() => setOpen(true)} title={`Add ${type}s`} size="large">
        Add {`${type}s`}
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm">
        <DialogTitle>
          <Typography variant="h4">Add {`${type}s`}</Typography>
        </DialogTitle>
        <DialogContent>
          {type === 'email' ? (
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <InputLabel>
                  <Typography>Email *</Typography>
                </InputLabel>
                <TextField
                  id="email"
                  name="email"
                  value={values.email}
                  {...Form.fieldErrorHelper(formik, 'email')}
                  error={!!(formik.touched.email && formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email ? formik.errors.email : null}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel>
                  <Typography>Name *</Typography>
                </InputLabel>
                <TextField
                  id="name"
                  name="name"
                  value={values.name}
                  {...Form.fieldErrorHelper(formik, 'name')}
                  error={!!(formik.touched.name && formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name ? formik.errors.name : null}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} align="left">
                <Button color="secondary" variant="contained" onClick={handleSubmit} size="large">
                  Add
                </Button>
              </Grid>
            </Grid>
          ) : (
            <>
              <InputLabel>
                <Typography>{`Select ${type}`}</Typography>
              </InputLabel>
              <FormControl fullWidth sx={{ minWidth: '300px' }}>
                <Autocomplete
                  options={list}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(e, value) => {
                    handleChangeEmail(`${type}s`, value?.value ? value?.value : '');
                  }}
                />
              </FormControl>
            </>
          )}
          <div style={{ marginTop: '1rem' }}>
            <Table>
              <TableBody>
                {emails[`${type}s`].map((item) => (
                  <TableRow key={`${v4()}${new Date().getMilliseconds()}`}>
                    <TableCell>{item.label}</TableCell>
                    {type === 'user' || (type === 'email' && <TableCell>{item.value}</TableCell>)}
                    <TableCell align="center">
                      <IconButton color="error" onClick={() => handleRemove(`${type}s`, item.value)}>
                        <FontAwesomeIcon icon={faTrash} size="lg" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={() => setOpen(false)} size="large">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

EmailDialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  list: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  emails: PropTypes.array,
  type: PropTypes.string,
  handleChangeEmail: PropTypes.func,
  handleRemove: PropTypes.func,
};

EmailDialog.defaultProps = {
  list: [],
  emails: [],
  type: '',
  handleChangeEmail: () => {},
  handleRemove: () => {},
};

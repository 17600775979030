import { ContextMenu, ContextMenuContext } from 'components/ContextMenu';
import { ContextMenuList } from 'components/ContextMenuItems';
import { resolveUserInitials } from 'lib/resolve-user-initials';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { resetAdmins } from 'slices/adminAdminsReducer';
import { resetAdminProjects } from 'slices/adminProjectReducer';
import { resetAdminTeams } from 'slices/adminTeamsReducer';
import { resetAllProjects } from 'slices/allProjectReducer';
import { logOut, updateUserInfo } from 'slices/authReducer';
import { resetAdminContents } from 'slices/contentAdminReducer';
import { resetContents } from 'slices/contentReducer';
import { resetExternalUsers } from 'slices/externalUserReducer';
import { resetOrganizations } from 'slices/organizationReducer';
import { resetProfileProjects } from 'slices/profileProjectReducer';
import { resetProfileTeams } from 'slices/profileTeamsReducer';
import { resetProjectContents } from 'slices/projectContentReducer';
import { resetProjectTeams } from 'slices/projectPermissionsReducer';
import { resetProjects } from 'slices/projectReducer';
import { resetTeamsMembers } from 'slices/teamMembersReducer';
import { resetTeamsProjects } from 'slices/teamProjectsReducer';
import { resetUsers } from 'slices/userReducer';
import { ModalEditUser } from 'smartComponents/ModalEditUser';
import { CopyUuidAlert } from 'components/AddUuid/CopyUuidAlert/CopyUuidAlert';
import { resetExternalProjects } from 'slices/externalProjectReducer';
import { resetExternalTeams } from 'slices/externalTeamReducer';
import { resetExternalContents } from 'slices/externalContentReducer';
import { Button } from '@mui/material';
import { ContextMenuItem } from 'components/ContextMenuItem';
import { resetProfileInspections } from 'slices/profileInspectionsReducer';
import { resetInspections } from 'slices/inspectionReducer';
import { useStyles } from './styles';
import CartoVidExtraLight from '../../images/CartoVidExtraLight.svg';

const AvatarButton = ({ initials, image }) => {
  const classes = useStyles();
  const { buttonRef, toggleMenu } = useContext(ContextMenuContext);

  return (
    <Button color="primary" variant="contained" ref={buttonRef} className={classes.button} onClick={() => toggleMenu()}>
      {image ? <img src={image} alt="" className={classes.image} /> : initials}
    </Button>
  );
};

// const url = `http://placekitten.com/300/300`;
const url = null;

export const Header = () => {
  const classes = useStyles();
  const [openEditUser, setOpenEditUser] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { userId, fname, lname, email } = useSelector((state) => state.auth);
  const [uuidCopied, setUuidCopied] = useState(false);

  // const [accountOrg, setAccountOrg] = useState('');

  // useEffect(() => {
  //   const getAdminsDetails = async () => {
  //     try {
  //       const orgAccount = await AccountService.getAccount();
  //       setAccountOrg(orgAccount.data.data);
  //     } catch (err) {
  //       throw new Error(err);
  //     }
  //   };
  //   getAdminsDetails();
  // }, []);

  const logout = () => {
    localStorage.clear();
    dispatch(logOut());
    dispatch(resetAdmins());
    dispatch(resetAdminTeams());
    dispatch(resetUsers());
    dispatch(resetTeamsMembers());
    dispatch(resetTeamsProjects());
    dispatch(resetOrganizations());
    dispatch(resetContents());
    dispatch(resetAdminContents());
    dispatch(resetAdminProjects());
    dispatch(resetProfileProjects());
    dispatch(resetProfileTeams());
    dispatch(resetProjectTeams());
    dispatch(resetProjects());
    dispatch(resetProjectContents());
    dispatch(resetAllProjects());
    dispatch(resetExternalUsers());
    dispatch(resetExternalProjects());
    dispatch(resetExternalTeams());
    dispatch(resetExternalContents());
    dispatch(resetInspections());
    dispatch(resetProfileInspections());
    history.push('/');
  };

  // Manage the Open Add a New user Modal
  const handleEditUser = async (userData) => {
    dispatch(
      updateUserInfo({
        firstName: userData.firstName,
        lastName: userData.lastName,
        phone: userData.phone,
        description: userData.description,
      })
    );
    setOpenEditUser(false);
  };

  const handleCancelEditUser = () => {
    setOpenEditUser(false);
  };

  return (
    <div className={classes.header}>
      <img src={CartoVidExtraLight} style={{ width: 'auto', height: 30 }} alt="Logo" />
      {/* <Typography variant="h3">CartoVid</Typography> */}
      <ContextMenu>
        <AvatarButton initials={resolveUserInitials({ firstName: fname, lastName: lname, email })} image={url} />
        <ContextMenuList position="bottom-right">
          <ContextMenuItem
            onClick={() => {
              setOpenEditUser(true);
            }}
          >
            Profile
          </ContextMenuItem>
          <ContextMenuItem onClick={() => setUuidCopied(userId)}>Copy My UUID</ContextMenuItem>
          <ContextMenuItem onClick={logout}>Logout</ContextMenuItem>
        </ContextMenuList>
      </ContextMenu>
      {openEditUser && (
        <ModalEditUser
          isOpen={openEditUser}
          onConfirm={handleEditUser}
          onCancel={handleCancelEditUser}
          userId={userId}
        />
      )}
      {uuidCopied && <CopyUuidAlert id={uuidCopied} handleClose={() => setUuidCopied(false)} />}
    </div>
  );
};

AvatarButton.propTypes = {
  initials: PropTypes.string.isRequired,
  image: PropTypes.string,
};

AvatarButton.defaultProps = {
  image: null,
};

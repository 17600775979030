import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  button: {
    '&.MuiButtonBase-root': {
      display: 'flex',
      right: 4,
      top: -10,
      position: 'absolute',
      background: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.light}`,
      borderRadius: 20,
      padding: 4,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.primary.dark,
      },
      '& svg': {
        color: theme.palette.primary[100],
      },
    },
  },
});

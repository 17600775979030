import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  avatarRow: {
    display: 'flex',
    flexDirection: 'row',
    '& > div': {
      marginRight: '5px',
    },
  },
  radioGroupContainer: {
    paddingTop: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    '& > div > div': {
      minWidth: '200px',
    },
  },
});

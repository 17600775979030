import PropTypes from 'prop-types';
import React from 'react';
import { ListItem, ListItemButton, ListItemIcon } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { theme } from 'theme';

export const ContextMenuItem = ({ icon: Icon, iconSpacing, children, disabled, onClick }) => (
  <ListItem disablePadding>
    <ListItemButton
      disabled={disabled}
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
    >
      {(!!Icon || iconSpacing) && (
        <ListItemIcon sx={{ minWidth: '30px', color: theme.palette.white }}>
          <FontAwesomeIcon icon={Icon} size="lg" />
        </ListItemIcon>
      )}
      {children}
    </ListItemButton>
  </ListItem>
);

ContextMenuItem.propTypes = {
  icon: PropTypes.any,
  iconSpacing: PropTypes.bool,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

ContextMenuItem.defaultProps = {
  icon: null,
  iconSpacing: false,
  disabled: false,
  onClick: () => {},
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { createProject } from 'slices/NewProjectReducer';
import { getOrganizations } from 'slices/organizationReducer';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { InfoIcon, LayersIcon } from 'components/Icons';
import { ModalHeaderIcon } from 'components/ModalHeaderIcon/ModalHeaderIcon';
import { useGlobalStyles } from 'styles';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import { DialogMessage } from 'components/DialogMessage';

export const ModalProjectNew = ({ onConfirm, onCancel, isModalOpen }) => {
  const globalClasses = useGlobalStyles();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const rootOrganizations = useSelector((state) => state.organizations.data);
  const [submitError, setSubmitError] = React.useState('');
  const [openDialog, setOpenDialog] = React.useState(false);

  // const today = Moment(Date.now()).format('MMM Do YYYY');

  React.useEffect(() => {
    dispatch(getOrganizations({ page_size: 500 }));
  }, []);

  const rootOptions = rootOrganizations.map((org) => ({
    label: org.name,
    value: org.id,
  }));

  const closeConfirm = () => {
    onConfirm();
  };

  const saveProject = async (values) => {
    setLoading(true);

    try {
      await dispatch(createProject(values));
      setLoading(false);
      setOpenDialog('Project was successfully created');
    } catch (err) {
      const result = err.response.data.message;
      setSubmitError(result);
      setLoading(false);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      description: '',
      orgId: '',
      isVisible: false,
      isOpen: false,
      isZoomEnabled: false,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .matches(/^[A-Za-z0-9.\s]+$/, 'Name can only contain letters, numbers, and dots')
        .min(1, 'Name must contain at least one character')
        .max(255)
        .required('Name Required'),
      description: Yup.string()
        .matches(/^[A-Za-z0-9.\s]+$/, 'Description can only contain letters, numbers, and dots')
        .min(1, 'Description must contain at least one character')
        .max(255)
        .required('Description Required'),
      orgId: Yup.string().required('Organization Required'),
      isVisible: Yup.boolean(),
      isOpen: Yup.boolean(),
      isZoomEnabled: Yup.boolean(),
    }),
    onSubmit(values) {
      saveProject(values);
    },
  });

  const { values, handleChange, handleBlur, handleSubmit } = formik;

  // eslint-disable-next-line no-unused-vars
  const getInitials = () => {
    const names = values.name.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  return (
    <Dialog open={isModalOpen} onClose={onCancel} maxWidth="xs">
      <DialogTitle>
        <ModalHeaderIcon icon={LayersIcon} text="Create Project" />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <InputLabel>Name *</InputLabel>
            <TextField
              placeholder="Current Name"
              disabled={loading}
              id="name"
              name="name"
              value={values.name}
              error={!!(formik.touched.name && formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name ? formik.errors.name : null}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Description *</InputLabel>
            <TextField
              placeholder="Project Description"
              disabled={loading}
              value={values.description}
              id="description"
              name="description"
              error={!!(formik.touched.description && formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description ? formik.errors.description : null}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Organization *</InputLabel>
            <FormControl error={formik.touched.orgId && formik.errors.orgId} fullWidth>
              <Autocomplete
                options={rootOptions}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onBlur={formik.handleBlur}
                    required
                    placeholder="Select Org"
                    error={!!(formik.touched.orgId && formik.errors.orgId)}
                  />
                )}
                onChange={(e, value) => {
                  formik.handleChange('orgId')(value?.value ? value?.value : '');
                }}
                onBlur={handleBlur}
              />
              <FormHelperText>
                {formik.touched.orgId && formik.errors.orgId ? formik.errors.orgId : null}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={<Switch color="secondary" />}
                disabled={loading}
                label={
                  <div>
                    <Typography>Make project visible to organization</Typography>
                    <Typography variant="caption" className={globalClasses.greyTypography}>
                      If on, organization members can view data in project as if they were &#34;View Only&#34; project
                      member
                    </Typography>
                  </div>
                }
                value="isVisible"
                onChange={(event) => {
                  if (!event.target.checked && formik.values.isOpen) {
                    formik.setFieldValue('isOpen', false);
                  }
                  formik.setFieldValue('isVisible', event.target.checked);
                }}
                checked={formik.values.isVisible}
                style={{ marginBottom: '1rem' }}
              />
              <FormControlLabel
                control={<Switch color="secondary" />}
                disabled={!formik.values.isVisible}
                label={
                  <div>
                    <Typography>Set project as ‘Open’</Typography>
                    <Typography variant="caption" className={globalClasses.greyTypography}>
                      Allow others to join the project without directly adding them
                    </Typography>
                  </div>
                }
                value="isOpen"
                onChange={(event) => formik.setFieldValue('isOpen', event.target.checked)}
                checked={formik.values.isOpen}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            {submitError && (
              <FormControl error fullWidth>
                {submitError && <FormHelperText>{submitError}.</FormHelperText>}
              </FormControl>
            )}
          </Grid>
        </Grid>
        <DialogMessage
          title={openDialog}
          isOpen={openDialog}
          icon={InfoIcon}
          confirmText="Ok"
          onConfirm={closeConfirm}
        />
        <LoadingOverlay loading={loading} />
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button color="primary" variant="contained" onClick={onCancel} disabled={loading} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button color="secondary" variant="contained" disabled={loading} onClick={handleSubmit} fullWidth>
              Save
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

ModalProjectNew.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
};

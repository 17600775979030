import { Card } from 'components/Card/Card';
import { ClockIcon, InfoIcon } from 'components/Icons';
import { ProfileSettingsContainer } from 'components/ProfileSettingsContainer/ProfileSettingsContainer';
import { useSidebar } from 'components/SidebarProvider/SidebarProvider';
import { TablePagination } from 'components/TablePagination/TablePagination';
import { Typography } from 'components/Typography/Typography';
import { Time } from 'lib/time';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePagination, useSortBy, useTable } from 'react-table';
import { getContents, getSingleContent, setPageFilter, setSearch } from 'slices/contentReducer';
import { WidgetSplash } from 'components/WidgetSplash/WidgetSplash';
import SplashImage from 'images/UsersSplash.png';
import { GeneralTable } from 'components/GeneralTable/GeneralTable';
import { TopBar } from 'components/TopBar/TopBar';
import { useGlobalStyles } from 'styles';
import { SearchPanel } from './SearchPanel';
import { InfoPanel } from './InfoPanel';
import { useStyles } from './styles';

let debounceTimeout = null;
export const ProfileContent = () => {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data, filters, assetCount } = useSelector((state) => state.content);
  const [selectedRow, setSelectedRow] = React.useState('');
  const { fname, lname } = useSelector((state) => state.auth);
  const search = filters.search || '';
  const handleSearch = (e) => dispatch(setSearch(e.target.value));

  const tableColumns = [
    {
      Header: 'Type/Length',
      accessor: (rowData) => {
        const { createdAt, type, timeLength } = rowData;
        return (
          <div className={globalClasses.nameContainer}>
            <div>
              <ClockIcon size={25} />
            </div>
            <div>
              <Typography>{type === 'VIDEO' ? Time.humanReadable({ seconds: timeLength }) : type}</Typography>
              <Typography className={globalClasses.greyTypography}>{moment(createdAt).format('MM/DD/YYYY')}</Typography>
            </div>
          </div>
        );
      },
    },
    {
      Header: 'Name',
      accessor: (rowData) => rowData.name,
    },
    {
      Header: 'Primary Project',
      accessor: (rowData) => rowData.project && rowData.project.name,
    },
    {
      Header: 'Other Projects Sharing',
      accessor: (rowData) => rowData.projectSharedWith && rowData.projectSharedWith,
    },
  ];

  const columns = React.useMemo(() => tableColumns, []);

  const table = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: filters.page - 1,
        pageSize: filters.page_size,
      },
      pageCount: Math.ceil(assetCount / filters.page_size),
      totalCount: assetCount,
      pageLength: data.length,
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  const {
    state: { pageIndex, pageSize },
  } = table;

  const loadData = () => {
    const sendFilters = {
      ...filters,
    };
    dispatch(getContents(sendFilters));
  };

  React.useEffect(() => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      loadData();
    }, 150);
  }, [filters]);

  React.useEffect(() => {
    dispatch(setPageFilter(pageIndex + 1));
  }, [pageIndex, pageSize]);

  const { displayPanel, setHeaderPanels } = useSidebar({
    open: false,
    config: {
      search: {
        show: true,
        component: SearchPanel,
      },
      header: [],
    },
  });

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData.id);
    dispatch(
      getSingleContent({ projectId: rowData.original.projId, id: rowData.original.id, user: rowData.original.user })
    );
    setHeaderPanels([
      {
        name: 'info',
        icon: InfoIcon,
        component: InfoPanel,
      },
    ]);
    displayPanel('info');
  };

  const getHeaderText = () => {
    const header = fname === null || lname === null ? 'Profile Dashboard' : `${fname} ${lname}'s Dashboard`;
    return header.toUpperCase();
  };

  return (
    <ProfileSettingsContainer title={getHeaderText()} showSidebar>
      <Card noPadding>
        {data.length > 0 ? (
          <>
            <div className={classes.cardHeader}>
              <Typography variant="h3">Your Contributions</Typography>
              <Typography variant="caption">Items you’ve added to the system.</Typography>
            </div>
            <TopBar searchValue={search} handleSearch={handleSearch} activatedFilter={filters.activated} />
            <GeneralTable table={table} selectedRow={selectedRow} handleRowClick={handleRowClick} />
            <TablePagination table={table} />
          </>
        ) : (
          <>
            <TopBar searchValue={search} handleSearch={handleSearch} activatedFilter={filters.activated} />
            <WidgetSplash
              alt="Content Splash"
              title="There is currently no content. Create one to begin uploading content."
              image={SplashImage}
            />
          </>
        )}
      </Card>
    </ProfileSettingsContainer>
  );
};

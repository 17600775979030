import { AdminPanelButton } from 'components/AdminPanelButton';
import { DialogMessage } from 'components/DialogMessage';
import { DoubleCheckIcon, UsersIcon } from 'components/Icons';
import { SidebarPanel } from 'components/SidebarPanel';
import { Typography } from 'components/Typography';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, getUsers } from 'slices/userReducer';
import { ModalEditUser } from 'smartComponents/ModalEditUser';
import styled, { css } from 'styled-components';
import { SidebarPanelButton } from './styles';

const StyledButtonText = styled(Typography)`
  display: inline-block;
  ${(props) => {
    if (props.hasIcon) {
      return css`
        margin-left: 10px};
      `;
    }

    return css``;
  }}
`;

const AdminButton = ({ icon: IconComponent, children }) => {
  const hasIcon = !!IconComponent;

  return (
    <SidebarPanelButton>
      {!!hasIcon && <IconComponent size={16} />}
      <StyledButtonText variant="p" as="p" hasIcon={hasIcon}>
        {children}
      </StyledButtonText>
    </SidebarPanelButton>
  );
};

export const AdminPanel = () => {
  const { filters, panels } = useSelector((state) => state.users);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectUserId, setSelectUserId] = useState('');
  const [openEditUser, setOpenEditUser] = useState(false);
  const dispatch = useDispatch();

  const loadData = (filterData) => {
    const sendFilters = {
      ...filterData,
      sort_by: Object.values(filterData.sort_by),
    };
    dispatch(getUsers({ ...sendFilters, include: ['orgs'] }));
  };

  const handleDeleteMember = async () => {
    await dispatch(deleteUser({ userId: selectUserId }));
    loadData(filters);
    setOpenDelete(false);
  };

  const handleCancelMember = () => {
    setOpenDelete(false);
  };

  const handleEditUserConfirm = () => {
    loadData(filters);
    setOpenEditUser(false);
  };

  const handleEditUserCancel = () => {
    setOpenEditUser(false);
  };

  const handleOpenEditUser = (id) => {
    setSelectUserId(id);
    setOpenEditUser(true);
  };

  return (
    <SidebarPanel title="Users">
      <AdminPanelButton icon={UsersIcon} onClick={() => handleOpenEditUser(panels.detail.id)}>
        Edit User
      </AdminPanelButton>
      <AdminPanelButton
        icon={UsersIcon}
        onClick={() => {
          setSelectUserId(panels.detail.id);
          setOpenDelete(true);
        }}
      >
        Remove User
      </AdminPanelButton>
      <DialogMessage
        title="Are you sure you want remove this user ?"
        icon={DoubleCheckIcon}
        isOpen={openDelete}
        onConfirm={handleDeleteMember}
        onCancel={handleCancelMember}
      />
      {openEditUser && (
        <ModalEditUser
          isOpen={openEditUser}
          onConfirm={handleEditUserConfirm}
          onCancel={handleEditUserCancel}
          userId={selectUserId}
        />
      )}
    </SidebarPanel>
  );
};

AdminButton.propTypes = {
  icon: PropTypes.func,
  children: PropTypes.string,
};

AdminButton.defaultProps = {
  icon: null,
  children: '',
};

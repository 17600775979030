import React from 'react';
import { useSelector } from 'react-redux';
import { InfoPanelBody } from 'pages/Admin/Content/InfoPanel/InfoPanelBody';

export const InfoPanel = () => {
  const { details, user, loading } = useSelector((state) => state.content.panels);

  // const navigateToAssetPage = () => {
  //   history.push(`/profile/projects/${details.projId}/content/${details.id}`);
  // };

  return <InfoPanelBody loading={loading} detail={details} user={user} />;
};

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Avatar } from 'components/Avatar/Avatar';
import { BarsIcon } from 'components/Icons';
import { WidgetContent } from 'components/WidgetContent/WidgetContent';
import { WidgetHeader } from 'components/WidgetHeader/WidgetHeader';
import { resolveUserInitials } from 'lib/resolve-user-initials';
import UserService from 'services/UserService';
import { Typography } from '@mui/material';
import { useGlobalStyles } from 'styles';
import { useStyles as useAdminStyles } from '../../Admin/Dashboard/styles';

export const ActiveMemberWidget = () => {
  const adminClasses = useAdminStyles();
  const globalClasses = useGlobalStyles();
  const params = useParams();
  const history = useHistory();
  const [teamUsersData, setTeamUsersData] = useState([]);
  const navigateToTeamMembers = () => {
    history.push(`/teams/${params.teamId}/members`);
  };

  useEffect(() => {
    const getTeamDetails = async () => {
      const responseTeamUsers = await UserService.getUsers({ teamId: params.teamId, sort_by: '-createdAt' });
      setTeamUsersData(responseTeamUsers.data.data);
    };
    getTeamDetails();
  }, []);

  return (
    <div className={adminClasses.userInfo} style={{ margin: '1rem' }}>
      <WidgetHeader
        title="Most Active Members"
        smallText="These are members most active whithin the team."
        actionIcon={BarsIcon}
        actionTitle="View All"
        border
        actionClick={() => {
          navigateToTeamMembers();
        }}
        link
      />
      <WidgetContent>
        {teamUsersData.map((user) => (
          <div className={globalClasses.nameContainer} style={{ marginTop: '10px' }}>
            <Avatar
              key={user.id}
              initials={resolveUserInitials({ firstName: user.firstName, lastName: user.lastName })}
              size={40}
            />
            <div>
              <Typography>{`${user.firstName} ${user.lastName}`}</Typography>
              <Typography className={globalClasses.greyTypography}>
                {user.roles.map((role) => role.displayName).join(', ')}
              </Typography>
            </div>
          </div>
        ))}
      </WidgetContent>
    </div>
  );
};

import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  sidebarContainer: {
    // display: 'flex',
    justifyContent: 'flex-end',
    gridArea: 'sidebar',
    position: 'fixed',
    height: '100vh',
    zIndex: 80,
    top: 0,
    width: 0,
  },
  sidebarTabButton: (props) => ({
    display: 'inlineFlex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '6px',
    borderWidth: '1px',
    borderStyle: 'solid',
    cursor: 'pointer',
    position: 'relative',
    width: '40px',
    height: '40px',
    margin: '6px',
    padding: '8px',
    backgroundColor: props.active ? theme.palette.secondary.base : theme.palette.primary.base,
    borderColor: props.active ? theme.palette.secondary.base : theme.palette.primary[900],
    color: props.active ? theme.palette.white : theme.palette.primary[700],
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.secondary.base,
    },
  }),
  sidebarNavContainer: {
    height: '100%',
    width: '64px',
    backgroundColor: theme.palette.primary.base,
    display: 'flex',
    flexDirection: 'column',
    borderTop: `${theme.palette.primary[900]} solid 1px`,
    borderLeft: `${theme.palette.primary[900]} solid 1px`,
    paddingBottom: theme.spacing(0.13),
  },
  sidebarNavHeader: {
    borderBottom: `${theme.palette.secondary.base} solid 1px`,
    padding: '6px',
    minHeight: '65px',
  },
  sidebarNavBody: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    margin: '0',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '6px',
  },
  sidebarNavFooter: {
    display: 'flex',
    flex: '0 0 auto',
    flexDirection: 'column',
    alignItems: 'center',
  },
  sidebarPanelContainer: (props) => ({
    height: props.fullWidth ? 'calc(100vh - 60px)' : '100%',
    display: 'block',
    width: props.fullWidth ? 'calc(100vw - 80px)' : '400px',
    position: 'fixed',
    transform: `translateX(${props.open ? '0%' : '100%'})`,
    transition: 'transform 0.3s ease-out',
    top: props.fullWidth ? 60 : 0,
    ...(!props.fullWidth && { right: 0 }),
    zIndex: props.detail ? 799 : 800,
    backgroundColor: theme.palette.primary[1400],
  }),
  active: {
    display: 'block',
  },
  background: {
    position: 'fixed',
    background: 'rgba(0,0,0,0.7)',
    zIndex: 800,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
});

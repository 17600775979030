import { Point, Polyline } from '@arcgis/core/geometry';
import Graphic from '@arcgis/core/Graphic';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import LabelClass from '@arcgis/core/layers/support/LabelClass';
import TextSymbol from '@arcgis/core/symbols/TextSymbol';
import GeometryService from 'services/GeometryService';
import { SimpleMarkerSymbol } from '@arcgis/core/symbols';
import observationImage from './observation.png';
import currentHeadingMarkerN from './iconPointerN.png';
import currentHeadingMarkerNW from './iconPointerNW.png';
import currentHeadingMarkerNE from './iconPointerNE.png';
import currentHeadingMarkerS from './iconPointerS.png';
// import testIcon from './test.png';
import currentHeadingMarkerSE from './iconPointerSE.png';
import currentHeadingMarkerE from './iconPointerE.png';
import currentHeadingMarkerSW from './iconPointerSW.png';
import currentHeadingMarkerW from './iconPointerW.png';
import findingImage from './finding.png';
import currentMarker from './markerNoAngle_26x26.png';

let debounceTimeout;

const mapSource = {
  searchFields: ['STR_Number'],
  displayField: 'STR_Number',
  zoomScale: 250000,
};

const resolveLineSymbol = (kV) => {
  let color = '#c2c2c2';
  switch (kV) {
    case 4:
    case 4.16:
    case 6.6:
    case 12:
    case 13:
    case 24:
    case 33:
      color = '#00ffd8';
      break;
    case 44:
      color = '#629cbe';
      break;
    case 66:
    case 69:
      color = '#be62b2';
      break;
    case 100:
    case 115:
      color = '#00cf00';
      break;
    case 138:
      color = '#ff0000';
      break;
    case 161:
      color = '#ff9900';
      break;
    case 230:
      color = '#0000ff';
      break;
    case 345:
      color = '#000000';
      break;
    case 500:
    case 525:
      color = '#cf4800';
      break;
    default:
      color = '#c2c2c2';
      break;
  }
  return {
    type: 'simple-line',
    color,
    width: 1,
    style: 'solid',
    xoffset: 20,
    yoffset: 3,
  };
};

// the blue gpsmarker icon that moves on the map
export const currentSymbol = {
  type: 'picture-marker',
  url: currentMarker,
  width: '18px',
  height: '18px',
};

// eslint-disable-next-line no-unused-vars
const headingDirection = {
  N: [
    { min: 0, max: 22 },
    { min: 325, max: 360 },
  ],
  NE: { min: 22, max: 67 },
  E: { min: 67, max: 112 },
  SE: { min: 112, max: 157 },
  S: { min: 157, max: 202 },
  SW: { min: 202, max: 247 },
  W: { min: 247, max: 292 },
  NW: { min: 292, max: 325 },
};

export const currentHeadingSymbol = (heading) => {
  if (
    (heading >= headingDirection.N[0].min && heading <= headingDirection.N[0].max) ||
    (heading >= headingDirection.N[1].min && heading <= headingDirection.N[1].max)
  ) {
    // North
    return {
      type: 'picture-marker',
      url: currentHeadingMarkerN,
      width: '28px',
      height: '28px',
    };
  }
  if (heading >= headingDirection.NE.min && heading <= headingDirection.NE.max) {
    // North East
    return {
      type: 'picture-marker',
      url: currentHeadingMarkerNE,
      width: '28px',
      height: '28px',
    };
  }
  if (heading >= headingDirection.E.min && heading <= headingDirection.E.max) {
    // East
    return {
      type: 'picture-marker',
      url: currentHeadingMarkerE,
      width: '28px',
      height: '28px',
    };
  }
  if (heading >= headingDirection.SE.min && heading <= headingDirection.SE.max) {
    // South East
    return {
      type: 'picture-marker',
      url: currentHeadingMarkerSE,
      width: '28px',
      height: '28px',
    };
  }
  if (heading >= headingDirection.S.min && heading <= headingDirection.S.max) {
    // South
    return {
      type: 'picture-marker',
      url: currentHeadingMarkerS,
      width: '28px',
      height: '28px',
    };
  }
  if (heading >= headingDirection.SW.min && heading <= headingDirection.SW.max) {
    // South West
    return {
      type: 'picture-marker',
      url: currentHeadingMarkerSW,
      width: '28px',
      height: '28px',
    };
  }
  if (heading >= headingDirection.W.min && heading <= headingDirection.W.max) {
    // West
    return {
      type: 'picture-marker',
      url: currentHeadingMarkerW,
      width: '28px',
      height: '28px',
    };
  }
  // if (heading >= headingDirection.NW.min && heading <= headingDirection.NW.max) {

  // North West
  return {
    type: 'picture-marker',
    url: currentHeadingMarkerNW,
    angle: heading,
    width: '28px',
    height: '28px',
  };
};

// -------------------------------------------------- create feature layer
export const createMapGeoFeatureLayer = (type, data) => {
  const labelClass = new LabelClass({
    labelExpressionInfo: {
      expression: '$feature.STR_Number',
    },
    labelPlacement: 'above-right',
    symbol: new TextSymbol({
      color: 'white',
      haloColor: 'black',
      haloSize: '2px',
      font: {
        size: 10,
      },
    }),
  });
  let renderer = {
    type: 'simple', // autocasts as new SimpleRenderer()
    symbol: {
      type: 'simple-marker', // autocasts as new SimpleMarkerSymbol()
      color: '#BE62B2',
      size: '10px',
      outline: {
        color: [217, 140, 0],
        width: 0.5, // points
      },
    },
  };
  const source = [];
  if (type === 'polyline') {
    source.push(
      new Graphic({
        geometry: new Polyline({
          paths: [[]],
          hasM: true,
          hasZ: false,
          spatialReference: { wkid: 3857 },
        }),
        attributes: { OBJECTID: -1, STR_Number: '' },
      })
    );
    renderer = {
      type: 'simple',
      symbol: resolveLineSymbol('default'),
    };
  }
  // create empty FeatureLayer
  const featureLayer = new FeatureLayer({
    objectIdField: 'ObjectID',
    geometryType: type,
    source,
    id: data.id,
    minScale: type === 'polyline' ? 2000000 : 250000,
    labelsVisible: true,
    labelingInfo: [labelClass],
    fields: [
      {
        name: 'OBJECTID',
        alias: 'OBJECTID',
        type: 'string',
      },
      {
        name: 'STR_Number',
        alias: 'STR_Number',
        type: 'string',
      },
    ],
    renderer,
  });
  return featureLayer;
};

// ---------------------------------------- add graphics to the feature layer
const featureGraphics = (mapFeatures) => {
  const pointsList = [];
  // ------------------------------------- show geo map
  mapFeatures.forEach((feature) => {
    // text settings
    const textSymbol = new TextSymbol({
      color: 'white',
      haloColor: 'black',
      haloSize: '1px',
      xoffset: 20,
      yoffset: 3,
      font: {
        size: 14,
      },
    });
    const pointCoordinates = JSON.parse(feature.geometry.coordinates);
    const properties = JSON.parse(feature.properties);
    const x = parseFloat(pointCoordinates[0]);
    const y = parseFloat(pointCoordinates[1]);
    properties.STR_Number = properties.STR_NUMBER;
    if (properties.STR_NUM) properties.STR_Number = properties.STR_NUM;
    if (properties.POLE_NUMBER) properties.STR_Number = properties.POLE_NUMBER;
    properties.OBJECTID = feature.id;
    // --------------------------------- paint geometry
    switch (feature.geometry.type) {
      case 'Point': {
        pointsList.push(
          new Graphic({
            geometry: new Point({
              x,
              y,
              spatialReference: { wkid: 3857 },
            }),
            symbol: textSymbol,
            attributes: properties,
          })
        );
        break;
      }
      case 'MultiLineString':
      case 'LineString': {
        // adding the purple line to the map
        const graphic = new Graphic({
          geometry: new Polyline({
            paths: pointCoordinates,
            hasM: true,
            hasZ: false,
            spatialReference: { wkid: 3857 },
          }),
          symbol: resolveLineSymbol(properties.VOLTAGE),
          attributes: {
            OBJECTID: properties.OBJECTID,
            STR_Number: properties.OPERATING_NAME,
          },
        });
        pointsList.push(graphic);
        break;
      }
      default:
      // do nothing
    }
  });
  return pointsList;
};

// validate painted grapchis map
const returnFinalGraphics = (result, features) => {
  result.forEach((item) => {
    features.forEach((feature) => {
      if (item.attributes.OBJECTID === feature.attributes.OBJECTID && !feature.remove) {
        feature.remove = true;
        item.remove = true;
      }
    });
  });
  return { finalResult: result.filter((item) => !item.remove), finalFeatures: features.filter((item) => !item.remove) };
};

// --------------------------------------------- clear feature layers and searching
const updateFeatureLayer = (featureLayer, result, mapGeoLayer, searchWidget, type) => {
  if (result.length > 2000) return;
  const mapFeatures = result.slice();
  searchWidget.sources = [];
  const pointsList = featureGraphics(mapFeatures);
  featureLayer.queryFeatures().then((layerResult) => {
    // edits object tells apply edits that you want to delete the features
    let addEdits = {};
    const { features } = layerResult;
    const { finalResult, finalFeatures } = returnFinalGraphics(pointsList, features);
    addEdits = {
      deleteFeatures: finalFeatures,
    };
    // apply edits to the layer
    featureLayer.applyEdits(addEdits);
    // ----------------------------------------- update layer
    addEdits = {
      addFeatures: finalResult,
    };
    if (pointsList.length > 0) featureLayer.applyEdits(addEdits);
    // update searching bar
    searchWidget.sources.push({
      layer: featureLayer,
      name: type === 'polylines' ? 'Operating Name' : 'Structure',
      ...mapSource,
    });
  });
  // ------------- update graphicLayer lines
  if (type === 'polylines') {
    mapGeoLayer.removeAll();
    mapGeoLayer.addMany(pointsList);
  }
};

// ---------------------------------------------- main function to show points and lines
export const fetchMapGeoData = async (data) => {
  const {
    view,
    mapGeoFeatureLayer,
    mapGeoFeatureLayerLines,
    mapGeoLayer,
    searchWidget,
    geometryOrgId,
    includeAccountGeometry,
  } = data;
  clearTimeout(debounceTimeout);
  debounceTimeout = setTimeout(async () => {
    const { xmin, xmax, ymin, ymax } = view.extent;
    const filters = {
      xmin,
      xmax,
      ymin,
      ymax,
      includeAcct: includeAccountGeometry,
    };
    if (geometryOrgId) {
      filters.orgId = geometryOrgId;
    }
    const result = await GeometryService.getGeometryByRect(filters);
    // if (result.data.features.length > 2000) return;
    updateFeatureLayer(
      mapGeoFeatureLayer,
      result.data.features.filter((item) => item.geometry.type === 'Point'),
      mapGeoLayer,
      searchWidget,
      'point'
    );
    updateFeatureLayer(
      mapGeoFeatureLayerLines,
      result.data.features.filter(
        (item) => item.geometry.type === 'LineString' || item.geometry.type === 'MultiLineString'
      ),
      mapGeoLayer,
      searchWidget,
      'polylines'
    );
  }, 400);
};

// -------------------------- observation poinst
export const observationSymbol = {
  type: 'picture-marker',
  url: observationImage,
  width: '25px',
  height: '25px',
};

export const observationPoints = (observations) => {
  const list = [];
  observations.forEach((item) => {
    const mapPoint = {
      type: 'point',
      longitude: item.lng,
      latitude: item.lat,
    };
    const firstPointGraphic = new Graphic({
      geometry: mapPoint,
      symbol: observationSymbol,
      attributes: item,
    });
    list.push(firstPointGraphic);
  });

  return list;
};

// -------------------------- finding points
export const findingSymbol = {
  type: 'picture-marker',
  url: findingImage,
  width: '25px',
  height: '25px',
};

export const findingPoints = (findings) => {
  const list = [];
  const auxFindings = findings?.length ? findings : [];
  auxFindings.forEach((item) => {
    const mapPoint = {
      type: 'point',
      longitude: item.lng,
      latitude: item.lat,
    };
    const firstPointGraphic = new Graphic({
      geometry: mapPoint,
      symbol: findingSymbol,
      attributes: { ...item, finding: true },
    });
    list.push(firstPointGraphic);
  });

  return list;
};

// This function maps through the spatial points on the backend, and creates a collection of orange dot graphics.
// starting green marker at the first coordinate
const startMarkerSymbol = {
  type: 'simple-marker', // autocasts as new SimpleMarkerSymbol()
  color: [50, 205, 50, 1],
  outline: {
    color: [255, 255, 255],
    width: 0,
  },
};
// small orange marker for the points in-between the first and last point
const gpsMarker = new SimpleMarkerSymbol({
  color: [255, 165, 0, 1],
  size: '12px',
  outline: {
    // autocasts as new SimpleLineSymbol()
    color: [217, 140, 0],
    width: 0.5, // points
  },
});
// ending orange marker at the last coordinate
const stopMarkerSymbol = {
  type: 'simple-marker', // autocasts as new SimpleMarkerSymbol()
  color: [255, 69, 0, 1],
  outline: {
    color: [255, 255, 255],
    width: 0,
  },
};
export const mainPointGraphics = (points, videoView, selectedSequence, lastPoint) =>
  points.map((point, index) => {
    const mapPoint = {
      type: 'point',
      longitude: point.location[0],
      latitude: point.location[1],
    };

    if (!videoView) {
      const symbol = !point.heading ? currentSymbol : currentHeadingSymbol(point.heading);

      // Current Selected Sequence Item
      if (selectedSequence === index) {
        const currentPointGraphic = new Graphic({
          geometry: mapPoint,
          symbol,
          attributes: index,
        });
        return currentPointGraphic;
      }

      // First Marker Created
      if (index === 0) {
        const firstPointGraphic = new Graphic({
          geometry: mapPoint,
          symbol: startMarkerSymbol,
          attributes: index,
        });
        return firstPointGraphic;
      }

      // Last Marker Created
      if (index === lastPoint) {
        const lastPointGraphic = new Graphic({
          geometry: mapPoint,
          symbol: stopMarkerSymbol,
          attributes: index,
        });
        return lastPointGraphic;
      }
    }

    if (videoView) {
      if (index === 0) {
        const firstPointGraphic = new Graphic({
          geometry: mapPoint,
          symbol: startMarkerSymbol,
          attributes: index,
        });
        return firstPointGraphic;
      }
      if (index === lastPoint) {
        const firstPointGraphic = new Graphic({
          geometry: mapPoint,
          symbol: stopMarkerSymbol,
          attributes: index,
        });
        return firstPointGraphic;
      }
    }

    if (points.length < 2) {
      const symbol = !point.heading ? currentSymbol : currentHeadingSymbol(point.heading);

      const firstPointGraphic = new Graphic({
        geometry: mapPoint,
        symbol,
        attributes: index,
      });
      return firstPointGraphic;
    }

    const firstPointGraphic = new Graphic({
      geometry: mapPoint,
      symbol: gpsMarker,
      attributes: index,
    });

    return firstPointGraphic;
  });

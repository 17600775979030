import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AdminService from 'services/AdminService';
import UserService from 'services/UserService';

export const fetchUsers = createAsyncThunk('admin-admins/fetchUsers', async (filterOpts) => {
  const filtersOpts = {
    ...filterOpts,
    include: ['roles'],
  };
  delete filtersOpts.activated;
  const response = await AdminService.getAdmins(filtersOpts);

  return response.data;
});

export const fetchUser = createAsyncThunk(
  'admin-admins/fetchUser',
  async (userId) => {
    const [user, roles] = await Promise.all([
      await UserService.getUser(userId),
      await UserService.getUserRoles(userId),
    ]);

    return {
      user: user.data,
      roles: roles.data,
    };
  },
  {
    condition: (userId, { getState }) => getState().adminAdmins.panels.userId !== userId,
  }
);

const initialState = {
  users: [],
  usersCount: 0,
  loading: true,
  error: null,
  panels: {
    userId: null,
    loading: true,
    detail: {},
    roles: {},
  },
  filters: {
    page: 1,
    page_size: 10,
    sort_by: {},
    search: '',
    participantLevel: '',
  },
};

const adminAdminsSlice = createSlice({
  name: 'admin-admins',
  initialState,
  reducers: {
    resetAdmins: () => initialState,
    setActiveUser(state, action) {
      state.panels.userId = action.payload.userId;
    },
    setPageFilter(state, action) {
      state.filters.page = action.payload;
    },
    setSearch(state, action) {
      state.filters.search = action.payload;
      state.filters.page = 1;
    },
    setSortBy(state, { payload }) {
      const { label, value } = payload;
      if (value === '' || value === null) {
        delete state.filters.sort_by[label];
      } else {
        state.filters.sort_by[label] = value;
      }
    },
    setFilterValue(state, { payload }) {
      const { param, value } = payload;

      if (value === '' || value === null) {
        delete state.filters[param];
        return;
      }

      state.filters[param] = payload.value;
    },
    setFilters(state, action) {
      const filter = { ...state.filters, ...action.payload, page: 1 };
      if (!filter?.participantLevel) delete filter?.participantLevel;
      state.filters = filter;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchUsers.fulfilled, (state, { payload }) => {
      state.users = payload.data;
      state.usersCount = payload.meta.totalCount;
      state.loading = false;
    });

    builder.addCase(fetchUsers.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchUsers.rejected, (state) => {
      state.loading = true;
      state.users = [];
      state.error = 'unable to load users';
    });

    builder.addCase(fetchUser.pending, (state) => {
      state.panels.loading = true;
      state.panels.userId = null;
    });

    builder.addCase(fetchUser.fulfilled, (state, { payload }) => {
      state.panels.userId = payload.user.data.id;
      state.panels.detail = payload.user.data;
      state.panels.roles = payload.roles.data;
      state.panels.loading = false;
    });

    builder.addCase(fetchUser.rejected, (state) => {
      state.panels.detail = {};
      state.panels.error = 'unable to load user data';
      state.panels.roles = {};
      state.panels.loading = false;
    });
  },
});

export const {
  setActiveUser,
  setPageFilter,
  setSortBy,
  setSearch,
  resetAdmins,
  setFilterValue,
  setFilters,
  // eslint-disable-next-line prettier/prettier
} = adminAdminsSlice.actions;

export default adminAdminsSlice.reducer;

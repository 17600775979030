import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import OrganizationService from 'services/OrganizationService';
import ProjectService from 'services/ProjectService';
import TeamService from 'services/TeamService';
import UserService from 'services/UserService';

export const getOrganizations = createAsyncThunk('organization/getOrganizations', async (filterOpts) => {
  const filtersOpts = {
    ...filterOpts,
    include: ['admins', 'projects'],
    isActive: { eq: true },
  };
  delete filtersOpts.activated;
  try {
    const response = await OrganizationService.getOrganizations(filtersOpts);
    return response.data;
  } catch (e) {
    throw new Error('Error during request for organization...');
  }
});

export const getSingleOrg = createAsyncThunk('organization/getSingleOrg', async ({ orgId, orgRole }) => {
  const [org, teams, projects, orgAdmins] = await Promise.all([
    await OrganizationService.getSingleOrganization(orgId),
    await TeamService.getTeams({ orgId: { eq: orgId } }),
    await ProjectService.getProjects({ orgId: { eq: orgId } }),
    await OrganizationService.getOrganizationAdminsRoles(orgId, orgRole),
  ]);

  return {
    org,
    teams,
    projects,
    orgAdmins,
  };
});

export const deleteOrganization = createAsyncThunk('organization/deleteSingleOrg', async ({ orgId }) => {
  await OrganizationService.deactivateOrganization(orgId);
});

export const preloadFilterDatasets = createAsyncThunk(
  'organization/preloadFilterDatasets',
  async () => {
    try {
      const [users, teams, projects] = await Promise.all([
        await UserService.getUsers({ page_size: 500 }),
        await TeamService.getTeams({ page_size: 500 }),
        await ProjectService.getProjects({ page_size: 1000 }),
      ]);
      return {
        users: users.data.data,
        teams: teams.data.data,
        projects: projects.data.data,
      };
    } catch (err) {
      throw new Error('error loading filter data');
    }
  },
  {
    condition: (_, { getState }) => getState().organizations.panels.search.loaded === false,
  }
);

const orderByName = (array) =>
  array.sort((a, b) => {
    const nameA = a.label.toUpperCase(); // ignore upper and lowercase
    const nameB = b.label.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

const initialState = {
  error: null,
  loading: true,
  data: [],
  panels: {
    orgId: null,
    loading: true,
    detail: {},
    teams: [],
    projects: [],
    orgAdmins: [],
    search: {
      loaded: false,
      users: [],
      teams: [],
      projects: [],
    },
  },
  filters: {
    page: 1,
    page_size: 10,
    sort_by: {},
    userId: '',
    teamId: '',
    projectId: '',
  },
};

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    resetOrganizations: () => initialState,
    setPageFilter(state, action) {
      state.filters.page = action.payload;
    },
    setSortBy(state, { payload }) {
      const { label, value } = payload;

      if (value === '' || value === null) {
        delete state.filters.sort_by[label];
      } else {
        state.filters.sort_by[label] = value;
      }
    },
    setSearch(state, action) {
      state.filters.search = action.payload;
      state.filters.page = 1;
    },
    updateAdminRoles(state, { payload }) {
      const { adminId, roles } = payload;

      for (let i = 0; i < state.panels.orgAdmins.length; i += 1) {
        const admin = state.panels.orgAdmins[i];
        if (admin.id === adminId) {
          admin.roles = roles;

          break;
        }
      }
    },
    updateOrgInfo(state, { payload }) {
      const { name, description, orgId } = payload;
      for (let i = 0; i < state.data.length; i += 1) {
        const organization = state.data[i];
        if (organization.id === orgId) {
          organization.name = name;
          organization.description = description;
          break;
        }
      }
    },
    setFilterValue(state, { payload }) {
      const { param, value } = payload;
      if (value === '' || value === null) {
        delete state.filters[param];
        return;
      }
      state.filters[param] = payload.value;
    },
    setFilters(state, action) {
      const filter = { ...state.filters, ...action.payload, page: 1 };
      state.filters = filter;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrganizations.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOrganizations.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.orgsCount = action.payload.meta.totalCount;
      state.error = null;
      state.loading = false;
    });
    builder.addCase(getOrganizations.rejected, (state) => {
      state.data = [];
      state.error = 'There was an error during the request.';
      state.loading = false;
    });

    builder.addCase(getSingleOrg.pending, (state) => {
      state.panels.loading = true;
      state.panels.orgId = null;
    });

    builder.addCase(getSingleOrg.fulfilled, (state, { payload }) => {
      state.panels.orgId = payload.org.data.data.id;
      state.panels.detail = payload.org.data.data;
      state.panels.teams = payload.teams.data.data;
      state.panels.projects = payload.projects.data.data;
      state.panels.orgAdmins = payload.orgAdmins.data.data;
      state.panels.loading = false;
    });

    builder.addCase(getSingleOrg.rejected, (state) => {
      state.panels.detail = {};
      state.panels.error = 'unable to load organization data';
      state.panels.teams = [];
      state.panels.projects = [];
      state.panels.loading = false;
    });
    builder.addCase(preloadFilterDatasets.pending, (state) => {
      state.panels.search.loaded = false;
    });
    builder.addCase(preloadFilterDatasets.rejected, (state) => {
      state.panels.search.users = [];
      state.panels.search.teams = [];
      state.panels.search.projects = [];
      state.panels.search.loaded = false;
    });
    builder.addCase(preloadFilterDatasets.fulfilled, (state, { payload }) => {
      const { users, teams, projects } = payload;
      state.panels.search.loaded = true;
      let newUsers = users.map((user) => ({
        label: `${user.firstName} ${user.lastName}`,
        value: user.id,
      }));
      newUsers = orderByName(newUsers);
      state.panels.search.users = newUsers;
      const newTeams = teams.map((team) => ({
        label: team.name,
        value: team.id,
      }));
      state.panels.search.teams = orderByName(newTeams);
      const newProjects = projects.map((proj) => ({
        label: proj.name,
        value: proj.id,
      }));
      state.panels.search.projects = orderByName(newProjects);
    });
  },
});

export const {
  setPageFilter,
  setSortBy,
  setSearch,
  updateAdminRoles,
  updateOrgInfo,
  resetOrganizations,
  setFilterValue,
  setFilters,
  // eslint-disable-next-line prettier/prettier
} = organizationSlice.actions;

export default organizationSlice.reducer;

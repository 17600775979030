/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Dialog, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { Calendar } from 'components/Calendar/Calendar';
import { useStyles } from './styles';

export const CalendarModal = ({ rangeDate, handleClose, handleConfirm }) => {
  const classes = useStyles();
  const [ranges, setRanges] = useState([
    {
      startDate: rangeDate === null || !rangeDate || !rangeDate[0] ? null : rangeDate[0],
      endDate: rangeDate === null || !rangeDate || !rangeDate[1] ? null : rangeDate[1],
      key: 'selection',
    },
  ]);

  return (
    <Dialog open maxWidth="md" onClose={handleClose} classes={{ paper: classes.modalCalendar }}>
      <DialogTitle className={classes.modalCalendarHeader} />
      <DialogContent className={classes.modalCalendarContent}>
        <Calendar months={2} ranges={ranges} onChange={(item) => setRanges([item.selection])} />
        <div className={classes.modalCalendarActions}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div className={classes.modalCalendarInputs}>
                <TextField
                  value={
                    ranges.length > 0
                      ? moment(ranges[0].startDate).format('ll') === 'Invalid date'
                        ? ''
                        : moment(ranges[0].startDate).format('ll')
                      : ''
                  }
                  disabled
                  className={classes.calendarInput}
                />{' '}
                -
                <TextField
                  value={
                    ranges.length > 0
                      ? moment(ranges[0].startDate).format('ll') === 'Invalid date'
                        ? ''
                        : moment(ranges[0].endDate).format('ll')
                      : ''
                  }
                  disabled
                  className={classes.calendarInput}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div className={classes.modalCalendarButtons}>
                <Button color="primary" variant="contained" onClick={handleClose}>
                  Cancel
                </Button>
                <Button color="secondary" variant="contained" onClick={() => handleConfirm(ranges)}>
                  Apply
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
};

CalendarModal.propTypes = {
  rangeDate: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};

/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormRadioGroup } from 'components/FormRadioGroup';
import { SidebarPanel, cleanObjectFilters } from 'components/SidebarPanel';
import { useDispatch } from 'react-redux';
import { ClearFilterAction } from 'components/SidebarPanel/ClearFilterAction';
import { FormControl, FormLabel } from '@mui/material';
import { SearchList } from 'components/SidebarPanel/SearchList/SearchList';

export const searchPanelSection = {
  ADMINUSERS: 'admin_users',
  EXTERNALUSERS: 'external_users',
  TEAMUSERS: 'team_users',
};

export const SearchPanelBody = ({
  loaded,
  filters,
  organizations,
  accounts,
  teams,
  projects,
  // eslint-disable-next-line no-unused-vars
  setFilterValue,
  setFilters,
  section,
}) => {
  const dispatch = useDispatch();
  const [filterActions, setFiltersActions] = useState([]);
  const [localFilters, setLocalFilters] = useState({
    sort_by: {},
    acctId: '',
    organizationId: '',
    teamId: '',
    projectId: '',
  });

  // clear sort
  const clearSort = (param) => {
    const auxFilters = { ...localFilters };
    delete auxFilters.sort_by[param];
    auxFilters.sort_by = cleanObjectFilters(auxFilters.sort_by);
    setLocalFilters(auxFilters);
  };

  // update filters
  const updateFIlter = (auxFilters) => {
    setLocalFilters({ ...auxFilters });
  };

  // update radio buttons
  const updateNormalFilters = (value, type) => {
    const auxFilters = { ...localFilters };
    auxFilters[type] = value;
    setLocalFilters({ ...auxFilters });
  };

  // -------------- clear filters
  const handleClearFilters = () => {
    const auxFilters = { filters };
    if (cleanObjectFilters(auxFilters)?.filters) {
      dispatch(
        setFilters({
          sort_by: {},
          acctId: '',
          organizationId: '',
          teamId: '',
          projectId: '',
          ...(section === searchPanelSection.TEAMUSERS && { userTeamRole: null }),
          activated: false,
        })
      );
    }
  };

  // -------------- apply filters
  const handleApplyFilters = () => {
    const auxFilters = { ...localFilters };
    auxFilters.sort_by = cleanObjectFilters(auxFilters.sort_by);
    dispatch(setFilters({ ...auxFilters, activated: filterActions.length > 0 }));
  };

  const createClearFilters = (clearFilters) => {
    const auxFilters = [];
    if (clearFilters.sort_by?.firstName)
      auxFilters.push({ text: 'Sort By Name', callBack: () => clearSort('firstName') });
    if (clearFilters.sort_by?.createdAt)
      auxFilters.push({ text: 'Sort By Created At', callBack: () => clearSort('createdAt') });
    if (clearFilters.sort_by?.org && section !== searchPanelSection.TEAMUSERS)
      auxFilters.push({ text: 'Sort By Org', callBack: () => clearSort('org') });
    if (section === searchPanelSection.TEAMUSERS) {
      if (clearFilters?.userTeamRole)
        auxFilters.push({ text: 'Clear By Role', callBack: () => updateNormalFilters(null, 'userTeamRole') });
    }
    if (section !== searchPanelSection.TEAMUSERS) {
      if (clearFilters?.organizationId?.length > 0)
        auxFilters.push({ text: 'Clear By Organization', callBack: () => updateNormalFilters(null, 'organizationId') });
      if (clearFilters?.acctId?.length > 0)
        auxFilters.push({ text: 'Clear By Account', callBack: () => updateNormalFilters(null, 'acctId') });
      if (clearFilters?.teamId?.length > 0 && section !== searchPanelSection.PROFILETEAMS)
        auxFilters.push({ text: 'Clear By Team', callBack: () => updateNormalFilters(null, 'teamId') });
      if (clearFilters?.projectId?.length > 0)
        auxFilters.push({ text: 'Clear By Project', callBack: () => updateNormalFilters(null, 'projectId') });
    }
    setFiltersActions(auxFilters);
    return auxFilters;
  };

  useEffect(() => {
    createClearFilters(localFilters);
  }, [localFilters]);

  useEffect(() => {
    createClearFilters(filters);
    setLocalFilters(filters);
  }, [filters]);

  return (
    <SidebarPanel
      title={
        section === searchPanelSection.ADMINUSERS
          ? 'Filters: Account Users'
          : section === searchPanelSection.EXTERNALUSERS
          ? 'Filters: External Users'
          : 'Filters'
      }
      subTitle="Apply filters to table data."
      filters
      handleClearFilters={handleClearFilters}
      handleApplyFilters={handleApplyFilters}
    >
      <div className="searchBody">
        <ClearFilterAction actions={filterActions} />
        <FormControl>
          <FormLabel>Sort</FormLabel>
          <FormRadioGroup
            name="alphabetical"
            value={localFilters.sort_by.firstName}
            onChecked={(checkedVal) => {
              const sortBy = { ...localFilters.sort_by };
              sortBy.firstName = checkedVal;
              updateNormalFilters(sortBy, 'sort_by');
            }}
            options={[
              { label: 'A - Z', value: '+firstName', disabled: false },
              { label: 'Z - A', value: '-firstName', disabled: false },
              { label: 'None', value: '', disabled: false },
            ]}
            view="horizontal"
          />
          <FormRadioGroup
            name="date-by"
            value={localFilters.sort_by.createdAt}
            onChecked={(checkedVal) => {
              const sortBy = { ...localFilters.sort_by };
              sortBy.createdAt = checkedVal;
              updateNormalFilters(sortBy, 'sort_by');
            }}
            options={[
              { label: 'Newest', value: '-createdAt', disabled: false },
              { label: 'Oldest', value: '+createdAt', disabled: false },
              { label: 'None', value: '', disabled: false },
            ]}
            view="horizontal"
          />
        </FormControl>
        {section === searchPanelSection.TEAMUSERS && (
          <FormControl>
            <FormLabel className="marginTop">Role</FormLabel>
            <FormRadioGroup
              name="alphabetical"
              value={localFilters.userTeamRole}
              onChecked={(checkedVal) => updateNormalFilters(checkedVal, 'userTeamRole')}
              options={[
                { label: 'All', value: null, disabled: false },
                { label: 'Participant', value: 17, disabled: false },
                { label: 'Administrator', value: 16, disabled: false },
              ]}
              view="horizontal"
            />
          </FormControl>
        )}
        {section !== searchPanelSection.TEAMUSERS && (
          <>
            <SearchList
              title={`${section === searchPanelSection.EXTERNALUSERS ? 'External ' : ''}Organization`}
              fullList={organizations}
              filter="organizationId"
              localFilters={localFilters}
              onChangeSelect={updateFIlter}
              loaded={loaded}
            />
            {section === searchPanelSection.EXTERNALUSERS && (
              <SearchList
                title="External Account"
                fullList={accounts}
                filter="acctId"
                localFilters={localFilters}
                onChangeSelect={updateFIlter}
                loaded={loaded}
              />
            )}
            <SearchList
              title="Team"
              fullList={teams}
              filter="teamId"
              localFilters={localFilters}
              onChangeSelect={updateFIlter}
              loaded={loaded}
            />
            <SearchList
              title="Project"
              fullList={projects}
              filter="projectId"
              localFilters={localFilters}
              onChangeSelect={updateFIlter}
              loaded={loaded}
            />
          </>
        )}
      </div>
    </SidebarPanel>
  );
};

SearchPanelBody.propTypes = {
  loaded: PropTypes.bool,
  filters: PropTypes.object,
  accounts: PropTypes.array,
  organizations: PropTypes.array,
  teams: PropTypes.array,
  projects: PropTypes.array,
  setFilterValue: PropTypes.func,
  setFilters: PropTypes.func,
  section: PropTypes.string,
};

SearchPanelBody.defaultProps = {
  loaded: false,
  filters: null,
  accounts: [],
  organizations: [],
  teams: [],
  projects: [],
  setFilterValue: () => {},
  setFilters: () => {},
  section: '',
};

import PropTypes from 'prop-types';
import React from 'react';

import { Svg } from './Svg';

export const AdminShieldIcon = ({ size }) => (
  <Svg viewBox="0 0 24 24" size={size}>
    <path
      id="Exclude"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0639 11.9894H19.1138C18.5793 16.1094 15.8104 19.7787 12.0639 20.9294V12H5.0141V6.30003L12.0639 3.18937V11.9894ZM12.0641 1L3 5V11C3 16.5493 6.86734 21.7387 12.0641 23C17.2608 21.7387 21.1282 16.5493 21.1282 11V5L12.0641 1Z"
      fill="currentColor"
    />
  </Svg>
);
AdminShieldIcon.propTypes = {
  size: PropTypes.number,
};

AdminShieldIcon.defaultProps = {
  size: 24,
};

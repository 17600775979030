import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';
import { useStyles } from './styles';

export const ActionButton = ({ title, subTitle, smallText, buttonText, onClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.actionButtonContent}>
      <div className={classes.actionButtonTextContent}>
        <Typography mb={0.5}>{title}</Typography>
        <Typography variant={smallText ? 'h5' : 'h1'} mb={0.5}>
          {subTitle || smallText}
        </Typography>
      </div>
      <div className={classes.actionButtonFooter}>
        <Button color="secondary" onClick={onClick}>
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

ActionButton.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  smallText: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
};

ActionButton.defaultProps = {
  title: '',
  subTitle: '',
  smallText: '',
  buttonText: '',
  onClick: () => {},
};

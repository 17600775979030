import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useGlobalStyles = makeStyles({
  avatarRow: {
    display: 'flex',
    flexDirection: 'row',
    '& > *': {
      marginRight: '-5px!important',
    },
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > div:last-child': {
      marginLeft: '10px',
    },
    '& p:first-child': {
      fontWeight: 500,
    },
    '& p:second-child': {
      fontWeight: 400,
    },
  },
  // info panel styles
  infoPanelBodyHeader: {
    '& img': {
      width: '100%',
    },
    marginBottom: `-${theme.spacing(2)}!important`,
  },
  infoPanelBodyHeaderAvatar: {
    position: 'relative',
    left: theme.spacing(1),
    bottom: theme.spacing(2),
  },
  infoPanelBody: {
    padding: theme.spacing(1),
    '& h5, & p': {
      fontWeight: 400,
    },
  },
  infoPanelName: { fontSize: '24px!important', fontWeight: 500 },
  openDetail: {
    fontWeight: 500,
    padding: '10px 16px',
  },
  infoTabPanel: {
    padding: `${theme.spacing(0.5)} 0`,
    maxHeight: '30vh',
    marginTop: 5,
    overflowY: 'auto',
  },
  greyTypography: {
    color: `${theme.palette.primary[500]}`,
    fontWeight: `${400}`,
  },
  infoPanelDescription: {
    color: `${theme.palette.primary[400]}`,
    lineHeight: '20px',
  },
  infoPanelAboutText: {
    fontWeight: `${500}!important`,
  },
  // ------------------------- copy id styles
  copyBox: {
    border: `1px solid ${theme.palette.success[500]}`,
    backgroundColor: theme.palette.success[100],
  },
  copyIcons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(0.5),
    color: theme.palette.success[800],
    '& svg, & button': {
      color: theme.palette.success[800],
    },
  },
  copyTitle: {
    color: `${theme.palette.success[900]}!important`,
    fontWeight: '500',
  },
  copyText: {
    color: `${theme.palette.success[800]}!important`,
  },
  // ------------------------- error message styles
  errorBox: {
    border: `1px solid ${theme.palette.danger[500]}`,
    backgroundColor: theme.palette.danger[100],
  },
  errorIcons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(0.5),
    color: theme.palette.danger[800],
    '& svg, & button': {
      color: theme.palette.danger[800],
    },
  },
  errorTitle: {
    color: `${theme.palette.danger[900]}!important`,
    fontWeight: '500',
  },
  errorText: {
    color: `${theme.palette.danger[800]}!important`,
  },
});

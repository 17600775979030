import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  layoutApp: {
    display: 'grid',
    height: '100vh',
    width: '100%',
    gridTemplateColumns: 'auto 1fr',
    gridTemplateRows: 'auto 1fr',
    gridTemplateAreas: "'header header' 'nav content'",
  },
  navMain: {
    flex: '1 1 auto',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    // paddingTop: '4rem',
  },
  navSecondary: {
    flex: '0 0 auto',
    // paddingBottom: '2rem',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
});

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { AccountSettingsContainer } from 'components/AccountSettingsContainer/AccountSettingsContainer';
import { AddUuid } from 'components/AddUuid/AddUuid';
import { AdminHeaderTitle } from 'components/AdminHeaderTitle/AdminHeaderTitle';
import { Card } from 'components/Card/Card';
import { AddMapServiceIcon } from 'components/Icons/AddMapServiceIcon';
import { Loader } from 'components/Loader';
import { TablePagination } from 'components/TablePagination/TablePagination';
import { WidgetSplash } from 'components/WidgetSplash/WidgetSplash';
import SplashImage from 'images/ContentSplash.png';
import { usePagination, useSortBy, useTable } from 'react-table';
import { /* getMapGisRow, */ getMapGisServicesAll, setPageFilter } from 'slices/mapGisReducer';
import { externalActionType, externalDataType } from 'lib/external-data';
import { GeneralTable } from 'components/GeneralTable/GeneralTable';
import { TopBar } from 'components/TopBar/TopBar';
import { mapGisOptions } from 'lib/topBarOptions';
import { useGlobalStyles } from 'styles';
import { ModalLinkToMapOrServices } from 'smartComponents/ModalLinkToMapOrServices';
import moment from 'moment';
import { useSidebar } from 'components/SidebarProvider/SidebarProvider';
import { PencilIcon } from 'components/Icons';
import { DeleteIcon } from 'components/Icons/DeleteIcon';
import { IconButton, Grid } from '@mui/material';
import { MapCard } from 'components/MapGis/MapCard/MapCard';
import { ActiveMapGis } from 'components/MapGis/ActiveMapGis/ActiveMapGis';
import { MapViewComponent } from './MapViewComponent';
import { SearchPanel } from './SearchPanel';
// import { ComingSoon } from '../../../components/ComingSoon';
import { useStyles } from './styles';
import { mapGisAction } from './helpers';

const tableColumns = () => [
  {
    Header: 'Off/On',
    accessor: (rowData) => {
      const { mapLayerOn } = rowData;
      return (
        <div>
          <span>
            <ActiveMapGis on={mapLayerOn} webmap={rowData} />
          </span>
        </div>
      );
    },
  },
  {
    Header: 'Map/GIS Layer',
    accessor: (rowData) => rowData.mapName,
  },
  {
    Header: 'Description',
    accessor: (rowData) => {
      const { mapDescription } = rowData;
      return mapDescription || 'No Description Found.';
    },
  },
  {
    Header: 'Set-At-Level',
    accessor: (rowData) => {
      const { levelDesc } = rowData;
      let levelLabel = '';

      if (levelDesc === 'ACCT') {
        levelLabel = 'Account';
      } else if (levelDesc === 'PROJ') {
        levelLabel = 'Project';
      } else if (levelDesc === 'ORG') {
        levelLabel = 'Organization';
      } else {
        levelLabel = 'N/A';
      }

      return levelLabel;
    },
  },
  {
    Header: 'Date',
    accessor: (rowData) => moment(rowData.createdAt).format('MM/DD/YY'),
  },
  /* {
    Header: 'Map View',
    accessor: (rowData) => <MapViewComponent webMapID={rowData.arcgisMapId} />,
  }, */
];
let debounceTimeout = null;

const baseMapInitData = [
  {
    id: 1,
    basemap: 'hybrid',
    center: [-118.244, 34.052],
    zoom: 2,
  },
];

export const MapGis = ({ external }) => {
  const classes = useGlobalStyles();
  const localClasses = useStyles();
  const dispatch = useDispatch();
  const [openMapServices, setOpenMapServices] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const { loading, data, error, filters, totalCount } = useSelector((state) => state.adminMapGis);
  const [openUuid, setOpenUuid] = useState(false);
  const columns = React.useMemo(() => tableColumns(classes), []);
  const search = filters.search || '';
  const [mapData, setMapData] = useState(baseMapInitData[0]);
  const [action, setAction] = useState();
  const [tableToggle, setTableToggle] = useState(true);

  const loadData = () => {
    const sendFilters = {
      ...filters,
      // sort_by: Object.values(filters.sort_by),
    };
    dispatch(getMapGisServicesAll(sendFilters));
    console.info('soc_m_loadData', data);
  };

  // useEffect(() => {
  //   loadData();
  // }, [data]);

  useEffect(() => {
    clearTimeout(debounceTimeout);

    console.info('soc 2 ', data);
    console.info('soc 3 ', error);

    debounceTimeout = setTimeout(() => {
      dispatch(getMapGisServicesAll(filters));
    }, 300);
  }, [filters]);

  // ------------------------------- UUID functions
  const handleCloseUuid = (flag) => {
    setOpenUuid(false);
    if (flag) loadData(filters);
  };

  const handleCloseMapOrGisModal = () => {
    loadData();
  };

  // Manage the Open of a New modal to link to Map or Services
  /*   const mapServicesModalToggle = (bool) => {
    console.info('soc_m_mapServicesModalToggle', bool);
    setOpenMapServices(bool);
    setAction({});
  }; */

  const table = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: filters.page - 1,
        pageSize: filters.page_size,
      },
      pageCount: Math.ceil(totalCount / filters.page_size),
      totalCount,
      pageLength: data.length,
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  const {
    state: { pageIndex, pageSize },
  } = table;

  useEffect(() => {
    dispatch(setPageFilter(pageIndex + 1));
  }, [pageIndex, pageSize]);

  const { displayPanel, setHeaderPanels } = useSidebar({
    open: false,
    config: {
      search: {
        show: true,
        component: SearchPanel,
      },
      header: [],
      actions: [],
    },
  });

  const handleRowClick = async (row) => {
    console.info('sjmr ', row);
    setSelectedRow(row);
    // const handleRowClick = async (rowData) => {
    setHeaderPanels([
      {
        name: 'info',
        component: SearchPanel,
      },
    ]);
    displayPanel('info');
  };

  const handleToggle = (toggle) => {
    setTableToggle(toggle);
  };

  const handleEdit = (map) => {
    setAction({ action: mapGisAction.EDIT, data: map });
  };

  const MapGisActions = ({ row }) => (
    <div className={localClasses.buttonActions}>
      <IconButton onClick={() => handleEdit(row.original)}>
        <PencilIcon size={14} />
      </IconButton>
      <IconButton>
        <DeleteIcon size={14} />
      </IconButton>
    </div>
  );

  MapGisActions.propTypes = {
    row: PropTypes.object.isRequired,
  };

  const EmptyMapGisContainer = () => (
    <>
      <WidgetSplash
        alt="MapGis Splash"
        title="There are currently no Maps/GIS Services at the account level. Click the create button to begin adding Maps/GIS Services."
        image={SplashImage}
        titleAboveImage={false}
      />
    </>
  );

  useEffect(() => {
    // Set the initial map data
    setMapData(baseMapInitData[0]);
  }, []);

  const MapGisContainer = () => (
    <Card noPadding>
      <TopBar tableCardToggle options={mapGisOptions} searchValue={search} handleToggle={handleToggle} />
      {tableToggle ? (
        <>
          {/* "67af0d0ed2824538ae0917c1eb71e11f" */} {/* 37020fde8fec4ff08a5879c8b9ab353b */}
          <MapViewComponent mapData={mapData} webMapId={selectedRow?.original?.arcgisMapId ?? data[0]?.arcgisMapId} />
          <GeneralTable
            table={table}
            selectedRow={selectedRow}
            handleRowClick={handleRowClick}
            // handleRowClick={() => selectMap(1)}
            rowComponent={MapGisActions}
            actionFunction={() => loadData()}
            // eslint-disable-next-line no-console
            handleSelectedIds={(ids) => console.info(ids)}
            external={external}
          />
        </>
      ) : (
        <Grid container spacing={1} className={localClasses.cardsContainer}>
          {data?.length &&
            data &&
            data.map((item) => (
              <Grid key={item.id} item lg={4} md={4} sm={12} xs={12}>
                <MapCard data={item} handleEdit={handleEdit} />
              </Grid>
            ))}
        </Grid>
      )}
      <TablePagination table={table} />
    </Card>
  );

  /* if (Object.prototype.hasOwnProperty.call(process.env, 'REACT_APP_COMING_SOON_ARCGIS')) {
    return (
      <AccountSettingsContainer
        title={<AdminHeaderTitle buttonName="ADMINISTRATION" section="Linked Maps/GIS Services: Account Level" />}
      >
        <ComingSoon />
      </AccountSettingsContainer>
    );
  }) */
  if (loading && data.length === 0) {
    return (
      <AccountSettingsContainer
        title={<AdminHeaderTitle buttonName="ADMINISTRATION" section="Linked Maps/GIS Services: Account Level" />}
        actions={[
          {
            icon: AddMapServiceIcon,
            onClick: () => {
              setOpenMapServices(true);
              setAction({});
            },
          },
        ]}
        showSidebar
      >
        <Loader loading={loading} height={250} />
        {openUuid?.type === externalActionType.ADD && (
          <AddUuid
            handleClose={handleCloseUuid}
            type={externalDataType.PROJ}
            variant={openUuid.variant}
            item={openUuid}
          />
        )}
      </AccountSettingsContainer>
    );
  }

  return (
    <AccountSettingsContainer
      title={<AdminHeaderTitle buttonName="ADMINISTRATION" section="Linked Maps/GIS Services: Account Level" />}
      actions={[
        {
          icon: AddMapServiceIcon,
          onClick: () => {
            setOpenMapServices(true);
            setAction({});
          },
        },
      ]}
      showSidebar
    >
      {data.length > 0 ? MapGisContainer() : <EmptyMapGisContainer />}
      {openUuid?.type === externalActionType.ADD && (
        <AddUuid
          handleClose={handleCloseUuid}
          type={externalDataType.PROJ}
          variant={openUuid.variant}
          item={openUuid}
        />
      )}
      <ModalLinkToMapOrServices
        handleOpen={(val) => {
          console.info('soc1 ', val);
          console.info('soc100 ', action?.data);
          console.info('soc1001 ', action?.action);
          setOpenMapServices(val);
          setAction({});
        }}
        isOpen={openMapServices || action?.action === mapGisAction.EDIT}
        onClose={handleCloseMapOrGisModal}
        mapGis={action?.data}
        action={action?.action}
      />
    </AccountSettingsContainer>
  );
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';
import { getInitials } from 'lib/generalFunctions';
import { Avatar } from 'components/Avatar';
import InspectionService from 'services/InspectionService';
import { DialogMessage, dialogMessageAction } from 'components/DialogMessage';
import { InfoIcon } from 'components/Icons';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import { assignInspectionType } from '../helpers';
import { useStyles } from './styles';

export const ProjectsInspectiosList = ({ list, objectId, type, onRemove }) => {
  const classes = useStyles();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const remove = async () => {
    const itemId = openConfirm;
    setOpenConfirm(false);
    setIsLoading(true);
    InspectionService.deleteInspectionProject(
      type === assignInspectionType.INSPECTION ? objectId : itemId,
      type === assignInspectionType.INSPECTION ? itemId : objectId
    )
      .then(() => {
        const itemsList = [...data];
        const index = itemsList.findIndex((item) => item.id === itemId);
        if (index !== -1) itemsList.splice(index, 1);
        setData(itemsList);
        setIsLoading(false);
        onRemove(itemsList);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setData(list);
  }, [list]);

  return (
    <>
      {data.map((item) => (
        <div key={item.id} className={classes.listItem}>
          <div className={classes.listName}>
            {type === assignInspectionType.INSPECTION && (
              <div className="avatar">
                <Avatar key={item.id} initials={getInitials(item.name)} size={40} />
              </div>
            )}
            <div className="text">
              <Typography>{item.name}</Typography>
              <Typography className="description">{item.description}</Typography>
            </div>
          </div>
          <Button color="danger" className={classes.listButton} onClick={() => setOpenConfirm(item.id)}>
            <Typography>Remove</Typography>
          </Button>
        </div>
      ))}
      <DialogMessage
        title="Remove Inspection & Findings"
        content="This action will remove this inspection and its findings from this project."
        isOpen={!!openConfirm}
        onConfirm={remove}
        onCancel={() => setOpenConfirm(false)}
        action={dialogMessageAction.delete}
      />
      <DialogMessage
        title={openDialog}
        isOpen={!!openDialog}
        icon={InfoIcon}
        confirmText="Ok"
        onConfirm={() => setOpenDialog(false)}
      />
      <LoadingOverlay loading={isLoading} />
    </>
  );
};

ProjectsInspectiosList.propTypes = {
  list: PropTypes.array,
  type: PropTypes.string.isRequired,
  objectId: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
};

ProjectsInspectiosList.defaultProps = {
  list: [],
  onRemove: () => {},
};

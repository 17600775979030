import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  assetContent: {
    position: 'relative',
  },
  progressContent: {
    position: 'absolute',
    left: '5px',
    top: '10px',
    width: 320,
    zIndex: 1,
    background: 'rgba(255, 255, 255, 0.20)',
    padding: '0px 12px',
  },
  progressBar: {
    '& .MuiLinearProgress-root': {
      background: theme.palette.primary.main,
    },
  },
  assignment: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  findingIcons: {
    '& svg': {
      color: theme.palette.primary[400],
    },
  },
  playButton: {
    '& .vjs-big-play-button': {
      top: '42%',
      fontSize: '5em',
      height: '2.5em',
      width: '2.5em',
      backgroundColor: 'transparent',
      backgroundImage: 'url(/inspectionPlay.png)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      border: 'none !important',
      boxShadow: 'none !important',
      '& .vjs-icon-placeholder:before': {
        content: '',
        display: 'none',
      },
      '&:hover': {
        backgroundColor: 'transparent',
        opacity: 0.7,
      },
    },
  },
  exportAllFindingsButton: {
    marginLeft: `${theme.spacing(0.5)}!important`,
    height: 40,
    '& svg': {
      marginRight: `${theme.spacing(0.2)}!important`,
    },
  },
}));

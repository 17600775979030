import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const LoadIcon = ({ size, className }) => (
  <Svg viewBox="0 0 24 24" size={size} className={className}>
    <path
      d="M19.65 4.5L24 8.95h-3.275c0 2.477-.853 4.588-2.559 6.332-1.705 1.745-3.752 2.617-6.14 2.617-1.672 0-3.224-.471-4.657-1.413l1.586-1.623c.921.524 1.945.785 3.07.785 1.809 0 3.352-.654 4.632-1.962 1.279-1.309 1.919-2.887 1.919-4.737H15.3l4.35-4.448zM5.475 8.95h3.276L4.35 13.397 0 8.949h3.275c0-2.477.853-4.588 2.559-6.332C7.539.872 9.604 0 12.026 0c1.671 0 3.223.471 4.656 1.413l-1.637 1.622a6.007 6.007 0 00-3.02-.785c-1.808 0-3.351.655-4.63 1.963-1.28 1.308-1.92 2.887-1.92 4.736z"
      fill="currentColor"
    />
  </Svg>
);

LoadIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
};

LoadIcon.defaultProps = {
  size: 24,
  className: '',
};

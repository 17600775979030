import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AssetService from 'services/AssetService';
import { formatDateRange } from '../helpers';

export const fetchContent = createAsyncThunk('content-search', async ({ filters }) => {
  const contentFilters = {
    search: filters.search,
    strict: true,
    ...formatDateRange(filters.date_range),
    // // ...getState().search.projects.filters,
    ...(filters.map_extent && { geo: filters.map_extent }),
    ...(filters.projectId && { projectId: filters.projectId }),
    ...(filters.organizationId && { organizationId: filters.organizationId }),
    ...(filters.accountId && { accountId: filters.accountId }),
    ...(filters.type && { type: filters.type }),
    ...(filters.page && { page: filters.page }),
    ...(filters.page_size && { page_size: filters.page_size }),
    include: ['uploader', 'project', 'org', 'account'],
  };
  const result = await AssetService.getAssets(contentFilters);

  return {
    ...result.data,
    filters,
  };
});

const initialState = {
  data: [],
  totalCount: 0,
  loading: false,
  error: null,
  proximity: null,
  filters: {
    page: 1,
    page_size: 100,
    search: '',
    date_range: null,
    map_extent: null,
    projectId: null,
    organizationId: null,
    accountId: null,
    type: null,
  },
};

const searchProjectsSlice = createSlice({
  name: 'search-content',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading;
    },
    reset: (state) => {
      state.data = [];
      state.totalCount = 0;
      state.loading = false;
      state.error = null;
      state.filters = {
        ...initialState.filters,
      };
    },
    cleanContent: (state) => {
      state.data = [];
      state.filters.page = 1;
      state.totalCount = 0;
    },
    setProximity: (state, action) => {
      state.proximity = action.payload.proximity;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchContent.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchContent.fulfilled, (state, { payload }) => {
      if (payload) {
        state.data = payload.data;
        state.totalCount = payload.meta.totalCount;
        state.loading = false;
        state.filters = {
          ...payload.filters,
          page: payload.meta.page,
          page_size: payload.meta.pageSize,
        };
        state.proximity = null;
      }
    });

    builder.addCase(fetchContent.rejected, (state) => {
      state.error = 'Error fetching content';
      state.totalCount = 0;
      state.data = [];
      state.loading = false;
    });
  },
});

export const { setLoading, reset, setPageFilter, cleanContent, setProximity } = searchProjectsSlice.actions;

export default searchProjectsSlice.reducer;

import styled from 'styled-components';

export const SvgRotated = styled.svg.attrs({
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg',
  xmlnsXlink: 'http://www.w3.org/1999/xlink',
  viewBox: '0 0 24 24',
  fill: 'none',
  transform: 'rotate(90)',
})`
  width: ${(props) => props.size}px;
  height: auto;
`;

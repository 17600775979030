import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const DocumentIcon = ({ size }) => (
  <Svg viewBox="0 0 24 24" size={size}>
    <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z" fill="currentColor" />
    <polyline points="13 2 13 9 20 9" />
  </Svg>
);

DocumentIcon.propTypes = {
  size: PropTypes.number,
};

DocumentIcon.defaultProps = {
  size: 24,
};

/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormRadioGroup } from 'components/FormRadioGroup';
import { SidebarPanel, cleanObjectFilters } from 'components/SidebarPanel';
import { FormControl, FormLabel } from '@mui/material';
import { SearchList } from 'components/SidebarPanel/SearchList/SearchList';
import { ClearFilterAction } from 'components/SidebarPanel/ClearFilterAction';

export const SearchPanelBody = ({
  loaded,
  filters,
  users,
  // eslint-disable-next-line no-unused-vars
  setFilterValue,
  setFilters,
}) => {
  const dispatch = useDispatch();
  const [filterActions, setFiltersActions] = useState([]);
  const [localFilters, setLocalFilters] = useState({
    userId: '',
    isOpen: null,
  });

  // update redux filters
  const onSearchChange = (value, param) => {
    const filter = {
      param,
      value,
    };
    dispatch(setFilterValue(filter));
  };

  // clear sort
  const clearSort = (param) => {
    const auxFilters = { ...localFilters };
    delete auxFilters.sort_by[param];
    auxFilters.sort_by = cleanObjectFilters(auxFilters.sort_by);
    setLocalFilters(auxFilters);
  };

  // update filters
  const updateFIlter = (auxFilters) => {
    setLocalFilters({ ...auxFilters });
  };

  // update radio buttons
  const updateNormalFilters = (value, type) => {
    const auxFilters = { ...localFilters };
    auxFilters[type] = value;
    setLocalFilters({ ...auxFilters });
  };

  // -------------- clear filters
  const handleClearFilters = () => {
    const auxFilters = { filters: localFilters };
    if (cleanObjectFilters(auxFilters)?.filters) {
      dispatch(
        setFilters({
          isOpen: null,
          sort_by: {},
          userId: '',
          activated: false,
        })
      );
    }
  };

  // -------------- apply filters
  const handleApplyFilters = () => {
    const auxFilters = { ...localFilters };
    auxFilters.sort_by = cleanObjectFilters(auxFilters.sort_by);
    dispatch(setFilters({ ...auxFilters, activated: filterActions.length > 0 }));
  };

  const createClearFilters = (clearFilters) => {
    const auxFilters = [];
    if (clearFilters?.userId?.length > 0)
      auxFilters.push({ text: 'Clear By User', callBack: () => updateNormalFilters(null, 'userId') });
    if (clearFilters.isOpen !== null && clearFilters.isOpen !== undefined)
      auxFilters.push({
        text: `Clear ${clearFilters.isOpen ? 'Open' : 'Closed'}`,
        callBack: () => updateNormalFilters(null, 'isOpen'),
      });
    if (clearFilters.sort_by?.name) auxFilters.push({ text: 'Sort By Name', callBack: () => clearSort('name') });
    if (clearFilters.sort_by?.createdAt)
      auxFilters.push({ text: 'Sort By Created At', callBack: () => clearSort('createdAt') });
    if (clearFilters.sort_by?.org) auxFilters.push({ text: 'Sort By Org', callBack: () => clearSort('org') });
    setFiltersActions(auxFilters);
    return auxFilters;
  };

  useEffect(() => {
    createClearFilters(localFilters);
  }, [localFilters]);

  useEffect(() => {
    const auxFilters = createClearFilters(filters);
    onSearchChange(auxFilters?.length > 0, 'activated');
    setLocalFilters(filters);
  }, [filters]);

  console.info(loaded);

  return (
    <SidebarPanel
      title="Filters: Map/GIS Services"
      subTitle="Apply filters to table data."
      filters
      handleClearFilters={handleClearFilters}
      handleApplyFilters={handleApplyFilters}
    >
      <ClearFilterAction actions={filterActions} />
      <div className="searchBody">
        <FormControl>
          <FormLabel className="marginTop">Switched On/Off</FormLabel>
          <FormRadioGroup
            name="open"
            value={localFilters.isOpen}
            // onChecked={(checkedVal) => updateNormalFilters(checkedVal, 'isOpen')}
            options={[
              { label: 'All', value: null },
              { label: 'On', value: true },
              { label: 'Off', value: false },
            ]}
            view="horizontal"
          />
        </FormControl>

        <FormControl>
          <FormLabel className="marginTop">Type</FormLabel>
          <FormRadioGroup
            name="open"
            value={localFilters.isOpen}
            // onChecked={(checkedVal) => updateNormalFilters(checkedVal, 'isOpen')}
            options={[
              { label: 'All', value: null },
              { label: 'Service', value: true },
              { label: 'Map', value: false },
            ]}
            view="horizontal"
          />
        </FormControl>

        <SearchList
          title="Added By"
          fullList={users}
          filter="userId"
          localFilters={localFilters}
          onChangeSelect={updateFIlter}
          loaded={loaded}
        />
      </div>
    </SidebarPanel>
  );
};

SearchPanelBody.propTypes = {
  loaded: PropTypes.bool,
  filters: PropTypes.object,
  users: PropTypes.array,
  setFilterValue: PropTypes.func,
  setFilters: PropTypes.func,
};

SearchPanelBody.defaultProps = {
  loaded: false,
  filters: null,
  users: [],
  setFilterValue: () => {},
  setFilters: () => {},
};

export class Permissions {
  static ACCOUNT_BILLING = 'account:billing';

  static ACCOUNT_CREATE_ORG = 'account:create_org';

  static ACCOUNT_DASHBOARD = 'account:dashboard';

  static ACCOUNT_MODIFY = 'account:modify';

  static ACCOUNT_USER_ADD = 'account:user_add';

  static ACCOUNT_USER_GRANT = 'account:user_grant';

  static ACCOUNT_USER_MODIFY = 'account:user_modify';

  static ACCOUNT_USER_REMOVE = 'account:user_remove';

  static ACCOUNT_USER_VIEW = 'account:user_view';

  static ACCOUNT_VIEW = 'account:view';

  static ORG_DASHBOARD = 'org:dashboard';

  static ORG_DELETE = 'org:delete';

  static ORG_MODIFY = 'org:modify';

  static ORG_PROJ_CREATE = 'org:proj_create';

  static ORG_TEAM_CREATE = 'org:team_create';

  static ORG_USER_ADD = 'org:user_add';

  static ORG_USER_GRANT = 'org:user_grant';

  static ORG_USER_MODIFY = 'org:user_modify';

  static ORG_USER_REMOVE = 'org:user_remove';

  static ORG_VIEW = 'org:view';

  static PROJ_DELETE = 'proj:delete';

  static PROJ_DELETE_CONTENT = 'proj:delete_content';

  static PROJ_MANAGE_ZOOM = 'proj:manage_zoom';

  static PROJ_MODIFY = 'proj:modify';

  static PROJ_MODIFY_CONTENT = 'proj:modify_content';

  static PROJ_MODIFY_CONTENT_SHARE_COPY = 'proj:modify_content-share_copy';

  static PROJ_SHARE = 'proj:modify_content-share';

  static PROJ_COPY = 'proj:modify_content-copy';

  static PROJ_UPLOAD_CONTENT = 'proj:upload_content';

  static PROJ_USER_ADD = 'proj:user_add';

  static PROJ_USER_GRANT = 'proj:user_grant';

  static PROJ_USER_MODIFY = 'proj:user_modify';

  static PROJ_USER_REMOVE = 'proj:user_remove';

  static PROJ_VIEW = 'proj:view';

  static PROJ_VIEW_CONTENT = 'proj:view_content';

  static PROJ_OBSERVATION = 'proj:observation';

  static PROJ_COPY_UUID = 'proj:copy_uuid';

  static PROJ_INSPECTIONS = 'proj:inspection_create';

  static TEAM_DELETE = 'team:delete';

  static TEAM_MODIFY = 'team:modify';

  static TEAM_USER_ADD = 'team:user_add';

  static TEAM_USER_GRANT = 'team:user_grant';

  static TEAM_USER_MODIFY = 'team:user_modify';

  static TEAM_USER_REMOVE = 'team:user_remove';

  static TEAM_VIEW = 'team:view';
}

export const Grants = {
  PROJ: 'projects',
  TEAM: 'teams',
};

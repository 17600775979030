import { Typography } from 'components/Typography';
import styled from 'styled-components';

export const StyledTypography = styled(Typography)`
  font-size: 15px;
`;

export const ObservationRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  width: 100%;
  & > * {
    margin-bottom: ${(props) => props.theme.spacing(0.4)};
    margin-right: ${(props) => props.theme.spacing(0.4)};
  }
`;

export const IconContent = styled.div`
  margin: 0;
  display: flex;
  align-items: end;
`;

export const NameContent = styled.div`
  margin: 0;
  display: flex;
  align-items: end;
  flex: 1 1;
  width: 60%;
  overflow-x: auto;
`;

export const ObservationIconStyle = styled.div`
  margin-right: 10px;
  color: ${(props) => props.theme.palette.secondary.base};
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
`;

export const ObservationName = styled.div`
  cursor: pointer;
`;

export const EditButton = styled.button`
  margin: 0;
  width: 40px;
  height: 40px;
  cursor: pointer;
  color: ${(props) => props.theme.palette.primary[300]};
  background: transparent;
  border: none;
  &:hover {
    border-radius: 50%;
    background-color: ${(props) => props.theme.palette.primary.base};
  }
`;

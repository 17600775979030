import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  observation: (props) => ({
    position: 'absolute',
    border: `1px solid ${theme.palette.primary[800]}`,
    borderRadius: '10px',
    left: props.type === 'video' || props.type === 'mapSimple' ? '25%' : '2px',
    right: props.type === 'video' || props.type === 'mapSimple' ? '25%' : '2px',
    bottom: props.type === 'video' ? '80px' : '2px',
    zIndex: 200,
    backgroundColor: theme.palette.primary[1000],
    padding: '1rem',
    display: 'flex',
    minHeight: '120px',
  }),
  container: {
    paddingLeft: theme.spacing(0.5),
    flex: 2,
  },
  image: {
    flex: 1.5,
    display: 'flex',
    alignItems: 'center',
    backgroundImage: (props) => `url(${props.src})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: '10px',
    '& > img': {
      width: '100%',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: theme.spacing(0.5),
  },
  primaryButton: {
    backgroundColor: `${theme.palette.primary.main}!important`,
    '&:hover': {
      backgroundColor: `${theme.palette.primary.dark}!important`,
    },
  },
  secondaryButton: {
    backgroundColor: `${theme.palette.secondary.main}!important`,
    color: 'black!important',
    '&:hover': {
      backgroundColor: `${theme.palette.secondary.dark}!important`,
    },
  },
}));

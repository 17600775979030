import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AdminService from 'services/AdminService';
import { LayoutAuth } from 'components/LayoutAuth';
import { Autocomplete, Button, Grid, InputLabel, TextField, Typography } from '@mui/material';

export const Accounts = () => {
  const history = useHistory();
  const [accounts, setAccounts] = useState([]);
  const error = useSelector((state) => state.auth.error);

  useEffect(() => {
    const getAccounts = async () => {
      const response = await AdminService.getAccounts();
      let mappedAccountData = response.data.data.map((account) => ({
        value: account.id,
        label: account.name,
      }));
      mappedAccountData = mappedAccountData.sort((a, b) => a.label.localeCompare(b.label));
      setAccounts(mappedAccountData);
    };
    getAccounts();
  }, []);

  const formik = useFormik({
    initialValues: {
      account: null,
    },
    validationSchema: Yup.object({
      account: Yup.object().required('Required'),
    }),
    onSubmit: async ({ account }) => {
      const response = await AdminService.switchAccounts({ accountId: account.value });
      if (response.status === 201) {
        // reset token
        const { token } = response.data.data;
        localStorage.setItem('user', JSON.stringify({ accessToken: token }));
        history.push('/admin');
      }
    },
  });

  const handleChange = (selectedOption) => {
    const event = { target: { name: 'account', value: selectedOption } };
    formik.handleChange(event);
  };

  return (
    <LayoutAuth title="Accounts" subTitle="Please select account being supported" noReturn>
      <form onSubmit={formik.handleSubmit} noValidate>
        {error && (
          <Typography color="error" align="center" mb={1}>
            {error}
          </Typography>
        )}
        <InputLabel>Account</InputLabel>
        <Autocomplete
          disableClearable
          options={accounts}
          value={formik.values.account}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => <TextField {...params} placeholder="Select Account" />}
          name="account"
          error={!!(formik.touched.account && formik.errors.account)}
          helpText={formik.touched.account && formik.errors.account ? formik.errors.account : null}
          onBlur={formik.handleBlur}
          onChange={(e, value) => {
            handleChange(value);
          }}
        />
        <Button type="submit" color="secondary" variant="contained" fullWidth>
          OK
        </Button>
        <Grid container spacing={1}>
          <Grid item xs={6} style={{ paddingTop: 0 }}>
            <Button
              type="button"
              color="primary"
              variant="contained"
              onClick={() => history.push('/sa/tgadmin-create')}
              size="small"
              fullWidth
            >
              Add to Admin
            </Button>
          </Grid>
          <Grid item xs={6} style={{ paddingTop: 0 }}>
            <Button
              type="button"
              color="primary"
              variant="contained"
              onClick={() => history.push('/sa/create-account')}
              size="small"
              fullWidth
            >
              Create Account
            </Button>
          </Grid>
        </Grid>
      </form>
    </LayoutAuth>
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus,
} from '@fortawesome/free-solid-svg-icons';
import { Button } from '@mui/material';
import { useStyles } from './styles';

export const CropperButtons = ({ cropperRef, isImageLoading, handleClickEditor, buttonEditName }) => {
  const classes = useStyles();

  return (
    <div className={classes.cropperControlContainer}>
      <Button
        type="button"
        size="medium"
        color="primary"
        variant="contained"
        title="Zoom In"
        onClick={() => {
          cropperRef.current.cropper.zoom(0.1);
        }}
      >
        <FontAwesomeIcon icon={faMagnifyingGlassPlus} size="lg" />
      </Button>
      <Button
        type="button"
        size="medium"
        color="primary"
        variant="contained"
        title="Zoom Out"
        onClick={() => {
          cropperRef.current.cropper.zoom(-0.1);
        }}
      >
        <FontAwesomeIcon icon={faMagnifyingGlassMinus} size="lg" />
      </Button>
      <Button
        type="button"
        size="medium"
        color="primary"
        variant="contained"
        title="Move Left"
        onClick={() => {
          cropperRef.current.cropper.move(-10, 0);
        }}
      >
        <FontAwesomeIcon icon={faArrowLeft} size="lg" />
      </Button>
      <Button
        type="button"
        size="medium"
        color="primary"
        variant="contained"
        title="Move Right"
        onClick={() => {
          cropperRef.current.cropper.move(10, 0);
        }}
      >
        <FontAwesomeIcon icon={faArrowRight} size="lg" />
      </Button>
      <Button
        type="button"
        size="medium"
        color="primary"
        variant="contained"
        title="Move Up"
        onClick={() => {
          cropperRef.current.cropper.move(0, -10);
        }}
      >
        <FontAwesomeIcon icon={faArrowUp} size="lg" />
      </Button>
      <Button
        type="button"
        size="medium"
        color="primary"
        variant="contained"
        title="Move Down"
        onClick={() => {
          cropperRef.current.cropper.move(0, 10);
        }}
      >
        <FontAwesomeIcon icon={faArrowDown} size="lg" />
      </Button>
      <Button
        type="button"
        size="medium"
        color="secondary"
        variant="contained"
        title="Edit Image"
        onClick={() => handleClickEditor()}
        disabled={isImageLoading}
      >
        {buttonEditName}
      </Button>
    </div>
  );
};

CropperButtons.propTypes = {
  cropperRef: PropTypes.node,
  isImageLoading: PropTypes.bool,
  handleClickEditor: PropTypes.func,
  buttonEditName: PropTypes.string,
};

CropperButtons.defaultProps = {
  cropperRef: null,
  isImageLoading: false,
  handleClickEditor: () => {},
  buttonEditName: 'Image Markup',
};

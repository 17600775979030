import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Grid } from '@mui/material';
import { usePagination, useSortBy, useTable } from 'react-table';
import { setProjectPageFilter } from 'slices/projectMapGis';
import { GeneralTable } from 'components/GeneralTable/GeneralTable';
import { TablePagination } from 'components/TablePagination/TablePagination';
import { WidgetSplash } from 'components/WidgetSplash/WidgetSplash';
import SplashImage from 'images/ContentSplash.png';
import { Loader } from 'components/Loader';
import { MapViewComponent } from 'pages/Admin/MapGis/MapViewComponent';
import { ActiveMapGis } from './ActiveMapGis/ActiveMapGis';
import { MapGisTopBar } from './MapGisTopBar/MapGisTopBar';
import { MapCard } from './MapCard/MapCard';
import { useStyles } from './styles';

export const mapGisSection = {
  PROJECT: 'project',
  ORG: 'org',
};

const tableColumns = () => [
  {
    Header: 'Off/On',
    accessor: (rowData) => {
      const { mapLayerOn } = rowData;
      return (
        <div>
          <span>
            <ActiveMapGis on={mapLayerOn} />
          </span>
        </div>
      );
    },
  },
  {
    Header: 'Map/GIS Layer',
    accessor: (rowData) => rowData.mapName,
  },
  {
    Header: 'Description',
    accessor: (rowData) => {
      const { mapDescription } = rowData;
      return mapDescription || 'No Description Found.';
    },
  },
  {
    Header: 'Set-At-Level',
    accessor: (rowData) => {
      const { account } = rowData;
      return account ? account.name : 'Account';
    },
  },
  {
    Header: 'Date',
    accessor: (rowData) => moment(rowData.createdAt).format('MM/DD/YY'),
  },
];

const baseMapInitData = [
  {
    id: 1,
    basemap: 'hybrid',
    center: [-118.244, 34.052],
    zoom: 2,
  },
];

export const MapGis = ({ section }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const reducer = section === mapGisSection.PROJECT ? 'projectMapGis' : '';
  const { data, filters, totalCount, loading } = useSelector((state) => state[reducer]);
  const columns = React.useMemo(() => tableColumns(), []);
  const [tableToggle, setTableToggle] = useState(false);

  const handleToggle = (toggle) => {
    setTableToggle(toggle);
  };

  const table = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: filters.page - 1,
        pageSize: filters.page_size,
      },
      pageCount: Math.ceil(totalCount / filters.page_size),
      totalCount,
      pageLength: data.length,
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  const {
    state: { pageIndex, pageSize },
  } = table;

  useEffect(() => {
    dispatch(setProjectPageFilter(pageIndex + 1));
  }, [pageIndex, pageSize]);

  if (loading && data.length === 0) {
    return <Loader loading={loading} height={250} />;
  }

  const Content = () => (
    <div className={classes.container}>
      <MapGisTopBar handleToggle={handleToggle} />
      {loading ? (
        <Loader height={50} loading size={32} />
      ) : (
        <>
          <div style={{ display: tableToggle ? 'flex' : 'none' }} className={classes.container}>
            <MapViewComponent mapData={baseMapInitData} webMapId={data[0]?.webMapId} />
            <GeneralTable
              table={table}
              // selectedRow={selectedRow}
              // handleRowClick={() => handleRowClick}
              // handleRowClick={() => selectMap(1)}
              // rowComponent={MapGisActions}
              // eslint-disable-next-line no-console
              // handleSelectedIds={(ids) => console.log(ids)}
            />
          </div>
          <Grid
            container
            spacing={1}
            className={classes.cardsContainer}
            style={{ display: tableToggle ? 'none' : 'flex' }}
          >
            {data?.length &&
              data &&
              data.map((item) => (
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <MapCard data={item} />
                </Grid>
              ))}
          </Grid>
          <TablePagination table={table} />
        </>
      )}
    </div>
  );

  return (
    <>
      {!data?.length ? (
        <WidgetSplash alt="Project Gis/Map Splash" title="There are no maps" image={SplashImage} />
      ) : (
        <>{Content()}</>
      )}
    </>
  );
};

MapGis.propTypes = {
  section: PropTypes.string,
};

MapGis.defaultProps = {
  section: mapGisSection.PROJECT,
};

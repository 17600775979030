import ApiService from './ApiService';

export default class EnumService {
  static getAccountRoles = () => ApiService.get(`/enum/roles/account`);

  static getOrgRoles = () => ApiService.get(`/enum/roles/org`);

  static getProjectRoles = () => ApiService.get(`/enum/roles/project`);

  static getTeamRoles = () => ApiService.get(`/enum/roles/team`);

  static getContentCameras = () => ApiService.get(`/enum/asset/cameras`);
}

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  userContent: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiTypography-root': {
      fontWeight: `${400}!important`,
    },
  },
  title: {
    position: 'relative',
  },
  heading: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '5px',
    width: '100%',
  },
});

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { ImageCheckIcon, InfoIcon } from 'components/Icons';
import { ModalHeaderIcon } from 'components/ModalHeaderIcon/ModalHeaderIcon';
import { getProjects } from 'slices/allProjectReducer';
import { useDispatch, useSelector } from 'react-redux';
import { DialogMessage } from 'components/DialogMessage';
import InspectionService from 'services/InspectionService';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import { getInspections } from 'slices/inspectionReducer';
import { Loader } from 'components/Loader';
import { assignInspectionType } from '../helpers';
import { ProjectsInspectiosList } from './ProjectsInspectiosList';
import { useStyles } from './styles';

const validationSchema = Yup.object({
  itemIds: Yup.array().min(1, `Field must have at least 1 items`).required(`Field is required`),
});

const groupLevel = {
  ACCT: 'Account-Level Inspections',
  PROJ: 'Project-Level Inspections',
  ORG: 'Org-Level Inspections',
};

export const AssignProjectInspection = ({ inspection, project, handleClose, type }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading, data, filters } = useSelector(
    (state) => state[type === assignInspectionType.INSPECTION ? 'allProjects' : 'inspections']
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const projects = data.map((proj) => ({
    label: proj.name,
    value: proj.id,
    ...(type === assignInspectionType.PROJ && { objectType: proj.objectType, objectId: proj.objectId }),
  }));
  const [list, setList] = useState([]);
  const [isListLoading, setIsListLoading] = useState([]);
  const [listEdited, setListEdited] = useState(false);

  const save = async (values) => {
    setIsLoading(true);
    let params = {
      inspectionId: inspection?.id,
      projectIds: values.itemIds.map((item) => item.value),
    };
    if (type === assignInspectionType.PROJ) {
      params = {
        projectId: project?.id,
        inspectionIds: values.itemIds.map((item) => item.value),
      };
    }

    InspectionService.assignProject(params)
      .then(() => {
        setIsLoading(false);
        handleClose();
      })
      .catch(() => {
        setIsLoading(false);
        setOpenDialog('An error occurred while trying to assign inspection to project.');
      });
  };

  const onRemove = () => {
    setListEdited(true);
  };

  const formik = useFormik({
    initialValues: {
      itemIds: [],
    },
    validationSchema,
    onSubmit(values) {
      save(values);
    },
  });

  const getInspectionsProjects = async () => {
    setIsListLoading(true);
    if (type === assignInspectionType.INSPECTION) {
      InspectionService.getProjectsInInspection(inspection?.id)
        .then((result) => {
          setList(result.data);
          setIsListLoading(false);
        })
        .catch(() => {
          setList([]);
          setIsListLoading(false);
        });
    } else {
      InspectionService.getInspectionsInProject(project?.id)
        .then((result) => {
          setList(result.data);
          setIsListLoading(false);
        })
        .catch(() => {
          setList([]);
          setIsListLoading(false);
        });
    }
  };

  const onCancel = () => {
    if (listEdited) {
      handleClose();
    } else handleClose(false);
  };

  useEffect(() => {
    const newFilters = {
      ...filters,
      page_size: 1000,
      ...(type === assignInspectionType.PROJ && { isActive: { eq: true } }),
    };
    if (type === assignInspectionType.INSPECTION) {
      dispatch(getProjects(newFilters));
    } else {
      dispatch(getInspections(newFilters));
    }
    getInspectionsProjects();
  }, []);

  const singleOptions = projects.filter(
    (item1) => !formik.values?.itemIds.some((item2) => item2.value === item1.value)
  );

  return (
    <Dialog open onClose={() => handleClose(false)} maxWidth="xs">
      <DialogContent>
        <ModalHeaderIcon
          icon={ImageCheckIcon}
          text={
            type === assignInspectionType.INSPECTION
              ? 'Bulk-Assign Inspection to Project(s)'
              : 'Assign Inspection to All Content'
          }
          color="warning"
        />
        <Typography mt={0.5}>
          This will assign the inspection to all content within the project. To assign to particular pieces of content,
          visit the Content page or manage within the project.
        </Typography>
        <div className={classes.list}>
          {isListLoading ? (
            <Loader height={10} loading size={32} />
          ) : (
            <ProjectsInspectiosList
              list={list}
              objectId={type === assignInspectionType.INSPECTION ? inspection?.id : project?.id}
              type={type}
              onRemove={onRemove}
            />
          )}
        </div>
        <InputLabel>
          <Typography>{type === assignInspectionType.INSPECTION ? 'Project' : 'Inspection'}*</Typography>
        </InputLabel>
        <FormControl error={formik.touched.itemIds && formik.errors.itemIds} fullWidth>
          {type === assignInspectionType.PROJ ? (
            <Autocomplete
              multiple
              loading={loading}
              name="itemIds"
              options={
                loading
                  ? []
                  : singleOptions
                      .filter(
                        (item) =>
                          item.objectType === 'ACCT' ||
                          item.objectType === 'ORG' ||
                          (item.objectType === 'PROJ' && item.objectId === project?.id)
                      )
                      .sort((a, b) => -b.objectType.localeCompare(a.objectType))
              }
              groupBy={(option) => option.objectType}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Inspection"
                  onBlur={formik.handleBlur}
                  required
                  error={!!(formik.touched.itemIds && formik.errors.itemIds)}
                />
              )}
              onChange={(e, value) => {
                formik.setFieldValue('itemIds', value);
              }}
              renderGroup={(option) => (
                <div>
                  <Typography className={classes.groupOption}>{groupLevel[option.group]}</Typography>
                  <Typography>{option.children}</Typography>
                </div>
              )}
            />
          ) : (
            <Autocomplete
              multiple
              loading={loading}
              options={loading ? [] : singleOptions}
              name="itemIds"
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Project"
                  onBlur={formik.handleBlur}
                  required
                  error={!!(formik.touched.itemIds && formik.errors.itemIds)}
                />
              )}
              onChange={(e, value) => {
                formik.setFieldValue('itemIds', value);
              }}
            />
          )}
          <FormHelperText>
            {formik.touched.itemIds && formik.errors.itemIds ? formik.errors.itemIds : null}
          </FormHelperText>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button color="primary" variant="contained" onClick={onCancel} size="large" fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button color="secondary" variant="contained" size="large" onClick={formik.handleSubmit} fullWidth>
              Done
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
      <DialogMessage
        title={openDialog}
        isOpen={openDialog}
        icon={InfoIcon}
        confirmText="Ok"
        onConfirm={() => {
          setOpenDialog(false);
        }}
      />
      <LoadingOverlay loading={isLoading} />
    </Dialog>
  );
};

AssignProjectInspection.propTypes = {
  inspection: PropTypes.object,
  project: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  type: PropTypes.string,
};

AssignProjectInspection.defaultProps = {
  inspection: null,
  project: null,
  type: assignInspectionType.INSPECTION,
};

import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  faqContent: {
    padding: theme.spacing(3),
  },
  boldFont: {
    fontWeight: 900,
    fontSize: '14px',
  },
});

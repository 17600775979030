import PropTypes from 'prop-types';
import React, { createContext, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

export const ContextMenuContext = createContext({
  buttonRef: { current: null },
  isOpen: false,
  menuRef: { current: null },
  positionData: { button: {}, menu: {} },
  toggleName: () => {},
});

/**
 * Context dropdown menu
 */
export const ContextMenu = ({ children }) => <MenuContextProvider>{children}</MenuContextProvider>;

ContextMenu.propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 * Provide context for the Menu component for opening/closing and element
 * position & sizing data.
 */
const MenuContextProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [positionData, setPositionData] = useState({
    button: {
      top: 0,
      left: 0,
      height: 0,
      width: 0,
    },
    menu: { width: 0, height: 0 },
  });

  const toggleMenu = useCallback(
    (openState = null) => {
      if (openState) {
        setIsOpen(openState);
      } else {
        setIsOpen((oldState) => !oldState);
      }
    },
    [setIsOpen]
  );

  const buttonRef = useRef(null);
  const menuRef = useRef(null);

  useEffect(() => {
    const listener = (event) => {
      // check if the button ref is valid
      if (buttonRef.current) {
        const { relatedTarget, target } = event;

        // if the menu is open and we have the ref
        if (isOpen && menuRef.current) {
          // check to see if the click target is outside the menu
          if (
            !menuRef.current.contains(relatedTarget || target) &&
            buttonRef.current !== target &&
            !buttonRef.current.contains(relatedTarget || target)
          ) {
            // close the menu
            setIsOpen(false);
          }
        }
      }
    };

    if (isOpen) {
      // create event listener
      window.addEventListener('mousedown', listener);

      // clean up listener after being set
      return () => {
        window.removeEventListener('mousedown', listener);
      };
    }

    return () => {};
  }, [isOpen, menuRef, buttonRef]);

  // calculate the size of the menu for positioning
  useLayoutEffect(() => {
    // only when open
    if (isOpen && menuRef.current && menuRef.current) {
      setPositionData({
        button: {
          top: buttonRef.current.offsetTop,
          left: buttonRef.current.offsetLeft,
          height: buttonRef.current.offsetHeight,
          width: buttonRef.current.offsetWidth,
        },
        menu: {
          width: menuRef.current.offsetWidth,
          height: menuRef.current.offsetHeight,
        },
      });
    }

    return () => {};
  }, [isOpen, menuRef]);

  return (
    <ContextMenuContext.Provider
      value={{
        isOpen,
        menuRef,
        buttonRef,
        positionData,
        toggleMenu,
      }}
    >
      {children}
    </ContextMenuContext.Provider>
  );
};

MenuContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

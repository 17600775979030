import React, { useEffect, useState } from 'react';
import { BarsIcon } from 'components/Icons/BarsIcon';
import { WidgetContent } from 'components/WidgetContent/WidgetContent';
import { WidgetHeader } from 'components/WidgetHeader/WidgetHeader';
import { WidgetItem } from 'components/WidgetItem/WidgetItem';
import { useHistory } from 'react-router-dom';
import OrganizationService from 'services/OrganizationService';
import { ModalOrganizationNew } from 'smartComponents/ModalOrganizationNew';
import { Button, Typography } from '@mui/material';
import { useStyles } from './styles';

export const OrganizationWidget = () => {
  const classes = useStyles();
  const history = useHistory();
  const [organizationsData, setOrganizationsData] = useState([]);
  const [open, setOpen] = useState(false);

  // Manage the Open of Add a New Organization
  const organizationModalToggle = (bool) => {
    setOpen(bool);
  };

  useEffect(() => {
    const getOrgsDetails = async () => {
      const responseOrg = await OrganizationService.getOrganizations({ page_size: 3 });
      setOrganizationsData(responseOrg.data.data);
    };
    getOrgsDetails();
  }, []);

  return (
    <div className={classes.generalContent}>
      <WidgetHeader
        title="Company Organization"
        subTitle="Divisions within your company."
        link
        actionIcon={BarsIcon}
        actionTitle="View All"
        actionClick={() => {
          history.push('/admin/organizations');
        }}
        border
      />

      <WidgetContent>
        {organizationsData.length === 0 ? (
          <div className={classes.textContainer}>
            <Typography variant="body">
              You have no organizations yet. <br />
              Why not{' '}
              <Button color="secondary" onClick={() => organizationModalToggle(true)}>
                create one now
              </Button>
              ?
            </Typography>
          </div>
        ) : (
          organizationsData.map((org) => (
            <div className={classes.orgSection} key={org.id}>
              <WidgetItem key={org.id} title={org.name} subHeader={org.description}>
                <Typography key={`typography-${org.id}`} variant="body1" mb={0.5} className="text">
                  {`${org.userCount} Members`}
                </Typography>
              </WidgetItem>
            </div>
          ))
        )}
      </WidgetContent>
      <ModalOrganizationNew isOpen={open} handleOpen={organizationModalToggle} />
    </div>
  );
};

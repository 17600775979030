import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SearchPanelBody, searchPanelSection } from 'pages/Projects/SearchPanel/SearchPanelBody';
import { dateRangeSelector, preloadFilterDatasets, setFilterValue, setFilters } from 'slices/adminProjectReducer';
import {
  externalDateRangeSelector,
  preloadExternalFilterDatasets,
  setExternalFilterValue,
  setExternalFilters,
} from 'slices/externalProjectReducer';
import { SidebarDetailContext } from 'components/SidebarProviderDetail/SidebarProviderDetail';

export const SearchPanel = () => {
  const dispatch = useDispatch();
  const ctx = useContext(SidebarDetailContext);
  const external = ctx?.config?.search?.external;
  const reduce = external ? 'externalProjects' : 'adminProjects';
  const nameSort = useSelector((state) => state[reduce].filters.sort_by.name || '');
  const dateSort = useSelector((state) => state[reduce].filters.sort_by.createdAt || '');
  const accountSort = useSelector((state) => state[reduce].filters.sort_by.account || '');
  const orgSort = useSelector((state) => state[reduce].filters.sort_by.org || '');
  const userId = useSelector((state) => state[reduce].filters.userId || '');
  const teamId = useSelector((state) => state[reduce].filters.teamId || '');
  const participantLevel = useSelector((state) => state[reduce].filters.participantLevel || '');
  const acctId = useSelector((state) => state[reduce].filters.acctId || '');
  const organizationId = useSelector((state) => state[reduce].filters.organizationId || '');
  const isOpen = useSelector((state) => state[reduce].filters.isOpen);
  const dateRange = useSelector(external ? externalDateRangeSelector : dateRangeSelector);
  const { users, teams, accounts, organizations, loaded } = useSelector((state) => state[reduce].panels.search);

  useEffect(() => {
    dispatch(external ? preloadExternalFilterDatasets() : preloadFilterDatasets());
  }, []);

  return (
    <SearchPanelBody
      loaded={loaded}
      users={users}
      teams={teams}
      accounts={accounts}
      organizations={organizations}
      filters={{
        sort_by: {
          name: nameSort,
          createdAt: dateSort,
          org: orgSort,
          account: accountSort,
        },
        acctId,
        organizationId,
        participantLevel,
        date_range: dateRange,
        userId,
        teamId,
        isOpen,
      }}
      setFilterValue={external ? setExternalFilterValue : setFilterValue}
      setFilters={external ? setExternalFilters : setFilters}
      section={external ? searchPanelSection.EXTERNALPROJECTS : searchPanelSection.ADMINPROJECTS}
    />
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import Auth from '../../lib/auth';
import { PermissionsValidator } from '../../lib/permValidator';

const AuthorizeRoute = ({ callback, children }) =>
  Auth.allowed({ callback }) ? children : <Redirect to={{ pathname: '/401' }} />;

AuthorizeRoute.propTypes = {
  children: PropTypes.node.isRequired,
  callback: PropTypes.func,
};

AuthorizeRoute.defaultProps = {
  callback: null,
};

export const PrivateRoute = ({ children, validate, scopes, ...rest }) => (
  <Route
    {...rest}
    render={({ location }) => {
      if (Auth.authenticate()) {
        if (validate) {
          return <AuthorizeRoute callback={validate}>{children}</AuthorizeRoute>;
        }
        if (scopes) {
          return <AuthorizeRoute callback={() => PermissionsValidator.validate({ scopes })}>{children}</AuthorizeRoute>;
        }
        return <AuthorizeRoute>{children}</AuthorizeRoute>;
      }
      return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
    }}
  />
);

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
  scopes: PropTypes.node,
  validate: PropTypes.func,
};

PrivateRoute.defaultProps = {
  validate: null,
  scopes: null,
};

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

const useStyles = makeStyles({
  layout: (props) => ({
    gridArea: props.area,
    overflowX: 'hidden',
    overflowY: props.allowScroll ? 'auto' : 'hidden',
    padding: props.padding ? theme.spacing(1) : '0',
    ...props.styles,
  }),
});

export const LayoutArea = ({ area, allowScroll, padding, children, styles }) => {
  const classes = useStyles({ area, allowScroll, padding, styles });

  return <div className={classes.layout}>{children}</div>;
};

LayoutArea.propTypes = {
  /**
   * The name of the grid-area that the element should appear in.
   */
  area: PropTypes.string.isRequired,

  /**
   * Should the element apply overflow-y: hidden or overflow-y: auto
   */
  allowScroll: PropTypes.bool,

  /**
   * Should the element apply default padding with theme.spacing(1)
   */
  padding: PropTypes.bool,

  /**
   * Component children
   */
  children: PropTypes.node,
  /**
   * extra styles
   */
  // eslint-disable-next-line react/require-default-props
  styles: PropTypes.object,
};

LayoutArea.defaultProps = {
  allowScroll: false,
  padding: false,
  children: null,
  styles: {},
};

import React, { useCallback, useEffect, useState } from 'react';
import { AccountSettingsContainer } from 'components/AccountSettingsContainer/AccountSettingsContainer';
import { AdminHeaderTitle } from 'components/AdminHeaderTitle/AdminHeaderTitle';
import { TabPanel } from 'components/TabPanel';
import { useParams, useLocation } from 'react-router-dom';
import AccountService from 'services/AccountService';
import OrganizationService from 'services/OrganizationService';
import ProjectService from 'services/ProjectService';
import TeamService from 'services/TeamService';
import UserService from 'services/UserService';
import { Permissions as Perms } from 'lib/permissions';
import { PermGuard } from 'components/Guards/PermGuard';
import { Box, Card, Tab, Tabs } from '@mui/material';
import { PermissionsPanel } from './Panels/PermissionsPanel';
import { useStyles } from './styles';

export const Permissions = () => {
  const classes = useStyles();
  const location = useLocation();
  const [value, setValue] = useState(0);
  const [userRoles, setUserRoles] = useState({
    accounts: [],
    organizations: [],
    projects: [],
    teams: [],
  });

  const { userId } = useParams();

  const fetchUserRoles = async () => {
    const result = await UserService.getUserRoles(userId);

    setUserRoles(result.data.data);
  };

  useEffect(() => {
    fetchUserRoles();
  }, []);

  /**
   *
   * @param {'accounts' | 'organizations' | 'projects' | 'teams'} type
   * @param {{ id: string }} entity
   * @param {Array<any>} roles
   */
  const updateRoles = useCallback(
    (type, entity, roles) => {
      setUserRoles((oldRoles) => {
        const newRoles = { ...oldRoles };
        newRoles[type] = newRoles[type].map((account) => {
          if (account.id === entity.id) {
            account.roles = roles;
          }

          return account;
        });

        return newRoles;
      });
    },
    [setUserRoles]
  );

  const handleAccountRoleEdit = async (entity, roles) => {
    const response = await AccountService.updateAccountUserRoles(userId, roles);

    updateRoles('accounts', entity, response.data.data);
  };

  const handleOrgRoleEdit = async (entity, roles) => {
    const response = await OrganizationService.updateUserRoles(entity.id, userId, roles);

    updateRoles('organizations', entity, response.data.data);
  };

  const handleProjectRoleEdit = async (entity, roles) => {
    const response = await ProjectService.updateUserOrTeamRoles(entity.id, userId, roles);

    updateRoles('projects', entity, response.data.data);
  };

  const handleTeamRoleEdit = async (entity, roles) => {
    const response = await TeamService.updateUserRoles(entity.id, userId, roles);

    updateRoles('teams', entity, response.data.data);
  };

  return (
    <AccountSettingsContainer
      title={
        <AdminHeaderTitle
          buttonName="ADMINISTRATION"
          section={location.pathname.includes('admins') ? 'ADMINISTRATORS' : 'USERS'}
          variant="userPermissions"
        />
      }
    >
      <Card className={classes.content}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value}>
            <Tab value={0} label="ALL" onClick={() => setValue(0)} />
            <PermGuard scopes={[Perms.ACCOUNT_USER_GRANT]}>
              <Tab value={1} label="Account Admins" onClick={() => setValue(1)} />
            </PermGuard>
            <Tab value={2} label="Organizations" onClick={() => setValue(2)} />
            <Tab value={3} label="Teams" onClick={() => setValue(3)} />
            <Tab value={4} label="Projects" onClick={() => setValue(4)} />
          </Tabs>
        </Box>

        <PermGuard scopes={[Perms.ACCOUNT_USER_GRANT]}>
          {userRoles.accounts.length !== 0 && (
            <TabPanel
              className={classes.panel}
              value={value}
              index={value === 0 ? 0 : 1}
              title="Account Administration Permissions"
            >
              <PermissionsPanel roles={userRoles.accounts} onEdit={handleAccountRoleEdit} roleType="account" />
            </TabPanel>
          )}
        </PermGuard>

        {userRoles.organizations.length !== 0 && (
          <TabPanel className={classes.panel} value={value} index={value === 0 ? 0 : 2} styles={{ minHeight: '200px' }}>
            <PermissionsPanel roles={userRoles.organizations} onEdit={handleOrgRoleEdit} roleType="org" />
          </TabPanel>
        )}

        {userRoles.projects.length !== 0 && (
          <TabPanel className={classes.panel} value={value} index={value === 0 ? 0 : 4}>
            <PermissionsPanel roles={userRoles.projects} onEdit={handleProjectRoleEdit} roleType="project" />
          </TabPanel>
        )}

        {userRoles.teams.length !== 0 && (
          <TabPanel className={classes.panel} value={value} index={value === 0 ? 0 : 3}>
            <PermissionsPanel roles={userRoles.teams} onEdit={handleTeamRoleEdit} roleType="team" />
          </TabPanel>
        )}
      </Card>
    </AccountSettingsContainer>
  );
};

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { OpenContext } from '../Nav/NavContext';
import { useStyles } from './styles';

export const NavItem = ({ active, icon: IconComponent, children, link, onClick }) => {
  const classes = useStyles({ active });
  const { open } = useContext(OpenContext);

  return (
    <li className={classes.item}>
      <Tooltip title={children} arrow placement="right">
        {onClick && active ? (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-is-valid
          <a className={classes.itemLink} onClick={onClick}>
            <span className={classes.styledIconWrap}>
              <IconComponent size={20} />
            </span>
            {open && children}
          </a>
        ) : (
          <Link to={link} className={classes.itemLink} open={open}>
            <span className={classes.styledIconWrap}>
              <IconComponent size={20} />
            </span>
            {open && children}
          </Link>
        )}
      </Tooltip>
    </li>
  );
};

NavItem.propTypes = {
  icon: PropTypes.any,
  children: PropTypes.string,
  link: PropTypes.string.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

NavItem.defaultProps = { icon: <div />, children: '', active: false, onClick: () => {} };

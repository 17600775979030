import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, IconButton, Typography } from '@mui/material';
import { cutUuidText } from 'lib/generalFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useGlobalStyles } from 'styles';
import { CheckCircleBroken } from 'components/Icons';

export const CopyUuidAlert = ({ id, handleClose }) => {
  const classes = useGlobalStyles();

  // ---------------- copy my uuid
  useEffect(() => {
    navigator.clipboard.writeText(id);
  }, []);

  return (
    <Dialog open onClose={handleClose} maxWidth="xs">
      <DialogContent className={classes.copyBox}>
        <div className={classes.copyIcons}>
          <CheckCircleBroken size={20} />
          <IconButton onClick={handleClose}>
            <FontAwesomeIcon icon={faClose} size="lg" />
          </IconButton>
        </div>
        <Typography className={classes.copyTitle}>UUID {cutUuidText(id)} Copied!</Typography>
        <Typography className={classes.copyText}>
          This UUID been copied to your clipboard. Please paste to send it to the appropriate party via your email.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

CopyUuidAlert.propTypes = {
  id: PropTypes.arrayOf(PropTypes.number),
  handleClose: PropTypes.func,
};

CopyUuidAlert.defaultProps = {
  id: null,
  handleClose: () => {},
};

import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, IconButton } from '@mui/material';
import { InfoCircleIcon } from 'components/Icons';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { ModalHeaderIcon } from 'components/ModalHeaderIcon/ModalHeaderIcon';

export const InfoDialog = () => {
  const [open, setOpen] = useState(false);

  const handleModal = () => {
    setOpen(!open);
  };
  return (
    <>
      <IconButton>
        <HelpOutlineOutlinedIcon onClick={handleModal} />
      </IconButton>
      <Dialog open={open} onClose={handleModal} maxWidth="xs" fullWidth>
        <DialogTitle>
          <ModalHeaderIcon icon={InfoCircleIcon} text="Map/GIS Layer Visibility" color="warning" />
        </DialogTitle>
        <DialogContent>
          <Typography variant="caption">
            These layers, which can be set at the Account, Org, Project and Asset levels, can be switched on or off.
            This setting will change the user’s ability to control what views are available to them in the maps.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={handleModal} fullWidth>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

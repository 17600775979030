import styled from 'styled-components';

export const Check = styled.div`
  display: flex;
  align-items: center;
  pointer-events: auto;
  cursor: pointer;
  margin-bottom: ${(props) => (props.marginNone ? '0' : props.theme.spacing(1))};
  padding-left: ${(props) => (props.paddingLeft ? props.theme.spacing(props.paddingLeft) : 0)};
  ${(props) => (props.disabled ? 'cursor: default;' : 'cursor: pointer;')};
  & > label {
    ${(props) => (props.disabled ? 'cursor: default;' : 'cursor: pointer;')};
  }
`;

export const InputContainer = styled.label`
  position: relative;
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin-right: 12px;
  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  div {
    position: relative;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border-radius: 10%;
    border: 2px solid
      ${(props) =>
        props.disabled ? props.theme.inputs.text.disabled.borderColor : props.theme.inputs.text.default.borderColor};
    span {
      position: absolute;
      top: 2px;
      left: 2px;
      right: 2px;
      bottom: 2px;
      border-radius: 10%;
    }
  }
  & input:checked ~ div > span {
    display: block;
    background-color: ${(props) =>
      props.disabled
        ? props.theme.inputs.checkBoxes.disabled.backgroundColor
        : props.theme.inputs.checkBoxes.checked.backgroundColor};
  }
`;

import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit';
import ProjectService from 'services/ProjectService';
import UserService from 'services/UserService';
import TeamService from 'services/TeamService';
import { formatDateRange, formatGeo, formatSortBy } from './helpers';

export const getFilteredProjectAssets = createAsyncThunk(
  'profileProjectAsset/getFilteredProjectAssets',
  async ({ filters, projId, idsToExport }) => {
    try {
      const response = await ProjectService.getSpecificAssets(filters, projId, idsToExport);
      return response.data;
    } catch (e) {
      throw new Error('Error during request for project content...');
    }
  }
);

const orderByName = (array) =>
  array.sort((a, b) => {
    const nameA = a.label.toUpperCase(); // ignore upper and lowercase
    const nameB = b.label.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

export const getProjectAssets = createAsyncThunk(
  'profileProjectAsset/getProjectAssets',
  async ({ projId, filters }) => {
    const sendFilters = {
      page: filters.page,
      strict: true,
      page_size: filters.page_size,
      include: ['uploader'],
      search: filters.search,
      ...formatGeo(filters.map_extent),
      ...formatSortBy(filters.sort_by),
      ...formatDateRange(filters.date_range),
      ...filters,
      ...(filters.pastId && { pastId: filters.pastId }),
    };
    sendFilters.contributorId = sendFilters.userId?.length
      ? sendFilters.userId.concat(sendFilters.teamId?.length ? sendFilters.teamId : [])
      : sendFilters.teamId;
    delete sendFilters.userId;
    delete sendFilters.teamId;
    delete sendFilters.date_range;
    delete sendFilters.activated;
    try {
      const response = await ProjectService.getProjectAssets(projId, sendFilters);
      /* const responseII = await ProjectService.getArcgisAssets();

      responseII?.data?.data?.map((item) => {
        item.type = 'ARCGIS';
        item.name = item.mapName;
        item.archived = false;
        item.hasError = false;
        item.isTranscodingComplete = true;
        return item;
      }); */

      // console.log(response.data);
      // console.log(responseII.data);

      // console.log(response.data.data.concat(responseII.data.data));

      // response.data.data = response.data.data.concat(responseII.data.data);

      return response.data;
    } catch (e) {
      throw new Error('Error during request for project content...');
    }
  }
);

export const getProjectAsset = createAsyncThunk(
  'profileProjectAsset/getProjectAsset',
  async ({ projId, assetId, lat, long }) => {
    try {
      const response = await ProjectService.getProjectAsset(projId, assetId);

      return {
        lat,
        long,
        data: response.data.data,
      };
    } catch (error) {
      throw new Error('Error during request for for a specific project content...');
    }
  }
);

export const deleteAsset = createAsyncThunk('profileProjectAsset/deleteAsset', async ({ projectId, assetId }) => {
  try {
    const deletedProjectAsset = await ProjectService.deleteProjectAsset(projectId, assetId);
    return {
      deletedProjectAsset: deletedProjectAsset.data,
    };
  } catch (e) {
    console.error(e);
  }
  return {};
});

export const ignorePerms = createAsyncThunk('profileProjectAsset/ignorePerms', async ({ projectId, assetId }) => {
  try {
    await ProjectService.ignorePerms(projectId, assetId);
    return {};
  } catch (e) {
    console.error(e);
  }
  return {};
});

export const viewArchive = createAsyncThunk(
  'profileProjectAsset/viewArchive',
  async ({ projectId, assetId, credits }) => {
    try {
      await ProjectService.viewArchive(projectId, assetId, credits);
      return {};
    } catch (e) {
      console.error(e);
    }
    return {};
  }
);

export const preloadFilterDatasets = createAsyncThunk(
  'profileProjectAsset/preloadFilterDatasets',
  async (arg, { getState }) => {
    try {
      const [users, teams, accounts] = await Promise.all([
        await UserService.getUsers({ page_size: 500 }),
        await TeamService.getTeams({ page_size: 500, userId: getState().auth.userId }).catch(() => ({
          data: { data: [] },
        })),
        await ProjectService.getProjectAssetAccounts(arg),
      ]);
      return {
        accounts: accounts?.data.data || [],
        organizations: [],
        users: users?.data.data || [],
        teams: teams?.data.data || [],
      };
    } catch (err) {
      throw new Error('error loading filter data');
    }
  },
  {
    condition: (_, { getState }) => getState().profileProjectAssets.panels.search.loaded === false,
  }
);

export const UPLOAD_APPEND_FILE = 0;
export const UPLOAD_DELETE_FILE = 1;
export const UPLOAD_UPDATE_PERCENT = 2;

const defaultFilter = {
  page: 1,
  page_size: 10,
  sort_by: {},
  search: '',
  ownership: '',
  map_extent: [],
};

export const projectAssetSection = {
  OVERVIEW: 'overview',
  OBSERVATIONS: 'observations',
  SERIES: 'series',
  SHARING: 'sharing',
  INSPECTIONS: 'inspections',
  ADMIN: 'admin',
};

const initialState = {
  error: null,
  loading: true,
  refetchLoading: false,
  mapExtentApplied: false,
  data: [],
  totalCount: 0,
  panels: {
    loading: true,
    detail: {},
    projUsers: [],
    projTeams: [],
    search: {
      loaded: false,
      projects: [],
      accounts: [],
      organizations: [],
      users: [],
      teams: [],
    },
  },
  projectAsset: {
    id: null,
  },
  projectCoordinates: {
    lat: 0,
    long: 0,
  },
  isAssetLoading: false,
  player: {
    selectedSequence: 0,
    observationAssetTime: null,
    ovservationDialogOpen: false,
  },
  filters: defaultFilter,
  uploadQueue: [],
  assetSharing: [],
  projectAssetSection: projectAssetSection.OVERVIEW,
};

const projectSlice = createSlice({
  name: 'profileProjectAsset',
  initialState,
  reducers: {
    setProjectAsset(state, action) {
      state.projectAsset = action.payload;
    },
    setAssetSharing(state, { payload }) {
      if (payload?.action === 'add') {
        const { assetSharing } = state;
        assetSharing.unshift(payload.data);
        state.assetSharing = assetSharing;
      } else if (payload?.action === 'delete') {
        const { assetSharing } = state;
        const index = assetSharing.findIndex((element) => element.id === payload.data.id);
        assetSharing.splice(index, 1);
        state.observations = assetSharing;
      } else {
        state.assetSharing = payload;
      }
    },
    setFilter(state, action) {
      state.filters = action.payload ? { ...defaultFilter, ...action.payload } : defaultFilter;
    },
    setPageFilter(state, action) {
      state.filters.page = action.payload;
    },
    setPageSize(state, action) {
      state.totalCount = action.payload;
    },
    setSearch(state, action) {
      state.filters.search = action.payload;
      state.filters.page = 1;
    },
    setSortBy(state, { payload }) {
      const { label, value } = payload;

      if (value === '' || value === null) {
        delete state.filters.sort_by[label];
      } else {
        state.filters.sort_by[label] = value;
      }
    },
    setMapExtent(state, { payload }) {
      state.filters.map_extent = payload;
    },
    setAssetsData(state, { payload }) {
      state.data = payload;
    },
    setLoading(state, { payload }) {
      state.loading = payload;
      state.filters.page = 1;
    },
    addPointCoordinates(state, { payload }) {
      state.projectCoordinates.long = payload.long;
      state.projectCoordinates.lat = payload.lat;
    },
    setRefetchLoading(state, { payload }) {
      state.refetchLoading = payload;
    },
    updateUploadQueue(state, { payload }) {
      switch (payload.type) {
        case UPLOAD_APPEND_FILE:
          return {
            ...state,
            uploadQueue: [...state.uploadQueue, payload.file],
          };
        case UPLOAD_DELETE_FILE: {
          if (window.uploadQueueXHR[payload.id]) {
            window.uploadQueueXHR[payload.id].abort();
            delete window.uploadQueueXHR[payload.id];
          }

          deleteAsset(payload.projId, payload.id);

          return {
            ...state,
            uploadQueue: state.uploadQueue.filter((f) => f.id !== payload.id),
          };
        }
        case UPLOAD_UPDATE_PERCENT: {
          // serialize the array then un-serialize it to make sure
          // its a new copy and not points to the same objects
          const newQueue = JSON.parse(JSON.stringify(state.uploadQueue));
          const index = newQueue.findIndex((f) => f.id === payload.id);
          newQueue[index].uploadPercentComplete = payload.uploadPercentComplete;
          return { ...state, uploadQueue: newQueue };
        }
        default:
          return state;
      }
    },

    setFilterValue(state, { payload }) {
      const { param, value } = payload;
      if ((value === '' || value === null) && param !== 'date_range') {
        delete state.filters[param];
        return;
      }
      state.filters[param] = value;
    },
    setDateRange(state, { payload }) {
      state.filters.date_range = payload;
    },
    setFilters(state, action) {
      const filter = { ...state.filters, ...action.payload, page: 1 };
      if (!filter?.participantLevel) delete filter?.participantLevel;
      state.filters = filter;
    },

    setSelectedSequence(state, { payload }) {
      state.player.selectedSequence = payload;
    },
    setObservationTime(state, { payload }) {
      state.player.observationAssetTime = payload;
      state.player.ovservationDialogOpen = true;
    },
    cancelObservationPoint(state) {
      state.player.observationAssetTime = null;
      state.player.ovservationDialogOpen = false;
    },
    confirmObservationPoint(state, { payload }) {
      state.projectAsset.sequences[state.player.selectedSequence].observations.push(payload);
      state.player.observationAssetTime = null;
      state.player.ovservationDialogOpen = false;
    },
    viewObservationPoint(state, { payload }) {
      state.player.viewObservationPointId = payload;
      state.player.viewObservationPointDialogOpen = true;
    },
    cancelViewObservationPoint(state) {
      state.player.viewObservationPointId = null;
      state.player.viewObservationPointDialogOpen = false;
    },
    clearProjectAsset(state) {
      state.projectAsset = { id: null, sequences: [] };
    },
    setMapFilterApplied(state) {
      state.mapExtentApplied = true;
    },
    unsetMapFilterApplied(state) {
      state.mapExtentApplied = false;
    },
    setUploadQueue(state, action) {
      state.uploadQueue = action.payload;
    },
    setProjAssetSection(state, action) {
      state.projectAssetSection = action.payload;
    },
    setProjAssetFindings(state, action) {
      const { projectAsset } = state;
      projectAsset.findings = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProjectAssets.pending, (state) => {
      state.refetchLoading = true;
    });
    builder.addCase(getFilteredProjectAssets.pending, (state) => {
      state.refetchLoading = true;
    });
    builder.addCase(getProjectAssets.fulfilled, (state, action) => {
      const auxData = [...action.payload.data];
      auxData.forEach((element) => {
        element.status = 'd';
        if (element.hasError) {
          element.status = 'a';
        } else if (!element.uploadComplete) {
          element.status = 'b';
        } else if (!element.isTranscodingComplete) {
          element.status = 'c';
        }
      });
      state.data = auxData;
      state.totalCount = action.payload.meta.totalCount;
      state.error = null;
      state.loading = false;
      state.refetchLoading = false;
    });
    builder.addCase(getFilteredProjectAssets.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.totalCount = action.payload.meta.totalCount;
      state.error = null;
      state.loading = false;
      state.refetchLoading = false;
    });
    builder.addCase(getProjectAssets.rejected, (state) => {
      state.data = [];
      state.error = 'There was an error during the request.';
      state.loading = false;
      state.refetchLoading = false;
      state.totalCount = 0;
    });
    builder.addCase(getFilteredProjectAssets.rejected, (state) => {
      state.data = [];
      state.error = 'There was an error during the request.';
      state.loading = false;
      state.refetchLoading = false;
      state.totalCount = 0;
    });
    builder.addCase(getProjectAsset.pending, (state) => {
      state.isAssetLoading = true;
      state.projectAsset = {};
    });
    builder.addCase(getProjectAsset.fulfilled, (state, action) => {
      state.projectAsset = action.payload.data;
      state.projectCoordinates.lat = action.payload.lat;
      state.projectCoordinates.long = action.payload.long;
      state.error = null;
      state.isAssetLoading = false;
    });
    builder.addCase(getProjectAsset.rejected, (state) => {
      state.data = [];
      state.projectAsset = {};
      state.isAssetLoading = false;
    });
    builder.addCase(deleteAsset.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteAsset.fulfilled, (state) => {
      state.error = null;
      state.loading = false;
    });
    builder.addCase(deleteAsset.rejected, (state) => {
      state.error = 'Error deleting asset';
      state.loading = false;
    });
    builder.addCase(preloadFilterDatasets.pending, (state) => {
      state.panels.search.loaded = false;
    });
    builder.addCase(preloadFilterDatasets.rejected, (state) => {
      state.panels.search.users = [];
      state.panels.search.teams = [];
      state.panels.search.projects = [];
      state.panels.search.accounts = [];
      state.panels.search.organizations = [];
      state.panels.search.loaded = false;
    });
    builder.addCase(preloadFilterDatasets.fulfilled, (state, { payload }) => {
      const { accounts, organizations, users, teams } = payload;
      state.panels.search.loaded = true;
      let newAccounts = accounts.map((account) => ({
        label: account.name,
        value: account.id,
      }));
      let newOrganizations = organizations.map((proj) => ({
        label: proj.name,
        value: proj.id,
      }));
      newAccounts = orderByName(newAccounts);
      newOrganizations = orderByName(newOrganizations);
      state.panels.search.accounts = newAccounts;
      state.panels.search.organizations = newOrganizations;
      const newTeams = teams.map((team) => ({
        label: team.name,
        value: team.id,
      }));
      state.panels.search.teams = newTeams;
      const newUsers = users.map((user) => ({
        label: `${user.firstName} ${user.lastName}`,
        value: user.id,
      }));
      state.panels.search.users = newUsers;
    });
  },
});

export const {
  setProjectAsset,
  updateUploadQueue,
  setPageFilter,
  setPageSize,
  setSortBy,
  setSearch,
  setFilterValue,
  setSelectedSequence,
  setObservationTime,
  cancelObservationPoint,
  confirmObservationPoint,
  viewObservationPoint,
  cancelViewObservationPoint,
  setMapExtent,
  setAssetsData,
  addPointCoordinates,
  clearProjectAsset,
  setMapFilterApplied,
  unsetMapFilterApplied,
  setFilter,
  setUploadQueue,
  setAssetSharing,
  setLoading,
  setFilters,
  setRefetchLoading,
  setProjAssetSection,
  setProjAssetFindings,
} = projectSlice.actions;

export const dateRangeSelector = createSelector(
  (state) => state.profileProjectAssets.filters,
  (state) => {
    const dates = state.date_range;

    if (!dates) {
      return dates;
    }

    return dates.map((date) => (date ? new Date(date) : null));
  }
);

export default projectSlice.reducer;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useGlobalStyles } from 'styles';
import { SidebarPanel } from 'components/SidebarPanel';
import headerImg from 'images/userDetail.png';
import { Button, Tab, Tabs, Typography } from '@mui/material';
import { TabPanel } from 'components/TabPanel';
import { getInitials, getNameRole } from 'lib/generalFunctions';
import { Avatar } from 'components/Avatar';
import { InfoPanelBodyHeader } from 'components/InfoPanelBodyHeader/InfoPanelBodyHeader';
import { UuidButton } from 'components/UuidButton/UuidButton';
import { useHistory } from 'react-router-dom';
import { DialogProjectInvite, typeInviteData } from 'components/DialogProjectInvite';
import { ViewRoleValidation } from 'components/ViewRoleValidation/ViewRoleValidation';
import { Permissions } from 'lib/permissions';

export const InfoPanelBody = ({ loading, detail, users, projects }) => {
  const history = useHistory();
  const [value, setValue] = useState(0);
  const globalClasses = useGlobalStyles();
  const [teamAction, setTeamAction] = useState(null);
  const [validateRole, setValidateRole] = useState({ open: false, callBack: () => {} });

  const navigateToPermissions = () => {
    history.push(`/teams/${detail.id}`);
  };

  return (
    <SidebarPanel title={detail?.org?.id && !loading ? detail.org.name : 'Team Detail'} loading={loading}>
      <InfoPanelBodyHeader image={headerImg} name={getInitials(detail.name)} />
      <div className={globalClasses.infoPanelBody}>
        <Typography mb={1} variant="h1" className={globalClasses.infoPanelName}>
          {detail.name}
        </Typography>
        <UuidButton data={detail} permissions={[Permissions.TEAM_MODIFY]} />
        <>
          <Button
            color="primary"
            variant="contained"
            size="large"
            sx={{ marginTop: '2rem' }}
            className={globalClasses.openDetail}
            onClick={() => navigateToPermissions()}
          >
            Open
          </Button>
          <Button
            color="secondary"
            variant="contained"
            size="large"
            sx={{ marginTop: '2rem', display: 'inline-block', marginLeft: '1rem' }}
            className={globalClasses.openDetail}
            onClick={() =>
              setValidateRole({
                open: true,
                action: [Permissions.TEAM_MODIFY, Permissions.TEAM_USER_REMOVE, Permissions.TEAM_USER_GRANT],
                callBack: () => setTeamAction(true),
              })
            }
          >
            Add to Project
          </Button>
        </>
        <Typography mt={1} className={globalClasses.infoPanelAboutText}>
          About
        </Typography>
        <Typography mb={1} variant="body2" className={globalClasses.infoPanelDescription}>
          {detail.description}
        </Typography>
        <Tabs value={value} className={globalClasses.infoPanelTabs} sx={{ width: '100%' }}>
          <Tab label="Members" onClick={() => setValue(0)} sx={{ width: '50%' }} />
          <Tab label="Projects" onClick={() => setValue(1)} sx={{ width: '50%' }} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <div className={globalClasses.infoTabPanel}>
            {users.map((user) => (
              <div className={globalClasses.nameContainer} style={{ margin: '10px' }}>
                <Avatar key={user.id} initials={getInitials(`${user.firstName} ${user.lastName}`)} size={48} />
                <div>
                  <Typography>{`${user.firstName} ${user.lastName}`}</Typography>
                  <Typography className={globalClasses.greyTypography}>{getNameRole([...user.roles])}</Typography>
                </div>
              </div>
            ))}
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className={globalClasses.infoTabPanel}>
            {projects.map((project) => (
              <div className={globalClasses.nameContainer} style={{ margin: '10px' }}>
                <Avatar key={project.id} initials={getInitials(project.name)} size={48} />
                <div>
                  <Typography>{project.name}</Typography>
                </div>
              </div>
            ))}
          </div>
        </TabPanel>
      </div>
      {teamAction && (
        <DialogProjectInvite
          isOpen
          handleClose={() => setTeamAction(false)}
          objectId={detail.id}
          itemName={detail.name}
          type={typeInviteData.PROJET}
          textDescription="Team"
        />
      )}
      {validateRole.open && (
        <ViewRoleValidation
          action={validateRole.action}
          data={detail}
          callBack={validateRole.callBack}
          handleClose={() => setValidateRole({ open: false, callBack: () => {} })}
        />
      )}
    </SidebarPanel>
  );
};

InfoPanelBody.propTypes = {
  loading: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  detail: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  users: PropTypes.array,
  projects: PropTypes.array,
};

InfoPanelBody.defaultProps = {
  loading: false,
  detail: { id: null, name: '' },
  users: [],
  projects: [],
};

import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const MagGlassIcon = ({ size, className }) => (
  <Svg viewBox="0 0 24 24" size={size} className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.5 21.018l-5.97-6.015a.667.667 0 01-.074-.84c2.448-3.754 1.877-8.918-1.734-12.002-3.476-2.967-8.766-2.866-12.133.225-3.865 3.548-3.962 9.587-.291 13.26 3.13 3.134 7.976 3.514 11.534 1.16a.664.664 0 01.843.074l5.957 6.002a.66.66 0 00.935.002l.932-.933a.66.66 0 00.001-.933zM5.162 13.78a6.55 6.55 0 010-9.248 6.537 6.537 0 019.24 0 6.55 6.55 0 010 9.248 6.538 6.538 0 01-9.24 0z"
      fill="currentColor"
    />
  </Svg>
);

MagGlassIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
};

MagGlassIcon.defaultProps = {
  size: 24,
  className: '',
};

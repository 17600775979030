import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ProjectService from 'services/ProjectService';
import UserAssets from 'services/UserAssets';

export const getProjectContent = createAsyncThunk('ProjectContent/getProjectContent', async (filter) => {
  const filtersOpts = {
    ...filter,
    include: ['uploader', 'project'],
  };
  delete filtersOpts.projId;

  try {
    const [content, project] = await Promise.all([
      await ProjectService.getProjectAssets(filter.projId, filtersOpts),
      await ProjectService.getProject(filter.projId),
    ]);
    return {
      content,
      project,
    };
  } catch (e) {
    throw new Error('Error during request for content...');
  }
});

export const getSingleContent = createAsyncThunk('ProjectContent/getSingleContent', async ({ projId, id, rowData }) => {
  const [details] = await Promise.all([await UserAssets.getSingleAsset(projId, id)]);

  return {
    details,
    rowData,
  };
});

const initialState = {
  error: null,
  loading: true,
  data: [],
  projectName: [],
  panels: {
    loading: true,
    details: [],
    uploader: '',
  },
  filters: {
    page: 1,
    page_size: 10,
  },
};

const ProjectContentSlice = createSlice({
  name: 'ProjectContent',
  initialState,
  reducers: {
    resetProjectContents: () => initialState,
    setPageFilter(state, action) {
      state.filters.page = action.payload;
    },
    setSearch(state, action) {
      state.filters.search = action.payload;
      state.filters.page = 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProjectContent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProjectContent.fulfilled, (state, { payload }) => {
      state.data = payload.content.data.data;
      state.projectName = payload.project.data.data.name;
      state.assetCount = payload.content.data.meta.totalCount;
      state.error = null;
      state.loading = false;
    });
    builder.addCase(getProjectContent.rejected, (state) => {
      state.data = [];
      state.project = [];
      state.error = 'There was an error during the request.';
      state.loading = false;
    });
    builder.addCase(getSingleContent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSingleContent.fulfilled, (state, { payload }) => {
      state.panels.details = payload.details.data.data;
      state.panels.uploader = payload.rowData.uploaders;
      state.panels.projectShared = payload.rowData.projectSharedWith;
      state.error = null;
      state.loading = false;
    });
    builder.addCase(getSingleContent.rejected, (state) => {
      state.panels.details = [];
      state.panels.uploader = [];
      state.error = 'There was an error during the request.';
      state.loading = false;
    });
  },
});

export const { setPageFilter, contentActions, setSearch, resetProjectContents } = ProjectContentSlice.actions;

export default ProjectContentSlice.reducer;

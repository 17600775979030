import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const ClockIcon = ({ size, className }) => (
  <Svg viewBox="0 0 24 24" size={size} className={className}>
    <path
      d="M12.62 5.972v6.31l5.408 3.211-.901 1.521-6.31-3.83V5.971h1.803ZM5.183 18.817C7.1 20.695 9.371 21.634 12 21.634c2.63 0 4.883-.94 6.76-2.817 1.916-1.916 2.874-4.188 2.874-6.817 0-2.63-.958-4.883-2.873-6.76C16.883 3.323 14.629 2.365 12 2.365c-2.63 0-4.901.958-6.817 2.873C3.305 7.117 2.366 9.371 2.366 12c0 2.63.94 4.901 2.817 6.817ZM3.493 3.549C5.859 1.183 8.695 0 12 0c3.305 0 6.122 1.183 8.45 3.55C22.818 5.877 24 8.694 24 12c0 3.305-1.183 6.14-3.55 8.507C18.123 22.836 15.306 24 12 24c-3.305 0-6.14-1.164-8.507-3.493C1.164 18.141 0 15.305 0 12c0-3.305 1.164-6.122 3.493-8.45Z"
      fill="currentColor"
    />
  </Svg>
);

ClockIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
};

ClockIcon.defaultProps = {
  size: 24,
  className: '',
};

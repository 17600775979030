import React, { useState } from 'react';
import { WidgetItem } from 'components/WidgetItem/WidgetItem';
import { Table } from 'components/Table';
import { CopyIcon } from 'components/Icons';
import { CopyRole } from 'components/SSO/CopyRole/CopyRole';
import { useTable } from 'react-table';
import EnumService from 'services/EnumService';
import AccountService from 'services/AccountService';
import OrganizationService from 'services/OrganizationService';
import ProjectService from 'services/ProjectService';
import TeamService from 'services/TeamService';
import {
  Autocomplete,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { useStyles } from './styles';

const roleFetchFuncs = {
  account: () => EnumService.getAccountRoles(),
  org: () => EnumService.getOrgRoles(),
  project: () => EnumService.getProjectRoles(),
  team: () => EnumService.getTeamRoles(),
};

const dataFetchFuncs = {
  account: () => AccountService.getAccount(),
  org: () => OrganizationService.getOrganizations({ page_size: 500 }),
  project: () => ProjectService.getProjects(),
  team: () => TeamService.getTeams({ page_size: 500 }),
};

const rootOptions = [
  {
    label: 'Account',
    value: 'account',
  },
  {
    label: 'Organization',
    value: 'org',
  },
  {
    label: 'Project',
    value: 'project',
  },
  {
    label: 'Team',
    value: 'team',
  },
];

const tableColumns = () => [
  {
    Header: 'Role',
  },
  {
    Header: 'Value',
  },
  {
    Header: 'Copy',
  },
];

export const AppRoleWidget = () => {
  const classes = useStyles();
  const [roles, setRoles] = useState([]);
  const [data, setData] = useState([]);
  const [selectedId, setSelectedId] = useState(false);
  const [valueCopy, setValueCopy] = useState(false);
  const [entityName, setEntityName] = useState('');

  const columns = React.useMemo(() => tableColumns(), []);
  const table = useTable({
    columns,
    data,
    totalCount: data.length,
    pageLength: data.length,
  });
  const handleEntitySelected = (val) => {
    dataFetchFuncs[val.value]()
      .then((result) => {
        const newData =
          result.data.data instanceof Array
            ? result.data.data.map((item) => ({ label: item.name, value: item.id }))
            : [result.data.data].map((item) => ({ label: item.name, value: item.id }));

        setEntityName(val.label);
        setData([]);
        setData(newData);
        if (newData.length > 1 || newData.length < 1) {
          setSelectedId(false);
        } else {
          setSelectedId(newData[0].value);
        }
        return roleFetchFuncs[val.value]();
      })
      .then((roleResult) => {
        setRoles(roleResult.data.data);
      });
  };

  return (
    <Grid item lg={8} md={8} sm={12} xs={12} className={classes.secondSection}>
      <div className={classes.headerSearch}>
        <Grid container spacing={1}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <InputLabel>Role for:</InputLabel>
            <FormControl fullWidth>
              <Autocomplete
                disableClearable
                placeholder=""
                options={rootOptions}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => <TextField {...params} placeholder="Select" />}
                onChange={(e, value) => {
                  handleEntitySelected(value);
                }}
                size="small"
              />
            </FormControl>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12} className={classes.columnHeader}>
            {data.length < 1 && <WidgetItem title={`No ${entityName.toLocaleLowerCase()}s found`} subHeader="" />}
            {data.length > 0 && data.length < 2 && <WidgetItem title={data[0].label} subHeader="" />}
            {data.length > 0 && data.length > 1 && (
              <FormControl fullWidth>
                <Autocomplete
                  options={data}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => <TextField {...params} placeholder="Select" />}
                  onChange={(e, value) => {
                    setSelectedId(value?.value ? value?.value : false);
                  }}
                  size="small"
                />
              </FormControl>
            )}
          </Grid>
        </Grid>
      </div>

      {selectedId && roles.length > 0 && (
        <Table table={table}>
          <TableHead>
            {table.headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell>{column.render('Header')}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...table.getTableBodyProps()}>
            {roles.map((role) => (
              <TableRow>
                <TableCell>{role.name}</TableCell>
                <TableCell>{`${selectedId}/${role.code}`}</TableCell>
                <TableCell>
                  <IconButton color="secondary" title="Save" onClick={() => setValueCopy(`${selectedId}/${role.code}`)}>
                    <CopyIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {valueCopy && <CopyRole value={valueCopy} handleClose={() => setValueCopy(false)} />}
    </Grid>
  );
};

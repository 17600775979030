import PropTypes from 'prop-types';
import React from 'react';
import { SidebarPanelButton, StyledButtonText } from './styles';

export const AdminPanelButton = ({ icon: IconComponent, onClick, children }) => {
  const hasIcon = !!IconComponent;

  return (
    <SidebarPanelButton onClick={onClick}>
      {!!hasIcon && <IconComponent size={16} />}
      <StyledButtonText variant="p" as="p" hasIcon={hasIcon}>
        {children}
      </StyledButtonText>
    </SidebarPanelButton>
  );
};

AdminPanelButton.propTypes = {
  icon: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.string,
};

AdminPanelButton.defaultProps = {
  icon: null,
  children: '',
};

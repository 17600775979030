import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ProjectService from 'services/ProjectService';
import TeamService from 'services/TeamService';
import UserService from 'services/UserService';

export const fetchMembers = createAsyncThunk('team-members/fetchMembers', async (filters) => {
  const filtersOpts = {
    ...filters,
    include: ['orgs'],
  };
  delete filtersOpts.activated;
  if (!filtersOpts.userTeamRole) delete filtersOpts.userTeamRole;
  if (!filtersOpts.organizationId) delete filtersOpts.organizationId;
  if (!filtersOpts.projectId) delete filtersOpts.projectId;
  if (!filtersOpts.acctId) delete filtersOpts.acctId;
  const response = await UserService.getUsers(filtersOpts);
  return response.data;
});

export const deleteMembers = createAsyncThunk('team-members/deleteMembers', async ({ teamId, memberId, roles }) => {
  await TeamService.updateUserRoles(teamId, memberId, roles);
});

export const addMember = createAsyncThunk('team-members/addMember', async ({ teamId, userId }) => {
  await TeamService.addTeamMember(teamId, userId);
});

export const fetchMember = createAsyncThunk('team-members/fetchMember', async (userId) => {
  try {
    const [user, teams, projects] = await Promise.all([
      await UserService.getUser(userId),
      await TeamService.getTeams({ userId }),
      await ProjectService.getProjects({ userId }),
    ]);

    return {
      user: user.data,
      teams: teams.data,
      projects: projects.data,
    };
    // TODO: Parse the JWT, save to localStorage, and redux.
  } catch (e) {
    throw new Error('Error during request for users...');
    // TODO: handle err
  }
});

const initialState = {
  members: [],
  membersCount: 0,
  loading: true,
  error: null,
  panels: {
    teams: [],
    projects: [],
    loading: false,
    detail: {},
  },
  filters: {
    page: 1,
    page_size: 10,
    sort_by: {},
    userTeamRole: null,
    search: '',
  },
};

const teamMembersReducer = createSlice({
  name: 'teams-members',
  initialState,
  reducers: {
    resetTeamsMembers: () => initialState,
    setPageFilter(state, action) {
      state.filters.page = action.payload;
    },
    setSearch(state, action) {
      state.filters.search = action.payload;
      state.filters.page = 1;
    },
    setSortBy(state, { payload }) {
      const { label, value } = payload;
      if (value === '' || value === null) {
        delete state.filters.sort_by[label];
      } else {
        state.filters.sort_by[label] = value;
      }
    },
    setParticipantLevel(state, { payload }) {
      const { value } = payload;
      if (value === '' || value === null) {
        delete state.filters.userRole;
      } else {
        state.filters.userRole = value;
      }
    },
    setFilters(state, action) {
      const filter = { ...state.filters, ...action.payload, page: 1 };
      if (!filter?.participantLevel) delete filter?.participantLevel;
      state.filters = filter;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchMembers.fulfilled, (state, { payload }) => {
      state.members = payload.data;
      state.membersCount = payload.meta.totalCount;
      state.loading = false;
    });

    builder.addCase(fetchMembers.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchMembers.rejected, (state) => {
      state.loading = false;
      state.members = [];
      state.error = 'unable to load Members';
    });

    builder.addCase(fetchMember.pending, (state) => {
      state.panels.loading = true;
    });

    builder.addCase(fetchMember.fulfilled, (state, { payload }) => {
      state.panels.detail = payload.user.data;
      state.panels.teams = payload.teams.data;
      state.panels.projects = payload.projects.data;
      state.panels.loading = false;
    });

    builder.addCase(fetchMember.rejected, (state) => {
      state.panels.detail = {};
      state.panels.error = 'unable to load user data';
      state.panels.roles = {};
      state.panels.loading = false;
    });
  },
});

export const {
  setPageFilter,
  setSortBy,
  setSearch,
  setParticipantLevel,
  resetTeamsMembers,
  setFilters,
  // eslint-disable-next-line prettier/prettier
} = teamMembersReducer.actions;

export default teamMembersReducer.reducer;

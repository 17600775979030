import { FormInput } from 'components/FormInput';
import { FormTextArea } from 'components/FormTextArea';
// import { FormSelect } from 'components/FormSelect/FormSelect';
import { Form } from 'lib/form';
import {
  Autocomplete,
  DialogContent,
  FormControl,
  FormHelperText,
  TextField,
  Grid,
  InputLabel,
  Typography,
} from '@mui/material';

import PropTypes from 'prop-types';
import React from 'react';

export const GisServices = ({ loading, formik }) => {
  const options = {
    kml: { value: 'kml', label: 'Keyhole Markup Language (KML)' },
    // wcs: { value: 'wcs', label: 'Web Coverage Service (WCS)' },
    wms: { value: 'wms', label: 'Web Map Service (WMS)' },
    wfs: { value: 'wfs', label: 'Web Feature Service (WFS)' },
    // wmts: { value: 'wmts', label: 'Web Map Tile Service (WMTS)' },
    // wps: { value: 'wps', label: 'Web Processing Service (WPS)' },
  };

  console.info('socrates_log', formik);

  return (
    <form>
      <DialogContent style={{ minWidth: 'xs', maxWidth: 'xs', marginRight: '-20px' }}>
        <Grid container>
          <Grid item xs={12} style={{ marginTop: '-15px' }}>
            <InputLabel>
              <Typography mt={0.5}>Name</Typography>
            </InputLabel>
            <FormInput
              placeholder="Name"
              name="name"
              value={formik?.values?.name}
              id="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              {...Form.fieldErrorHelper(formik, 'name')}
              disabled={loading}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: '-15px' }}>
            <InputLabel>
              <Typography mt={0.5}>GIS Service URL (root)</Typography>
            </InputLabel>
            <FormTextArea
              label="GIS Service URL (root)"
              placeholder="The URL of the service you're adding."
              name="url"
              value={formik?.values?.url}
              id="url"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              {...Form.fieldErrorHelper(formik, 'url')}
              fullWidth
              disabled={loading}
              required
              multiline
              rows={3}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: '5px' }}>
            <InputLabel>
              <Typography mt={0.5}>Description</Typography>
            </InputLabel>
            <FormInput
              placeholder="Description"
              name="desc"
              value={formik?.values?.desc}
              id="desc"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              {...Form.fieldErrorHelper(formik, 'desc')}
              disabled={loading}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={12} style={{ marginTop: '-15px' }}>
            <InputLabel>
              <Typography mt={0.5}>Service Type</Typography>
            </InputLabel>
            <FormControl error={formik.touched.service && formik.errors.service} fullWidth>
              <Autocomplete
                options={Object.values(options)}
                name="service"
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onBlur={formik.handleBlur}
                    required
                    placeholder="Select Service"
                    error={!!(formik.touched.service && formik.errors.service)}
                  />
                )}
                value={options[formik.values.service]}
                onChange={(e, value) => {
                  const event = { target: { name: 'service', value: value?.value ? value?.value : '' } };
                  formik.handleChange(event);
                }}
                // onBlur={handleBlur}
              />
              <FormHelperText>
                {formik.touched.service && formik.errors.service ? formik.errors.service : null}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} style={{ marginTop: '5px' }}>
            <InputLabel>
              <Typography mt={0.5}>ArcGIS Map ID</Typography>
            </InputLabel>
            <FormInput
              placeholder="ArcGIS Map ID"
              name="webMapId"
              value={formik?.values?.webMapId}
              id="webMapId"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              {...Form.fieldErrorHelper(formik, 'webMapId')}
              disabled={loading}
              required
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
    </form>
  );
};

GisServices.propTypes = {
  loading: PropTypes.bool.isRequired,
  formik: PropTypes.shape({
    values: PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
      desc: PropTypes.string,
      service: PropTypes.string,
    }),
    errors: PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
      desc: PropTypes.string,
      service: PropTypes.string,
    }),
    touched: PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
      desc: PropTypes.string,
      service: PropTypes.string,
    }),
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
  }).isRequired,
};

import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  modalCalendar: {
    width: 'min-content',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderRadius: '8px',
  },
  modalCalendarHeader: {
    backgroundColor: 'transparent',
  },
  modalCalendarContent: {
    backgroundColor: theme.palette.primary[1300],
    border: `1px solid${theme.palette.primary[300]}`,
    borderRadius: 8,
    padding: 0,
  },
  modalCalendarsContent: {
    padding: `${theme.spacing(1)} ${theme.spacing(1)} 0 ${theme.spacing(1)}`,
  },
  modalCalendarLeft: {
    borderRight: `1px solid${theme.palette.primary[300]}`,
  },
  modalCalendarActions: {
    // borderTop: `1px solid${theme.palette.primary[300]}`,
    padding: theme.spacing(1),
  },
  modalCalendarButtons: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 10,
    justifyContent: 'end',
    '& button': {
      minWidth: '109px',
    },
  },
  modalCalendarInputs: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 10,
    alignItems: 'center',
  },
  calendarInput: {
    maxWidth: '45%',
    display: 'inline-block',
  },
});

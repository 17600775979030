import ApiService from './ApiService';

export default class GeometryService {
  /**
   *
   * @param {{
   *  xmin: number;
   *  xmax: number;
   *  ymin: number;
   *  ymax: number;
   *  includeAcct?: boolean;
   *  orgId?: string;
   * }} filters
   * @returns
   */
  static getGeometryByRect = async (filters) => ApiService.get(`/geometry/rect`, filters);
}

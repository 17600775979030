import PropTypes from 'prop-types';
import React from 'react';

import { Svg } from './Svg';

export const ProjectsIcon = ({ size }) => (
  <Svg viewBox="0 0 24 24" size={size}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.684 0H2.526C1.136 0 0 .982 0 2.182v15.272h2.526V2.182h15.158V0zm3.79 4.364H7.579c-1.39 0-2.526.981-2.526 2.181v15.273c0 1.2 1.137 2.182 2.526 2.182h13.895c1.39 0 2.526-.982 2.526-2.182V6.545c0-1.2-1.137-2.181-2.526-2.181zm0 17.454H7.579V6.545h13.895v15.273z"
      fill="currentColor"
    />
  </Svg>
);

ProjectsIcon.propTypes = {
  size: PropTypes.number,
};

ProjectsIcon.defaultProps = {
  size: 24,
};

import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  panelContainer: {
    height: '100%',
    display: 'flex',
    width: '100%',
    padding: '0',
    flexDirection: 'column',
    backgroundColor: theme.palette.primary[1400],
    '& .MuiFormLabel-root, & .MuiFormLabel-root': {
      color: theme.palette.primary[100],
    },
    '& .MuiInputBase-root svg': {
      marginRight: '5px',
    },
    '& .listSection': {
      maxHeight: '10vh',
      marginTop: theme.spacing(0.5),
      overflowY: 'auto',
    },
    '& .itemList': {
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
      color: theme.palette.primary[500],
    },
    '& .MuiRadio-root, & .MuiCheckbox-root': {
      color: theme.palette.primary[400],
      '&.Mui-checked': {
        color: theme.palette.primary[400],
      },
      '&:hover': {
        color: theme.palette.secondary.main,
      },
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& .MuiFormGroup-row': {
      width: '100%',
      justifyContent: 'space-between',
    },
    '& .marginTop': {
      marginTop: theme.spacing(1),
    },
  },
  panelHeader: {
    position: 'relative',
    display: 'flex',
    flex: '0 0 auto',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'start',
    padding: theme.spacing(1),
    minHeight: '65px',
  },
  closeButton: {
    padding: 0,
  },
  subTitle: {
    fontWeight: 400,
  },
  panelBody: {
    overflowY: 'auto',
    width: '100%',
    overflowX: 'hidden',
    flex: '1 1 auto',
    // '& p': {
    //   fontSize: '14px',
    // },
    '& .searchBody': {
      padding: `0 ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)}`,
    },
    '& ::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '& ::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary[800],
    },
    '& ::-webkit-scrollbar-thumb:hover': {
      backgroundColor: theme.palette.primary[700],
    },
  },
  panelLoaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  footer: {
    position: 'relative',
    display: 'flex',
    flex: '0 0 auto',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'start',
    padding: theme.spacing(1),
    minHeight: '65px',
    borderTop: `1px solid ${theme.palette.primary[400]}`,
  },
  clearButton: {
    color: theme.palette.primary[400],
    border: `1px solid ${theme.palette.secondary[700]}`,
  },
  buttons: {
    display: 'flex',
    gap: '5px',
  },
});

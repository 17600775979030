import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PencilIcon, SharingIcon } from 'components/Icons';
import moment from 'moment';
import { UuidButton } from 'components/UuidButton/UuidButton';
import { TabPanel } from 'components/TabPanel';
import { Grid, Button, Tabs, Tab, Typography, Box, LinearProgress } from '@mui/material';
import { ProjectVideoPlayer } from 'pages/Profile/SingleProject/ProjectVideoPlayer/ProjectVideoPlayer';
import { Permissions } from 'lib/permissions';
import { inspectionType, actions as saveActions } from 'components/Inspections/helpers';
import { SaveInspection } from 'components/Inspections/SaveInspection/SaveInspection';
import { Assignment } from 'components/Inspections/Assignment/Assignment';
import { useSelector } from 'react-redux';
import { ViewRoleValidation } from 'components/ViewRoleValidation/ViewRoleValidation';
import { ExportFindings } from 'components/Findings/ExportFindings/ExportFindings';
import { Findings } from './Findings';
import { FindingSearch } from '../ProjectAssets/ProjectAssetDetail/ProjectAssetInspections/ProjectAssetInspectionFindings/FindingSearch';
import { useStyles as useProjectStyles } from '../styles';
import { useStyles } from './styles';

export const LinearProgressWithLabel = (props) => {
  const classes = useStyles();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 0.3 }} className={classes.progressBar}>
        <LinearProgress color="secondary" variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 'max-content' }}>
        <Typography sx={{ color: 'white' }}>{`${Math.round(props.value > 100 ? 100 : props.value)}${
          props.label
        }`}</Typography>
      </Box>
    </Box>
  );
};

export const ProjectAssetInspectionDetail = ({ projAssetInspection, externalAction }) => {
  const projectClases = useProjectStyles();
  const classes = useStyles();
  const [tab, setTabValue] = useState(0);
  const [openEdit, setOpenEdit] = useState(false);
  const { userId } = useSelector((state) => state.auth);
  const [validateRole, setValidateRole] = useState({ open: false, callBack: () => {} });
  const [openExport, setOpenExport] = useState(false);

  const validateInspection = () => {
    if (userId === projAssetInspection.assignment?.assignee?.id) {
      setOpenEdit(true);
      return;
    }
    setValidateRole({
      open: true,
      action: [Permissions.PROJ_INSPECTIONS],
      callBack: () => setOpenEdit(true),
    });
  };

  const validateExport = () => {
    if (userId === projAssetInspection.assignment?.assignee?.id) {
      setOpenExport(true);
      return;
    }
    setValidateRole({
      open: true,
      action: [Permissions.PROJ_INSPECTIONS],
      callBack: () => setOpenExport(true),
    });
  };

  useEffect(() => {
    setTabValue(0);
  }, [projAssetInspection?.id]);

  const AssetContent = () => (
    <>
      <div className={projectClases.title}>
        <div className={projectClases.titleContent}>
          <Typography variant="h5">{projAssetInspection.inspection?.name || ''}</Typography>
        </div>
        <div className={projectClases.titleContent}>
          <Button
            size="medium"
            color="primary"
            variant="contained"
            className={`${classes.exportAllFindingsButton}`}
            onClick={externalAction}
          >
            Export All Findings
          </Button>
        </div>
      </div>
      <div className={classes.assetContent}>
        <div
          className={`${projectClases.mapDetailContainer} ${
            userId === projAssetInspection.assignment?.assignee?.id ? classes.playButton : ''
          }`}
        >
          {projAssetInspection?.projectAsset?.id && (
            <ProjectVideoPlayer
              projectId={projAssetInspection.projectAsset?.projId}
              selectedAsset={projAssetInspection?.projectAsset}
            />
          )}
        </div>
      </div>
    </>
  );

  return (
    <div className={projectClases.containerDetail}>
      {AssetContent()}
      <div className={projectClases.tabsDetail}>
        {projAssetInspection?.id && (
          <>
            <Tabs value={tab} className={projectClases.headerTabs} sx={{ position: 'relative' }}>
              <Tab label="Overview" onClick={() => setTabValue(0)} />
              <Tab label="Findings" onClick={() => setTabValue(1)} />
              <Tab label="Advanced" onClick={() => setTabValue(2)} />
              <div style={{ position: 'absolute', right: 20, top: 0 }}>{tab === 1 && <FindingSearch small />}</div>
            </Tabs>
            <div className={projectClases.bodyDetail} style={{ maxHeight: '25vh' }}>
              <TabPanel value={tab} index={0}>
                <Grid container spacing={0.5} alignItems="center">
                  <Grid item xs={12} sm={12} lg={8} md={8} xl={8}>
                    <Typography variant="h4">
                      {projAssetInspection.projectAsset?.name}: {projAssetInspection.inspection?.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={4} md={4} xl={4}>
                    <Typography variant="h5" className={classes.assignment}>
                      Assigned To:
                      <Assignment projAssetInspection={projAssetInspection} type={inspectionType.PROJECTASSET} />
                    </Typography>
                  </Grid>
                </Grid>
                <Typography mt={0.5}>
                  Assigned:{' '}
                  {projAssetInspection?.assignment?.assignee?.id
                    ? moment(projAssetInspection?.assignment?.assignee?.createdAt).format('MM/DD/YY')
                    : 'Date not found'}
                </Typography>
                <Typography mt={1}>Inspection Description: {projAssetInspection.inspection?.description}</Typography>
                <Typography mt={1} variant="h5">
                  Source Content: {projAssetInspection.projectAsset?.name}
                </Typography>
                <Typography mt={1}>
                  Created By: {`${projAssetInspection.uploader?.firstName} ${projAssetInspection.uploader?.lastName}`}
                </Typography>
                <Typography mb={0.5}>
                  Creation Date: {moment(projAssetInspection.createdAt).format('MM/DD/YY')}
                </Typography>
                <Grid container spacing={0.5}>
                  <Grid item xs={12} sm={12} lg={6} md={6} xl={6} />
                  <Grid item xs={12} sm={12} lg={6} md={6} xl={6}>
                    <LinearProgressWithLabel
                      value={projAssetInspection.progress?.id ? projAssetInspection.progress.percent : 0}
                      label="% Complete"
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <Findings projAssetInspection={projAssetInspection} />
              </TabPanel>
              <TabPanel value={tab} index={2}>
                <Grid container spacing={0.5}>
                  <Grid item xs={12}>
                    <UuidButton
                      data={{ id: projAssetInspection.projectAsset?.projId }}
                      permissions={[Permissions.PROJ_INSPECTIONS]}
                      buttonType
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button className={projectClases.buttonGrapper} onClick={() => validateInspection()}>
                      <Button color="secondary" variant="contained" className={projectClases.smallButton} size="small">
                        <PencilIcon size={15} />
                      </Button>
                      Edit
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button className={projectClases.buttonGrapper} onClick={() => validateExport()}>
                      <Button color="secondary" variant="contained" className={projectClases.smallButton} size="small">
                        <SharingIcon size={15} />
                      </Button>
                      Export Findings
                    </Button>
                  </Grid>
                </Grid>
                {openExport && (
                  <ExportFindings handleClose={() => setOpenExport(false)} projAssetInspection={projAssetInspection} />
                )}
              </TabPanel>
            </div>
          </>
        )}
      </div>
      {openEdit && (
        <SaveInspection
          onCancel={() => setOpenEdit(false)}
          onConfirm={() => setOpenEdit(false)}
          action={saveActions.UPDATE}
          inspection={{
            ...projAssetInspection.inspection,
            paiId: projAssetInspection.id,
            projectAsset: {
              ...projAssetInspection.projectAsset,
              project: { id: projAssetInspection.projectAsset.projId },
            },
          }}
          objectId={projAssetInspection.projectAsset.projId}
          actionType={inspectionType.PROJECTASSET}
        />
      )}
      {validateRole.open && (
        <ViewRoleValidation
          action={validateRole.action}
          data={{ id: projAssetInspection?.projectAsset?.projId }}
          callBack={validateRole.callBack}
          handleClose={() => setValidateRole({ open: false, callBack: () => {} })}
        />
      )}
    </div>
  );
};

ProjectAssetInspectionDetail.propTypes = {
  projAssetInspection: PropTypes.object.isRequired,
};

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs } from '@mui/material'; // , Fab
// import AddIcon from '@mui/icons-material/Add';
import { TabPanel } from 'components/TabPanel';
import { ManageSharedAssets } from 'components/ManageSharedAssets/ManageSharedAssets';
import { SidebarDetailContext } from 'components/SidebarProviderDetail/SidebarProviderDetail';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'components/Loader';
import { setAssetFilter, setObservation, setShowObservationDetailFromUrl } from 'slices/observationsReducer';
import { AddButton } from 'components/SeriesList/AddButton';
import { resetInspections } from 'slices/inspectionReducer';
import { projectAssetSection, setProjAssetSection } from 'slices/profileProjectAssetReducer';
import { ProjectAssetInfo } from './ProjectAssetInfo';
import { ProjectAssetObservations } from './ProjectAssetObservations/ProjectAssetObservations';
import { ProjectAssetSeries } from './ProjectAssetSeries';
import { ProjectAssetSettings } from './ProjectAssetSettings';
import { ProjectAssetObservationSideBar } from './ProjectAssetObservations/ProjectAssetObservationSideBar';
import { ProjectAssetInspFindingSideBar } from './ProjectAssetInspections/ProjectAssetInspFindingSideBar/ProjectAssetInspFindingSideBar';
import { ProjectAssetObservationsSearch } from './ProjectAssetObservations/ProjectAssetObservationsSearch';
import { ProjectAssetInspections } from './ProjectAssetInspections/ProjectAssetInspections';
import { AddProjectAssetInspection } from './ProjectAssetInspections/AddProjectAssetInspection';
import { useStyles as generalStyles } from '../../styles';
import { useStyles } from './styles';

export const ProjectAssetDetail = ({ asset, project }) => {
  const ctx = useContext(SidebarDetailContext);
  const external = ctx?.config?.search?.external;
  const dispatch = useDispatch();
  const generalClasses = generalStyles();
  const classes = useStyles();
  const [tab, setTabValue] = useState(0);
  const { isAssetLoading } = useSelector((state) => state.profileProjectAssets);
  const { observations, showObservationDetailFromUrl } = useSelector((state) => state.assetObservations);
  const [openFinding, setOpenFinding] = useState(false);

  const changeTab = (value, section) => {
    dispatch(setProjAssetSection(section));
    setTabValue(value);
  };

  useEffect(() => {
    // -------------------- get observation from email
    if (showObservationDetailFromUrl) {
      const params = new URLSearchParams(window.location.search);
      const observationId = params.get('observationId');
      const findingId = params.get('findingId');
      const auxObservation = observations.find((item) => item.id === observationId);
      if (observationId && auxObservation?.id) {
        dispatch(setObservation(auxObservation));
        setTabValue(1);
      } else if (findingId) {
        setOpenFinding(findingId);
      }
      dispatch(setShowObservationDetailFromUrl(false));
    }
  }, [observations]);

  useEffect(() => {
    dispatch(setObservation({}));
    setTabValue(0);
    dispatch(setAssetFilter({ search: '' }));
    dispatch(resetInspections());
  }, [asset?.id]);

  return (
    <>
      <div className={classes.assetDetail}>
        {asset?.id && (
          <>
            <Tabs value={tab} className={generalClasses.headerTabs}>
              <Tab label="Overview" onClick={() => changeTab(0, projectAssetSection.OVERVIEW)} />
              <Tab label="Observations" onClick={() => changeTab(1, projectAssetSection.OBSERVATIONS)} />
              <Tab label="Series" onClick={() => changeTab(2, projectAssetSection.SERIES)} />
              <Tab label="Sharing" onClick={() => changeTab(3, projectAssetSection.SHARING)} />
              <Tab label="Inspections" onClick={() => changeTab(4, projectAssetSection.INSPECTIONS)} />
              <Tab label="Administration" onClick={() => changeTab(5, projectAssetSection.ADMIN)} />
            </Tabs>
            <div style={{ position: 'absolute', right: 20, top: 10 }}>
              {tab === 1 && <ProjectAssetObservationsSearch />}
              {tab === 2 && <AddButton assetId={asset.id} projId={asset.projId} icon />}
              {tab === 4 && ctx.activePanel !== 'assetPanel' && <AddProjectAssetInspection />}
            </div>
            <div className={`${classes.assetBodyDetail} ${tab === 4 ? classes.inspectionsContent : ''}`}>
              <TabPanel value={tab} index={0}>
                <ProjectAssetInfo asset={asset} project={project} />
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <ProjectAssetObservations asset={asset} />
              </TabPanel>
              <TabPanel value={tab} index={2}>
                <ProjectAssetSeries />
              </TabPanel>
              <TabPanel value={tab} index={3}>
                {isAssetLoading ? (
                  <Loader height={10} loading size={32} />
                ) : (
                  <ManageSharedAssets asset={asset} external={external} />
                )}
              </TabPanel>
              <TabPanel value={tab} index={4}>
                {isAssetLoading ? (
                  <Loader height={10} loading size={32} />
                ) : (
                  <ProjectAssetInspections projectAsset={asset} />
                )}
              </TabPanel>
              <TabPanel value={tab} index={5}>
                <ProjectAssetSettings asset={asset} project={project} />
              </TabPanel>
            </div>
          </>
        )}
      </div>
      {ctx.activePanel !== 'assetPanel' && (
        <>
          <ProjectAssetObservationSideBar />
          <ProjectAssetInspFindingSideBar findingId={openFinding} handleClose={() => setOpenFinding(false)} />
        </>
      )}
    </>
  );
};

ProjectAssetDetail.propTypes = {
  asset: PropTypes.object,
  project: PropTypes.object,
};

ProjectAssetDetail.defaultProps = {
  asset: null,
  project: null,
};

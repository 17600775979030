import { SearchPanelBody, searchPanelSection } from 'components/Inspections/SearchPanelBody/SearchPanelBody';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dateRangeSelector, preloadFilterDatasets, setFilterValue, setFilters } from 'slices/profileInspectionsReducer';

export const SearchPanel = () => {
  const dispatch = useDispatch();
  const nameSort = useSelector((state) => state.profileInspections.filters.sort_by.name || '');
  const dateSort = useSelector((state) => state.profileInspections.filters.sort_by.createdAt || '');
  const inspectionIds = useSelector((state) => state.profileInspections.filters.inspectionIds || '');
  const projectIds = useSelector((state) => state.profileInspections.filters.projectIds || '');
  const pastIds = useSelector((state) => state.profileInspections.filters.pastIds || '');
  const type = useSelector((state) => state.profileInspections.filters.type || '');
  const dateRange = useSelector(dateRangeSelector);
  const { assets, inspections, projects, loaded } = useSelector((state) => state.profileInspections.panels.search);

  useEffect(() => {
    dispatch(preloadFilterDatasets());
  }, []);

  return (
    <SearchPanelBody
      loaded={loaded}
      assets={assets}
      inspections={inspections}
      projects={projects}
      filters={{
        sort_by: {
          name: nameSort,
          createdAt: dateSort,
        },
        inspectionIds,
        projectIds,
        pastIds,
        type,
        date_range: dateRange,
      }}
      setFilterValue={setFilterValue}
      setFilters={setFilters}
      section={searchPanelSection.PROFILE}
    />
  );
};

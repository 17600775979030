import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  table: {
    marginBottom: '10px',
    borderTop: `1px solid ${theme.palette.primary[700]}`,
    background: theme.palette.primary.main,
    '& thead tr, & thead': {
      backgroundColor: theme.palette.primary.dark,
    },
    '& thead': {
      borderBottom: `1px solid ${theme.palette.primary[800]}`,
    },
    '& thead th': {
      position: 'sticky',
      top: 0,
      backgroundColor: theme.palette.primary.dark,
      zIndex: 1,
    },
    '& tbody': {
      paddingTop: '40px',
    },
    '& tbody tr': {
      cursor: 'pointer',
    },
    '& th, & td': {
      padding: '15px',
      borderBottom: 'solid 1px rgba(128, 128, 128, 0.5)',
      position: 'relative',
      color: theme.palette.primary[300],
      fontSize: '14px',
      fontWeight: 500,
    },
    '& .Mui-selected, & .Mui-selected:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    '& tbody tr:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
});

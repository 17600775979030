import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Svg } from './Svg';

export const PencilIconMapGis = ({ size }) => {
  const [isFocused, setIsFocused] = useState(false);

  const onFocus = () => {
    setIsFocused(true);
  };

  const onBlur = () => {
    setIsFocused(false);
  };

  const onClick = () => {
    setIsFocused(!isFocused);
  };

  return (
    <div>
      <Svg viewBox="0 0 24 24" size={size} tabIndex="0" onFocus={onFocus} onBlur={onBlur} onClick={onClick}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.9 4.88c.133.174.133-.68 0-1.594L20.715.099c-.914-.132-1.768-.132-1.593 0l-3.187 3.187 4.78 4.78c.307 0 2.79-2.483 3.187-3.187zM0 19.22V24h4.78L19.12 9.66l-4.78-4.78L0 19.22z"
          fill={isFocused ? 'orange' : 'currentColor'}
        />
      </Svg>
    </div>
  );
};

PencilIconMapGis.propTypes = {
  size: PropTypes.number,
};

PencilIconMapGis.defaultProps = {
  size: 24,
};

import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  content: {
    padding: theme.spacing(1.5),
    background: 'none',
    color: theme.palette.white,
    height: '85vh',
    overflowY: 'auto',
  },
  panel: {
    paddingTop: theme.spacing(1),
  },
});

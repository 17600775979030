import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getShareProjects } from 'slices/allProjectReducer';
import { useParams } from 'react-router';
import { ManageSharedAssets } from 'components/ManageSharedAssets/ManageSharedAssets';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { ModalHeaderIcon } from 'components/ModalHeaderIcon/ModalHeaderIcon';
import { SharingIcon } from 'components/Icons';

export const DialogProjectShare = ({
  icon: IconComponent,
  title,
  isOpen,
  onCancel,
  onConfirm,
  asset,
  external,
  disabledShares,
  projectId,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [selectValue, setSelectValue] = React.useState('');
  const [deleteOne, setDeleteOne] = React.useState(false);
  const projId = projectId || params.projId;

  const preOnCancel = () => {
    onCancel(external ? false : deleteOne);
  };

  useEffect(() => {
    dispatch(getShareProjects());
  }, []);

  const { shares, loadingShare } = useSelector((state) => state.allProjects);

  const rootOptions = shares
    .filter((element) => element.id !== projId)
    .map((proj) => ({
      label: proj.name,
      value: proj.id,
    }));

  return (
    <Dialog open={isOpen} maxWidth="sm" onClose={preOnCancel}>
      <DialogTitle>
        <ModalHeaderIcon icon={IconComponent || SharingIcon} />
        <Typography variant="h4">{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <InputLabel>Project *</InputLabel>
        <FormControl fullWidth>
          <Autocomplete
            options={rootOptions}
            getOptionLabel={(option) => option.label}
            renderInput={(props) => <TextField {...props} placeholder="Select a Project..." />}
            onChange={(e, value) => {
              setSelectValue(value?.value ? value?.value : '');
            }}
            loading={loadingShare}
          />
        </FormControl>
        {!disabledShares && (
          <ManageSharedAssets
            asset={asset}
            handleClose={(deletedFlag) => setDeleteOne(deletedFlag)}
            external={external}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button color="primary" variant="contained" onClick={preOnCancel} size="large" fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => onConfirm(selectValue)}
              size="large"
              disabled={!selectValue}
              fullWidth
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
DialogProjectShare.propTypes = {
  /**
   * The icon is an svg component that is passed a className and size.
   */
  icon: PropTypes.node,
  /**
   * The title is the text that is displayed on the screen
   */
  title: PropTypes.string,
  /**
   * is a boolean that checks if the modal is open or not
   */
  isOpen: PropTypes.bool,
  /**
   * this is what action is needed to be handled when clicking confirm
   */
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  // projectId: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  asset: PropTypes.object,
  projectId: PropTypes.string,
  external: PropTypes.bool,
  disabledShares: PropTypes.bool,
};

DialogProjectShare.defaultProps = {
  icon: null,
  title: '',
  isOpen: false,
  onConfirm: null,
  onCancel: null,
  asset: null,
  projectId: null,
  external: false,
  disabledShares: false,
};

import styled, { css } from 'styled-components';

const buttonSizes = {
  large: css`
    font-size: 17px;
    font-weight: 700;
    line-height: 1.5;
    text-align: center;
    height: 60px;
    ${(props) => {
      if (props.iconOnly) {
        return css`
          padding: 15px;
          width: 60px;
        `;
      }
      return css`
        padding: 13px 20px;
      `;
    }}
  `,
  medium: css`
    font-size: 17px;
    font-weight: 700;
    line-height: 1.5;
    text-align: center;
    height: 40px;
    ${(props) => {
      if (props.iconOnly) {
        return css`
          padding: 8px;
          width: 40px;
        `;
      }
      return css`
        padding: 3px 15px;
      `;
    }}
  `,
  small: css`
    font-size: 14px;
    font-weight: 700;
    line-height: 1.5;
    text-align: center;
    height: 30px;
    ${(props) => {
      if (props.iconOnly) {
        return css`
          padding: 6px;
          width: 30px;
        `;
      }
      return css`
        padding: 1px 10px;
      `;
    }}
  `,
};

const buttonThemes = {
  primary: css`
    background-color: ${(props) => props.theme.palette.secondary.base};
    border-color: ${(props) => props.theme.palette.secondary.base};
    color: white;
    &:hover {
      background-color: ${(props) => props.theme.palette.secondary[800]};
    }
    &:disabled {
      color: ${(props) => props.theme.palette.primary[600]};
      background-color: ${(props) => props.theme.palette.secondary.disabled};
      cursor: default;
      border-color: ${(props) => props.theme.palette.secondary.base};
      pointer-events: none;
    }
  `,
  secondary: css`
    background-color: ${(props) => props.theme.palette.primary.base};
    border-color: ${(props) => props.theme.palette.primary[700]};
    color: ${(props) => props.theme.palette.primary[700]};
    &:hover {
      color: ${(props) => props.theme.palette.secondary.base};
      background-color: #222526;
    }
    &:disabled {
      color: ${(props) => props.theme.palette.primary[600]};
      cursor: default;
      pointer-events: none;
    }
  `,
  danger: css`
    background-color: ${(props) => props.theme.palette.danger[600]};
    border-color: ${(props) => props.theme.palette.danger[600]};
    color: white;
    &:hover {
      background-color: ${(props) => props.theme.palette.danger[700]};
    }
    &:disabled {
      color: ${(props) => props.theme.palette.primary[600]};
      background-color: ${(props) => props.theme.palette.danger.disabled};
      cursor: default;
      pointer-events: none;
    }
  `,
  success: css`
    background-color: ${(props) => props.theme.palette.success[700]};
    border-color: ${(props) => props.theme.palette.success[700]};
    color: white;
    &:hover {
      background-color: ${(props) => props.theme.palette.success[1000]};
    }
    &:disabled {
      color: ${(props) => props.theme.palette.primary[600]};
      background-color: ${(props) => props.theme.palette.success.disabled};
      cursor: default;
      pointer-events: none;
    }
  `,
};

export const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  position: relative;
  ${(props) => (props.fullWidth ? 'width: 100%;' : '')}
  ${(props) => buttonSizes[props.size]}
  ${(props) => buttonThemes[props.kind]}
  ${(props) => {
    if (props.iconOnly) {
      return css``;
    }

    return css``;
  }};
  margin: ${(props) => (props.spacingTop ? props.theme.spacing(props.spacingTop) : 0)}
    ${(props) => (props.spacingRight ? props.theme.spacing(props.spacingRight) : 0)}
    ${(props) => (props.spacingBottom ? props.theme.spacing(props.spacingBottom) : 0)}
    ${(props) => (props.spacingLeft ? props.theme.spacing(props.spacingLeft) : 0)};
`;

import { Map } from 'components/Map/Map';
import styled from 'styled-components';

export const Layout = styled.div`
  display: grid;
  height: 800px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 16px;
`;

export const ProjectsContainer = styled.div`
  display: grid;
  height: 40vh;
  overflow-y: auto;
  grid-template-columns: repeat(6, 1fr);
  column-gap: ${(props) => props.theme.spacing(2)};
  row-gap: ${(props) => props.theme.spacing(2)};
  padding: 2rem;

  @media (max-width: 1700px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 1450px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1150px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 850px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledMap = styled(Map)`
  border: 1px solid red;
  & > div {
    border: 1px solid red;
  }
`;

import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    backgroundColor: theme.palette.primary[1100],
    borderBottom: `1px solid ${theme.palette.secondary[600]}`,
    gridArea: 'header',
  },
  button: {
    padding: 0,
    margin: 0,
    width: '36px',
    height: '36px',
    minHeight: '36px!important',
    minWidth: '36px!important',
    fontSize: '14px!important',
  },
  image: {
    width: '100%',
    objectFit: 'cover',
    borderRadius: '5px',
  },
});

import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import ProjectService from 'services/ProjectService';
import TeamService from 'services/TeamService';
import UserService from 'services/UserService';
import { formatDateRange, formatSortBy } from './helpers';

export const getProjects = createAsyncThunk('team-projects/getProjects', async (filters) => {
  const filtersOpts = {
    ...filters,
    ...formatSortBy(filters.sort_by),
    ...formatDateRange(filters.date_range),
    include: ['organization'],
  };
  delete filtersOpts.date_range;
  delete filtersOpts.activated;
  if (!filtersOpts.organizationId) delete filtersOpts.organizationId;
  if (!filtersOpts.acctId) delete filtersOpts.acctId;
  if (!filtersOpts.userId) delete filtersOpts.userId;
  if (filtersOpts.isOpen === undefined || filtersOpts.isOpen === null) delete filtersOpts.isOpen;
  const response = await ProjectService.getProjects(filtersOpts);
  return response.data;
});

export const getProject = createAsyncThunk('team-projects/getProject', async ({ projId }) => {
  const [projDetails, projUsers, projTeams] = await Promise.all([
    await ProjectService.getProject(projId),
    await UserService.getUsers({ projectId: projId }),
    await TeamService.getTeams({ projectId: projId }),
  ]);

  return { projDetails, projUsers, projTeams };
});

export const addProject = createAsyncThunk('team-members/addProject', async ({ projId, teamId }) => {
  await ProjectService.updateUserOrTeamRoles(projId, teamId, [13, 14, 15]);
});

export const preloadFilterDatasets = createAsyncThunk(
  'team-projects/preloadFilterDatasets',
  async (teamId) => {
    try {
      const [accounts, organizations] = await Promise.all([
        await ProjectService.getProjectAccounts({ teamId, participantLevel: 'all' }),
        await ProjectService.getProjectOrganizations({ teamId, participantLevel: 'all' }),
      ]);
      return {
        accounts: accounts.data.data,
        organizations: organizations.data.data,
      };
    } catch (err) {
      throw new Error('error loading filter data');
    }
  },
  {
    condition: (_, { getState }) => getState().teamProjects.panels.search.loaded === false,
  }
);

const orderByName = (array) =>
  array.sort((a, b) => {
    const nameA = a.label.toUpperCase(); // ignore upper and lowercase
    const nameB = b.label.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

const initialState = {
  projects: [],
  projectsCount: 0,
  loading: true,
  error: null,
  panels: {
    projUsers: [],
    projTeams: [],
    loading: true,
    error: null,
    detail: {},
    search: {
      loaded: false,
      accounts: [],
      organizations: [],
      teams: [],
    },
  },
  filters: {
    page: 1,
    page_size: 10,
    sort_by: {},
    search: '',
    date_range: null,
    isOpen: null,
  },
};

const teamProjectsReducer = createSlice({
  name: 'teams-projects',
  initialState,
  reducers: {
    resetTeamsProjects: () => initialState,
    setPageFilter(state, action) {
      state.filters.page = action.payload;
    },
    setSearch(state, action) {
      state.filters.search = action.payload;
      state.filters.page = 1;
    },
    setSortBy(state, { payload }) {
      const { label, value } = payload;
      if (value === '' || value === null) {
        delete state.filters.sort_by[label];
      } else {
        state.filters.sort_by[label] = value;
      }
    },
    setFilterValue(state, { payload }) {
      const { param, value } = payload;
      if ((value === '' || value === null) && param !== 'date_range') {
        delete state.filters[param];
        return;
      }
      state.filters[param] = value;
    },
    setFilters(state, action) {
      const filter = { ...state.filters, ...action.payload, page: 1 };
      if (!filter?.participantLevel) delete filter?.participantLevel;
      state.filters = filter;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getProjects.fulfilled, (state, { payload }) => {
      state.projects = payload.data;
      state.projectsCount = payload.meta.totalCount;
      state.loading = false;
    });

    builder.addCase(getProjects.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getProjects.rejected, (state) => {
      state.loading = false;
      state.projects = [];
      state.error = 'unable to load Projects';
    });

    builder.addCase(getProject.pending, (state) => {
      state.panels.loading = true;
    });

    builder.addCase(getProject.fulfilled, (state, action) => {
      state.panels.detail = action.payload.projDetails.data.data;
      state.panels.projTeams = action.payload.projTeams.data.data;
      state.panels.projUsers = action.payload.projUsers.data.data;
      state.error = null;
      state.panels.loading = false;
    });

    builder.addCase(getProject.rejected, (state) => {
      state.panels.error = 'unable to fetch projects';
      state.panels.detail = {};
      state.panels.loading = null;
      state.panels.projTeams = [];
      state.panels.projUsers = [];
    });

    builder.addCase(preloadFilterDatasets.pending, (state) => {
      state.panels.search.loaded = false;
    });
    builder.addCase(preloadFilterDatasets.rejected, (state) => {
      state.panels.search.users = [];
      state.panels.search.teams = [];
      state.panels.search.projects = [];
      state.panels.search.accounts = [];
      state.panels.search.organizations = [];
      state.panels.search.loaded = false;
    });
    builder.addCase(preloadFilterDatasets.fulfilled, (state, { payload }) => {
      const { accounts, organizations } = payload;
      state.panels.search.loaded = true;
      let newAccounts = accounts.map((proj) => ({
        label: proj.name,
        value: proj.id,
      }));
      let newOrganizations = organizations.map((proj) => ({
        label: proj.name,
        value: proj.id,
      }));
      newAccounts = orderByName(newAccounts);
      newOrganizations = orderByName(newOrganizations);
      state.panels.search.accounts = newAccounts;
      state.panels.search.organizations = newOrganizations;
    });
  },
});

export const {
  setFilterValue,
  setPageFilter,
  setSearch,
  setSortBy,
  resetTeamsProjects,
  setFilters,
  // eslint-disable-next-line prettier/prettier
} = teamProjectsReducer.actions;

export const dateRangeSelector = createSelector(
  (state) => state.teamProjects.filters,
  (state) => {
    const dates = state.date_range;

    if (!dates) {
      return dates;
    }

    return dates.map((date) => (date ? new Date(date) : null));
  }
);

export default teamProjectsReducer.reducer;

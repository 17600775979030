import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableBody, IconButton, TableHead, ButtonGroup, TableRow, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { TrashIcon } from 'components/Icons';
import { Table } from 'components/Table';
import { useStyles } from './styles';

export const FieldTable = ({ fields, reoderderFields, setNewField, removeField }) => {
  const classes = useStyles();

  return (
    <Table>
      <TableHead>
        <TableCell />
        <TableCell>#</TableCell>
        <TableCell>Field Name</TableCell>
        <TableCell>Remove</TableCell>
      </TableHead>
      <TableBody>
        {fields
          .filter((item) => !item.deletedAt)
          .map((field, index) => (
            <TableRow>
              <TableCell>
                <ButtonGroup orientation="vertical" aria-label="vertical outlined button group">
                  <IconButton key="up" className={classes.reorderButton} onClick={() => reoderderFields(index, 'up')}>
                    <FontAwesomeIcon icon={faChevronUp} />
                  </IconButton>
                  <IconButton
                    key="down"
                    className={classes.reorderButton}
                    onClick={() => reoderderFields(index, 'down')}
                  >
                    <FontAwesomeIcon icon={faChevronDown} />
                  </IconButton>
                </ButtonGroup>
              </TableCell>
              <TableCell onClick={() => setNewField({ ...field, editAction: true })}>{index + 1}</TableCell>
              <TableCell onClick={() => setNewField({ ...field, editAction: true })}>{field.name}</TableCell>
              <TableCell>
                <Button onClick={() => removeField(field)}>
                  <TrashIcon size={20} />
                </Button>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

FieldTable.propTypes = {
  fields: PropTypes.array,
  reoderderFields: PropTypes.func.isRequired,
  setNewField: PropTypes.func.isRequired,
  removeField: PropTypes.func.isRequired,
};

FieldTable.defaultProps = {
  fields: [],
};

/* eslint-disable no-nested-ternary */
import React from 'react';
import { Form } from 'lib/form';
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { v4 } from 'uuid';
import { useStyles } from './styles';

export const CustomForm = ({ formik, questions }) => {
  const classes = useStyles();

  const handleCheckBox = (e, label, index) => {
    try {
      if (e.checked) {
        if (![...formik.values[label]].find((item) => item === e.value)) {
          const result =
            e.value === 'selectAll'
              ? questions.find((item) => +label === item.id).answers
              : [...formik.values[label], e.value];
          formik.setFieldValue(label, result);
          if (index === 0 && result?.length) {
            formik.setFieldValue('name', `${questions[0].name} - ${result.toString()}`);
          }
        }
      } else {
        const result = e.value === 'selectAll' ? [] : [...formik.values[label]].filter((item) => item !== e.value);
        formik.setFieldValue(label, result);
        if (index === 0 && result?.length) {
          formik.setFieldValue('name', `${questions[0].name} - ${result.toString()}`);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const firstElement = (value) => {
    if (value) {
      formik.setFieldValue('name', `${questions[0].name} - ${value}`);
    }
  };

  return (
    <Grid container spacing={1} className={classes.customForm}>
      {questions.map((item, index) => (
        <Grid key={item.id} item xs={12} sm={12} md={4} lg={4} xl={4}>
          <InputLabel>
            {item.name} {item.required && '*'}
          </InputLabel>
          {item.code === 'input' ? (
            <TextField
              placeholder={item.name}
              value={formik.values[`${item.id}`]}
              id={`${item.id}`}
              name={`${item.id}`}
              {...Form.fieldErrorHelper(formik, `${item.id}`)}
              helperText={
                formik.touched[`${item.id}`] && formik.errors[`${item.id}`] ? formik.errors[`${item.id}`] : null
              }
              onChange={(e) => {
                formik.handleChange(`${item.id}`)(e.target?.value || '');
                if (index === 0) {
                  firstElement(e.target?.value);
                }
              }}
              onBlur={formik.handleBlur}
              // disabled={loading || !enabledEdit}
              fullWidth
            />
          ) : item.code === 'dropdown' ? (
            <FormControl error={formik.touched[`${item.id}`] && formik.errors[`${item.id}`]} fullWidth>
              <Autocomplete
                id={`${item.id}`}
                name={`${item.id}`}
                value={item.answers
                  .map((answer) => ({ value: answer, label: answer }))
                  .find((response) => response.value === formik.values[`${item.id}`])}
                options={item.answers.map((answer) => ({ value: answer, label: answer }))}
                getOptionLabel={(option) => option.label}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    placeholder="Select..."
                    onBlur={formik.handleBlur}
                    error={formik.touched[`${item.id}`] && formik.errors[`${item.id}`]}
                  />
                )}
                onChange={(e, value) => {
                  formik.handleChange(`${item.id}`)(value?.value || '');
                  if (index === 0) {
                    firstElement(value?.value);
                  }
                }}
                onBlur={formik.handleBlur}
                // loading={loadingShare}
              />
              <FormHelperText>
                {formik.touched[`${item.id}`] && formik.errors[`${item.id}`] ? formik.errors[`${item.id}`] : null}
              </FormHelperText>
            </FormControl>
          ) : item.code === 'checkbox' ? (
            <FormControl error={formik.touched[`${item.id}`] && formik.errors[`${item.id}`]} fullWidth>
              <Stack spacing={1} direction="row">
                {item.answers?.map((answer) => (
                  <FormControlLabel
                    key={`${v4()}${new Date().getMilliseconds()}`}
                    control={<Checkbox color="secondary" />}
                    label={answer}
                    value={answer}
                    name={item.id}
                    onChange={(e) => {
                      handleCheckBox(e.target, `${item.id}`, index);
                    }}
                    checked={formik.values[`${item.id}`]?.some((resp) => resp === answer)}
                  />
                ))}
                {item.selectAll && (
                  <FormControlLabel
                    control={<Checkbox color="secondary" />}
                    label="Select All"
                    value="selectAll"
                    name={item.id}
                    onChange={(e) => {
                      handleCheckBox(e.target, `${item.id}`, index);
                    }}
                    checked={formik.values[`${item.id}`]?.length === item.answers.length}
                  />
                )}
              </Stack>
              <FormHelperText>
                {formik.touched[`${item.id}`] && formik.errors[`${item.id}`] ? formik.errors[`${item.id}`] : null}
              </FormHelperText>
            </FormControl>
          ) : (
            <FormControl error={formik.touched[`${item.id}`] && formik.errors[`${item.id}`]} fullWidth>
              <RadioGroup row name={item.id}>
                {item.answers?.map((answer) => (
                  <FormControlLabel
                    key={`${v4()}${new Date().getMilliseconds()}`}
                    value={answer}
                    control={<Radio />}
                    label={answer}
                    onClick={() => {
                      formik.setFieldValue(item.id, answer);
                      if (index === 0) {
                        firstElement(answer);
                      }
                    }}
                    checked={formik.values[`${item.id}`] === answer}
                  />
                ))}
              </RadioGroup>
              <FormHelperText>
                {formik.touched[`${item.id}`] && formik.errors[`${item.id}`] ? formik.errors[`${item.id}`] : null}
              </FormHelperText>
            </FormControl>
          )}
          <Typography className={classes.descriptionLabel}>{item.description}</Typography>
        </Grid>
      ))}
    </Grid>
  );
};

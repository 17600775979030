import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';

export const Loader = ({ loading, height, size }) => {
  const override = {
    display: 'block',
    margin: `${height}px auto`,
  };

  return (
    <div
      className="loader-component"
      style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <CircularProgress color="secondary" loading={loading.toString()} sx={override} size={size || 48} />
    </div>
  );
};

Loader.propTypes = {
  loading: PropTypes.bool.isRequired,
  height: PropTypes.number.isRequired,
  size: PropTypes.number,
};

Loader.defaultProps = {
  size: 0,
};

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  panelContent: {
    '& > div > div': {
      padding: 0,
    },
  },
  header: {
    width: '100%',
    padding: theme.spacing(1),
    background: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.primary[100]}`,
  },
  container: {
    // padding: theme.spacing(1),
    position: 'relative',
    '& .delete-btn': {
      position: 'absolute',
      top: 5,
      left: 0,
    },
  },
  imageEditor: {
    '& > div': {
      height: '55vh!important',
    },
    '& .tui-image-editor-container': {
      height: '100%!important',
    },
  },
  playContainer: {
    position: 'absolute',
    top: '45%',
    zIndex: 1,
    display: 'flex',
    // background: theme.palette.white,
  },
  latTimeText: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 10,
    alignItems: 'center',
    height: '100%',
  },
  headerButton: {
    padding: '10px',
    maxWidth: '40px',
    minWidth: '40px',
    maxHeight: '40px',
    minHeight: '40px',
    marginLeft: 8,
  },
  descriptionLabel: {
    fontWeight: 400,
    color: '#8F8F8F',
  },
  customForm: {
    maxHeight: '23vh',
    overflowY: 'auto',
  },
}));

import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import ProjectService from 'services/ProjectService';
import UserAssets from 'services/UserAssets';
import { formatDateRange } from './helpers';

export const getContents = createAsyncThunk('content/getContents', async (filterOpts) => {
  const filtersOpts = {
    ...filterOpts,
    include: ['uploader', 'project'],
    sort_by: Object.values(filterOpts.sort_by),
    ...formatDateRange(filterOpts.date_range),
    strict: true,
  };
  delete filtersOpts.acctId;
  delete filtersOpts.userId;
  delete filtersOpts.teamId;
  delete filtersOpts.date_range;
  delete filtersOpts.activated;
  try {
    const response = await UserAssets.getUserAssets(filtersOpts);
    return response.data;
  } catch (e) {
    throw new Error('Error during request for content...');
  }
});

export const getSingleContent = createAsyncThunk('AssetService/getSingleContent', async ({ projectId, id, user }) => {
  const [details] = await Promise.all([await UserAssets.getSingleAsset(projectId, id)]);
  return {
    details,
    user,
  };
});

export const preloadFilterDatasets = createAsyncThunk(
  'content/preloadFilterDatasets',
  async () => {
    try {
      const [projects, organizations] = await Promise.all([
        await ProjectService.getProjects({ page_size: 1000, participantLevel: 'administer' }),
        await ProjectService.getProjectOrganizations({ participantLevel: ['administer'] }),
      ]);

      return {
        projects: projects.data.data,
        organizations: organizations.data.data,
      };
    } catch (err) {
      throw new Error('error loading filter data');
    }
  },
  {
    condition: (_, { getState }) => getState().content.panels.search.loaded === false,
  }
);

const orderByName = (array) =>
  array.sort((a, b) => {
    const nameA = a.label.toUpperCase(); // ignore upper and lowercase
    const nameB = b.label.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

const initialState = {
  error: null,
  loading: true,
  data: [],
  panels: {
    loading: true,
    details: [],
    user: [],
    search: {
      loaded: false,
      projects: [],
      organizations: [],
    },
  },
  filters: {
    page: 1,
    page_size: 10,
    sort_by: {},
    date_range: null,
  },
  detail: {},
};

const contentSlice = createSlice({
  name: 'contents',
  initialState,
  reducers: {
    resetContents: () => initialState,
    setPageFilter(state, action) {
      state.filters.page = action.payload;
    },
    setSearch(state, action) {
      state.filters.search = action.payload;
      state.filters.page = 1;
    },
    setFilterValue(state, { payload }) {
      const { param, value } = payload;
      if ((value === '' || value === null) && param !== 'date_range') {
        delete state.filters[param];
        return;
      }
      state.filters[param] = payload.value;
    },
    setDateRange(state, { payload }) {
      state.filters.date_range = payload;
    },
    setFilters(state, action) {
      const filter = { ...state.filters, ...action.payload, page: 1 };
      if (!filter?.participantLevel) delete filter?.participantLevel;
      state.filters = filter;
    },
    setContentDetail(state, { payload }) {
      state.detail = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getContents.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getContents.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.assetCount = action.payload.meta.totalCount;
      state.error = null;
      state.loading = false;
    });
    builder.addCase(getContents.rejected, (state) => {
      state.content = [];
      state.error = 'There was an error during the request.';
      state.loading = false;
    });
    builder.addCase(getSingleContent.pending, (state) => {
      state.panels.loading = true;
    });
    builder.addCase(getSingleContent.fulfilled, (state, action) => {
      state.panels.details = action.payload.details.data.data;
      state.panels.user = action.payload.user;
      state.error = null;
      state.panels.loading = false;
    });
    builder.addCase(getSingleContent.rejected, (state) => {
      state.data = [];
      state.error = 'There was an error during the request.';
      state.panels.loading = false;
    });

    builder.addCase(preloadFilterDatasets.pending, (state) => {
      state.panels.search.loaded = false;
    });
    builder.addCase(preloadFilterDatasets.rejected, (state) => {
      state.panels.search.users = [];
      state.panels.search.teams = [];
      state.panels.search.projects = [];
      state.panels.search.organizations = [];
      state.panels.search.loaded = false;
    });
    builder.addCase(preloadFilterDatasets.fulfilled, (state, action) => {
      const { projects, organizations } = action.payload;
      state.panels.search.loaded = true;
      const newProjects = projects.map((proj) => ({
        label: proj.name,
        value: proj.id,
      }));
      let newOrganizations = organizations.map((proj) => ({
        label: proj.name,
        value: proj.id,
      }));
      newOrganizations = orderByName(newOrganizations);
      state.panels.search.projects = newProjects;
      state.panels.search.organizations = newOrganizations;
    });
  },
});

export const {
  setPageFilter,
  contentActions,
  setSearch,
  resetContents,
  setFilterValue,
  setFilters,
  setContentDetail,
  // eslint-disable-next-line prettier/prettier
} = contentSlice.actions;

export const dateRangeSelector = createSelector(
  (state) => state.content.filters,
  (state) => {
    const dates = state.date_range;

    if (!dates) {
      return dates;
    }
    return dates.map((date) => (date ? new Date(date) : null));
  }
);

export default contentSlice.reducer;

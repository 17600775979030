import ApiService from './ApiService';

export default class UserService {
  static getUsers = async (filters) => ApiService.get(`/users`, filters);

  static getUser = async (userId) => ApiService.get(`/users/${userId}`);

  static getUserRoles = async (userId) => ApiService.get(`/users/${userId}/roles`);

  static getUsersAccountsOrganizations = async (filters) => ApiService.get(`/users/all/accountsOrganizations`, filters);

  static createUser = async (user) => ApiService.post(`/users`, user);

  static resendInvitation = async (userId) => ApiService.post(`/users/${userId}/resend-invitation`);

  /**
   * Update the account level roles for a userS
   *
   * @param {string} userId
   * @param {object{}} data
   * @returns
   */
  static updateAccountPassword = async (userId, data) => ApiService.patch(`/users/${userId}/update-password`, data);

  static updateAccountUser = async (userId, data) => ApiService.patch(`/users/${userId}`, data);

  static deleteAccountUser = async (userId) => ApiService.delete(`/users/${userId}`);
}

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import localforage from 'localforage';
/* System User Pages */
import { Loader } from '../../Loader';

import {
  authenticateCurrentUser,
  getAllProjectGrants,
  getAllOrgGrants,
  getAllTeamsGrants,
  getAccountGrants,
  buildPermsList,
} from '../../../slices/authReducer';

export const AuthGuard = ({ children }) => {
  const nonAuthPaths = ['/accept-invite', '/password-reset', '/request-reset', '/', '/login'];

  const authStatus = useSelector((state) => state.auth.authStatus);
  const projectStatus = useSelector((state) => state.auth.projectStatus);
  const teamsStatus = useSelector((state) => state.auth.teamsStatus);
  const orgStatus = useSelector((state) => state.auth.orgStatus);
  const accountStatus = useSelector((state) => state.auth.accountStatus);
  const tgAdmin = useSelector((state) => state.auth.tgAdmin);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const token = JSON.parse(localStorage.getItem('user'))?.accessToken;

  useEffect(() => {
    if (token) {
      dispatch(authenticateCurrentUser());
      dispatch(getAllProjectGrants());
      dispatch(getAllOrgGrants());
      dispatch(getAllTeamsGrants());
      dispatch(getAccountGrants());
    }
  }, [token]);

  const { state } = useLocation();

  // Use to determine if all dispatches has executed successfully
  const loading = ![authStatus, projectStatus, teamsStatus, orgStatus, accountStatus].every(
    (status) => status === 'succeeded'
  );

  const loginUrl = '/login';

  if (authStatus === 'failed') {
    localStorage.clear();
    if (location.pathname !== loginUrl) history.push(loginUrl);
  }

  if (token) {
    // allow user to hit root or login page
    const decoded = jwt.decode(token, { complete: true });
    const accountId = decoded?.payload.acctId;
    localforage.setItem('acctId', JSON.stringify(accountId));
    let dashboardUrl;

    if (tgAdmin) {
      // redirect sa's with no account id to account select
      dashboardUrl = accountId ? '/admin' : '/sa/accounts';
    } else {
      dashboardUrl = '/profile';
    }

    if (state?.from) {
      history.push(state?.from);
    }

    // redirect users with token to dashboard if not already on dashboard
    if (location.pathname === loginUrl) history.push(dashboardUrl);
  } else {
    // unauthenticated user actions
    // allow user to non auth pages
    const isNonAuthPath = nonAuthPaths.some((path) => location.pathname.includes(path));

    if (isNonAuthPath) return children;

    history.push(loginUrl);
  }

  if (!loading) dispatch(buildPermsList());

  const LoaderContainer = (
    <div className="App">
      <Loader loading={loading} height={250} />
    </div>
  );

  // if loading show loader else show app
  return loading ? LoaderContainer : children;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

AuthGuard.defaultProps = {
  children: null,
};

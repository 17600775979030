import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  assetDetail: {
    padding: `${theme.spacing(0.5)} 0`,
    position: `relative`,
    '& .MuiTabs-root': {
      width: '100%',
    },
    '& .MuiTabs-flexContainer': {
      gap: '8px',
    },
    '& .MuiTab-root': {
      borderRight: 'none',
      padding: '8px 12px!important',
      '&.Mui-selected': {
        background: `var(--gray-800, ${theme.palette.primary[1200]})`,
        borderRadius: '6px',
      },
    },
  },
  assetBodyDetail: {
    padding: theme.spacing(0.5),
    overflowY: 'auto',
    maxHeight: '20vh',
    '& .MuiTableContainer-root': {
      height: 'auto',
    },
    '& .MuiTable-root': {
      borderTop: 'none',
      '& tbody tr': {
        cursor: 'default',
      },
    },
    '& .Mui-checked': {
      color: `${theme.palette.secondary.main}!important`,
    },
  },
  observationsContainer: {},
  observationIcons: {
    '& svg': {
      color: theme.palette.primary[400],
    },
  },
  observationDetail: (props) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.primary[1400],
    borderLeft: `1px solid ${theme.palette.secondary[700]}`,
    zIndex: 799,
    transform: `translateX(${props.open ? '0%' : '110%'})`,
    transition: 'transform 0.3s ease-out',
  }),
  buttonGrapper: {
    padding: 0,
  },
  smallButton: {
    minHeight: 'auto',
    minWidth: 'auto',
    padding: '8px',
    marginRight: '5px',
  },
  openButton: (props) => ({
    width: props.autoWidth ? 'max-content' : '30px',
    height: '25px',
    ...(!props.autoWidth && { padding: 0 }),
    minHeight: '30px',
    minWidth: '30px',
  }),
  observationSearch: {
    width: '180px!important',
    minHeight: '30px',
    height: '30px',
    '& .MuiInputBase-root': {
      height: '100%',
    },
    '& button': {
      padding: 0,
    },
  },
  dropDown: {
    position: 'absolute',
    width: '200%',
    maxHeight: '20vh',
    top: '100%',
    right: 0,
    backgroundColor: theme.palette.primary[1400],
    zIndex: 2,
    color: theme.palette.primary[100],
    overflowY: 'auto',
    overflowX: 'hidden',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    '& .content': {
      flex: 1,
      overflowY: 'auto',
    },
    '& .new-option': {
      padding: '5px 2rem',
      cursor: 'pointer',
      borderTop: `1px solid${theme.palette.primary.main}`,
      '&:hover': {
        backgroundColor: theme.palette.primary[1300],
      },
    },
    '& .option': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: '5px',
    },
  },
  inspectionsContent: {
    height: '20vh',
    overflowY: 'hidden',
    '& > div': {
      height: '100%',
    },
    '& .MuiTableContainer-root': {
      height: '100%',
      overflowY: 'auto',
      '& thead th': {
        position: 'relative',
      },
    },
  },
  selectAssign: {
    position: 'relative',
    '& .defult-assign': {
      display: 'flex',
      gap: 8,
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '& .danger': {
      color: theme.palette.danger.main,
    },
    '& .MuiButtonBase-root': {
      color: theme.palette.primary[100],
    },
    '& .items': {
      position: 'absolute',
      top: '100%',
      backgroundColor: theme.palette.primary.dark,
      borderRadius: '8px',
      maxHeight: '12vh',
      overflowY: 'auto',
      zIndex: 10,
      '& .MuiMenuItem-root': {
        borderBottom: `1px solid ${theme.palette.primary[400]}`,
        fontSize: '14px',
        cursor: 'default',
      },
      '& .MuiMenuItem-root:last-child': {
        borderBottom: 'none',
      },
    },
  },
  table: {
    height: '100%',
    '& .MuiTableRow-root': {
      cursor: 'pointer!important',
    },
  },
}));

import { CheckIcon } from 'components/Icons/CheckIcon';
import { CloseIcon } from 'components/Icons/CloseIcon';
// import { FileIcon } from 'components/Icons/FileIcon';
import { ProgressBar } from 'components/ProgressBar/ProgressBar';
import { Typography } from 'components/Typography/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import {
  StyledButtonContainer,
  StyledContainer,
  StyledIconContainer,
  StyledItemButton,
  StyledProgressContainer,
  StyledTitleContainer,
  StyledUploadCompleteText,
} from './styles';

library.add(faFile);

export const UploadStatusFileItem = ({ progress, onCancel, children }) => {
  const isDone = progress >= 100;

  return (
    <StyledContainer complete={isDone}>
      <StyledIconContainer>
        <FontAwesomeIcon icon={faFile} size="sm" style={{ width: 20, height: 20 }} />
      </StyledIconContainer>
      <StyledTitleContainer>
        <Typography>{children}</Typography>
      </StyledTitleContainer>
      <StyledProgressContainer>
        {isDone ? (
          <StyledUploadCompleteText>Uploaded</StyledUploadCompleteText>
        ) : (
          <ProgressBar value={progress} color="green" />
        )}
      </StyledProgressContainer>
      <StyledButtonContainer>
        <StyledItemButton type="button" complete={isDone} onClick={onCancel}>
          {isDone ? <CheckIcon size="16" /> : <CloseIcon size="16" />}
        </StyledItemButton>
      </StyledButtonContainer>
    </StyledContainer>
  );
};

UploadStatusFileItem.propTypes = {
  progress: PropTypes.number,
  onCancel: PropTypes.func,
  children: PropTypes.node,
};

UploadStatusFileItem.defaultProps = {
  progress: 0,
  onCancel: () => {},
  children: null,
};

import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  content: {
    backgroundColor: theme.palette.primary[1400],
    height: '100vh',
    display: 'grid',
    placeItems: 'center',
    overflowY: 'auto',
    padding: theme.spacing(1),
  },
  logoContent: {
    width: '100%',
    textAlign: 'center',
  },
  grapper: {
    width: '40ch',
  },
  logo: {
    width: '30ch',
  },
  textContent: (props) => ({
    textAlign: 'center',
    margin: props.subTitle ? `${theme.spacing(2)} 0 ${theme.spacing(1)} 0` : `${theme.spacing(2)} 0 `,
  }),
  title: {
    '&.MuiTypography-root': {
      fontSize: '30px',
      fontWeight: 600,
    },
  },
  subtitle: {
    '&.MuiTypography-root': {
      fontWeight: 400,
      color: theme.palette.primary[300],
    },
  },
  childContent: {
    '& .MuiButton-containedSecondary, & .MuiButton-containedPrimary': {
      margin: `${theme.spacing(1)} 0`,
    },
    '& .shortText': {
      textAlign: 'center',
      margin: `${theme.spacing(1)} 0 ${theme.spacing(1.5)} 0`,
      '& a': {
        cursor: 'pointer',
      },
    },
  },
  loginReturn: {
    marginTop: theme.spacing(0.5),
    width: '100%',
    textAlign: 'center',
    '& a': {
      fontWeight: 500,
      cursor: 'pointer',
      color: theme.palette.primary[300],
      '&:hover': {
        color: `${theme.palette.primary[400]}!important`,
        textDecoration: 'none',
      },
      '& svg': {
        color: theme.palette.primary[600],
        marginRight: 10,
      },
    },
  },
});

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  styledWidgetSplash: {
    flex: '1',
    textAlign: 'center',
    padding: '2rem',
  },
  styledTitle: {
    flex: '1 1 auto',
    paddingBottom: '10px',
    display: 'flex',
    justifyContent: 'center',
  },
  typograhpy: {
    fontSize: '25px',
    width: '700px',
  },
  styledImage: {
    flex: '1',
    paddingBottom: '20px',
  },
  styledButtonContainer: {
    textAlign: 'center',
  },
});

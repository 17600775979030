import React from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton, Typography } from '@mui/material';
import { cutUuidText } from 'lib/generalFunctions';
import { CopyUuidAlert } from 'components/AddUuid/CopyUuidAlert/CopyUuidAlert';
import { CopyIcon } from 'components/Icons';
import { ViewRoleValidation } from 'components/ViewRoleValidation/ViewRoleValidation';
import { useStyles } from './styles';

export const UuidButton = ({ data, permissions, buttonType }) => {
  const classes = useStyles();
  const [uuidCopied, setUuidCopied] = React.useState(false);
  const [validateRole, setValidateRole] = React.useState({ open: false, callBack: () => {} });

  const copyUuid = () => {
    if (permissions && permissions.length > 0) {
      setValidateRole({
        open: true,
        action: [Permissions.TEAM_MODIFY],
        data,
        callBack: () => setUuidCopied(data.id),
      });
    } else {
      setUuidCopied(data.id);
    }
  };

  return (
    <>
      {!buttonType ? (
        <Typography className={classes.text}>
          UUID:
          <span className={classes.uuid}> {cutUuidText(data.id)} </span>
          <IconButton onClick={copyUuid} size="large" className={classes.uuidIcon}>
            <CopyIcon />
          </IconButton>
        </Typography>
      ) : (
        <Button onClick={copyUuid} className={classes.buttonGrapper}>
          <Button color="secondary" variant="contained" className={classes.smallButton}>
            <CopyIcon size={15} />
          </Button>
          Copy UUID
        </Button>
      )}
      {uuidCopied && <CopyUuidAlert id={uuidCopied} handleClose={() => setUuidCopied(false)} />}
      {validateRole.open && (
        <ViewRoleValidation
          action={permissions}
          data={validateRole.data}
          callBack={validateRole.callBack}
          handleClose={() => setValidateRole({ open: false, callBack: () => {} })}
        />
      )}
    </>
  );
};

UuidButton.propTypes = {
  data: PropTypes.object.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string),
  buttonType: PropTypes.bool,
};

UuidButton.defaultProps = {
  permissions: [],
  buttonType: false,
};

import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  invitationCard: {
    position: 'relative',
    marginBottom: '2rem',
  },
  invitationItem: {
    backgroundColor: theme.palette.primary[1000],
    width: '100%',
    display: 'block',
    textAlign: 'left',
    fontSize: '13px',
    fontweight: theme.typography.small.fontWeight,
    border: 'none',
    color: theme.palette.white,
    '&:hover, &:focus, &:active': {
      backgroundColor: theme.palette.primary[900],
      borderradius: '5px',
    },
  },
  searchResults: {
    zIndex: 99,
    backgroundColor: theme.palette.primary[1000],
    position: 'absolute',
    width: '100%',
    borderRadius: '0 0 5px 5px',
  },
  itemContainer: {
    minHeight: '200px',
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginBottom: '5px',
    },
  },
  itemPanel: {
    display: 'grid',
    alignItems: 'right',
    gridTemplateColumns: '2.5fr 1fr',
  },
  itemSection: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1.5fr 2.5fr 7fr',
  },
  itemRoles: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
  },
  itemTagSection: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'start',
    alignItems: 'start',
    alignContent: 'space-between',
  },
  itemTag: {
    display: 'inline-flex',
    alignItems: 'right',
    border: theme.palette.white,
    borderRadius: '5px',
    padding: '1px 5px',
    margin: '3px',
  },
});

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import BillingService from 'services/BillingService';

export const fetchCreditUsage = createAsyncThunk('credit-usage/fetchCreditUsage', async (filterOpts) => {
  const filtersOpts = {
    ...filterOpts,
  };
  delete filtersOpts.activated;
  const response = await BillingService.getCreditUsage({ filtersOpts });

  return response.data;
});

const initialState = {
  records: [],
  recordsCount: 0,
  loading: true,
  error: null,
  filters: {
    page: 1,
    page_size: 10,
    sort_by: {},
    search: '',
  },
};

const creditUsageSlice = createSlice({
  name: 'credit-usage',
  initialState,
  reducers: {
    resetRecords: () => initialState,
    setPageFilter(state, action) {
      state.filters.page = action.payload;
    },
    setSearch(state, action) {
      state.filters.search = action.payload;
      state.filters.page = 1;
    },
    setSortBy(state, { payload }) {
      const { label, value } = payload;
      if (value === '' || value === null) {
        delete state.filters.sort_by[label];
      } else {
        state.filters.sort_by[label] = value;
      }
    },
    setFilterValue(state, { payload }) {
      const { param, value } = payload;

      if (value === '' || value === null) {
        delete state.filters[param];
        return;
      }

      state.filters[param] = payload.value;
    },
    setFilters(state, action) {
      const filter = { ...state.filters, ...action.payload, page: 1 };
      state.filters = filter;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchCreditUsage.fulfilled, (state, { payload }) => {
      state.records = payload.data;
      state.recordsCount = payload.meta.totalCount;
      state.loading = false;
    });

    builder.addCase(fetchCreditUsage.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchCreditUsage.rejected, (state) => {
      state.loading = true;
      state.records = [];
      state.error = 'unable to load records';
    });
  },
});

export const {
  setPageFilter,
  setSortBy,
  setSearch,
  setFilterValue,
  setFilters,
  // eslint-disable-next-line prettier/prettier
} = creditUsageSlice.actions;

export default creditUsageSlice.reducer;

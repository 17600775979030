import PropTypes from 'prop-types';
import React from 'react';
import { Typography } from '@mui/material';
import { WidgetDivider } from 'components/WidgetDivider/WidgetDivider';
import { Avatar } from '../Avatar/Avatar';
import { useStyles } from './styles';

export const WidgetUser = ({ userTitle, userSubTitle, initials, border }) => {
  const classes = useStyles();

  return (
    <div className={classes.userContent}>
      <div className={classes.title}>
        <Avatar initials={initials} size={95} />
      </div>
      <div className={classes.heading}>
        <Typography mb={0.5}>Hello,</Typography>
        <Typography variant="h3" mb={0.3}>
          {userTitle}
        </Typography>
        <Typography variant="h5">{userSubTitle}</Typography>
      </div>
      {border && <WidgetDivider margin />}
    </div>
  );
};

WidgetUser.propTypes = {
  userTitle: PropTypes.string.isRequired,
  userSubTitle: PropTypes.string.isRequired,
  initials: PropTypes.string.isRequired,
  // iconClick: PropTypes.func,
  border: PropTypes.bool,
};

WidgetUser.defaultProps = {
  // iconClick: null,
  border: true,
};

/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CirclePlusIcon, InfoIcon } from 'components/Icons';
import { Button, Checkbox, Typography } from '@mui/material';
import { DialogMessage } from 'components/DialogMessage';
import { actions, inspectionType } from 'components/Inspections/helpers';
import { SaveInspection } from 'components/Inspections/SaveInspection/SaveInspection';
import { ViewRoleValidation } from 'components/ViewRoleValidation/ViewRoleValidation';
import { Permissions } from 'lib/permissions';
import { v4 } from 'uuid';
import InspectionService from 'services/InspectionService';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import { setProjectAssetInspections } from 'slices/projectAssetActiveInspectionReducer';
import { useStyles } from '../styles';

export const AddProjectAssetInspection = () => {
  const classes = useStyles({ autoWidth: true });
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState('');
  const { data: inspections, loading: loadingInspections } = useSelector((state) => state.projectInspections);
  const projectAsset = useSelector((state) => state.profileProjectAssets.projectAsset);
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState(null);
  const [validateRole, setValidateRole] = useState({ open: false, callBack: () => {} });
  const { data } = useSelector((state) => state.projectAssetActiveInspections);
  const { fname, lname, userId } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);

  // add inspection to proj asset
  const addInspection = async (inspection) => {
    setLoading(true);
    try {
      const result = await InspectionService.assignAsset(inspection.id, {
        pastId: projectAsset?.id,
        projectId: projectAsset?.project?.id,
      });
      if (result?.data?.data) {
        const projectAssetInspections = [...data];
        const insp = result?.data?.data;
        const projectAssetInspection = {
          createdAt: new Date(),
          inspection,
          assignment: null,
          uploader: { firstName: fname, lastName: lname, id: userId },
          ...insp,
        };
        projectAssetInspections.push(projectAssetInspection);
        dispatch(setProjectAssetInspections(projectAssetInspections));
      }
      setLoading(false);
      setOpenDialog('Project Asset Inspection was saved.');
    } catch (error) {
      setLoading(false);
      setOpenDialog('An error occurred while trying to save the project asset inspection.');
    }
  };

  // close assign inspection
  const onCancel = (flagConfirm) => {
    setAction(null);
    if (flagConfirm) setOpen(!open);
  };

  const newOption = () => (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, prettier/prettier
    <div
      className="new-option"
      onClick={() =>
        setAction({ value: actions.CREATE, type: inspectionType.PROJECTASSET, objectId: projectAsset?.projId })
      }
    >
      <Typography>Create New</Typography>
    </div>
  );

  return (
    <>
      <Button
        color="secondary"
        variant="contained"
        onClick={() =>
          setValidateRole({
            open: true,
            action: [Permissions.PROJ_INSPECTIONS],
            callBack: () => setOpen(!open),
          })
        }
        className={classes.openButton}
        sx={{ position: 'relative' }}
      >
        <CirclePlusIcon size={15} /> <Typography ml={0.2}>Inspection</Typography>
      </Button>
      {open && (
        <div className={classes.dropDown}>
          {loadingInspections ? (
            <div className="option">
              <Typography>Loading...</Typography>
            </div>
          ) : !inspections.length ? (
            <>
              <div className="content">
                <div className="option">
                  <Typography>There are not any inspection assigned to this content.</Typography>
                </div>
              </div>
              {newOption()}
            </>
          ) : (
            <>
              <div className="content">
                {inspections.map((item) => (
                  <div className="option" key={`${v4()}${new Date().getMilliseconds()}`}>
                    <Checkbox
                      color="secondary"
                      checked={false}
                      onClick={() => addInspection({ ...item, projectAsset })}
                    />
                    <Typography>{item.name}</Typography>
                  </div>
                ))}
              </div>
              {newOption()}
            </>
          )}
        </div>
      )}
      <DialogMessage
        title={openDialog}
        isOpen={openDialog}
        confirmText="Ok"
        icon={InfoIcon}
        onConfirm={() => {
          setOpenDialog(false);
        }}
      />
      {action?.value && (
        <SaveInspection
          onCancel={onCancel}
          onConfirm={onCancel}
          action={action.value}
          inspection={action.inspection}
          actionType={action.type}
          objectId={action.objectId}
        />
      )}
      {validateRole.open && (
        <ViewRoleValidation
          action={validateRole.action}
          data={{ id: projectAsset?.project?.id }}
          callBack={validateRole.callBack}
          handleClose={() => setValidateRole({ open: false, callBack: () => {} })}
        />
      )}
      <LoadingOverlay loading={loading} />
    </>
  );
};

import { Button, Grid } from '@mui/material';
import React from 'react';

export const ActionButtons = ({ openAddFields, tab, loading, onCancel, handleSubmit }) => (
  <Grid container spacing={1}>
    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
      <Button color="primary" variant="contained" onClick={onCancel} disabled={loading} fullWidth>
        Cancel
      </Button>
    </Grid>
    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
      <Button color="secondary" variant="contained" disabled={loading} onClick={handleSubmit} fullWidth>
        {!openAddFields || (openAddFields && tab) ? 'Save Inspection' : 'Next'}
      </Button>
    </Grid>
  </Grid>
);

import React, { createContext, useContext, useState } from 'react';

const PACContext = createContext({
  unsavedChanges: false,
  setUnsavedChanges: () => {},
  openUnsavedDialog: false,
  setOpenUnsavedDialog: () => {},
  continueAction: () => {},
  setContinueAction: () => {},
});

export const useProjectAssetContentContext = () => {
  const context = useContext(PACContext);
  if (context === undefined) {
    throw new Error('useProjectAssetContentContext must be used within a ProjectAssetContentContextProvider');
  }
  return context;
};

export const ProjectAssetContentContextProvider = ({ children }) => {
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [openUnsavedDialog, setOpenUnsavedDialog] = useState(false);
  const [continueAction, setContinueAction] = useState(null);

  return (
    <PACContext.Provider
      value={{
        unsavedChanges,
        setUnsavedChanges,
        openUnsavedDialog,
        setOpenUnsavedDialog,
        continueAction,
        setContinueAction,
      }}
    >
      {children}
    </PACContext.Provider>
  );
};

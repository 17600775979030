import React, { useState } from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import * as localForage from 'localforage';

export const ActiveMapGis = ({ on, webmap }) => {
  const [toggle, setToggle] = useState(on);

  const handleClick = async () => {
    setToggle(!toggle);

    // set Map Switch
    await localForage.setItem('webmap', webmap);
  };

  return (
    <FormControlLabel
      control={<Switch color="secondary" />}
      value="isActive"
      onChange={() => handleClick()}
      style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
      checked={toggle}
    />
  );
};

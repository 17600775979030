/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormRadioGroup } from 'components/FormRadioGroup';
import { SidebarPanel, cleanObjectFilters } from 'components/SidebarPanel';
import { useDispatch, useSelector } from 'react-redux';
import { ClearFilterAction } from 'components/SidebarPanel/ClearFilterAction';
import { FormControl, FormLabel, Checkbox, FormControlLabel, RadioGroup, Button, Radio, Stack } from '@mui/material';
import { SearchList } from 'components/SidebarPanel/SearchList/SearchList';
import { CalendarModal } from 'components/CalendarModal';
import { MapExtentModal, distanceInKm } from 'pages/Profile/SingleProject/SearchPanel/MapExtentModal/MapExtentModal';
import ProjectService from 'services/ProjectService';
import { simpleMapSection } from 'components/MapSimplePoint/MapSimplePoint';
import { setProjectAssetInspections } from 'slices/projectAssetActiveInspectionReducer';

export const searchPanelSection = {
  PROFILE: 'profile',
  PROJECT: 'project',
  INSPECTIONS: 'inspections',
};

export const SearchPanelBody = ({ loaded, filters, users, assets, inspections, projects, setFilters, section }) => {
  const dispatch = useDispatch();
  const [openCalendar, setOpenCalendar] = useState(false);
  const [modalMapOpen, setModalMapOpen] = useState(false);
  const [extentCoords, setExtentCoords] = useState([]);
  const [auxCoords, setAuxCoords] = useState([]);
  const [filterActions, setFiltersActions] = useState([]);
  const [localFilters, setLocalFilters] = useState({
    sort_by: {},
    ...(section !== searchPanelSection.INSPECTIONS && {
      inspectionIds: '',
      pastIds: '',
      projectIds: '',
      assigneeId: '',
      type: '',
    }),
    date_range: null,
    ...(section === searchPanelSection.INSPECTIONS && { userId: '', filterIsActive: undefined }),
  });
  const { userId } = useSelector((state) => state.auth);
  const projectAssetInspection = useSelector((state) => state.projectAssetActiveInspections.detail);
  const projectAsset = projectAssetInspection?.projectAsset;

  // add assegned to my
  const assignedToMe = (type) => {
    let auxFilters = { ...localFilters };
    if (type) {
      if (
        typeof auxFilters.assigneeId === 'string' ||
        !Array.isArray(auxFilters.assigneeId) ||
        !auxFilters.assigneeId
      ) {
        auxFilters.assigneeId = [userId];
      } else {
        auxFilters = {
          ...auxFilters,
          assigneeId: auxFilters.assigneeId.length ? [...auxFilters.assigneeId, userId] : [userId],
        };
      }
    } else {
      auxFilters.assigneeId = auxFilters.assigneeId.filter((item) => item !== userId);
    }
    setLocalFilters({ ...auxFilters });
  };

  // update checkbox filters
  const checkBoxFilters = (value, checked, filter) => {
    const auxFilters = { ...localFilters };
    if (checked) {
      if (typeof auxFilters[filter] === 'string' || !Array.isArray(auxFilters[filter]) || !auxFilters[filter])
        auxFilters[filter] = [];
      auxFilters[filter] = [...auxFilters[filter]];
      auxFilters[filter].push(value);
    } else {
      auxFilters[filter] = auxFilters[filter].filter((element) => element !== value);
    }
    setLocalFilters({ ...auxFilters });
  };

  // clear sort
  const clearSort = (param) => {
    const auxFilters = { ...localFilters };
    delete auxFilters.sort_by[param];
    auxFilters.sort_by = cleanObjectFilters(auxFilters.sort_by);
    setLocalFilters(auxFilters);
  };

  // update filters
  const updateFIlter = (auxFilters) => {
    setLocalFilters({ ...auxFilters });
  };

  // update radio buttons
  const updateNormalFilters = (value, type) => {
    const auxFilters = { ...localFilters };
    auxFilters[type] = value;
    setLocalFilters({ ...auxFilters });
  };

  // -------------- clear filters
  const handleClearFilters = () => {
    const auxFilters = { filters };
    if (cleanObjectFilters(auxFilters)?.filters) {
      dispatch(
        setFilters({
          sort_by: {},
          ...(section !== searchPanelSection.INSPECTIONS && {
            inspectionIds: '',
            pastIds: '',
            projectIds: '',
            assigneeId: '',
            type: '',
          }),
          date_range: null,
          activated: false,
          ...(section === searchPanelSection.INSPECTIONS && { userId: '', filterIsActive: undefined }),
        })
      );
    }
  };

  const filterAssets = async (limits, defaultAsset, auxFilters) => {
    // send the request to the new service
    // dispatch(setRefetchLoading(true));
    ProjectService.getExtentProjectAssets(
      projectAsset.project?.id,
      limits.minLat,
      limits.minLon,
      limits.maxLat,
      limits.maxLon
    ).then((res) => {
      const idsToExport = [];
      const assetsIds = res.data.data;
      if (assetsIds.length > 0) {
        assetsIds.map((asset) => idsToExport.push(asset.past_id));

        // Get assets data
        dispatch(setFilters({ ...auxFilters, pastIds: idsToExport, activated: filterActions.length > 0 }));
      } else {
        const distanceToMin = distanceInKm(
          projectAsset?.sequence?.spatialPoint?.longitude,
          projectAsset?.sequence?.spatialPoint?.latitude,
          limits.minLon,
          limits.minLat
        );
        const distanceToMax = distanceInKm(
          projectAsset?.sequence?.spatialPoint?.longitude,
          projectAsset?.sequence?.spatialPoint?.latitude,
          limits.maxLon,
          limits.maxLat
        );
        if (distanceToMin >= 10 || distanceToMax >= 10) {
          dispatch(setProjectAssetInspections([]));
        } else {
          dispatch(setProjectAssetInspections([projectAssetInspection]));
        }
      }
    });
  };

  const handleGetExtent = (coords, auxFilters) => {
    if (coords.length > 0) {
      const finalCoords = { minLat: 999, maxLat: -999, minLon: 999999999999999, maxLon: 0 };
      coords.map((coor) => {
        if (coor.lat < finalCoords.minLat) finalCoords.minLat = coor.lat;
        if (coor.lat > finalCoords.maxLat) finalCoords.maxLat = coor.lat;
        if (Math.abs(coor.long) < Math.abs(finalCoords.minLon)) finalCoords.minLon = coor.long;
        if (Math.abs(coor.long) > Math.abs(finalCoords.maxLon)) finalCoords.maxLon = coor.long;
        return coor;
      });
      filterAssets(finalCoords, projectAsset, auxFilters);
    }
  };

  const clearExtent = () => {
    const auxFilters = { ...localFilters };
    auxFilters.getExtent = false;
    auxFilters.pastIds = [];
    setLocalFilters({ ...auxFilters });
    setExtentCoords([]);
  };

  // -------------- apply filters
  const handleApplyFilters = () => {
    const auxFilters = { ...localFilters };
    if (section === searchPanelSection.INSPECTIONS) {
      if (auxFilters.filterIsActive === '' || auxFilters.filterIsActive === null) {
        auxFilters.filterIsActive = undefined;
      }
    }
    auxFilters.sort_by = cleanObjectFilters(auxFilters.sort_by);
    if (auxFilters.getExtent && extentCoords.length > 0) {
      handleGetExtent(extentCoords, auxFilters);
    } else {
      dispatch(setFilters({ ...auxFilters, activated: filterActions.length > 0 }));
    }
  };

  // aplly coordinates get extent
  const setApplyExtentCoords = () => {
    updateNormalFilters(true, 'getExtent');
    setExtentCoords(auxCoords);
    setModalMapOpen(false);
  };

  const createClearFilters = (clearFilters) => {
    const auxFilters = [];
    if (clearFilters.sort_by?.name)
      auxFilters.push({ text: 'Sort By Inspection Name', callBack: () => clearSort('name') });
    if (clearFilters.sort_by?.createdAt)
      auxFilters.push({ text: 'Sort By Created At', callBack: () => clearSort('createdAt') });
    if (clearFilters.getExtent) auxFilters.push({ text: 'Clear By Get Extent', callBack: () => clearExtent() });
    if (clearFilters.date_range !== null && clearFilters.date_range)
      auxFilters.push({ text: 'Clear Date Range', callBack: () => updateNormalFilters(null, 'date_range') });
    if (clearFilters?.type?.length > 0)
      auxFilters.push({ text: 'Clear By Type', callBack: () => updateNormalFilters('', 'type') });
    if (clearFilters?.inspectionId?.length > 0)
      auxFilters.push({ text: 'Clear By Inspection', callBack: () => updateNormalFilters(null, 'inspectionId') });
    if (clearFilters?.projectIds?.length > 0 && section !== searchPanelSection.PROJECT)
      auxFilters.push({ text: 'Clear By Project', callBack: () => updateNormalFilters(null, 'projectIds') });
    if (clearFilters?.pastIds?.length > 0 && !clearFilters.getExtent)
      auxFilters.push({ text: 'Clear By Source', callBack: () => updateNormalFilters(null, 'pastIds') });
    if (clearFilters?.assigneeId?.length > 0 && section !== searchPanelSection.PROFILE)
      auxFilters.push({ text: 'Clear By Assigned', callBack: () => updateNormalFilters(null, 'assigneeId') });
    if (clearFilters?.userId?.length > 0 && section === searchPanelSection.INSPECTIONS)
      auxFilters.push({ text: 'Clear Created By', callBack: () => updateNormalFilters(null, 'userId') });
    if (
      clearFilters.filterIsActive !== null &&
      clearFilters.filterIsActive !== undefined &&
      section === searchPanelSection.INSPECTIONS
    )
      auxFilters.push({
        text: `Clear ${clearFilters.filterIsActive ? 'Open' : 'Closed'}`,
        callBack: () => updateNormalFilters(null, 'filterIsActive'),
      });
    setFiltersActions(auxFilters);
    return auxFilters;
  };

  useEffect(() => {
    createClearFilters(localFilters);
  }, [localFilters]);

  useEffect(() => {
    createClearFilters(filters);
    setLocalFilters(filters);
  }, [filters]);

  return (
    <SidebarPanel
      title="Filters: Inspections"
      subTitle="Apply filters to table data."
      filters
      handleClearFilters={handleClearFilters}
      handleApplyFilters={handleApplyFilters}
    >
      <div className="searchBody">
        <ClearFilterAction actions={filterActions} />
        <FormControl>
          <FormLabel>Sort</FormLabel>
          <FormRadioGroup
            name="alphabetical"
            value={localFilters.sort_by.name}
            onChecked={(checkedVal) => {
              const sortBy = { ...localFilters.sort_by };
              sortBy.name = checkedVal;
              updateNormalFilters(sortBy, 'sort_by');
            }}
            options={[
              { label: 'A - Z', value: '+name', disabled: false },
              { label: 'Z - A', value: '-name', disabled: false },
              { label: 'None', value: '', disabled: false },
            ]}
            view="horizontal"
          />
          <FormRadioGroup
            name="date-by"
            value={localFilters.sort_by.createdAt}
            onChecked={(checkedVal) => {
              const sortBy = { ...localFilters.sort_by };
              sortBy.createdAt = checkedVal;
              updateNormalFilters(sortBy, 'sort_by');
            }}
            options={[
              { label: 'Newest', value: '-createdAt', disabled: false },
              { label: 'Oldest', value: '+createdAt', disabled: false },
              { label: 'None', value: '', disabled: false },
            ]}
            view="horizontal"
          />
        </FormControl>
        <FormControl className="marginTop">
          <Stack spacing={2} direction="row">
            {projectAsset?.id && (
              <Button color="secondary" variant="contained" onClick={() => setModalMapOpen(true)}>
                Get Extent
              </Button>
            )}
            <Button
              color={projectAsset?.id ? 'primary' : 'secondary'}
              variant="contained"
              onClick={() => setOpenCalendar(true)}
            >
              By Date Range
            </Button>
          </Stack>
        </FormControl>

        {section !== searchPanelSection.INSPECTIONS && (
          <>
            <FormControl className="marginTop">
              <FormLabel>File Type</FormLabel>
              <RadioGroup row>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Video"
                  onChange={(event) => checkBoxFilters('VIDEO', event.target.checked, 'type')}
                  checked={localFilters?.type?.length > 0 && localFilters.type.includes('VIDEO')}
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Image"
                  onChange={(event) => checkBoxFilters('PHOTO', event.target.checked, 'type')}
                  checked={localFilters?.type?.length > 0 && localFilters.type.includes('PHOTO')}
                />
              </RadioGroup>
            </FormControl>
            <FormControl className="marginTop">
              <FormLabel>Assignment</FormLabel>
              <RadioGroup row>
                <FormControlLabel
                  control={<Radio />}
                  label="All"
                  onChange={() => assignedToMe(false)}
                  checked={!localFilters.assigneeId?.includes(userId)}
                />
                <FormControlLabel
                  control={<Radio />}
                  label="Assigned to you"
                  onChange={() => assignedToMe(true)}
                  checked={localFilters?.assigneeId?.length > 0 && localFilters.assigneeId?.includes(userId)}
                />
              </RadioGroup>
            </FormControl>
          </>
        )}
        {section === searchPanelSection.INSPECTIONS ? (
          <>
            <FormControl>
              <FormLabel className="marginTop">Open/Closed</FormLabel>
              <FormRadioGroup
                name="open"
                value={localFilters.filterIsActive}
                onChecked={(checkedVal) => updateNormalFilters(checkedVal, 'filterIsActive')}
                options={[
                  { label: 'All', value: null },
                  { label: 'Active', value: true },
                  { label: 'Desactive', value: false },
                ]}
                view="horizontal"
              />
            </FormControl>
            <SearchList
              title="Created By"
              fullList={users}
              filter="userId"
              localFilters={localFilters}
              onChangeSelect={updateFIlter}
              loaded={loaded}
            />
          </>
        ) : (
          <>
            <SearchList
              title="Inspection"
              fullList={inspections}
              filter="inspectionIds"
              localFilters={localFilters}
              onChangeSelect={updateFIlter}
              loaded={loaded}
            />
            {section !== searchPanelSection.PROFILE && (
              <SearchList
                title="Assigned To"
                fullList={users.filter((item) => item.value !== userId)}
                filter="assigneeId"
                localFilters={localFilters}
                onChangeSelect={updateFIlter}
                loaded={loaded}
              />
            )}
            {section !== searchPanelSection.PROJECT && (
              <SearchList
                title="Project"
                fullList={projects}
                filter="projectIds"
                localFilters={localFilters}
                onChangeSelect={updateFIlter}
                loaded={loaded}
              />
            )}
            <SearchList
              title="Source"
              fullList={assets}
              filter="pastIds"
              localFilters={localFilters}
              onChangeSelect={updateFIlter}
              loaded={loaded}
            />
          </>
        )}
        {openCalendar && (
          <CalendarModal
            rangeDate={localFilters.date_range}
            handleClose={() => setOpenCalendar(false)}
            handleConfirm={(rangeDate) => {
              if (rangeDate.length > 0 && rangeDate[0].startDate !== null && rangeDate[0].endDate !== null)
                updateNormalFilters([rangeDate[0].startDate, rangeDate[0].endDate], 'date_range');
              setOpenCalendar(false);
            }}
          />
        )}
        {modalMapOpen && (
          <MapExtentModal
            project={projectAsset.project}
            selectedAsset={projectAsset}
            setApplyExtentCoords={setApplyExtentCoords}
            setAuxCoords={setAuxCoords}
            handleClose={() => setModalMapOpen(false)}
            section={simpleMapSection.PROJACTIVEASSETINSPECTIONS}
          />
        )}
      </div>
    </SidebarPanel>
  );
};

SearchPanelBody.propTypes = {
  loaded: PropTypes.bool,
  filters: PropTypes.object,
  users: PropTypes.array,
  assets: PropTypes.array,
  inspections: PropTypes.array,
  projects: PropTypes.array,
  setFilters: PropTypes.func,
  section: PropTypes.string,
};

SearchPanelBody.defaultProps = {
  loaded: false,
  filters: null,
  users: [],
  assets: [],
  inspections: [],
  projects: [],
  setFilters: () => {},
  section: '',
};

import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'lib/form';
import { Grid, InputLabel, TextField, Typography } from '@mui/material';
import { ObservationMap } from 'components/Observation/ObservationMap';
import { FormTextArea } from 'components/FormTextArea';
import { useStyles } from './styles';

export const DefaultForm = ({ formik, point, handleGetCoordinates, imgHandler, error, finding, action }) => {
  const classes = useStyles();

  return (
    <Grid item md={4} lg={4} xl={4}>
      <div className={classes.container}>
        {finding?.id && (
          <ObservationMap
            observation={finding}
            point={point}
            enableEdit
            handleGetCoordinates={handleGetCoordinates}
            type={action}
            imgHandler={imgHandler}
            findingIcon
          />
        )}
        <Typography color="error" mb={0.5}>
          {error || formik.errors.coordinates}
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <InputLabel>Name Inspection Finding*</InputLabel>
            <TextField
              placeholder="Title"
              value={formik.values.name}
              id="name"
              name="name"
              {...Form.fieldErrorHelper(formik, 'name')}
              helperText={formik.touched.name && formik.errors.name ? formik.errors.name : null}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
              // disabled={loading || !enabledEdit}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>
              <Typography mt={0.5}>Description</Typography>
            </InputLabel>
            <FormTextArea
              placeholder="Description"
              rows="5"
              value={formik.values.description}
              id="description"
              name="description"
              {...Form.fieldErrorHelper(formik, 'description')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              // disabled={loading || !enabledEdit}
              required
            />
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

DefaultForm.propTypes = {
  formik: PropTypes.object.isRequired,
  point: PropTypes.object.isRequired,
  finding: PropTypes.object.isRequired,
  handleGetCoordinates: PropTypes.func.isRequired,
  imgHandler: PropTypes.func.isRequired,
  error: PropTypes.string,
  action: PropTypes.string.isRequired,
};

DefaultForm.defaultProps = {
  error: '',
};

import ApiService from './ApiService';

export default class AccountService {
  static updateRoles = (adminId, { roles }) => ApiService.post(`/account/admins/${adminId}/roles`, { roles });

  static getAccount = async () => ApiService.get('/account');

  // Account SSO Settings
  static getSettings = async () => ApiService.get('/sso/settings');

  static updateSettings = (settings) => ApiService.post(`/sso/settings`, settings);

  static updateAccount = (settings) => ApiService.patch(`/account`, settings);

  static updateAccount = (settings) => ApiService.patch(`/account`, settings);

  static updateCredits = (credits) => ApiService.patch(`/account/updateCredits`, { creditLimit: credits });

  /**
   * Update the account level roles for a user
   *
   * @param {string} userId
   * @param {number[]} roles
   * @returns
   */
  static updateAccountUserRoles = async (userId, roles) =>
    ApiService.post(`/account/admins/${userId}/roles`, { roles });
}

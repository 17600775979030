import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ProjectService from 'services/ProjectService';
import { formatDateRange, formatSortBy } from '../helpers';

export const fetchProjects = createAsyncThunk('project-search', async ({ filters }, { dispatch, getState }) => {
  if (filters.search.length < 3) {
    // clear state

    // eslint-disable-next-line no-use-before-define
    dispatch(reset(true));
    return null;
  }

  const projectFilters = {
    search: filters.search,
    strict: true,
    ...formatDateRange(filters.date_range),
    ...formatSortBy(filters.sort_by),
    // format map_extent TBD
    ...getState().search.projects.filters,
    include: ['collaborators'],
  };

  const result = await ProjectService.getProjects(projectFilters);

  return result.data;
});

const initialState = {
  data: [],
  totalCount: 0,
  loading: true,
  error: null,
  filters: {
    page: 1,
    page_size: 100,
  },
};

const searchProjectsSlice = createSlice({
  name: 'search-projects',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading;
    },
    reset: (state) => {
      state.data = [];
      state.totalCount = 0;
      state.loading = false;
      state.error = null;
      state.filters = {
        ...initialState.filters,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjects.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchProjects.fulfilled, (state, { payload }) => {
      if (payload) {
        state.data = payload.data;
        state.totalCount = payload.meta.totalCount;
        state.loading = false;
      }
    });

    builder.addCase(fetchProjects.rejected, (state) => {
      state.error = 'Error fetching projects';
      state.totalCount = 0;
      state.data = [];
      state.loading = false;
    });
  },
});

export const { setLoading, reset } = searchProjectsSlice.actions;

export default searchProjectsSlice.reducer;

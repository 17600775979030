import { Typography } from 'components/Typography/Typography';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing(1)};
  color: ${(props) => (props.complete ? '#cfcfcf' : '#6B6B6B')};
  &:not(:last-of-type) {
    margin-bottom: ${(props) => props.theme.spacing(1)};
  }
`;

export const StyledIconContainer = styled.div`
  flex: 0 0 24px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

export const StyledTitleContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
`;

export const StyledProgressContainer = styled.div`
  flex: 0 0 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
`;

export const StyledButtonContainer = styled.div`
  flex: 0 0 24px;
  display: flex;
`;

export const StyledItemButton = styled.button`
  width: 24px;
  height: 24px;
  padding-top: 3px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  color: ${(props) => (props.complete ? props.theme.palette.success.base : '#6b6b6b')};
  cursor: pointer;
  &:hover {
    border-color: #3e3e3e;
  }
`;

export const StyledUploadCompleteText = styled(Typography)`
  color: ${(props) => props.theme.palette.success.base};
  line-height: 24px;
  font-weight: 700;
  display: block;
  width: 100%;
  text-align: right;
`;

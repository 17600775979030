import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import OrganizationService from 'services/OrganizationService';
import { getOrganizations } from 'slices/organizationReducer';
import * as Yup from 'yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@mui/material';
import { ModalHeaderIcon } from 'components/ModalHeaderIcon/ModalHeaderIcon';
import { GridIcon, InfoIcon } from 'components/Icons';
import { DialogMessage } from 'components/DialogMessage';

export const ModalOrganizationNew = ({ handleOpen, isOpen }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [submitError, setSubmitError] = React.useState('');
  const [openDialog, setOpenDialog] = React.useState(false);

  // const today = Moment(Date.now()).format('MMM Do YYYY');
  const { filters } = useSelector((state) => state.organizations);
  const loadData = () => {
    const sendFilters = {
      ...filters,
      sort_by: Object.values(filters.sort_by),
    };
    dispatch(getOrganizations(sendFilters));
  };

  const closeConfirm = () => {
    handleOpen(false);
    loadData();
  };

  const saveOrganization = async (values) => {
    setLoading(true);
    const data = {
      name: values.orgName,
      description: values.orgDesc,
    };

    try {
      await OrganizationService.saveOrganization(data);
      setLoading(false);
      setOpenDialog('Organization was successfully created');
    } catch (err) {
      const errorResponse = err.response.data.message;
      setSubmitError(errorResponse);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      orgName: '',
      orgDesc: '',
      team: [],
    },
    validationSchema: Yup.object({
      orgName: Yup.string()
        .trim()
        .matches(/^[A-Za-z0-9.\s]+$/, 'Org Name can only contain letters, numbers, and dots')
        .min(1, 'Org Name must contain at least one character')
        .max(40)
        .required('Org Name Required'),
      orgDesc: Yup.string()
        .trim()
        .matches(/^[A-Za-z0-9.\s]+$/, 'Description can only contain letters, numbers, and dots')
        .min(1, 'Description must contain at least one character')
        .max(255)
        .required('Description Required'),
    }),
    onSubmit(values) {
      saveOrganization(values);
    },
  });

  const { values, handleChange, handleBlur, handleSubmit } = formik;

  return (
    <Dialog open={isOpen} onClose={() => handleOpen(false)} maxWidth="xs">
      <DialogTitle>
        <ModalHeaderIcon icon={GridIcon} text="Create Org" />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <InputLabel>Name *</InputLabel>
            <TextField
              placeholder="Current Name"
              disabled={loading}
              id="orgName"
              name="orgName"
              value={values.orgName}
              error={!!(formik.touched.orgName && formik.errors.orgName)}
              helperText={formik.touched.orgName && formik.errors.orgName ? formik.errors.orgName : null}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Description *</InputLabel>
            <TextField
              placeholder="New Org Description"
              disabled={loading}
              id="orgDesc"
              name="orgDesc"
              value={values.orgDesc}
              error={!!(formik.touched.orgDesc && formik.errors.orgDesc)}
              helperText={formik.touched.orgDesc && formik.errors.orgDesc ? formik.errors.orgDesc : null}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              fullWidth
            />
          </Grid>
          {submitError && (
            <Grid item xs={12}>
              <FormControl error fullWidth>
                {submitError && <FormHelperText>{submitError}.</FormHelperText>}
              </FormControl>
            </Grid>
          )}
        </Grid>
        <DialogMessage
          title="Success"
          content="The organization has been created successfully."
          isOpen={openDialog}
          icon={InfoIcon}
          confirmText="Ok"
          onConfirm={closeConfirm}
        />
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button color="primary" variant="contained" onClick={() => handleOpen(false)} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button color="secondary" variant="contained" disabled={loading} onClick={handleSubmit} fullWidth>
              Create
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

ModalOrganizationNew.propTypes = {
  isOpen: PropTypes.bool,
  handleOpen: PropTypes.func.isRequired,
};

ModalOrganizationNew.defaultProps = {
  isOpen: false,
};

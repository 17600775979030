import React, { useContext, useEffect } from 'react';
import { SidebarContext } from 'components/SidebarProvider/SidebarProvider';
import { useDispatch, useSelector } from 'react-redux';
import { preloadExternalFilterDatasets, setExternalFilterValue, setExternalFilters } from 'slices/externalUserReducer';
import { preloadFilterDatasets, setFilterValue, setFilters } from 'slices/userReducer';
import { SearchPanelBody, searchPanelSection } from './SearchPanelBody';

export const SearchPanel = () => {
  const dispatch = useDispatch();
  const ctx = useContext(SidebarContext);
  const external = ctx?.config?.search?.external;
  const reduce = external ? 'externalUsers' : 'users';
  const nameSort = useSelector((state) => state[reduce].filters.sort_by.firstName || '');
  const dateSort = useSelector((state) => state[reduce].filters.sort_by.createdAt || '');
  const acctId = useSelector((state) => state[reduce].filters.acctId || '');
  const organizationId = useSelector((state) => state[reduce].filters.organizationId || '');
  const teamId = useSelector((state) => state[reduce].filters.teamId || '');
  const projectId = useSelector((state) => state[reduce].filters.projectId || '');
  const { teams, accounts, organizations, projects, loaded } = useSelector((state) => state[reduce].panels.search);

  useEffect(() => {
    dispatch(external ? preloadExternalFilterDatasets() : preloadFilterDatasets());
  }, []);

  return (
    <SearchPanelBody
      loaded={loaded}
      teams={teams}
      projects={projects}
      accounts={accounts}
      organizations={organizations}
      filters={{
        sort_by: {
          firstName: nameSort,
          createdAt: dateSort,
        },
        acctId,
        organizationId,
        teamId,
        projectId,
      }}
      setFilterValue={external ? setExternalFilterValue : setFilterValue}
      setFilters={external ? setExternalFilters : setFilters}
      section={external ? searchPanelSection.EXTERNALUSERS : searchPanelSection.ADMINUSERS}
    />
  );
};

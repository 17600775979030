/* eslint-disable no-case-declarations */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useFormik } from 'formik/dist';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Autocomplete,
  TextField,
} from '@mui/material';
import tokml from '@maphubs/tokml';
import GeoJSON from 'geojson';
import exportFromJSON from 'export-from-json';
import moment from 'moment';
import { pdf } from '@react-pdf/renderer';
import { ModalHeaderIcon } from 'components/ModalHeaderIcon/ModalHeaderIcon';
import { Time } from 'lib/time';
import { InfoIcon, SquareArrow } from 'components/Icons';
import InspectionService from 'services/InspectionService';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import { DialogMessage } from 'components/DialogMessage';
import { PDFDocument } from 'components/Observation/ObservationExport/PDFDocument';
import { DisclaimerExporting } from 'components/DisclaimerExporting/DisclaimerExporting';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import JSZipUtils from 'jszip-utils';
import exifr from 'exifr';

const validationSchema = Yup.object({
  type: Yup.string().min(1).required('Type is required'),
  inspections: Yup.array().min(1).required('Inspections are required'),
});

const options = [
  { value: 'csv', label: 'CSV' },
  { value: 'kml', label: 'KML' },
  { value: 'pdf', label: 'PDF' },
  { value: 'json', label: 'JSON' },
  { value: 'xml', label: 'XML' },
];

export const ExportMultipleFindings = ({ projectName, projectAssestInspections, handleClose }) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState('');
  const [inspections, setInspections] = useState([{}]);

  const addInspections = (projectAssetInpections) => {
    const groupedInspections = projectAssetInpections.reduce((acc, projAssInspection) => {
      if (!acc[projAssInspection.inspection.id]) {
        acc[projAssInspection.inspection.id] = {
          id: projAssInspection.inspection.id,
          name: projAssInspection.inspection.name,
          quoestions: projAssInspection.inspection.questions,
          assets: [],
        };
      }
      acc[projAssInspection.inspection.id].assets.push(projAssInspection.id);
      return acc;
    }, {});
    setInspections(Object.values(groupedInspections));
  };

  const getShapeFromImage = async (url) => {
    try {
      const metadata = await exifr.parse(url);
      return {
        Xmin: metadata.Xmin,
        Xmax: metadata.Xmax,
        Ymin: metadata.Ymin,
        Ymax: metadata.Ymax,
      };
    } catch (e) {
      return {
        Xmin: 0,
        Xmax: 0,
        Ymin: 0,
        Ymax: 0,
      };
    }
  };

  const createZip = async (file, findings) => {
    const zip = new JSZip();

    zip.file(file.name, file.data);
    const imageFolder = zip.folder('images');
    const promises = findings.map((finding) => {
      const filename = `${finding.id}.png`;
      return new Promise((resolve, reject) => {
        JSZipUtils.getBinaryContent(finding.signedUrl, (err, data) => {
          if (err) {
            reject(err);
          } else {
            imageFolder.file(filename, data, { binary: true });
            resolve();
          }
        });
      });
    });

    // Wait for all promises to resolve
    await Promise.all(promises);
    return zip.generateAsync({ type: 'blob' });
  };

  const PDFContent = (fileName, findings) => <PDFDocument fileName={fileName} observations={findings} />;

  const findingFormat = (values, findings) =>
    [...findings].map((item) => {
      const sequenceId = `&sequenceId=${
        item.projectAssetSequence?.asset?.type !== 'VIDEO' ? item.pasqId : +item.time - 1
      }`;
      const url = `https://${window.location.hostname}/projects?projectId=${item.projectId || item.projectId}&pastId=${
        item.projectAssetId
      }&findingId=${item.id}${sequenceId}`;
      let object = {};
      item.responses.forEach((element) => {
        if (!element.deletedAt) {
          object = { ...object, [element.name]: element.response ? element.response.toString() : '' };
        }
      });
      return {
        [values.type === 'kml' ? 'name' : 'Name']: item.name,
        ...(values.type !== 'kml' && { Description: item.description }),
        ...(values.type === 'kml' && {
          Description: item.description,
          'Launch in CartoVid': `<![CDATA[<a href="${url}">Click to Open Finding</a>]]>`,
          Image: `<![CDATA[<img src="${item.signedUrl}">]]>`,
        }),
        ...object,
        Ymin: item.Ymin,
        Ymax: item.Ymax,
        Xmin: item.Xmin,
        Xmax: item.Xmax,
        'Date Created': moment(item.createdAt).format('llll'),
        'Date Updated': moment(item.updatedAt).format('llll'),
        'Timeline Marker': Time.humanReadable({ seconds: +item.time + 1 }),
        Latitude: item.lat.toFixed(5),
        Longitude: item.lng.toFixed(5),
        ...(values.type !== 'kml' && {
          'Launch in CartoVid': url,
          Img: item.signedUrl,
        }),
      };
    });

  const exportFindings = async (selectedInspections) =>
    InspectionService.getProjectAssetInspectionFindings(null, {
      paiId: selectedInspections.reduce((acc, item) => [...acc, ...item.assets], []),
    })
      .then((res) => {
        let results = { ...res };
        if (!results?.data?.data?.length) {
          setIsLoading(false);
          return [];
        }
        results = results?.data?.data?.map((item) => ({
          ...item,
          responses: JSON.parse(item.responses),
        }));
        results = results.map((finding) => {
          const auxFinding = { ...finding };
          const auxInspection = { ...projectAssestInspections.find((item) => item.id === finding.paiId).inspection };
          try {
            auxInspection.questions = JSON.parse(auxInspection.questions);
          } catch (e) {
            auxInspection.questions = [];
          }
          auxFinding.responses = finding.responses.map((response) => {
            const object = { ...response };
            auxInspection.questions.forEach((question) => {
              if (question.id === response.id) {
                object.name = question.name;
                object.description = question.description;
                object.deletedAt = question.deletedAt;
              }
            });
            return object;
          });
          const notExist = auxInspection.questions
            .filter((item1) => !auxFinding.responses.some((item2) => item2.id === item1.id))
            .filter((item) => !item.deletedAt);
          notExist.forEach((element) => {
            auxFinding.responses.push({
              id: element.id,
              name: element.name,
              description: element.description,
              value: '',
            });
          });
          auxFinding.projectAssetId = finding.projectAssetInspection?.projectAsset?.id;
          auxFinding.projectId = finding.projectAssetInspection?.projectAsset?.projId;

          return auxFinding;
        });

        return Promise.all(
          results.map((finding) => getShapeFromImage(finding.signedUrl).then((result) => ({ ...finding, ...result })))
        )
          .then((values) => values)
          .catch((reason) => {
            console.error(reason);
            setIsLoading(false);
          });
      })
      .catch(() => {
        setIsLoading(false);
        setOpenDialog('An error occurred while trying to export the findings.');
        return [];
      });

  const saveFile = async (data, filename, imageFindings) =>
    createZip(
      {
        name: filename,
        data,
      },
      imageFindings
    ).then((content) => {
      saveAs(content, `${filename}.zip`);
      setTimeout(() => {
        handleClose();
      }, 2000);
      setIsLoading(false);
    });

  const exportFunction = async (values) => {
    const filename = projectName;
    setIsLoading(true);
    exportFindings(values.inspections)
      .then((findings) => {
        if (!findings.length) {
          setOpenDialog('There are no findings at this current inspection.');
          return;
        }

        switch (values.type) {
          case 'pdf':
            pdf(PDFContent(filename, findings))
              .toBlob()
              .then((res) => saveFile(res, `${filename}.pdf`, findings));
            break;
          case 'csv':
            const auxFindings = findingFormat(values, findings);
            auxFindings.unshift({ 'Date Exported': moment().format('llll') });
            exportFromJSON({
              data: auxFindings,
              filename,
              exportType: values.type,
              processor: async (content, type, fileName) => saveFile(content, fileName, findings),
            });
            break;
          case 'kml':
            const geojson = GeoJSON.parse(findingFormat(values, findings), {
              Point: ['Latitude', 'Longitude'],
            });
            const featuresWithHtml = geojson.features.map((feature) => ({
              ...feature,
              properties: {
                ...feature.properties,
              },
            }));
            let kml = tokml(
              { ...geojson, features: featuresWithHtml },
              {
                documentName: filename,
                documentDescription: `Findings - Date Exported: ${moment().format('llll')}`,
                name: 'name',
              }
            );
            kml = kml.replaceAll('&quot;', '"');
            kml = kml.replaceAll('&lt;', '<');
            kml = kml.replaceAll('&lt;', '<');
            kml = kml.replaceAll('&gt;', '>');
            const file = new Blob([kml], { type: 'kml' });
            saveFile(file, `${filename}.kml`, findings);
            break;
          case 'json':
            const auxFindingsJson = findingFormat(values, findings);
            auxFindingsJson.unshift({ 'Date Exported': moment().format('llll') });
            exportFromJSON({
              data: auxFindingsJson,
              filename,
              exportType: values.type,
              processor: async (content, type, fileName) => saveFile(content, fileName, findings),
            });
            break;
          case 'xml':
            const auxFindingsXml = findingFormat(values, findings);
            auxFindingsXml.unshift({ 'Date Exported': moment().format('llll') });
            exportFromJSON({
              data: auxFindingsXml,
              filename,
              exportType: values.type,
              processor: async (content, type, fileName) => saveFile(content, fileName, findings),
            });
            break;
          default:
            break;
        }
      })
      .catch((err) => console.error(err));
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      type: '',
    },
    validationSchema,
    onSubmit(values) {
      exportFunction(values);
    },
  });

  const { handleSubmit } = formik;

  useEffect(() => {
    setOpen(true);
    setIsLoading(false);
    addInspections(projectAssestInspections);
  }, []);

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle>
          <ModalHeaderIcon icon={SquareArrow} text="Export to File" />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <InputLabel>Select File type *</InputLabel>
              <FormControl error={!!(formik.touched.type && formik.errors.type)} fullWidth>
                <Select
                  name="type"
                  onChange={(e) => formik.handleChange('type')(e.target.value)}
                  required
                  size="small"
                  fullWidth
                >
                  {options.map((item) => (
                    <MenuItem value={item.value} key={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{formik.touched.type && formik.errors.type ? formik.errors.type : null}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Select Inspections *</InputLabel>
              <FormControl error={!!(formik.touched.inspections && formik.errors.inspections)} fullWidth>
                <Autocomplete
                  multiple
                  id="multiple-limit-tags"
                  name="inspections"
                  onChange={(e, value) => {
                    formik.setFieldValue('inspections', value);
                  }}
                  required
                  size="small"
                  fullWidth
                  getOptionLabel={(option) => option.name}
                  options={inspections}
                  renderInput={(params) => <TextField key={params.id} {...params} />}
                />
                <FormHelperText>
                  {formik.touched.inspections && formik.errors.inspections ? formik.errors.inspections : null}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button color="primary" variant="contained" onClick={handleClose} size="small" fullWidth>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                color="secondary"
                variant="contained"
                onClick={handleSubmit}
                size="small"
                fullWidth
                disabled={isLoading}
              >
                Export
              </Button>
            </Grid>
            <Grid item xs={12}>
              <DisclaimerExporting />
            </Grid>
          </Grid>
        </DialogActions>
        <LoadingOverlay loading={isLoading} />
      </Dialog>
      <DialogMessage
        title={openDialog}
        isOpen={!!openDialog}
        icon={InfoIcon}
        confirmText="Ok"
        onConfirm={() => {
          if (!open) {
            handleClose();
          }
          setOpenDialog(false);
        }}
      />
    </>
  );
};

ExportMultipleFindings.propTypes = {
  projectAssestInspections: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
};

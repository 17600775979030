import React from 'react';
import { Card, CardContent, Typography, Button } from '@mui/material';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DeleteIcon } from 'components/Icons/DeleteIcon';
import { ArrowTopRight } from 'components/Icons';
import { ActiveMapGis } from '../ActiveMapGis/ActiveMapGis';
// import { Map } from './Map';
import { MapViewComponent } from 'pages/Admin/MapGis/MapViewComponent';
import { InfoDialog } from './InfoDialog';
import { useStyles } from './styles';

export const MapCard = ({ data, handleEdit }) => {
  const classes = useStyles();

  return (
    <Card className={classes.container}>
      <CardContent className={classes.cardContent}>
        <div className={classes.header}>
          <Typography>{data.mapName}</Typography>
          <div className="icons">
            <ActiveMapGis on={data.mapLayerOn} webmap={data} />
            <InfoDialog />
          </div>
        </div>
        <div className={classes.mapBtnContainer}>
          <MapViewComponent mapData={data} webMapId={data.arcgisMapId} />
          <div className={classes.actions}>
            <Button size="small" color="primary" variant="contained">
              <DeleteIcon size={18} />
            </Button>
            <Button size="small" color="primary" variant="contained" onClick={() => handleEdit(data)}>
              <Typography mr={0.5}>Edit</Typography> <ArrowTopRight size={14} />
            </Button>
          </div>
        </div>
        <div className={classes.description}>
          <Typography>{data.mapDescription}</Typography>
          <Typography>{moment(data?.createdAt).format('MM/DD/YY')}</Typography>
        </div>
      </CardContent>
    </Card>
  );
};

MapCard.propTypes = {
  data: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormRadioGroup } from 'components/FormRadioGroup';
import { SidebarPanel, cleanObjectFilters } from 'components/SidebarPanel';
import { Button, FormControl, FormLabel } from '@mui/material';
import { ClearFilterAction } from 'components/SidebarPanel/ClearFilterAction';
import { SearchList } from 'components/SidebarPanel/SearchList/SearchList';
import { setFilters } from 'slices/observationsReducer';
import { CalendarModal } from 'components/CalendarModal';

export const ObservationSearch = () => {
  const dispatch = useDispatch();
  const [openCalendar, setOpenCalendar] = useState(false);
  const filters = useSelector((state) => state.assetObservations.filters);
  const { projectObservations } = useSelector((state) => state.assetObservations);
  const nameSort = useSelector((state) => state.assetObservations.filters.sort_by.name || '');
  const assetNameSort = useSelector((state) => state.assetObservations.filters.sort_by.assetName || '');
  const assetId = useSelector((state) => state.assetObservations.filters.assetId || '');
  const dateRange = useSelector((state) => state.assetObservations.filters.date_range);
  const [filterActions, setFiltersActions] = useState([]);
  const [localFilters, setLocalFilters] = useState({
    sort_by: {},
    assetId: '',
    date_range: null,
  });

  let assets = [...new Set(projectObservations.map((item) => item.asset))].map((item) => ({
    value: item.id,
    label: item.name,
  }));
  assets = [...new Map(assets.map((item) => [item.value, item])).values()].sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  // clear sort
  const clearSort = (param) => {
    const auxFilters = { ...localFilters };
    delete auxFilters.sort_by[param];
    auxFilters.sort_by = cleanObjectFilters(auxFilters.sort_by);
    setLocalFilters(auxFilters);
  };

  // update filters
  const updateFIlter = (auxFilters) => {
    setLocalFilters({ ...auxFilters });
  };

  // update radio buttons
  const updateNormalFilters = (value, type) => {
    const auxFilters = { ...localFilters };
    auxFilters[type] = value;
    setLocalFilters({ ...auxFilters });
  };

  // -------------- clear filters
  const handleClearFilters = () => {
    const auxFilters = { filters };
    if (cleanObjectFilters(auxFilters)?.filters) {
      dispatch(
        setFilters({
          sort_by: {},
          assetId: '',
          activated: false,
        })
      );
    }
  };

  // -------------- apply filters
  const handleApplyFilters = () => {
    const auxFilters = { ...localFilters };
    auxFilters.sort_by = cleanObjectFilters(auxFilters.sort_by);
    dispatch(setFilters({ ...auxFilters, activated: filterActions.length > 0 }));
  };

  const createClearFilters = (clearFilters) => {
    const auxFilters = [];
    if (clearFilters.sort_by?.name) auxFilters.push({ text: 'Sort By Name', callBack: () => clearSort('name') });
    if (clearFilters.sort_by?.assetName)
      auxFilters.push({ text: 'Sort By Source', callBack: () => clearSort('assetName') });
    if (clearFilters?.date_range?.length > 0)
      auxFilters.push({ text: 'Clear Date Range', callBack: () => updateNormalFilters(null, 'date_range') });
    if (clearFilters?.assetId?.length > 0)
      auxFilters.push({ text: 'Clear By Source', callBack: () => updateNormalFilters(null, 'assetId') });
    setFiltersActions(auxFilters);
    return auxFilters;
  };

  useEffect(() => {
    createClearFilters(localFilters);
  }, [localFilters]);

  useEffect(() => {
    createClearFilters(filters);
    setLocalFilters({
      sort_by: {
        name: nameSort,
        assetName: assetNameSort,
      },
      assetId,
      date_range: dateRange,
    });
  }, [filters]);

  return (
    <SidebarPanel
      title="Filter Observations"
      subTitle="Apply filters to table data."
      filters
      handleClearFilters={handleClearFilters}
      handleApplyFilters={handleApplyFilters}
    >
      <div className="searchBody">
        <ClearFilterAction actions={filterActions} />
        <FormControl>
          <FormLabel>Sort</FormLabel>
          <FormRadioGroup
            name="name"
            value={localFilters.sort_by.name}
            onChecked={(checkedVal) => {
              const sortBy = { ...localFilters.sort_by };
              sortBy.name = checkedVal;
              updateNormalFilters(sortBy, 'sort_by');
            }}
            options={[
              { label: 'A - Z', value: '+', disabled: false },
              { label: 'Z - A', value: '-', disabled: false },
              { label: 'None', value: '', disabled: false },
            ]}
            view="horizontal"
          />
          <FormRadioGroup
            name="createdAt"
            value={localFilters.sort_by.assetName}
            onChecked={(checkedVal) => {
              const sortBy = { ...localFilters.sort_by };
              sortBy.assetName = checkedVal;
              updateNormalFilters(sortBy, 'sort_by');
            }}
            options={[
              { label: 'A - Z', value: '+', disabled: false },
              { label: 'Z - A', value: '-', disabled: false },
              { label: 'Source None', value: '', disabled: false },
            ]}
            view="horizontal"
          />
        </FormControl>
        <Button color="secondary" variant="contained" onClick={() => setOpenCalendar(true)} className="marginTop">
          By Date Ranges
        </Button>
        <SearchList
          title="Source"
          fullList={assets}
          filter="assetId"
          localFilters={localFilters}
          onChangeSelect={updateFIlter}
          loaded
        />
        {openCalendar && (
          <CalendarModal
            rangeDate={localFilters.date_range}
            handleClose={() => setOpenCalendar(false)}
            handleConfirm={(rangeDate) => {
              if (rangeDate.length > 0 && rangeDate[0].startDate !== null && rangeDate[0].endDate !== null)
                updateNormalFilters([rangeDate[0].startDate, rangeDate[0].endDate], 'date_range');
              setOpenCalendar(false);
            }}
          />
        )}
      </div>
    </SidebarPanel>
  );
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ProjectService from 'services/ProjectService';
import localforage from 'localforage';
import { MapSimple } from 'components/MapSimple/MapSimple';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import { useDispatch } from 'react-redux';
import { setObservations } from 'slices/observationsReducer';
import { setProjectAsset } from 'slices/profileProjectAssetReducer';

export const MapContainer = ({ assetId, projectData, handleChange }) => {
  const [pointsData, setPointsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  let cd = [];

  // ---------------------------------- get asset data from API
  const getMapDataFromApi = async (project, asset) => {
    const x = await ProjectService.getProjectAsset(project, asset);
    return x;
  };

  // ---------------------------------- get observation from API
  // eslint-disable-next-line no-unused-vars
  const getObservationsFromApi = async () => {
    if (assetId != null) {
      const x = await ProjectService.getAssetObservations(projectData.id, assetId);
      return x;
    }
    return [];
  };

  const procesData = (response, observationsList, flagObservations, flagPoints) => {
    setLoading(false);
    // eslint-disable-next-line
    const sequences = response.data.data.sequences;
    let auxObservations = [];
    if (sequences && sequences.length > 1) {
      sequences.map((sequence) => {
        // ---------------- iterate observations
        if (flagObservations && flagPoints) {
          sequence.observations.forEach((item) => {
            const obj = { ...item };
            obj.sequence = sequence;
            obj.projectId = response.data.data.project.id;
            auxObservations.push(obj);
          });
        }
        let spatialPoints3 = sequence.spatialPoints;
        // eslint-disable-next-line
        if (spatialPoints3 && spatialPoints3.length > 0) {
          spatialPoints3 = spatialPoints3.sort((a, b) => a.longitude > b.longitude);
          const a = spatialPoints3.map((item) => ({
            id: item.time !== -1 ? item.time : sequence.ordinal,
            sequenceIndex: sequence.ordinal,
            time: item.time,
            location: [item.longitude, item.latitude],
            name: '',
            description: '',
            url: response.config.url ? response.config.url : '',
            assetId: sequence.assetId,
            sequence,
          }));
          cd.push(a[0]);
        }
        return spatialPoints3;
      });
    } else {
      // ---------------- iterate observations
      if (flagObservations && flagPoints) {
        sequences[0].observations.forEach((item) => {
          const obj = { ...item };
          const sequence = { ...sequences[0] };
          obj.sequence = sequence;
          obj.projectId = response.data.data.project.id;
          auxObservations.push(obj);
        });
      }
      // eslint-disable-next-line
      const spatialPoints3 = sequences[0].spatialPoints;
      // eslint-disable-next-line
      if (spatialPoints3) {
        cd = spatialPoints3.map((item) => ({
          id: item.time,
          sequenceIndex: 0,
          time: item.time,
          location: [item.longitude, item.latitude],
          name: '',
          description: '',
          url: response.config.url,
          assetId: sequences[0].assetId,
          sequence: sequences[0],
        }));
      }
    }
    // ---------------------- iterate observations from API
    if (flagObservations && observationsList && observationsList.length > 0) {
      auxObservations = [];
      observationsList.forEach((observation) => {
        const obj = { ...observation };
        cd.forEach((point) => {
          if (observation.projectAssetSequenceId === point.sequence.id) {
            obj.sequence = point.sequence;
            obj.projectId = response.data.data.project.id;
          }
        });
        auxObservations.push(obj);
      });
    }
    // ------------------ validate array changes from callback
    // if (flagObservations) dispatch(setObservations(auxObservations));
    cd = cd.sort((a, b) => a.id - b.id);
    if (flagPoints) setPointsData(cd);
  };

  const getMapData = (loadData) => {
    const ab = loadData;
    let mapdata = null;
    if (ab && ab.length > 0) {
      localforage
        .getItem(ab[0].assetId) // get the asset data
        .then((value) => {
          if (!value || value === null) {
            // ----------------- get data from API
            setLoading(true);
            // if there is not data get it from the api and save it
            mapdata = getMapDataFromApi(projectData.id, ab[0].assetId);
            dispatch(setProjectAsset(mapdata?.data?.data));
            mapdata.then((val) => {
              localforage.setItem(ab[0].assetId, JSON.stringify(val));
              procesData(val, [], true, true);
            });
          } else {
            // --------------------------- get spatial points from localstorage
            const response = JSON.parse(value);
            dispatch(setProjectAsset(response?.data?.data));
            procesData(response, [], false, true);
            // --------------------------- get observation from API
            // dispatch(setIsObservationLoading(true));
            // const auxObservations = getObservationsFromApi();
            // auxObservations.then((val) => {
            //   dispatch(setIsObservationLoading(false));
            //   if (val?.data?.data) {
            //     procesData(response, val.data.data, true, false);
            //   }
            // });
          }
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.error(e);
        });
    }
  };

  useEffect(() => {
    dispatch(setObservations([]));
    localforage
      .getItem('data')
      .then((value) => {
        let dd = JSON.parse(value);
        if (assetId)
          dd = dd
            .filter((proj) => proj.uploadComplete === true && proj.id === assetId)
            .map((proj) => ({ projId: proj.projId, assetId: proj.id }));
        else
          dd = dd
            .filter((proj) => proj.uploadComplete === true)
            .map((proj) => ({ projId: proj.projId, assetId: proj.id }));
        if (dd.length === 0 && assetId && projectData?.id) {
          dd = [{ projId: projectData.id, assetId }];
        }
        getMapData(dd);
      })
      .catch((e) => {
        // eslint-disable-next-line
        console.error(e);
      });
    return () => {
      setPointsData([]);
    };
  }, [assetId, projectData]);

  return (
    <>
      <div style={{ height: '60vh', width: '100%', borderRadius: '8px', overflow: 'hidden' }}>
        {pointsData.length > 0 && (
          <div style={{ height: '100%', marginBottom: '0', position: 'sticky', zIndex: '79', top: '0' }}>
            <MapSimple
              points={pointsData}
              onClickGetExtent={handleChange}
              assetId={assetId}
              projectData={projectData}
            />
          </div>
        )}
      </div>
      <LoadingOverlay loading={loading} />
    </>
  );
};

MapContainer.propTypes = {
  assetId: PropTypes.arrayOf(PropTypes.number),
  // eslint-disable-next-line react/forbid-prop-types
  projectData: PropTypes.object,
  handleChange: PropTypes.func,
};

MapContainer.defaultProps = {
  assetId: null,
  projectData: { id: null, orgId: null, name: '' },
  handleChange: () => {},
};

import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  text: {
    fontWeight: 400,
  },
  content: {
    height: '100%',
    width: '100%',
    overflowY: 'auto',
    padding: `${theme.spacing(0)} ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)}`,
  },
  imageContent: {
    position: 'relative',
    marginBottom: theme.spacing(3),
  },
  image: {
    position: 'absolute',
    width: 240,
    top: '80%',
    left: theme.spacing(1),
    borderRadius: '8px',
  },
  buttonContent: {
    width: '100%',
    textAlign: 'right',
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'end',
    gap: '8px',
    '& button svg': {
      marginRight: '8px',
    },
  },
  observationText: {
    color: theme.palette.primary[400],
    fontWeight: 400,
  },
});

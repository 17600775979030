import React, { useState } from 'react';
import moment from 'moment';
import { Button } from 'components/Button';
import PropTypes from 'prop-types';
import ProjectService from 'services/ProjectService';
import { checkIsImage, checkIsVideo } from 'lib/content-helpers';
import { DoubleCheckIcon, InfoIcon, PlusIcon } from 'components/Icons';
import { DialogMessage } from 'components/DialogMessage';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import { FileDropZone } from 'components/FileDropZone/FileDropZone';
import { useProjectAssetUploader } from 'hooks/use-project-asset-uploader';
import EnumService from 'services/EnumService';
import { Typography } from 'components/Typography';
import { IconButton, Dialog, DialogActions, DialogContent } from '@mui/material';

let fileId = 1;

export const AddButton = ({ assetId, projId, loading, icon, handleClose }) => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const selected = 'series';
  const [files, setFiles] = React.useState([]);
  const [cameraTypes, setCameraTypes] = React.useState([]);
  const uploadAssets = useProjectAssetUploader({ projId });

  React.useEffect(() => {
    const fetchContentCameras = async () => {
      const result = await EnumService.getContentCameras();
      const cameraTypesResults = result.data.data;
      setCameraTypes(cameraTypesResults);
    };

    fetchContentCameras();
  }, []);

  const onSave = async () => {
    setOpenConfirm(false);
    setIsLoading(true);
    try {
      await uploadAssets({ files, isSeries: true, isGroup: false });
      await ProjectService.addSequence(projId, assetId);
      setIsLoading(false);
      handleClose();
    } catch (error) {
      setIsLoading(false);
      setOpenDialog('An error occurred while trying to add the sequence item.');
    }
  };

  const checkSeriesFileTypes = (file) => {
    if (files[0] && files[0].metaType !== file.metaType) {
      return false;
    }
    return true;
  };

  const processFile = (file) => {
    const fileObject = {
      data: file,
    };
    if (checkIsVideo(file.name)) {
      fileObject.metaType = 'VIDEO';
    } else if (checkIsImage(file.name)) {
      fileObject.metaType = 'PHOTO';
    } else {
      fileObject.metaType = 'DOCUMENT';
    }

    if (selected === 'series' && checkSeriesFileTypes(fileObject) === false) {
      return;
    }

    fileObject.metaId = `id_${fileId}`;
    fileObject.metaName = file.name;
    fileObject.cameraType = cameraTypes[0].value;
    fileObject.metaAcqDate = moment(file.lastModified).format('MM/DD/YYYY');
    fileObject.description = '';

    if ((selected === 'series' || selected === 'group') && files.length > 0) {
      const firstFile = files[0];
      fileObject.metaName = firstFile.metaName;
    }

    setFiles((oldFiles) => [...oldFiles, fileObject]);
    fileId += 1;
  };

  return (
    <>
      {icon ? (
        <IconButton onClick={() => setOpenConfirm(true)} color="warning">
          <PlusIcon size={16} />
        </IconButton>
      ) : (
        <Button
          kind="success"
          spacingRight={1}
          onClick={() => setOpenConfirm(true)}
          disabled={loading}
          title="Save Changes"
        >
          Save
        </Button>
      )}
      {openUpload && (
        <Dialog open onClose={handleClose} width="sm">
          <DialogContent>
            <Typography mt={1} onClose={handleClose}>{`Add new item to ${assetId.split('-')[0]}`}</Typography>
            <Typography variant="h2" as="h2" spacing={1}>
              Please drag and drop or select the file that will be added to the sequence
            </Typography>
            <FileDropZone onAddFile={processFile} accept={['video/mp4', 'video/quicktime', 'image/jpeg', 'video/ts']} />
          </DialogContent>
          <DialogActions>
            <Button kind="secondary" onClick={() => setOpenUpload(false)} title="Cancel">
              Cancel
            </Button>
            <Button kind="primary" title="Save" onClick={onSave}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <DialogMessage
        title="Are you sure you want to add an item to the sequence?"
        icon={DoubleCheckIcon}
        isOpen={openConfirm}
        onConfirm={() => {
          setOpenConfirm(false);
          setOpenUpload(true);
        }}
        onCancel={() => setOpenConfirm(false)}
      />
      <DialogMessage
        title={openDialog}
        isOpen={openDialog}
        icon={InfoIcon}
        confirmText="Ok"
        onConfirm={() => {
          setOpenDialog(false);
        }}
      />
      <LoadingOverlay loading={isLoading} />
    </>
  );
};

AddButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  assetId: PropTypes.string,
  projId: PropTypes.string,
  loading: PropTypes.bool,
  icon: PropTypes.bool,
  handleClose: PropTypes.func,
};

AddButton.defaultProps = {
  assetId: '',
  projId: '',
  loading: false,
  icon: false,
  handleClose: () => {},
};

import React, { useEffect, useState } from 'react';
import { TeamsSettingsContainer } from 'components/TeamsSettingsContainer/TeamsSettingsContainer';
import { useParams } from 'react-router';
import TeamService from 'services/TeamService';
import { Card } from 'components/Card';
import { Grid, Typography } from '@mui/material';
import { useGlobalStyles } from 'styles';
import { ActiveMemberWidget } from './ActiveMemberWidget';
import { ActiveProjectsWidget } from './ActiveProjectsWidget';
import { LastUploadsWidget } from './LastUploadsWidget';
import { QuickActionWidget } from './QuickActionWidget';
import ReportsSplash from './ReportsSplash.svg';
import { useStyles as useAdminStyles } from '../../Admin/Dashboard/styles';

export const TeamsDashboard = () => {
  const adminClasses = useAdminStyles();
  const globalClasses = useGlobalStyles();
  const params = useParams();
  const [teamData, setTeamData] = useState('');

  useEffect(() => {
    const getTeamDetails = async () => {
      const responseTeam = await TeamService.getTeam(params.teamId);
      setTeamData(responseTeam.data.data);
    };
    getTeamDetails();
  }, []);

  return (
    <TeamsSettingsContainer title={`${teamData.name}'S DASHBOARD`} showSidebar>
      <Card noPadding>
        <div className={adminClasses.dashContent}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={8.5} lg={8.5} xl={8.5}>
              <div className={adminClasses.imgTitle}>
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Typography variant="h1" mt={1}>
                      Your teammates and activities
                    </Typography>
                    <Typography variant="h4" className={globalClasses.greyTypography} mt={1}>
                      Collaborate, Participate, some other nice little message here for the users.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={8} align="right">
                    <img src={ReportsSplash} alt="Reports Splash" width="100%" />
                  </Grid>
                </Grid>
              </div>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <LastUploadsWidget />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <QuickActionWidget />
                  <ActiveProjectsWidget row={3} col={2} rowspan={1} colspan={1} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={3.5} lg={3.5} xl={3.5}>
              <ActiveMemberWidget row={1} col={3} rowspan={3} colspan={1} />
            </Grid>
          </Grid>
        </div>
      </Card>
    </TeamsSettingsContainer>
  );
};

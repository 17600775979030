import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import OrganizationService from 'services/OrganizationService';
import TeamService from 'services/TeamService';
import UserService from 'services/UserService';
import { formatSortBy } from './helpers';

export const fetchProjectTeams = createAsyncThunk('project-teams/fetchProjectTeams', async (filters) => {
  const filtersOpts = {
    ...filters,
    acctId: filters.accountId,
    include: ['org'],
    ...formatSortBy(filters.sort_by),
  };
  delete filtersOpts.activated;
  delete filtersOpts.accountId;
  delete filtersOpts.viewOption;
  try {
    const response = await TeamService.getTeams(filtersOpts);
    return response.data;
  } catch (e) {
    throw new Error('Error during request for teams...');
  }
});

export const fetchProjectUsers = createAsyncThunk('project-users/fetchProjectUsers', async (filters) => {
  const filtersOpts = {
    ...filters,
    include: ['orgs', 'contributions'],
    ...formatSortBy(filters.sort_by),
  };
  delete filtersOpts.activated;
  delete filtersOpts.viewOption;
  try {
    const response = await UserService.getUsers(filtersOpts);
    return response.data;
  } catch (e) {
    throw new Error('Error during request for users...');
  }
});

export const fetchProjectOrgs = createAsyncThunk('project-orgs/fetchProjecOrgs', async (filters) => {
  const filtersOpts = {
    ...filters,
    include: ['roles', 'account'],
    ...formatSortBy(filters.sort_by),
  };
  delete filtersOpts.activated;
  delete filtersOpts.accountId;
  delete filtersOpts.organizationId;
  delete filtersOpts.viewOption;
  try {
    const response = await OrganizationService.getOrganizations(filtersOpts);
    return response.data;
  } catch (e) {
    throw new Error('Error during request for orgs...');
  }
});

export const typeSearch = {
  USER: 'individual',
  TEAM: 'teams',
  ORG: 'orgs',
};

export const preloadFilterDatasets = createAsyncThunk(
  'projectPermissions/preloadFilterDatasets',
  async (arg, { getState }) => {
    try {
      const newFilters = {
        ...getState().projectPermissions.filters,
        sort_by: Object.values(getState().projectPermissions.filters.sort_by),
        projectId: arg.projectId,
      };
      let result = { organizations: [], accounts: [] };
      delete newFilters.activated;
      delete newFilters.viewOption;
      if (arg.type === typeSearch.USER) {
        result = (await UserService.getUsersAccountsOrganizations(newFilters)).data.data;
      } else if (arg.type === typeSearch.TEAM) {
        newFilters.acctId = newFilters.accountId;
        delete newFilters.accountId;
        result = (await TeamService.getTeamAccountsOrganizations(newFilters)).data.data;
      }
      return result;
    } catch (err) {
      throw new Error('error loading filter data');
    }
  },
  {
    condition: (_, { getState }) => getState().projectPermissions.panels.search.loaded === false,
  }
);

const orderByName = (array) =>
  array.sort((a, b) => {
    const nameA = a.label.toUpperCase(); // ignore upper and lowercase
    const nameB = b.label.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

const initialState = {
  participants: [],
  participantType: 'individual',
  participantCount: 0,
  loading: true,
  error: null,
  panels: {
    loading: true,
    search: {
      loaded: false,
      accounts: [],
      organizations: [],
    },
  },
  filters: {
    page: 1,
    page_size: 10,
    sort_by: {},
    search: '',
    viewOption: 'individual',
  },
};

const projectTeamsReducer = createSlice({
  name: 'project-teams',
  initialState,
  reducers: {
    resetProjectTeams: () => initialState,
    setPageFilter(state, action) {
      state.filters.page = action.payload;
    },
    updateParticipantRoles(state, { payload }) {
      const { partId, roles, user } = payload;
      for (let i = 0; i < state.participants.length; i += 1) {
        const participant = state.participants[i];
        if (participant.id === partId) {
          participant.roles = roles;
          if (user?.id) {
            participant.firstName = user.firstName;
            participant.lastName = user.lastName;
          }
          break;
        }
      }
    },
    setSearch(state, action) {
      state.filters.search = action.payload;
      state.filters.page = 1;
    },
    setFilters(state, action) {
      const filter = { ...state.filters, ...action.payload, page: 1 };
      state.filters = filter;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjectTeams.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchProjectTeams.fulfilled, (state, { payload }) => {
      if (state.participantType !== typeSearch.TEAM) {
        state.panels.search.loaded = false;
      }
      state.participants = payload.data;
      state.participantType = typeSearch.TEAM;
      state.participantCount = payload.meta.totalCount;
      state.loading = false;
    });

    builder.addCase(fetchProjectTeams.rejected, (state) => {
      state.loading = false;
      state.participants = [];
      state.participantType = typeSearch.TEAM;
      state.participantCount = 0;
      state.error = 'unable to load teams';
    });

    builder.addCase(fetchProjectUsers.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchProjectUsers.fulfilled, (state, { payload }) => {
      state.participants = payload.data;
      state.participantType = typeSearch.USER;
      if (state.participantType !== typeSearch.USER) {
        state.panels.search.loaded = false;
      }
      state.participantCount = payload.meta.totalCount;
      state.loading = false;
    });

    builder.addCase(fetchProjectUsers.rejected, (state) => {
      state.individuals.error = 'unable to load users data';
      state.participantType = typeSearch.USER;
      state.participantCount = 0;
      state.participants = [];
      state.loading = false;
    });

    builder.addCase(fetchProjectOrgs.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchProjectOrgs.fulfilled, (state, { payload }) => {
      state.participants = payload.data;
      state.participantType = typeSearch.ORG;
      if (state.participantType !== typeSearch.ORG) {
        state.panels.search.loaded = false;
      }
      state.participantCount = payload.meta.totalCount;
      state.loading = false;
    });

    builder.addCase(fetchProjectOrgs.rejected, (state) => {
      state.individuals.error = 'unable to load organizations data';
      state.participantType = typeSearch.ORG;
      state.participantCount = 0;
      state.participants = [];
      state.loading = false;
    });
    builder.addCase(preloadFilterDatasets.pending, (state) => {
      state.panels.search.loaded = false;
    });
    builder.addCase(preloadFilterDatasets.rejected, (state) => {
      state.panels.search.organizations = [];
      state.panels.search.accounts = [];
      state.panels.search.projects = [];
      state.panels.search.users = [];
      state.panels.search.loaded = false;
    });
    builder.addCase(preloadFilterDatasets.fulfilled, (state, { payload }) => {
      const { organizations, accounts } = payload;
      state.panels.search.loaded = true;
      let newAccounts = accounts.map((account) => ({
        label: account.name,
        value: account.id,
      }));
      let newOrganizations = organizations.map((org) => ({
        label: org.name,
        value: org.id,
      }));
      newAccounts = orderByName(newAccounts);
      newOrganizations = orderByName(newOrganizations);
      state.panels.search.accounts = newAccounts;
      state.panels.search.organizations = newOrganizations;
    });
  },
});

export const {
  setPageFilter,
  updateParticipantRoles,
  resetProjectTeams,
  setSearch,
  setFilters,
  // eslint-disable-next-line prettier/prettier
} = projectTeamsReducer.actions;

export default projectTeamsReducer.reducer;

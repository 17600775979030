import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const HouseIcon = ({ size }) => (
  <Svg viewBox="0 0 24 24" size={size}>
    <path d="M12 2L0 12.588h3.6V22h6v-7.059h4.8V22h6v-9.412H24L12 2z" fill="currentColor" />
  </Svg>
);

HouseIcon.propTypes = {
  size: PropTypes.number,
};

HouseIcon.defaultProps = {
  size: 24,
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import ProjectService from 'services/ProjectService';
import { useDispatch, useSelector } from 'react-redux';
import { setProject } from 'slices/allProjectReducer';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';

export const UpdateButton = ({
  values,
  projectId,
  setLoading,
  setSubmitError,
  handleOpen,
  fullWidth,
  detail,
  handleChange,
  isChanging,
}) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const { detail: project } = useSelector((state) => state.allProjects.panels);

  const saveProjectSettings = async () => {
    setLoading(true);
    setLoader(true);
    try {
      const projectResponse = await ProjectService.updateProject(projectId, values);
      handleOpen(projectResponse.data.data);
      setLoading(false);
      setLoader(false);
      if (detail) {
        handleChange(false);
        dispatch(
          setProject({
            ...project,
            ...values,
          })
        );
      }
    } catch (err) {
      const result = err.response.data.message;
      setSubmitError(result);
      setLoading(false);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (detail && isChanging) {
      saveProjectSettings();
      // console.log('save');
      // console.log(values);
    }
  }, [values]);

  return (
    <>
      {!detail && (
        <Button
          color="secondary"
          variant="contained"
          onClick={saveProjectSettings}
          disabled={loader}
          fullWidth={fullWidth}
        >
          Confirm
        </Button>
      )}
      <LoadingOverlay loading={loader} />
    </>
  );
};

UpdateButton.propTypes = {
  values: PropTypes.object,
  projectId: PropTypes.string,
  setLoading: PropTypes.func,
  setSubmitError: PropTypes.func,
  handleOpen: PropTypes.func,
  fullWidth: PropTypes.bool,
  detail: PropTypes.bool,
  handleChange: PropTypes.func,
  isChanging: PropTypes.bool,
};

UpdateButton.defaultProps = {
  values: null,
  projectId: null,
  setLoading: () => {},
  setSubmitError: () => {},
  handleOpen: () => {},
  fullWidth: false,
  detail: false,
  handleChange: () => {},
  isChanging: false,
};

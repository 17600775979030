import React, { useContext, useEffect } from 'react'; // , { useEffect }
import PropTypes from 'prop-types';
import moment from 'moment';
import { Time } from 'lib/time';
import { useDispatch, useSelector } from 'react-redux';
import { usePagination, useSortBy, useTable } from 'react-table';
import { AccountSettingsContainer } from 'components/AccountSettingsContainer/AccountSettingsContainer';
import { Card } from 'components/Card/Card';
import { ContextMenu } from 'components/ContextMenu/ContextMenu';
import { ContextMenuButton } from 'components/ContextMenuButton/ContextMenuButton';
import { ContextMenuList } from 'components/ContextMenuItems';
import { DialogMessage, dialogMessageAction } from 'components/DialogMessage';
import { PermGuard } from 'components/Guards/PermGuard';
import { CirclePlusIcon, CopyIcon, LinkedIcon, SharingIcon } from 'components/Icons';
import { ClockIcon } from 'components/Icons/ClockIcon';
import { Loader } from 'components/Loader';
import { useSidebar } from 'components/SidebarProvider/SidebarProvider';
import { TablePagination } from 'components/TablePagination/TablePagination';
import { Permissions } from 'lib/permissions';
import { WidgetSplash } from 'components/WidgetSplash/WidgetSplash';
import SplashImage from 'images/UsersSplash.png';
import {
  copyContent,
  deleteContent,
  getAdminContents,
  getSingleContent,
  revokeContent,
  setPageFilter,
  shareContent,
  setSearch,
} from 'slices/contentAdminReducer';
import { AdminHeaderTitle } from 'components/AdminHeaderTitle/AdminHeaderTitle';
import { getExternalContents, setExternalPageFilter } from 'slices/externalContentReducer';
import { DeleteUuid } from 'components/AddUuid/DeleteUuid/DeleteUuid';
import { CopyUuidAlert } from 'components/AddUuid/CopyUuidAlert/CopyUuidAlert';
import { AddUuid } from 'components/AddUuid/AddUuid';
import { DialogProjectShare } from 'components/DialogProjectShare';
import ProjectService from 'services/ProjectService';
import { DialogProjectCopy } from 'components/DialogProjectCopy';
import { externalActionType, externalDataType } from 'lib/external-data';
import { ManageSharedAssets } from 'components/ManageSharedAssets/ManageSharedAssets';
import { ViewRoleValidation } from 'components/ViewRoleValidation/ViewRoleValidation';
import { GeneralTable } from 'components/GeneralTable/GeneralTable';
import { contentOptions, externalContentOptions } from 'lib/topBarOptions';
import { TopBar } from 'components/TopBar/TopBar';
import { ContextMenuItem } from 'components/ContextMenuItem';
import { WidgetDivider } from 'components/WidgetDivider/WidgetDivider';
import { Typography } from '@mui/material';
import { useGlobalStyles } from 'styles';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { setContentDetail } from 'slices/contentReducer';
import { getProjectAsset } from 'slices/profileProjectAssetReducer';
import { SidebarDetailContext } from 'components/SidebarProviderDetail/SidebarProviderDetail';
import { ContentDetail } from 'components/ContentDetail/ContentDetail';
import { AdminPanel } from './AdminPanel';
// eslint-disable-next-line import/no-cycle
import { InfoPanel } from './InfoPanel';
import { SearchPanel } from './SearchPanel';
import { useStyles } from './styles';

const tableColumns = (external, globalClasses, classes) => [
  {
    Header: 'Type/Length',
    accessor: (rowData) => {
      const { createdAt, type, timeLength } = external ? rowData.projectAsset.asset : rowData;
      return (
        <div className={globalClasses.nameContainer}>
          <span className={classes.columnContainer}>
            <ClockIcon size={14} />
          </span>
          <div>
            <Typography>{type === 'VIDEO' ? Time.humanReadable({ seconds: timeLength }) : type}</Typography>
            <Typography className={classes.greyTypography}>{moment(createdAt).format('MM/DD/YYYY')}</Typography>
          </div>
        </div>
      );
    },
  },
  {
    Header: 'Name',
    accessor: (rowData) => {
      const { user } = rowData;
      return (
        <div>
          <Typography>{external ? rowData.projectAsset.name : rowData.name}</Typography>
          {user && (
            <Typography
              variant="small"
              className={classes.greyTypography}
            >{`${user.firstName} ${user.lastName}`}</Typography>
          )}
        </div>
      );
    },
  },
  {
    Header: 'Primary Project',
    accessor: (rowData) => {
      const { name } = external ? rowData.projectAsset.project : rowData.project;
      return name;
    },
  },
  {
    Header: external ? 'Leading Account' : 'Projects Sharing',
    accessor: (rowData) => {
      if (external) {
        return rowData.projectAsset.project.account.name;
      }
      return rowData.projectSharedWith && rowData.projectSharedWith;
    },
  },
];

let debounceTimeout = null;
export const Content = ({ external }) => {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const [openCopyAccess, setOpenCopyAccess] = React.useState(false);
  const [openShareAccess, setOpenShareAccess] = React.useState(false);
  const [openCopyDenied, setOpenCopyDenied] = React.useState(false);
  const [openShareDenied, setOpenShareDenied] = React.useState(false);
  const [openRevokeAccess, setOpenRevokeAccess] = React.useState(false);
  const [openDeleteAccess, setOpenDeleteAccess] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState('');
  const [item, setItem] = React.useState('');
  const adminScopes = [Permissions.ACCOUNT_USER_GRANT];
  const dispatch = useDispatch();
  const { data, loading, assetCount, filters } = useSelector(
    (state) => state[external ? 'externalContents' : 'adminContents']
  );
  const [openUuid, setOpenUuid] = React.useState(false);
  const [validateRole, setValidateRole] = React.useState({ open: false, callBack: () => {} });
  const [openManageShareAsset, setOpenManageShareAsset] = React.useState(false);
  const search = filters.search || '';
  const { setActivePanel, setFullWidth, setHeaderPanels: setHeaderPanelsDetail } = useContext(SidebarDetailContext);

  const columns = React.useMemo(() => tableColumns(external, globalClasses, classes), []);
  const loadData = () => {
    const sendFilters = {
      ...filters,
    };
    dispatch(external ? getExternalContents(sendFilters) : getAdminContents(sendFilters));
  };

  useEffect(() => {
    dispatch(setSearch());
  }, []);

  const setShare = (auxItem, access) => {
    setItem(auxItem);
    if (access) {
      setOpenShareDenied(true);
    } else {
      setOpenShareAccess(true);
    }
    return null;
  };

  const handleShareChange = () => {
    data.map((row) => {
      if (row.id === item.id) {
        dispatch(
          shareContent({
            id: row.id,
            sharingEnabled: row.sharingEnabled,
          })
        );
      }
      setOpenShareAccess(false);
      setOpenShareDenied(false);
      return null;
    });
  };

  const setCopy = (auxItem, access) => {
    setItem(auxItem);
    if (access) {
      setOpenCopyDenied(true);
    } else {
      setOpenCopyAccess(true);
    }
    return null;
  };

  const handleCopyChange = () => {
    data.map((row) => {
      if (row.id === item.id) {
        dispatch(
          copyContent({
            id: row.id,
            copyingEnabled: row.copyingEnabled,
          })
        );
      }
      setOpenCopyDenied(false);
      setOpenCopyAccess(false);
      return null;
    });
  };

  const setRevoke = (auxItem) => {
    setItem(auxItem);
    setOpenRevokeAccess(true);
    return null;
  };

  const handleRevokeChange = () => {
    data.map((row) => {
      if (row.id === item.id) {
        dispatch(
          revokeContent({
            id: row.id,
          })
        );
      }
      setOpenDeleteAccess(false);
      return null;
    });
  };

  const setDelete = (auxItem) => {
    setItem(auxItem);
    setOpenDeleteAccess(true);
    return null;
  };

  const handleDeleteChange = async () => {
    const res = data.find((row) => row.id === item.id);
    if (res) {
      await dispatch(deleteContent({ projectId: res.project.id, assetId: res.id }));
    }
    setOpenDeleteAccess(false);
    loadData();
  };

  const table = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: filters.page - 1,
        pageSize: filters.page_size,
      },
      pageCount: Math.ceil(assetCount / filters.page_size),
      totalCount: assetCount,
      pageLength: data.length,
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  const {
    state: { pageIndex, pageSize },
  } = table;

  React.useEffect(() => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      loadData();
    }, 150);
  }, [filters]);

  React.useEffect(() => {
    dispatch(external ? setExternalPageFilter(pageIndex + 1) : setPageFilter(pageIndex + 1));
  }, [pageIndex, pageSize]);

  const { displayPanel, setHeaderPanels } = useSidebar({
    open: false,
    config: {
      search: {
        show: true,
        component: SearchPanel,
        external,
      },
      header: [],
    },
  });

  const handleRowClick = (rowData) => {
    dispatch(
      getSingleContent({
        projectId: external ? rowData.original.projectAsset.projId : rowData.original.projId,
        id: external ? rowData.original.projectAsset.id : rowData.original.id,
        user: external ? rowData.original.projectAsset.asset.user : rowData.original.user,
      })
    );
    setSelectedRow(rowData.id);
    setHeaderPanels([
      {
        name: 'info',
        component: InfoPanel,
      },
      {
        name: 'admin',
        component: AdminPanel,
      },
    ]);
    displayPanel('info');
  };

  // ------------------------------- UUID functions
  const handleCloseUuid = (flag) => {
    setOpenUuid(false);
    if (flag) loadData(filters);
  };

  // ------------------------------- share functions
  const handleProjectShare = async (projectId) => {
    await ProjectService.ShareAssetWithProject(projectId, openUuid.id, {
      shareWithProjectId: projectId,
      assetId: openUuid.id,
    });
    setOpenUuid(false);
  };

  const closeManageSharedAssets = (flagRefresh) => {
    setOpenManageShareAsset(false);
    if (flagRefresh) loadData();
  };

  const handleProjectCopy = async (projectId) => {
    await ProjectService.CopyAssetWithProject(projectId, openUuid.id, projectId);
    setOpenUuid(false);
  };

  // view content detail
  const viewContent = (row) => {
    const projAsset = external ? row.original.projectAsset : row.original;
    dispatch(setContentDetail({ ...projAsset, inspectionSection: true }));
    dispatch(getProjectAsset({ projId: projAsset.projId, assetId: projAsset.id }));
    setHeaderPanelsDetail([
      {
        name: 'contentDetail',
        component: ContentDetail,
      },
    ]);
    setFullWidth(true);
    setActivePanel('contentDetail');
  };

  const EmptyContentContainer = () => (
    <WidgetSplash
      alt="Content Splash"
      title="There is currently no content. Create one to begin uploading content."
      image={SplashImage}
    />
  );

  const ContentActions = ({ row }) => (
    <ContextMenu>
      <ContextMenuButton />
      <ContextMenuList position="bottom-right">
        {!external && (
          <ContextMenuItem onClick={() => setOpenUuid({ id: row.original.id, type: externalActionType.COPY })}>
            Copy UUID
          </ContextMenuItem>
        )}
        <ContextMenuItem onClick={() => viewContent(row)}>View Content</ContextMenuItem>
        <WidgetDivider />
        {!external && (
          <>
            <ContextMenuItem onClick={() => setShare(row.original, row.original.sharingEnabled)}>
              Allow Sharing/Disallow Sharing
            </ContextMenuItem>
            <ContextMenuItem onClick={() => setCopy(row.original, row.original.copyingEnabled)}>
              Allow Copies/Disallow Copies
            </ContextMenuItem>
            <ContextMenuItem
              disabled={!row.original.sharingEnabled && !row.original.copyingEnabled}
              onClick={() => setRevoke(row.original)}
            >
              Revoke Access
            </ContextMenuItem>
            <ContextMenuItem
              key="edit"
              onClick={() =>
                setValidateRole({
                  open: true,
                  action: [Permissions.PROJ_MODIFY_CONTENT],
                  data: { ...row.original.project, asset: row.original },
                  callBack: () => setOpenManageShareAsset(row.original),
                })
              }
            >
              Manage Access
            </ContextMenuItem>
            <PermGuard scopes={adminScopes}>
              <WidgetDivider />
              <ContextMenuItem icon={faTrash} onClick={() => setDelete(row.original)}>
                Delete
              </ContextMenuItem>
            </PermGuard>
          </>
        )}
        {external && (
          <>
            <ContextMenuItem
              onClick={() => setOpenUuid({ ...row.original.projectAsset, type: externalActionType.COPY_ASSET })}
            >
              Copy
            </ContextMenuItem>
            <ContextMenuItem
              onClick={() => setOpenUuid({ ...row.original.projectAsset, type: externalActionType.SHARE_ASSET })}
            >
              Share
            </ContextMenuItem>
            <WidgetDivider />
            <ContextMenuItem
              onClick={() => setOpenUuid({ ...row.original.projectAsset, type: externalActionType.REMOVE })}
            >
              Remove from Account
            </ContextMenuItem>
          </>
        )}
      </ContextMenuList>
    </ContextMenu>
  );

  const handleSearch = (e) => dispatch(setSearch(e.target.value));

  ContentActions.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    row: PropTypes.object.isRequired,
  };

  const ContentContainer = () => (
    <>
      <GeneralTable
        table={table}
        selectedRow={selectedRow}
        handleRowClick={handleRowClick}
        rowComponent={ContentActions}
      />
      <TablePagination table={table} />
      <DialogMessage
        title={`Allow Copies ${item.name}`}
        content="Allow Copies on this content?"
        isOpen={openCopyAccess}
        icon={CopyIcon}
        onConfirm={() => {
          handleCopyChange();
        }}
        onCancel={() => {
          setOpenCopyAccess(false);
        }}
      />
      <DialogMessage
        title={`Disallow Copies ${item.name}`}
        content="Disallow Copies on this content?"
        isOpen={openCopyDenied}
        icon={CopyIcon}
        onConfirm={() => {
          handleCopyChange();
        }}
        onCancel={() => {
          setOpenCopyDenied(false);
        }}
      />
      <DialogMessage
        title={`Allow Sharing ${item.name}`}
        content="Allow Sharing on this content?"
        isOpen={openShareAccess}
        icon={SharingIcon}
        onConfirm={() => {
          handleShareChange();
        }}
        onCancel={() => {
          setOpenShareAccess(false);
        }}
      />
      <DialogMessage
        title={`Disallow Sharing ${item.name}`}
        content="Disallow Sharing on this content?"
        isOpen={openShareDenied}
        icon={SharingIcon}
        onConfirm={() => {
          handleShareChange();
        }}
        onCancel={() => {
          setOpenShareDenied(false);
        }}
      />

      <DialogMessage
        title={`Revoke ${item.name}`}
        content="Revoke access to this content?"
        isOpen={openRevokeAccess}
        icon={LinkedIcon}
        onConfirm={() => {
          handleRevokeChange();
        }}
        onCancel={() => {
          setOpenRevokeAccess(false);
        }}
      />

      <DialogMessage
        title={`Delete ${item.name}`}
        content="Delete this content?"
        isOpen={openDeleteAccess}
        onConfirm={() => {
          handleDeleteChange();
        }}
        onCancel={() => {
          setOpenDeleteAccess(false);
        }}
        action={dialogMessageAction.delete}
      />
      {openUuid?.id && openUuid?.type === externalActionType.SHARE_ASSET && (
        <DialogProjectShare
          title="Select a project to share this content with."
          isOpen={openUuid}
          onConfirm={(projectId) => handleProjectShare(projectId)}
          onCancel={() => {
            setOpenUuid(false);
          }}
          asset={openUuid}
          external={external}
        />
      )}
      {openUuid?.id && openUuid?.type === externalActionType.COPY_ASSET && (
        <DialogProjectCopy
          title="Select a project to copy this content with."
          isOpen={openUuid}
          onConfirm={(projectId) => handleProjectCopy(projectId)}
          onCancel={() => {
            setOpenUuid(false);
          }}
          projectId={openUuid.projId}
        />
      )}
      {openUuid?.id && openUuid?.type === externalActionType.REMOVE && (
        <DeleteUuid externalData={openUuid} type={externalDataType.ASSET} handleClose={handleCloseUuid} />
      )}
      {openUuid?.id && openUuid?.type === externalActionType.COPY && (
        <CopyUuidAlert id={openUuid.id} handleClose={() => setOpenUuid(false)} />
      )}
      {openManageShareAsset && (
        <ManageSharedAssets asset={openManageShareAsset} handleClose={closeManageSharedAssets} modalContent />
      )}
      {validateRole.open && (
        <ViewRoleValidation
          action={validateRole.action}
          data={validateRole.data}
          callBack={validateRole.callBack}
          handleClose={() => setValidateRole({ open: false, callBack: () => {} })}
        />
      )}
    </>
  );

  const headerActions = [
    {
      text: 'Add Via UUID',
      icon: CirclePlusIcon,
      onClick: () => {
        setOpenUuid({ type: externalActionType.ADD });
      },
    },
  ];

  if (loading && data.length === 0) {
    return (
      <AccountSettingsContainer
        title={<AdminHeaderTitle buttonName="ADMINISTRATION" section={external ? 'EXTERNAL CONTENT' : 'CONTENT'} />}
        actions={external ? headerActions : []}
        showSidebar
      >
        <Loader loading={loading} height={250} />
      </AccountSettingsContainer>
    );
  }

  return (
    <AccountSettingsContainer
      title={<AdminHeaderTitle buttonName="ADMINISTRATION" section={external ? 'EXTERNAL CONTENT' : 'CONTENT'} />}
      actions={external ? headerActions : []}
      showSidebar
    >
      <Card noPadding>
        <TopBar
          options={external ? externalContentOptions : contentOptions}
          handleSearch={handleSearch}
          searchValue={search}
          activatedFilter={filters.activated}
        />
        {data.length > 0 ? <ContentContainer /> : <EmptyContentContainer />}
      </Card>
      {openUuid?.type === externalActionType.ADD && (
        <AddUuid
          handleClose={handleCloseUuid}
          type={externalDataType.ASSET}
          variant={openUuid.variant}
          item={openUuid}
        />
      )}
    </AccountSettingsContainer>
  );
};

Content.propTypes = {
  external: PropTypes.bool,
};

Content.defaultProps = {
  external: false,
};

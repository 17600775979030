import React, { useMemo, useEffect, useState, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@mui/material';
import { useStyles } from 'components/TablePagination/styles';
import { v4 } from 'uuid';

export const CardPagination = ({ page, pageSize, totalCount, pageLength, onPageChange }) => {
  const classes = useStyles({ overflowX: true });
  const [showMorePages, setShowMorePages] = useState(null);
  const morePagesRef = useRef(null);
  const [morePages, setMorePages] = useState([]);
  const [paginationOpts, setPaginationOpts] = useState([]);

  const possiblePages = useMemo(() => {
    const pageCount = Math.ceil(totalCount / pageSize);

    return Array(pageCount)
      .fill()
      .map((_, idx) => idx);
  }, [totalCount, pageSize]);

  const hasPrev = useMemo(() => page > 1, [page]);
  const hasNext = useMemo(() => page < possiblePages.length, [page, possiblePages]);

  const calcPageOptions = (options, idx, numVisible = 7) => {
    const sep = '/';
    let result = [];
    if (options.length < numVisible || numVisible < 1) {
      return options;
    }
    const half = Math.floor(numVisible / 2);
    const even = numVisible % 2 === 0 ? 1 : 0;
    const left = half - 1;
    const right = options.length - half + even;
    if (idx > left && idx < right) {
      const start = idx - half + 2;
      const end = idx + half - 1 - even;
      result = [0, sep, ...options.slice(start, end), sep, ...options.slice(-1)];
    } else if (idx === left) {
      const end = idx + half - even;
      result = [...options.slice(0, end), sep, ...options.slice(-1)];
    } else if (idx === right) {
      const start = idx - half + 1;
      result = [0, sep, ...options.slice(start)];
    } else {
      result = [...options.slice(0, half), sep, ...options.slice(right)];
    }
    return result;
  };

  // ------------------------- show the pages in especific space
  const showPages = (index) => {
    setShowMorePages(index);
  };

  // ------------------------- calculate the number of more pages
  const createMorePages = (index, auxPaginationOpts) => {
    const firstNumber = +auxPaginationOpts[index - 1] + 1;
    const pages = [firstNumber];
    for (let i = firstNumber + 1; i < auxPaginationOpts[index + 1]; i += 1) {
      pages.push(i);
    }
    return pages;
  };

  useEffect(() => {
    // ------------------- hide popup clicking out side div
    function handleClickOutside(event) {
      if (morePagesRef.current && !morePagesRef.current.contains(event.target)) {
        setShowMorePages(null);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const auxPaginationOpts = calcPageOptions(possiblePages, page - 1, 10);
    setPaginationOpts(auxPaginationOpts);
    const auxMorePages = [];
    auxPaginationOpts.forEach((element, index) => {
      if (element === '/') {
        auxMorePages[index] = createMorePages(index, auxPaginationOpts);
      }
    });
    setMorePages(auxMorePages);
  }, [possiblePages]);

  return (
    <div className={classes.pagination}>
      {morePages.length > 0 && morePages[showMorePages] && showMorePages && (
        <div className={classes.morePages} ref={morePagesRef}>
          {morePages[showMorePages].map((pageHidden) => (
            <Button
              color="primary"
              variant="contained"
              onClick={() => onPageChange(pageHidden + 1)}
              key={pageHidden}
              size="small"
            >
              {pageHidden + 1}
            </Button>
          ))}
        </div>
      )}
      <div className={classes.paginationContent}>
        <div className={classes.text}>
          Showing {pageLength === 0 ? pageLength : (page - 1) * pageSize + 1}-{pageSize * (page - 1) + pageLength} of{' '}
          {totalCount} items
        </div>
        <div className={classes.tablePagination}>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={() => onPageChange(page - 1)}
            disabled={!hasPrev}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </Button>
          <div className={classes.pages}>
            {paginationOpts.map((pageNumber, i) => {
              if (pageNumber === '/') {
                return (
                  <Button
                    className={classes.moreButton}
                    onClick={() => showPages(i)}
                    size="small"
                    color="primary"
                    variant="contained"
                    key={`${v4()}${new Date().getMilliseconds()}`}
                  >
                    ...
                  </Button>
                );
              }
              return (
                <Button
                  color={page === pageNumber + 1 ? 'secondary' : 'primary'}
                  onClick={() => onPageChange(pageNumber + 1)}
                  key={pageNumber}
                  size="small"
                  variant="contained"
                >
                  {pageNumber + 1}
                </Button>
              );
            })}
          </div>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={() => onPageChange(page + 1)}
            disabled={!hasNext}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </Button>
        </div>
      </div>
    </div>
  );
};

CardPagination.propTypes = {
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  pageLength: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  onPageChange: PropTypes.func,
};

CardPagination.defaultProps = {
  onPageChange: () => {},
};

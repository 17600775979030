import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  dialogLayout: {
    display: 'flex',
    gap: theme.spacing(2),
    // paddingTop: theme.spacing(1),
  },
  layoutCol1: {
    flex: '1 1 auto',
    width: '65vw',
    '& .tie-btn-delete': {
      display: 'none !important',
    },
  },
  layoutCol2: {
    flex: '1 0 auto',
    width: '23vw',
  },
  cropperControlContainer: {
    marginTop: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  },
  assetContainer: {
    position: 'relative',
    '& .delete-btn': {
      position: 'absolute',
      top: '10px',
      left: '10px',
    },
  },
  playContainer: {
    position: 'absolute',
    top: '45%',
    zIndex: 1,
    display: 'flex',
    // background: theme.palette.white,
  },
  subTypography: {
    marginLeft: theme.spacing(0.2),
    color: '#848484',
    overflowWrap: 'break-word',
    textAlign: 'left',
  },
  buttonsContent: {
    display: 'flex',
    justifyContent: 'end',
    gap: 8,
  },
});

import { EditIcon, ObservationIcon } from 'components/Icons';
import { SidebarPanel } from 'components/SidebarPanel';
import { SidebarPanelSection } from 'components/SidebarPanelSection';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { v4 } from 'uuid';
import { ObservationExport } from '../ObservationExport/ObservationExport';
import { DeleteButton } from '../ObservationModal/DeleteButton';
import { ObservationModal } from '../ObservationModal/ObservationModal';
import { EditButton, IconContent, NameContent, ObservationIconStyle, ObservationName, ObservationRow } from './styles';

export const ObservationsList = () => {
  const { isAssetLoading } = useSelector((state) => state.profileProjectAssets);
  const { observations, projectObservations, isLoading } = useSelector((state) => state.assetObservations);
  const [observationData, setObservationData] = useState(null);
  const [point, setPoint] = useState(null);
  const location = useLocation();

  const handleViewObservationClose = () => {
    setObservationData(null);
  };

  const handleViewObservationOpen = (observation) => {
    setPoint(null);
    if (observation?.spatialPoint?.id) {
      setPoint(observation?.spatialPoint);
    } else {
      const { sequence } = observation;
      if (sequence?.spatialPoints) {
        let auxPoint = null;
        if (sequence.asset.type === 'PHOTO') auxPoint = { ...sequence.spatialPoints[0] };
        else auxPoint = sequence.spatialPoints.find((item) => +item.time === +observation.time - 1);
        setPoint(auxPoint);
      }
    }
    setObservationData(observation);
  };

  return (
    <SidebarPanel title="Observations" loading={!location.pathname.includes('content') ? isLoading : isAssetLoading}>
      <SidebarPanelSection title="Asset Observations" accordion>
        {observations.length > 0 && <ObservationExport observations={observations} />}
        {observations.map((observation) => (
          <ObservationRow key={`${v4()}${new Date().getMilliseconds()}`}>
            <NameContent>
              <ObservationIconStyle>
                <ObservationIcon />
              </ObservationIconStyle>
              <ObservationName onClick={() => handleViewObservationOpen(observation)}>
                {observation.name}
              </ObservationName>
            </NameContent>
            <IconContent>
              <DeleteButton observation={observation} icon />
              <EditButton onClick={() => handleViewObservationOpen(observation)}>
                <EditIcon type="simple" />
              </EditButton>
            </IconContent>
          </ObservationRow>
        ))}
      </SidebarPanelSection>
      <SidebarPanelSection title="Project Observations" accordion>
        {projectObservations.length > 0 && <ObservationExport observations={projectObservations} />}
        {projectObservations.map((observation) => (
          <ObservationRow key={`${v4()}${new Date().getMilliseconds()}`}>
            <NameContent>
              <ObservationIconStyle>
                <ObservationIcon />
              </ObservationIconStyle>
              <ObservationName onClick={() => handleViewObservationOpen(observation)}>
                {observation.name}
              </ObservationName>
            </NameContent>
            <IconContent>
              <DeleteButton observation={observation} icon />
              <EditButton onClick={() => handleViewObservationOpen(observation)}>
                <EditIcon type="simple" />
              </EditButton>
            </IconContent>
          </ObservationRow>
        ))}
      </SidebarPanelSection>
      {observationData?.id && (
        <ObservationModal
          isOpen
          handleClose={handleViewObservationClose}
          observationMap={observationData}
          pointMap={point}
        />
      )}
    </SidebarPanel>
  );
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Grid, Typography } from '@mui/material';
import image from 'images/detailMapInfo.png';
import lightImage from 'images/lighthouseImage.png';
import { CopyIcon, SharingIcon } from 'components/Icons';
import { UuidButton } from 'components/UuidButton/UuidButton';
import { Permissions } from 'lib/permissions';
import { ModalComingSoon } from 'smartComponents/ModalComingSoon/ModalComingSoon';
import { useStyles } from './styles';

export const ProjectInfo = ({ data }) => {
  const classes = useStyles();
  const [openCominSoon, setOpenCominSoon] = useState(false);

  return (
    <div>
      <div className={classes.imageContent}>
        <img alt="" src={image} width="100%" />
        <img alt="" src={lightImage} width="100%" className={classes.image} />
        <div className={classes.buttonContent}>
          <Button variant="contained" onClick={() => setOpenCominSoon(true)}>
            <CopyIcon />
            Copy Project
          </Button>
          <Button variant="contained" onClick={() => setOpenCominSoon(true)}>
            <SharingIcon />
            Share Project
          </Button>
        </div>
      </div>
      <div className={classes.content}>
        <Grid container spacing={1}>
          <Grid item lg={10} md={10} sm={12} xs={12}>
            <Typography variant="h5" mt={1} className={classes.text}>
              {data.name}
            </Typography>
            <UuidButton data={data} permissions={[Permissions.PROJ_COPY_UUID]} />
            <Typography>{data?.projUsers?.length} Participants</Typography>
          </Grid>
          <Grid item lg={2} md={2} sm={12} xs={12} textAlign="right">
            <Typography variant="h5" mt={1} className={classes.text}>
              Creation Date: {moment(data.createdAt).format('MM/DD/YY')}
            </Typography>
            <Typography className={classes.observationText}>{data?.observationsCount} Observations</Typography>
          </Grid>
        </Grid>
        <Typography mt={1} className={classes.text}>
          About
        </Typography>
        <Typography mb={1} variant="body1" className={classes.observationText}>
          {data.description}
        </Typography>
      </div>
      {openCominSoon && <ModalComingSoon onCancel={() => setOpenCominSoon(false)} />}
    </div>
  );
};

ProjectInfo.propTypes = {
  data: PropTypes.object,
};

ProjectInfo.defaultProps = {
  data: { id: null, name: '', loading: false },
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ExternalService from 'services/ExternalService';
import ProjectService from 'services/ProjectService';
import TeamService from 'services/TeamService';

export const getExternalUsers = createAsyncThunk('externalUsers/getExternalUsers', async (filters) => {
  try {
    delete filters.activated;
    const response = await ExternalService.getExternalList(filters, 'users');
    return response.data;
  } catch (e) {
    throw new Error('Error during request for users...');
  }
});

export const preloadExternalFilterDatasets = createAsyncThunk(
  'externalUsers/preloadExternalFilterDatasets',
  async () => {
    try {
      const [accounts, organizations, teams, projects] = await Promise.all([
        await ExternalService.getUserAccounts(),
        await ExternalService.getUserOrganizations(),
        await ProjectService.getProjects({ page_size: 1000 }),
        await TeamService.getTeams({ page_size: 1000 }),
      ]);
      return {
        accounts: accounts.data.data,
        organizations: organizations.data.data,
        teams: teams.data.data,
        projects: projects.data.data,
      };
    } catch (err) {
      throw new Error('error loading filter data');
    }
  },
  {
    condition: (_, { getState }) => getState().externalUsers.panels.search.loaded === false,
  }
);

const orderByName = (array) =>
  array.sort((a, b) => {
    const nameA = a.label.toUpperCase(); // ignore upper and lowercase
    const nameB = b.label.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

const initialState = {
  error: null,
  loading: false,
  data: [],
  panels: {
    userId: null,
    teams: [],
    projects: [],
    loading: false,
    detail: {},
    search: {
      loaded: false,
      teams: [],
      projects: [],
      accounts: [],
      organizations: [],
    },
  },
  filters: {
    page: 1,
    page_size: 10,
    sort_by: {},
  },
  showBulkUploadUsers: false,
};

const slice = createSlice({
  name: 'externalUsers',
  initialState,
  reducers: {
    resetExternalUsers: () => initialState,
    setExternalPageFilter(state, action) {
      state.filters.page = action.payload;
    },
    setExternalSearch(state, action) {
      state.filters.search = action.payload;
    },
    setExternalSortBy(state, { payload }) {
      const { label, value } = payload;

      if (value === '' || value === null) {
        delete state.filters.sort_by[label];
      } else {
        state.filters.sort_by[label] = value;
      }
    },
    setExternalFilterValue(state, { payload }) {
      const { param, value } = payload;
      if (value === '' || value === null) {
        delete state.filters[param];
        return;
      }
      state.filters[param] = payload.value;
    },
    setExternalFilters(state, action) {
      const filter = { ...state.filters, ...action.payload, page: 1 };
      state.filters = filter;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getExternalUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getExternalUsers.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.userCount = action.payload.meta.totalCount;
      state.error = null;
      state.loading = false;
    });
    builder.addCase(getExternalUsers.rejected, (state) => {
      state.data = [];
      state.error = 'There was an error during the request.';
      state.loading = false;
    });
    builder.addCase(preloadExternalFilterDatasets.pending, (state) => {
      state.panels.search.loaded = false;
    });
    builder.addCase(preloadExternalFilterDatasets.rejected, (state) => {
      state.panels.search.accounts = [];
      state.panels.search.organizations = [];
      state.panels.search.teams = [];
      state.panels.search.projects = [];
      state.panels.search.loaded = false;
    });
    builder.addCase(preloadExternalFilterDatasets.fulfilled, (state, { payload }) => {
      const { accounts, organizations, teams, projects } = payload;
      state.panels.search.loaded = true;
      let newAccounts = accounts.map((acct) => ({
        label: acct.name,
        value: acct.id,
      }));
      newAccounts = orderByName(newAccounts);
      state.panels.search.accounts = newAccounts;
      let newOrgs = organizations.map((org) => ({
        label: org.name,
        value: org.id,
      }));
      newOrgs = orderByName(newOrgs);
      state.panels.search.organizations = newOrgs;
      const newTeams = teams.map((team) => ({
        label: team.name,
        value: team.id,
      }));
      state.panels.search.teams = orderByName(newTeams);
      const newProjects = projects.map((proj) => ({
        label: proj.name,
        value: proj.id,
      }));
      state.panels.search.projects = orderByName(newProjects);
    });
  },
});

export const {
  setExternalPageFilter,
  setExternalSortBy,
  setExternalSearch,
  resetExternalUsers,
  setExternalFilterValue,
  setExternalFilters,
  // eslint-disable-next-line prettier/prettier
} = slice.actions;

export default slice.reducer;

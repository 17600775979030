import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  permission: {
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem 0',
  },
});

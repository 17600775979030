import ApiService from './ApiService';

export default class InspectionService {
  static getInspections = (filters) => ApiService.get(`/inspections`, filters);

  static createInspection = async (inspection) => ApiService.post(`/inspections`, inspection);

  static updateInspection = async (inspectionId, inspection) =>
    ApiService.patch(`/inspections/${inspectionId}`, inspection);

  static deleteInspection = async (inspectionId, params) =>
    ApiService.post(`/inspections/${inspectionId}/delete`, params);

  static assignAsset = async (inspectionId, params) => ApiService.post(`/inspections/${inspectionId}/asset`, params);

  static assignProject = async (params) => ApiService.post(`/inspections/assign`, params);

  static questionTypes = () => ApiService.get(`/inspections/all/questionTypes`);

  static getInspectionUsers = (filters) => ApiService.get(`/inspections/users`, filters);

  // inspection in projects
  static getProjectAssetInspections = (filters) => ApiService.get(`/project-asset-inspections`, filters);

  static getInspectionsInProject = (projectId) => ApiService.get(`/inspections/${projectId}/inspections`);

  static getProjectsInInspection = (inspectId) => ApiService.get(`/inspections/${inspectId}/projects`);

  static deleteProjectAssetInspection = async (projectAssetInspectionId, projId) =>
    ApiService.delete(`/project-asset-inspections/${projectAssetInspectionId}/${projId}`);

  static deleteInspectionProject = async (inspectionId, projId) =>
    ApiService.delete(`/project-asset-inspections/${inspectionId}/inspection/${projId}`);

  static assignUserProjectAssetInspection = async (paiId, userId) =>
    ApiService.post(`/project-asset-inspections/${paiId}/user/${userId}`);

  static reAssignUserProjectAssetInspection = async (paiId, userId) =>
    ApiService.patch(`/project-asset-inspections/${paiId}/user/${userId}`);

  static getProjectAssetInspectionProgress = async (paiId, body) =>
    ApiService.post(`/project-asset-inspections/${paiId}/progress`, body);

  static getAssetInspections = (filters) => ApiService.get(`/project-asset-inspections/inspections`, filters);

  static getProjAssetInspectionsAssets = (filters) => ApiService.get(`/project-asset-inspections/assets`, filters);

  static getProjAssetInspectionsUsers = (filters) => ApiService.get(`/project-asset-inspections/users`, filters);

  // inspection findings
  static createFinding = async (paiId, body) => ApiService.post(`/project-asset-inspections/${paiId}/findings`, body);

  static getProjectAssetInspectionFindings = (paiId, filters) =>
    ApiService.get(`/project-asset-inspections/${paiId}/findings`, filters);

  static getFinding = async (paiId, paifId) => ApiService.get(`/project-asset-inspections/${paiId}/findings/${paifId}`);

  static getFindingImage = async (paiId, paifId) =>
    ApiService.get(`/project-asset-inspections/${paiId}/findings/${paifId}/image`, null, {
      responseType: 'blob',
    });

  static getFindingImageUrl = async (paiId, paifId) =>
    ApiService.get(`/project-asset-inspections/${paiId}/findings/${paifId}/imageUrl`);

  static updateFinding = async (paiId, paifId, body) =>
    ApiService.patch(`/project-asset-inspections/${paiId}/findings/${paifId}`, body);

  static deleteFinding = async (paiId, id, projectId) =>
    ApiService.delete(`/project-asset-inspections/${paiId}/findings/${id}/${projectId}`);

  static exportFindings = async (paiId) => ApiService.get(`/project-asset-inspections/${paiId}/findings/export`);

  static getInspectionFindings = async (inspId) => ApiService.get(`/inspections/${inspId}/findings`);
}

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Chevron,
  SidebarPanelSectionButton,
  SidebarPanelSectionChevDown,
  SidebarPanelSectionChevUp,
  SidebarPanelSectionContent,
  SidebarPanelSectionHeader,
} from './styles';

export const SidebarPanelSection = ({ title, children, actions, accordion, padding, alignment, nested }) => {
  const [open, setOpen] = useState(true);

  return (
    <>
      <SidebarPanelSectionHeader onClick={() => accordion && setOpen(!open)} accordion={accordion} nested={nested}>
        {title}
        <div>
          {actions &&
            actions.map((action) => (
              <SidebarPanelSectionButton
                key={action.name}
                onClick={(e) => {
                  e.stopPropagation();
                  action.onClick(e);
                }}
              >
                <action.icon />
              </SidebarPanelSectionButton>
            ))}{' '}
          <Chevron accordion={accordion}>
            {open === false ? <SidebarPanelSectionChevDown size={12} /> : <SidebarPanelSectionChevUp size={12} />}
          </Chevron>
        </div>
      </SidebarPanelSectionHeader>
      {open && (
        <SidebarPanelSectionContent padding={padding} alignment={alignment}>
          {children}
        </SidebarPanelSectionContent>
      )}
    </>
  );
};

SidebarPanelSection.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  actions: PropTypes.instanceOf(Array),
  accordion: PropTypes.bool,
  padding: PropTypes.number,
  alignment: PropTypes.oneOf(['left', 'center', 'right']),
  nested: PropTypes.bool,
};

SidebarPanelSection.defaultProps = {
  children: null,
  title: '',
  actions: [],
  accordion: false,
  padding: 1,
  alignment: 'left',
  nested: false,
};

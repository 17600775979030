import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

const initialState = { alerts: [] };

export const removeAlert = (id) => (dispatch) => {
  dispatch({
    type: 'alert/removeAlert',
    payload: id,
  });
};

export const alertSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    setAlert: (state, action) => {
      const id = uuidv4();
      const msg = action.payload[0];
      const alertType = action.payload[1];

      const payload = { msg, alertType, id };
      state.alerts.push(payload);
    },

    [removeAlert]: (state, action) => {
      state.alerts.filter((alert) => alert.id !== action.payload);
    },
  },
});

export const selectAlerts = (state) => state.alert.alerts;

export const { setAlert } = alertSlice.actions;
export default alertSlice.reducer;

import React, { useState, useEffect } from 'react';
import localforage from 'localforage';
import { AccountSettingsContainer } from 'components/AccountSettingsContainer/AccountSettingsContainer';
import Dropzone from 'react-dropzone';
import UserService from 'services/UserService';
import OrganizationService from 'services/OrganizationService';
import Papa from 'papaparse';
import { DialogMessage } from 'components/DialogMessage/DialogMessage';
import { DoubleCheckIcon, InfoIcon, UploadIcon } from 'components/Icons';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import AccountService from 'services/AccountService';
import { useGlobalStyles } from 'styles';
import ReportsSplash from 'images/adminDashboard.png';
import { Card } from 'components/Card';
import { AdminInfoWidget } from './AdminInfoWidget';
import { OrganizationWidget } from './OrganizationWidget';
import { ProjectsWidget } from './ProjectsWidget';
import { QuickActionWidget } from './QuickActionWidget';
import {
  UploadAreaContainer,
  UploadFilesOuterContainer,
  AddNew,
  StyledPlus,
  StyledPlusText,
  useStyles,
} from './styles';

export const Dashboard = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const [showModal, setShowModal] = useState(false);
  const [orgs, setOrgs] = React.useState([]);
  const [orgSelected, setOrgSelected] = useState([]);
  const [file, setFile] = useState([]);
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [msgError, setMsgError] = useState('');
  const [msgSuccess, setMsgSuccess] = useState('');
  const [accountOrg, setAccountOrg] = useState('');
  // const acctId = useSelector((state) => state.adminProjects.filters.acctId || '');

  const onFileCancel = () => {
    setShowModal(false);
  };
  const onFileSave = () => {
    Papa.parse(file[0], {
      header: true,
      skipEmptyLines: true,
      complete(results) {
        results.data.map(async (row) => {
          const data = {
            firstName: row.firstName,
            lastName: row.lastName,
            email: row.email,
            message: row.message,
          };
          try {
            const userResponse = await UserService.createUser(data);
            await OrganizationService.addUser(orgSelected, {
              userId: userResponse.data.data.id,
              roles: [9],
            });
            setOpenSuccess(true);
            setMsgSuccess(userResponse.data.data);
          } catch (err) {
            const result = err?.response?.data?.message;
            setMsgError(result);
            setOpenError(true);
            // eslint-disable-next-line
            console.error('error', JSON.stringify(err));
          }
          return true;
        });
      },
    });
  };

  const onBulkUsersUpload = () => {
    setShowModal(true);
  };

  const responseParser = (organizations) =>
    organizations.map((org) => ({
      value: org.id,
      label: org.name,
    }));

  const fetchOrg = async () => {
    const orgAccount = await AccountService.getAccount();
    setAccountOrg(orgAccount.data.data);
    const response = await OrganizationService.getOrganizations({ page_size: 500 });
    localforage.setItem('orgId', JSON.stringify(response.data.data[0]?.id));
    setOrgs(responseParser(response.data.data));
  };

  useEffect(() => {
    fetchOrg();
  }, []);

  const gotSomeFiles = async (acceptedFiles) => {
    setFile(acceptedFiles);
  };

  return (
    <AccountSettingsContainer title="ADMINISTRATION">
      <Card noPadding>
        <div className={classes.dashContent}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={12} lg={3.5} xl={3.5}>
              <AdminInfoWidget />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8.5} xl={8.5}>
              <div>
                <div className={classes.imgTitle}>
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Typography variant="h1" mt={0.5}>
                        {accountOrg.name}
                      </Typography>
                      <Typography variant="h3" className={globalClasses.greyTypography} mt={0.2}>
                        Collaborate, Participate &amp; Make Magic
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} align="center">
                      <img src={ReportsSplash} alt="Reports Splash" width="60%" />
                    </Grid>
                  </Grid>
                </div>
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    {/* Quick Actions Widget */}
                    <QuickActionWidget />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <div className={classes.flexCards}>
                      <div style={{ flex: 1 }}>
                        {/* Company Organization Widget */}
                        <OrganizationWidget />
                      </div>
                      {/* Most resource-intensive projects Widget */}
                      <ProjectsWidget />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </Card>
      <>
        {true === false && (
          <AddNew onClick={onBulkUsersUpload}>
            <StyledPlus>+</StyledPlus>
            <StyledPlusText>Import Users</StyledPlusText>
          </AddNew>
        )}
        {showModal && (
          <Dialog open onClose={onFileCancel}>
            <DialogTitle>
              <Typography variant="h4">Import Users</Typography>
              <Typography variant="h5">Upload and attach files to this project</Typography>
            </DialogTitle>
            <DialogContent>
              <Button
                color="secondary"
                variant="outlined"
                href="https://storage.googleapis.com/cvdev-assets/sample_users.csv"
                download
              >
                Get Template
              </Button>
              <UploadFilesOuterContainer style={{ padding: `10px` }}>
                <FormControl fullWidth>
                  <Autocomplete
                    label="Add to an Organization"
                    name="organization"
                    options={orgs}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField {...params} label="Add to an Organization" placeholder="Select One" required />
                    )}
                    value={orgs.find((u) => u.value === orgSelected)}
                    onChange={(e, value) => {
                      setOrgSelected(value?.value || '');
                    }}
                  />
                </FormControl>
              </UploadFilesOuterContainer>
              <UploadFilesOuterContainer>
                <Dropzone
                  onDrop={gotSomeFiles}
                  acceptedFiles=".csv,.xls,.xlsx"
                  style={{ padding: 50, backgroundColor: 'orange' }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <UploadIcon size={46} className="UploadIcon" />
                        <input {...getInputProps()} />
                        <p>Drag and drop some files here, or click to select files</p>
                      </div>
                      <UploadAreaContainer style={{ width: file.length > 0 ? '80%' : '1%' }}>
                        <p style={{ color: 'orange', display: 'inline-block' }}> </p>
                      </UploadAreaContainer>{' '}
                      <p style={{ color: 'white', display: 'inline-block', position: 'relative', top: '30%' }}>
                        {file.length > 0 ? '100%' : '0%'}
                      </p>
                      <div style={{ position: 'relative' }}>
                        {file.map((i) => (
                          <div>
                            <b>File:</b> {i.name} (size: {i.size})
                          </div>
                        ))}
                      </div>
                    </section>
                  )}
                </Dropzone>
              </UploadFilesOuterContainer>
            </DialogContent>
            <DialogActions>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Button color="primary" variant="contained" onClick={onFileCancel} size="large" fullWidth>
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button color="secondary" variant="contained" onClick={onFileSave} size="large" fullWidth>
                    Save
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
            {openError && (
              <DialogMessage
                title={msgError}
                isOpen={openError}
                icon={DoubleCheckIcon}
                confirmText="Ok"
                onConfirm={() => {
                  setOpenError(false);
                  setShowModal(false);
                }}
              />
            )}
            {openSuccess && (
              <DialogMessage
                title={msgSuccess}
                isOpen={openSuccess}
                icon={InfoIcon}
                confirmText="Ok"
                onConfirm={() => {
                  setOpenSuccess(false);
                  setShowModal(false);
                }}
              />
            )}
          </Dialog>
        )}

        {/** Banner Image Widget 
        <SplashWidget row={1} col={2} rowspan={1} colspan={2} /> */}
      </>
    </AccountSettingsContainer>
  );
};

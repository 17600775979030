export class Bytes {
  static dynamicHumanReadable = ({ bytes, decimalPoint }) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1000;
    const dm = decimalPoint || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const total = parseFloat((bytes / k ** i).toFixed(dm));
    return `${total} ${sizes[i]}`;
  };
}

/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { AccountSettingsContainer } from 'components/AccountSettingsContainer/AccountSettingsContainer';
import { CirclePlusIcon } from 'components/Icons';
import { Loader } from 'components/Loader';
import { useSidebar } from 'components/SidebarProvider/SidebarProvider';
import { TablePagination } from 'components/TablePagination/TablePagination';
import { ViewRoleValidation } from 'components/ViewRoleValidation/ViewRoleValidation';
import { WidgetSplash } from 'components/WidgetSplash/WidgetSplash';
import SplashImage from 'images/ContentSplash.png';
import { Bytes } from 'lib/bytes';
import { useDispatch, useSelector } from 'react-redux';
import { usePagination, useSortBy, useTable } from 'react-table';
import { getProject, getProjects, setPageFilter, setSearch } from 'slices/allProjectReducer';
import { setAssetsData, setMapExtent } from 'slices/profileProjectAssetReducer';
import { ModalProjectNew } from 'smartComponents/ModalProjectNew';
import { ProjectActions } from 'components/ProjectActions/ProjectActions';
import { Permissions } from 'lib/permissions';
import { GeneralTable } from 'components/GeneralTable/GeneralTable';
import { TopBar } from 'components/TopBar/TopBar';
import { Card } from 'components/Card';
import { ProjectDetail } from 'components/ProjectDetail/ProjectDetail';
import { useSidebarDetail } from 'components/SidebarProviderDetail/SidebarProviderDetail';
import { setProject as setProjectActive } from 'slices/projectActiveInspectionsReducer';
import { setProject } from 'slices/projectInspectionReducer';
import { ProjectInspections } from 'components/Inspections/ProjectInspections/ProjectInspections';
import { ProjectActiveInspections } from 'components/Inspections/ProjectInspections/ProjectActiveInspections';
import { SearchPanel } from './SearchPanel';
import { InfoPanel } from './InfoPanel';

const tableColumns = () => [
  {
    Header: 'Name',
    accessor: 'name', // accessor is the "key" in the data
  },
  {
    Header: 'Description',
    accessor: (rowData) => {
      const { description } = rowData;
      return description || 'No Description Found.';
    },
  },
  {
    Header: 'Account',
    accessor: (rowData) => {
      const { account } = rowData;
      return account ? account.name : '';
    },
  },
  {
    Header: 'Organization',
    accessor: (rowData) => {
      const { organization } = rowData;
      return organization ? organization.name : '';
    },
  },
  {
    Header: 'Size',
    accessor: (rowData) => {
      const { fileTotalSize } = rowData;
      return Bytes.dynamicHumanReadable({ bytes: fileTotalSize });
    },
  },
  {
    Header: 'Files',
    accessor: 'fileHostedCount',
  },
  {
    Header: 'Observations',
    accessor: 'observationsCount',
  },
  {
    Header: 'Collaborators',
    accessor: (rowData) => {
      const { collaborators } = rowData;
      return collaborators?.length || 0;
    },
  },
];

let debounceTimeout = null;
export const Projects = () => {
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState('');
  const [openAddProject, setOpenAddProject] = useState(false);
  const { loading, data, filters, totalCount } = useSelector((state) => state.allProjects);
  const { showObservationDetailFromUrl } = useSelector((state) => state.assetObservations);
  const [validateRole, setValidateRole] = useState({ open: false, callBack: () => {} });
  const search = useSelector((state) => state.allProjects.filters.search || '');
  const handleSearch = (e) => dispatch(setSearch(e.target.value));

  const loadData = () => {
    const sendFilters = {
      ...filters,
      sort_by: Object.values(filters.sort_by),
    };
    dispatch(getProjects(sendFilters));
  };

  useEffect(() => {
    clearTimeout(debounceTimeout);

    debounceTimeout = setTimeout(() => {
      dispatch(getProjects({ ...filters }));
    }, 300);
    dispatch(setMapExtent([]));
    dispatch(setAssetsData([]));
  }, [filters]);

  const handleConfirmAddProject = () => {
    setOpenAddProject(false);
    loadData();
  };

  const handleCancelAddProject = () => {
    setOpenAddProject(false);
  };

  const columns = React.useMemo(() => tableColumns(), []);
  const table = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: filters.page - 1,
        pageSize: filters.page_size,
      },
      pageCount: Math.ceil(totalCount / filters.page_size),
      totalCount,
      pageLength: data.length,
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  const {
    state: { pageIndex, pageSize },
  } = table;

  useEffect(() => {
    dispatch(setPageFilter(pageIndex + 1));
  }, [pageIndex, pageSize]);

  const { displayPanel, setHeaderPanels, setFullWidth } = useSidebar({
    open: false,
    visible: false,
    config: {
      onClose: () => {},
    },
  });

  const {
    displayPanel: displayPanelDetail,
    setHeaderPanels: setHeaderPanelsDetail,
    setFullWidth: setFullWidthDetail,
  } = useSidebarDetail({
    open: false,
    visible: false,
    config: {
      onClose: () => {},
      search: {
        show: true,
        component: SearchPanel,
      },
    },
  });

  const handleRowClick = async (rowData) => {
    // dispatch redux action to load user data
    // set sidebar active panel to user info and open if necessary
    setSelectedRow(rowData.id);
    dispatch(getProject(rowData.original.id));
    setHeaderPanels([
      {
        name: 'info',
        component: InfoPanel,
      },
    ]);
    setFullWidth(false);
    displayPanelDetail('');
    displayPanel('info');
  };

  const projectDetail = (project) => {
    dispatch(
      project.openInspection
        ? setProject(project)
        : project.openActiveInspection
        ? setProjectActive(project)
        : getProject(project.id)
    );
    const displayHeader = project.openInspection
      ? 'projectInspections'
      : project.openActiveInspection
      ? 'projectActiveInspections'
      : 'projectDetail';
    setHeaderPanelsDetail([
      {
        name: displayHeader,
        component: project.openInspection
          ? ProjectInspections
          : project.openActiveInspection
          ? ProjectActiveInspections
          : ProjectDetail,
      },
    ]);
    setFullWidthDetail(true);
    displayPanelDetail(displayHeader);
  };

  // open project from url
  useEffect(() => {
    if (showObservationDetailFromUrl) {
      const params = new URLSearchParams(window.location.search);
      const projectId = params.get('projectId');
      if (projectId) {
        projectDetail({ id: projectId });
      }
    }
  }, []);

  const EmptyProjectsContainer = () => (
    <>
      <WidgetSplash
        alt="Projects Splash"
        title="Does your company have any projects that need to be uploaded? Fine-tune your control by adding them now."
        image={SplashImage}
        cta="Add a Project"
        onClick={() =>
          setValidateRole({
            open: true,
            action: [Permissions.ORG_PROJ_CREATE],
            callBack: () => setOpenAddProject(true),
          })
        }
      />
      {openAddProject && (
        <ModalProjectNew
          isModalOpen={openAddProject}
          onConfirm={handleConfirmAddProject}
          onCancel={handleCancelAddProject}
        />
      )}
    </>
  );

  const ProjectsContainer = () => (
    <>
      <GeneralTable
        table={table}
        selectedRow={selectedRow}
        handleRowClick={handleRowClick}
        rowComponent={ProjectActions}
        actionFunction={(project) => (project ? projectDetail(project) : loadData())}
        selectRow={false}
      />
      <TablePagination table={table} />
      {openAddProject && (
        <ModalProjectNew
          isModalOpen={openAddProject}
          onConfirm={handleConfirmAddProject}
          onCancel={handleCancelAddProject}
        />
      )}
    </>
  );

  if (loading && data.length === 0) {
    return <Loader loading={loading} height={250} />;
  }
  return (
    <AccountSettingsContainer
      title="ALL PROJECTS"
      actions={[
        {
          icon: CirclePlusIcon,
          text: 'Add New',
          onClick: () =>
            setValidateRole({
              open: true,
              action: [Permissions.ORG_PROJ_CREATE],
              callBack: () => setOpenAddProject(true),
            }),
        },
      ]}
      showSidebar
      hideMenu
    >
      <Card noPadding>
        <TopBar
          tableCardToggle
          searchValue={search}
          handleSearch={handleSearch}
          activatedFilter={filters.activated}
          sideBarDetail
        />
        {data.length > 0 ? <ProjectsContainer /> : <EmptyProjectsContainer />}
      </Card>
      {validateRole.open && (
        <ViewRoleValidation
          action={validateRole.action}
          callBack={validateRole.callBack}
          handleClose={() => setValidateRole({ open: false, callBack: () => {} })}
        />
      )}
    </AccountSettingsContainer>
  );
};

import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SidebarContext } from 'components/SidebarProvider/SidebarProvider';
import { SearchPanelBody, searchPanelSection } from 'pages/Profile/SingleProject/SearchPanel/SearchPanelBody';
import { setFilters, setFilterValue, preloadFilterDatasets, dateRangeSelector } from 'slices/contentAdminReducer';
import {
  setFilters as setExternalFilters,
  setFilterValue as setExternalFilterValue,
  preloadFilterDatasets as preloadExteralFilterDatasets,
  dateRangeSelector as externalDateRangeSelector,
} from 'slices/externalContentReducer';

export const SearchPanel = () => {
  const dispatch = useDispatch();
  const ctx = useContext(SidebarContext);
  const external = ctx?.config?.search?.external;
  const reduce = external ? 'externalContents' : 'adminContents';
  const nameSort = useSelector((state) => state[reduce].filters.sort_by.name || '');
  const dateSort = useSelector((state) => state[reduce].filters.sort_by.createdAt || '');
  const accountSort = useSelector((state) => state[reduce].filters.sort_by.account || '');
  const userId = useSelector((state) => state[reduce].filters.userId || '');
  const teamId = useSelector((state) => state[reduce].filters.teamId || '');
  const acctId = useSelector((state) => state[reduce].filters.acctId || '');
  const projectId = useSelector((state) => state[reduce].filters.projectId || '');
  const organizationId = useSelector((state) => state[reduce].filters.organizationId || '');
  const ownership = useSelector((state) => state[reduce].filters.ownership || '');
  const type = useSelector((state) => state[reduce].filters.type || '');
  const dateRange = useSelector(external ? externalDateRangeSelector : dateRangeSelector);
  const { users, teams, projects, organizations, accounts, loaded } = useSelector(
    (state) => state[reduce].panels.search
  );

  useEffect(() => {
    dispatch(external ? preloadExteralFilterDatasets() : preloadFilterDatasets());
  }, []);

  return (
    <SearchPanelBody
      loaded={loaded}
      users={users}
      accounts={accounts}
      teams={teams}
      projects={projects}
      organizations={organizations}
      filters={{
        sort_by: {
          name: nameSort,
          createdAt: dateSort,
          account: accountSort,
        },
        date_range: dateRange,
        userId,
        teamId,
        acctId,
        ownership,
        type,
        organizationId,
        projectId,
      }}
      setFilterValue={external ? setExternalFilterValue : setFilterValue}
      setFilters={external ? setExternalFilters : setFilters}
      section={external ? searchPanelSection.EXTERNALCONTENT : searchPanelSection.ADMINCONTENT}
    />
  );
};

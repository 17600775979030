import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.primary[1300],
    '& button': {
      color: theme.palette.white,
    },
  },
  filters: {
    display: 'flex',
  },
  input: {
    width: '300px',
    '& svg': {
      marginRight: `${theme.spacing(0.2)}!important`,
    },
    fontSize: 12,
    height: '50%',
    flex: 1,
  },
  filterButton: {
    marginLeft: `${theme.spacing(0.5)}!important`,
    height: 40,
    '& svg': {
      marginRight: `${theme.spacing(0.2)}!important`,
    },
  },
  activatedFilter: {
    color: `${theme.palette.secondary[500]}!important`,
    border: `1px solid ${theme.palette.secondary[500]}`,
  },
}));

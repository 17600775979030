import PropTypes from 'prop-types';
import React from 'react';
import { Button, Typography } from '@mui/material';
import { useStyles } from './styles';

export const WidgetHeader = ({
  title,
  subTitle,
  smallText,
  border,
  link,
  actionClick,
  // eslint-disable-next-line no-unused-vars
  actionIcon: IconComponent,
  actionTitle,
}) => {
  const classes = useStyles({ border });

  return (
    <div className={classes.header}>
      <div className={classes.title}>
        <Typography variant="h5">{title}</Typography>
      </div>
      <div className={classes.subTitle}>
        {smallText ? (
          <Typography variant="caption" mb={0.5}>
            {smallText}
          </Typography>
        ) : (
          <Typography variant="body1" mb={0.5}>
            {subTitle}
          </Typography>
        )}
      </div>

      {link && (
        <Button color="primary" variant="contained" onClick={actionClick} className={classes.button}>
          <Typography variant="caption">{actionTitle}</Typography>
        </Button>
      )}
    </div>
  );
};

WidgetHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  smallText: PropTypes.string,
  border: PropTypes.bool,
  actionIcon: PropTypes.func,
  actionClick: PropTypes.func,
  actionTitle: PropTypes.string,
  link: PropTypes.bool,
};

WidgetHeader.defaultProps = {
  border: false,
  link: false,
  actionIcon: null,
  actionClick: null,
  actionTitle: '',
  smallText: '',
};

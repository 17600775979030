import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { ConfirmButton } from 'smartComponents/ModalProjectSettings/ConfirmButton';
import { Typography } from '@mui/material';
import { ProjectSharingForm } from 'smartComponents/ModalProjectSettings/ProjectSharingForm';
import { useStyles } from '../styles';

const validationSchema = Yup.object({
  isSharingNotAllowed: Yup.boolean(),
  isCopyingNotAllowed: Yup.boolean(),
});

export const ProjectSharing = ({ project }) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [submitError, setSubmitError] = React.useState('');
  const [change, setChange] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      isVisible: project.isVisible,
      isOpenProject: project.isOpen,
      isSharingNotAllowed: project.isSharingNotAllowed,
      isCopyingNotAllowed: project.isCopyingNotAllowed,
    },
    validationSchema,
    enableReinitialize: true,
  });

  return (
    <div>
      <Typography mb={0.3}>Content Sharing & Copying</Typography>
      <div className={classes.sharingContent}>
        <ProjectSharingForm formik={formik} loading={loading} handleChange={(value) => setChange(value)} />
        {submitError && (
          <Typography color="error" mt={0.5} mb={0.5}>
            {submitError}.
          </Typography>
        )}
      </div>
      <div className={classes.visibilityButton}>
        <ConfirmButton
          values={formik.values}
          projectId={project.id}
          setLoading={(value) => setLoading(value)}
          setSubmitError={(value) => setSubmitError(value)}
          handleChange={(value) => setChange(value)}
          isChanging={change}
          detail
        />
      </div>
    </div>
  );
};

ProjectSharing.propTypes = {
  project: PropTypes.object,
};

ProjectSharing.defaultProps = {
  project: { id: null, name: '' },
};

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  groupOption: {
    backgroundColor: `${theme.palette.primary[1000]}`,
    color: theme.palette.primary[100],
    position: 'sticky',
    padding: '4px 10px',
    borderTop: `1px solid ${theme.palette.primary[300]}`,
  },
  list: {
    maxHeight: '15vh',
    overflowY: 'auto',
    margin: `${theme.spacing(0.5)} 0`,
  },
  listItem: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
    margin: `${8}px 0`,
    alignItems: 'center',
  },
  listName: {
    flex: '1 0',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
    alignItems: 'center',
    '& .description': {
      color: theme.palette.primary[500],
      fontWeight: 400,
    },
    '& .text': {
      flex: 1,
    },
  },
  listButton: {
    color: theme.palette.danger[500],
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.danger.main,
    },
  },
}));

import React, { useEffect, useState } from 'react';
import { AccountSettingsContainer } from 'components/AccountSettingsContainer/AccountSettingsContainer';
import { AdminHeaderTitle } from 'components/AdminHeaderTitle/AdminHeaderTitle';
import { TopBar } from 'components/TopBar/TopBar';
import { Card } from 'components/Card/Card';
// eslint-disable-next-line no-unused-vars
import { ChevronDownIcon, ChevronUpIcon, DoubleCheckIcon, CloseIcon } from 'components/Icons';
import { ModalHeaderIcon } from 'components/ModalHeaderIcon/ModalHeaderIcon';
// eslint-disable-next-line no-unused-vars
import {
  Chip,
  CardContent,
  Typography,
  CardActions,
  Button,
  Card as CardMui,
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@mui/material';
// import { ArrowDownIcon } from 'components/Icons/ArrowDownIcon';
import { SquareArrow } from 'components/Icons/SquareArrow';
import BillingService from 'services/BillingService';
import { TablePagination } from 'components/TablePagination/TablePagination';
import { usePagination, useSortBy, useTable } from 'react-table';
import { BillingActions } from 'components/BillingActions/BillingActions';
import { GeneralTable } from 'components/GeneralTable/GeneralTable';
import { useSelector, useDispatch } from 'react-redux';
import { ModalCreditsPurchasing } from 'smartComponents/ModalCreditsPurchasing';
// eslint-disable-next-line no-unused-vars
import { fetchCreditUsage, setPageFilter, setSearch } from 'slices/creditUsageReducer';
import AccountService from 'services/AccountService';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as FileSaver from 'file-saver';
// eslint-disable-next-line import/no-extraneous-dependencies
import XLSX from 'sheetjs-style';
// eslint-disable-next-line import/no-extraneous-dependencies, no-unused-vars
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import GreenIcon from '../../../images/GreenIcon.svg';
import { Loader } from 'components/Loader';
import { useHistory } from 'react-router';

const tableColumns = () => [
  {
    Header: 'Type',
    accessor: (rowData) => (
      <Chip
        label={`${rowData.credits_type}`}
        color="secondary"
        style={{ fontSize: 14, backgroundColor: `${rowData.credits_type === 'Processing' ? '#FFB84D' : '#FF6B00'}` }}
      />
    ),
  },
  {
    Header: 'Credit Use Date',
    accessor: (rowData) => {
      // eslint-disable-next-line camelcase
      const { created_date } = rowData;
      const tmpDate = created_date.replace('T', ' ');
      const tmpDate2 = tmpDate.split('.')[0];
      const tmpDate3 = tmpDate2.split(' ')[0];
      const tmpData4 = tmpDate3?.split('-');
      console.info(tmpData4);
      // eslint-disable-next-line camelcase
      return `${tmpData4[1]}/${tmpData4[2]}/${tmpData4[0]}`;
    },
  },
  {
    Header: 'Credits Used',
    accessor: (rowData) => {
      // eslint-disable-next-line camelcase
      const { credits_used } = rowData;
      // eslint-disable-next-line camelcase
      return `${credits_used === 0 ? parseFloat('1').toFixed(2) : credits_used}` || '';
    },
  },
  {
    Header: 'File Size',
    accessor: (rowData) => {
      // eslint-disable-next-line camelcase
      const { file_size_mb } = rowData;
      // eslint-disable-next-line camelcase
      return `${file_size_mb} MB` || '';
    },
  },
  {
    Header: 'File Type',
    accessor: (rowData) => {
      // eslint-disable-next-line camelcase
      const { file_type } = rowData;
      // eslint-disable-next-line camelcase
      return `${file_type}` ?? ``;
    },
  },
  /*   {
    Header: 'Original Size',
    accessor: (rowData) => {
      // eslint-disable-next-line camelcase
      const { original_file_size_mb } = rowData;
      // eslint-disable-next-line camelcase
      return original_file_size_mb;
    },
  }, */
];

export const CreditUsage = () => {
  const columns = React.useMemo(() => tableColumns(), []);
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState([]);
  const [row, setRow] = useState({});
  const { records, loading, filters, recordsCount } = useSelector((state) => state.creditUsage);
  const search = filters.search || '';
  const [accountOrg, setAccountOrg] = useState('');
  const [avg, setAvg] = useState();
  const [graph, setGraph] = useState([]);
  const [expanded, setExpanded] = useState(true);
  // const [hideNumbers, setHideNumbers] = useState(false);
  const [creditsPurchasingModal, setCreditsPurchasingModal] = useState(false);
  const [creditsChoosen, setCreditsChoosen] = React.useState('');
  const [openPaymentSuccess, setOpenPaymentSuccess] = React.useState('');
  const [paymentError, setPaymentError] = React.useState('');
  const dispatch = useDispatch();

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToExcel = async (dataToExport, fileName) => {
    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const tmpData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(tmpData, `${fileName}${fileExtension}`);
  };

  const table = useTable(
    {
      columns,
      data: records,
      initialState: {
        pageIndex: filters.page - 1,
        pageSize: filters.page_size,
      },
      pageCount: Math.ceil(records?.length / filters.page_size),
      totalCount: recordsCount,
      pageLength: records?.length,
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  const {
    state: { pageIndex, pageSize },
  } = table;

  const fetchData = (filterData) => {
    const sendFilters = {
      ...filterData,
      sort_by: Object.values(filterData.sort_by),
    };
    dispatch(fetchCreditUsage(sendFilters));
  };

  const params = window.location.href?.split('?')[1];

  useEffect(() => {
    if (params?.split('&')[0] === 'paymentSuccess=true') {
      const credits = params?.split('&')[1]?.split('=')[1];
      setCreditsChoosen(credits.replace(',', ''));

      // Call the api to update the credits
      AccountService.updateCredits(parseFloat(credits.replace(',', '')));

      setOpenPaymentSuccess(true);
    } else if (params?.split('&')[0] === 'paymentSuccess=false') {
      setPaymentError(true);
    }
  }, []);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      fetchData(filters);
    }, 150);
    return () => clearTimeout(debounceTimeout);
  }, [filters]);

  useEffect(() => {
    dispatch(setPageFilter(pageIndex + 1));
  }, [pageIndex, pageSize]);

  const getCreditUsage = async () => {
    const results = await BillingService.getCreditUsage({ filters });
    // eslint-disable-next-line
    // dispatch(getCreditUsage({ filters: filters }));
    // console.log(results);
    setData(results.data.data);
  };

  const getCreditsAvg = async () => {
    const results = await BillingService.getCreditAvg();
    setAvg(results.data.data);
  };

  const getCreditsGraph = async () => {
    const results = await BillingService.getCreditGraph();
    const res = [];
    results?.data?.data?.map((item) => {
      const found = res.findIndex((i) => i.name === item.monthyear);
      if (found >= 0) {
        if (item.credits_type === 'Processing') res[found].processing = item.credits;
        if (item.credits_type === 'Storage') res[found].storage = item.credits;
      } else {
        res.push({
          name: item.monthyear,
          processing: item.credits_type === 'Processing' && item.credits,
          storage: item.credits_type === 'Storage' && item.credits,
          ai: 0,
        });
      }
      return res;
    });
    setGraph(res);
  };

  const getAdminsDetails = async () => {
    const orgAccount = await AccountService.getAccount();
    setAccountOrg(orgAccount.data.data);
  };

  const handleSearch = (e) => dispatch(setSearch(e.target.value));

  const onBuyCredits = async () => setCreditsPurchasingModal(true);

  useEffect(() => {
    getAdminsDetails();
    getCreditUsage();
    getCreditsAvg();
    getCreditsGraph();
    dispatch(setSearch());
  }, []);

  const history = useHistory();

  return (
    <AccountSettingsContainer
      title={<AdminHeaderTitle buttonName="ADMINISTRATION" section="Credit Use" />}
      actions={[
        {
          icon: CloseIcon,
          text: '',
          onClick: () => history.push(`/`),
          color: 'primary',
        },
      ]}
      showSidebar
    >
      <Grid container spacing={2}>
        <Grid item xs={11} />
        <Grid item xs={1}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setExpanded(!expanded)}
            style={{ marginLeft: 25 }}
          >
            {expanded ? <ChevronDownIcon size={20} /> : <ChevronUpIcon size={20} />}
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <CardMui sx={{ background: 'transparent', color: 'white', padding: 0, border: 'none', width: '100%' }}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={1}>
                  <img src={GreenIcon} alt="Green Success Icon" />
                </Grid>
                <Grid item xs={4}>
                  <Typography sx={{ fontSize: 18 }} gutterBottom>
                    Credit Use and Analysis
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Overview of the past and current credit use.
                  </Typography>
                </Grid>
              </Grid>
              <CardMui
                sx={{
                  background: 'transparent',
                  color: 'white',
                  borderTop: '1px #fff solid',
                  borderBottom: 'none',
                  marginTop: 1,
                }}
              >
                {expanded && (
                  <CardContent>
                    <BarChart
                      width={800}
                      height={300}
                      data={graph}
                      margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                      style={{
                        backgroundColor: 'transparent',
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="processing" stackId="a" fill="#FFCC80" />
                      <Bar dataKey="storage" stackId="a" fill="#FFA826" />
                    </BarChart>
                  </CardContent>
                )}
                <CardActions style={{ justifyContent: 'flex-end', borderTop: '1px #fff solid' }}>
                  <Button variant="contained" color="primary" style={{ color: 'white' }}>
                    Filter Dates
                  </Button>
                </CardActions>
              </CardMui>
            </CardContent>
          </CardMui>
        </Grid>
        <Grid item xs={4}>
          <CardMui sx={{ background: 'rgba(0,0,0,0.3)', color: 'white', padding: 0, height: '100%' }}>
            <CardContent>
              <Typography sx={{ fontSize: 18 }} gutterBottom>
                Available Credits
              </Typography>
              <Typography variant="body2" gutterBottom>
                Keep track of your remaining credits.
              </Typography>
              <CardMui sx={{ background: 'transparent', color: 'white', borderTop: '2px #fff solid', marginTop: 1 }}>
                {expanded && (
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 24 }}
                      style={{ textAlign: 'center', paddingTop: 4, paddingBottom: 4, marginTop: 10 }}
                      gutterBottom
                    >
                      {(accountOrg.creditLimit - accountOrg.creditsUsed).toFixed(2)}
                    </Typography>
                    <Grid container spacing={2} style={{ marginTop: 10 }}>
                      <Grid item xs={8}>
                        <Typography variant="body2" gutterBottom>
                          Average Credit Used Per Day
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          Average Credit Used Per Month
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body2" gutterBottom>
                          {avg?.daily && avg?.daily[0]?.avg_credits_used_daily.toFixed(4)}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {avg?.monthly && avg?.monthly[0]?.avg_credits_used_monthly.toFixed(4)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                )}
                <CardActions style={{ justifyContent: 'flex-end', borderTop: '2px #fff solid' }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={onBuyCredits}
                    style={{ justifyContent: 'flex-end', marginTop: 10 }}
                  >
                    Purchase Credits Now
                  </Button>
                </CardActions>
              </CardMui>
            </CardContent>
          </CardMui>
        </Grid>
      </Grid>
      <Card noPadding>
        <CardMui sx={{ background: 'rgba(0,0,0,0.2)', color: 'white' }}>
          {expanded && (
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  <Typography sx={{ fontSize: 20 }}>Credit Use Details</Typography>
                </Grid>
                <Grid item xs={2} style={{ justifyContent: 'flex-end' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ color: 'white', width: '100%' }}
                    onClick={() => exportToExcel(data, `Credit_Usage_${new Date().getMilliseconds()}`)}
                  >
                    <SquareArrow /> <Typography sx={{ fontSize: 14, paddingLeft: 1 }}>Export</Typography>
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          )}
        </CardMui>
        <TopBar
          title="Credit Use Details"
          options=""
          searchValue={search}
          handleSearch={handleSearch}
          activatedFilter={false}
          sideBarDetail
        />
        <>
          {loading === true && <Loader loading={loading} height={250} />}
          <GeneralTable
            table={table}
            selectedRow={row ?? ''}
            handleRowClick={(selectedRow) => setRow(selectedRow)}
            rowComponent={BillingActions}
            // actionFunction={() => {}}
            handleSelectedIds={(ids) => console.info(ids)}
            external={false}
          />
          <TablePagination table={table} position="start" />
        </>
      </Card>
      {creditsPurchasingModal &&
        (process.env.REACT_APP_HIDE_BILLING === false || process.env.REACT_APP_HIDE_BILLING === 'false') && (
          <ModalCreditsPurchasing
            isOpen={creditsPurchasingModal}
            onConfirm={() => setCreditsPurchasingModal(false)}
            onCancel={() => setCreditsPurchasingModal(false)}
          />
        )}
      {openPaymentSuccess && (
        <Dialog open={openPaymentSuccess} onClose={() => setOpenPaymentSuccess(false)} maxWidth="sm">
          <DialogTitle>
            <ModalHeaderIcon icon={DoubleCheckIcon} text="Success" />
            <Typography>Successfully Added Credits.</Typography>
          </DialogTitle>
          <DialogContent>
            <Typography>
              Congratulations. You have successfully loaded {creditsChoosen} credits to your account.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setOpenPaymentSuccess(false)}
                  size="large"
                  fullWidth
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      )}
      {paymentError && (
        <Dialog open={paymentError} onClose={() => setPaymentError(false)} maxWidth="sm">
          <DialogTitle>
            <Typography>Transaction has been cancelled.</Typography>
          </DialogTitle>
          <DialogContent>
            <Typography>
              The payment has been cancelled or had an error, please check your details in case you want to proceed and
              try again.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button color="primary" variant="contained" onClick={() => setPaymentError(false)} size="small" fullWidth>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </AccountSettingsContainer>
  );
};

import React, { useState } from 'react';
import { Button } from 'components/Button';
import PropTypes from 'prop-types';
import ProjectService from 'services/ProjectService';

import { DoubleCheckIcon, InfoIcon, TrashIcon } from 'components/Icons';
import { DialogMessage } from 'components/DialogMessage';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import { IconButton } from '@mui/material';

export const DeleteButton = ({ sequenceId, assetId, projId, loading, icon, handleClose }) => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const deleteSequence = async () => {
    setOpenConfirm(false);
    setIsLoading(true);
    try {
      await ProjectService.deleteSequence(projId, assetId, sequenceId);
      setIsLoading(false);
      handleClose();
    } catch (error) {
      setIsLoading(false);
      setOpenDialog('An error occurred while trying to delete the sequence.');
    }
  };

  return (
    <>
      {icon ? (
        <IconButton color="danger" onClick={() => setOpenConfirm(true)}>
          <TrashIcon size={16} />
        </IconButton>
      ) : (
        <Button
          kind="danger"
          spacingRight={1}
          onClick={() => setOpenConfirm(true)}
          disabled={loading}
          title="Delete Sequence"
        >
          Delete Sequence
        </Button>
      )}
      <DialogMessage
        title={`Are you sure you want to delete this [${sequenceId}]? This action cannot be undone`}
        icon={DoubleCheckIcon}
        isOpen={openConfirm}
        onConfirm={deleteSequence}
        onCancel={() => setOpenConfirm(false)}
      />
      <DialogMessage
        title={openDialog}
        isOpen={openDialog}
        icon={InfoIcon}
        confirmText="Ok"
        onConfirm={() => {
          setOpenDialog(false);
        }}
      />
      <LoadingOverlay loading={isLoading} />
    </>
  );
};

DeleteButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  sequenceId: PropTypes.string,
  assetId: PropTypes.string,
  projId: PropTypes.string,
  loading: PropTypes.bool,
  icon: PropTypes.bool,
  handleClose: PropTypes.func,
};

DeleteButton.defaultProps = {
  sequenceId: '',
  assetId: '',
  projId: '',
  loading: false,
  icon: false,
  handleClose: () => {},
};

import React, { useState } from 'react';
import { Button } from 'components/Button';
import PropTypes from 'prop-types';
import ProjectService from 'services/ProjectService';

import { DoubleCheckIcon, InfoIcon, SharingIcon } from 'components/Icons';
import { DialogMessage } from 'components/DialogMessage';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import { IconButton } from '@mui/material';

export const ShareButton = ({ sequenceId, assetId, projId, loading, icon, handleClose }) => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const shareSequence = async () => {
    setOpenConfirm(false);
    setIsLoading(true);
    try {
      await ProjectService.shareSequence(projId, assetId, sequenceId);
      setIsLoading(false);
      handleClose();
    } catch (error) {
      setIsLoading(false);
      setOpenDialog('An error occurred while trying to share the sequence.');
    }
  };

  return (
    <>
      {icon ? (
        <IconButton color="warning" onClick={() => setOpenConfirm(true)}>
          <SharingIcon size={16} />
        </IconButton>
      ) : (
        <Button
          kind="warning"
          spacingRight={1}
          onClick={() => setOpenConfirm(true)}
          disabled={loading}
          title="Share Sequence"
        >
          Share Sequence
        </Button>
      )}
      <DialogMessage
        title={`Are you sure you want to share this [${sequenceId}]?`}
        icon={DoubleCheckIcon}
        isOpen={openConfirm}
        onConfirm={shareSequence}
        onCancel={() => setOpenConfirm(false)}
      />
      <DialogMessage
        title={openDialog}
        isOpen={openDialog}
        icon={InfoIcon}
        confirmText="Ok"
        onConfirm={() => {
          setOpenDialog(false);
        }}
      />
      <LoadingOverlay loading={isLoading} />
    </>
  );
};

ShareButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  sequenceId: PropTypes.string,
  assetId: PropTypes.string,
  projId: PropTypes.string,
  loading: PropTypes.bool,
  icon: PropTypes.bool,
  handleClose: PropTypes.func,
};

ShareButton.defaultProps = {
  sequenceId: '',
  assetId: '',
  projId: '',
  loading: false,
  icon: false,
  handleClose: () => {},
};

import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { FormHelperText } from '@mui/material';
import { useStyles } from './styles';

export const FormTextArea = forwardRef(
  (
    { error, disabled, rows, placeholder, value, onChange, type, required, className, helpText, ...rest },
    forwardedRef
  ) => {
    const classes = useStyles({ error, disabled });

    return (
      <>
        <textarea
          className={`${classes.textArea} ${className}`}
          type={type}
          ref={forwardedRef}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
          required={required}
          rows={rows}
          {...rest}
        />
        {helpText && <FormHelperText className={`${classes.helpText} Mui-error`}>{helpText}</FormHelperText>}
      </>
    );
  }
);

FormTextArea.propTypes = {
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  helpText: PropTypes.string,
  type: PropTypes.oneOf([
    'text',
    'email',
    'password',
    'date',
    'datetime-local',
    'file',
    'image',
    'number',
    'tel',
    'search',
    'color',
    'month',
    'url',
    'week',
  ]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  rows: PropTypes.string,
  required: PropTypes.bool,
};

FormTextArea.defaultProps = {
  error: false,
  rows: '4',
  className: null,
  helpText: '',
  disabled: false,
  label: null,
  value: null,
  type: 'text',
  placeholder: '',
  onChange: () => {},
  required: false,
};

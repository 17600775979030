import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';
import { CirclePlusIcon, CloseIcon } from 'components/Icons';
import { SidebarContext } from 'components/SidebarProvider/SidebarProvider';
import { useStyles } from './styles';

export const HeaderInspectionFinding = ({ name, handleSubmit, loading, isImageLoading }) => {
  const classes = useStyles();
  const { setOpen, handleClose } = useContext(SidebarContext);

  return (
    <div className={classes.header}>
      <div>
        <Typography variant="h3">Create Inspection Finding</Typography>
        <Typography>{name}</Typography>
      </div>
      <div>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => handleSubmit()}
          disabled={loading || isImageLoading}
        >
          <CirclePlusIcon size={18} />
          <Typography ml={0.2}>Save Now</Typography>
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            setOpen(false);
            handleClose();
          }}
          className={classes.headerButton}
        >
          <CloseIcon size={15} />
        </Button>
      </div>
    </div>
  );
};

HeaderInspectionFinding.propTypes = {
  name: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  isImageLoading: PropTypes.bool.isRequired,
};

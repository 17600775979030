import { Typography } from 'components/Typography';
import styled from 'styled-components';

export const AttachedFileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
  &:not(:first-child) {
    margin-left: ${(props) => props.theme.spacing(0.6)};
  }
  color: ${(props) => {
    switch (props.iconColor) {
      case 'disallowed':
        return props.theme.palette.danger.base;
      case 'allowed':
        return props.theme.palette.success.base;
      default:
        return props.theme.card.colors.icon;
    }
  }};

  & svg {
    margin-right: ${(props) => props.theme.spacing(0.1)};
  }
`;

export const StyledTypography = styled(Typography)`
  color: #fff;
  margin-left: 5px;
`;

// import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
// import { useSelector } from 'react-redux';
import ProjectService from 'services/ProjectService';
import { ModalHeaderIcon } from 'components/ModalHeaderIcon/ModalHeaderIcon';
import { PlayIconCircle } from 'components/Icons';

const validationSchema = Yup.object({
  assetName: Yup.string().required('Asset name is required'),
  description: Yup.string().max(255).nullable(),
});

export const ModalEditAsset = ({ onCancel, onConfirm, isOpen, asset, projectId }) => {
  const [loading, setLoading] = React.useState(false);
  const [assetData, setAssetData] = React.useState({});
  const [submitError, setSubmitError] = React.useState('');

  const editAssetInfo = async (values) => {
    setLoading(true);

    const data = {
      name: values.assetName,
      description: values.description,
    };

    try {
      const response = await ProjectService.updateProjectAsset(projectId, asset.id, data);
      onConfirm(response.data.data);
    } catch (err) {
      const result = err.response.data.message;
      setSubmitError(result);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      assetName: assetData.name || '',
      description: assetData.description || '',
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit(values) {
      editAssetInfo(values);
    },
  });

  React.useEffect(() => {
    setAssetData(asset);
  }, []);

  return (
    <Dialog open={isOpen} maxWidth="sm" onClose={onCancel}>
      <DialogTitle>
        <ModalHeaderIcon icon={PlayIconCircle} color="warning" text={`Edit ${assetData?.name}`} />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <InputLabel>Title *</InputLabel>
            <TextField
              variant="outlined"
              placeholder=""
              name="assetName"
              disabled={loading}
              type="text"
              {...formik.getFieldProps('assetName')}
              error={!!(formik.touched.assetName && formik.errors.assetName)}
              helperText={formik.touched.assetName && formik.errors.assetName ? formik.errors.assetName : null}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Description</InputLabel>
            <TextField
              variant="outlined"
              placeholder="Some description about the asset."
              name="description"
              disabled={loading}
              type="text"
              {...formik.getFieldProps('description')}
              error={!!(formik.touched.description && formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description ? formik.errors.description : null}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            {submitError && <Typography color="error">{submitError}.</Typography>}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button color="primary" variant="contained" onClick={onCancel} disabled={loading} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button color="secondary" variant="contained" disabled={loading} onClick={formik.handleSubmit} fullWidth>
              Save
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
      {/* <LoadingOverlay loading={loading} /> */}
    </Dialog>
  );
};

ModalEditAsset.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  isOpen: PropTypes.bool,
  // eslint-disable-next-line react/no-typos
  asset: PropTypes.obj,
  projectId: PropTypes.string.isRequired,
};

ModalEditAsset.defaultProps = {
  isOpen: false,
  onConfirm: null,
  onCancel: null,
  asset: null,
};

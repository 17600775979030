import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Roles } from 'lib/roles';
import { useDispatch, useSelector } from 'react-redux';
import AccountService from 'services/AccountService';
import UserService from 'services/UserService';
import { fetchUsers } from 'slices/adminAdminsReducer';
import { getUsers } from 'slices/userReducer';
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { ModalHeaderIcon } from 'components/ModalHeaderIcon/ModalHeaderIcon';
import { WidgetDivider } from 'components/WidgetDivider/WidgetDivider';
import { InfoIcon, ShieldIcon } from 'components/Icons';
import { DialogMessage } from 'components/DialogMessage';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  message: '',
  roles: [],
};

export const ModalInviteAccountLevelAdmin = ({ onConfirm, onCancel, isOpen }) => {
  const [loading, setLoading] = React.useState(false);
  const [submitError, setSubmitError] = React.useState('');
  const [seletctedUser, setSeletctedUser] = React.useState(null);
  const [userRoles, setUserRoles] = React.useState([]);
  const [isExistingUser, setIsExistingUser] = React.useState(false);
  const [isAccountAdmin, setIsAccountAdmin] = React.useState(false);
  const [isAccountContent, setIsAccountContent] = React.useState(false);
  const [isAccountBill, setIsAccountBill] = React.useState(false);
  const { data } = useSelector((state) => state.users);
  const [openDialog, setOpenDialog] = React.useState(false);
  const dispatch = useDispatch();

  const validationSchema = isExistingUser
    ? Yup.object({
        firstName: Yup.string().optional(),
        lastName: Yup.string().optional(),
        email: Yup.string().optional(),
        message: Yup.string().optional(),
        desc: Yup.string().optional(),
        userId: Yup.string().required('User is required'),
        roles: Yup.array(Yup.number().min(2).max(4)),
      })
    : Yup.object({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        email: Yup.string()
          .matches(/^\S*$/, 'Email can not contain space')
          .email('Invalid email address')
          .required('Email is required'),
        message: Yup.string().optional(),
        desc: Yup.string(),
        roles: Yup.array(Yup.number().min(2).max(4)),
      });

  useEffect(() => {
    dispatch(getUsers({ page_size: 500, include: ['orgs'] }));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (seletctedUser) {
        const response = await UserService.getUserRoles(seletctedUser);

        const roleAdmin = [];
        if (response.data.data.accounts.length > 0) {
          const acctadminrole = response.data.data.accounts[0].roles.filter((obj) => obj.roleId === Roles.ACCT_ADMIN);
          const contadminrole = response.data.data.accounts[0].roles.filter(
            (obj) => obj.roleId === Roles.ACCT_CONTENT_ADMIN
          );
          const billadminrole = response.data.data.accounts[0].roles.filter(
            (obj) => obj.roleId === Roles.ACCT_BILL_ADMIN
          );
          if (acctadminrole.length > 0) {
            roleAdmin.push(acctadminrole[0].roleId);
          }
          if (contadminrole.length > 0) {
            roleAdmin.push(contadminrole[0].roleId);
          }
          if (billadminrole.length > 0) {
            roleAdmin.push(billadminrole[0].roleId);
          }
        }
        setUserRoles(roleAdmin);
      }
    };

    fetchData();
  }, [seletctedUser]);

  const users = data.map((item) => ({
    value: item.id,
    label: `${item.firstName} ${item.lastName}`,
  }));

  const closeConfirm = () => {
    dispatch(fetchUsers());
    onCancel();
  };

  const saveAccountLevelAdmin = async (values) => {
    setLoading(true);

    const saveAccData = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      message: values.message,
    };

    try {
      if (isExistingUser) {
        const res = await AccountService.updateRoles(seletctedUser, {
          roles: userRoles,
        });
        setLoading(false);
        if (res.status === 201) {
          onConfirm(res.data.data);
        }
      } else {
        const response = await UserService.createUser(saveAccData);
        const res = await AccountService.updateRoles(response.data.data.id, {
          roles: values.roles.map((role) => Number(role)),
        });
        setLoading(false);
        if (res.status === 201) {
          onConfirm(response.data.data);
        }
      }
      setOpenDialog('Administrator was created successfully');
    } catch (err) {
      const result = err.response.data.message;
      setSubmitError(result);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit(values, { resetForm }) {
      saveAccountLevelAdmin(values);
      resetForm();
    },
  });

  const handleCheckedChange = ({ checked, value }) => {
    let newRoles = [...formik.values.roles];

    if (checked) {
      newRoles.push(+value);
    } else {
      newRoles = newRoles.filter((role) => role !== +value);
    }

    formik.setFieldValue('roles', newRoles);
  };

  const addExistingUser = async () => {
    setIsExistingUser(!isExistingUser);
  };

  const addAccountAdmin = async (e) => {
    if (e.checked) {
      const oldArr = [...userRoles, +e.value];
      setUserRoles(oldArr);
      setIsAccountAdmin(true);
    } else {
      const oldArr = [...userRoles].filter((role) => role !== +e.value);
      setUserRoles(oldArr);
      setIsAccountAdmin(false);
    }
  };

  const addAccountContent = async (e) => {
    if (e.checked) {
      const oldArr = [...userRoles, +e.value];
      setUserRoles(oldArr);
      setIsAccountContent(true);
    } else {
      const oldArr = [...userRoles].filter((role) => role !== +e.value);
      setUserRoles(oldArr);
      setIsAccountContent(false);
    }
  };

  const addAccountBill = async (e) => {
    if (e.checked) {
      const oldArr = [...userRoles, +e.value];
      setUserRoles(oldArr);
      setIsAccountBill(true);
    } else {
      const oldArr = [...userRoles].filter((role) => role !== +e.value);
      setUserRoles(oldArr);
      setIsAccountBill(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onCancel} maxWidth="sm">
      <DialogTitle>
        <ModalHeaderIcon icon={ShieldIcon} text="Admin Permissions" />
      </DialogTitle>
      <DialogContent>
        <FormControlLabel
          label="Add an existing user as Administrator."
          control={<Checkbox color="secondary" />}
          name="roles"
          onChange={() => addExistingUser()}
          checked={isExistingUser}
        />
        <WidgetDivider color="secondary" margin />
        {isExistingUser && (
          <>
            <InputLabel>User *</InputLabel>
            <FormControl error={formik.touched.userId && formik.errors.userId} fullWidth>
              <Autocomplete
                options={users}
                name="userId"
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select User"
                    onBlur={formik.handleBlur}
                    error={!!(formik.touched.userId && formik.errors.userId)}
                    required
                  />
                )}
                disabled={!isExistingUser}
                value={data.find((u) => u.value === 'userId')}
                onChange={(e, value) => {
                  setSeletctedUser(value?.value ? value?.value : '');
                  formik.handleChange('userId')(value?.value ? value?.value : '');
                }}
              />
              <FormHelperText>
                {formik.touched.userId && formik.errors.userId ? formik.errors.userId : null}
              </FormHelperText>
            </FormControl>
          </>
        )}
        {isExistingUser && (
          <FormGroup>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FormControlLabel
                  control={<Checkbox color="secondary" />}
                  label="Account Administrator"
                  name="roles"
                  value={Roles.ACCT_ADMIN}
                  onChange={(e) => addAccountAdmin(e.target)}
                  checked={isAccountAdmin || userRoles.some((role) => role === Roles.ACCT_ADMIN)}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={<Checkbox color="secondary" />}
                  label="Account Content Coordinator"
                  name="roles"
                  value={Roles.ACCT_CONTENT_ADMIN}
                  onChange={(e) => addAccountContent(e.target)}
                  checked={isAccountContent || userRoles.some((role) => role === Roles.ACCT_CONTENT_ADMIN)}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox color="secondary" />}
                  label="Account Billing Coordinator"
                  name="roles"
                  value={Roles.ACCT_BILL_ADMIN}
                  onChange={(e) => addAccountBill(e.target)}
                  checked={isAccountBill || userRoles.some((role) => role === Roles.ACCT_BILL_ADMIN)}
                />
              </Grid>
            </Grid>
          </FormGroup>
        )}
        {!isExistingUser && (
          <Grid container spacing={1}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <InputLabel>First Name *</InputLabel>
              <TextField
                variant="outlined"
                placeholder="First Name"
                name="firstName"
                value={formik.values.firstName}
                error={!!(formik.touched.firstName && formik.errors.firstName)}
                helperText={formik.touched.firstName && formik.errors.firstName ? formik.errors.firstName : null}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
                id="firstName"
                required={!isExistingUser}
                disabled={loading || isExistingUser}
                fullWidth
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <InputLabel>Last Name *</InputLabel>
              <TextField
                placeholder="Last Name"
                value={formik.values.lastName}
                error={!!(formik.touched.lastName && formik.errors.lastName)}
                helperText={formik.touched.lastName && formik.errors.lastName ? formik.errors.lastName : null}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
                name="lastName"
                id="lastName"
                required
                disabled={loading || isExistingUser}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Email *</InputLabel>
              <TextField
                placeholder="Please enter email"
                value={formik.values.email}
                error={!!(formik.touched.email && formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email ? formik.errors.email : null}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="email"
                name="email"
                id="email"
                required
                disabled={loading || isExistingUser}
                fullWidth
              />
            </Grid>
          </Grid>
        )}
        {!isExistingUser && (
          <FormGroup>
            <Grid container spacing={1}>
              <Grid item xs={12} sx={{ marginTop: '1rem' }}>
                <Typography variant="h5" gutterBottom mt={1}>
                  Account Administration Roles
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ paddingTop: '0!important' }}>
                <FormControlLabel
                  control={<Checkbox color="secondary" />}
                  label="Account Administrator"
                  name="roles"
                  value={Roles.ACCT_ADMIN}
                  onChange={(e) => handleCheckedChange(e.target)}
                  disabled={loading || isExistingUser}
                  checked={formik.values.roles.indexOf(Roles.ACCT_ADMIN) >= 0}
                />
              </Grid>
              <Grid item xs={6} sx={{ paddingTop: '0!important' }}>
                <FormControlLabel
                  control={<Checkbox color="secondary" />}
                  label="Account Content Coordinator"
                  name="roles"
                  value={Roles.ACCT_CONTENT_ADMIN}
                  onChange={(e) => handleCheckedChange(e.target)}
                  disabled={loading || isExistingUser}
                  checked={formik.values.roles.indexOf(Roles.ACCT_CONTENT_ADMIN) >= 0}
                  readOnly
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox color="secondary" />}
                  label="Account Billing Coordinator"
                  name="roles"
                  value={Roles.ACCT_BILL_ADMIN}
                  onChange={(e) => handleCheckedChange(e.target)}
                  disabled={loading || isExistingUser}
                  checked={formik.values.roles.indexOf(Roles.ACCT_BILL_ADMIN) >= 0}
                />
              </Grid>
            </Grid>
          </FormGroup>
        )}
        {submitError && (
          <FormControl error fullWidth>
            <FormHelperText>{submitError}</FormHelperText>
          </FormControl>
        )}
        <DialogMessage
          title={openDialog}
          isOpen={openDialog}
          icon={InfoIcon}
          confirmText="Ok"
          onConfirm={closeConfirm}
        />
        <LoadingOverlay loading={loading} />
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button color="primary" variant="contained" onClick={onCancel} size="large" fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              color="secondary"
              variant="contained"
              disabled={loading}
              onClick={formik.handleSubmit}
              size="large"
              fullWidth
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

ModalInviteAccountLevelAdmin.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
};

ModalInviteAccountLevelAdmin.defaultProps = {
  onConfirm: () => {},
  onCancel: () => {},
};

import styled from 'styled-components';

export const StyledProgress = styled.div`
  height: 4px;
  color: ${(props) => props.theme.palette.primary[700]};
  background-color: ${(props) => props.theme.palette.primary[1000]};
  box-sizing: content-box;
  position: relative;
  border-radius: 25px;
  width: 100%;

  & span {
    display: block;
    height: 100%;
    background-color: ${(props) => props.theme.palette.primary[700]};
    border-radius: 25px;
    width: ${(props) => `${props.value}%`};
    position: relative;
    overflow: hidden;
  }
`;

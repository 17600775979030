/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Grid, Dialog, DialogContent, DialogTitle, Button } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import { CustomForm } from 'components/ProjectDetail/ProjectAssets/ProjectAssetDetail/ProjectAssetInspections/SaveProjectAssetInspectionFinding/CustomForm';
import { DefaultForm } from 'components/ProjectDetail/ProjectAssets/ProjectAssetDetail/ProjectAssetInspections/SaveProjectAssetInspectionFinding/DefaultForm';
import { useStyles } from './styles';
import { WidgetDivider } from 'components/WidgetDivider/WidgetDivider';

export const ViewInspectionSample = ({ inspection, handleClose }) => {
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const responses = inspection.responses ? JSON.parse(inspection.responses) : [];
  let questions = JSON.parse(inspection.questions);

  const extraValues = {};
  const extraValidations = {};

  const removeIfIsEmptyDeleted = (questionsTemp) =>
    questionsTemp.map((question) => {
      const auxQuestion = { ...question };
      responses.forEach((response) => {
        if (question.deletedAt && response.id === question.id && response.response) {
          delete auxQuestion.deletedAt;
        }
      });
      return auxQuestion;
    });

  if (inspection.questions.length > 0) {
    questions = removeIfIsEmptyDeleted(questions).filter((item) => !item.deletedAt);
    questions.forEach((element) => {
      extraValues[`${element.id}`] = element.code === 'checkbox' ? [] : '';
      if (element.required) {
        extraValidations[`${element.id}`] = Yup.string().min(1).required(`${element.name} is required`);
      }
      if (element.required && element.code === 'checkbox') {
        extraValidations[`${element.id}`] = Yup.array()
          .min(1, `${element.name} field must have at least 1 items`)
          .required(`${element.name} is required`);
      }
    });
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      description: '',
      coordinates: '',
      ...extraValues,
    },
    validationSchema: Yup.object(extraValidations),
  });

  return (
    <Dialog open onClose={handleClose} maxWidth="lg">
      <DialogTitle className={classes.topBar}>
        <Button size="xs" onClick={handleClose}>
          Close
        </Button>
      </DialogTitle>
      <DialogContent className={classes.modalContent}>
        <Grid container spacing={1} sx={{ padding: '1rem' }}>
          <Grid item md={8} lg={8} xl={8}>
            <div style={{ borderRadius: 8, overflow: 'hidden', display: 'inherit' }}>
              <img src="/inspection-template-sample.png" alt="inspection-template" />
            </div>
            <WidgetDivider margin color="secondary" />
            <CustomForm formik={formik} questions={questions} />
          </Grid>
          <DefaultForm
            point={{
              latitude: 35.16,
              longitude: -77.09,
            }}
            formik={formik}
            finding={{
              id: 'a',
              name: '',
              lng: 0,
              lat: 0,
            }}
            action="creation"
          />
        </Grid>
        <LoadingOverlay loading={isLoading} />
      </DialogContent>
    </Dialog>
  );
};

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  fullHeight: {
    height: '100%',
  },
  arrowSequence: {
    '&.Mui-disabled': {
      color: theme.palette.secondary.main,
      opacity: 0.4,
    },
  },
  mapControls: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '9%',
    backgroundColor: theme.palette.primary[1200],
  },
  imageContent: {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    backgroundColor: theme.palette.primary[1300],
  },
}));

import React, { useState, useEffect } from 'react';
import { ProjectObservationDetail } from 'components/ProjectDetail/ProjectObservations/ProjectObservationDetail';
import { useSelector } from 'react-redux';
import { useStyles } from '../styles';

export const ProjectAssetObservationSideBar = () => {
  const { observation, observations, isLoading } = useSelector((state) => state.assetObservations);
  const classes = useStyles({ open: observation?.id });
  const [observationData, setObservationData] = useState(null);
  const [point, setPoint] = useState(null);

  // ----------------------------- open observation modal
  const handleViewObservationOpen = (auxObservation = observation) => {
    setPoint(null);
    if (auxObservation?.spatialPoint?.id) {
      setPoint(auxObservation?.spatialPoint);
    } else {
      const { sequence } = auxObservation;
      if (sequence?.asset) {
        let auxPoint = null;
        if (sequence.asset.type === 'PHOTO') auxPoint = { ...sequence.spatialPoints[0] };
        else auxPoint = sequence.spatialPoints.find((item) => +item.time === +auxObservation.time - 1);
        setPoint(auxPoint);
      }
    }
    setObservationData(auxObservation);
  };

  useEffect(() => {
    if (observation?.id) {
      handleViewObservationOpen(observation);
    }
  }, [observation]);

  return (
    <div className={classes.observationDetail}>
      {observationData?.id && (
        <ProjectObservationDetail
          observation={observationData}
          point={point}
          observations={observations}
          isObservationLoading={isLoading}
          closeButton
        />
      )}
    </div>
  );
};

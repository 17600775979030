import { AccountSettingsContainer } from 'components/AccountSettingsContainer/AccountSettingsContainer';
import { AdminHeaderTitle } from 'components/AdminHeaderTitle/AdminHeaderTitle';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSortBy, useTable, usePagination } from 'react-table';
import { TablePagination } from 'components/TablePagination/TablePagination';
import { Table } from 'components/Table';
import { PencilIcon } from 'components/Icons';
import { ContextMenu } from 'components/ContextMenu';
import { getFailedContents, updateTelemetryCoordinates } from 'slices/failedImportedContent';
import { Loader } from 'components/Loader';
import Dropzone from 'react-dropzone';
import { ContextMenuButton } from 'components/ContextMenuButton';
import { ContextMenuList } from 'components/ContextMenuItems';
import { ContextMenuItem } from 'components/ContextMenuItem';
import { MapSimple } from 'components/MapSimple/MapSimple';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAlphaDesc, faSortAlphaAsc, faSort } from '@fortawesome/free-solid-svg-icons';
import { useTelemetryUploader } from 'hooks/use-project-asset-uploader';
import ProgressBar from '@ramonak/react-progress-bar';
// import localforage from 'localforage';
import {
  Card,
  // Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  // Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

import { TableHeaders } from './TableHeaders/TableHeader';
import { ModifiedTd, StyledInput } from './styles';

export const FailedImportedContent = () => {
  const dispatch = useDispatch();
  const { data, loading, filters } = useSelector((state) => state.failedContent);
  const acctId = useSelector((state) => state.adminProjects.filters.acctId || '');
  const [showUploadModal, setShowUploadModal] = useState({ show: false, id: '' });
  const [accept, setAccept] = useState('');
  const [progress, setProgress] = useState(0);
  const [coords, setCoords] = useState([35.7745, -78.7689]);
  const [showGPS, setShowGPS] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);

  // const getAcct = async () => localforage.getItem('acctId');

  const table = useTable(
    {
      columns: TableHeaders,
      data,
      initialState: {
        pageIndex: filters.page - 1,
        pageSize: data.length,
      },
      pageCount: 1,
      totalCount: data.length,
      pageLength: data.length,
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  const loadData = () => {
    const sendFilters = {
      ...filters,
    };
    dispatch(getFailedContents(sendFilters));
  };

  const onUploadGPS = (id) => {
    // show modal to upload telemetry and call
    // telemetryUpload
    setShowUploadModal({ show: true, id: `${id}` });
  };

  const handleClose = () => {
    setShowUploadModal({ show: false, id: '' });
  };

  const handleUpload = (file) => {
    setAccept(true); // replace this with checking the file extensions
    setProgress(30);
    // eslint-disable-next-line
    useTelemetryUploader({ projId: '22333', assetId: showUploadModal.id, file });
    setProgress(100);
    dispatch(
      getFailedContents({
        ...filters,
        acctId,
      })
    );
  };

  useEffect(() => {
    loadData();
  }, [filters]);

  const ContentContainer = () => (
    <>
      {showUploadModal.show && (
        <Dialog open onClose={handleClose}>
          <DialogContent>
            <DialogTitle onClose={handleClose}>
              <Button variant="contained" onClick={() => setShowGPS(!showGPS)} title="OK">
                {showGPS
                  ? `Upload Telemetry File to asset ${showUploadModal.id}`
                  : `Select GPS Location on Map for asset ${showUploadModal.id}`}
              </Button>
            </DialogTitle>
            {showGPS && (
              <>
                <div style={{ marginBottom: 20 }}>
                  Latitude: {coords[0]} || Longitude: {coords[1]}
                </div>
                <div style={{ height: 200, width: 550 }}>
                  <MapSimple
                    points={[
                      {
                        location: coords,
                      },
                    ]}
                    getCoordsOnClick={(lat, lon) => {
                      setCoords([lat, lon]);
                    }}
                  />
                </div>
                <div style={{ marginTop: 10 }}>
                  <Button
                    kind="secondary"
                    onClick={async () => {
                      const res = updateTelemetryCoordinates(coords, showUploadModal.id);
                      res.then((val) => {
                        if (val.status === 201) {
                          setShowSuccess(true);
                        }
                      });
                    }}
                    title="OK"
                  >
                    Save
                  </Button>
                </div>
              </>
            )}
            {!showGPS && (
              <>
                <Dropzone
                  onDrop={handleUpload}
                  acceptedFiles=".csv, .srt, .gpx"
                  style={{ padding: 150, backgroundColor: 'orange', borderColor: 'white' }}
                >
                  {() => (
                    <div style={{ padding: 50 }}>
                      <div>You can drop your file here or upload it below by the picker</div>
                      <StyledInput
                        type="file"
                        accept={accept}
                        id="telemetryUploadInput"
                        onChange={(e) => handleUpload(e.target.value)}
                        disabled={false}
                      />
                    </div>
                  )}
                </Dropzone>
                <ProgressBar completed={progress} bgColor="#ffa500" />
              </>
            )}
            {showSuccess && (
              <Dialog>
                <DialogTitle>Success</DialogTitle>
                <DialogContent>The new geo location has been saved successfully!</DialogContent>
                <DialogActions border>
                  <Button kind="secondary" onClick={() => setShowSuccess(false)} title="OK">
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            )}
          </DialogContent>
        </Dialog>
      )}
      {loading ? (
        <Loader loading={loading} height={250} />
      ) : (
        <Card border>
          <Table table={table}>
            <TableHead>
              {table.headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      <span style={{ margin: '2px 4px', position: 'absolute' }}>
                        {(() => {
                          if (column.isSorted) {
                            if (column.isSortedDesc) {
                              return <FontAwesomeIcon icon={faSortAlphaDesc} size="sm" />;
                            }
                            return <FontAwesomeIcon icon={faSortAlphaAsc} size="sm" />;
                          }
                          return <FontAwesomeIcon icon={faSort} size="sm" />;
                        })()}
                      </span>
                    </TableCell>
                  ))}
                  <TableCell>Advanced</TableCell>
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...table.getTableBodyProps()}>
              {table.page.map((row) => {
                table.prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <ModifiedTd {...cell.getCellProps()}>{cell.render('Cell')}</ModifiedTd>
                    ))}
                    <TableCell>
                      <ContextMenu>
                        <ContextMenuButton />
                        <ContextMenuList position="bottom-right">
                          <ContextMenuItem icon={PencilIcon} onClick={() => onUploadGPS(row.cells[0].row.original.ID)}>
                            Upload GPS Information
                          </ContextMenuItem>
                        </ContextMenuList>
                      </ContextMenu>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination table={table} />
        </Card>
      )}
    </>
  );

  return (
    <AccountSettingsContainer
      title={<AdminHeaderTitle buttonName="ADMINISTRATION" section="FAILED IMPORTED CONTENT" />}
      showSidebar
      // hideMenu={acctId !== '16AB130D-C3BB-4456-846E-C2B0F4EFFB24'}
    >
      <ContentContainer />
    </AccountSettingsContainer>
  );
};

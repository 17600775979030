import React, { useEffect, useRef, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ArcGISMap from '@arcgis/core/Map';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import MapView from '@arcgis/core/views/MapView';
import Search from '@arcgis/core/widgets/Search';
import Graphic from '@arcgis/core/Graphic';
import {
  createMapGeoFeatureLayer,
  currentHeadingSymbol,
  currentSymbol,
  fetchMapGeoData,
  findingSymbol,
  mainPointGraphics,
  observationSymbol,
} from 'components/MapFeatureLayer/MapFeatureLayer';
import { TrackZoom } from 'components/TrackZoom/TrackZoom';
import { useSelector } from 'react-redux';
import { useStyles } from './styles';

export const ObservationMap = ({
  observation,
  point,
  enableEdit,
  type,
  handleGetCoordinates,
  imgHandler,
  fullHeight,
  findingIcon,
}) => {
  const classes = useStyles({ fullHeight });
  const { projectAsset } = useSelector((state) => state.profileProjectAssets);
  const mapDiv = useRef(null);
  const mapGeoLayer = useMemo(() => new GraphicsLayer({ id: 'mainLine' }), []);
  const polylineGraphicLayer = useMemo(() => new GraphicsLayer({ id: 'mainPoints' }), []);
  const observationLayer = useMemo(() => new GraphicsLayer(), []);
  const [viewMap, setViewMap] = useState(null);
  // create the layer for the spatial points and the associating markers
  const [searchMap, setSearchMap] = useState(null);
  const [track, setTrack] = useState(true);
  const zoom = 19;

  // This function maps through the spatial points on the backend, and creates a collection of orange dot graphics.
  const pointGraphics = mainPointGraphics(
    projectAsset?.points?.length ? projectAsset?.points : [],
    true,
    -1,
    projectAsset?.points?.length - 1 || 0
  );

  const goToPoint = (view, markerPoint) => {
    if (markerPoint?.longitude && view !== null) {
      setTrack(false);
      view.goTo({
        center: [markerPoint.longitude, markerPoint.latitude],
        zoom,
      });
    }
  };

  // ------------------ sequence blue point
  const createPoint = () => {
    if (point?.longitude) {
      const mapPoint = {
        type: 'point',
        longitude: point.longitude,
        latitude: point.latitude,
      };
      const currentPointGraphic = new Graphic({
        geometry: mapPoint,
        symbol: !point.heading ? currentSymbol : currentHeadingSymbol(point.heading),
      });
      goToPoint(viewMap, point);
      return currentPointGraphic;
    }
    return null;
  };

  const createObservationPoint = (longitude, latitude) => {
    const mapPoint = {
      type: 'point',
      longitude,
      latitude,
    };
    const observationPoint = new Graphic({
      geometry: mapPoint,
      symbol: findingIcon ? findingSymbol : observationSymbol,
    });
    return observationPoint;
  };

  // -------------------- When the Map finishes loading, zoom to the video point path
  const defaultZoom = (view, featureLayerData) => {
    setTrack(true);
    let target = { target: observationLayer.graphics };
    if (observationLayer.graphics.length < 2) target = { target: observationLayer.graphics, zoom };
    view.when(
      view
        .goTo(target, { animate: true, easing: 'ease', duration: 0 })
        .then(() => fetchMapGeoData(featureLayerData))
        .catch((error) => {
          if (error.name !== 'AbortError') {
            // eslint-disable-next-line
            console.error(error);
          }
        })
    );
  };

  // ---------------------- track zoom function
  const handleTrackZoomClick = () => {
    if (viewMap !== null) {
      // ------------------- get data to get lines and poles
      const featureLayerData = {
        view: viewMap,
        mapGeoFeatureLayer: null,
        mapGeoFeatureLayerLines: null,
        mapGeoLayer,
        searchWidget: searchMap,
        geometryOrgId: projectAsset?.project?.orgId || null,
        includeAccountGeometry: false,
      };
      viewMap.map.layers.forEach((layer) => {
        switch (layer.id) {
          case 'inital-points-1':
            featureLayerData.mapGeoFeatureLayer = layer;
            break;
          case 'inital-lines-1':
            featureLayerData.mapGeoFeatureLayerLines = layer;
            break;
          default:
            break;
        }
      });
      // -------------- default zoom
      defaultZoom(viewMap, featureLayerData);
    }
  };

  // ---------------------- edit observation point coordinates
  const editabledMarker = (view) => {
    if (enableEdit && view !== null) {
      view.on('click', (event) => {
        const lat = event.mapPoint.latitude;
        const lng = event.mapPoint.longitude;
        observationLayer.removeAll();
        observationLayer.add(createObservationPoint(lng, lat));
        observationLayer.add(createPoint());
        // --------------------- update coordinates
        handleGetCoordinates(lat, lng);
        goToPoint(view, event.mapPoint);
      });
    }
  };

  useEffect(() => {
    // ----------------- enable edit coordinates for observation point
    editabledMarker(viewMap);
  }, [enableEdit]);

  useEffect(() => {
    if (mapDiv.current) {
      // Initialize map
      const map = new ArcGISMap({
        basemap: 'hybrid',
      });
      const view = new MapView({
        map,
        container: mapDiv.current,
      });
      setViewMap(view);
      const searchWidget = new Search({
        view,
      });
      view.ui.add(searchWidget, {
        position: 'top-right',
        index: 2,
      });
      setSearchMap(searchWidget);

      // ------------------------ add the feature layer to the map fro lines and poles
      const mapGeoFeatureLayer = createMapGeoFeatureLayer('point', { id: 'inital-points-1' });
      const mapGeoFeatureLayerLines = createMapGeoFeatureLayer('polyline', { id: 'inital-lines-1' });
      map.add(mapGeoFeatureLayerLines);
      map.add(mapGeoLayer);
      // create the layer for the spatial points and the associating markers
      polylineGraphicLayer.addMany(pointGraphics);
      map.add(polylineGraphicLayer);
      map.add(mapGeoFeatureLayer, 0);

      // ----------------------------------------------- zoom in point after searching
      searchWidget.goToOverride = (view2, goToParams) => {
        goToParams.options.duration = 300;
        const target = {
          ...goToParams.target,
          ...(goToParams.target.target?.attributes?.ObjectID &&
            goToParams.target.target?.layer?.uid === mapGeoFeatureLayer.uid && { zoom }),
        };
        return view.goTo(target, goToParams.options);
      };
      // ------------- add sequence point
      observationLayer.add(createPoint());
      // --------------------- add observation point in detail action
      if (type !== 'creation') observationLayer.add(createObservationPoint(observation.lng, observation.lat));
      if (type !== 'creation' && enableEdit) editabledMarker(view);
      // --------------------- enable edit coordinates for creation action
      if (type === 'creation') editabledMarker(view);
      // -------------------- add layer to map
      map.add(observationLayer);
      imgHandler(view);
      // -------------------- lines and poles functions
      const featureLayerData = {
        view,
        mapGeoFeatureLayer,
        mapGeoFeatureLayerLines,
        mapGeoLayer,
        searchWidget,
        geometryOrgId: projectAsset?.project?.orgId || null,
        includeAccountGeometry: false,
      };
      // ------------------- get lines and poles
      view.on('drag', (event) => {
        if (event.action === 'end') {
          setTrack(false);
          // -------------------- call map geometry data
          fetchMapGeoData(featureLayerData);
        }
      });
      view.on('mouse-wheel', () => {
        // -------------------- call map geometry data
        setTrack(false);
        fetchMapGeoData(featureLayerData);
      });
      // -------------- default zoom
      defaultZoom(view, featureLayerData);
      return () => {
        observationLayer.removeAll();
        map.remove(mapGeoFeatureLayerLines);
        map.remove(mapGeoLayer);
        map.remove(mapGeoFeatureLayer);
        map.remove(observationLayer);
        map.remove(polylineGraphicLayer);
      };
    }
    return () => {};
  }, [observation, point]);

  return (
    <div className={classes.mapContainer}>
      <TrackZoom handleTrackZoomClick={handleTrackZoomClick} active={track} />
      <div className="mapDiv" id="observationMapDiv" ref={mapDiv} />
    </div>
  );
};

ObservationMap.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  observation: PropTypes.object,
  // eslint-disable-next-line react/no-unused-prop-types, react/forbid-prop-types
  point: PropTypes.any,
  enableEdit: PropTypes.bool,
  type: PropTypes.string,
  handleGetCoordinates: PropTypes.func,
  imgHandler: PropTypes.func,
  fullHeight: PropTypes.bool,
  findingIcon: PropTypes.bool,
};

ObservationMap.defaultProps = {
  observation: { id: null, name: '', lng: 0, lat: 0 },
  point: null,
  enableEdit: false,
  type: '',
  handleGetCoordinates: () => {},
  imgHandler: () => {},
  fullHeight: false,
  findingIcon: false,
};

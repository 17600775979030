import React from 'react';
import { PropTypes } from 'prop-types';
import { Button, Tooltip, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faInfoCircle, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useStyles } from './styles';

export const PermissionCard = ({ title, permissions, onEdit }) => {
  const classes = useStyles();

  return (
    <div>
      <div>
        <Typography variant="h5">{title && title}</Typography>
        <Button
          color="secondary"
          title={title}
          onClick={onEdit}
          size="large"
          variant="outlined"
          sx={{ margin: '5px 0' }}
        >
          <Typography variant="h6">Edit</Typography>
        </Button>
      </div>
      <div>
        {permissions.map((perm) => (
          <div className={classes.permission}>
            <div style={{ marginRight: '10px' }}>
              {perm.isChecked ? (
                <FontAwesomeIcon icon={faCheck} size="lg" color="green" />
              ) : (
                <FontAwesomeIcon icon={faXmark} size="lg" color="red" />
              )}
            </div>
            <Typography variant="body" as="p">
              {perm.name}
            </Typography>
            {perm.info && (
              <Tooltip sx={{ color: 'white' }} title={perm.info}>
                <FontAwesomeIcon icon={faInfoCircle} size="lg" style={{ marginLeft: '10px', cursor: 'pointer' }} />
              </Tooltip>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

PermissionCard.propTypes = {
  /**
   * title of the permissions, project, organization, user, team. exp. RadixBay
   */
  title: PropTypes.string,
  /**
   * User Permissions array with if the permission is checked or not
   */
  permissions: PropTypes.arrayOf([
    PropTypes.shape({
      permId: PropTypes.number.isRequired,
      isChecked: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ]),
  onEdit: PropTypes.func,
};

PermissionCard.defaultProps = {
  title: '',
  permissions: [],
  onEdit: () => {},
};

import PropTypes from 'prop-types';
import React from 'react';
import { useStyles } from './styles';

export const SecondaryNav = ({ children }) => {
  const classes = useStyles();

  return (
    <nav className={classes.nav}>
      <ul>{children}</ul>
    </nav>
  );
};

SecondaryNav.propTypes = {
  children: PropTypes.node,
};

SecondaryNav.defaultProps = {
  children: null,
};

import styled from 'styled-components';
import { Button } from 'components/Button/Button';

export const Layout = styled.div`
  display: grid;
  height: 800px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 16px;
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: center;

  & > div:last-child {
    margin-left: 10px;
  }
`;

export const AvatarRow = styled.div`
  display: flex;
  flex-direction: row;
  & > * {
    margin-right: 2px;
  }
`;

export const StyledRow = styled.tr`
  background-color: ${(props) => (props.isSelected ? props.theme.palette.primary[1200] : null)};
`;

export const UploadFilesModal = styled.div`
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
`;

export const UploadFilesModalBtn = styled(Button)`
  background-color: unset;
  width: 43%;
  position: relative;
  display: inline-block;
`;

export const UploadFilesModalTitle = styled.div`
  font-size: 20px;
  top: 10px;
  position: relative;
  text-align: left;
  margin-left: 5%;
`;

export const UploadFilesGetTemplate = styled(Button)`
  background-color: unset;
  font-weight: 200;
  font-size: 15;
  text-align: right;
  left: 35%;
  top: -25px;
  position: relative;
  color: orange;
  font-style: unset;
  text-decoration: underline;
`;

export const UploadFilesOuterContainer = styled.div`
  border: 1px solid #fff;
  border-radius: 10px;
  background-color: #333;
  width: 90%;
  left: 5%;
  right: 5%;
  position: relative;
  display: block;
  margin-top: 50px;
`;

export const UploadFilesInnerContainer = styled.div`
  height: 30%;
  padding: 50px;
`;

export const UploadAreaContainer = styled.div`
  background-color: orange;
  border: 1;
  border-style: none;
  border-radius: 10px;
  top: 30%;
  position: relative;
  left: 0%;
  display: inline-block;
`;

export const UploadPopup = styled.div`
  z-index: 3000;
  width: 500px;
  min-height: 200px;
  position: absolute;
  top: 30%;
  left: 20%;
  background-color: #333;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 2px 2px 2px #333;
  font-size: 16px;
  color: orange;
  border-color: #bce8f1;
`;

export const CloseBtnPopup = styled.div`
  position: absolute;
  top: 0px;
  right: 10px;
`;

export const UploadFilesModalBtnTxt = styled.div`
  font-size: 20px;
  text-align: center;
  font-weight: 100;
`;

export const UploadFilesModalContainer = styled.div`
  position: relative;
  background-color: #262222;
  margin-top: 30%;
  left: 30%;
  top: -50%;
  height: 70%;
  border-radius: 10px;
  width: 40%;
`;

export const UploadFilesModalBtnII = styled(Button)`
  width: 43%;
  position: relative;
  display: inline-block;
  margin-left: 15px;
`;

export const AddNew = styled(Button)`
  position: fixed;
  right: 150px;
  top: 70px;
  z-index: 200;
`;

export const StyledPlus = styled.div`
  border: 0px;
  border-radius: 50px;
  border-color: white;
  border-style: solid;
  font-size: 20px;
  padding-right: 5px;
  display: inline-block;
`;

export const StyledPlusText = styled.div`
  font-size: 18px;
  font-weight: 300;
  display: inline-block;
`;

export const StyledPopUpCopy = styled.div`
  position: absolute;
  padding: ${(props) => props.theme.spacing(0.5)};
  bottom: ${(props) => (props.variant === 'infoPanel' ? '90%' : '100%')};
  background-color: ${(props) => props.theme.palette.primary[800]};
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.palette.primary[1200]};
  width: max-content;
  left: ${(props) => (props.variant === 'infoPanel' ? '-25%' : '5%')};
  color: ${(props) => props.theme.palette.primary[100]};
`;

import PropTypes from 'prop-types';
import React from 'react';
import { StyledProgress } from './styles';

export const ProgressBar = ({ value, color, testId }) => (
  <StyledProgress value={value} color={color} data-test-id={testId} data-value={value}>
    <span />
  </StyledProgress>
);

ProgressBar.propTypes = {
  /**
   * Value of th progress bar from 1-100
   */
  value: PropTypes.number.isRequired,

  /**
   * Color of the progress bar
   */
  color: PropTypes.oneOf(['green', 'orange', 'red', 'gray']),

  /**
   * value to display in `data-test-id`
   */
  testId: PropTypes.string,
};

ProgressBar.defaultProps = {
  color: 'gray',
  testId: 'progressBar',
};

import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  sharingContent: {
    // padding: `${theme.spacing(0.5)} ${theme.spacing(0.5)}`,
    // background: theme.palette.primary[1300],
    // borderRadius: 8,
    '& .MuiFormLabel-root, & .MuiFormLabel-root': {
      color: theme.palette.primary[100],
    },
    '& .MuiInputBase-root svg': {
      marginRight: '5px',
    },
    '& .listSection': {
      maxHeight: '10vh',
      marginTop: theme.spacing(0.5),
      overflowY: 'auto',
    },
    '& .itemList': {
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
      color: theme.palette.primary[500],
    },
    '& .MuiRadio-root, & .MuiCheckbox-root': {
      color: theme.palette.primary[400],
      '&.Mui-checked': {
        color: theme.palette.primary[400],
      },
      '&:hover': {
        color: theme.palette.secondary.main,
      },
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& .MuiFormGroup-row': {
      width: '100%',
      justifyContent: 'space-between',
    },
    '& .marginTop': {
      marginTop: theme.spacing(1),
    },
  },
});

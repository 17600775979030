import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

export const AdminHeaderTitle = ({ buttonName, section, variant }) => {
  const history = useHistory();

  const DefaultAdmin = () => (
    <>
      <button type="button" onClick={() => history.push('/admin')}>
        {buttonName}
      </button>
      {' > '}
    </>
  );

  return (
    <>
      {variant === 'admin' && (
        <>
          <DefaultAdmin /> {section}
        </>
      )}
      {variant === 'userPermissions' && (
        <>
          <DefaultAdmin />
          <button type="button" onClick={() => history.goBack()}>
            {section}
          </button>{' '}
          <button type="button" onClick={() => history.goBack()}>
            {' > '}USER PERMISSIONS
          </button>
        </>
      )}
    </>
  );
};

AdminHeaderTitle.propTypes = {
  buttonName: PropTypes.string,
  section: PropTypes.string,
  variant: PropTypes.string,
};

AdminHeaderTitle.defaultProps = {
  buttonName: '',
  section: '',
  variant: 'admin',
};

import React from 'react';
import { Button, DialogContent, Dialog, Grid, DialogActions, DialogTitle, Typography } from '@mui/material';
import { ModalHeaderIcon } from 'components/ModalHeaderIcon/ModalHeaderIcon';
import { MapFileIcon } from 'components/Icons';
import { WidgetDivider } from 'components/WidgetDivider/WidgetDivider';
import { MapSimplePoint } from 'components/MapSimplePoint/MapSimplePoint';

// get extent functions
export const distanceInKm = (lon1, lat1, lon2, lat2) => {
  const deg2rad = (deg) => deg * (Math.PI / 180);
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
};

export const MapExtentModal = ({
  project,
  selectedAsset,
  handleClose,
  setAuxCoords,
  setApplyExtentCoords,
  section,
}) => (
  <Dialog open onClose={() => handleClose()} maxWidth="sm" fullWidth>
    <DialogTitle>
      <ModalHeaderIcon icon={MapFileIcon} text="Select by Map Extent" />
      <Typography mt={0.5}>Zoom and drag to select the desired area.</Typography>
    </DialogTitle>
    <DialogContent>
      <MapSimplePoint
        project={project}
        selectedAsset={selectedAsset}
        handleRowClick={() => {}}
        getExtent
        onClickGetExtent={(coordinates) => setAuxCoords(coordinates)}
        section={section}
      />
    </DialogContent>
    <DialogActions>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <WidgetDivider color="secondary" />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              handleClose();
            }}
            fullWidth
          >
            Close
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Button color="secondary" variant="contained" onClick={() => setApplyExtentCoords()} fullWidth>
            Save
          </Button>
        </Grid>
      </Grid>
    </DialogActions>
  </Dialog>
);

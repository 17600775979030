import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { ArrowLeftIcon } from 'components/Icons';
import Logo from '../../images/CartovidTransparent.png';
import { useStyles } from './styles';

export const LayoutAuth = ({ title, subTitle, noReturn, children }) => {
  const classes = useStyles({ subTitle });

  return (
    <div className={classes.content}>
      <div className={classes.grapper}>
        <div className={classes.logoContent}>
          <img src={Logo} alt="Cartovid Logo" className={classes.logo} />
        </div>
        <div className={classes.textContent}>
          {title && <Typography className={classes.title}>{title}</Typography>}
          {subTitle && (
            <Typography variant="h5" className={classes.subtitle}>
              {subTitle}
            </Typography>
          )}
        </div>
        <div className={classes.childContent}>{children}</div>
        {!noReturn && (
          <div className={classes.loginReturn}>
            <Link to="/login">
              <ArrowLeftIcon size={12} /> Return to log in
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

LayoutAuth.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  noReturn: PropTypes.bool,
  children: PropTypes.node,
};

LayoutAuth.defaultProps = { title: null, subTitle: null, noReturn: false, children: null };

import { StyleSheet } from '@react-pdf/renderer';
import { theme } from 'theme';

export const pdfStyles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    color: 'black',
    padding: 10,
    paddingTop: 15,
  },
  section: {
    margin: 5,
    padding: 10,
  },
  titleSection: {
    margin: 5,
    padding: 10,
    borderBottom: '1px solid black',
    fontWeight: 'heavy',
    fontSize: '20px',
  },
  left: {
    marginBottom: -20,
    width: 450,
  },
  right: {
    textAlign: 'right',
  },
  date: {
    fontSize: '10px',
    color: '#454646',
  },
  text: {
    margin: 5,
    fontSize: '14px',
  },
  normal: {
    color: '#454646',
  },
  img: {
    fontSize: '14px',
    color: theme.palette.secondary.main,
    borderRadius: '4px',
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    borderTop: `1px solid ${theme.palette.secondary.main}`,
    borderLeft: `1px solid ${theme.palette.secondary.main}`,
    borderRight: `1px solid ${theme.palette.secondary.main}`,
    margin: '5 0',
    padding: 1,
    width: '150px',
    textAlign: 'center',
  },
  buttonGrapper: {
    padding: 0,
  },
  smallButton: {
    minHeight: 'auto',
    minWidth: 'auto',
    padding: '8px',
    marginRight: '5px',
  },
});

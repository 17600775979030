/**
 * Parse errors returned from axios into a format cmpatible with formik.
 *
 * @param {import("axios").AxiosError} err
 * @param {Record<key, string>} overrides Key value pairs to map api field names to formik field names if necessary.
 * @returns
 */
export const parseApiValidationError = (err, overrides = {}) => {
  if (err.response.status === 400) {
    const messages = err.response.data.error.extra;
    return Object.keys(messages).reduce((acc, key) => {
      acc[overrides[key] || key] = messages[key][0].message;

      return acc;
    }, {});
  }
  return false;
};

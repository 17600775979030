import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { parseApiValidationError } from 'lib/parse-api-validation-error';
import { useFormik } from 'formik';
import OrganizationService from 'services/OrganizationService';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputLabel, TextField } from '@mui/material';
import { ModalHeaderIcon } from 'components/ModalHeaderIcon/ModalHeaderIcon';
import { GridIcon } from 'components/Icons';

const validationSchema = Yup.object({
  name: Yup.string()
    .trim()
    .matches(/^[A-Za-z0-9\s]+$/, 'Org Name can only contain letters, numbers, and spaces')
    .min(1, 'Org Name must contain at least one character')
    .max(40)
    .required('Organization name is required'),
  description: Yup.string()
    .trim()
    .matches(/^[A-Za-z0-9\s]+$/, 'Description can only contain letters, numbers, and spaces')
    .min(1, 'Description must  contain at least one character')
    .max(255)
    .required('Description is required'),
});

export const ModalEditOrganization = ({ onCancel, onConfirm, isOpen, orgId, orgName }) => {
  const [loading, setLoading] = React.useState(false);
  const [orgData, setOrgData] = React.useState({});

  const editOrgInfo = async (values) => {
    setLoading(true);
    const data = {
      name: values.name,
      description: values.description,
    };

    try {
      const response = await OrganizationService.updateOrganizationInfo(orgId, data);
      onConfirm(response.data.data);
    } catch (err) {
      setLoading(false);
      const result = parseApiValidationError(err);

      if (result) {
        // eslint-disable-next-line no-use-before-define
        formik.setErrors(result);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      name: orgData.name || '',
      description: orgData.description || null,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit(values) {
      editOrgInfo(values);
    },
  });

  const fetchOrgData = async (id) => {
    const getOrganization = async (filters) => {
      const response = await OrganizationService.getSingleOrganization(filters);
      setOrgData(response.data.data);
    };
    getOrganization(id);
  };

  React.useEffect(() => {
    fetchOrgData(orgId);
  }, []);

  return (
    <Dialog open={isOpen} maxWidth="xs" onClose={onCancel}>
      <DialogTitle>
        <ModalHeaderIcon icon={GridIcon} text={`Update ${orgName}`} color="warning" />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <InputLabel>Name *</InputLabel>
            <TextField
              variant="outlined"
              name="name"
              disabled={loading}
              type="text"
              {...formik.getFieldProps('name')}
              error={!!(formik.touched.name && formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name ? formik.errors.name : null}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Description *</InputLabel>
            <TextField
              variant="outlined"
              placeholder="Org Description"
              name="description"
              disabled={loading}
              type="text"
              {...formik.getFieldProps('description')}
              error={!!(formik.touched.description && formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description ? formik.errors.description : null}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button color="primary" variant="contained" onClick={onCancel} disabled={loading} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button color="secondary" variant="contained" disabled={loading} onClick={formik.handleSubmit} fullWidth>
              Update
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

ModalEditOrganization.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  isOpen: PropTypes.bool,
  orgId: PropTypes.string,
  orgName: PropTypes.string,
};

ModalEditOrganization.defaultProps = {
  isOpen: false,
  onConfirm: null,
  onCancel: null,
  orgId: null,
  orgName: '',
};

import PropTypes from 'prop-types';
import React from 'react';

export const If = ({ cond, children }) => {
  if (cond === true) {
    return <>{children}</>;
  }
  return <></>;
};

If.propTypes = {
  cond: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

If.defaultProps = {
  children: null,
};

import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { DevicesIcon, NotificationIcon, PencilIcon, UsersIcon } from 'components/Icons';
import { WidgetHeader } from 'components/WidgetHeader/WidgetHeader';
import { PermissionsValidator } from 'lib/permValidator';
import TeamService from 'services/TeamService';
import { ModalEditTeamDescription } from 'smartComponents/ModalEditTeamDescription';
import { ModalInviteTeam } from 'smartComponents/ModalInviteTeam';
import { Button, Grid, Typography } from '@mui/material';
import { useStyles as useAdminStyles } from '../../Admin/Dashboard/styles';

export const QuickActionWidget = () => {
  const adminClasses = useAdminStyles();
  const history = useHistory();
  const params = useParams();
  const [openInviteUsers, setOpenInviteUsers] = useState(false);
  const [organizationId, setOrganizationId] = useState(null);
  const [openEditTeamDescription, setOpenEditTeamDescription] = useState(false);

  React.useEffect(() => {
    // Get Team Info
    const getTeam = async () => {
      const response = await TeamService.getTeam(params.teamId);
      setOrganizationId(response.data.data.org.id);
    };
    getTeam();
  }, []);

  const navigateToTeamMembers = () => {
    history.push(`/teams/${params.teamId}/members`);
  };

  const navigateToTeamProjects = () => {
    history.push(`/teams/${params.teamId}/projects`);
  };

  // Manage the Open & Close Invite users to an Organization Modal
  const handleOpenInviteUsers = async () => {
    setOpenInviteUsers(true);
  };

  const handleInviteUsers = async () => {
    setOpenInviteUsers(false);
  };

  const handleCancelInviteUsers = () => {
    setOpenInviteUsers(false);
  };

  const handleOpenEditTeamDescription = () => {
    setOpenEditTeamDescription(true);
  };

  const handleConfirmEditTeamDescription = () => {
    setOpenEditTeamDescription(false);
    // loadData();
  };

  const handleCancelEditTeamDescription = () => {
    setOpenEditTeamDescription(false);
  };

  const isTeamAdmin = PermissionsValidator.validate({ itemId: params.teamId, scopes: ['team:modify'] });

  return (
    <div className={adminClasses.generalContent}>
      <WidgetHeader title="Quick Actions" subTitle="Get to the details fast." />
      <Grid container spacing={0.5}>
        {isTeamAdmin ? (
          <>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Button
                size="large"
                color="primary"
                type="button"
                fullWidth
                onClick={() => handleOpenInviteUsers()}
                variant="contained"
              >
                <UsersIcon />
                <Typography ml={0.5}>Add Team Members</Typography>
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Button
                size="large"
                color="primary"
                type="button"
                fullWidth
                onClick={() => navigateToTeamMembers()}
                variant="contained"
              >
                <UsersIcon />
                <Typography ml={0.5}>Edit Team Members</Typography>
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Button
                size="large"
                color="primary"
                type="button"
                fullWidth
                onClick={() => handleOpenEditTeamDescription()}
                variant="contained"
              >
                <PencilIcon />
                <Typography ml={0.5}>Edit Team Desciption</Typography>
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Button
                size="large"
                color="primary"
                type="button"
                fullWidth
                onClick={() => navigateToTeamProjects()}
                variant="contained"
              >
                <DevicesIcon />
                <Typography ml={0.5}>Project Participation</Typography>
              </Button>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Button
                size="large"
                color="primary"
                type="button"
                fullWidth
                onClick={() => navigateToTeamProjects()}
                variant="contained"
              >
                <DevicesIcon />
                <Typography ml={0.5}>View Team Projects</Typography>
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Button
                size="large"
                color="primary"
                type="button"
                fullWidth
                onClick={() => navigateToTeamMembers()}
                variant="contained"
              >
                <UsersIcon />
                <Typography ml={0.5}>Member Profiles</Typography>
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Button size="large" color="primary" type="button" fullWidth variant="contained">
                <NotificationIcon />
                <Typography ml={0.5}>View Team Discussions</Typography>
              </Button>
            </Grid>
          </>
        )}
      </Grid>
      {openInviteUsers && (
        <ModalInviteTeam
          isOpen={openInviteUsers}
          onConfirm={handleInviteUsers}
          onCancel={handleCancelInviteUsers}
          teamId={params.teamId}
          orgId={organizationId}
        />
      )}
      {openEditTeamDescription && (
        <ModalEditTeamDescription
          isOpen={openEditTeamDescription}
          handleConfirm={handleConfirmEditTeamDescription}
          handleCancel={handleCancelEditTeamDescription}
          teamId={params.teamId}
        />
      )}
    </div>
  );
};

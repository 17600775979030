/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Checkbox, FormControlLabel, Grid, InputLabel, TextField } from '@mui/material';
import { PlusIcon } from 'components/Icons';

const validateObj = {
  name: Yup.string().required('Name Required'),
  description: Yup.string().required('Description Required'),
  required: Yup.boolean(),
};

export const ShowNewField = ({ field, type, onHandleCreate }) => {
  const [answers, setAnswers] = useState(type.value === 'input' ? [] : ['']);

  // add field to table
  const createFiel = (values) => {
    const result = {
      ...field,
      name: values.name,
      description: values.description,
      required: values.required,
      ...(type.value === 'checkbox' && { selectAll: values.selectAll }),
      ...(type.isMultiple && {
        answers: Object.keys(values)
          .filter((key) => key.startsWith('choice_'))
          .map((key) => values[key]),
      }),
      code: type.value,
      fieldName: type.label,
      isMultiple: type.isMultiple,
    };
    onHandleCreate(result);
  };

  const auxValidate = {};
  answers.forEach((element, index) => {
    auxValidate[`choice_${index + 1}`] = Yup.string().required(`Choice ${index + 1} Required`);
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      description: '',
      required: field.editAction ? field.required : false,
      selectAll: field.editAction ? field.selectAll : false,
    },
    validationSchema: Yup.object({ ...validateObj, ...auxValidate }),
    onSubmit(values) {
      createFiel(values);
    },
  });

  const { values, handleChange, handleBlur, handleSubmit } = formik;

  const addNewInput = () => {
    setAnswers([...answers, '']);
  };

  // add posible answers for checkbox, select and radio
  useEffect(() => {
    answers.forEach((element, index) => {
      if (formik.values[`choice_${index + 1}`]) {
        formik.handleChange(`choice_${index + 1}`)(formik.values[`choice_${index + 1}`]);
      } else {
        formik.handleChange(`choice_${index + 1}`)(field.editAction ? element : '');
      }
    });
  }, [answers]);

  useEffect(() => {
    // update inputs in filed type and edition
    if (!answers.length) setAnswers(['']);
    if (type.value === 'input') setAnswers([]);
    if (field.editAction) {
      formik.handleChange('name')(field.name);
      formik.handleChange('description')(field.description);
      if (field.isMultiple && field.answers?.length) {
        const auxAnswers = [];
        field.answers.forEach((element) => {
          auxAnswers.push(element);
        });
        setAnswers(auxAnswers);
      }
    }
  }, [type.value, field.id]);

  return (
    <>
      <Grid item xs={12}>
        <InputLabel>Name</InputLabel>
        <TextField
          placeholder={`Field Name ${field.index}`}
          value={values.name}
          id="name"
          name="name"
          error={!!(formik.touched.name && formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name ? formik.errors.name : null}
          onChange={handleChange}
          onBlur={handleBlur}
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <InputLabel>Description</InputLabel>
        <TextField
          placeholder="Current Description of Field"
          value={values.description}
          id="description"
          name="description"
          error={!!(formik.touched.description && formik.errors.description)}
          helperText={formik.touched.description && formik.errors.description ? formik.errors.description : null}
          onChange={handleChange}
          onBlur={handleBlur}
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={<Checkbox color="secondary" />}
          label="Required Field"
          name="required"
          value={values.required}
          onChange={handleChange}
          checked={values.required}
          color="primary"
        />
      </Grid>
      {type.value === 'checkbox' && (
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" />}
            label="Provide 'Select All' Optioon"
            name="selectAll"
            value={values.selectAll}
            onChange={handleChange}
            checked={values.selectAll}
            color="primary"
          />
        </Grid>
      )}
      {type.isMultiple ? (
        <>
          {answers.map((answer, index) => (
            <Grid item xs={12} key={answer}>
              {index === 0 && <InputLabel>{`${type.label} Choices`}</InputLabel>}
              <TextField
                placeholder={`Choice ${index + 1}`}
                value={formik.values[`choice_${index + 1}`] || ''}
                name={`choice_${index + 1}`}
                error={!!(formik.touched[`choice_${index + 1}`] && formik.errors[`choice_${index + 1}`])}
                helperText={
                  formik.touched[`choice_${index + 1}`] && formik.errors[`choice_${index + 1}`]
                    ? formik.errors[`choice_${index + 1}`]
                    : null
                }
                onChange={handleChange}
                onBlur={handleBlur}
                required
                fullWidth
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button color="secondary" variant="text" onClick={addNewInput}>
              <PlusIcon size={20} /> Add another
            </Button>
          </Grid>
        </>
      ) : (
        ''
      )}
      <Grid item xs={12}>
        <Button color="primary" variant="contained" fullWidth onClick={handleSubmit}>
          Save Changes
        </Button>
      </Grid>
    </>
  );
};

ShowNewField.propTypes = {
  field: PropTypes.object.isRequired,
  type: PropTypes.object.isRequired,
  onHandleCreate: PropTypes.func.isRequired,
};

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, InputLabel, TextField, Typography } from '@mui/material';
import { SearchIcon } from 'components/Icons';
import { Loader } from 'components/Loader';
import { v4 } from 'uuid';

export const SearchList = ({ title, fullList, filter, localFilters, onChangeSelect, loaded, noMarginTop }) => {
  const [search, setSearch] = useState('');
  const [list, setList] = useState([]);
  const listSectionRef = useRef(null);

  const scrollToTop = () => {
    if (listSectionRef.current) {
      listSectionRef.current.scrollTop = 0;
    }
  };

  // order list if there are selected items
  const orderList = (a, b) => {
    const indexA = [...localFilters[filter]].reverse().indexOf(a.value);
    const indexB = [...localFilters[filter]].reverse().indexOf(b.value);
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;
    return indexA - indexB;
  };

  // functions to search in list sections
  const filterOptions = (text) => {
    const filteredOptions = fullList.filter((option) => option.label.toLowerCase().includes(text.toLowerCase()));
    return filteredOptions;
  };

  const filterList = (value) => {
    setSearch(value);
    const auxList =
      Array.isArray(localFilters[filter]) && localFilters[filter].length > 0
        ? [...filterOptions(value)].sort(orderList)
        : [...filterOptions(value)];
    setList([...auxList]);
  };

  // update filters
  const updateFIlter = (value, checked) => {
    const auxFilters = { ...localFilters };
    if (checked) {
      if (typeof auxFilters[filter] === 'string' || !Array.isArray(auxFilters[filter]) || !auxFilters[filter])
        auxFilters[filter] = [];
      auxFilters[filter] = [...auxFilters[filter]];
      auxFilters[filter].push(value);
    } else {
      auxFilters[filter] = auxFilters[filter].filter((element) => element !== value);
    }
    onChangeSelect(auxFilters);
  };

  useEffect(() => {
    filterList(search);
    scrollToTop();
  }, [fullList, localFilters]);

  return (
    <>
      <InputLabel>
        <Typography className={noMarginTop ? '' : 'marginTop'}>{title}</Typography>
      </InputLabel>
      <TextField
        variant="outlined"
        placeholder="Search"
        InputProps={{
          startAdornment: <SearchIcon size={15} />,
        }}
        value={search}
        onChange={(val) => filterList(val.target.value)}
        fullWidth
        disabled={!loaded}
      />
      {!loaded ? (
        <Loader height={10} loading />
      ) : (
        list?.length > 0 && (
          <div className="listSection" ref={listSectionRef}>
            {list.map((option) => (
              <div key={`${v4()}${new Date().getMilliseconds()}`} className="itemList">
                <Checkbox
                  checked={
                    localFilters[filter] &&
                    localFilters[filter].length > 0 &&
                    localFilters[filter].includes(option.value)
                  }
                  onChange={(event) => updateFIlter(option.value, event.target.checked)}
                  color="secondary"
                />
                <Typography>{option.label}</Typography>
              </div>
            ))}
          </div>
        )
      )}
    </>
  );
};

SearchList.propTypes = {
  title: PropTypes.string,
  fullList: PropTypes.array,
  filter: PropTypes.string,
  localFilters: PropTypes.object,
  onChangeSelect: PropTypes.func,
  loaded: PropTypes.bool,
  noMarginTop: PropTypes.bool,
};

SearchList.defaultProps = {
  title: '',
  fullList: [],
  filter: '',
  localFilters: {},
  onChangeSelect: () => {},
  loaded: false,
  noMarginTop: false,
};

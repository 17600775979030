import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const ChevronUpIcon = ({ size, className }) => (
  <Svg viewBox="0 0 24 24" size={size} className={className}>
    <svg
      className="feather feather-chevron-up"
      fill="none"
      height="24"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polyline points="18 15 12 9 6 15" />
    </svg>
  </Svg>
);

ChevronUpIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
};

ChevronUpIcon.defaultProps = {
  size: 24,
  className: '',
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SearchPanelBody, searchPanelSection } from 'pages/Profile/SingleProject/SearchPanel/SearchPanelBody';
import { dateRangeSelector, preloadFilterDatasets, setFilterValue, setFilters } from 'slices/contentReducer';

export const SearchPanel = () => {
  const dispatch = useDispatch();
  const nameSort = useSelector((state) => state.content.filters.sort_by.name || '');
  const dateSort = useSelector((state) => state.content.filters.sort_by.createdAt || '');
  const projectId = useSelector((state) => state.content.filters.projectId || '');
  const organizationId = useSelector((state) => state.content.filters.organizationId || '');
  const type = useSelector((state) => state.content.filters.type || '');
  const dateRange = useSelector(dateRangeSelector);
  const { users, teams, projects, organizations, accounts, loaded } = useSelector(
    (state) => state.content.panels.search
  );

  useEffect(() => {
    dispatch(preloadFilterDatasets());
  }, []);

  return (
    <SearchPanelBody
      loaded={loaded}
      users={users}
      accounts={accounts}
      teams={teams}
      projects={projects}
      organizations={organizations}
      filters={{
        sort_by: {
          name: nameSort,
          createdAt: dateSort,
        },
        date_range: dateRange,
        type,
        organizationId,
        projectId,
      }}
      setFilterValue={setFilterValue}
      setFilters={setFilters}
      section={searchPanelSection.PROFILECONTENT}
    />
  );
};

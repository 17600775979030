import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  styledLi: {
    listStyle: 'none',
    margin: 0,
    padding: `${theme.spacing(0.1)} 0`,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
  },
  styledLink: (props) => ({
    display: 'flex',
    backgroundColor: props.active ? theme.palette.secondary.main : theme.palette.primary[1200],
    color: props.active ? 'black!important' : theme.palette.primary[300],
    textDecoration: 'none',
    padding: `${theme.spacing(0.5)} ${theme.spacing(0.5)}`,
    ...(!props.active && {
      '&:hover, &:focus': {
        color: theme.palette.primary[300],
        backgroundColor: theme.palette.primary[900],
        '& svg': {
          color: theme.palette.primary[500],
        },
      },
    }),
    '& svg': {
      color: props.active ? 'black!important' : theme.palette.primary[500],
    },
    borderRadius: '10px',
    alignItems: 'center',
  }),
});

import { AdminPanelButton } from 'components/AdminPanelButton';
import { PermGuard } from 'components/Guards/PermGuard/PermGuard';
import { CameraIcon, DoubleCheckIcon, EyeIcon, UsersIcon } from 'components/Icons';
import { SidebarPanel } from 'components/SidebarPanel';
import { Permissions } from 'lib/permissions';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ModalProjectSettings } from 'smartComponents/ModalProjectSettings/ModalProjectSettings';
import { ModalSelectThumbnail } from 'smartComponents/ModalSelectThumbnail';

export const AdminPanel = () => {
  const [selectThumbnailOpen, setSelectThumbnailOpen] = React.useState(false);
  const [openProjectSettingsModal, setOpenProjectSettingsModal] = React.useState(false);
  const { id } = useSelector((state) => state.adminProjects.panels.projDetails);

  const history = useHistory();
  const navigateToParticipant = () => {
    history.push(`/projects/${id}/participant`);
  };

  const navigateToAssets = () => {
    history.push(`/projects/${id}/content`);
  };

  const onThumbnailSelect = (/* { type, img } */) => {
    // dispatch action to update the selected thumbnai
    setSelectThumbnailOpen(false);
  };

  const onThumbnailCancel = () => {
    setSelectThumbnailOpen(false);
  };

  const handleOpenSelectThumbnailModal = () => {
    setSelectThumbnailOpen(true);
  };

  // Manage the Project Settings Modal
  const handleOpenProjectSettingsModal = () => {
    setOpenProjectSettingsModal(true);
  };

  const onCancelProjectSettings = () => {
    setOpenProjectSettingsModal(false);
  };

  const handleProjectSettings = () => {
    setOpenProjectSettingsModal(false);
  };

  return (
    <>
      <SidebarPanel title="Project Details">
        <PermGuard itemId={id} scopes={[Permissions.PROJ_MODIFY]}>
          <AdminPanelButton icon={EyeIcon} onClick={handleOpenProjectSettingsModal}>
            Project Visibility &amp; Content Sharing/Copying Settings
          </AdminPanelButton>
          <AdminPanelButton icon={CameraIcon} onClick={handleOpenSelectThumbnailModal}>
            Set project image-Choose a meaningful image or map area
          </AdminPanelButton>

          <AdminPanelButton
            icon={DoubleCheckIcon}
            onClick={() => {
              navigateToAssets();
            }}
          >
            Content coordination-Turn on/off access to project assets
          </AdminPanelButton>
        </PermGuard>
        <AdminPanelButton
          icon={UsersIcon}
          onClick={() => {
            navigateToParticipant();
          }}
        >
          Participant Coordination - Turn access on/off for teams &amp; individuals.
        </AdminPanelButton>
        {/* <AdminPanelButton icon={FlagIcon}>
          [Future State] Project Alerts - Notify users of changes or issues.
        </AdminPanelButton>
        <AdminPanelButton icon={LoadIcon}>
          Autoshare - Automate incoming &amp; outgoing sharing/copying.
        </AdminPanelButton> */}
      </SidebarPanel>
      {selectThumbnailOpen && (
        <ModalSelectThumbnail
          onSelect={onThumbnailSelect}
          onCancel={onThumbnailCancel}
          projectId={id}
          open={selectThumbnailOpen}
        />
      )}
      {openProjectSettingsModal && (
        <ModalProjectSettings
          handleOpen={handleProjectSettings}
          isOpenModal={openProjectSettingsModal}
          handleClose={onCancelProjectSettings}
          projectId={id}
        />
      )}
    </>
  );
};

AdminPanelButton.propTypes = {
  icon: PropTypes.func,
  children: PropTypes.string,
  onClick: PropTypes.func,
};

AdminPanelButton.defaultProps = {
  icon: null,
  children: '',
  onClick: () => {},
};

const { Permissions } = require('./permissions');

export const orgOptions = [
  { value: 1, label: 'Combine Organizations' },
  { value: 2, label: 'Transfer Content' },
  { value: 3, label: 'Transfer Members' },
  { value: 4, label: 'Delete Organizations' },
];

export const adminOptions = [{ value: 1, label: 'Add/Remove Admin Privs' }];

export const userOptions = [
  { value: 1, label: 'Add/Remove from Project' },
  { value: 2, label: 'Add/Remove from Team' },
  { value: 3, label: 'Add/Transfer Orgs' },
  { value: 4, label: 'Fore Password Reset' },
  { value: 5, label: 'Delete Users' },
];

export const projectOptions = [
  { value: Permissions.PROJ_COPY, label: 'Copy Projects' },
  { value: Permissions.PROJ_SHARE, label: 'Share Projects' },
  { value: Permissions.PROJ_COPY, label: 'Add Administrator' },
  { value: Permissions.PROJ_COPY, label: 'Transfer Projects' },
  { value: Permissions.PROJ_COPY, label: 'Archive Projects' },
  { value: Permissions.PROJ_DELETE, label: 'Delete Projects' },
];

export const teamOptions = [
  { value: 1, label: 'Add/Remove from Project' },
  { value: 2, label: 'Duplicate Teams' },
  { value: 3, label: 'Transfer Orgs' },
  { value: 4, label: 'Combine Teams' },
  { value: 5, label: 'Delete Teams' },
];

export const contentOptions = [
  { value: 1, label: 'Copy Content' },
  { value: 2, label: 'Share Content' },
  { value: 3, label: 'Transfer to Project' },
  { value: 4, label: 'Add to Inspections' },
  { value: 5, label: 'Delete Content' },
];

export const externalUsersOptions = [{ value: 1, label: 'Remove from Account' }];

export const externalProjectsOptions = [
  { value: Permissions.PROJ_COPY, label: 'Copy Projects' },
  { value: Permissions.PROJ_SHARE, label: 'Share Projects' },
  { value: 3, label: 'Remove from Account' },
];

export const externalTeamsOptions = [{ value: 1, label: 'Remove from Account' }];

export const externalContentOptions = [
  { value: 1, label: 'Copy Content' },
  { value: 2, label: 'Share Content' },
  { value: 3, label: 'Transfer to Project' },
  { value: 5, label: 'Delete Content' },
];

export const mapGisOptions = [
  { value: 1, label: 'Copy Content' },
  { value: 5, label: 'Delete Content' },
];

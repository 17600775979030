import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const FlagIcon = ({ size, className }) => (
  <Svg viewBox="0 0 24 24" size={size} className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.726 2.824L13.14 0H0v24h2.92v-9.882h8.177l.584 2.823h10.222V2.824h-8.177z"
      fill="currentColor"
    />
  </Svg>
);

FlagIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
};

FlagIcon.defaultProps = {
  size: 24,
  className: '',
};

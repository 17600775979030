import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  trackZoom: (props) => ({
    position: 'absolute',
    margin: '6px',
    zIndex: 100,
    bottom: props.type === 'map' ? '20px' : '10px',
    right: props.type === 'map' ? '50px' : '0',
    padding: '2px',
  }),
});

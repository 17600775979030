import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormRadioGroup } from 'components/FormRadioGroup/FormRadioGroup';
import ProjectService from 'services/ProjectService';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { WidgetDivider } from 'components/WidgetDivider/WidgetDivider';
import { useStyles } from './styles';

export const ModalSelectThumbnail = ({ onSelect, onCancel, projectId, open }) => {
  const classes = useStyles();
  const [selectedType, setSelectedType] = React.useState('mapview');
  const [selectedThumb, setSelectedThumb] = React.useState(null);
  const [assets, setAssets] = React.useState([]);
  const [errors, setErrors] = React.useState(null);

  const fetchAssets = useCallback(async () => {
    const [assetResults, projectResult] = await Promise.all([
      await ProjectService.getProjectAssets(projectId),
      await ProjectService.getProject(projectId),
    ]);

    setAssets(assetResults.data.data);
    setSelectedThumb(
      assetResults.data.data.find((asset) => asset.id === projectResult.data.data.thumbnailAssetId) || {}
    );
    setSelectedType(projectResult.data.data.thumbnailType);
  }, [projectId]);

  useEffect(() => {
    fetchAssets();
  }, []);

  const validate = () => {
    if (selectedType === 'asset' && !selectedThumb.id) {
      setErrors('Please select a thumbnail');

      return false;
    }
    setErrors(null);

    return true;
  };

  const parseThumbDescription = (description = '') => {
    if (description.length < 20) {
      return description;
    }

    return `${description.substring(0, 17)}...`;
  };

  const handleSelectClick = async () => {
    const isValid = validate();

    // update project
    if (isValid) {
      await ProjectService.updateProject(projectId, {
        thumbnailType: selectedType,
        thumbnailAssetId: selectedType === 'mapview' ? null : selectedThumb.id,
      });

      // pass asset back to calling component via onSelect
      onSelect({
        type: selectedType,
        thumbnail: selectedThumb,
      });
    }
  };

  return (
    <Dialog open={open} maxWidth="sm" onClose={onCancel}>
      <DialogTitle>Select Project Thumbnail</DialogTitle>
      <DialogContent>
        <FormRadioGroup
          name="thumbnailType"
          value={selectedType}
          onChecked={(checkedVal) => {
            setSelectedType(checkedVal);
            setErrors(null);
            if (checkedVal === 'mapview') {
              setSelectedThumb(null);
            }
          }}
          options={[
            { label: 'Default: Use project map view', value: 'mapview', disabled: false },
            { label: 'Select the thumbnail of a project asset', value: 'asset', disabled: false },
          ]}
        />
        <WidgetDivider color="secondary" margin />
        {errors && <Typography color="error">{errors}</Typography>}
        <div className={classes.thumbnailRow}>
          {assets.map((asset) => (
            <Button
              className={classes.ImgContainer}
              key={asset?.id}
              onClick={selectedType === 'asset' ? () => setSelectedThumb(asset) : () => {}}
              selected={selectedThumb?.id === asset.id}
              disabled={selectedType === 'mapview'}
            >
              {asset.thumbnails && <img src={asset.thumbnails[400]} alt={asset.description} />}
              {asset.description && <Typography>{parseThumbDescription(asset.description)}</Typography>}
            </Button>
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button color="primary" variant="contained" onClick={onCancel} size="xs" fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button color="secondary" variant="contained" onClick={handleSelectClick} size="xs" fullWidth>
              Use as Thumbnail
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

ModalSelectThumbnail.propTypes = {
  open: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
};

ModalSelectThumbnail.defaultProps = {
  open: false,
};

/// import { FormInput } from 'components/FormInput';
// import { FormRadioGroup } from 'components/FormRadioGroup';
// import { FormSelect } from 'components/FormSelect/FormSelect';
// import { SidebarPanel } from 'components/SidebarPanel';
// import { SidebarPanelSection } from 'components/SidebarPanelSection';
// import { SidebarContext } from 'components/SidebarProvider/SidebarProvider';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  // dateRangeSelector,
  preloadFilterDatasets,
  // setAcctId,
  // setDateRange,
  setFilterValue,
  setFilters,
  // setOrganizationId,
  // setSearch,
  // setSortBy,
} from 'slices/adminProjectReducer';
import { SearchPanelBody } from './SearchPanelBody';

export const SearchPanel = () => {
  const dispatch = useDispatch();
  // const ctx = useContext(SidebarContext);
  const reduce = 'adminProjects';
  // const nameSort = useSelector((state) => state[reduce].filters.sort_by.name || '');
  // const dateSort = useSelector((state) => state[reduce].filters.sort_by.createdAt || '');
  // const accountSort = useSelector((state) => state.adminProjects.filters.sort_by.account || '');
  // const search = useSelector((state) => state[reduce].filters.search || '');
  // const orgSort = useSelector((state) => state[reduce].filters.sort_by.org || '');
  const userId = useSelector((state) => state[reduce].filters.userId || '');
  // const teamId = useSelector((state) => state[reduce].filters.teamId || '');
  // const acctId = useSelector((state) => state.adminProjects.filters.acctId || '');
  // const organizationId = useSelector((state) => state.adminProjects.filters.organizationId || '');
  // const dateRange = useSelector(dateRangeSelector);
  /* TODO - create arcgis slices */
  const { users } = useSelector((state) => state.adminProjects.panels.search);
  const { loaded } = useSelector((state) => state[reduce].panels.search);
  const isOpen = useSelector((state) => state[reduce].filters.isOpen);

  useEffect(() => {
    dispatch(preloadFilterDatasets());
  }, []);

  return (
    <SearchPanelBody
      loaded={loaded}
      users={users}
      filters={{
        userId,
        isOpen,
      }}
      setFilterValue={setFilterValue}
      setFilters={setFilters}
    />
  );
};

import React, { useState } from 'react';
import moment from 'moment';
import { Avatar } from 'components/Avatar';
import { RoleDialog } from 'components/RoleDialog/RoleDialog';
import { SidebarPanel } from 'components/SidebarPanel';
import { useDispatch, useSelector } from 'react-redux';
import EnumService from 'services/EnumService';
import headerImg from 'images/userDetail.png';
import OrganizationService from 'services/OrganizationService';
import { updateAdminRoles } from 'slices/organizationReducer';
import { ModalOrganizationInviteUsers } from 'smartComponents/ModalOrganizationInviteUsers';
import { InfoPanelBodyHeader } from 'components/InfoPanelBodyHeader/InfoPanelBodyHeader';
import { getInitials } from 'lib/generalFunctions';
import { useGlobalStyles } from 'styles';
import { Button, Tab, Tabs, Typography } from '@mui/material';
import { TabPanel } from 'components/TabPanel';

export const InfoPanel = () => {
  const globalClasses = useGlobalStyles();
  const [isOpenOrganizationAdmin, setIsOpenOrganizationAdmin] = React.useState(false);
  const [organizationRoles, setOrganizationRoles] = React.useState([]);
  const [openInviteUsers, setOpenInviteUsers] = React.useState(false);
  const [defaultSelections, setDefaultSelections] = React.useState([]);
  const [adminId, setAdminId] = React.useState(null);
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);

  // Manage the Open & Close Invite users to an Organization Modal
  const handleInviteUsers = async () => {
    setOpenInviteUsers(false);
  };

  const handleCancelInviteUsers = () => {
    setOpenInviteUsers(false);
  };

  React.useEffect(() => {
    const getRoles = async () => {
      const orgResponse = await EnumService.getOrgRoles();
      setOrganizationRoles(orgResponse.data.data);
    };
    getRoles();
  }, []);

  const { loading, detail, teams, projects, orgAdmins } = useSelector((state) => state.organizations.panels);

  // Manage the Open & Close Organization Admin Roles Modal
  const handleOpenOrganizationRoles = (userId) => {
    const getUserRoles = async () => {
      const userRoles = await OrganizationService.getOrganizationUserRoles(detail.id, userId);

      const holder = organizationRoles.map((role) => ({
        ...role,
        isChecked: !!userRoles.data.data.find((user) => role.id === user.roleId),
      }));

      setAdminId(userId);
      setDefaultSelections(holder);
      setIsOpenOrganizationAdmin(true);
    };
    getUserRoles();
  };

  const handleSaveOrganizationAdmin = (newRoles) => {
    const updateRoles = async () => {
      const response = await OrganizationService.updateUserRoles(
        detail.id,
        adminId,
        newRoles.map((role) => role.id)
      );

      // update state with new roles
      dispatch(
        updateAdminRoles({
          adminId,
          roles: response.data.data,
        })
      );

      setIsOpenOrganizationAdmin(false);
    };

    updateRoles();
  };

  return (
    <SidebarPanel title={detail?.org?.id && !loading ? detail.org.name : 'Organization Detail'} loading={loading}>
      <InfoPanelBodyHeader image={headerImg} name={getInitials(detail.name)} />
      <div className={globalClasses.infoPanelBody}>
        <Typography mb={1} variant="h1" className={globalClasses.infoPanelName}>
          {detail.name}
        </Typography>
        <Typography mb={0.5}>{`Date Added: ${
          detail.createdAt === null ? 'Not Found' : moment(detail.createdAt).format('MM/DD/YY')
        }`}</Typography>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => setOpenInviteUsers(true)}
          size="medium"
          sx={{ marginTop: '2rem' }}
          className={globalClasses.openDetail}
        >
          Manage Users
        </Button>
        <Typography mt={1} className={globalClasses.infoPanelAboutText}>
          About
        </Typography>
        <Typography mb={1} variant="body2" className={globalClasses.infoPanelDescription}>
          {detail.description}
        </Typography>
        <Tabs value={value} className={globalClasses.infoPanelTabs} sx={{ width: '100%' }}>
          <Tab label="Admins" onClick={() => setValue(0)} sx={{ width: '33%' }} />
          <Tab label="Projects" onClick={() => setValue(1)} sx={{ width: '33%' }} />
          <Tab label="Teams" onClick={() => setValue(2)} sx={{ width: '33%' }} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <div className={globalClasses.infoTabPanel}>
            {orgAdmins.map((admin) => (
              <div className={globalClasses.nameContainer} style={{ margin: '10px' }}>
                <Avatar
                  key={admin.id}
                  initials={getInitials(`${admin.firstName} ${admin.lastName}`)}
                  size={48}
                  isButton
                  onClick={() => {
                    handleOpenOrganizationRoles(admin.id);
                  }}
                />
                <div>
                  <Typography>{`${admin.firstName} ${admin.lastName}`}</Typography>
                </div>
              </div>
            ))}
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className={globalClasses.infoTabPanel}>
            {projects.map((project) => (
              <div className={globalClasses.nameContainer} style={{ margin: '10px' }}>
                <Avatar key={project.id} initials={getInitials(project.name)} size={48} />
                <div>
                  <Typography>{project.name}</Typography>
                </div>
              </div>
            ))}
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div className={globalClasses.infoTabPanel}>
            {teams.map((team) => (
              <div className={globalClasses.nameContainer} style={{ margin: '10px' }}>
                <Avatar key={team.id} initials={getInitials(team.name)} size={48} />
                <div>
                  <Typography>{team.name}</Typography>
                </div>
              </div>
            ))}
          </div>
        </TabPanel>
      </div>
      <RoleDialog
        isOpen={isOpenOrganizationAdmin}
        title="Edit Admin Roles"
        subTitle="Admin Name"
        roles={defaultSelections}
        onSave={handleSaveOrganizationAdmin}
        radioButton
      />
      {openInviteUsers && (
        <ModalOrganizationInviteUsers
          isOpen={openInviteUsers}
          onConfirm={handleInviteUsers}
          onCancel={handleCancelInviteUsers}
          orgId={detail.id}
        />
      )}
    </SidebarPanel>
  );
};

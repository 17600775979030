import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ProjectService from 'services/ProjectService';

export const getProjects = createAsyncThunk('projects/getProjects', async ({ userId }) => {
  try {
    const response = await ProjectService.getProjects({ userId });
    return response.data;
  } catch (e) {
    throw new Error('Error during request for users...');
  }
});

const initialState = {
  error: null,
  loading: false,
  data: [],
  panels: {
    userId: null,
    loading: false,
    detail: {},
  },
  filters: {
    page: 1,
    page_size: 10,
    sort_by: {},
  },
};

const slice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    resetProjects: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getProjects.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProjects.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.projectCount = action.payload.meta.totalCount;
      state.error = null;
      state.loading = false;
    });
    builder.addCase(getProjects.rejected, (state) => {
      state.data = [];
      state.error = 'There was an error during the request.';
      state.loading = false;
    });
  },
});

export const { resetProjects } = slice.actions;

export default slice.reducer;

import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const CheckIcon = ({ size, className }) => (
  <Svg viewBox="0 0 24 24" size={size} className={className}>
    <path
      d="M23.334 5.192a1 1 0 000-1.414l-.707-.707a1 1 0 00-1.414 0L8.485 15.8l-5.657-5.657a1 1 0 00-1.414 0l-.707.707a1 1 0 000 1.414l7.071 7.071a1 1 0 001.414 0L23.334 5.192z"
      fill="currentColor"
    />
  </Svg>
);

CheckIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
};

CheckIcon.defaultProps = {
  size: 24,
  className: null,
};

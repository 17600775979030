import React, { useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { AccountSettingsContainer } from 'components/AccountSettingsContainer/AccountSettingsContainer';
import { ProfileSettingsContainer } from 'components/ProfileSettingsContainer/ProfileSettingsContainer';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { clearProjectAsset, getProjectAsset } from 'slices/profileProjectAssetReducer';
import { goBack } from 'lib/route';
import ProjectService from 'services/ProjectService';
import PermFormatter from 'lib/permFormatter';
import { updateProjectPermissions } from 'slices/authReducer';
import { setProjectObservations } from 'slices/observationsReducer';
import { AssetContent } from './AssetContent';

let debounceTimeout = null;

export const ProjectVideoPlayer = ({ projectId, selectedAsset, fullScreen }) => {
  const location = useLocation();
  const playerRef = React.useRef(null);
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector((state) => state.auth);

  const currentUrl = window.location.href;
  const currentPage = currentUrl.split('/')[3];

  const {
    projectAsset,
    player: { selectedSequence, ovservationDialogOpen },
  } = useSelector((state) => state.profileProjectAssets);

  const getProjectName = async () => {
    const response = await ProjectService.getProject(params.projId);
    const { permissions } = auth;
    const projectPermissions = { ...permissions };
    projectPermissions.projects = {
      ...permissions.projects,
      ...PermFormatter.format(response.data.data.permissions),
    };
    dispatch(updateProjectPermissions(projectPermissions.projects));
    dispatch(setProjectObservations({ observations: response.data.data.observations, projectId: params.projId }));
  };

  useEffect(() => {
    if (!fullScreen) {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(() => {
        dispatch(getProjectAsset({ projId: projectId || params.projId, assetId: selectedAsset?.id || params.assetId }));
      }, 1000);
    }
    if (!projectId) {
      getProjectName();
    }
  }, [selectedAsset?.id]);

  useEffect(() => {
    if (playerRef.current && ovservationDialogOpen === false) {
      const sequence = projectAsset.sequences[selectedSequence];

      playerRef.current.markers.reset(
        sequence.observations.map((obs) => ({
          time: Number(obs.time),
          text: obs.name,
          id: obs.id,
        }))
      );
    }
    return () => {
      if (!fullScreen) {
        dispatch(clearProjectAsset());
      }
    };
  }, []);

  // ------------------------------------ button actions for the header
  const HeaderTitle = (type) => (
    <>
      {location.pathname.includes('admin') && (
        <>
          <button type="button" onClick={() => history.push('/admin')}>
            ADMINISTRATION
          </button>
          {' > '}
        </>
      )}
      <button type="button" onClick={() => goBack(history, -3)}>
        {type}
      </button>
      {' > '}
      <button type="button" onClick={() => goBack(history, -2)}>
        {projectAsset?.project?.name}
      </button>
      {' > '} {projectAsset?.name}
    </>
  );

  const SubTitle = () => (
    <>
      {projectAsset?.createdAt && projectAsset?.updatedAt && projectAsset?.uploaders && (
        <>
          Added: {moment(projectAsset.createdAt).format('MM/DD/YY')}, Acquired:{' '}
          {moment(projectAsset.updatedAt).format('MM/DD/YY')}, By:{' '}
          {projectAsset?.uploaders.length > 0
            ? `${projectAsset?.uploaders[0].firstName} ${projectAsset?.uploaders[0].lastName}`
            : 'Uploader not found'}
        </>
      )}
    </>
  );

  return selectedAsset?.id ? (
    <AssetContent projectId={projectId} selectedAsset={selectedAsset} />
  ) : (
    <>
      {currentPage === 'profile' && (
        <ProfileSettingsContainer title={HeaderTitle('PROJECTS')} videoDisplay showSidebar subtitle={<SubTitle />}>
          <AssetContent />
        </ProfileSettingsContainer>
      )}
      {currentPage === 'admin' && (
        <AccountSettingsContainer
          title={HeaderTitle(location.pathname.includes('external') ? 'EXTERNAL PROJECTS' : 'PROJECTS')}
          videoDisplay
          showSidebar
          subtitle={<SubTitle />}
        >
          <AssetContent />
        </AccountSettingsContainer>
      )}

      {currentPage === 'projects' && (
        <AccountSettingsContainer
          title={HeaderTitle('ALL PROJECTS')}
          // actions={[
          //   {
          //     icon: PlusIcon,
          //     onClick: () => {
          //       setOpenAddProject(true);
          //     },
          //   },
          // ]}
          showSidebar
          hideMenu
          subtitle={<SubTitle />}
        >
          <AssetContent />
        </AccountSettingsContainer>
      )}
    </>
  );
};

ProjectVideoPlayer.propTypes = {
  selectedAsset: PropTypes.object,
  projectId: PropTypes.string,
  fullScreen: PropTypes.bool,
};

ProjectVideoPlayer.defaultProps = {
  selectedAsset: null,
  projectId: null,
  fullScreen: false,
};

import { InfoPanelBody } from 'pages/Projects/InfoPanel/InfoPanelBody';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

export const InfoPanel = () => {
  const history = useHistory();
  const { detail, loading, projUsers, projTeams } = useSelector((state) => state.teamProjects.panels);
  const navigateToProject = () => {
    history.push(`/profile/projects/${detail.id}`);
  };

  return (
    <InfoPanelBody
      loading={loading}
      detail={detail}
      projUsers={projUsers}
      projTeams={projTeams}
      navigateToPermissions={navigateToProject}
    />
  );
};

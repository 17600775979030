import { ChevronDownIcon, ChevronUpIcon } from 'components/Icons';
import styled from 'styled-components';

export const SidebarPanelSectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border: none;
  border-bottom: ${(props) => props.theme.palette.primary[700]} 1px solid;
  width: 100%;
  background-color: ${(props) => (props.nested ? '#383838' : props.theme.palette.primary[1100])};
  color: ${(props) => props.theme.palette.primary[200]};
  text-align: left;
  height: 45px;
  padding: 0 ${(props) => props.theme.spacing(1)};
  cursor: ${(props) => (props.accordion ? 'pointer' : 'default')};
  padding-right: ${(props) => props.theme.spacing(1.5)};
  font-weight: ${(props) => props.theme.typography.h2.fontWeight};
  font-size: ${(props) => (props.nested ? '11px' : 'inherit')};
  letter-spacing: 1.7px;
  text-transform: uppercase;
`;

export const SidebarPanelSectionButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  position: relative;
  width: 30px;
  height: 30px;
  margin: 6px;
  padding: 8px;
  background-color: ${(props) =>
    props.active ? props.theme.palette.secondary.base : props.theme.palette.primary.base};
  border-color: ${(props) => (props.active ? props.theme.palette.secondary.base : props.theme.palette.primary[700])};
  color: ${(props) => (props.active ? 'white' : props.theme.palette.primary[700])};
  &:hover {
    color: white;
    background-color: ${(props) => props.theme.palette.secondary.base};
  }
`;

const chevronStyles = {
  position: 'absolute',
  right: '12px',
  top: '50%',
  marginTop: '-6px',
};

export const SidebarPanelSectionChevDown = styled(ChevronDownIcon)(chevronStyles);

export const SidebarPanelSectionChevUp = styled(ChevronUpIcon)(chevronStyles);

export const SidebarPanelSectionContent = styled.div`
  background-color: #383838;
  padding: ${(props) => props.theme.spacing(props.padding)};
  border-bottom: ${(props) => props.theme.palette.primary[700]} 1px solid;
  text-align: ${(props) => props.alignment};
  color: ${(props) => props.theme.palette.primary[200]};

  & p {
    color: ${(props) => props.theme.palette.primary[500]};
  }
  &:last-of-type {
    border-bottom: none;
  }
`;

export const Chevron = styled.div`
  display: ${(props) => (props.accordion ? 'block' : 'none')};
`;

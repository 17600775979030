export const Switch = ({ value, children }) => {
  let defaultCase = null;
  const match = children.filter((child) => {
    if (child.props.isDefault) {
      defaultCase = child;
    }

    return child.props.name === value;
  });

  if (match.length > 0) {
    return match;
  }

  return defaultCase;
};

import PropTypes from 'prop-types';
import React from 'react';

import { Svg } from './Svg';

export const ChevronLeftIcon = ({ size }) => (
  <Svg viewBox="0 0 24 24" size={size}>
    <path d="M18 24V0L6 12l12 12z" fill="currentColor" />
  </Svg>
);

ChevronLeftIcon.propTypes = {
  size: PropTypes.number,
};

ChevronLeftIcon.defaultProps = {
  size: 24,
};

import { DialogMessage } from 'components/DialogMessage';
import { CameraIcon, DoubleCheckIcon, FlagIcon, LinkedIcon, SharingIcon } from 'components/Icons';
import { SidebarPanel } from 'components/SidebarPanel';
import PropTypes from 'prop-types';
import React from 'react';
import { ModalRevokeAccess } from 'smartComponents/ModalRevokeAccess/ModalRevokeAccess';
import { SidebarPanelButton, StyledButtonText } from './styles';

const AdminButton = ({ icon: IconComponent, children, onClick }) => {
  const hasIcon = !!IconComponent;

  return (
    <SidebarPanelButton onClick={onClick}>
      {!!hasIcon && <IconComponent size={16} />}
      <StyledButtonText variant="p" as="p" hasIcon={hasIcon}>
        {children}
      </StyledButtonText>
    </SidebarPanelButton>
  );
};

const collaborators = [
  { id: 1, image: 'http://placekitten.com/300/300', initials: 'ml', alt: 'RadixBay' },
  { id: 2, image: '', initials: 'cv', alt: 'RadixBay' },
  { id: 3, image: '', initials: 'ml', alt: 'RadixBay' },
  { id: 4, image: '', initials: 'zc', alt: 'RadixBay' },
  { id: 5, image: '', initials: 'mg', alt: 'RadixBay' },
  { id: 6, image: '', initials: 'ob', alt: 'RadixBay' },
];

export const AdminPanel = () => {
  const [openCopyAccess, setOpenCopyAccess] = React.useState(false);
  const [openShareAccess, setOpenShareAccess] = React.useState(false);
  const [openRevokeAcces, setOpenRevokeAcces] = React.useState(false);

  const onSubmitRevokeAcces = () => {
    setOpenRevokeAcces(false);
  };
  const onCancelRevokeAcces = () => {
    setOpenRevokeAcces(false);
  };

  const handleConfirmCopy = () => {
    // Action submitted to backend
    // Close the window
    setOpenCopyAccess(false);
  };

  const handleConfirmShare = () => {
    // Action submitted to backend

    // Close the window
    setOpenShareAccess(false);
  };
  return (
    <SidebarPanel title="Content">
      <AdminButton icon={LinkedIcon} onClick={() => setOpenRevokeAcces(true)}>
        Revoke Access
      </AdminButton>
      <AdminButton icon={DoubleCheckIcon} onClick={() => setOpenCopyAccess(true)}>
        Allow Copies - Currently turned on..
      </AdminButton>
      <AdminButton icon={SharingIcon} onClick={() => setOpenShareAccess(true)}>
        Allow Sharing - Currently turned on..
      </AdminButton>
      <AdminButton icon={CameraIcon}>Set Primary Thumbnail</AdminButton>
      <AdminButton icon={FlagIcon}>Add Alert - Notify users of changes or issues.</AdminButton>
      <DialogMessage
        title="Copy this content"
        isOpen={openCopyAccess}
        onConfirm={() => {
          handleConfirmCopy();
        }}
        onCancel={() => {
          setOpenCopyAccess(false);
        }}
      />
      <DialogMessage
        title="Share this content"
        isOpen={openShareAccess}
        onConfirm={() => {
          handleConfirmShare();
        }}
        onCancel={() => {
          setOpenShareAccess(false);
        }}
      />
      <ModalRevokeAccess
        isOpen={openRevokeAcces}
        collaborators={collaborators}
        onSubmit={onSubmitRevokeAcces}
        onCancel={onCancelRevokeAcces}
      />
    </SidebarPanel>
  );
};

AdminButton.propTypes = {
  icon: PropTypes.func,
  children: PropTypes.string,
  onClick: PropTypes.func,
};

AdminButton.defaultProps = {
  icon: null,
  children: '',
  onClick: () => {},
};

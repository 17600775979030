import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Form } from 'lib/form';
import { useFormik } from 'formik';
import { Button, FormControl, Grid, InputLabel, TextField } from '@mui/material';
import { LayoutAuth } from 'components/LayoutAuth/LayoutAuth';
import { Loader } from 'components/Loader/Loader';
import { Switch } from 'components/Switch/Switch';
import { SwitchCase } from 'components/SwitchCase/SwitchCase';
import AuthService from 'services/AuthService';

const validationSchema = Yup.object({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string()
    .matches(/^\S*$/, 'Email can not contain space')
    .email('Invalid email address')
    .required('Email is required'),
  password: Yup.string()
    .required('Please enter your password')
    .min(8, 'Password must be at least 8 characters long')
    .matches(/^(?=.*[a-z]).{8,}$/, 'Password must contain lowercase letters')
    .matches(/^(?=.*[A-Z]).{8,}$/, 'Password must contain uppercase letters')
    .matches(/^(?=.*\d).{8,}$/, 'Password must contain numbers')
    .matches(
      /^(?=.*[#$^+=!*()@%&]).{8,}$/,
      'Password must contain at least 1 special character: #, $, ^, +, =, !, *, (, ), @, %, &'
    ),
  passwordConfirm: Yup.string()
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export const AcceptInvitation = () => {
  const [panel, setPanel] = useState('default');
  const [loading, setLoading] = useState(false);
  const [inviteUser, setInviteUser] = useState({});
  const history = useHistory();

  const { userId, inviteToken } = useParams();

  const fetchInviteInfo = async () => {
    try {
      const response = await AuthService.validateInviteToken(userId, inviteToken);
      setInviteUser(response.data.data);
      setPanel('info');
    } catch (err) {
      setPanel('error');
    }
  };

  useEffect(() => {
    if (userId && inviteToken) {
      fetchInviteInfo();
    }
  }, [userId, inviteToken]);

  const submitInvitation = async ({ firstName, lastName, password, passwordConfirm }) => {
    try {
      setLoading(true);
      await AuthService.acceptInvitation(userId, {
        token: inviteToken,
        firstName,
        lastName,
        password,
        passwordConfirm,
      });
      setLoading(false);
      history.push('/login');
    } catch (err) {
      setLoading(false);

      const result = Form.parseApiValidationError(err);

      if (result) {
        // eslint-disable-next-line no-use-before-define
        formik.setErrors(result);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: inviteUser.firstName,
      lastName: inviteUser.lastName,
      email: inviteUser.email,
      password: '',
      passwordConfirm: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit(values) {
      submitInvitation(values);
    },
  });

  const { values, handleChange, handleBlur, handleSubmit } = formik;

  return (
    <Switch value={panel}>
      <SwitchCase name="default">
        <LayoutAuth title="Accept Invitation">
          <Loader loading height={50} />
        </LayoutAuth>
      </SwitchCase>
      <SwitchCase name="info">
        <LayoutAuth title="Accept Invitation">
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <InputLabel disabled>Email *</InputLabel>
                <FormControl fullWidth>
                  <TextField
                    disabled
                    placeholder="Enter e-mail address"
                    id="email"
                    name="email"
                    value={values.email}
                    {...Form.fieldErrorHelper(formik, 'email')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    helperText={formik.touched.email && formik.errors.email ? formik.errors.email : null}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <InputLabel disabled={loading}>First Name *</InputLabel>
                <FormControl fullWidth>
                  <TextField
                    placeholder="Enter first name"
                    disabled={loading}
                    id="firstName"
                    name="firstName"
                    value={values.firstName}
                    {...Form.fieldErrorHelper(formik, 'firstName')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    helperText={formik.touched.firstName && formik.errors.firstName ? formik.errors.firstName : null}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <InputLabel disabled={loading}>Last Name *</InputLabel>
                <FormControl fullWidth>
                  <TextField
                    placeholder="Enter last name"
                    disabled={loading}
                    id="lastName"
                    name="lastName"
                    value={values.lastName}
                    {...Form.fieldErrorHelper(formik, 'lastName')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    helperText={formik.touched.lastName && formik.errors.lastName ? formik.errors.lastName : null}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <InputLabel disabled={loading}>Password *</InputLabel>
                <FormControl fullWidth>
                  <TextField
                    placeholder="Enter password"
                    disabled={loading}
                    type="password"
                    id="password"
                    name="password"
                    value={values.password}
                    {...Form.fieldErrorHelper(formik, 'password')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    helperText={formik.touched.password && formik.errors.password ? formik.errors.password : null}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <InputLabel disabled={loading}>Confirm password *</InputLabel>
                <FormControl fullWidth>
                  <TextField
                    placeholder="Confirm your password"
                    disabled={loading}
                    type="password"
                    id="passwordConfirm"
                    name="passwordConfirm"
                    value={values.passwordConfirm}
                    {...Form.fieldErrorHelper(formik, 'passwordConfirm')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    helperText={
                      formik.touched.passwordConfirm && formik.errors.passwordConfirm
                        ? formik.errors.passwordConfirm
                        : null
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Button type="submit" color="secondary" variant="contained" disabled={loading} fullWidth>
              Confirm
            </Button>
          </form>
        </LayoutAuth>
      </SwitchCase>
      <SwitchCase name="error">
        <LayoutAuth title="Accept Invitation" subTitle="Invalid or expired invitation" />
      </SwitchCase>
      <SwitchCase name="complete">
        <LayoutAuth title="Accept Invitation" subTitle="You have successfuly accepted the inviation." />
      </SwitchCase>
    </Switch>
  );
};

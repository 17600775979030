import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  content: {
    height: 'calc(100vh - 122px)',
  },
  firstSection: {
    height: '100%',
    backgroundColor: theme.palette.primary[1400],
    padding: `${0}!important`,
    overflowY: 'auto',
  },
  secondSection: {
    backgroundColor: theme.palette.primary[1200],
    height: '100%',
    overflowY: 'auto',
  },
  sectionContent: (props) => ({
    padding: theme.spacing(1),
    ...(props.flex && { display: 'flex', flexDirection: 'column', height: '100%' }),
  }),
  headerSearch: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary[1300],
  },
  columnHeader: { display: 'flex', alignItems: 'end' },
  formContent: {
    flex: 1,
  },
});

/* eslint-disable no-case-declarations */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Form } from 'lib/form';
import { useFormik } from 'formik/dist';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import tokml from '@maphubs/tokml';
import GeoJSON from 'geojson';
import exportFromJSON from 'export-from-json';
import moment from 'moment';
import { pdf } from '@react-pdf/renderer';
import { ModalHeaderIcon } from 'components/ModalHeaderIcon/ModalHeaderIcon';
import { Time } from 'lib/time';
import { InfoIcon, SquareArrow } from 'components/Icons';
import InspectionService from 'services/InspectionService';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import { DialogMessage } from 'components/DialogMessage';
import { PDFDocument } from 'components/Observation/ObservationExport/PDFDocument';
import { DisclaimerExporting } from 'components/DisclaimerExporting/DisclaimerExporting';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';

const validationSchema = Yup.object({
  name: Yup.string().min(1).required('Name is required'),
  type: Yup.string().min(1).required('Type is required'),
});

const options = [
  { value: 'csv', label: 'CSV' },
  { value: 'kml', label: 'KML' },
  { value: 'pdf', label: 'PDF' },
  { value: 'json', label: 'JSON' },
  { value: 'xml', label: 'XML' },
];

export const ExportFindings = ({ projAssetInspection, handleClose, inspection }) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState('');
  const [findings, setFindings] = useState('');
  const [inspectionObj, setInspectionObj] = useState('');

  const PDFContent = (fileName) => <PDFDocument fileName={fileName} observations={findings} />;

  const findingFormat = (values) =>
    [...findings].map((item) => {
      const sequenceId = `&sequenceId=${
        item.projectAssetSequence?.asset?.type !== 'VIDEO' ? item.pasqId : +item.time - 1
      }`;
      const url = `https://${window.location.hostname}/projects?projectId=${item.projectId || item.projectId}&pastId=${
        item.projectAssetId
      }&findingId=${item.id}${sequenceId}`;
      let object = {};
      item.responses.forEach((element) => {
        if (!element.deletedAt) {
          object = { ...object, [element.name]: element.response ? element.response.toString() : '' };
        }
      });
      return {
        [values.type === 'kml' ? 'name' : 'Name']: item.name,
        ...(values.type !== 'kml' && { Description: item.description }),
        ...(values.type === 'kml' && {
          Description: item.description,
          'Launch in CartoVid': `<![CDATA[<a href="${url}">Click to Open Finding</a>]]>`,
          Image: `<![CDATA[<img src="${item.signedUrl}">]]>`,
        }),
        ...object,
        'Date Created': moment(item.createdAt).format('llll'),
        'Date Updated': moment(item.updatedAt).format('llll'),
        'Timeline Marker': Time.humanReadable({ seconds: +item.time + 1 }),
        Latitude: item.lat.toFixed(5),
        Longitude: item.lng.toFixed(5),
        ...(values.type !== 'kml' && {
          'Launch in CartoVid': url,
          Img: item.signedUrl,
        }),
      };
    });

  const createZip = async (file, findingsAux) => {
    const zip = new JSZip();

    zip.file(file.name, file.data);
    const imageFolder = zip.folder('images');
    const promises = findingsAux.map((finding) => {
      const filename = `${finding.id}.png`;
      return new Promise((resolve, reject) => {
        JSZipUtils.getBinaryContent(finding.signedUrl, (err, data) => {
          if (err) {
            reject(err);
          } else {
            imageFolder.file(filename, data, { binary: true });
            resolve();
          }
        });
      });
    });

    // Wait for all promises to resolve
    return Promise.all(promises).then(() => zip.generateAsync({ type: 'blob' }));
  };

  const saveFile = async (data, filename, imageFindings) =>
    createZip(
      {
        name: filename,
        data,
      },
      imageFindings
    )
      .then((content) => {
        setIsLoading(false);
        saveAs(content, `${filename}.zip`);
        setTimeout(() => {
          handleClose();
        }, 2000);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });

  const exportFunction = async (values) => {
    const fileName = values.name;
    setIsLoading(true);
    switch (values.type) {
      case 'pdf':
        pdf(PDFContent(fileName))
          .toBlob()
          .then((res) => saveFile(res, `${fileName}.pdf`, findings));
        break;
      case 'csv':
        const auxFindings = findingFormat(values);
        auxFindings.unshift({ 'Date Exported': moment().format('llll') });
        exportFromJSON({
          data: auxFindings,
          fileName,
          exportType: values.type,
          processor: async (content, type, filename) => saveFile(content, filename, findings),
        });
        break;
      case 'kml':
        const geojson = GeoJSON.parse(findingFormat(values), {
          Point: ['Latitude', 'Longitude'],
        });
        const labels = {};
        inspectionObj.questions.forEach((element) => {
          labels[element.name] = element.name;
        });
        const featuresWithHtml = geojson.features.map((feature) => ({
          ...feature,
          properties: {
            ...feature.properties,
          },
        }));
        let kml = tokml(
          { ...geojson, features: featuresWithHtml },
          {
            documentName: fileName,
            documentDescription: `Findings - Date Exported: ${moment().format('llll')}`,
            name: 'name',
          }
        );
        kml = kml.replaceAll('&quot;', '"');
        kml = kml.replaceAll('&lt;', '<');
        kml = kml.replaceAll('&lt;', '<');
        kml = kml.replaceAll('&gt;', '>');
        const file = new Blob([kml], { type: 'kml' });
        saveFile(file, `${fileName}.kml`, findings);
        break;

      case 'json':
        const auxFindingsJson = findingFormat(values, findings);
        auxFindingsJson.unshift({ 'Date Exported': moment().format('llll') });
        exportFromJSON({
          data: auxFindingsJson,
          fileName,
          exportType: values.type,
          processor: (content, type, filename) => saveFile(content, filename, findings),
        });
        break;
      case 'xml':
        const auxFindingsXml = findingFormat(values, findings);
        auxFindingsXml.unshift({ 'Date Exported': moment().format('llll') });
        exportFromJSON({
          data: auxFindingsXml,
          fileName,
          exportType: values.type,
          processor: async (content, type, filename) => saveFile(content, filename, findings),
        });
        break;
      default:
        break;
    }
    handleClose();
  };

  const mapFindings = (auxInspection, result) => {
    let auxResult = { ...result };
    const inspectionResult = { ...auxInspection };
    inspectionResult.questions = JSON.parse(inspectionResult.questions);
    auxResult = auxResult?.data?.data?.map((item) => ({
      ...item,
      responses: JSON.parse(item.responses),
    }));
    auxResult = auxResult.map((finding) => {
      const auxFinding = { ...finding };
      auxFinding.responses = finding.responses.map((response) => {
        const object = { ...response };
        inspectionResult.questions.forEach((question) => {
          if (question.id === response.id) {
            object.name = question.name;
            object.description = question.description;
            object.deletedAt = question.deletedAt;
          }
        });
        return object;
      });
      const notExist = inspectionResult.questions
        .filter((item1) => !auxFinding.responses.some((item2) => item2.id === item1.id))
        .filter((item) => !item.deletedAt);
      notExist.forEach((element) => {
        auxFinding.responses.push({
          id: element.id,
          name: element.name,
          description: element.description,
          value: '',
        });
      });
      auxFinding.projectAssetId = finding.projectAssetInspection?.projectAsset?.id;
      auxFinding.projectId = finding.projectAssetInspection?.projectAsset?.projId;
      return auxFinding;
    });
    setFindings(auxResult);
    setInspectionObj(inspectionResult);
    setOpen(true);
  };

  const exportFindings = async () => {
    setOpen(false);
    setIsLoading(true);

    if (projAssetInspection) {
      InspectionService.exportFindings(projAssetInspection.id)
        .then((result) => {
          setIsLoading(false);
          if (!result.data?.data?.length) {
            setOpenDialog('There are no findings at this current inspection.');
          } else {
            const auxInspection = { ...projAssetInspection.inspection };
            mapFindings(auxInspection, result);
          }
        })
        .catch(() => {
          setIsLoading(false);
          setOpenDialog('An error occurred while trying to export the findings.');
        });
    } else if (inspection) {
      InspectionService.getInspectionFindings(inspection.id)
        .then((result) => {
          setIsLoading(false);
          if (!result.data?.data?.length) {
            setOpenDialog('There are no findings at this current inspection.');
          } else {
            mapFindings(inspection, result);
          }
        })
        .catch(() => {
          setIsLoading(false);
          setOpenDialog('An error occurred while trying to export the findings.');
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      type: '',
    },
    validationSchema,
    onSubmit(values) {
      exportFunction(values);
    },
  });

  const { values, handleChange, handleBlur, handleSubmit } = formik;

  useEffect(() => {
    exportFindings();
  }, []);

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle>
          <ModalHeaderIcon icon={SquareArrow} text="Export to File" />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <InputLabel>File name *</InputLabel>
              <TextField
                variant="outlined"
                value={values.name}
                id="name"
                name="name"
                {...Form.fieldErrorHelper(formik, 'name')}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(formik.touched.name && formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name ? formik.errors.name : null}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Select File type *</InputLabel>
              <FormControl error={!!(formik.touched.type && formik.errors.type)} fullWidth>
                <Select
                  name="type"
                  onChange={(e) => formik.handleChange('type')(e.target.value)}
                  required
                  size="small"
                  fullWidth
                >
                  {options.map((item) => (
                    <MenuItem value={item.value} key={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{formik.touched.type && formik.errors.type ? formik.errors.type : null}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button color="primary" variant="contained" onClick={handleClose} size="small" fullWidth>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                color="secondary"
                disabled={isLoading}
                variant="contained"
                onClick={handleSubmit}
                size="small"
                fullWidth
              >
                Export
              </Button>
            </Grid>
            <Grid item xs={12}>
              <DisclaimerExporting />
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <DialogMessage
        title={openDialog}
        isOpen={!!openDialog}
        icon={InfoIcon}
        confirmText="Ok"
        onConfirm={() => {
          if (!open) {
            handleClose();
          }
          setOpenDialog(false);
        }}
      />
      <LoadingOverlay loading={isLoading} />
    </>
  );
};

ExportFindings.propTypes = {
  projAssetInspection: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  inspection: PropTypes.object,
};

ExportFindings.defaultProps = {
  projAssetInspection: null,
  inspection: null,
};

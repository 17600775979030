import { webMercatorToGeographic } from '@arcgis/core/geometry/support/webMercatorUtils';
import ArcGISMap from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

export const DialogMapExtentFilter = ({ open, bounds, zoom, onCancel, onConfirm }) => {
  /**
   * @type {[{
   *  lat: number,
   *  long: number,
   * }, func]}
   */
  const [coords, setCoords] = useState(bounds || []);
  const mapDiv = useRef();

  // default center of US
  const center = {
    lat: 38.880343,
    long: -98.319621,
  };

  useEffect(() => {
    setCoords(bounds);
  }, [bounds]);

  const handleConfirm = () => {
    onConfirm(coords);
  };

  const handleCancel = () => {
    onCancel();
  };

  // on page load
  useEffect(() => {
    if (!mapDiv.current) return;

    // calc max height for map
    const windowHeight = window.innerHeight;
    const dialog = document.querySelector('[data-testid="DialogContent"]');
    const dialogStyle = dialog.currentStyle || window.getComputedStyle(dialog);
    const dialogMargin = parseInt(dialogStyle.marginTop, 10) + parseInt(dialogStyle.marginBottom, 10);

    const mapHeight = windowHeight - dialog.offsetHeight - dialogMargin;
    mapDiv.current.style.cssText = `
      height: ${mapHeight}px;
      width:100%;
      margin:0;
      padding:0;
    `;

    // let sketchViewModel;

    const map = new ArcGISMap({
      basemap: 'hybrid',
    });

    const view = new MapView({
      map,
      container: mapDiv.current,
      zoom,
      center: [center.long, center.lat],
    });

    const convertExtentToCoords = (extent) => {
      const { xmin, ymin, xmax, ymax } = webMercatorToGeographic(extent);
      setCoords([
        {
          lat: ymax,
          long: xmin,
        },
        {
          lat: ymax,
          long: xmax,
        },
        {
          lat: ymin,
          long: xmax,
        },
        {
          lat: ymin,
          long: xmin,
        },
      ]);
    };

    view.on('drag', (event) => {
      if (event.action === 'end') {
        convertExtentToCoords(view.extent);
      }
    });

    view.on('mouse-wheel', (event) => {
      if (event.action === 'end') {
        convertExtentToCoords(view.extent);
      }
    });
  }, [open]);

  return (
    <Dialog open={open} maxWidth="md" onClose={handleCancel}>
      <DialogTitle>Map Extent Filter</DialogTitle>
      <DialogContent>
        <div className="mapDiv" id="mapContainer" ref={mapDiv} />
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button color="primary" variant="contained" onClick={handleCancel} size="large" fullWidth>
              Ok
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button color="secondary" variant="contained" onClick={handleConfirm} size="large" fullWidth>
              Ok
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

DialogMapExtentFilter.propTypes = {
  /**
   * Open or close the dialog
   */
  open: PropTypes.bool.isRequired,

  /**
   * fired when the user closes or cancels the dialog
   */
  onCancel: PropTypes.func.isRequired,

  /**
   * Fired when the user confirms the dialog
   *
   * (coords: Array<{ lat: number, long: number }>) => void
   */
  onConfirm: PropTypes.func.isRequired,

  /**
   * Default map zoom
   */
  zoom: PropTypes.number,

  /**
   * Existing coordinates representing map bounds
   */
  bounds: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number.isRequired,
      long: PropTypes.number.isRequired,
    })
  ),
};

DialogMapExtentFilter.defaultProps = {
  bounds: [],
  zoom: 4,
};

import React from 'react';
import PropTypes from 'prop-types';
import { LayoutArea } from 'components/LayoutArea';
import { useStyles } from 'components/LayoutPageContent';
import { SecondaryHeader } from 'components/SecondaryHeader';
import { SecondaryNav } from 'components/SecondaryNav';
import { SecondaryNavItem } from 'components/SecondaryNavItem';
import { Sidebar } from 'components/Sidebar';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { LayersIcon, UserIcon } from 'components/Icons';
import { ActivityIcon } from 'components/Icons/ActivityIcon';
import { SideBarDetail } from 'components/SideBarDetail/SideBarDetail';

export const TeamsSettingsContainer = ({ title, showSidebar, sidebarConfig, children, actions }) => {
  const classes = useStyles();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');
  const params = useParams();

  const style = { marginLeft: 10 };
  const iconSize = 20;

  return (
    <div className={classes.content}>
      <SecondaryNav>
        <SecondaryNavItem
          to={`/teams/${params.teamId}`}
          active={splitLocation[1] === 'teams' && splitLocation.length < 4}
        >
          <ActivityIcon size={iconSize} />
          <span style={style}>Overview</span>
        </SecondaryNavItem>
        <SecondaryNavItem to={`/teams/${params.teamId}/members`} active={splitLocation[3] === 'members'}>
          <UserIcon size={iconSize} />
          <span style={style}>Members</span>
        </SecondaryNavItem>
        <SecondaryNavItem to={`/teams/${params.teamId}/projects`} active={splitLocation[3] === 'projects'}>
          <LayersIcon size={iconSize} />
          <span style={style}>Projects</span>
        </SecondaryNavItem>
      </SecondaryNav>
      {showSidebar && <Sidebar config={sidebarConfig} />}
      <SideBarDetail />
      <LayoutArea area="content" allowScroll>
        <SecondaryHeader title={title} actions={actions} />
        {children}
      </LayoutArea>
    </div>
  );
};

TeamsSettingsContainer.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  showSidebar: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  sidebarConfig: PropTypes.object,
  actions: PropTypes.instanceOf(Array),
};

TeamsSettingsContainer.defaultProps = {
  showSidebar: false,
  sidebarConfig: {},
  actions: [],
};

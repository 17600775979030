import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'components/Avatar/Avatar';
import { ContextMenu } from 'components/ContextMenu/ContextMenu';
import { ContextMenuItem } from 'components/ContextMenuItem/ContextMenuItem';
import { ContextMenuList } from 'components/ContextMenuItems/ContextMenuList';
import { resolveUserInitials } from 'lib/resolve-user-initials';
import EnumService from 'services/EnumService';
import OrganizationService from 'services/OrganizationService';
import TeamService from 'services/TeamService';
import UserService from 'services/UserService';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUserXmark } from '@fortawesome/free-solid-svg-icons';
import { ContextMenuButton } from 'components/ContextMenuButton';
import { ModalHeaderIcon } from 'components/ModalHeaderIcon/ModalHeaderIcon';
import { UserPlusIcon } from 'components/Icons/UserPlusIcon';
import { useStyles } from './styles';

let debounceTimeout = null;
export const ModalInviteTeam = ({ onCancel, onConfirm, isOpen, teamId, orgId }) => {
  const classes = useStyles();
  const [teamRoles, setTeamRoles] = React.useState([]);
  const [teamAdmins, setTeamAdmins] = React.useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [submitError, setSubmitError] = React.useState('');

  React.useEffect(() => {
    // Get main Organizations Roles
    const getRoles = async () => {
      const response = await EnumService.getTeamRoles();
      response.data.data.map((role) => {
        role.displayName = role.name;
        role.roleId = role.id;
        delete role.name;
        delete role.id;
        return role;
      });
      setTeamRoles(response.data.data);
    };
    getRoles();

    // Get Team Users
    const getUsers = async () => {
      const responseTeamUsers = await UserService.getUsers({ teamId, sort_by: '+firstName' });
      setTeamAdmins(responseTeamUsers.data.data);
    };
    getUsers();
  }, []);

  // Declare this function to handle the search results =>  all users endpoint
  const FilterOrg = async () => {
    const newResult = await OrganizationService.getOrganizationUsers(orgId, {
      search: searchTerm,
      page_size: 6,
    });

    const adminLookup = teamAdmins.reduce((acc, admin) => {
      acc[admin.id] = true;
      return acc;
    }, {});

    const result = newResult.data.data.filter((item1) => !adminLookup[item1.id]);
    setSearchResults(result);
  };

  const handleChange = (e) => {
    const newSearchTerm = e.target.value || '';
    setSearchTerm(newSearchTerm);
    clearTimeout(debounceTimeout);
    if (newSearchTerm === '') {
      setSearchResults([]);
      return;
    }
    debounceTimeout = setTimeout(() => {
      FilterOrg();
    }, 150);
  };

  // Declare this function to remove an admin
  const removeNewAdmin = (newUser) => {
    setTeamAdmins((oldOrgAdminRoles) =>
      oldOrgAdminRoles.map((adm) => {
        const newObj = {
          ...adm,
        };

        if (newObj.id === newUser.id) {
          newObj.roles = [];
        }

        return newObj;
      })
    );
  };

  // Declare this function to add an admin
  const addOrgAdmin = async (newUser) => {
    newUser.roles = [
      {
        code: 'team:part',
        displayName: 'Team Participant',
        grantId: '',
        grantObjectId: '',
        ngrantObjectType: 'TEAM',
        roleId: 17,
        userId: newUser.id,
      },
    ];

    const newAdminsList = [...teamAdmins, newUser];
    const sortedList = newAdminsList.sort((a, b) => {
      const result = a.firstName.localeCompare(b.firstName);
      return result !== 0 ? result : a.lastName.localeCompare(b.lastName);
    });

    setTeamAdmins(sortedList);
    // Reset Search Result
    setSearchTerm('');
    setSearchResults([]);
  };

  // Declare this function to add user's roles
  const addUserRoles = (userId, role) => {
    const selectedRole = role;
    selectedRole.code = role.code;
    selectedRole.grantId = '';
    selectedRole.grantObjectId = '';
    selectedRole.grantObjectType = 'TEAM';
    selectedRole.userId = userId;
    delete selectedRole.description;

    const userExist = teamAdmins.some((admin) => admin.id === userId);
    if (userExist) {
      setTeamAdmins((oldOrgAdminRoles) =>
        oldOrgAdminRoles.map((adm) => {
          const newObj = {
            ...adm,
          };

          if (newObj.id === userId) {
            newObj.roles = [selectedRole];
          }

          return newObj;
        })
      );
    }
  };

  // Manage Submit Manage Invite Admins Modal
  const onSubmitInvitation = () => {
    const updateRoles = async () => {
      try {
        await Promise.all;
        teamAdmins.map((user) =>
          TeamService.updateUserRoles(
            teamId,
            user.id,
            user.roles.map(({ roleId }) => roleId)
          )
        );
      } catch (err) {
        const result = err.response.data.message;
        setSubmitError(result);
      }

      // update state with new roles

      onConfirm();
    };

    updateRoles();
  };

  return (
    <Dialog open={isOpen} maxWidth="sm" onClose={onCancel}>
      <DialogTitle>
        <ModalHeaderIcon icon={UserPlusIcon} text="Manage Users" />
      </DialogTitle>
      <DialogContent>
        <div className={classes.invitationCard}>
          <TextField
            variant="outlined"
            placeholder="Search"
            type="text"
            InputProps={{
              startAdornment: <FontAwesomeIcon icon={faPlus} size="2x" style={{ marginRight: '1rem' }} />,
            }}
            onChange={handleChange}
            prepend
            fullWidth
          />

          <div className={classes.searchResults}>
            {searchResults.map((user) => (
              <Button
                className={classes.invitationItem}
                key={user.id}
                onClick={() => {
                  addOrgAdmin(user);
                }}
              >
                {user.firstName} {user.lastName} ({user.email})
              </Button>
            ))}
          </div>
        </div>

        <div className={classes.itemContainer}>
          {/* Loop throught the Organization Users */}
          {teamAdmins !== undefined &&
            teamAdmins.map(
              (admin) =>
                admin.roles.length !== 0 && (
                  <div className={classes.itemPanel} key={admin.id}>
                    <div className={classes.itemSection}>
                      <IconButton color="error" onClick={() => removeNewAdmin(admin)}>
                        <FontAwesomeIcon icon={faUserXmark} size="2x" />
                      </IconButton>
                      <Avatar
                        image={admin.photo}
                        initials={resolveUserInitials({
                          firstName: admin.firstName,
                          lastName: admin.lastName,
                          email: admin.email,
                        })}
                        size={36}
                      />
                      <Typography variant="body2">
                        {admin.firstName} {admin.lastName}
                      </Typography>
                      <div className={classes.itemTagSection}>
                        {admin.roles.map((roleTag) => (
                          <div className={classes.itemTag} key={roleTag.code}>
                            <Typography variant="subtitle1">{roleTag.displayName}</Typography>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className={classes.itemRoles}>
                      <ContextMenu>
                        <ContextMenuButton size="small">Choose Role(s)</ContextMenuButton>
                        <ContextMenuList position="bottom-right">
                          {teamRoles.map((role) => (
                            <ContextMenuItem
                              key={role.roleId}
                              onClick={() => addUserRoles(admin.id, role)}
                              // disabled={admin.roles.some((ro) => ro.roleId === role.roleId)}
                            >
                              {role.displayName}
                            </ContextMenuItem>
                          ))}
                        </ContextMenuList>
                      </ContextMenu>
                    </div>
                  </div>
                )
            )}
        </div>

        {submitError && (
          <Typography color="error" mt={1}>
            {submitError}.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button color="primary" variant="contained" onClick={onCancel} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button color="secondary" variant="contained" onClick={() => onSubmitInvitation()} fullWidth>
              Confirm
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

ModalInviteTeam.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  isOpen: PropTypes.bool,
  teamId: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
};

ModalInviteTeam.defaultProps = {
  isOpen: false,
  onConfirm: null,
  onCancel: null,
};

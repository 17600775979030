import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const AlertTriangleIcon = ({ size }) => (
  <Svg viewBox="0 0 24 24" size={size}>
    <path
      id="Icon"
      d="M12.4998 9.00023V13.0002M12.4998 17.0002H12.5102M11.0574 3.89195L2.48982 18.0986C2.01461 18.8866 1.777 19.2806 1.81212 19.6039C1.84275 19.886 1.99667 20.1423 2.23559 20.309C2.5095 20.5002 2.98373 20.5002 3.9322 20.5002H21.0674C22.0158 20.5002 22.4901 20.5002 22.764 20.309C23.0029 20.1423 23.1568 19.886 23.1875 19.6039C23.2226 19.2806 22.985 18.8866 22.5097 18.0986L13.9422 3.89195C13.4687 3.10679 13.2319 2.71421 12.923 2.58235C12.6536 2.46734 12.346 2.46734 12.0766 2.58235C11.7677 2.71421 11.5309 3.10679 11.0574 3.89195Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </Svg>
);

AlertTriangleIcon.propTypes = {
  size: PropTypes.number,
};

AlertTriangleIcon.defaultProps = {
  size: 24,
};

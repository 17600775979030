import ApiService from './ApiService';

export default class TeamService {
  static getTeams = (filters) => ApiService.get('/teams', filters);

  static getTeam = async (teamId) => ApiService.get(`/teams/${teamId}`);

  static getUserRole = async (teamId, memberId) => ApiService.get(`/teams/${teamId}/users/${memberId}/roles`);

  static createTeam = async (team) => ApiService.post(`/teams`, team);

  static addTeamMember = async (teamId, userId, roles) => ApiService.post(`/teams/${teamId}/users`, { userId, roles });

  static getTeamAccounts = (userId) => ApiService.get('/teams/all/accounts', { userId });

  static getTeamOrganizations = (userId) => ApiService.get('/teams/all/organizations', { userId });

  static getTeamAccountsOrganizations = (filters) => ApiService.get('/teams/all/accountsOrganizations', filters);

  /**
   * Update the team roles for a user
   *
   * @param {string} teamId
   * @param {string} memberId
   * @param {number[]} roles
   * @returns
   */
  static updateUserRoles = async (teamId, memberId, roles) =>
    ApiService.post(`/teams/${teamId}/users/${memberId}/roles`, { roles });

  /**
   * Update the team name and description
   *
   * @param {string} teamId
   * @param {object} data
   * @returns
   */
  static updateTeam = async (teamId, data) => ApiService.patch(`/teams/${teamId}`, data);
}

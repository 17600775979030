import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { GeneralTable } from 'components/GeneralTable/GeneralTable';
import { TablePagination } from 'components/TablePagination/TablePagination';
import { usePagination, useSortBy, useTable } from 'react-table';
import { useDispatch, useSelector } from 'react-redux';
import { fetchContent } from 'slices/search/contentReducer';
import { SecondaryHeader } from 'components/SecondaryHeader';
import { Table } from 'components/Table';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import Img from 'react-cool-img';

const formatDate = (dateStr) => {
  const d = new Date(dateStr);
  return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
};

const tableColumns = () => [
  {
    Header: 'Title',
    accessor: (row) => row.name,
  },
  {
    Header: 'Account',
    accessor: (row) => row.account?.name,
  },
  {
    Header: 'Organization',
    accessor: (rowData) => rowData.org?.name,
  },
  {
    Header: 'Primary Project',
    accessor: (row) => row.project.name,
  },
  {
    Header: 'Added',
    accessor: (row) => formatDate(row.createdAt),
  },
];

const ResultsPanel = ({ onSelect }) => {
  const columns = React.useMemo(() => tableColumns(), []);
  const { data, loading, totalCount, filters } = useSelector((state) => state.search.content);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const dispatch = useDispatch();

  const table = useTable(
    {
      columns,
      data: data || [],
      initialState: {
        pageIndex: filters.page - 1,
        pageSize: filters.page_size,
      },
      pageCount: Math.ceil(totalCount / filters.page_size),
      totalCount,
      pageLength: data.length,
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  const {
    state: { pageIndex, pageSize },
  } = table;

  useEffect(() => {
    if (pageIndex !== filters.page - 1) {
      setTimeout(() => {
        dispatch(fetchContent({ filters: { ...filters, page: pageIndex + 1, page_size: filters.page_size } }));
      }, 300);
    }
  }, [pageIndex, pageSize]);

  const checkComponent = ({ row }) => (
    <Checkbox
      color="secondary"
      checked={selectedRows.map((sRow) => sRow.id).includes(row.original.id)}
      onChange={() => {
        if (selectedRows.map((sRow) => sRow.id).includes(row.original.id)) {
          setSelectedRows(selectedRows.filter((sRow) => sRow.id !== row.original.id));
        } else {
          setSelectedRows([...selectedRows, row.original]);
        }
      }}
    />
  );

  const handleRowClick = (row) => {
    onSelect(row);
    setSelectedRow(row.id);
  };

  return (
    <div
      style={{
        width: '55%',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        maxHeight: 'calc(100vh - 126px)',
        borderLeft: '1px solid orange',
      }}
    >
      {loading && <LoadingOverlay loading={loading} />}
      {!loading && data.length === 0 && (
        <div
          style={{
            height: '70%',
            textAlign: 'center',
            padding: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Img src="emptyState.svg" alt="Empty" />
        </div>
      )}

      {data.length > 0 && (
        <>
          <GeneralTable
            style={{ height: '70%' }}
            table={table}
            handleRowClick={(row) => handleRowClick(row.original)}
            rowComponent={checkComponent}
            actionHeaderText="Add to Cue"
            selectRow={false}
            selectedRow={selectedRow}
          />
          <TablePagination table={table} />
        </>
      )}
      <div style={{ height: '30%', display: 'flex', flexDirection: 'column', borderTop: '1px solid orange' }}>
        <SecondaryHeader title="Your Cue">
          <Button
            style={{ marginLeft: 'auto' }}
            type="button"
            size="medium"
            color="secondary"
            variant="contained"
            title="Clear Cue"
            onClick={() => setSelectedRows([])}
          >
            Clear Cue
          </Button>
        </SecondaryHeader>
        <div style={{ overflowY: 'auto', flex: 1 }}>
          {selectedRows.length === 0 ? (
            <div
              style={{
                height: '100%',
                textAlign: 'center',
                padding: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography variant="h3">Your Cue is Currently Empty</Typography>
            </div>
          ) : (
            <Table>
              <TableBody>
                {selectedRows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      <Checkbox
                        color="secondary"
                        checked
                        onChange={() => setSelectedRows(selectedRows.filter((sRow) => sRow.id !== row.id))}
                      />
                    </TableCell>
                    <TableCell onClick={() => handleRowClick(row)}>{row.name}</TableCell>
                    <TableCell onClick={() => handleRowClick(row)}>
                      {row.user ? `${row.user.firstName} ${row.user.lastName}` : ''}
                    </TableCell>
                    <TableCell onClick={() => handleRowClick(row)}>{row.org?.name}</TableCell>
                    <TableCell onClick={() => handleRowClick(row)}>{row.project.name}</TableCell>
                    <TableCell onClick={() => handleRowClick(row)}>{formatDate(row.createdAt)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </div>
      </div>
    </div>
  );
};

ResultsPanel.propTypes = {
  onSelect: PropTypes.func,
};

ResultsPanel.defaultProps = {
  onSelect: () => {},
};

export default ResultsPanel;

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  assetType: {
    background: theme.palette.primary.dark,
    padding: '13px',
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: 4,
  },
}));

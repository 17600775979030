import React, { useEffect, useState } from 'react';
import { AccountSettingsContainer } from 'components/AccountSettingsContainer/AccountSettingsContainer';
import { AdminHeaderTitle } from 'components/AdminHeaderTitle/AdminHeaderTitle';
import { TopBar } from 'components/TopBar/TopBar';
import { Card } from 'components/Card/Card';
import { ArrowDownIcon } from 'components/Icons/ArrowDownIcon';
import BillingService from 'services/BillingService';
import { useSelector } from 'react-redux';
import { TablePagination } from 'components/TablePagination/TablePagination';
import { usePagination, useSortBy, useTable } from 'react-table';
import { BillingActions } from 'components/BillingActions/BillingActions';
import { GeneralTable } from 'components/GeneralTable/GeneralTable';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as FileSaver from 'file-saver';
// eslint-disable-next-line import/no-extraneous-dependencies
import XLSX from 'sheetjs-style';

const tableColumns = () => [
  {
    Header: 'Receipt',
    accessor: (rowData) => rowData.id,
  },
  {
    Header: 'Purchase Date',
    accessor: (rowData) => {
      const { created } = rowData;
      return new Date(created * 1000).toLocaleDateString();
    },
  },
  {
    Header: 'Purchased By',
    accessor: (rowData) => {
      // eslint-disable-next-line camelcase
      const { customer_details } = rowData;
      // eslint-disable-next-line camelcase
      return `${customer_details.name}` || '';
    },
  },
  {
    Header: 'Purchase Email',
    accessor: (rowData) => {
      // eslint-disable-next-line camelcase
      const { customer_details } = rowData;
      // eslint-disable-next-line camelcase
      return `${customer_details.email}` || '';
    },
  },
  {
    Header: 'Amount',
    accessor: (rowData) => {
      // eslint-disable-next-line camelcase
      const { amount_total, currency } = rowData;
      // eslint-disable-next-line camelcase
      return `${amount_total} ${String(currency).toUpperCase()}` ?? ``;
    },
  },
  {
    Header: 'Credits',
    accessor: (rowData) => {
      const { credits } = rowData;
      return credits;
    },
  },
];

export const PaymentHistory = () => {
  const columns = React.useMemo(() => tableColumns(), []);
  const [data, setData] = useState([]);
  const { email } = useSelector((state) => state.auth);
  const [row, setRow] = useState({});

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToExcel = async (dataToExport, fileName) => {
    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const tmpData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(tmpData, `${fileName}${fileExtension}`);
  };

  const table = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 1, // filters.page - 1,
        pageSize: data.length, // filters.page_size,
      },
      pageCount: 1, // Math.ceil(totalCount / filters.page_size),
      totalCount: data.length,
      pageLength: data.length,
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  const getInvoiceLink = async (invoiceId) => {
    const result = await BillingService.getInvoiceDetails(invoiceId);
    return result?.data?.invoice_pdf ?? ``;
  };

  const getPaymentsHistory = async () => {
    const results = await BillingService.getTransactions(email);

    const promises = results.data.data.map(async (item) => {
      const link = item.invoice ? await getInvoiceLink(item.invoice) : '';
      item.invoiceLink = link;
      item.credits = item?.success_url?.split('&')[1]?.split('=')[1];

      return item;
    });

    await Promise.all(promises);
    setData(results.data.data);
  };

  useEffect(() => {
    getPaymentsHistory();
  }, []);

  return (
    <AccountSettingsContainer
      title={<AdminHeaderTitle buttonName="ADMINISTRATION" section="BILLING" />}
      actions={[
        {
          icon: ArrowDownIcon,
          text: 'Download All',
          onClick: () => exportToExcel(data, `Payment_History_${new Date().getMilliseconds()}`), // I need to export to XLSX all the records of invoices
        },
      ]}
      showSidebar
    >
      <Card noPadding>
        <TopBar options="" searchValue="" handleSearch={() => {}} activatedFilter={false} sideBarDetail />
        <>
          <GeneralTable
            table={table}
            selectedRow={row ?? ''}
            handleRowClick={(selectedRow) => setRow(selectedRow)}
            rowComponent={BillingActions}
            // actionFunction={() => {}}
            handleSelectedIds={(ids) => console.info(ids)}
            external={false}
          />
          <TablePagination table={table} />
        </>
      </Card>
    </AccountSettingsContainer>
  );
};

import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';
import { LaunchIcon } from 'components/Icons/LaunchIcon';

export const useStyles = makeStyles({
  content: {
    padding: '4rem',
    height: '100%',
    '& svg': {
      height: '100%',
      width: 'auto',
    },
    '& h4': {
      fontWeight: 400,
    },
  },
  fullHeight: {
    height: '100%',
  },
});

export const ComingSoon = () => {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <Grid container spacing={1} className={classes.fullHeight}>
        <Grid item lg={5} md={5} sm={12} xs={12}>
          <Typography style={{ fontSize: '30px' }}>Coming Soon!</Typography>
          <Typography variant="h4" mt={1}>
            We’re hard at work preparing this feature for launch.
          </Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} className={classes.fullHeight} align="center">
          <LaunchIcon size="80%" />
        </Grid>
      </Grid>
    </div>
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, FormGroup, Switch, Radio, RadioGroup, Grid } from '@mui/material';

export const ProjectRetentionForm = ({ formik, loading, handleChange }) => (
  <FormGroup>
    <FormControlLabel
      control={<Switch color="secondary" />}
      label="Override Current Retention Policy"
      value="overrideRetention"
      onChange={(event) => {
        formik.setFieldValue('overrideRetention', event.target.checked);
        handleChange(true);
      }}
      checked={formik.values.overrideRetention}
      disabled={loading}
    />
    <RadioGroup
      defaultValue={0}
      name="retention-months"
      onChange={(event) => {
        formik.setFieldValue('retentionMonths', event.target.value * 1);
        handleChange(true);
      }}
    >
      <Grid container spacing={0.5} columns={2}>
        <Grid item xs={1} md={1}>
          <FormControlLabel
            value={12}
            control={
              <Radio
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 22,
                  },
                }}
              />
            }
            label="12 Months"
            disabled={!formik.values.overrideRetention}
            checked={formik.values.retentionMonths === 12}
          />
        </Grid>
        <Grid item xs={2} md={1}>
          <FormControlLabel
            value={18}
            control={
              <Radio
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 22,
                  },
                }}
              />
            }
            label="18 Months"
            disabled={!formik.values.overrideRetention}
            checked={formik.values.retentionMonths === 18}
          />
        </Grid>
        <Grid item xs={2} md={1}>
          <FormControlLabel
            value={24}
            control={
              <Radio
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 22,
                  },
                }}
              />
            }
            label="2 Years"
            disabled={!formik.values.overrideRetention}
            checked={formik.values.retentionMonths === 24}
          />
        </Grid>
        <Grid item xs={2} md={1}>
          <FormControlLabel
            value={36}
            control={
              <Radio
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 22,
                  },
                }}
              />
            }
            label="3 Years"
            disabled={!formik.values.overrideRetention}
            checked={formik.values.retentionMonths === 36}
          />
        </Grid>
        <Grid item xs={2} md={1}>
          <FormControlLabel
            value={60}
            control={
              <Radio
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 22,
                  },
                }}
              />
            }
            label="5 Years"
            disabled={!formik.values.overrideRetention}
            checked={formik.values.retentionMonths === 60}
          />
        </Grid>
        <Grid item xs={2} md={1}>
          <FormControlLabel
            value={120}
            control={
              <Radio
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 22,
                  },
                }}
              />
            }
            label="10 Years"
            disabled={!formik.values.overrideRetention}
            checked={formik.values.retentionMonths === 120}
          />
        </Grid>
        <Grid item xs={2} md={1}>
          <FormControlLabel
            value={0}
            control={
              <Radio
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 22,
                  },
                }}
              />
            }
            label="Never Archive"
            disabled={!formik.values.overrideRetention}
            checked={formik.values.retentionMonths === 0}
          />
        </Grid>
      </Grid>
    </RadioGroup>
  </FormGroup>
);

ProjectRetentionForm.propTypes = {
  formik: PropTypes.object,
  loading: PropTypes.bool,
  handleChange: PropTypes.func,
};

ProjectRetentionForm.defaultProps = {
  formik: null,
  loading: false,
  handleChange: () => {},
};

import PropTypes from 'prop-types';
import React from 'react';

// eslint-disable-next-line no-unused-vars
export const SwitchCase = ({ name, isDefault, children }) => <>{children}</>;

SwitchCase.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isDefault: PropTypes.bool,
  children: PropTypes.node,
};

SwitchCase.defaultProps = {
  name: null,
  isDefault: false,
  children: null,
};

/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { usePagination, useSortBy, useTable } from 'react-table';
import { useDispatch, useSelector } from 'react-redux';
import { setSortBy, setPageFilter } from 'slices/profileProjectAssetReducer';
import { CardPagination } from 'components/CardPagination/CardPagination';
import { CheckCircleBroken, AlertCircleIcon, LinkedIcon, UploadCloudIcon } from 'components/Icons';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortAlphaAsc, faSortAlphaDesc } from '@fortawesome/free-solid-svg-icons';
import { v4 } from 'uuid';
import { useStyles } from './styles';

const tableColumns = (classes) => [
  {
    Header: 'Status',
    id: 'status',
    localFilter: true,
    accessor: (rowData) =>
      !rowData.uploadComplete ? (
        <div className={`${classes.tableIcon} ${classes.transcodingIcon}`} title="uploading">
          <UploadCloudIcon size={15} />
        </div>
      ) : rowData.hasError ? (
        <div className={`${classes.tableIcon} ${classes.errorIcon}`} title="has error">
          <AlertCircleIcon size={15} />
        </div>
      ) : !rowData.isTranscodingComplete ? (
        <div className={`${classes.tableIcon} ${classes.transcodingIcon}`} title="transcoding">
          <LinkedIcon size={15} />
        </div>
      ) : rowData.archived ? (
        <div className={`${classes.tableIcon} ${classes.archivedIcon}`} title="archived">
          <LinkedIcon size={15} />
        </div>
      ) : (
        <div className={`${classes.tableIcon} ${classes.succesIcon}`} title="completed">
          <CheckCircleBroken size={15} />
        </div>
      ),
  },
  {
    Header: 'Date',
    id: 'createdAt',
    accessor: (rowData) => {
      const { createdAt } = rowData;
      return createdAt ? moment(createdAt).format('MM/DD/YY') : 'date not found';
    },
  },
  {
    Header: 'Title',
    accessor: 'name', // accessor is the "key" in the data
  },
  {
    Header: 'Type',
    id: 'type',
    localFilter: true,
    accessor: (rowData) => (rowData.type === 'VIDEO' ? '.mp4' : rowData.type === 'PHOTO' ? '.jpg' : '.arcgis.online'),
  },
];

export const ProjectAssetsTable = ({ assets, filters, totalCount, selectedAsset, handleRowClick }) => {
  const classes = useStyles();
  const columns = React.useMemo(() => tableColumns(classes), []);
  const [list, setList] = useState([]);
  const dispatch = useDispatch();
  const [localFilters, setLocalFilters] = useState({ sort_by: [] });
  const { isAssetLoading } = useSelector((state) => state.profileProjectAssets);

  const table = useTable(
    {
      columns,
      data: list,
      initialState: {
        pageIndex: filters.page - 1,
        pageSize: filters.page_size,
      },
      pageCount: Math.ceil(totalCount / filters.page_size),
      totalCount,
      pageLength: list.length,
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  const sorStatus = (column) => {
    const index = localFilters.sort_by?.findIndex((obj) => obj.label === column.id);
    const auxFilters = { ...localFilters };
    if (localFilters.sort_by.length === 0 || index === -1) {
      auxFilters.sort_by.push({ label: column.id, value: `+${column.id}` });
    } else if (index !== -1) {
      const symbol = localFilters.sort_by.find((obj) => obj.value === `+${column.id}`) ? '-' : '+';
      if (localFilters.sort_by.find((obj) => obj.value === `-${column.id}`)) {
        auxFilters.sort_by.splice(index, 1);
      } else {
        auxFilters.sort_by[index] = { label: column.id, value: `${symbol}${column.id}` };
      }
    }
    setLocalFilters({ ...auxFilters });
  };

  // --------------------- manual click on column header for sorting
  const columnHeaderClick = (column) => {
    if (!column.desabledSort) {
      if (column.localFilter) {
        sorStatus(column);
        return;
      }
      if (Object.keys(filters.sort_by).length > 0) {
        if (Object.values(filters.sort_by).includes(`-${column.id}`)) {
          dispatch(setSortBy({ label: column.id, value: null }));
        } else if (Object.values(filters.sort_by).includes(`+${column.id}`)) {
          dispatch(setSortBy({ label: column.id, value: `-${column.id}` }));
        } else {
          dispatch(setSortBy({ label: column.id, value: `+${column.id}` }));
        }
      } else {
        dispatch(setSortBy({ label: column.id, value: `+${column.id}` }));
      }
    }
  };

  //  sort list in FE
  const localSort = () => {
    const sortedData = [...assets].sort((a, b) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const filter of localFilters.sort_by) {
        const { label, value } = filter;
        const order = value.startsWith('+') ? 1 : -1;
        if (a[label] < b[label]) return -order;
        if (a[label] > b[label]) return order;
      }
      return 0;
    });
    return sortedData;
  };

  useEffect(() => {
    setList(localSort);
  }, [localFilters]);

  useEffect(() => {
    setList(localSort);
  }, [assets]);

  return (
    <div>
      <Table table={table}>
        <TableHead>
          {table.headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  onClick={() => columnHeaderClick(column)}
                  style={{
                    cursor: column.desabledSort ? 'default' : 'pointer',
                  }}
                  key={`${v4()}${new Date().getMilliseconds()}`}
                >
                  {column.render('Header')}
                  {!column.desabledSort && (
                    <span
                      style={{
                        margin: '2px 4px',
                        position: 'absolute',
                      }}
                    >
                      {column.localFilter
                        ? (() => {
                            if (localFilters.sort_by.length > 0) {
                              if (localFilters.sort_by.find((obj) => obj.value === `-${column.id}`)) {
                                return <FontAwesomeIcon icon={faSortAlphaDesc} size="sm" />;
                              }
                              if (localFilters.sort_by.find((obj) => obj.value === `+${column.id}`)) {
                                return <FontAwesomeIcon icon={faSortAlphaAsc} size="sm" />;
                              }
                            }
                            return <FontAwesomeIcon icon={faSort} size="sm" />;
                          })()
                        : (() => {
                            if (Object.keys(filters.sort_by).length > 0) {
                              if (Object.values(filters.sort_by).includes(`-${column.id}`)) {
                                return <FontAwesomeIcon icon={faSortAlphaDesc} size="sm" />;
                              }
                              if (Object.values(filters.sort_by).includes(`+${column.id}`)) {
                                return <FontAwesomeIcon icon={faSortAlphaAsc} size="sm" />;
                              }
                            }
                            return <FontAwesomeIcon icon={faSort} size="sm" />;
                          })()}
                    </span>
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...table.getTableBodyProps()}>
          {table.rows.map((row) => {
            table.prepareRow(row);
            return (
              <TableRow
                key={`${v4()}${new Date().getMilliseconds()}`}
                {...row.getRowProps()}
                selected={selectedAsset === row.original.id}
              >
                {row.cells.map((cell) => (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
                  <TableCell
                    {...cell.getCellProps()}
                    onClick={
                      isAssetLoading
                        ? null
                        : () => handleRowClick(row.original, row.originallatitude, row.originallongitude)
                    }
                    key={`${v4()}${new Date().getMilliseconds()}`}
                  >
                    {cell.render('Cell')}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <CardPagination
        page={filters.page}
        pageSize={filters.page_size}
        pageLength={list.length}
        totalCount={totalCount}
        onPageChange={(page) => {
          dispatch(setPageFilter(page));
        }}
      />
    </div>
  );
};

ProjectAssetsTable.propTypes = {
  assets: PropTypes.array,
  filters: PropTypes.object,
  totalCount: PropTypes.number,
  selectedAsset: PropTypes.string,
  handleRowClick: PropTypes.func,
};

ProjectAssetsTable.defaultProps = {
  assets: [],
  filters: null,
  totalCount: 0,
  selectedAsset: null,
  handleRowClick: () => {},
};

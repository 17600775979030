import { Avatar } from 'components/Avatar';
import { ArrowCurvedIcon } from 'components/Icons';
import { SidebarPanel } from 'components/SidebarPanel';
import { SidebarPanelSection } from 'components/SidebarPanelSection';
import { resolveUserInitials } from 'lib/resolve-user-initials';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AvatarRowSpacing, ViewDetailsButton } from './styles';

export const InfoPanel = () => {
  const { details, uploader, projectSharedWith } = useSelector((state) => state.projectContent.panels);
  const history = useHistory();
  const navigateToAssetPage = () => {
    history.push(`/profile/projects/${details.projId}/content/${details.id}`);
  };
  return (
    <SidebarPanel title="Content Details">
      <SidebarPanelSection title="Description" accordion>
        <div>
          <p>Name: {details.name}</p>
          <p>Description: {details.description}</p>
          <p>Date Added: {details && moment(details.createdAt).format('MM/DD/YY')}</p>
          <p>Creator: {uploader[0] && `${uploader[0].firstName} ${uploader[0].lastName}`}</p>
          <ViewDetailsButton kind="secondary" icon={ArrowCurvedIcon} size="small" onClick={() => navigateToAssetPage()}>
            View Asset Details
          </ViewDetailsButton>
        </div>
      </SidebarPanelSection>

      <SidebarPanelSection title="Shared With" accordion>
        <AvatarRowSpacing>
          {/* <ContextMenu>
            <SharingContextMenu icon={SharingIcon} iconOnly kind="secondary" size="small" />
            <ContextMenuList position="bottom">
              <StyledContextMenuItem icon={StylesSharingIcon}>Share to a Project</StyledContextMenuItem>
            </ContextMenuList>
          </ContextMenu> */}

          {/* {projectData.slice(0, 10).map((user) => ( */}
          {projectSharedWith && (
            <Avatar key={details.id} initials={resolveUserInitials({ firstName: projectSharedWith })} size={48} />
          )}
          {/* ))} */}
          {/* {projectData.length > 10 && <Avatar initials={`+${projectData.length - 10}`} size={48} />} */}
        </AvatarRowSpacing>
      </SidebarPanelSection>
    </SidebarPanel>
  );
};

import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import AdminAssets from 'services/AdminAssets';
import ProjectService from 'services/ProjectService';
import TeamService from 'services/TeamService';
import UserService from 'services/UserService';
import { formatDateRange } from './helpers';

export const getAdminContents = createAsyncThunk('adminContents/getAdminContents', async (filterOpts) => {
  const filtersOpts = {
    ...filterOpts,
    sort_by: Object.values(filterOpts.sort_by),
    include: ['uploader', 'project'],
    ...formatDateRange(filterOpts.date_range),
    strict: true,
  };
  filtersOpts.contributorId = filtersOpts.userId?.length
    ? filtersOpts.userId.concat(filtersOpts.teamId?.length ? filtersOpts.teamId : [])
    : filtersOpts.teamId;
  delete filtersOpts.acctId;
  delete filtersOpts.userId;
  delete filtersOpts.teamId;
  delete filtersOpts.date_range;
  delete filtersOpts.activated;
  try {
    const response = await AdminAssets.getAdminAssets(filtersOpts);
    return response.data;
  } catch (e) {
    throw new Error('Error during request for content...');
  }
});

export const getTotalUploads = createAsyncThunk('adminContents/getTotalUploads', async () => {
  try {
    const responseProject = await AdminAssets.getAdminAssets();
    return responseProject.data;
  } catch (e) {
    throw new Error('Error during request for content...');
  }
});

export const getSingleContent = createAsyncThunk('adminContents/getSingleContent', async ({ projectId, id, user }) => {
  const [details] = await Promise.all([await AdminAssets.getSingleAsset(projectId, id)]);

  return {
    details: details.data,
    user,
  };
});

export const deleteContent = createAsyncThunk('adminContents/deleteContent', async ({ projectId, assetId }) => {
  const deletedProjectAsset = await ProjectService.deleteProjectAsset(projectId, assetId);
  return {
    deletedProjectAsset: deletedProjectAsset.data,
  };
});

export const preloadFilterDatasets = createAsyncThunk(
  'adminContents/preloadFilterDatasets',
  async () => {
    try {
      const [projects, users, teams, organizations] = await Promise.all([
        await ProjectService.getProjects({ page_size: 1000, participantLevel: 'administer' }),
        await UserService.getUsers({ page_size: 500 }),
        await TeamService.getTeams({ page_size: 500 }),
        await ProjectService.getProjectOrganizations({ participantLevel: ['administer'] }),
      ]);

      return {
        projects: projects.data.data,
        users: users.data.data,
        organizations: organizations.data.data,
        teams: teams.data.data,
      };
    } catch (err) {
      throw new Error('error loading filter data');
    }
  },
  {
    condition: (_, { getState }) => getState().adminContents.panels.search.loaded === false,
  }
);

const orderByName = (array) =>
  array.sort((a, b) => {
    const nameA = a.label.toUpperCase(); // ignore upper and lowercase
    const nameB = b.label.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

const initialState = {
  error: null,
  loading: true,
  data: [],
  panels: {
    loading: true,
    details: [],
    user: [],
    search: {
      loaded: false,
      users: [],
      teams: [],
      projects: [],
      organizations: [],
    },
  },
  filters: {
    page: 1,
    page_size: 10,
    sort_by: {},
    date_range: null,
  },
  total: null,
};

const contentSlice = createSlice({
  name: 'adminContents',
  initialState,
  reducers: {
    resetAdminContents: () => initialState,
    setPageFilter(state, action) {
      state.filters.page = action.payload;
    },
    setSearch(state, action) {
      state.filters.search = action.payload;
      state.filters.page = 1;
    },
    setSortBy(state, { payload }) {
      const { label, value } = payload;
      if (value === '' || value === null) {
        delete state.filters.sort_by[label];
      } else {
        state.filters.sort_by[label] = value;
      }
    },
    shareContent(state, action) {
      const oldItem = action.payload;
      const currentItem = state.data.find((item) => item.id === oldItem.id);
      if (!currentItem) {
        state.data = [];
        state.error = 'Content not found.';
      } else {
        currentItem.sharingEnabled = !currentItem.sharingEnabled;
      }
    },
    copyContent(state, action) {
      const oldItem = action.payload;
      const currentItem = state.data.find((item) => item.id === oldItem.id);
      if (!currentItem) {
        state.data = [];
        state.error = 'Content not found.';
      } else {
        currentItem.copyingEnabled = !currentItem.copyingEnabled;
      }
    },
    accessContent(state, action) {
      const oldItem = action.payload;
      const currentItem = state.data.find((item) => item.id === oldItem.id);
      if (!currentItem) {
        state.data = [];
        state.error = 'Content not found.';
      } else {
        currentItem.sharingEnabled = false;
        currentItem.copyingEnabled = false;
      }
    },
    revokeContent(state, action) {
      const oldItem = action.payload;
      const currentItem = state.data.find((item) => item.id === oldItem.id);
      if (!currentItem) {
        state.data = [];
        state.error = 'Content not found.';
      } else if (currentItem.accessEnabled === true) {
        currentItem.accessEnabled = false;
        currentItem.sharingEnabled = false;
        currentItem.copyingEnabled = false;
      } else {
        currentItem.accessEnabled = true;
      }
    },
    setFilterValue(state, { payload }) {
      const { param, value } = payload;
      if ((value === '' || value === null) && param !== 'date_range') {
        delete state.filters[param];
        return;
      }
      state.filters[param] = payload.value;
    },
    setDateRange(state, { payload }) {
      state.filters.date_range = payload;
    },
    setFilters(state, action) {
      const filter = { ...state.filters, ...action.payload, page: 1 };
      if (!filter?.participantLevel) delete filter?.participantLevel;
      state.filters = filter;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAdminContents.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAdminContents.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.assetCount = action.payload.meta.totalCount;
      state.error = null;
      state.loading = false;
    });
    builder.addCase(getAdminContents.rejected, (state) => {
      state.content = [];
      state.error = 'There was an error during the request.';
      state.loading = false;
    });
    builder.addCase(getSingleContent.pending, (state) => {
      state.loading = true;
      state.panels.loading = true;
    });
    builder.addCase(getSingleContent.fulfilled, (state, action) => {
      state.panels.details = action.payload.details.data;
      state.panels.user = action.payload.user;
      state.error = null;
      state.loading = false;
      state.panels.loading = false;
    });
    builder.addCase(getSingleContent.rejected, (state) => {
      state.data = [];
      state.error = 'There was an error during the request.';
      state.loading = false;
      state.panels.loading = false;
    });
    builder.addCase(deleteContent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteContent.fulfilled, (state) => {
      state.error = null;
      state.loading = false;
    });
    builder.addCase(deleteContent.rejected, (state) => {
      state.data = [];
      state.error = 'There was an error during the request.';
      state.loading = false;
    });
    builder.addCase(preloadFilterDatasets.pending, (state) => {
      state.panels.search.loaded = false;
    });
    builder.addCase(preloadFilterDatasets.rejected, (state) => {
      state.panels.search.users = [];
      state.panels.search.teams = [];
      state.panels.search.projects = [];
      state.panels.search.organizations = [];
      state.panels.search.loaded = false;
    });
    builder.addCase(preloadFilterDatasets.fulfilled, (state, action) => {
      const { projects, organizations, users, teams } = action.payload;
      state.panels.search.loaded = true;
      const newProjects = projects.map((proj) => ({
        label: proj.name,
        value: proj.id,
      }));
      let newOrganizations = organizations.map((proj) => ({
        label: proj.name,
        value: proj.id,
      }));
      newOrganizations = orderByName(newOrganizations);
      state.panels.search.projects = newProjects;
      state.panels.search.organizations = newOrganizations;
      const newTeams = teams.map((team) => ({
        label: team.name,
        value: team.id,
      }));
      state.panels.search.teams = newTeams;
      const newUsers = users.map((user) => ({
        label: `${user.firstName} ${user.lastName}`,
        value: user.id,
      }));
      state.panels.search.users = newUsers;
    });
  },
});

export const {
  setPageFilter,
  contentActions,
  setSearch,
  setSortBy,
  accessContent,
  shareContent,
  copyContent,
  revokeContent,
  resetAdminContents,
  setFilterValue,
  setDateRange,
  setFilters,
} = contentSlice.actions;

export const dateRangeSelector = createSelector(
  (state) => state.adminContents.filters,
  (state) => {
    const dates = state.date_range;

    if (!dates) {
      return dates;
    }
    return dates.map((date) => (date ? new Date(date) : null));
  }
);

export default contentSlice.reducer;

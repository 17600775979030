import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dateRangeSelector, preloadFilterDatasets, setFilterValue, setFilters } from 'slices/profileProjectReducer';
import { SearchPanelBody, searchPanelSection } from 'pages/Projects/SearchPanel/SearchPanelBody';

export const SearchPanel = () => {
  const dispatch = useDispatch();
  const nameSort = useSelector((state) => state.profileProjects.filters.sort_by.name || '');
  const dateSort = useSelector((state) => state.profileProjects.filters.sort_by.createdAt || '');
  const orgSort = useSelector((state) => state.profileProjects.filters.sort_by.org || '');
  const acctId = useSelector((state) => state.profileProjects.filters.acctId || '');
  const organizationId = useSelector((state) => state.profileProjects.filters.organizationId || '');
  const participantLevel = useSelector((state) => state.profileProjects.filters.participantLevel || '');
  const teamId = useSelector((state) => state.profileProjects.filters.teamId || '');
  const accountSort = useSelector((state) => state.profileProjects.filters.sort_by.account || '');
  const isOpen = useSelector((state) => state.profileProjects.filters.isOpen);
  const dateRange = useSelector(dateRangeSelector);
  const { accounts, organizations, teams, loaded } = useSelector((state) => state.profileProjects.panels.search);
  const { userId } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(preloadFilterDatasets(userId));
  }, []);

  return (
    <SearchPanelBody
      loaded={loaded}
      teams={teams}
      accounts={accounts}
      organizations={organizations}
      filters={{
        sort_by: {
          name: nameSort,
          createdAt: dateSort,
          org: orgSort,
          account: accountSort,
        },
        acctId,
        organizationId,
        participantLevel,
        date_range: dateRange,
        teamId,
        isOpen,
      }}
      setFilterValue={setFilterValue}
      setFilters={setFilters}
      section={searchPanelSection.PROFILEPROJECTS}
    />
  );
};

import React, { useEffect, useState } from 'react';
import { BarsIcon } from 'components/Icons/BarsIcon';
import { Loader } from 'components/Loader/Loader';
import { ProgressBar } from 'components/ProgressBar/ProgressBar';
import { WidgetContent } from 'components/WidgetContent/WidgetContent';
import { WidgetHeader } from 'components/WidgetHeader/WidgetHeader';
import { WidgetItem } from 'components/WidgetItem/WidgetItem';
import AdminAssets from 'services/AdminAssets';
import ProjectService from 'services/ProjectService';
import { Typography } from '@mui/material';
import { useStyles } from './styles';

export const ProjectsWidget = () => {
  const classes = useStyles();
  const [projectData, setProjectData] = useState(null);
  const [uploadData, setUploadData] = useState(null);

  useEffect(() => {
    const getProjsDetails = async () => {
      const responseProject = await ProjectService.getProjects();
      setProjectData(responseProject.data.meta.totalCount);
    };

    const getContentDetails = async () => {
      const responseProject = await AdminAssets.getAdminAssets();
      setUploadData(responseProject.data.meta.totalCount);
    };

    getProjsDetails();
    getContentDetails();
  }, []);
  return (
    <div className={classes.generalContent}>
      {projectData === null ? (
        <Loader loading height={50} size={32} />
      ) : (
        <>
          <WidgetHeader
            title="Most resource-intensive projects"
            subTitle="These are projects you own and are hosting."
            actionIcon={BarsIcon}
            actionTitle="View All"
            border={false}
          />
          <WidgetContent>
            <div className={classes.orgSection}>
              <WidgetItem title="Uploads" subHeader="Total Uploads.">
                <Typography variant="body1" mb={0.5} className="text">
                  {uploadData}
                </Typography>
                <ProgressBar value={0} color="orange" />
              </WidgetItem>
            </div>
            <div className={classes.orgSection}>
              <WidgetItem title="Projects" subHeader="Total active projects.">
                <Typography variant="body1" mb={0.5} className="text">
                  {projectData}
                </Typography>
                <ProgressBar value={0} />
              </WidgetItem>
            </div>
          </WidgetContent>
        </>
      )}
    </div>
  );
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { ModalHeaderIcon } from 'components/ModalHeaderIcon/ModalHeaderIcon';
import { ShieldIcon, InfoIcon } from 'components/Icons';
import CreditCardIcon from '../../images/CreditCardIcon.png';

import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { loadStripe } from '@stripe/stripe-js';

export const ModalCreditsPurchasing = ({ onConfirm, onCancel, isOpen }) => {
  const [value, setValue] = useState('');
  const [openPaymentPending, setOpenPaymentPending] = useState('');
  const [label, setLabel] = useState('');
  const { email } = useSelector((state) => state.auth);

  let stripePromise;

  const getStripe = () => {
    if (!stripePromise) {
      stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);
    }
    return stripePromise;
  };

  const handleCheckout = async (priceId) => {
    const stripe = await getStripe();
    console.info(stripe);
    await stripe.redirectToCheckout({
      lineItems: [
        {
          price: priceId,
          quantity: 1,
        },
      ],
      mode: 'subscription',
      successUrl: `${window.location.href}?paymentSuccess=true&creditsLoaded=${label?.split(' / ')[0]?.split(' ')[0]}`,
      cancelUrl: `${window.location.href}?paymentSuccess=false`,
      customerEmail: email,
    });
  };

  const goToCheckout = () => {
    console.info('checkout');
    switch (value) {
      case '10,000':
        handleCheckout('price_1OcApWHGsnhECh4ZMjnwPL7s');
        // window.open('https://buy.stripe.com/14kcQr674gpk1WwbIJ');
        break;
      case '50,000':
        window.open('https://buy.stripe.com/fZebMn3YWehc44EaEG');
        break;
      case '100,000':
        window.open('https://buy.stripe.com/9AQ4jVgLIflg44E28b');
        break;
      case '500,000':
        window.open('https://buy.stripe.com/cN25nZ1QO1uqeJi004');
        break;
      case '1,000,000':
        window.open('https://buy.stripe.com/00g2bNbro2yufNmcMR');
        break;
      case '5,000,000':
        window.open('https://buy.stripe.com/6oEg2DdzwgpkdFe8wC');
        break;
      case '10,000,000':
        window.open('https://buy.stripe.com/bIYg2DgLIflgfNm8wD');
        break;
      default:
        break;
    }
    if (openPaymentPending === 'accepted') {
      setOpenPaymentPending('');
      onConfirm();
    }
  };

  return (
    <Dialog open={isOpen} onClose={onCancel} maxWidth="sm">
      <DialogTitle>
        <img src={CreditCardIcon} alt="Credit Card Icon" />
        <ModalHeaderIcon icon={ShieldIcon} text="Purchase Credits" />
        <Typography>Select your desired number of credits.</Typography>
      </DialogTitle>

      <DialogContent>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="10,000"
          name="radio-buttons-group"
          value={value}
          onChange={(info) => {
            setValue(info.target?.value);
            switch (info.target?.value) {
              case '10,000':
                setLabel('10,000 credits / $100');
                break;
              case '50,000':
                setLabel('50,000 credits / $500');
                break;
              case '100,000':
                setLabel('100,000 credits  / $1,000');
                break;
              case '500,000':
                setLabel('500,000 credits / $5,000');
                break;
              case '1,000,000':
                setLabel('1,000,000 credits / $9,900 (1% discount)');
                break;
              case '5,000,000':
                setLabel('5,000,000 credits / $48,750 (2% discount)');
                break;
              case '10,000,000':
                setLabel('10,000,000 credits / $97,000 (3% discount)');
                break;
              default:
                break;
            }
          }}
        >
          <FormControlLabel value="10,000" control={<Radio />} label="10,000 credits / $100" />
          <FormControlLabel value="50,000" control={<Radio />} label="50,000 credits / $500" />
          <FormControlLabel value="100,000" control={<Radio />} label="100,000 credits  / $1,000" />
          <FormControlLabel value="500,000" control={<Radio />} label="500,000 credits / $5,000" />
          <FormControlLabel value="1,000,000" control={<Radio />} label="1,000,000 credits / $9,900 (1% discount)" />
          <FormControlLabel value="5,000,000" control={<Radio />} label="5,000,000 credits / $48,750 (2% discount)" />
          <FormControlLabel value="10,000,000" control={<Radio />} label="10,000,000 credits / $97,000 (3% discount)" />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button color="primary" variant="contained" onClick={onCancel} size="large" fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                if (openPaymentPending !== 'accepted') setOpenPaymentPending('pending');
              }}
              size="large"
              fullWidth
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
      {openPaymentPending && (
        <Dialog open={openPaymentPending === 'pending'} onClose={() => {}} maxWidth="sm">
          <DialogTitle>
            <ModalHeaderIcon icon={InfoIcon} text="Confirm your purchase amount." />
          </DialogTitle>
          <DialogContent>{`You've selected ${label?.split(' / ')[0]} for ${label?.split(' / ')[1]}.`}</DialogContent>
          <DialogActions>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    setOpenPaymentPending('accepted');
                    goToCheckout();
                  }}
                  size="large"
                  fullWidth
                >
                  Accept
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setOpenPaymentPending('cancelled');
                  }}
                  size="large"
                  fullWidth
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      )}
    </Dialog>
  );
};

ModalCreditsPurchasing.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
};

ModalCreditsPurchasing.defaultProps = {
  onConfirm: () => {},
  onCancel: () => {},
};

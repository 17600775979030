import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';

// Sidebar Context
export const SidebarDetailContext = createContext({
  open: false,
  visible: false,
  activePanel: '',
  fullWidth: false,
  onClose: () => {},
  config: {
    nav: [],
    search: {},
    header: [],
  },
  panels: {},
  setOpen: () => {},
  setConfig: () => {},
  setActivePanel: () => {},
  setFullWidth: () => {},
});

export const SidebarProviderDetail = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [config, setConfig] = useState({
    nav: [],
    search: {},
    header: [],
    actions: [],
  });

  const [visible, setVisible] = useState(false);
  const [panels, setPanels] = useState({});
  const [activePanel, setActivePanel] = useState('');
  const [fullWidth, setFullWidth] = useState('');

  const publicSetOpen = (isOpen) => {
    if (!isOpen) {
      setActivePanel('');
    }
    setVisible(isOpen);
    setOpen(isOpen);
  };

  const publicSetActive = (panelName) => {
    if (panelName && panelName !== '') {
      setActivePanel(panelName);
      setOpen(true);
      setVisible(true);
      return;
    }

    setActivePanel('');
    setOpen(false);
    setVisible(false);
  };

  const publicSetConfig = (cfg) => {
    const newPanels = {};

    const newConfig = {
      onClose: cfg.onClose || (() => {}),
      search: {
        show: false,
        external: false,
        component: null,
        ...cfg.search,
      },
      header: [...(cfg.header || [])],
      nav: [...(cfg.nav || [])],
      actions: [...(cfg.actions || [])],
    };

    setConfig(newConfig);
    if (newConfig.search.show) {
      newPanels.search = newConfig.search.component;
    }

    if (newConfig.header) {
      newConfig.header.forEach((h) => {
        newPanels[h.name] = h.component;
      });
    }

    if (newConfig.nav) {
      newConfig.nav.forEach((n) => {
        newPanels[n.name] = n.component;
      });
    }
    setPanels(newPanels);
  };

  const clearHeaderPanels = () => {
    publicSetConfig({
      ...config,
      header: [],
    });
  };

  const setHeaderPanels = (headerPanels) => {
    publicSetConfig({
      ...config,
      header: [...headerPanels],
    });
  };

  const handleClose = () => {
    config.onClose();
    clearHeaderPanels();
  };

  const publicSetFullWidth = (value) => {
    setFullWidth(value);
  };

  return (
    <SidebarDetailContext.Provider
      value={{
        config,
        activePanel,
        open,
        panels,
        visible,
        fullWidth,
        clearHeaderPanels,
        setHeaderPanels,
        setActivePanel: publicSetActive,
        setFullWidth: publicSetFullWidth,
        setOpen: publicSetOpen,
        setConfig: publicSetConfig,
        handleClose,
        setVisible,
      }}
    >
      {children}
    </SidebarDetailContext.Provider>
  );
};

SidebarProviderDetail.propTypes = {
  children: PropTypes.node,
};

SidebarProviderDetail.defaultProps = {
  children: null,
};

export const useSidebarDetail = (
  initialConfig = {
    open: false,
    visible: false,
    fullWidth: false,
    config: {
      onClose: () => {},
      nav: [],
      search: {
        show: false,
        external: false,
        component: null,
      },
      header: [],
    },
  }
) => {
  // eslint-disable-next-line prettier/prettier
  const {
    config,
    open,
    visible,
    fullWidth,
    setConfig,
    setOpen,
    setActivePanel,
    setVisible,
    setFullWidth,
    clearHeaderPanels,
    setHeaderPanels,
    // eslint-disable-next-line prettier/prettier
  } = useContext(SidebarDetailContext);

  useEffect(() => {
    setVisible(initialConfig.open);
    setFullWidth(initialConfig.fullWidth);
    setOpen(initialConfig.open);
    setConfig(initialConfig.config);
    return () => {
      setVisible(false);
      setFullWidth(false);
      setOpen(false);
      setConfig({
        onClose: () => {},
        nav: [],
        search: {},
        header: [],
      });
    };
  }, []);

  const toggleSidebar = () => {
    setVisible((oldVal) => !oldVal);
    setOpen((oldVal) => !oldVal);
  };

  const displayPanel = (name) => {
    setActivePanel(name);
  };

  return {
    open,
    config,
    visible,
    fullWidth,
    toggleSidebar,
    displayPanel,
    setHeaderPanels,
    setFullWidth,
    clearHeaderPanels,
  };
};

import { CloseIcon } from 'components/Icons';
// import ReorderIcon from '@mui/icons-material/Reorder';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Typography } from 'components/Typography';
import styled from 'styled-components';

export const StyledNameContent = styled.div`
  margin-left: 0;
`;

export const StyledTR = styled.tr`
  width: 100%;
`;

export const StyledTD = styled.td`
  background-color: ${(props) => props.theme.palette.primary.base};
  border: 1px solid ${(props) => props.theme.palette.primary.light};
  vertical-align: middle;
`;

export const StyledInput = styled.input`
  background: transparent;
  border: none;
  color: white;
  padding: ${(props) => props.theme.spacing(0.5)};
  width: 100%;
  flex-grow: 1;
  &:disabled {
    color: ${(props) => props.theme.palette.primary[500]};
  }
`;

export const StyledSelect = styled.select`
  background: transparent;
  border: none;
  color: white;
  padding: ${(props) => props.theme.spacing(0.5)};
  width: 100%;
  &:disabled {
    color: ${(props) => props.theme.palette.primary[500]};
  }
  & > * {
    background: ${(props) => props.theme.palette.primary[1000]};
  }
`;

export const StyledDragIcon = styled(DragIndicatorIcon)`
  color: ${(props) => props.theme.palette.secondary.base};
`;

export const StyledCloseIcon = styled(CloseIcon)`
  border: 1px solid white;
  border-radius: 50%;
  padding: ${(props) => props.theme.spacing(0.2)};
  vertical-align: middle;
  cursor: pointer;
`;

export const StyledRow = styled.div`
  position: relative;
`;

export const StyledCell = styled.div`
  display: flex;
`;

export const ReadOnlyText = styled(Typography)`
  margin: 8px 8px;
`;

export const CloseTD = styled.td`
  vertical-align: middle;
  width: 31px;
  text-align: center;
  min-width: 31px;
`;

export const StyledTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.primary[500]};
  margin-left: ${(props) => props.theme.spacing(0.7)};
  width: 100%;
`;

export const DragDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  & > svg {
    margin-left: auto;
    margin-right: auto;
  }
`;

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  bar: (props) => ({
    position: props.fixed ? 'fixed' : 'absolute',
    top: props.fixed ? '13%' : 0,
    left: props.fixed ? '34%' : 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.primary[1400],
    borderLeft: `1px solid ${theme.palette.secondary[700]}`,
    zIndex: 799,
    transform: `translateX(${props.open ? '0%' : '110%'})`,
    transition: 'transform 0.3s ease-out',
    // ...(props.fixed && {
    //   '& .mapDetailContainer': {
    //     height: '60vh',
    //   },
    // }),
  }),
  topButton: {
    alignItems: 'center',
  },
}));

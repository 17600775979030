import React, { useContext } from 'react';
import { SidebarContext } from '../SidebarProvider/SidebarProvider';
import { useStyles } from './styles';

export const Sidebar = () => {
  const { panels, activePanel, open, visible, fullWidth, config } = useContext(SidebarContext);
  const classes = useStyles({ open, fullWidth, visible });
  const Panel = panels[activePanel];

  return (
    <>
      {config?.search?.background && open && <div className={classes.background} />}
      <div className={classes.sidebarPanelContainer}>{Panel && <Panel />}</div>
    </>
    // <div className={classes.sidebarContainer}>
    //   <div className={classes.sidebarNavContainer}>
    //     <div className={classes.sidebarNavHeader}>
    //       {config.search.show && (
    //         <button
    //           className={classes.sidebarTabButton}
    //           type="button"
    //           // active={activePanel === 'search'}
    //           onClick={() => {
    //             setActivePanel('search');
    //           }}
    //         >
    //           <SearchIcon size={18} />
    //         </button>
    //       )}
    //     </div>
    //     <div className={classes.sidebarNavBody}>
    //       {config.nav.map((item) => (
    //         <button
    //           className={classes.sidebarTabButton}
    //           type="button"
    //           key={item.name}
    //           // active={activePanel === item.name}
    //           onClick={() => {
    //             setActivePanel(item.name);
    //           }}
    //         >
    //           <item.icon size={item.size || 18} />
    //         </button>
    //       ))}
    //     </div>
    //     <div className={classes.sidebarNavFooter}>
    //       {config.actions.map((action) => (
    //         <button type="button" className={classes.sidebarTabButton} onClick={action.onClick} key={uuid.v4()}>
    //           <action.icon size={18} />
    //         </button>
    //       ))}
    //     </div>
    //   </div>
    // </div>
  );
};

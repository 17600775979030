import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  styledMap: {
    padding: 0,
    margin: 0,
  },
  mapContainer: (props) => ({
    height: props.fullHeight ? '100%' : '33vh',
    position: 'relative',
    '& .esri-ui-corner-container': {
      inset: '1px 3px 16px !important',
    },
  }),
});

import PropTypes from 'prop-types';
import React from 'react';
import { useStyles } from './styles';

export const WidgetContent = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.content}>{children}</div>;
};

WidgetContent.propTypes = {
  children: PropTypes.node.isRequired,
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  Tab,
  Typography,
  Tabs,
} from '@mui/material';
import { CirclePlusIcon, InfoIcon } from 'components/Icons';
import InspectionService from 'services/InspectionService';
import { DialogMessage } from 'components/DialogMessage';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import { useDispatch, useSelector } from 'react-redux';
import { getInspections } from 'slices/inspectionReducer';
import { TabPanel } from 'components/TabPanel';
import { getProjectInspections } from 'slices/projectInspectionReducer';
import { setProjectAssetInspections } from 'slices/projectAssetActiveInspectionReducer';
import { actions, inspectionType } from '../helpers';
import { NewField } from './NewField/NewField';
import { ActionButtons } from './ActionButtons';
import { FieldTable } from './FieldTable';
import { Header } from './Header';
import { IntialForm } from './IntialForm';
import { useStyles } from './styles';

export const SaveInspection = ({ onCancel, action, inspection, onConfirm, actionType, objectId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.inspections);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openAddFields, setOpenAddFields] = useState(false);
  const [tab, setTab] = useState(0);
  const [newField, setNewField] = useState(null);
  const [fields, setFields] = useState([]);
  const [questionTypes, setQuestionTypes] = useState([]);
  const { data } = useSelector((state) => state.projectAssetActiveInspections);
  const { fname, lname, userId: authId } = useSelector((state) => state.auth);
  const [userId, setUserId] = useState(null);
  const projectAsset = useSelector((state) => state.profileProjectAssets.projectAsset);
  const [isActive, setIsActive] = useState(inspection?.isActive);
  const { filters: projectFilters } = useSelector((state) => state.projectInspections);

  // reload data of inspections after created a new one
  const loadData = (inspec) => {
    if (inspec?.id && actionType === inspectionType.PROJECTASSET) {
      // update projectAssetInspection after updating it
      let projectAssetInspections = [...data];
      if (action === actions.UPDATE) {
        // update inspection inside project asset inspection
        projectAssetInspections = projectAssetInspections.map((item) => {
          if (item.inspection.id === inspec.id) {
            return {
              ...item,
              inspection: inspec,
            };
          }
          return item;
        });
      } else {
        // show a new one
        const projectAssetInspection = {
          ...inspec.projectAssetInspection,
          createdAt: new Date(),
          inspection: { ...inspec, ...(action === actions.CREATE && { isActive: true }) },
          uploader: { firstName: fname, lastName: lname, id: authId },
        };
        projectAssetInspection.assignment = userId?.value
          ? { id: new Date(), assignee: { ...userId, id: userId.value } }
          : null;
        projectAssetInspections.push(projectAssetInspection);
      }
      dispatch(setProjectAssetInspections(projectAssetInspections));
    } else if (actionType === inspectionType.PROJECT) {
      dispatch(getProjectInspections({ ...projectFilters, projectId: objectId, include: ['uploader', 'type'] }));
    } else if (action !== actions.DISABLED) {
      // reload inspections after create a new one and update in inspection page
      dispatch(getInspections(filters));
    }
  };

  // send data to API
  const saveInspection = async (values, checked) => {
    let params = {
      ...values,
      type: inspectionType.ACCOUNT,
      questions: fields,
    };
    delete params.addFields;
    setLoading(true);
    try {
      let auxInspection = null;
      if (action === actions.CREATE) {
        if (actionType) {
          params.type = actionType;
          params.id = objectId;
          if (actionType === inspectionType.PROJECTASSET) {
            params.type = inspectionType.PROJECT;
            params.pastId = projectAsset.id;
            params.userId = userId?.value;
          }
        }
        auxInspection = await InspectionService.createInspection(params);
      } else {
        params = {
          name: values.name,
          description: values.description,
          isActive: action !== actions.DISABLED ? values.isActive : checked,
          questions: fields,
          ...(actionType === inspectionType.PROJECTASSET && {
            pastId: inspection.projectAsset?.id,
            paiId: inspection.paiId,
            projectId: inspection.projectAsset?.project?.id,
          }),
          ...(actionType === inspectionType.PROJECT && {
            projectId: objectId,
          }),
        };
        auxInspection = await InspectionService.updateInspection(inspection?.id, params);
      }
      if (action === actions.DISABLED) setIsActive(checked);
      setLoading(false);
      loadData(auxInspection?.data?.data);
      onConfirm(true);
    } catch (err) {
      setLoading(false);
      setOpenDialog('An error occurred while trying to save the inspection.');
    }
  };

  // function to open add new fields
  const openAdd = async () => {
    setOpenAddFields(true);
    try {
      const auxQuestionTypes = await InspectionService.questionTypes();
      setQuestionTypes(
        auxQuestionTypes.data.data.map((item) => ({
          label: item.name,
          value: item.code,
          isMultiple: item.isMultiple,
        }))
      );
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: action === actions.CREATE ? '' : inspection?.name,
      description: action === actions.CREATE ? '' : inspection?.description,
      ...(action !== actions.CREATE && { isActive: inspection?.isActive }),
      ...(actionType === inspectionType.PROJECTASSET && { userId: [] }),
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name Required'),
      description: Yup.string().required('Description Required'),
    }),
    onSubmit(values) {
      // redirect to custom fields
      if (values.addFields && !openAddFields) {
        openAdd();
        setTab(1);
        // save inspection
      } else if (!openAddFields || (openAddFields && tab)) {
        saveInspection(values);
        // show second tab
      } else {
        setTab(1);
      }
    },
  });

  // get id for field
  const getId = () => new Date().getTime();

  // add new field to array
  const onHandleCreate = (item) => {
    let auxFields = [...fields];
    if (item.editAction) {
      auxFields = auxFields.map((field) => {
        if (field.id === item.id) {
          delete item.editAction;
          return { ...field, ...item };
        }
        return field;
      });
    } else {
      auxFields.push(item);
    }
    setFields(auxFields);
    setNewField(null);
  };

  // remove field to array
  const removeField = (fieldToRemove) => {
    const auxFields = [...fields];
    const index = auxFields.findIndex((item) => fieldToRemove.id === item.id);
    if (index !== -1) {
      auxFields[index].deletedAt = new Date();
      if (newField?.editAction) setNewField(null);
    }
    setFields(auxFields);
  };

  // reorder fields in table
  const reoderderFields = (index, type) => {
    const auxFields = [...fields];
    if (type === 'up') {
      if (index > 0 && index < auxFields.length) {
        const field = auxFields[index];
        auxFields[index] = auxFields[index - 1];
        auxFields[index - 1] = field;
        setFields(auxFields);
      }
    } else if (index >= 0 && index < auxFields.length - 1) {
      const field = auxFields[index];
      auxFields[index] = auxFields[index + 1];
      auxFields[index + 1] = field;
      setFields(auxFields);
    }
  };

  const { handleSubmit } = formik;

  useEffect(() => {
    try {
      const auxFields = JSON.parse(inspection?.questions);
      if (action !== actions.CREATE && auxFields.length && action !== actions.DISABLED) {
        setFields(auxFields);
        openAdd();
      }
    } catch (error) {
      setFields([]);
    }
    if (action === actions.DISABLED) setIsActive(inspection?.isActive);
  }, []);

  const IntialFormContainer = () => (
    <IntialForm
      formik={formik}
      openAddFields={openAddFields}
      loading={loading}
      action={action}
      actionType={actionType}
      userId={userId}
      setUserId={setUserId}
    />
  );

  return (
    <>
      {action !== actions.DISABLED ? (
        <Dialog
          open
          disableEscapeKeyDown
          maxWidth={openAddFields ? 'md' : 'xs'}
          classes={openAddFields ? { paper: classes.changeBack } : ''}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') onCancel();
          }}
        >
          {!openAddFields && (
            <DialogTitle>
              <Header action={action} openAddFields={openAddFields} tab={tab} />
            </DialogTitle>
          )}
          <DialogContent>
            {!openAddFields ? (
              IntialFormContainer()
            ) : (
              <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                  <div className={classes.form}>
                    <div>
                      <Header action={action} openAddFields={openAddFields} tab={tab} />
                      <Tabs value={tab} className={classes.headerTabs}>
                        <Tab label="Default Fields" onClick={() => setTab(0)} />
                        <Tab label="Specialized Fields" onClick={!tab ? handleSubmit : () => {}} />
                      </Tabs>
                      <TabPanel value={tab} index={0}>
                        {IntialFormContainer()}
                      </TabPanel>
                      <TabPanel value={tab} index={1}>
                        <FieldTable
                          fields={fields}
                          reoderderFields={reoderderFields}
                          setNewField={setNewField}
                          removeField={removeField}
                        />
                      </TabPanel>
                    </div>
                    <div className={classes.buttonActions}>
                      <div className="actionsContent">
                        {tab === 1 && (
                          <Grid container spacing={0}>
                            <Grid item xs={12} align="right">
                              <Button
                                color="secondary"
                                variant="contained"
                                onClick={() =>
                                  !newField?.id || newField?.editAction ? setNewField({ id: getId() }) : () => {}
                                }
                                style={{ marginTop: 8 }}
                              >
                                <CirclePlusIcon size={18} /> <Typography ml={0.2}>Add New</Typography>
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                        <br />
                        <ActionButtons
                          openAddFields={openAddFields}
                          tab={tab}
                          loading={loading}
                          onCancel={() => onCancel()}
                          handleSubmit={handleSubmit}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div className={classes.fields}>
                    {newField?.id ? (
                      <NewField field={newField} questionTypes={questionTypes} onHandleCreate={onHandleCreate} />
                    ) : (
                      <Typography className={classes.grayText} variant="h5">
                        Note: Default fields have assigned locations on the Inspection page. When creating specialized
                        fields, you will have the ability to set the fields’ order on the page.
                      </Typography>
                    )}
                  </div>
                </Grid>
              </Grid>
            )}
            <LoadingOverlay loading={loading} />
          </DialogContent>
          {!openAddFields && (
            <DialogActions>
              <ActionButtons
                openAddFields={openAddFields}
                tab={tab}
                loading={loading}
                onCancel={() => onCancel()}
                handleSubmit={handleSubmit}
              />
            </DialogActions>
          )}
        </Dialog>
      ) : (
        <FormControlLabel
          control={<Switch color="secondary" />}
          disabled={loading}
          value="isActive"
          onChange={(event) => {
            saveInspection(formik.values, event.target.checked);
          }}
          checked={isActive}
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        />
      )}
      <LoadingOverlay loading={loading} />
      <DialogMessage
        title={openDialog}
        isOpen={openDialog}
        icon={InfoIcon}
        confirmText="Ok"
        onConfirm={() => {
          setOpenDialog(false);
        }}
      />
    </>
  );
};

SaveInspection.propTypes = {
  onCancel: PropTypes.func,
  action: PropTypes.string.isRequired,
  inspection: PropTypes.object,
  onConfirm: PropTypes.func,
  actionType: PropTypes.string,
  objectId: PropTypes.string,
};

SaveInspection.defaultProps = {
  inspection: null,
  onConfirm: () => {},
  onCancel: () => {},
  actionType: '',
  objectId: '',
};

import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  item: (props) => ({
    listStyleType: 'none',
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: props.active ? '' : theme.palette.primary.light,
    },
    backgroundColor: props.active ? theme.palette.secondary.main : '',
    height: '48px',
    width: '48px',
    dsplay: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  itemLink: {
    textDecoration: 'none',
    color: 'white',
    fontSize: '17px',
    fontWeight: '700',
    display: 'flex',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    cursor: 'pointer',
  },
  styledIconWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60px',
    textAlign: 'center',
    color: 'white',
  },
});

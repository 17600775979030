import { AttachedFile } from 'components/AttachedFile';
import { ContextMenu } from 'components/ContextMenu';
import { ContextMenuList } from 'components/ContextMenuItems';
import { InfoIcon, ObservationIcon, PhotoIcon } from 'components/Icons';
import { Loader } from 'components/Loader';
import { resolveUserInitials } from 'lib/resolve-user-initials';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Moment from 'react-moment';
// import Img from 'react-optimized-image';
import { v4 } from 'uuid';
import { ContextMenuButton } from 'components/ContextMenuButton';
import {
  AvatarAdds,
  AvatarRow,
  CardButtons,
  CardContainer,
  CardContent,
  CardDetails,
  CardFileContainer,
  CardFooter,
  CardHeader,
  CardImage,
  CardProcessingText,
  CardTitle,
  InfoContainer,
  StyledAvatar,
} from './styles';

export const ProjectCard = ({
  image,
  projectName,
  projectCreator,
  isTranscodingComplete,
  isUploadComplete,
  creatorUpdate,
  projectDate,
  contextMenuItems,
  panelHandler,
  avatars,
  attachedFiles,
  navigateToContent,
  testId,
  openSideBar,
  selectedRow,
  originProject,
  observations,
}) => {
  const plusMoreAvatars = `+${avatars.length - 3}`;
  const plusAvatar = `+`;
  const [containerSelected, setContainerSelected] = useState(false);

  const handleContainerClick = () => {
    setContainerSelected(!containerSelected);
  };

  return (
    <CardContainer data-testid={testId} className={selectedRow === testId && 'selected'} onClick={handleContainerClick}>
      <CardHeader>
        {isTranscodingComplete && isUploadComplete && (
          <CardImage
            src={image}
            alt={`${projectName} image`}
            onClick={() => {
              panelHandler();
            }}
          />
        )}
        {(!isTranscodingComplete || !isUploadComplete) && (
          <>
            <Loader height={100} loading />
            <CardProcessingText>Content Processing</CardProcessingText>
          </>
        )}
        <InfoContainer onClick={() => openSideBar()}>
          <InfoIcon size={17} />
        </InfoContainer>
      </CardHeader>

      <CardContent>
        <ContextMenu>
          <ContextMenuButton />
          <ContextMenuList position="bottom-right">{contextMenuItems}</ContextMenuList>
        </ContextMenu>
        <CardTitle
          variant="h3"
          as="h3"
          onClick={() => {
            navigateToContent();
          }}
        >
          {projectName}
        </CardTitle>
        {projectDate && (
          <CardDetails variant="body" as="p">
            created <Moment format="MMM Do, YYYY">{projectDate}</Moment>
          </CardDetails>
        )}
        {projectCreator && (
          <CardDetails variant="body" as="p">
            by {projectCreator}
          </CardDetails>
        )}
        {creatorUpdate && (
          <CardDetails variant="body" as="p">
            {creatorUpdate}
          </CardDetails>
        )}
        {originProject && (
          <CardDetails variant="body" as="p">
            Origin Project: {originProject}
          </CardDetails>
        )}
        <CardFooter>
          <AvatarRow>
            {avatars.slice(0, 3).map((user) => (
              <StyledAvatar
                image={user.image}
                key={`${v4()}${new Date().getMilliseconds()}`}
                initials={resolveUserInitials({ firstName: user.firstName, lastName: user.lastName })}
                size="28"
              />
            ))}
            {avatars.length <= 12 ? (
              avatars.length > 3 && <AvatarAdds initials={plusMoreAvatars} size={28} />
            ) : (
              <AvatarAdds initials={plusAvatar} size={28} />
            )}
          </AvatarRow>
          <CardButtons>
            <CardFileContainer>
              <AttachedFile icon={ObservationIcon} number={observations || '0'} />
            </CardFileContainer>
          </CardButtons>
          <CardButtons>
            <CardFileContainer>
              {attachedFiles && Array.isArray(attachedFiles) ? (
                attachedFiles.map((file) => (
                  // we will return the new Component we will create
                  <AttachedFile
                    icon={file.icon}
                    number={file.numberOfFiles}
                    iconColor={file.iconColor}
                    key={file.fileId}
                  />
                ))
              ) : (
                <AttachedFile icon={PhotoIcon} number={attachedFiles || '0'} />
              )}
            </CardFileContainer>
          </CardButtons>
        </CardFooter>
      </CardContent>
    </CardContainer>
  );
};

ProjectCard.propTypes = {
  /**
   this is the image on the project card
  */
  image: PropTypes.node,
  /**
      !!! isRequired
      this is the name of the project that will be displayed on the card
  */
  projectName: PropTypes.string.isRequired,
  originProject: PropTypes.string.isRequired,
  /**
      !!! isRequired
      this is the name of the user or team that created the project
  */
  projectCreator: PropTypes.string.isRequired,

  creatorUpdate: PropTypes.string,
  /**
      !!! isRequired
      this is the date on when the project was created
  */
  projectDate: PropTypes.string.isRequired,
  /**
      This function will be passed to the InfoPanel button on the card to touch
      the right sidebar panel from the card
  */
  panelHandler: PropTypes.func,
  /**
      the onClick function to handle parent buttons
  */
  /**
      Data-testid to test the specific component of ProjectCard
  */
  testId: PropTypes.string,

  contextMenuItems: PropTypes.arrayOf(
    /**
   this is will hold the menu item on the Project Card
  */
    PropTypes.node
  ),
  avatars: PropTypes.arrayOf(
    /**
   this is the user icon on the project card
  */
    PropTypes.shape({
      img: PropTypes.string,
      alt: PropTypes.string,
      initials: PropTypes.string,
    })
  ).isRequired,
  /**
   this is the attached file on the project card
  */
  attachedFiles: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.nod,
      numberOfFiles: PropTypes.number,
      iconColor: PropTypes.oneOf(['default', 'disallowed', 'allowed']),
    })
  ),
  navigateToContent: PropTypes.func,
  isTranscodingComplete: PropTypes.bool,
  isUploadComplete: PropTypes.bool,
  openSideBar: PropTypes.func,
  selectedRow: PropTypes.string,
  observations: PropTypes.number,
};

ProjectCard.defaultProps = {
  image: null,
  testId: 'ProjectCard',
  panelHandler: () => {},
  navigateToContent: () => {},
  contextMenuItems: [],
  attachedFiles: [{ iconColor: 'default' }],
  creatorUpdate: '',
  isTranscodingComplete: false,
  isUploadComplete: false,
  openSideBar: () => {},
  selectedRow: null,
  observations: 0,
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Form } from 'lib/form';
import * as Yup from 'yup';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import ProjectService from 'services/ProjectService';
import { useDispatch, useSelector } from 'react-redux';
import { DialogMessage } from 'components/DialogMessage';
import { getUsers } from 'slices/userReducer';
import { fetchTeams } from 'slices/adminTeamsReducer';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Typography,
} from '@mui/material';
import { InfoIcon } from 'components/Icons';
import { DisclaimerExporting } from 'components/DisclaimerExporting/DisclaimerExporting';
import { FormTextArea } from 'components/FormTextArea';
import { EmailDialog } from './EmailDialog';

const validationSchema = Yup.object({
  email: Yup.string().required('Email is required'),
  message: Yup.string().min(1).required('Message is required'),
});

export const ObservationEmail = ({ observation, screenshot, handleClose }) => {
  const dispatch = useDispatch();
  const {
    projectAsset: { projId },
  } = useSelector((state) => state.profileProjectAssets);
  const [loading, setLoading] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const { filters: userFilers } = useSelector((state) => state.users);
  const usersList = useSelector((state) => state.users.data);
  const teamsList = useSelector((state) => state.adminTeams.teams);
  const [emails, setEmails] = useState({ emails: [], users: [], teams: [] });
  // eslint-disable-next-line no-unused-vars
  const users = usersList.map((user) => ({
    label: `${user.firstName} ${user.lastName}`,
    value: user.email,
  }));
  const teams = teamsList.map((team) => ({
    label: team.name,
    value: team.id,
  }));

  // -------------------------- convert image to a blob
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  // ----------------------------------- send data to API
  const submitFormData = async (values) => {
    const proId = !projId ? observation.projectId : projId;
    setLoading(true);
    try {
      const singUrl = await ProjectService.singUrlObservationPoint(proId, observation.id);
      await ProjectService.uploadObservationPointAsset(singUrl.data.data, dataURItoBlob(screenshot));
      await ProjectService.emailObservationPoint(proId, observation.id, {
        emails: [...emails.emails, ...emails.users],
        teams: emails.teams.map((item) => item.value),
        ...values,
        projectId: proId,
      });
      setLoading(false);
      handleClose();
    } catch (err) {
      setDialogMessage('An error occurred while trying to send the observation by email.');
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      message: '',
    },
    validationSchema,
    onSubmit(values) {
      submitFormData(values);
    },
  });

  const { values, handleChange, handleBlur, handleSubmit } = formik;

  // -------------------------- add emails
  const addEmails = (label, value) => {
    let val = value;
    const auxEmails = { ...emails };
    const array = [...auxEmails[label]];
    if (label === 'users' || label === 'teams') {
      const find = array.find((element) => element.value === value);
      if (find?.value) return;
      val = (label === 'users' ? users : teams).find((element) => element.value === value);
      if (!val?.value) return;
    }
    if (label === 'emails') {
      const find = array.find((element) => element.value === value.value);
      if (find) return;
    }
    auxEmails[label].push(val);
    setEmails({ ...auxEmails });
    formik.handleChange('email')(
      auxEmails.emails.length > 0 || auxEmails.teams.length > 0 || auxEmails.users.length > 0 ? 'true' : ''
    );
  };

  // -------------------------- remove emails
  const removeEmails = (label, value) => {
    const auxEmails = { ...emails };
    const array = [...auxEmails[label]];
    let find = null;
    find = array.findIndex((element) => element.value === value);
    if (find === null) return;
    array.splice(find, 1);
    auxEmails[label] = [...array];
    setEmails({ ...auxEmails });
    formik.handleChange('email')(
      auxEmails.emails.length > 0 && auxEmails.teams.length > 0 && auxEmails.users.length > 0 ? 'true' : ''
    );
  };

  useEffect(() => {
    const sendFilters = {
      ...userFilers,
      sort_by: Object.values(userFilers.sort_by),
      page_size: 500,
    };
    dispatch(getUsers({ ...sendFilters, include: [] }));
    dispatch(fetchTeams({ page_size: 1000 }));
  }, []);

  return (
    <>
      <Dialog open onClose={handleClose} maxWidth="md">
        <DialogTitle onClose={handleClose}>
          <Typography variant="h4">EMAIL OBSERVATION</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <EmailDialog
                list={teams}
                emails={emails}
                type="team"
                handleChangeEmail={addEmails}
                handleRemove={removeEmails}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <EmailDialog
                list={users}
                emails={emails}
                type="user"
                handleChangeEmail={addEmails}
                handleRemove={removeEmails}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <EmailDialog
                list={users}
                emails={emails}
                type="email"
                handleChangeEmail={addEmails}
                handleRemove={removeEmails}
              />
            </Grid>
          </Grid>
          {!formik.values.email && formik.touched.email && (
            <FormControl error={!formik.values.email && formik.touched.email} fullWidth>
              <FormHelperText>Email is required</FormHelperText>
            </FormControl>
          )}
          <br />
          <InputLabel>
            <Typography mt={0.5}>Custome Message *</Typography>
          </InputLabel>
          <FormControl fullWidth>
            <FormTextArea
              placeholder="Custome Message"
              rows="5"
              value={values.message}
              id="message"
              name="message"
              {...Form.fieldErrorHelper(formik, 'message')}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={loading}
              required
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleClose}
                disabled={loading}
                size="large"
                fullWidth
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                color="secondary"
                variant="contained"
                disabled={loading}
                onClick={handleSubmit}
                size="large"
                fullWidth
              >
                Send
              </Button>
            </Grid>
            <Grid item xs={12}>
              <DisclaimerExporting />
            </Grid>
          </Grid>
        </DialogActions>
        <LoadingOverlay loading={loading} />
        <DialogMessage
          title={dialogMessage}
          isOpen={dialogMessage}
          icon={InfoIcon}
          confirmText="Ok"
          onConfirm={() => {
            setDialogMessage(false);
          }}
        />
      </Dialog>
    </>
  );
};

ObservationEmail.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  observation: PropTypes.any,
  screenshot: PropTypes.string,
  handleClose: PropTypes.func,
};

ObservationEmail.defaultProps = {
  observation: { id: null },
  screenshot: '',
  handleClose: () => {},
};

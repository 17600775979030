import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const SearchIcon = ({ size }) => (
  <Svg viewBox="0 0 24 24" size={size}>
    <path
      d="M22.293 23.707a1 1 0 0 0 1.414-1.414l-1.414 1.414Zm-3.903-6.73a1 1 0 0 0-1.414 1.413l1.414-1.414Zm1.166-6.2a8.778 8.778 0 0 1-8.778 8.779v2c5.952 0 10.778-4.826 10.778-10.778h-2Zm-8.778 8.779A8.778 8.778 0 0 1 2 10.778H0c0 5.952 4.825 10.778 10.778 10.778v-2ZM2 10.778A8.778 8.778 0 0 1 10.778 2V0C4.825 0 0 4.825 0 10.778h2ZM10.778 2a8.778 8.778 0 0 1 8.778 8.778h2C21.556 4.825 16.73 0 10.778 0v2Zm12.93 20.293-5.318-5.317-1.414 1.414 5.317 5.317 1.414-1.414Z"
      fill="currentColor"
    />
  </Svg>
);

SearchIcon.propTypes = {
  size: PropTypes.number,
};

SearchIcon.defaultProps = {
  size: 24,
};

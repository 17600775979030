import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ArcGISMap from '@arcgis/core/Map';
import { webMercatorToGeographic } from '@arcgis/core/geometry/support/webMercatorUtils';
import MapView from '@arcgis/core/views/MapView';
import Expand from '@arcgis/core/widgets/Expand';
import Search from '@arcgis/core/widgets/Search';
import Graphic from '@arcgis/core/Graphic';
import BasemapGallery from '@arcgis/core/widgets/BasemapGallery';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import { createMapGeoFeatureLayer, fetchMapGeoData } from 'components/MapFeatureLayer/MapFeatureLayer';
import { TrackZoom } from 'components/TrackZoom/TrackZoom';
import { Loader } from 'components/Loader';
import markerImage from './marker.png';
import selectedMarkerImage from '../MapFeatureLayer/markerNoAngle_26x26.png';
import { useStyles } from './styles';
import * as localForage from 'localforage';
import ProjectService from 'services/ProjectService';

export const simpleMapSection = {
  PROJACTIVEASSETINSPECTIONS: 'proj_active_asset_inspections',
  PROJECTASSETS: 'project_assets',
};

export const MapSimplePoint = ({
  project,
  selectedAsset,
  handleRowClick,
  onClickGetExtent,
  getExtent,
  section,
  // arcgis,
}) => {
  const classes = useStyles();
  const mapDiv = useRef(null);
  const reduce =
    section === simpleMapSection.PROJACTIVEASSETINSPECTIONS ? 'projectAssetActiveInspections' : 'profileProjectAssets';
  const { data, isAssetLoading, refetchLoading } = useSelector((state) => state[reduce]);
  const mapGeoLayer = useMemo(() => new GraphicsLayer({ id: 'mainLine' }), []);
  const polylineGraphicLayer = useMemo(() => new GraphicsLayer({ id: 'mainPoints' }), []);
  const [viewMap, setViewMap] = useState(null);
  const [searchMap, setSearchMap] = useState(null);
  const [track, setTrack] = useState(true);
  const zoom = 14;

  const currentSymbol = (assetId) => ({
    type: 'picture-marker',
    url: selectedAsset?.id === assetId ? selectedMarkerImage : markerImage,
    width: selectedAsset?.id === assetId ? '12px' : '20px',
    height: selectedAsset?.id === assetId ? '12px' : '20px',
  });

  // create initial point
  const createPoints = () => {
    const points = [];
    localForage.getItem('webmap', (err, value) => {
      // Call latitude, longitude endpoint
      const arcgis = ProjectService.getArcgisLatLon(value?.arcgisMapId);
      if (arcgis && arcgis.length)
        arcgis.map((item) => {
          const mapPoint = {
            type: 'point',
            longitude: item?.y,
            latitude: item?.x,
          };
          points.push(
            new Graphic({
              geometry: mapPoint,
              symbol: currentSymbol(value?.arcgisMapId),
              attributes: item,
            })
          );
          return item;
        });
    });
    if (section === simpleMapSection.PROJACTIVEASSETINSPECTIONS) {
      data.forEach((inspection) => {
        if (inspection?.projectAsset?.sequence?.spatialPoint?.id) {
          const mapPoint = {
            type: 'point',
            longitude: inspection?.projectAsset?.sequence?.spatialPoint.longitude,
            latitude: inspection?.projectAsset?.sequence?.spatialPoint.latitude,
          };
          points.push(
            new Graphic({
              geometry: mapPoint,
              symbol: currentSymbol(inspection?.projectAsset?.id),
              attributes: inspection?.projectAsset,
            })
          );
        }
      });
    } else {
      data.forEach((asset) => {
        if (asset?.sequence?.spatialPoint?.id) {
          const mapPoint = {
            type: 'point',
            longitude: asset?.sequence?.spatialPoint.longitude,
            latitude: asset?.sequence?.spatialPoint.latitude,
          };
          points.push(
            new Graphic({
              geometry: mapPoint,
              symbol: currentSymbol(asset?.id),
              attributes: asset,
            })
          );
        }
      });
    }
    return points;
  };

  // -------------------- When the Map finishes loading, zoom to the video point path
  const defaultZoom = (view, featureLayerData) => {
    setTrack(true);
    let target = {};
    if (polylineGraphicLayer?.graphics?.length < 2) {
      target = { target: polylineGraphicLayer.graphics, zoom };
    } else {
      target = { target: polylineGraphicLayer.graphics };
    }
    view.when(
      view
        .goTo(target, { animate: true, easing: 'ease', duration: 0 })
        .then(() => fetchMapGeoData(featureLayerData))
        .catch((error) => {
          if (error.name !== 'AbortError') {
            // eslint-disable-next-line
            console.error(error);
          }
        })
    );
  };

  // ---------------------- track zoom function
  const handleTrackZoomClick = () => {
    if (viewMap !== null) {
      // ------------------- get data to get lines and poles
      const featureLayerData = {
        view: viewMap,
        mapGeoFeatureLayer: null,
        mapGeoFeatureLayerLines: null,
        mapGeoLayer,
        searchWidget: searchMap,
        geometryOrgId: project?.orgId,
        includeAccountGeometry: false,
      };
      viewMap.map.layers.forEach((layer) => {
        switch (layer.id) {
          case 'inital-points-1':
            featureLayerData.mapGeoFeatureLayer = layer;
            break;
          case 'inital-lines-1':
            featureLayerData.mapGeoFeatureLayerLines = layer;
            break;
          default:
            break;
        }
      });
      // -------------- default zoom
      setTrack(true);
      defaultZoom(viewMap, featureLayerData);
    }
  };

  // show initial point of sequence
  useEffect(() => {
    polylineGraphicLayer.removeAll();
    if (data?.length) {
      polylineGraphicLayer.addMany(createPoints());
      handleTrackZoomClick();
    }
  }, [data, selectedAsset]);

  // load map
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (mapDiv.current) {
      /**
       * Initialize application
       */
      const map = new ArcGISMap({
        basemap: 'hybrid',
      });

      // const home = new Home();

      const view = new MapView({
        map,
        container: mapDiv.current,
      });
      setViewMap(view);
      // create the map selector
      const basemapGallery = new BasemapGallery({
        view,
        container: document.createElement('div'),
      });

      const bgExpand = new Expand({
        view,
        content: basemapGallery.container,
        expandIconClass: 'esri-icon-basemap',
      });

      // ------------------------ add the feature layer to the map
      const mapGeoFeatureLayer = createMapGeoFeatureLayer('point', { id: 'inital-points-1' });
      const mapGeoFeatureLayerLines = createMapGeoFeatureLayer('polyline', { id: 'inital-lines-1' });
      map.add(mapGeoFeatureLayerLines);
      map.add(mapGeoLayer);
      map.add(mapGeoFeatureLayer, 0);
      map.add(polylineGraphicLayer);

      const searchWidget = new Search({
        view,
      });
      view.ui.add(searchWidget, {
        position: 'top-right',
        index: 2,
      });
      setSearchMap(searchWidget);

      // ----------------------------------------------- zoom in point after searching
      searchWidget.goToOverride = (view2, goToParams) => {
        goToParams.options.duration = 300;
        const target = {
          ...goToParams.target,
          ...(goToParams.target.target?.attributes?.ObjectID &&
            goToParams.target.target?.layer?.uid === mapGeoFeatureLayer.uid && { zoom }),
        };
        return view.goTo(target, goToParams.options);
      };

      view.ui.add([bgExpand], 'top-left');

      const featureLayerData = {
        view,
        mapGeoFeatureLayer,
        mapGeoFeatureLayerLines,
        mapGeoLayer,
        searchWidget,
        geometryOrgId: project?.orgId,
        includeAccountGeometry: false,
      };

      const convertExtentToCoords = (extent) => {
        const { xmin, ymin, xmax, ymax } = webMercatorToGeographic(extent);
        const newExtentCoords = [
          {
            lat: ymax,
            long: xmin,
          },
          {
            lat: ymax,
            long: xmax,
          },
          {
            lat: ymin,
            long: xmax,
          },
          {
            lat: ymin,
            long: xmin,
          },
        ];
        onClickGetExtent(newExtentCoords, extent);
      };

      view.on('drag', (event) => {
        if (event.action === 'end') {
          setTrack(false);
          // get extent
          if (getExtent) {
            convertExtentToCoords(view.extent);
          }
          // -------------------- call map geometry data
          fetchMapGeoData(featureLayerData);
        }
      });

      view.on('mouse-wheel', () => {
        setTrack(false);
        // get extent
        if (getExtent) {
          convertExtentToCoords(view.extent);
        }
        // -------------------- call map geometry data
        fetchMapGeoData(featureLayerData);
      });

      view.on('pointer-move', (event) => {
        view.hitTest(event).then((response) => {
          if (response.results.length > 0) {
            document.getElementById('mapContainer').style.cursor = 'pointer';
          } else {
            document.getElementById('mapContainer').style.cursor = 'default';
          }
        });
      });

      // -------------- default zoom
      defaultZoom(view, featureLayerData);

      // click on initial points
      view.on('click', (event) => {
        view.hitTest(event).then((response) => {
          // do something with the result graphic
          const { graphic } = response.results[0];
          if (graphic?.attributes && !isAssetLoading) {
            handleRowClick(graphic?.attributes);
          }
        });
      });

      return () => {
        polylineGraphicLayer.removeAll();
        map.remove(polylineGraphicLayer);
        map.remove(mapGeoFeatureLayerLines);
        map.remove(mapGeoLayer);
        map.remove(mapGeoFeatureLayer);
      };
    }
  }, []);

  return (
    <div className={classes.container}>
      {refetchLoading && <Loader loading size={32} />}
      <TrackZoom handleTrackZoomClick={handleTrackZoomClick} active={track} />
      <div className={classes.map} id="mapContainer" ref={mapDiv} />
    </div>
  );
};

MapSimplePoint.propTypes = {
  project: PropTypes.object.isRequired,
  getExtent: PropTypes.bool,
  handleRowClick: PropTypes.func.isRequired,
  section: PropTypes.string,
  selectedAsset: PropTypes.object,
  onClickGetExtent: PropTypes.func,
  // arcgis: PropTypes.object,
};

MapSimplePoint.defaultProps = {
  getExtent: false,
  onClickGetExtent: () => {},
  section: simpleMapSection.PROJECTASSETS,
  selectedAsset: {},
  // arcgis: {},
};

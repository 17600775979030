import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, Grid, InputLabel, TextField } from '@mui/material';
import { ShowNewField } from './ShowNewField';

export const NewField = ({ field, questionTypes, onHandleCreate }) => {
  const [type, setType] = useState(null);

  useEffect(() => {
    setType(
      field.editAction
        ? { value: field.code, label: field.fieldName, isMultiple: field.isMultiple, editAction: true }
        : null
    );
  }, [field]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <InputLabel>Field Type</InputLabel>
          <Autocomplete
            value={type}
            disableClearable
            options={questionTypes}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => <TextField {...params} required placeholder="Select type of field" />}
            onChange={(e, value) => setType(value?.value ? value : '')}
          />
        </Grid>
        {type?.value && <ShowNewField field={field} onHandleCreate={onHandleCreate} type={type} />}
      </Grid>
    </>
  );
};

NewField.propTypes = {
  field: PropTypes.object.isRequired,
  questionTypes: PropTypes.array.isRequired,
  onHandleCreate: PropTypes.func.isRequired,
};

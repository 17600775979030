import React from 'react';
import { Typography } from 'components/Typography';
import { DocumentIcon, PhotoIcon, VideoIcon, LoadIcon } from 'components/Icons';
import { ColumnContainer, NameContainer, StyledSubText } from '../styles';

export const TableHeaders = [
  {
    Header: 'Title',
    id: 'name',
    accessor: (rowData) => {
      const { InputVideoLocation, HasError, isTranscodingComplete } = rowData;
      return (
        <NameContainer>
          <ColumnContainer>
            {/* eslint-disable-next-line no-nested-ternary */}
            {InputVideoLocation.split('.')[1] === 'mp4' ? (
              <VideoIcon />
            ) : // eslint-disable-next-line no-nested-ternary
            InputVideoLocation.split('.')[1] === 'jpeg' ? (
              <PhotoIcon />
            ) : !HasError && isTranscodingComplete ? (
              <DocumentIcon />
            ) : (
              <LoadIcon />
            )}
          </ColumnContainer>
          <div>
            <Typography>{InputVideoLocation.split('.')[1]}</Typography>
            <StyledSubText>{InputVideoLocation.split('.')[1] === 'mp4'}</StyledSubText>
          </div>
        </NameContainer>
      );
    },
  },
  {
    Header: 'ID',
    id: 'ID',
    accessor: (rowData) => {
      const { ID } = rowData;
      return ID || 'No ID Found.';
    },
  },
  {
    Header: 'Input Asset Location',
    id: 'InputVideoLocation',
    accessor: (rowData) => {
      const { InputVideoLocation } = rowData;
      return InputVideoLocation || 'Input Location not found';
    },
  },
  {
    Header: 'Has been removed',
    id: 'RemovedAsset',
    accessor: (rowData) => {
      const { RemovedFromProjects } = rowData;
      return RemovedFromProjects === true ? 'Yes' : 'No';
    },
  },
  {
    Header: 'Telemetry Location',
    id: 'TelemetryLocation',
    accessor: (rowData) => {
      const { TelemetryLocation } = rowData;
      return TelemetryLocation || 'Telemetry not found';
    },
  },
  {
    Header: 'Error',
    id: 'WhatIsTheError',
    accessor: (rowData) => {
      const { WrongTelemetry, ImageCorrupted } = rowData;
      /* eslint-disable */
      return WrongTelemetry
        ? 'The telemetry file is missing or corrupt.'
        : ImageCorrupted
        ? 'The image or video is corrupt.'
        : '';
    },
  },
  {
    Header: 'Filename',
    id: 'SrcFilename',
    accessor: (rowData) => {
      const { src_filename } = rowData; // eslint-disable-line
      return src_filename || ''; // eslint-disable-line
    },
  },
];

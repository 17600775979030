import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'components/Loader';
import { Map } from 'components/Map/Map';
import { Typography } from 'components/Typography';
// import { Button } from 'components/Button';
// import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { VideoPlayer } from 'components/VideoPlayer';
import { ImagePlayer } from 'components/VideoPlayer/ImagePlayer';
import { setProjectAsset, setSelectedSequence } from 'slices/profileProjectAssetReducer';
import { setObservations } from 'slices/observationsReducer';
// import { goBack } from 'lib/route';
import { Grid, IconButton } from '@mui/material';
import { ArrowLeftIcon, ArrowRightIcon } from 'components/Icons';
import { SaveProjectAssetInspectionProgress } from 'components/Inspections/ProjectInspections/SaveProjectAssetInspectionProgress';
import { assetType } from 'components/Inspections/helpers';
import { useStyles } from './styles';

const mapWidth = 35;
const videoWidth = 100 - mapWidth;

export const AssetContent = ({ projectId }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const {
    projectAsset,
    isAssetLoading,
    player: { selectedSequence, ovservationDialogOpen },
  } = useSelector((state) => state.profileProjectAssets);
  // const history = useHistory();
  const playerRef = React.useRef(null);
  const dispatch = useDispatch();
  const [points, setPoints] = useState([]);
  const [shouldShowVideoPlayer, setShouldShowVideoPlayer] = useState(false);
  const [imageProgress, setImageProgress] = useState({});

  const changeSequence = (auxSequence) => {
    if (playerRef.current && ovservationDialogOpen === false) {
      const sequence = points[auxSequence];
      playerRef.current.markers.reset(
        sequence?.observations?.map((obs) => ({
          time: Number(obs.time),
          text: obs.name,
          id: obs.id,
        }))
      );
    }
  };

  const handlePreviousSequence = () => {
    if (selectedSequence !== 0) {
      setIsLoading(true);
      const auxSequence = selectedSequence - 1;
      dispatch(setSelectedSequence(auxSequence));
      changeSequence(auxSequence);
    }
  };

  const handleNextSequence = () => {
    if (points.length - 1 !== selectedSequence) {
      setIsLoading(true);
      const auxSequence = selectedSequence + 1;
      dispatch(setSelectedSequence(auxSequence));
      changeSequence(auxSequence);
    }
  };

  useEffect(() => {
    if (projectAsset?.points?.length && projectAsset?.points[selectedSequence]) {
      setImageProgress(projectAsset.points[selectedSequence]);
    }
  }, [selectedSequence]);

  useEffect(() => {
    setPoints([]);
  }, []);

  useEffect(() => {
    if (projectAsset?.sequences && projectAsset?.sequences?.length > 0) {
      let auxPoints = [];
      projectAsset.sequences.forEach((seq) => {
        auxPoints = [
          ...auxPoints,
          ...seq.spatialPoints.map((point) => ({
            id: seq.ordinal,
            spatialPointId: point.id,
            time: point.time,
            sequenceIndex: seq.ordinal,
            location: [point.longitude, point.latitude],
            heading: point.hasHeading ? point.heading : 0,
            assets: seq.asset,
            sequenceId: seq.id,
            observations: seq.observations,
            sequence: seq,
          })),
        ];
      });

      if (auxPoints.length <= 0) {
        auxPoints = [{ id: 0, location: [0, 0] }];
      } else {
        auxPoints = auxPoints.sort((a, b) => a.id - b.id);
      }
      setPoints(auxPoints);
      dispatch(setProjectAsset({ ...projectAsset, points: auxPoints }));
      const auxshouldShowVideoPlayer = projectAsset.sequences[0]?.asset.type === 'VIDEO';
      setShouldShowVideoPlayer(auxshouldShowVideoPlayer);
      if (!auxshouldShowVideoPlayer) {
        const auxObservations = [];
        auxPoints.forEach((element) => {
          element?.observations?.forEach((item) => {
            if (element.sequenceId === item.projectAssetSequenceId) {
              const obj = { ...item };
              obj.sequence = element.sequence;
              obj.projectId = projectAsset?.project?.id;
              obj.asset = projectAsset;
              auxObservations.push(obj);
            }
          });
        });
        dispatch(setObservations(auxObservations));
        setImageProgress(auxPoints[0]);
        // -------------------- get sequence id from url
        const params = new URLSearchParams(window.location.search);
        const sequenceId = params.get('sequenceId');
        const foundSequenceId = auxPoints.find((item) => sequenceId === item?.sequence?.id);
        if (sequenceId && foundSequenceId?.id) {
          dispatch(setSelectedSequence(foundSequenceId?.id));
        }
      }
    }
  }, [projectAsset?.sequences]);

  if (isAssetLoading) {
    return <Loader loading={isAssetLoading} height={50} />;
  }

  return (
    <>
      {/* <Button spacingBottom={0.5} kind="secondary" size="small" onClick={() => goBack(history, -2)}>
        Back to Project
      </Button> */}
      <Grid container spacing={0} className={classes.fullHeight}>
        {isLoading && false && (
          <div
            style={{
              position: 'absolute',
              width: `${videoWidth}%`,
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}
          >
            <Loader loading height={250} />
          </div>
        )}
        {shouldShowVideoPlayer ? (
          <VideoPlayer projectAsset={projectAsset} profileView projectId={projectId} />
        ) : (
          <>
            <Grid item lg={8} md={8} sm={12} xs={12} style={{ position: 'relative' }} className={classes.fullHeight}>
              <ImagePlayer points={points} />
              <SaveProjectAssetInspectionProgress
                progress={imageProgress}
                projectAsset={projectAsset}
                type={assetType.IMAGE}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12} className={classes.fullHeight}>
              {points && points.length > 0 && (
                <Map
                  points={points}
                  videoRef={shouldShowVideoPlayer ? playerRef : null}
                  videoView={shouldShowVideoPlayer}
                  sequenceLength={points.length}
                  geometryOrgId={projectAsset?.project?.orgId}
                  projectId={projectId}
                />
              )}
              {points.length > 1 ? (
                <div className={`${classes.mapControls} mapControls`}>
                  <IconButton
                    className={classes.arrowSequence}
                    onClick={() => handlePreviousSequence()}
                    disabled={selectedSequence === 0}
                    color="secondary"
                  >
                    <ArrowLeftIcon size={24} />
                  </IconButton>
                  <Typography>Series Segment</Typography>
                  <IconButton
                    className={classes.arrowSequence}
                    onClick={() => handleNextSequence()}
                    disabled={points.length - 1 === selectedSequence}
                    color="secondary"
                  >
                    <ArrowRightIcon size={24} />
                  </IconButton>
                </div>
              ) : null}
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

AssetContent.propTypes = {
  projectId: PropTypes.string,
};

AssetContent.defaultProps = {
  projectId: null,
};

export const goBack = (history, page) => {
  let path = '';
  history.location.pathname
    .split('/')
    .slice(0, page)
    .forEach((element) => {
      if (element) path += `/${element}`;
    });
  history.push(path);
};

import { AccountSettingsContainer } from 'components/AccountSettingsContainer/AccountSettingsContainer';
import { Card } from 'components/Card/Card';
import { ContextMenu } from 'components/ContextMenu/ContextMenu';
import { ContextMenuList } from 'components/ContextMenuItems/ContextMenuList';
import { DialogMessage } from 'components/DialogMessage';
import { ClockIcon, InfoIcon, ShieldIcon } from 'components/Icons';
import { useSidebar } from 'components/SidebarProvider/SidebarProvider';
import { TablePagination } from 'components/TablePagination/TablePagination';
import { Typography } from 'components/Typography/Typography';
import { Time } from 'lib/time';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { usePagination, useSortBy, useTable } from 'react-table';
import { getProjectContent, getSingleContent, setPageFilter } from 'slices/projectContentReducer';
import { ModalRevokeAccess } from 'smartComponents/ModalRevokeAccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortAlphaAsc, faSortAlphaDesc } from '@fortawesome/free-solid-svg-icons';
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { ContextMenuItem } from 'components/ContextMenuItem';
import { AdminPanel } from './AdminPanel';
import { InfoPanel } from './InfoPanel';
import { SearchPanel } from './SearchPanel';
import {
  AccessCopyIcon,
  AccessIcon,
  AccessSharingIcon,
  AccessTrashIcon,
  CardHeader,
  ColumnContainer,
  DeniedCopyIcon,
  DeniedSharingIcon,
  DeniedTrashIcon,
  NameContainer,
  StyledContextMenuButton,
  StyledSubText,
  StyledTable,
} from './styles';

const collaborators = [
  { id: 1, image: 'http://placekitten.com/300/300', initials: 'ml', alt: 'RadixBay' },
  { id: 2, image: '', initials: 'cv', alt: 'RadixBay' },
  { id: 3, image: '', initials: 'ml', alt: 'RadixBay' },
  { id: 4, image: '', initials: 'zc', alt: 'RadixBay' },
  { id: 5, image: '', initials: 'mg', alt: 'RadixBay' },
  { id: 6, image: '', initials: 'ob', alt: 'RadixBay' },
];

let debounceTimeout = null;
export const ProjectContent = () => {
  const [openCopyAccess, setOpenCopyAccess] = useState(false);
  const [openShareAccess, setOpenShareAccess] = useState(false);
  const [openRevokeAccess, setOpenRevokeAccess] = useState(false);
  const [openRevokeDenied, setOpenRevokeDenied] = useState(false);
  const [openCopyDenied, setOpenCopyDenied] = useState(false);
  const [openShareDenied, setOpenShareDenied] = useState(false);
  const [openDeleteAccess, setOpenDeleteAccess] = useState(false);
  const [openDeleteDenied, setOpenDeleteDenied] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  const { data, projectName, filters, assetCount } = useSelector((state) => state.projectContent);

  const handleConfirmCopy = () => {
    setOpenCopyAccess(false);
  };

  const handleConfirmShare = () => {
    setOpenShareAccess(false);
  };

  const handleConfirmRevoke = () => {
    setOpenRevokeAccess(false);
  };

  const handleConfirmDelete = () => {
    setOpenDeleteAccess(false);
  };
  const tableColumns = [
    {
      Header: 'Type/Length',
      accessor: (rowData) => (
        <NameContainer>
          <ColumnContainer>
            <ClockIcon size={14} />
          </ColumnContainer>
          <div>
            <Typography>
              {rowData.type && rowData.type === 'VIDEO'
                ? Time.humanReadable({ seconds: rowData.timeLength })
                : rowData.type}
            </Typography>
            <StyledSubText>{moment(rowData.createdAt).format('MM/DD/YYYY')}</StyledSubText>
          </div>
        </NameContainer>
      ),
    },
    {
      Header: 'Name',
      accessor: (rowData) => (
        <div>
          <Typography>{rowData.name}</Typography>
          <StyledSubText>
            {rowData.uploaders[0] && `${rowData.uploaders[0].firstName} ${rowData.uploaders[0].lastName}`}
          </StyledSubText>
        </div>
      ),
    },
    {
      Header: 'Primary Project',
      accessor: (rowData) => rowData.project && rowData.project.name,
    },
    {
      Header: 'Other Projects Sharing',
      accessor: (rowData) => rowData.projectSharedWith && rowData.projectSharedWith,
    },
  ];

  const columns = React.useMemo(() => tableColumns, []);

  const table = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: filters.page - 1,
        pageSize: filters.page_size,
      },
      pageCount: Math.ceil(assetCount / filters.page_size),
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  const { displayPanel, setHeaderPanels } = useSidebar({
    open: false,
    config: {
      search: {
        show: true,
        component: SearchPanel,
      },
      header: [],
    },
  });

  const {
    state: { pageIndex, pageSize },
  } = table;

  React.useEffect(() => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      const sendFilters = {
        ...filters,
        projId: params.projId,
      };
      dispatch(getProjectContent(sendFilters));
    }, 150);
  }, [filters]);

  React.useEffect(() => {
    dispatch(setPageFilter(pageIndex + 1));
  }, [pageIndex, pageSize]);

  const handleRowClick = (rowData) => {
    dispatch(getSingleContent({ projId: params.projId, id: rowData.original.id, rowData: rowData.original }));
    setHeaderPanels([
      {
        name: 'info',
        icon: InfoIcon,
        component: InfoPanel,
      },
      {
        name: 'admin',
        icon: ShieldIcon,
        component: AdminPanel,
      },
    ]);
    displayPanel('info');
  };

  return (
    <AccountSettingsContainer title={`PROJECTS > ${projectName}`} showSidebar hideMenu>
      <Card noPadding>
        <CardHeader>
          <Typography variant="h3">Project Content</Typography>
          <StyledSubText>Change availability settings per asset.</StyledSubText>
        </CardHeader>
        <StyledTable table={table}>
          <TableHead>
            {table.headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span style={{ margin: '2px 4px', position: 'absolute' }}>
                      {(() => {
                        if (column.isSorted) {
                          if (column.isSortedDesc) {
                            return <FontAwesomeIcon icon={faSortAlphaDesc} size="sm" />;
                          }
                          return <FontAwesomeIcon icon={faSortAlphaAsc} size="sm" />;
                        }
                        return <FontAwesomeIcon icon={faSort} size="sm" />;
                      })()}
                    </span>
                  </TableCell>
                ))}
                <TableCell>Access</TableCell>
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...table.getTableBodyProps()}>
            {table.page.map((row) => {
              table.prepareRow(row);
              return (
                <TableRow
                  {...row.getRowProps()}
                  onClick={() => {
                    handleRowClick(row);
                  }}
                >
                  {row.cells.map((cell) => (
                    <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
                  ))}
                  <TableCell>
                    <ContextMenu>
                      <StyledContextMenuButton icon={AccessIcon} kind="secondary" size="small" iconOnly />
                      <ContextMenuList position="bottom-right">
                        <ContextMenuList>
                          <ContextMenuItem onClick={() => setOpenShareAccess(true)}>Allow Sharing</ContextMenuItem>
                          <ContextMenuItem onClick={() => setOpenCopyAccess(true)}>Allow Copies</ContextMenuItem>
                          <ContextMenuItem onClick={() => setOpenRevokeAccess(true)}>Revoke Access</ContextMenuItem>
                          <ContextMenuItem
                            onClick={() => {
                              setOpenDeleteAccess(true);
                            }}
                          >
                            Deletes
                          </ContextMenuItem>
                        </ContextMenuList>
                      </ContextMenuList>
                    </ContextMenu>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </StyledTable>
        <TablePagination table={table} />
        <DialogMessage
          title="Copy this content"
          isOpen={openCopyAccess}
          icon={AccessCopyIcon}
          onConfirm={() => {
            handleConfirmCopy();
          }}
          onCancel={() => {
            setOpenCopyAccess(false);
          }}
        />
        <DialogMessage
          title="Do not allow copying for this item."
          isOpen={openCopyDenied}
          icon={DeniedCopyIcon}
          onConfirm={() => {
            setOpenCopyDenied(false);
          }}
          onCancel={() => {
            setOpenCopyDenied(false);
          }}
        />
        <DialogMessage
          title="Share this content"
          isOpen={openShareAccess}
          icon={AccessSharingIcon}
          onConfirm={() => {
            handleConfirmShare();
          }}
          onCancel={() => {
            setOpenShareAccess(false);
          }}
        />
        <DialogMessage
          title="Do not allow sharing for this item."
          isOpen={openShareDenied}
          icon={DeniedSharingIcon}
          onConfirm={() => {
            setOpenShareDenied(false);
          }}
          onCancel={() => {
            setOpenShareDenied(false);
          }}
        />

        <DialogMessage
          title="Delete this content"
          isOpen={openDeleteAccess}
          icon={AccessTrashIcon}
          onConfirm={() => {
            handleConfirmDelete();
          }}
          onCancel={() => {
            setOpenDeleteAccess(false);
          }}
        />

        <DialogMessage
          title="Do not allow deleting for this item."
          isOpen={openDeleteDenied}
          icon={DeniedTrashIcon}
          onConfirm={() => {
            setOpenDeleteDenied(false);
          }}
          onCancel={() => {
            setOpenDeleteDenied(false);
          }}
        />

        <ModalRevokeAccess
          isOpen={openRevokeAccess}
          collaborators={collaborators}
          onSubmit={() => {
            handleConfirmRevoke();
          }}
          onCancel={() => {
            setOpenRevokeAccess(false);
          }}
        />

        <DialogMessage
          title="Do not allow revoking access for this item."
          isOpen={openRevokeDenied}
          icon={DeniedSharingIcon}
          onConfirm={() => {
            setOpenRevokeDenied(false);
          }}
          onCancel={() => {
            setOpenRevokeDenied(false);
          }}
        />
      </Card>
    </AccountSettingsContainer>
  );
};

import ApiService from './ApiService';

export default class MapGisService {
  static getMapGisServices = (filters) => ApiService.get('/gis', filters);

  static getMapGisService = async (id) => ApiService.get(`/gis/${id}`);

  static saveMapGisService = (data) => ApiService.post('/arcgis-cartovid', data);

  static deactivateMapGisService = async (id) => ApiService.post(`/arcgis-cartovid/${id}/deactivate`);

  static activateMapGisService = async (id) => ApiService.post(`/arcgis-cartovid/${id}/activate`);

  static getMapGisServicesAll = (filters) => ApiService.get('/arcgis-cartovid', filters);

  /**
   * Update the Map GIS or External Map information.
   *
   * @param {string} id
   * @param {string[]} data
   * @returns
   */
  static updateMapGisService = async (id, data) => ApiService.patch(`/arcgis-cartovid/${id}`, data);
}

import { combineReducers } from '@reduxjs/toolkit';
import content from './contentReducer';
import filters from './filterReducer';
import projects from './projectsReducer';

export default combineReducers({
  filters,
  projects,
  content,
});

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@mui/material';
import { CheckIcon, InfoIcon } from 'components/Icons';
import { ModalHeaderIcon } from 'components/ModalHeaderIcon/ModalHeaderIcon';
import { useDispatch, useSelector } from 'react-redux';
import InspectionService from 'services/InspectionService';
import { DialogMessage } from 'components/DialogMessage';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import { setProjectAssetInspections } from 'slices/projectAssetActiveInspectionReducer';
import { setProfileInspections } from 'slices/profileInspectionsReducer';
import { useProjectAssetContentContext } from 'components/ProjectDetail/ProjectAssets/ProjectAssetContentContext';
import { assetType } from '../helpers';
import { useHistory } from 'react-router-dom';
import UnsavedPrompt from './UnsavedPrompt';

export const splitVideoTime = (duration) => {
  const splitTime = duration <= 60 ? duration / 4 : duration / 10;
  let auxTime = splitTime;
  const auxTimeButtons = [];
  while (auxTime <= duration) {
    auxTimeButtons.push(auxTime);
    auxTime += splitTime;
  }
  return auxTimeButtons;
};

export const SaveProjectAssetInspectionProgress = ({ progress, projectAsset, handleStopVideo, type }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userId } = useSelector((state) => state.auth);
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [projAssetInspections, setProjAssetInspections] = useState([]);
  const [projAssetInspectionsBackup, setProjAssetInspectionsBackup] = useState([]);
  const { data } = useSelector((state) => state.projectAssetActiveInspections);
  const { data: profileInspections } = useSelector((state) => state.profileInspections);
  const { detail } = useSelector((state) => state.projectAssetActiveInspections);
  const {
    unsavedChanges,
    setUnsavedChanges,
    openUnsavedDialog,
    setOpenUnsavedDialog,
    continueAction,
    setContinueAction,
  } = useProjectAssetContentContext();

  const saveProgress = async (values) => {
    setIsLoading(true);
    try {
      const auxProgress = await InspectionService.getProjectAssetInspectionProgress(values.paiId, {
        time: type === assetType.VIDEO ? Math.ceil(progress.time) : progress?.id,
        pasqId: type === assetType.VIDEO ? projectAsset.sequences[0].id : progress?.sequenceId,
        type,
        duration: type === assetType.VIDEO ? progress.duration : projectAsset?.points?.length,
      });
      setIsLoading(false);
      let auxInspections = [...projAssetInspections].map((item) => ({
        ...item,
        progress: auxProgress.data,
      }));
      setProjAssetInspections(auxInspections);
      auxInspections = [...projAssetInspectionsBackup].map((item) => ({
        ...item,
        progress: auxProgress.data,
      }));
      setProjAssetInspectionsBackup(auxInspections);
      // update in asset section
      if (data.length) {
        const auxProjAssetInspections = [...data];
        const index = auxProjAssetInspections.findIndex((item) => item.id === values.paiId);
        if (index !== -1) {
          auxProjAssetInspections[index] = {
            ...auxProjAssetInspections[index],
            progress: auxProgress.data,
          };
        }
        dispatch(setProjectAssetInspections(auxProjAssetInspections));
      }
      // update in profile inspection section
      if (profileInspections.length) {
        const auxProfileInspections = [...profileInspections];
        const index = auxProfileInspections.findIndex((item) => item.id === values.paiId);
        if (index !== -1) {
          auxProfileInspections[index] = {
            ...auxProfileInspections[index],
            progress: auxProgress.data,
          };
        }
        dispatch(setProfileInspections(auxProfileInspections));
      }
      setOpen(false);
      setUnsavedChanges(false);
    } catch (error) {
      setIsLoading(false);
      setOpenDialog('An error occurred while trying to delete the observation.');
    }
  };

  const getProjAssetInpections = async () => {
    try {
      const result = await InspectionService.getProjectAssetInspections({
        page: 1,
        page_size: 1000,
        assigneeId: userId,
        include: ['inspection', 'progress', 'assignment'],
        pastId: projectAsset?.id,
      });
      const rootOptions = result.data.data.map((projAsstInspection) => ({
        label: projAsstInspection.inspection.name,
        value: projAsstInspection.id,
        progress: projAsstInspection.progress,
        assignment: projAsstInspection.assignment,
      }));
      setProjAssetInspections(rootOptions);
      setProjAssetInspectionsBackup(rootOptions);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getProjAssetInpections();
  }, [data]);

  // eslint-disable-next-line no-unused-vars
  const validateNextTime = (time) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const element of splitVideoTime(progress.duration)) {
      if (time <= element) {
        return element;
      }
    }
    return 0;
  };

  useEffect(() => {
    let inspecitions = [...projAssetInspectionsBackup];
    if (progress?.time && type === assetType.VIDEO) {
      inspecitions = inspecitions.filter(
        (item) =>
          (progress?.time > +item?.progress?.time || !item?.progress?.time) && item.assignment?.assignee?.id === userId
      );
    } else {
      inspecitions = inspecitions.filter(
        (item) =>
          (!item?.progress?.id || progress?.id > +item?.progress?.time) && item.assignment?.assignee?.id === userId
      );
    }
    // validate in proj active inspections section
    if (detail.activeInspecSection) {
      inspecitions = inspecitions.filter((item) => item.value === detail.id);
    }
    setProjAssetInspections(inspecitions);
  }, [progress, projAssetInspectionsBackup, detail]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      paiId: '',
    },
    validationSchema: Yup.object({
      paiId: Yup.string().required('Inspection Required'),
    }),
    onSubmit(values) {
      saveProgress(values);
    },
  });

  const openInspectionsModal = () => {
    formik.handleChange('paiId')('');
    handleStopVideo();
    setOpen(true);
  };

  const { handleBlur, handleSubmit } = formik;

  useEffect(() => {
    if (progress.time && projAssetInspections.length) {
      setUnsavedChanges(true);
    } else {
      setUnsavedChanges(false);
    }
  }, [projAssetInspections.length, progress.time]);

  const handleConfirm = () => {
    setOpenUnsavedDialog(false);
    openInspectionsModal();
  };

  const handleCancel = (path) => {
    setOpenUnsavedDialog(false);
    setUnsavedChanges(false);
    if (continueAction) {
      continueAction();
      setContinueAction(null);
    }
    if (path) {
      history.push(path);
    }
  };

  const onCloseInspectionModal = () => {
    setOpen(false);
  };

  return (
    <>
      <UnsavedPrompt when={unsavedChanges} show={openUnsavedDialog} onConfirm={handleConfirm} onCancel={handleCancel} />
      {progress.time && projAssetInspections.length ? (
        <Button
          color="secondary"
          variant="contained"
          sx={{ bottom: type === assetType.IMAGE ? '5px' : '80px', right: '5px', position: 'absolute', zIndex: 1 }}
          onClick={() => openInspectionsModal()}
        >
          Save Inspection Progress
        </Button>
      ) : null}
      <Dialog open={open} onClose={onCloseInspectionModal} maxWidth="xs" fullWidth>
        <DialogTitle>
          <ModalHeaderIcon icon={CheckIcon} text="Save Inspection Progress" />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <InputLabel>Select Inspection *</InputLabel>
              <FormControl error={formik.touched.paiId && formik.errors.paiId} fullWidth>
                <Autocomplete
                  options={projAssetInspections}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onBlur={formik.handleBlur}
                      required
                      placeholder="Select Inspection"
                      error={!!(formik.touched.paiId && formik.errors.paiId)}
                    />
                  )}
                  onChange={(e, value) => {
                    formik.handleChange('paiId')(value?.value ? value?.value : '');
                  }}
                  onBlur={handleBlur}
                />
                <FormHelperText>
                  {formik.touched.paiId && formik.errors.paiId ? formik.errors.paiId : null}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <LoadingOverlay loading={isLoading} />
        </DialogContent>
        <DialogActions>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button color="primary" variant="contained" onClick={onCloseInspectionModal} size="small" fullWidth>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button color="secondary" variant="contained" onClick={handleSubmit} size="small" fullWidth>
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <DialogMessage
        title={openDialog}
        isOpen={openDialog}
        icon={InfoIcon}
        confirmText="Ok"
        onConfirm={() => {
          setOpenDialog(false);
        }}
      />
    </>
  );
};

SaveProjectAssetInspectionProgress.propTypes = {
  progress: PropTypes.object.isRequired,
  projectAsset: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  handleStopVideo: PropTypes.func,
};

SaveProjectAssetInspectionProgress.defaultProps = {
  handleStopVideo: () => {},
};

import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Typography } from 'components/Typography/Typography';
import { useDispatch, useSelector } from 'react-redux';
import TeamService from 'services/TeamService';
import { getOrganizations } from 'slices/organizationReducer';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@mui/material';
import { ModalHeaderIcon } from 'components/ModalHeaderIcon/ModalHeaderIcon';
import { InfoIcon, UsersIcon } from 'components/Icons';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import { DialogMessage } from 'components/DialogMessage';

export const ModalNewTeam = ({ isOpen, onConfirm, onCancel }) => {
  const [submitError, setSubmitError] = React.useState('');
  const dispatch = useDispatch();
  const rootOrganizations = useSelector((state) => state.organizations.data);
  const [openDialog, setOpenDialog] = React.useState(false);

  React.useEffect(() => {
    dispatch(getOrganizations({ page_size: 500 }));
  }, []);

  const rootOptions = rootOrganizations.map((org) => ({
    label: org.name,
    value: org.id,
  }));

  const [loading, setLoading] = React.useState(false);

  // const today = Moment(Date.now()).format('MMM Do YYYY');

  const closeConfirm = () => {
    onConfirm();
  };

  const saveTeam = async (values) => {
    setLoading(true);
    const data = {
      name: values.teamName,
      description: values.teamDesc,
      orgId: values.orgId,
    };

    try {
      await TeamService.createTeam(data);
      setLoading(false);
      setOpenDialog('Team was successfully created');
    } catch (err) {
      const result = err.response.data.message;
      setSubmitError(result);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      teamName: '',
      teamDesc: '',
      orgId: '',
    },
    validationSchema: Yup.object({
      teamName: Yup.string().required('Team Name Required'),
      teamDesc: Yup.string().required('Description Required'),
      orgId: Yup.string().required('Organization Required'),
    }),
    onSubmit(values) {
      saveTeam(values);
    },
  });

  const { values, handleChange, handleBlur, handleSubmit } = formik;

  // eslint-disable-next-line no-unused-vars
  const getInitials = () => {
    const names = values.teamName.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  return (
    <Dialog open={isOpen} onClose={onCancel} maxWidth="sm">
      <DialogTitle>
        <ModalHeaderIcon icon={UsersIcon} text="Create Team" />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <InputLabel>Name *</InputLabel>
            <TextField
              placeholder="Current Name"
              disabled={loading}
              id="teamName"
              name="teamName"
              value={values.teamName}
              error={!!(formik.touched.teamName && formik.errors.teamName)}
              helperText={formik.touched.teamName && formik.errors.teamName ? formik.errors.teamName : null}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Description *</InputLabel>
            <TextField
              placeholder="Team Description"
              disabled={loading}
              id="teamDesc"
              name="teamDesc"
              value={values.teamDesc}
              error={!!(formik.touched.teamDesc && formik.errors.teamDesc)}
              helperText={formik.touched.teamDesc && formik.errors.teamDesc ? formik.errors.teamDesc : null}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Organization *</InputLabel>
            <FormControl error={formik.touched.orgId && formik.errors.orgId} fullWidth>
              <Autocomplete
                options={rootOptions}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select"
                    onBlur={formik.handleBlur}
                    required
                    error={!!(formik.touched.orgId && formik.errors.orgId)}
                  />
                )}
                onChange={(e, value) => {
                  formik.handleChange('orgId')(value?.value ? value?.value : '');
                }}
                onBlur={formik.handleBlur}
              />
              <FormHelperText>
                {formik.touched.orgId && formik.errors.orgId ? formik.errors.orgId : null}
              </FormHelperText>
            </FormControl>
          </Grid>
          {submitError && (
            <Grid item xs={12}>
              <Typography color="error">{submitError}.</Typography>
            </Grid>
          )}
        </Grid>
        <DialogMessage
          title={openDialog}
          isOpen={openDialog}
          icon={InfoIcon}
          confirmText="Ok"
          onConfirm={closeConfirm}
        />
        <LoadingOverlay loading={loading} />
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button color="primary" variant="contained" onClick={onCancel} disabled={loading} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button color="secondary" variant="contained" disabled={loading} onClick={handleSubmit} fullWidth>
              Save
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

ModalNewTeam.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  isOpen: PropTypes.bool,
};

ModalNewTeam.defaultProps = {
  isOpen: false,
  onConfirm: null,
  onCancel: null,
};

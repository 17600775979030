import React, { useMemo, useState, useEffect } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import moment from 'moment';
import { AccountSettingsContainer } from 'components/AccountSettingsContainer/AccountSettingsContainer';
import { AdminHeaderTitle } from 'components/AdminHeaderTitle/AdminHeaderTitle';
import { Card } from 'components/Card';
import { TablePagination } from 'components/TablePagination/TablePagination';
import { TopBar } from 'components/TopBar/TopBar';
import SplashImage from 'images/ContentSplash.png';
import { useDispatch, useSelector } from 'react-redux';
import { WidgetSplash } from 'components/WidgetSplash/WidgetSplash';
import { Loader } from 'components/Loader';
import { getInspections, setPageFilter, setSearch } from 'slices/inspectionReducer';
import { CirclePlusIcon } from 'components/Icons';
import { ContextMenu } from 'components/ContextMenu';
import { ContextMenuButton } from 'components/ContextMenuButton';
import { ContextMenuList } from 'components/ContextMenuItems';
import { ContextMenuItem } from 'components/ContextMenuItem';
import { WidgetDivider } from 'components/WidgetDivider/WidgetDivider';
import { DeleteInspection } from 'components/Inspections/DeleteInspection/DeleteInspection';
import { SaveInspection } from 'components/Inspections/SaveInspection/SaveInspection';
import { actions, inspectionType } from 'components/Inspections/helpers';
import { AssignProjectInspection } from 'components/Inspections/AssignProjectInspection/AssignProjectInspection';
import { Table } from 'components/Table';
import { v4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortAlphaAsc, faSortAlphaDesc } from '@fortawesome/free-solid-svg-icons';
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useSidebar } from 'components/SidebarProvider/SidebarProvider';
import { SearchPanel } from './SearchPanel';
import { ViewInspectionSample } from 'components/ProjectDetail/ProjectAssets/ProjectAssetDetail/ProjectAssetInspections/ViewInspectionSample/ViewInspectionSample';
import { InfoPanel } from 'components/Inspections/InspectionTemplates';
import { ExportFindings } from 'components/Findings/ExportFindings/ExportFindings';
import InspectionService from 'services/InspectionService';

const capitalizeFirstLetter = (text) => text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

const tableColumns = () => [
  {
    Header: 'Title',
    accessor: 'name', // accessor is the "key" in the data
  },
  {
    Header: 'Description',
    accessor: (rowData) => {
      const { description } = rowData;
      return description || 'No Description Found.';
    },
  },
  {
    Header: 'Type',
    accessor: (rowData) => {
      const { type } = rowData;
      return type?.name || '';
    },
  },
  {
    Header: 'Level',
    accessor: (rowData) => {
      const { objectType } = rowData;
      return objectType
        ? capitalizeFirstLetter(
            Object.keys(inspectionType)
              .find((property) => inspectionType[property] === objectType)
              .toLowerCase()
          )
        : '';
    },
  },
  {
    Header: 'Created By',
    accessor: (rowData) => {
      const { uploader } = rowData;
      return uploader ? `${uploader?.firstName} ${uploader?.lastName}` : 'user not found';
    },
  },
  {
    Header: 'Created',
    accessor: (rowData) => {
      const { createdAt } = rowData;
      return createdAt ? moment(createdAt).format('MM/DD/YY') : 'date not found';
    },
  },
];

export const Inspections = () => {
  const dispatch = useDispatch();
  const columns = useMemo(() => tableColumns(), []);
  const { loading, data, filters, totalCount } = useSelector((state) => state.inspections);
  const search = useSelector((state) => state.inspections.filters.search || '');
  const handleSearch = (e) => dispatch(setSearch(e.target.value));
  const [selectedRow, setSelectedRow] = useState('');
  const [action, setAction] = useState(null);

  const table = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: filters.page - 1,
        pageSize: filters.page_size,
      },
      pageCount: Math.ceil(totalCount / filters.page_size),
      totalCount,
      pageLength: data.length,
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  // eslint-disable-next-line prettier/prettier, no-unused-vars
  const { displayPanel, setHeaderPanels, setFullWidth, setOpen } = useSidebar({
    open: false,
    config: {
      search: {
        show: true,
        component: SearchPanel,
      },
      header: [],
    },
  });

  // get inspection in row clicking
  const handleRowClick = (inspection) => {
    setSelectedRow(inspection);
    setHeaderPanels([
      {
        name: 'info',
        component: InfoPanel,
        data: {
          inspection,
        },
        actions: {
          assignToProjects: () => {
            setAction({ type: actions.ASSIGN, inspection, refresh: true });
          },
          getProjects: async () =>
            InspectionService.getProjectsInInspection(inspection?.id).then((result) => result.data),
          exportFindings: async () => {
            setAction({ type: actions.EXPORT, inspection });
          },
        },
      },
    ]);
    setFullWidth(false);
    displayPanel('info');
  };

  const loadData = () => {
    const auxFilters = {
      ...filters,
      sort_by: Object.values(filters.sort_by),
    };
    dispatch(getInspections(auxFilters));
  };

  // close modal
  const onCancel = () => {
    const refresh = action?.refresh || false;
    setAction(null);
    if (refresh) {
      displayPanel('');
      displayPanel('info');
    }
  };

  const {
    state: { pageIndex, pageSize },
  } = table;

  useEffect(() => {
    dispatch(setPageFilter(pageIndex + 1));
  }, [pageIndex, pageSize]);

  useEffect(() => {
    loadData();
  }, [filters]);

  const handleCloseExportFindings = () => {
    setTimeout(() => {
      onCancel();
    }, 3000);
  };

  const InspectionActions = ({ row }) => (
    <ContextMenu>
      <ContextMenuButton />
      <ContextMenuList position="bottom-right">
        <ContextMenuItem onClick={() => setAction({ type: actions.ASSIGN, inspection: row.original })}>
          Assign to Projects
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setAction({ type: actions.VIEW, inspection: row.original })}>
          View Inspection Sample
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setAction({ type: actions.UPDATE, inspection: row.original })}>
          Edit Inspection Parameters
        </ContextMenuItem>
        <WidgetDivider />
        <ContextMenuItem onClick={() => setAction({ type: actions.DELETE, inspection: row.original })}>
          Remove Inspection
        </ContextMenuItem>
      </ContextMenuList>
    </ContextMenu>
  );

  const EmptyInspectionsContainer = () => (
    <>
      <WidgetSplash
        alt="Inspections Splash"
        title="Does your company have any inspection templates that need to be created?"
        image={SplashImage}
        cta="Create a Inspection"
        onClick={() => setAction({ type: actions.CREATE })}
      />
    </>
  );

  const InspectionsContainer = () => (
    <>
      <Table table={table} height>
        <TableHead>
          {table.headerGroups.map((headerGroup) => (
            <TableRow key={v4()} {...headerGroup.getHeaderGroupProps()}>
              <TableCell>Turn On/Off</TableCell>
              {headerGroup.headers.map((column) => (
                <TableCell key={v4()} {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span style={{ margin: '2px 4px', position: 'absolute' }}>
                    {(() => {
                      if (column.isSorted) {
                        if (column.isSortedDesc) {
                          return <FontAwesomeIcon icon={faSortAlphaDesc} size="sm" />;
                        }
                        return <FontAwesomeIcon icon={faSortAlphaAsc} size="sm" />;
                      }
                      return <FontAwesomeIcon icon={faSort} size="sm" />;
                    })()}
                  </span>
                </TableCell>
              ))}
              <TableCell align="center">Advanced</TableCell>
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...table.getTableBodyProps()}>
          {table.rows.map((row) => {
            table.prepareRow(row);
            return (
              <TableRow key={v4()} selected={selectedRow === row.original.id}>
                <TableCell>
                  <SaveInspection action={actions.DISABLED} inspection={row.original} />
                </TableCell>
                {row.cells.map((cell) => (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                  <TableCell
                    key={v4()}
                    onClick={() => {
                      handleRowClick(row.original);
                    }}
                    {...cell.getCellProps()}
                  >
                    {cell.render('Cell')}
                  </TableCell>
                ))}
                <TableCell align="center">
                  <InspectionActions row={row} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination table={table} />
    </>
  );

  if (loading && data.length === 0) {
    return (
      <AccountSettingsContainer
        title={<AdminHeaderTitle buttonName="ADMINISTRATION" section="Account-Level Inspection Templates" />}
        actions={[]}
      >
        <Loader loading={loading} height={250} />
      </AccountSettingsContainer>
    );
  }

  return (
    <AccountSettingsContainer
      title={<AdminHeaderTitle buttonName="ADMINISTRATION" section="Account-Level Inspection Templates" />}
      actions={[
        {
          icon: CirclePlusIcon,
          text: 'Inspection Template',
          onClick: () => setAction({ type: actions.CREATE }),
        },
      ]}
      showSidebar
    >
      <Card noPadding>
        <TopBar searchValue={search} handleSearch={handleSearch} activatedFilter={filters.activated} />
        {data.length > 0 ? InspectionsContainer() : EmptyInspectionsContainer()}
        {(action?.type === actions.CREATE || action?.type === actions.UPDATE) && (
          <SaveInspection
            onCancel={onCancel}
            onConfirm={onCancel}
            action={action?.type}
            inspection={action.inspection}
          />
        )}
        {action?.type === actions.DELETE && <DeleteInspection handleClose={onCancel} inspection={action.inspection} />}
        {action?.type === actions.ASSIGN && (
          <AssignProjectInspection handleClose={onCancel} inspection={action.inspection} />
        )}
        {action?.type === actions.VIEW && (
          <ViewInspectionSample handleClose={onCancel} inspection={action.inspection} />
        )}
        {action?.type === actions.EXPORT && (
          <ExportFindings inspection={selectedRow} handleClose={handleCloseExportFindings} />
        )}
      </Card>
    </AccountSettingsContainer>
  );
};

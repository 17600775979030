/**
 * resolve a users initials from firs, last and email
 *
 * @param {{
 *   firstName: string;
 *   lastName: string;
 *   email: string;
 * }} param0
 * @returns
 */
export const resolveUserInitials = ({ firstName, lastName, email }) => {
  if (firstName && lastName) {
    return `${firstName[0]}${lastName[0]}`;
  }
  if (firstName && !lastName) {
    return `${firstName.slice(0, 2)}`;
  }
  if (lastName && !firstName) {
    return `${lastName.slice(0, 2)}`;
  }

  return email?.slice(0, 2) || '';
};

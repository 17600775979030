import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './styles';

export const Card = ({ children, border, variant, noPadding, noHeight }) => {
  const classes = useStyles({ border, variant, noPadding, noHeight });

  return <div className={classes.card}>{children}</div>;
};

Card.propTypes = {
  children: PropTypes.node,
  border: PropTypes.bool,
  variant: PropTypes.oneOf(['default', 'dark']),
  noPadding: PropTypes.bool,
};

Card.defaultProps = {
  children: null,
  border: false,
  variant: 'default',
  noPadding: false,
};

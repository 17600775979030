import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  inspectionDetail: (props) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.primary[1400],
    borderLeft: `1px solid ${theme.palette.secondary[700]}`,
    zIndex: 999,
    transform: `translateX(${props.open ? '0%' : '110%'})`,
    transition: 'transform 0.3s ease-out',
  }),
  filter: {
    display: 'flex',
    justifyContent: 'space-between',
    aligItems: 'center',
    '& .title': {
      color: theme.palette.secondary.light,
      cursor: 'default',
    },
  },
  findingSearch: (props) => ({
    maxWidth: '500px',
    ...(props.small && {
      width: '350px!important',
      minHeight: '25px',
      height: '25px',
      '& .MuiInputBase-root': {
        height: '100%',
      },
      '& button': {
        padding: 0,
      },
    }),
  }),
  findingIcons: {
    '& svg': {
      color: theme.palette.primary[400],
    },
  },
  table: {
    '& .MuiTableRow-root': {
      cursor: 'pointer!important',
    },
  },
  small: {
    width: '30px',
    height: '25px',
    minHeight: '25px',
    minWidth: '30px',
    padding: 0,
  },
}));

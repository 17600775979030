import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Bytes } from 'lib/bytes';
import { BarsIcon } from 'components/Icons';
import { WidgetContent } from 'components/WidgetContent/WidgetContent';
import { WidgetHeader } from 'components/WidgetHeader/WidgetHeader';
import { WidgetItem } from 'components/WidgetItem/WidgetItem';
import ProjectService from 'services/ProjectService';
import { useGlobalStyles } from 'styles';
import { LinearProgress, Typography } from '@mui/material';
import { useStyles as useAdminStyles } from '../../Admin/Dashboard/styles';

export const ActiveProjectsWidget = () => {
  const adminClasses = useAdminStyles();
  const globalClasses = useGlobalStyles();
  const [projectData, setProjectData] = useState([]);
  const { userId } = useSelector((state) => state.auth);
  const history = useHistory();

  useEffect(() => {
    const getProjects = async (filters) => {
      const response = await ProjectService.getProjects(filters);
      setProjectData(response.data.data);
    };
    getProjects({ userId, page_size: 3, sort_by: '-createdAt' });
  }, []);

  return (
    <div className={adminClasses.generalContent}>
      <WidgetHeader
        title="Most Active Projects"
        subTitle="Projects you participate in the most."
        link
        actionIcon={BarsIcon}
        actionTitle="View All"
        actionClick={() => {
          history.push('/profile/projects');
        }}
        border
      />
      <WidgetContent>
        {projectData.map((project) => (
          <WidgetItem key={project.name} title={project.name} subHeader={project.description}>
            <Typography variant="body" className={globalClasses.greyTypography} mb={1}>
              {Bytes.dynamicHumanReadable({ bytes: project.fileTotalSize })}
            </Typography>
            {/*
                  colors: red, green, orange
                  will be added after Uploads Endpoint ready
                */}
            <LinearProgress variant="determinate" value={0} />
          </WidgetItem>
        ))}
      </WidgetContent>
    </div>
  );
};

import { AdminPanelButton } from 'components/AdminPanelButton';
import { PermGuard } from 'components/Guards/PermGuard';
import { UsersIcon } from 'components/Icons/UsersIcon';
import { SidebarPanel } from 'components/SidebarPanel';
import { SidebarPanelSection } from 'components/SidebarPanelSection';
import { Permissions } from 'lib/permissions';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ModalInviteTeam } from 'smartComponents/ModalInviteTeam';

export const AdminPanel = () => {
  const [openInviteUsers, setOpenInviteUsers] = useState(false);

  const { teamId, detail } = useSelector((state) => state.adminTeams.panels);

  // Manage the Open & Close Invite users to an Organization Modal
  const handleOpenInviteUsers = async () => {
    setOpenInviteUsers(true);
  };

  const handleInviteUsers = async () => {
    setOpenInviteUsers(false);
  };

  const handleCancelInviteUsers = () => {
    setOpenInviteUsers(false);
  };

  return (
    <SidebarPanel title="Teams">
      <SidebarPanelSection title="ADMIN SETTINGS" padding={0} accordion={false}>
        <PermGuard
          scopes={[
            Permissions.TEAM_USER_GRANT,
            Permissions.TEAM_USER_ADD,
            Permissions.TEAM_USER_REMOVE,
            Permissions.TEAM_USER_MODIFY,
          ]}
          itemId={teamId}
        >
          <AdminPanelButton icon={UsersIcon} onClick={() => handleOpenInviteUsers(teamId)}>
            Manage Members
          </AdminPanelButton>
        </PermGuard>
      </SidebarPanelSection>
      {openInviteUsers && (
        <ModalInviteTeam
          isOpen={openInviteUsers}
          onConfirm={handleInviteUsers}
          onCancel={handleCancelInviteUsers}
          teamId={teamId}
          orgId={detail.orgId}
        />
      )}
    </SidebarPanel>
  );
};

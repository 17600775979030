import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

function getContainer(container) {
  return typeof container === 'function' ? container() : container;
}

export const Portal = ({ container, children }) => {
  const [mountNode, setMountNode] = useState(null);

  useEffect(() => {
    setMountNode(getContainer(container) || document.body);
  }, [container]);

  return mountNode ? ReactDOM.createPortal(children, mountNode) : mountNode;
};

Portal.propTypes = {
  container: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  children: PropTypes.node.isRequired,
};

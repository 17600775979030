import { Typography } from 'components/Typography';
import { Widget } from 'components/Widget/Widget';
import { WidgetSplash } from 'components/WidgetSplash/WidgetSplash';
import SplashImage from 'images/404.png';
import React from 'react';
import styled from 'styled-components';

export const Layout = styled.div`
  height: 800px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 16px;
`;

export const Title = styled.h1`
  display: block;
  margin: 15vh auto 2vh;
  text-align: center;
  font-size: 60px;
`;

export const Description = styled.div`
  display: block;
  margin: 0 auto;
  text-align: center;
`;

export const NotAllowed = () => (
  <Layout>
    <Title>401</Title>
    <Description>
      <Typography variant="subtitle" as="h3" spacing={0.5}>
        Oops. Page not allowed.
      </Typography>
    </Description>
    <Widget row={2} col={2} rowSpan={1} colspan={1} border={false}>
      <WidgetSplash alt="Page not allowed." image={SplashImage} button={false} />
    </Widget>
  </Layout>
);

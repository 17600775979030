import React from 'react';
import PropTypes from 'prop-types';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import moment from 'moment';
import { v4 } from 'uuid';
import { pdfStyles } from './style';

export const PDFDocument = ({ fileName, observations, projectId }) => (
  <Document>
    <Page size="A4" style={pdfStyles.page}>
      <View style={pdfStyles.titleSection}>
        <View style={pdfStyles.left}>
          <Text>{fileName}</Text>
          <Text style={pdfStyles.date}>Date Exported: {moment().format('llll')}</Text>
        </View>
        <View style={pdfStyles.right}>
          <Text>CartoVid</Text>
        </View>
      </View>
      {observations.map((observation) => (
        <View style={pdfStyles.section} key={`${v4()}${new Date().getMilliseconds()}`}>
          <Text style={pdfStyles.text}>
            Name: <Text style={pdfStyles.normal}>{observation.name}</Text>
          </Text>
          <Text style={pdfStyles.text}>
            Description: <Text style={pdfStyles.normal}>{observation.description}</Text>
          </Text>
          {observation?.responses?.map((response) => (
            <Text style={pdfStyles.text}>
              {response.name}: <Text style={pdfStyles.normal}>{response?.response?.toString()}</Text>
            </Text>
          ))}
          {observation.Xmin && observation.Ymin && observation.Xmax && observation.Ymax ? (
            <>
              <Text style={pdfStyles.text}>
                Xmin: <Text style={pdfStyles.normal}>{observation.Xmin}</Text>
              </Text>
              <Text style={pdfStyles.text}>
                Xmax: <Text style={pdfStyles.normal}>{observation.Xmax}</Text>
              </Text>
              <Text style={pdfStyles.text}>
                Ymin: <Text style={pdfStyles.normal}>{observation.Ymin}</Text>
              </Text>
              <Text style={pdfStyles.text}>
                Ymax: <Text style={pdfStyles.normal}>{observation.Ymax}</Text>
              </Text>
            </>
          ) : null}
          <Text style={pdfStyles.text}>
            Date Created: <Text style={pdfStyles.normal}>{moment(observation.createdAt).format('llll')}</Text>
          </Text>
          <Text style={pdfStyles.text}>
            Date Updated: <Text style={pdfStyles.normal}>{moment(observation.updatedAt).format('llll')}</Text>
          </Text>
          <Text style={pdfStyles.text}>
            Latitude,Longitude:{' '}
            <Text style={pdfStyles.normal}>{`${observation.lat.toFixed(5)},${observation.lng.toFixed(5)}`}</Text>
          </Text>
          <Text
            style={pdfStyles.img}
            href={`https://${window.location.hostname}/projects?projectId=${
              observation.projectId || projectId
            }&pastId=${observation.projectAssetId}&${
              observation.projectAssetInspection ? 'findingId' : 'observationId'
            }=${observation.id}&sequenceId=${
              observation.sequence?.asset?.type !== 'VIDEO' ? observation.projectAssetSequenceId : observation.time - 1
            }`}
          >
            Launch in CartoVid
          </Text>
          <Text style={pdfStyles.img} href={observation.signedUrl}>
            See image
          </Text>
        </View>
      ))}
    </Page>
  </Document>
);

PDFDocument.propTypes = {
  fileName: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  observations: PropTypes.array,
  projectId: PropTypes.string,
};

PDFDocument.defaultProps = {
  fileName: '',
  observations: [],
  projectId: '',
};

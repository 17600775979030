import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  image: {
    width: '100%',
    objectFit: 'cover',
    borderRadius: '5px',
  },
  avatar: (props) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: props.isButton ? 'pointer' : 'normal',
    borderRadius: '4px',
    width: `${props.size}px`,
    height: `${props.size}px`,
    fontSize: `${props.size * 0.5}px`,
    backgroundColor: theme.palette.primary.dark,
    fontWweight: `${props.size < 40 ? 300 : 100} !important`,
    color: theme.palette.white,
    textTransform: 'uppercase',
    border: !props.image ? `1px solid ${theme.palette.primary.light}` : 'none',
  }),
});

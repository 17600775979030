/* eslint-disable no-console */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { MapGis, mapGisSection } from 'components/MapGis/MapGis';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectMapGisRows } from 'slices/projectMapGis';

let debounceTimeout = null;

export const ProjectMapGis = ({ project }) => {
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.projectMapGis);

  console.info('====================================');
  console.info(project);
  console.info('====================================');

  const loadData = () => {
    const sendFilters = {
      ...filters,
      sort_by: Object.values(filters.sort_by),
      // projectId: project?.id
    };
    dispatch(getProjectMapGisRows(sendFilters));
  };

  useEffect(() => {
    clearTimeout(debounceTimeout);

    debounceTimeout = setTimeout(() => {
      loadData();
    }, 300);
  }, [filters]);

  return (
    <>
      <MapGis section={mapGisSection.PROJECT} />
    </>
  );
};

ProjectMapGis.propTypes = {
  project: PropTypes.object.isRequired,
};

import { StringRoles, TextRoles } from './roles';

export const getInitials = (string) => {
  if (!string) return '';
  const names = string.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

// show uuid
export const cutUuidText = (string) => {
  if (!string) return '';
  if (string.length <= 2) {
    return string;
  }

  const mitad = Math.floor(string.length / 2);
  const shortString = `${string.slice(0, mitad)}...`;

  return shortString;
};

// show project role
const getKeyByValue = (object, code) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in object) {
    // eslint-disable-next-line no-prototype-builtins
    if (object.hasOwnProperty(key) && object[key] === code) {
      return key;
    }
  }
  return null;
};

export const getNameRole = (roles) => {
  if (roles.length > 0) {
    const auxRoles = roles.sort((a, b) => a.roleId - b.roleId);
    return TextRoles[getKeyByValue(StringRoles, auxRoles[0].code)];
  }
  return '';
};

import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  buttonContent: {
    marginBottom: theme.spacing(1),
    position: 'relative',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '2rem!important',
  },
  optionsContent: {
    position: 'absolute',
    background: 'white',
    color: 'black',
    textAlign: 'left',
    borderRadius: '4px',
    width: '100%',
    top: '100%',
    left: '0',
    right: '0',
  },
  textOption: {
    cursor: 'pointer',
    display: 'block',
    width: '100%',
    padding: '1rem',
    color: `${theme.palette.primary[900]}!important`,
    '&:hover': {
      backgroundColor: theme.palette.primary[200],
    },
  },
  buttonStyle: {
    position: 'relative!important',
    width: 'max-content',
    backgroundColor: 'white!important',
    color: `${theme.palette.primary[900]}!important`,
    '&:hover': {
      backgroundColor: `${theme.palette.primary[200]}`,
    },
    '& span': {
      display: 'inherit',
    },
    border: '1px solid #8c8c8c!important',
    height: '41px',
    padding: `${0}!important`,
  },
  styledImage: {
    flex: 1,
    width: '20px',
    height: '20px',
    display: 'inline-block',
    marginRight: '12px',
    marginLeft: '12px',
  },
  textButton: {
    fontSize: '15px!important',
    fontWeight: `${600}!important`,
    marginRight: '12px!important',
    color: `${theme.palette.primary[900]}`,
  },
});

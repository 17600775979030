import React, { useEffect, useState } from 'react';
import { FormRadioGroup } from 'components/FormRadioGroup';
import { SidebarPanel, cleanObjectFilters } from 'components/SidebarPanel';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import { ClearFilterAction } from 'components/SidebarPanel/ClearFilterAction';
import { setFilters } from 'slices/adminAdminsReducer';
import { Roles } from 'lib/roles';

export const SearchPanel = () => {
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.adminAdmins.filters);
  const nameSort = useSelector((state) => state.adminAdmins.filters.sort_by.firstName || '');
  const dateSort = useSelector((state) => state.adminAdmins.filters.sort_by.createdAt || '');
  const participantLevel = useSelector((state) => state.adminAdmins.filters.participantLevel || '');
  const [filterActions, setFiltersActions] = useState([]);
  const [localFilters, setLocalFilters] = useState({
    sort_by: {},
    participantLevel: '',
  });

  // update checkbox filters
  const checkBoxFilters = (value, checked, filter) => {
    const auxFilters = { ...localFilters };
    if (checked) {
      if (typeof auxFilters[filter] === 'string' || !Array.isArray(auxFilters[filter]) || !auxFilters[filter])
        auxFilters[filter] = [];
      auxFilters[filter] = [...auxFilters[filter]];
      auxFilters[filter].push(value);
    } else {
      auxFilters[filter] = auxFilters[filter].filter((element) => element !== value);
    }
    setLocalFilters({ ...auxFilters });
  };

  // update radio buttons
  const updateNormalFilters = (value, type) => {
    const auxFilters = { ...localFilters };
    auxFilters[type] = value;
    setLocalFilters({ ...auxFilters });
  };

  // clear sort
  const clearSort = (param) => {
    const auxFilters = { ...localFilters };
    delete auxFilters.sort_by[param];
    auxFilters.sort_by = cleanObjectFilters(auxFilters.sort_by);
    setLocalFilters(auxFilters);
  };

  // -------------- clear filters
  const handleClearFilters = () => {
    const auxFilters = { filters };
    if (cleanObjectFilters(auxFilters)?.filters) {
      dispatch(
        setFilters({
          sort_by: {},
          participantLevel: null,
          activated: false,
        })
      );
    }
  };

  // -------------- apply filters
  const handleApplyFilters = () => {
    const auxFilters = { ...localFilters };
    auxFilters.sort_by = cleanObjectFilters(auxFilters.sort_by);
    dispatch(setFilters({ ...auxFilters, activated: filterActions.length > 0 }));
  };

  const createClearFilters = (clearFilters) => {
    const auxFilters = [];
    if (clearFilters.sort_by?.firstName) auxFilters.push({ text: 'Sort By Name', callBack: () => clearSort('name') });
    if (clearFilters.sort_by?.createdAt)
      auxFilters.push({ text: 'Sort By Created At', callBack: () => clearSort('createdAt') });
    if (clearFilters.participantLevel?.length > 0)
      auxFilters.push({ text: 'Clear By Role', callBack: () => updateNormalFilters(null, 'participantLevel') });
    setFiltersActions(auxFilters);
    return auxFilters;
  };

  useEffect(() => {
    createClearFilters(localFilters);
  }, [localFilters]);

  useEffect(() => {
    createClearFilters(filters);
    setLocalFilters({
      sort_by: {
        firstName: nameSort,
        createdAt: dateSort,
      },
      participantLevel,
    });
  }, [filters]);

  return (
    <SidebarPanel
      title="Filters: Account Admin"
      subTitle="Apply filters to table data."
      filters
      handleClearFilters={handleClearFilters}
      handleApplyFilters={handleApplyFilters}
    >
      <div className="searchBody">
        <ClearFilterAction actions={filterActions} />
        <FormControl>
          <FormLabel>Sort</FormLabel>
          <FormRadioGroup
            name="alphabetical"
            value={localFilters.sort_by.firstName}
            onChecked={(checkedVal) => {
              const sortBy = { ...localFilters.sort_by };
              sortBy.firstName = checkedVal;
              updateNormalFilters(sortBy, 'sort_by');
            }}
            options={[
              { label: 'A - Z', value: '+firstName', disabled: false },
              { label: 'Z - A', value: '-firstName', disabled: false },
              { label: 'None', value: '', disabled: false },
            ]}
            view="horizontal"
          />
          <FormRadioGroup
            name="date-by"
            value={localFilters.sort_by.createdAt}
            onChecked={(checkedVal) => {
              const sortBy = { ...localFilters.sort_by };
              sortBy.createdAt = checkedVal;
              updateNormalFilters(sortBy, 'sort_by');
            }}
            options={[
              { label: 'Newest', value: '-createdAt', disabled: false },
              { label: 'Oldest', value: '+createdAt', disabled: false },
              { label: 'None', value: '', disabled: false },
            ]}
            view="horizontal"
          />
        </FormControl>
        <FormControl>
          <FormLabel className="marginTop">Role</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="Account Administrator"
              onChange={(event) => checkBoxFilters(Roles.ACCT_ADMIN, event.target.checked, 'participantLevel')}
              checked={
                localFilters?.participantLevel?.length > 0 && localFilters.participantLevel.includes(Roles.ACCT_ADMIN)
              }
            />
            <FormControlLabel
              control={<Checkbox />}
              label="Account Content Administrator"
              onChange={(event) => checkBoxFilters(Roles.ACCT_CONTENT_ADMIN, event.target.checked, 'participantLevel')}
              checked={
                localFilters?.participantLevel?.length > 0 &&
                localFilters.participantLevel.includes(Roles.ACCT_CONTENT_ADMIN)
              }
            />
            <FormControlLabel
              control={<Checkbox />}
              label="Account Billing Coordinator"
              onChange={(event) => checkBoxFilters(Roles.ACCT_BILL_ADMIN, event.target.checked, 'participantLevel')}
              checked={
                localFilters?.participantLevel?.length > 0 &&
                localFilters.participantLevel.includes(Roles.ACCT_BILL_ADMIN)
              }
            />
          </FormGroup>
        </FormControl>
      </div>
    </SidebarPanel>
  );
};

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useSortBy, useTable } from 'react-table';
import { GeneralTable } from 'components/GeneralTable/GeneralTable';
import { Loader } from 'components/Loader';
import { Bytes } from 'lib/bytes';
import { DeleteButton } from 'components/SeriesList/DeleteButton';
import { ShareButton } from 'components/SeriesList/ShareButton';

const SizeCal = (size) => Bytes.dynamicHumanReadable({ bytes: size });

const tableColumns = () => [
  {
    Header: '#',
    id: 'ordinal',
    accessor: (rowData) => {
      const { ordinal } = rowData;
      return ordinal;
    },
  },
  {
    Header: 'Size',
    accessor: (rowData) => SizeCal(rowData?.asset?.size || 'Size not found'),
  },
  {
    Header: 'Date',
    id: 'createdAt',
    accessor: (rowData) => {
      const { createdAt } = rowData;
      return createdAt ? moment(createdAt).format('MM/DD/YY') : 'date not found';
    },
  },
  {
    Header: 'Actions',
    id: 'actions',
    accessor: (rowData) => (
      <>
        <ShareButton sequenceId={rowData?.asset?.id} assetId={rowData?.id} projId={rowData?.projId} icon />
        <DeleteButton sequenceId={rowData?.asset?.id} assetId={rowData?.id} projId={rowData?.projId} icon />
      </>
    ),
  },
];

export const ProjectAssetSeries = () => {
  const { projectAsset, isAssetLoading } = useSelector((state) => state.profileProjectAssets);
  const columns = React.useMemo(() => tableColumns(), []);
  const [sequences, setSequences] = useState([]);

  const table = useTable(
    {
      columns,
      data: sequences,
    },
    useSortBy
  );

  useEffect(() => {
    setSequences(
      projectAsset?.sequences?.length > 0 ? [...projectAsset.sequences].sort((a, b) => a.ordinal - b.ordinal) : []
    );
  }, [projectAsset?.sequences]);

  return (
    <>
      {isAssetLoading ? (
        <Loader height={50} loading />
      ) : (
        <>
          <GeneralTable table={table} selectRow={false} />
        </>
      )}
    </>
  );
};

import Auth from 'lib/auth';
import PropTypes from 'prop-types';
import { PermissionsValidator } from 'lib/permValidator';

export const PermGuard = ({ children, scopes, itemId }) => {
  const show = PermissionsValidator.validate({ scopes, itemId });
  return Auth.allowed({ callback: () => show }) ? children : null;
};

PermGuard.propTypes = {
  children: PropTypes.node,
  scopes: PropTypes.array,
  itemId: PropTypes.string,
};

PermGuard.defaultProps = {
  children: null,
  scopes: [],
  itemId: '',
};

export default class PermFormatter {
  static format = (perms) => {
    const formattedPerms = {};

    const parseGrants = (grants) => {
      const grantsObj = {};
      grants.forEach((grant) => {
        grantsObj[grant] = true;
      });
      return grantsObj;
    };

    if (!Array.isArray(perms)) {
      formattedPerms[perms.id] = parseGrants(perms.grants);
      return formattedPerms;
    }

    if (perms.length === 0) return {};

    perms.forEach((perm) => {
      formattedPerms[perm.id] = parseGrants(perm.grants);
      if (perm.role) {
        formattedPerms[perm.id].role = perm.role;
      }
    });
    return formattedPerms;
  };
}

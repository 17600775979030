import { ProfileSettingsContainer } from 'components/ProfileSettingsContainer/ProfileSettingsContainer';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { Card } from 'components/Card';
import { useGlobalStyles } from 'styles';
import UserAssets from 'services/UserAssets';
import { ActiveProjectsWidget } from './ActiveProjectsWidget';
import { LastUploadsWidget } from './LastUploadsWidget';
import { QuickActionsWidget } from './QuickActionsWidget';
import ReportsSplash from './ReportsSplash.svg';
import { useStyles as useAdminStyles } from '../../Admin/Dashboard/styles';
import { UserInfoWidget } from './UserInfoWidget';

export const ProfileDashboard = () => {
  const adminClasses = useAdminStyles();
  const globalClasses = useGlobalStyles();
  const { fname, lname } = useSelector((state) => state.auth);
  const [uploadsData, setUploadsData] = useState({ data: [], meta: { totalCount: 0 } });

  const getHeaderText = () => {
    const header = fname === null || lname === null ? 'Profile Dashboard' : `${fname} ${lname}'s Dashboard`;
    return header.toUpperCase();
  };

  useEffect(() => {
    const getUploads = async (filters) => {
      const response = await UserAssets.getUserAssets(filters);
      setUploadsData(response.data);
    };
    getUploads({ include: ['uploader'], sort_by: '-createdAt' });
  }, []);

  return (
    <ProfileSettingsContainer title={getHeaderText()} showSidebar>
      <Card noPadding>
        <div className={adminClasses.dashContent}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={3.5} lg={3.5} xl={3.5}>
              <UserInfoWidget uploadsData={uploadsData} />
            </Grid>
            <Grid item xs={12} sm={12} md={8.5} lg={8.5} xl={8.5}>
              {/** Reporting Widget splash */}
              <div className={adminClasses.imgTitle}>
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Typography variant="h1" mt={1}>
                      Your Dashboard
                    </Typography>
                    <Typography variant="h3" className={globalClasses.greyTypography} mt={1}>
                      Collaborate, Participate &amp; Make Magic
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={8} align="right">
                    <img src={ReportsSplash} alt="Reports Splash" width="70%" />
                  </Grid>
                </Grid>
              </div>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <QuickActionsWidget />
                  <ActiveProjectsWidget />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <LastUploadsWidget uploadsData={uploadsData?.data || []} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Card>
    </ProfileSettingsContainer>
  );
};

import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const LayersIcon = ({ size, className }) => (
  <Svg viewBox="0 0 24 24" size={size} className={className}>
    <path
      d="m12 1 .447-.894a1 1 0 0 0-.894 0L12 1ZM1 6.5l-.447-.894a1 1 0 0 0 0 1.788L1 6.5ZM12 12l-.447.894a1 1 0 0 0 .894 0L12 12Zm11-5.5.447.894a1 1 0 0 0 0-1.788L23 6.5ZM1.447 16.606a1 1 0 1 0-.894 1.788l.894-1.788ZM12 23l-.447.894a1 1 0 0 0 .894 0L12 23Zm11.447-4.606a1 1 0 1 0-.894-1.788l.894 1.788Zm-22-7.288a1 1 0 1 0-.894 1.788l.894-1.788ZM12 17.5l-.447.894a1 1 0 0 0 .894 0L12 17.5Zm11.447-4.606a1 1 0 1 0-.894-1.788l.894 1.788ZM11.553.106l-11 5.5.894 1.788 11-5.5-.894-1.788Zm-11 7.288 11 5.5.894-1.788-11-5.5-.894 1.788Zm11.894 5.5 11-5.5-.894-1.788-11 5.5.894 1.788Zm11-7.288-11-5.5-.894 1.788 11 5.5.894-1.788ZM.553 18.394l11 5.5.894-1.788-11-5.5-.894 1.788Zm11.894 5.5 11-5.5-.894-1.788-11 5.5.894 1.788Zm-11.894-11 11 5.5.894-1.788-11-5.5-.894 1.788Zm11.894 5.5 11-5.5-.894-1.788-11 5.5.894 1.788Z"
      fill="currentColor"
    />
  </Svg>
);

LayersIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
};

LayersIcon.defaultProps = {
  size: 24,
  className: null,
};

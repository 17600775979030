import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { DialogMessage, dialogMessageAction } from 'components/DialogMessage';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import { IconButton } from '@mui/material';
import { InfoIcon, TrashIcon } from 'components/Icons';
import InspectionService from 'services/InspectionService';
import { setFindings } from 'slices/findingReducer';
import { setProjAssetFindings } from 'slices/profileProjectAssetReducer';

export const DeleteFinding = ({ finding }) => {
  const dispatch = useDispatch();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { data } = useSelector((state) => state.findings);
  const {
    projectAsset: { findings: assetFindings },
  } = useSelector((state) => state.profileProjectAssets);

  const deletePoint = async () => {
    setOpenConfirm(false);
    setIsLoading(true);
    try {
      await InspectionService.deleteFinding(finding.paiId, finding.id, finding.projectAsset?.projId);
      setIsLoading(false);
      const findings = [...data];
      let index = findings.findIndex((item) => item.id === finding.id);
      if (index !== -1) findings.splice(index, 1);
      dispatch(setFindings(findings));
      const auxAssetFindings = [...(assetFindings?.length ? assetFindings : [])];
      index = auxAssetFindings.findIndex((item) => item.id === finding.id);
      if (index !== -1) auxAssetFindings.splice(index, 1);
      dispatch(setProjAssetFindings(auxAssetFindings));
    } catch (error) {
      setIsLoading(false);
      setOpenDialog('An error occurred while trying to delete the inspection finding.');
    }
  };

  return (
    <>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          setOpenConfirm(true);
        }}
      >
        <TrashIcon size={20} />
      </IconButton>
      <DialogMessage
        title={`Delete ${finding.name}`}
        content={`Are you sure you want to delete this ${finding.name}? This action cannot be undone`}
        isOpen={openConfirm}
        onConfirm={deletePoint}
        onCancel={() => setOpenConfirm(false)}
        action={dialogMessageAction.delete}
      />
      <DialogMessage
        title={openDialog}
        isOpen={openDialog}
        icon={InfoIcon}
        confirmText="Ok"
        onConfirm={() => {
          setOpenDialog(false);
        }}
      />
      <LoadingOverlay loading={isLoading} />
    </>
  );
};

DeleteFinding.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  finding: PropTypes.object,
};

DeleteFinding.defaultProps = {
  finding: { id: null, name: '' },
};

import { Avatar } from 'components/Avatar';
import { resolveUserInitials } from 'lib/resolve-user-initials';
import moment from 'moment';
import React from 'react';
import { v4 } from 'uuid';
import { Time } from 'lib/time';
import { Typography } from 'components/Typography';
import { DocumentIcon, PhotoIcon, VideoIcon, LoadIcon } from 'components/Icons';
import { AvatarRow, ColumnContainer, NameContainer, StyledSubText } from './styles';

export const AssetsHeaders = [
  {
    Header: 'Title',
    id: 'name',
    accessor: (rowData) => {
      const { name, type, timeLength, uploadComplete, isTranscodingComplete } = rowData;
      return (
        <NameContainer>
          <ColumnContainer>
            {/* eslint-disable-next-line no-nested-ternary */}
            {type === 'VIDEO' ? (
              <VideoIcon />
            ) : // eslint-disable-next-line no-nested-ternary
            type === 'PHOTO' ? (
              <PhotoIcon />
            ) : uploadComplete && isTranscodingComplete ? (
              <DocumentIcon />
            ) : (
              <LoadIcon />
            )}
          </ColumnContainer>
          <div>
            <Typography>{name}</Typography>
            <StyledSubText>{type === 'VIDEO' ? Time.humanReadable({ seconds: timeLength }) : type}</StyledSubText>
          </div>
        </NameContainer>
      );
    },
  },
  {
    Header: 'Description',
    id: 'description',
    accessor: (rowData) => {
      const { description } = rowData;
      return description || 'No Description Found.';
    },
  },
  {
    Header: 'Date Acquired',
    id: 'createdAt',
    accessor: (rowData) => {
      const { createdAt } = rowData;
      return createdAt ? moment(createdAt).format('MM/DD/YY') : 'date not found';
    },
  },
  {
    Header: 'Added',
    id: 'updatedAt',
    accessor: (rowData) => {
      const { updatedAt } = rowData;
      return updatedAt ? moment(updatedAt).format('MM/DD/YY') : 'date not found';
    },
  },
  {
    Header: 'Files',
    id: 'assetSeries',
    desabledSort: true,
    accessor: (rowData) => {
      const { assetSeries } = rowData;
      return assetSeries || 0;
    },
  },
  {
    Header: 'Observations',
    id: 'observations',
    desabledSort: true,
    accessor: (rowData) => {
      const { observations } = rowData;
      return observations?.length || 0;
    },
  },
  {
    Header: 'Uploaded By',
    id: 'uploaders',
    desabledSort: true,
    accessor: (rowData) => {
      const { uploaders } = rowData;
      return (
        <AvatarRow>
          {uploaders.length
            ? uploaders
                .slice(0, 6)
                .map((user) => (
                  <Avatar
                    initials={resolveUserInitials({ firstName: user.firstName, lastName: user.lastName })}
                    size={30}
                    key={`${v4()}${new Date().getMilliseconds()}`}
                  />
                ))
            : uploaders.length > 6 && <Avatar initials={`+${uploaders.length - 6}`} size={30} />}
        </AvatarRow>
      );
    },
  },
  {
    Header: 'Origin Project',
    id: 'project',
    accessor: (rowData) => {
      const { project } = rowData;
      return project?.name || '';
    },
  },
];

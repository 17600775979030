import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { usePagination, useSortBy, useTable } from 'react-table';
import { useDispatch, useSelector } from 'react-redux';
import { ContextMenu } from 'components/ContextMenu';
import { ContextMenuList } from 'components/ContextMenuItems';
import { Table } from 'components/Table';
import { setSortBy } from 'slices/profileProjectAssetReducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faEllipsis,
  faInfo,
  faSortAlphaAsc,
  faSortAlphaDesc,
  faSort,
  faArrowLeftRotate,
  faClose,
  faPlay,
} from '@fortawesome/free-solid-svg-icons';
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { ContextMenuButton } from 'components/ContextMenuButton';
import { ContextMenuItem } from 'components/ContextMenuItem';
import { v4 } from 'uuid';
import { InfoContainer, PlayContainer, StyledTable } from './styles';
import { AssetsHeaders } from './AssetsHeaders';

library.add(faEllipsis);
library.add(faInfo);
library.add(faSortAlphaAsc);
library.add(faSortAlphaDesc);
library.add(faSort);
library.add(faArrowLeftRotate);
library.add(faClose);
library.add(faPlay);

export const AssetsTable = ({
  assets,
  selectedRow,
  contextMenuItems,
  handleRowClick,
  openSideBar,
  updateSelected,
  detailedView,
}) => {
  const [list, setList] = useState([]);
  const [showContext, setShowContext] = useState({ show: false, x: 0, y: 0, item: {} });
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.profileProjectAssets);
  const table = useTable(
    {
      columns: AssetsHeaders,
      data: list,
      manualSortBy: true,
    },
    useSortBy,
    usePagination
  );

  // --------------------- manual click on column header for sorting
  const columnHeaderClick = (column) => {
    if (!column.desabledSort) {
      if (Object.keys(filters.sort_by).length > 0) {
        if (Object.values(filters.sort_by).includes(`-${column.id}`)) {
          dispatch(setSortBy({ label: column.id, value: null }));
        } else if (Object.values(filters.sort_by).includes(`+${column.id}`)) {
          dispatch(setSortBy({ label: column.id, value: `-${column.id}` }));
        } else {
          dispatch(setSortBy({ label: column.id, value: `+${column.id}` }));
        }
      } else {
        dispatch(setSortBy({ label: column.id, value: `+${column.id}` }));
      }
    }
  };

  useEffect(() => {
    // -------------------- update selected asset in page changing
    if (assets.length > 0) {
      const asset = assets.find((item) => item.isTranscodingComplete);
      const prevAsset = list.find((item) => item.isTranscodingComplete);
      if (asset?.id && prevAsset?.id !== asset.id) updateSelected(asset);
    }
    setList(assets);
  }, [assets]);

  const ProjectsContainer = () => (
    <StyledTable>
      <Table table={table}>
        <TableHead>
          {table.headerGroups.map((headerGroup) => (
            <TableRow key={`${v4()}${new Date().getMilliseconds()}`} {...headerGroup.getHeaderGroupProps()}>
              <TableCell> </TableCell>
              <TableCell> </TableCell>
              {headerGroup.headers.map((column) => (
                <TableCell
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  onClick={() => columnHeaderClick(column)}
                  style={{
                    cursor: column.desabledSort ? 'default' : 'pointer',
                  }}
                  key={`${v4()}${new Date().getMilliseconds()}`}
                >
                  {column.render('Header')}
                  {!column.desabledSort && (
                    <span
                      style={{
                        margin: '2px 4px',
                        position: 'absolute',
                      }}
                    >
                      {(() => {
                        if (Object.keys(filters.sort_by).length > 0) {
                          if (Object.values(filters.sort_by).includes(`-${column.id}`)) {
                            return (
                              <FontAwesomeIcon icon={faSortAlphaAsc} size="sm" style={{ width: 14, height: 14 }} />
                            );
                          }
                          if (Object.values(filters.sort_by).includes(`+${column.id}`)) {
                            return (
                              <FontAwesomeIcon icon={faSortAlphaDesc} size="sm" style={{ width: 14, height: 14 }} />
                            );
                          }
                        }
                        return <FontAwesomeIcon icon={faSort} size="sm" style={{ width: 14, height: 14 }} />;
                      })()}
                    </span>
                  )}
                </TableCell>
              ))}
              <TableCell>Advanced</TableCell>
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...table.getTableBodyProps()}>
          {table.page.map((row) => {
            table.prepareRow(row);
            return (
              <TableRow
                key={`${v4()}${new Date().getMilliseconds()}`}
                {...row.getRowProps()}
                selected={selectedRow === row.original.id}
              >
                <TableCell key={`${v4()}${new Date().getMilliseconds()}`}>
                  <InfoContainer onClick={() => openSideBar(row.original.id)}>
                    <FontAwesomeIcon icon={faInfo} size="sm" style={{ width: 14, height: 14 }} />
                  </InfoContainer>
                </TableCell>
                <TableCell>
                  <PlayContainer onClick={() => detailedView(row.original, 'same-window')}>
                    <FontAwesomeIcon icon={faPlay} size="sm" style={{ width: 14, height: 14 }} />
                  </PlayContainer>
                </TableCell>
                {row.cells.map((cell) => (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
                  <TableCell
                    {...cell.getCellProps()}
                    onContextMenu={(e) => {
                      setShowContext({ show: true, x: e.clientX, y: e.clientY, item: row.original });
                    }}
                    onClick={() => {
                      handleRowClick(row.original.id, row.originallatitude, row.originallongitude);
                    }}
                    key={`${v4()}${new Date().getMilliseconds()}`}
                  >
                    {cell.render('Cell')}
                  </TableCell>
                ))}
                <TableCell>
                  <ContextMenu>
                    <ContextMenuButton />
                    <ContextMenuList position="bottom-right">{contextMenuItems(row.original)}</ContextMenuList>
                  </ContextMenu>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
        {showContext.show === true && (
          <div
            style={{
              position: 'fixed',
              top: showContext.y,
              left: showContext.x,
              backgroundColor: '#333',
              color: 'white',
              padding: 10,
              minWidth: 250,
              borderColor: 'white',
              borderWidth: 1,
              borderStyle: 'solid',
              borderRadius: 10,
              boxShadow: '1px 2px 9px #000',
            }}
          >
            <ContextMenuItem onClick={() => setShowContext({ show: false, x: 0, y: 0, item: {} })}>
              Close Menu
            </ContextMenuItem>
            <ContextMenuItem
              onClick={() => {
                detailedView(showContext.item, 'new-window');
                setShowContext({ show: false, x: 0, y: 0, item: {} });
              }}
            >
              Open Asset in new window
            </ContextMenuItem>
          </div>
        )}
      </Table>
    </StyledTable>
  );

  return <ProjectsContainer />;
};

AssetsTable.propTypes = {
  assets: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      assetSeries: PropTypes.number,
      type: PropTypes.string,
      timeLength: PropTypes.number,
      uploadComplete: PropTypes.bool,
      isTranscodingComplete: PropTypes.bool,
    })
  ),
  selectedRow: PropTypes.string,
  contextMenuItems: PropTypes.arrayOf(PropTypes.node),
  handleRowClick: PropTypes.func,
  openSideBar: PropTypes.func,
  updateSelected: PropTypes.func,
  detailedView: PropTypes.func,
};

AssetsTable.defaultProps = {
  assets: [],
  selectedRow: null,
  contextMenuItems: [],
  handleRowClick: () => {},
  openSideBar: () => {},
  updateSelected: () => {},
  detailedView: () => {},
};

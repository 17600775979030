import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MicrosoftImage from 'images/microsoftLogo.png';
import { Button, Typography } from '@mui/material';
import { useStyles } from './styles';

export const SignInButton = ({ options, optionHandler }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <div className={classes.buttonContainer}>
      <div className={classes.buttonContent}>
        <Button className={classes.buttonStyle} onClick={() => setOpen(!open)}>
          <img className={classes.styledImage} alt="Sign in with Microsoft" src={MicrosoftImage} />
          <Typography className={classes.textButton}>Sign in with Microsoft</Typography>
        </Button>
        {open && (
          <div className={classes.optionsContent}>
            {options.map((option) => (
              <Typography
                className={classes.textOption}
                key={option.id}
                onClick={() => {
                  optionHandler(option);
                  setOpen(false);
                }}
              >
                {option.name}
              </Typography>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

SignInButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.arrayOf(PropTypes.any),
  optionHandler: PropTypes.func,
};

SignInButton.defaultProps = {
  options: [],
  optionHandler: () => {},
};

import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  infoSection: {
    position: 'relative',
  },
  editButton: {
    position: 'absolute',
    right: '0px',
    top: '10px',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > *': {
      flex: 1,
    },
  },
  uploadItem: {
    display: 'grid',
    // gridTemplateColumns: '60px 90px auto 40px',
    gridTemplateColumns: '50px 90px auto',
    alignItems: 'center',
    marginBottom: theme.spacing(0.4),
  },
  shortText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  clockIcon: {
    minWidth: 32,
    minHeight: 32,
    width: 32,
    height: 32,
    padding: 8,
    boxShadow: 'none!important',
    cursor: 'default',
    pointerEvents: 'none',
  },
});

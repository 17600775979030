import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { FormHelperText } from '../FormHelperText';
import { FormLabel } from '../FormLabel';
import { StyledDiv, StyledIcon, StyledInputElement } from './styles';

export const FormInput = forwardRef(
  (
    {
      error,
      disabled,
      label,
      helpText,
      placeholder,
      value,
      onChange,
      icon: IconComponent,
      type,
      className,
      required,
      prepend,
      ...rest
    },
    forwardedRef
  ) => (
    <StyledDiv className={className}>
      {label && (
        <FormLabel error={error} disabled={disabled} required={required}>
          {label}
        </FormLabel>
      )}
      <StyledInputElement
        type={type}
        ref={forwardedRef}
        value={value}
        placeholder={placeholder}
        error={error}
        disabled={disabled}
        hasIcon={!!IconComponent}
        onChange={onChange}
        required={required}
        prepend={prepend}
        {...rest}
      />
      {IconComponent && (
        <StyledIcon label={label} prepend={prepend}>
          <IconComponent size={18} />
        </StyledIcon>
      )}
      {helpText && (
        <FormHelperText error={error} disabled={disabled} required={required}>
          {helpText}
        </FormHelperText>
      )}
    </StyledDiv>
  )
);

FormInput.propTypes = {
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  helpText: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.oneOf([
    'text',
    'email',
    'password',
    'date',
    'datetime-local',
    'file',
    'image',
    'number',
    'tel',
    'search',
    'color',
    'month',
    'url',
    'week',
  ]),
  icon: PropTypes.func,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  prepend: PropTypes.bool,
};

FormInput.defaultProps = {
  error: false,
  className: null,
  disabled: false,
  label: null,
  helpText: null,
  required: false,
  value: null,
  type: 'text',
  icon: null,
  placeholder: '',
  onChange: () => {},
  prepend: false,
};

/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-markers';
import 'videojs-markers/dist/videojs.markers.css';
import './styles.css';
import { Map } from 'components/Map/Map';
import ProjectService from 'services/ProjectService';
import SplashImage from 'images/ContentSplash.png';
import { WidgetSplash } from 'components/WidgetSplash/WidgetSplash';
import { Typography } from 'components/Typography';
import qualitySelector from '@silvermine/videojs-quality-selector';
import '@silvermine/videojs-quality-selector/dist/css/quality-selector.css';
import { ObservationPopup } from 'components/Observation/ObservationPopup';
import { useDispatch, useSelector } from 'react-redux';
import { setObservations } from 'slices/observationsReducer';
import { ObservationModal } from 'components/Observation/ObservationModal/ObservationModal';
import { Button, Grid, IconButton } from '@mui/material';
import { ArrowLeftIcon, ArrowRightIcon, PencilIcon, AlertTriangleIcon } from 'components/Icons';
import { SkipButton } from 'components/SkipButton/SkipButton';
import { useStyles } from 'pages/Profile/SingleProject/ProjectVideoPlayer/styles';
import { setProjectAsset } from 'slices/profileProjectAssetReducer';
import { ButtonCreateProjectAssetInspectionFinding } from 'components/ProjectDetail/ProjectAssets/ProjectAssetDetail/ProjectAssetInspections/ButtonCreateProjectAssetInspectionFinding';
import {
  SaveProjectAssetInspectionProgress,
  splitVideoTime,
} from 'components/Inspections/ProjectInspections/SaveProjectAssetInspectionProgress';
import { assetType } from 'components/Inspections/helpers';
import { DialogConfirmDownload } from 'components/VideoPlayer/DialogConfirmDownload';
import { DialogMessage } from 'components/DialogMessage';

const CREDITS_ARCHIVE_VIEW = 3;

export const VideoPlayer = ({ projectAsset, projectId }) => {
  const classes = useStyles();
  qualitySelector(videojs);
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const [isCreateObservationOpen, setIsCreateObservationOpen] = useState(false);
  const [currentTime, setCurrentTime] = useState(null);
  const [currentIndicator, setCurrentIndicator] = useState(0);
  const [observation, setObservation] = useState({ name: null });
  const [points, setPoints] = useState([]);
  const dispatch = useDispatch();
  const { detail } = useSelector((state) => state.projectAssetActiveInspections);
  const [timeButtons, setTimeButtons] = useState({ time: 0, percent: 0 });
  const [ignoreArchive, setIgnoreArchive] = React.useState(false);
  const [viewDownload, setViewDownload] = React.useState(false);
  const [credits, setCredits] = React.useState(0);
  const [creditError, setCreditError] = React.useState(false);

  const SizeCal = () => {
    let numbers = [];
    if (projectAsset.sequences) {
      numbers = projectAsset.sequences.map((as) => as.asset.assetFiles.reduce((total, file) => total + file.size, 0));
    } else {
      numbers = [
        projectAsset.sequence.asset
          ? projectAsset.sequence.asset?.assetFiles?.reduce((total, file) => total + file.size, 0)
          : 0,
      ];
    }
    const summation =
      numbers.length > 0 ? numbers.reduce((accumulator, currentValue) => accumulator + currentValue) : 0;
    return Math.ceil(summation / 1000 / 1000 / 1000) * CREDITS_ARCHIVE_VIEW;
  };

  React.useEffect(() => {
    setCredits(SizeCal());
    setIgnoreArchive(false);
  }, [projectAsset]);

  const videoJsOptions = {
    autoplay: false,
    muted: true,
    controls: true,
    responsive: true,
    inactivityTimeout: false,
    playbackRates: [1, 1.25, 1.5, 2],
    controlBar: {
      children: [
        'playToggle',
        'currentTimeDisplay',
        'timeDivider',
        'durationDisplay',
        'progressControl',
        'playbackRateMenuButton',
        'QualitySelector',
        'volumePanel',
        'fullscreenToggle',
      ],
    },
  };

  // --------------------- validate if there is 720 resolution
  const selectVideoResolution = () => {
    const resolutions = [];
    projectAsset.sequences[currentIndicator].asset.assetFiles.forEach((f) => {
      resolutions.push(f.resolution);
    });
    return !!resolutions.find((element) => element === '720');
  };

  // calculate time for buttons
  const calculateTimeButtons = (duration, time) => {
    const auxTimeButtons = splitVideoTime(duration);
    // eslint-disable-next-line no-restricted-syntax
    for (const element of auxTimeButtons) {
      if (time <= element) {
        setTimeButtons({ time, nextTime: element, duration, firstTime: auxTimeButtons[0] });
        break;
      }
    }
  };

  useEffect(() => {
    // make sure Video.js player is only initialized once
    // if (playerRef.current) return;
    const videoElement = videoRef.current;
    if (!videoElement) return;

    if (!projectAsset.sequences) {
      return;
    }
    const player = videojs(videoRef.current, videoJsOptions, () => {
      playerRef.current = player;
      const assetVideo = projectAsset.sequences[currentIndicator].asset.assetFiles.map((f, index) => ({
        src: projectAsset.sequences[currentIndicator].asset.signedURLPrefix.replace(':filename', f.fileName),
        type: 'video/mp4',
        label: f.resolution,
        res: f.resolution,
        selected: selectVideoResolution()
          ? f.resolution === '720'
          : projectAsset.sequences[currentIndicator].asset.assetFiles.length - 1 === index,
      }));

      player.src(assetVideo.sort((a, b) => b.label - a.label));
      player.on('timeupdate', () => {
        const video = videoRef.current;
        if (!video) return;
        calculateTimeButtons(video.duration, player.currentTime());
        const time = Math.floor(player.currentTime() || 0) === 0 ? 0 : Math.floor(player.currentTime());
        setCurrentTime(time);
        // dispatch(setObservationTime(time));
      });
    });

    if (playerRef.current) {
      playerRef.current.markers.reset(
        projectAsset.sequences[currentIndicator].observations.map((obs) => ({
          time: Number(obs.time),
          name: obs.name,
          text: obs.name,
          description: obs.description,
          id: obs.id,
          signedUrl: obs.signedUrl,
          lat: obs.lat,
          lng: obs.lng,
          sequence: projectAsset.sequences[currentIndicator],
        }))
      );
    }

    if (currentIndicator === 0 && !playerRef.current) {
      player.markers({
        markerStyle: {
          width: '8px',
          'background-color': 'white',
        },
        markerTip: {
          display: true,
          text(markers) {
            return markers.name;
          },
          name(markers) {
            return markers.name;
          },
          description(markers) {
            return markers.description;
          },
          signedUrl(markers) {
            return markers.signedUrl;
          },
          time(markers) {
            return markers.time;
          },
          lat(markers) {
            return markers.lat;
          },
          lng(markers) {
            return markers.lng;
          },
          sequence(markers) {
            return markers.sequence;
          },
        },
        onMarkerClick(marker) {
          setObservation(marker);
          player.pause();
        },
        markers: projectAsset.sequences[currentIndicator].observations.map((obs) => ({
          time: Number(obs.time),
          name: obs.name,
          text: obs.name,
          description: obs.description,
          id: obs.id,
          signedUrl: obs.signedUrl,
          lat: obs.lat,
          lng: obs.lng,
          sequence: projectAsset.sequences[currentIndicator],
        })),
      });
    }

    const observations = [];
    if (projectAsset.sequences[0].asset.type === 'VIDEO') {
      projectAsset.sequences[0].observations.forEach((item) => {
        const obj = { ...item };
        obj.sequence = { ...projectAsset.sequences[0] };
        obj.projectId = projectAsset?.project?.id;
        obj.asset = projectAsset;
        observations.push(obj);
      });
    }
    dispatch(setObservations(observations));
  }, [currentIndicator, ignoreArchive]);

  const handleCreateObservationOpen = () => {
    setIsCreateObservationOpen(true);
  };

  const handleCreateObservationClose = () => {
    setIsCreateObservationOpen(false);
  };

  const handleOnObservationClick = () => {
    videoRef.current.pause();
    setIsCreateObservationOpen(true);
  };

  useEffect(() => {
    setPoints([]);
    setTimeButtons({ time: 0, percent: 0 });
  }, []);

  useEffect(() => {
    if (projectAsset?.sequences && projectAsset?.sequences.length > 0) {
      let auxPopoints = [];
      projectAsset?.sequences?.forEach((seq) => {
        auxPopoints = [
          ...auxPopoints,
          ...seq.spatialPoints.map((point) => ({
            id: point.time,
            spatialPointId: point.id,
            time: point.time,
            sequenceIndex: seq.ordinal,
            location: [point.longitude, point.latitude],
            heading: point.hasHeading ? point.heading : 0,
            sequenceId: seq.id,
            longitude: point.longitude,
            latitude: point.latitude,
            sequence: seq,
          })),
        ];
      });
      auxPopoints = auxPopoints.sort((a, b) => a.id - b.id);
      setPoints(auxPopoints);
      dispatch(setProjectAsset({ ...projectAsset, points: auxPopoints }));
    }
  }, []);

  const handlePreviousSequence = () => {
    setCurrentIndicator(currentIndicator - 1);
  };

  const handleNextSequence = () => {
    setCurrentIndicator(currentIndicator + 1);
  };

  const handleViewAsset = async () => {
    // console.log(projectAsset);
    try {
      await ProjectService.viewArchive(projectAsset.projId, projectAsset.id, credits);
      setIgnoreArchive(true);
    } catch (e) {
      if (e.message.indexOf('code 402') >= 0) {
        setCreditError(true);
      }
    }
    setViewDownload(false);
  };

  const skip = (value) => {
    videoRef.current.currentTime += value;
  };

  return (
    <>
      {(!projectAsset.archived || ignoreArchive) && (
        <Grid item lg={8} md={8} sm={12} xs={12} style={{ position: 'relative' }} className={classes.fullHeight}>
          <ButtonCreateProjectAssetInspectionFinding
            time={currentTime}
            points={points}
            action="creation"
            selectedSequence={currentIndicator}
            type={assetType.VIDEO}
          />
          {!detail?.id && (
            <Button
              sx={{ bottom: '80px', left: '5px', position: 'absolute', zIndex: 1 }}
              onClick={handleOnObservationClick}
              color="secondary"
              variant="contained"
              title="Create an Observation"
            >
              <PencilIcon size={20} />
            </Button>
          )}
          <SaveProjectAssetInspectionProgress
            progress={timeButtons}
            projectAsset={projectAsset}
            handleStopVideo={() => videoRef.current.pause()}
            type={assetType.VIDEO}
          />
          <div data-vjs-player>
            <video ref={videoRef} className="video-js vjs-big-play-centered" />
            <SkipButton
              style={{ bottom: '80px', left: '65px', position: 'absolute', backgroundColor: '#FAA800' }}
              onClick={() => skip(-10)}
              type="backward"
              title="Rewind video"
            />
            <SkipButton
              style={{ bottom: '80px', left: '120px', position: 'absolute', backgroundColor: '#FAA800' }}
              onClick={() => skip(10)}
              type="forward"
              title="Advance video"
            />
            <ObservationPopup
              observation={observation}
              type="video"
              handleObservationClose={() => setObservation({ name: '' })}
            />
            {isCreateObservationOpen && (
              <ObservationModal
                isOpen={isCreateObservationOpen}
                handleOpen={handleCreateObservationOpen}
                handleClose={handleCreateObservationClose}
                time={currentTime}
                selectedSequence={currentIndicator}
                points={points}
                action="creation"
              />
            )}
          </div>
        </Grid>
      )}
      {projectAsset.archived && !ignoreArchive && (
        <Grid item lg={8} md={8} sm={12} xs={12} style={{ position: 'relative' }} className={classes.fullHeight}>
          <WidgetSplash
            alt="Archived File"
            title="This file is archived"
            cta="VIEW VIDEO"
            image={SplashImage}
            onClick={() => setViewDownload(true)}
          />
        </Grid>
      )}
      <Grid item lg={4} md={4} sm={12} xs={12} className={classes.fullHeight}>
        {points && points?.length > 0 && (
          <Map
            points={points}
            videoRef={playerRef}
            videoView
            sequenceLength={projectAsset?.sequences?.length}
            geometryOrgId={projectAsset?.project?.orgId}
            projectId={projectId}
          />
        )}
        {projectAsset?.sequences?.length > 1 ? (
          <div className={classes.mapControls}>
            <IconButton
              className={classes.arrowSequence}
              onClick={() => handlePreviousSequence()}
              disabled={currentIndicator === 0}
              color="secondary"
            >
              <ArrowLeftIcon size={24} />
            </IconButton>
            <Typography>Series Segment</Typography>
            <IconButton
              className={classes.arrowSequence}
              onClick={() => handleNextSequence()}
              disabled={projectAsset.sequences.length - 1 === currentIndicator}
              color="secondary"
            >
              <ArrowRightIcon size={24} />
            </IconButton>
          </div>
        ) : null}
      </Grid>
      <DialogConfirmDownload
        isOpen={viewDownload}
        onConfirm={handleViewAsset}
        onCancel={() => setViewDownload(false)}
        credits={credits}
        data={{ id: projectId }}
        openPerms={projectAsset.ignorePerms}
      />
      <DialogMessage
        icon={AlertTriangleIcon}
        title="Not enough credits"
        content="Your account does not have enough credits to view this archived file."
        isOpen={creditError}
        confirmText="Ok"
        iconColor="warning"
        onConfirm={() => setCreditError(false)}
      />
    </>
  );
};

VideoPlayer.propTypes = {
  projectAsset: PropTypes.oneOfType([PropTypes.object]).isRequired,
  projectId: PropTypes.string,
};

VideoPlayer.defaultProps = {
  projectId: null,
};

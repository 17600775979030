import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const ArrowCurvedIcon = ({ size }) => (
  <Svg viewBox="0 0 24 24" size={size}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 9.33333L14.6667 18.6667V13.2C8 13.2 3.33333 15.3333 0 20C1.33333 13.3333 5.33333 6.66667 14.6667 5.33333V0L24 9.33333Z"
      fill="currentColor"
    />
  </Svg>
);
ArrowCurvedIcon.propTypes = {
  size: PropTypes.number,
};

ArrowCurvedIcon.defaultProps = {
  size: 24,
};

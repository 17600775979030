import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useRef } from 'react';
import { FormLabel } from '../FormLabel';
import { Check, InputContainer } from './styles';

function useCombinedRefs(...refs) {
  const targetRef = useRef();

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
}

export const FormCheckbox = forwardRef(
  ({ checked, disabled, value, label, onChecked, marginNone, paddingLeft, ...rest }, forwardedRef) => {
    const innerRef = useRef(null);
    const combinedRef = useCombinedRefs(forwardedRef, innerRef);

    const labelProps = {};

    if (rest.name) {
      labelProps.htmlFor = rest.name;
    }

    return (
      <Check
        onClick={() =>
          !disabled &&
          onChecked({
            checked: !checked,
            value,
          })
        }
        disabled={disabled}
        marginNone={marginNone}
        paddingLeft={paddingLeft}
      >
        <InputContainer>
          <input type="checkbox" value={value} ref={combinedRef} checked={checked} disabled={disabled} {...rest} />
          <div>
            <span />
          </div>
        </InputContainer>
        {label && (
          <FormLabel disabled={disabled} {...labelProps} noSpacing>
            {label}
          </FormLabel>
        )}
      </Check>
    );
  }
);

FormCheckbox.propTypes = {
  /**
   * checked property determines if the
   * Checkbox should be checked or not
   * ex. ```<Checkbox checked={true} />```
   */
  checked: PropTypes.bool.isRequired,
  /**
   * Disabled property determines if the
   * Checkbox should be disabled.
   * ex. ```<Checkbox disabled={false} />```
   */
  disabled: PropTypes.bool,
  /**
   * Value property determines the
   * value of the checkbox.
   * ex. ```<Checkbox value={'hello'} />```
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * onChecked is a function that is called
   * when the checkbox state is changed.
   */
  onChecked: PropTypes.func,
  /**
   * Label property determines the
   * label of the checkbox.
   * ex. ```<Checkbox label={'Hello World'} />```
   */
  label: PropTypes.string,
  marginNone: PropTypes.bool,
  paddingLeft: PropTypes.number,
};

FormCheckbox.defaultProps = {
  disabled: false,
  marginNone: false,
  paddingLeft: null,
  value: null,
  label: null,
  onChecked: () => {},
};

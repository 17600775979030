import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, IconButton, Typography } from '@mui/material';
// import { cutUuidText } from 'lib/generalFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useGlobalStyles } from 'styles';
import { UploadCloudIcon, AlertCircleIcon } from 'components/Icons';

export const MessageAlert = ({ id, handleClose, message, title, type }) => {
  const classes = useGlobalStyles();

  // ---------------- copy my uuid
  useEffect(() => {
    navigator.clipboard.writeText(id);
  }, []);

  return (
    <Dialog open onClose={handleClose} maxWidth="xs">
      <DialogContent className={type === 1 ? classes.copyBox : classes.errorBox}>
        <div className={type === 1 ? classes.copyIcons : classes.errorIcons}>
          {type === 1 && <UploadCloudIcon size={20} />}
          {type !== 1 && <AlertCircleIcon size={20} />}
          <IconButton onClick={handleClose}>
            <FontAwesomeIcon icon={faClose} size="lg" />
          </IconButton>
        </div>
        <Typography className={type === 1 ? classes.copyTitle : classes.errorTitle}>{title}</Typography>
        <Typography className={type === 1 ? classes.copyText : classes.errorText}>{message}</Typography>
      </DialogContent>
    </Dialog>
  );
};

MessageAlert.propTypes = {
  id: PropTypes.arrayOf(PropTypes.number),
  handleClose: PropTypes.func,
  message: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.number,
};

MessageAlert.defaultProps = {
  id: null,
  handleClose: () => {},
  message: 'message',
  title: 'title',
  type: 1,
};

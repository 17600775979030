import ApiService from './ApiService';

export default class ExternalService {
  static getExternalData = (id, type, body = {}) => ApiService.get(`/admin/external/${type}s/${id}`, body);

  static postExternalData = (body, type) => ApiService.post(`/admin/external/${type}s`, body);

  static getExternalList = async (filters, type) => ApiService.get(`/admin/external/${type}`, filters);

  static removeExternalData = async (id, type) => ApiService.delete(`/admin/external/${type}s/${id}`);

  static getProjectAccounts = (id) => ApiService.get(`/admin/external/projects/${id || null}/accounts`);

  static getProjectOrganizations = (id) => ApiService.get(`/admin/external/projects/${id || null}/organizations`);

  static getTeamAccounts = (id) => ApiService.get(`/admin/external/teams/${id || null}/accounts`);

  static getTeamOrganizations = (id) => ApiService.get(`/admin/external/teams/${id || null}/organizations`);

  static getUserAccounts = (id) => ApiService.get(`/admin/external/users/${id || null}/accounts`);

  static getUserOrganizations = (id) => ApiService.get(`/admin/external/users/${id || null}/organizations`);

  static getAssetsAccounts = () => ApiService.get(`/admin/external/assets/accounts`);
}

import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const UploadCloudIcon = ({ size }) => (
  <Svg viewBox="0 0 24 24" size={size}>
    <path
      id="Icon"
      d="M8 16L12 12M12 12L16 16M12 12V21M20 16.7428C21.2215 15.734 22 14.2079 22 12.5C22 9.46243 19.5376 7 16.5 7C16.2815 7 16.0771 6.886 15.9661 6.69774C14.6621 4.48484 12.2544 3 9.5 3C5.35786 3 2 6.35786 2 10.5C2 12.5661 2.83545 14.4371 4.18695 15.7935"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </Svg>
);

UploadCloudIcon.propTypes = {
  size: PropTypes.number,
};

UploadCloudIcon.defaultProps = {
  size: 24,
};

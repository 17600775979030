import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { useGlobalStyles } from 'styles';
import ProjectService from 'services/ProjectService';
import { useSelector } from 'react-redux';
import { actions, inspectionType } from '../helpers';
import { useStyles } from './styles';

export const IntialForm = ({ formik, openAddFields, loading, action, actionType, userId, setUserId }) => {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const projectAsset = useSelector((state) => state.profileProjectAssets.projectAsset);
  const [usersOptions, setUsersOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // get users to assing in projAssetInsp
  const getUsers = async () => {
    setIsLoading(true);
    try {
      const result = await ProjectService.getProjectUsers(projectAsset.project?.id);
      const users = result?.data?.data.map((user) => ({
        label: `${user.firstName} ${user.lastName}`,
        value: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
      }));
      setUsersOptions(users);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setUsersOptions([]);
    }
  };

  useEffect(() => {
    if (actionType === inspectionType.PROJECTASSET && action === actions.CREATE) getUsers();
  }, []);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <InputLabel>Name *</InputLabel>
        <TextField
          placeholder="Name"
          disabled={loading}
          id="name"
          name="name"
          value={formik.values.name}
          error={!!(formik.touched.name && formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name ? formik.errors.name : null}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <InputLabel>Description *</InputLabel>
        <TextField
          placeholder="Description"
          disabled={loading}
          id="description"
          name="description"
          value={formik.values.description}
          error={!!(formik.touched.description && formik.errors.description)}
          helperText={formik.touched.description && formik.errors.description ? formik.errors.description : null}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          required
          fullWidth
        />
      </Grid>
      {actionType === inspectionType.PROJECTASSET && action === actions.CREATE && (
        <Grid item xs={12}>
          <InputLabel>
            <Typography>Assign To Person</Typography>
          </InputLabel>
          <FormControl error={formik.touched.userId && formik.errors.userId} fullWidth>
            <Autocomplete
              loading={isLoading}
              options={usersOptions}
              value={usersOptions.find((item) => item.value === userId?.value)}
              name="userId"
              getOptionLabel={(option) => option.label}
              renderInput={(params) => <TextField {...params} placeholder="Select" />}
              onChange={(e, value) => {
                setUserId(null);
                if (value?.value) {
                  setUserId(value);
                }
              }}
            />
            <Typography className={classes.grayText}>Leave unassigned to make assignments later.</Typography>
            <FormHelperText>
              {formik.touched.userId && formik.errors.userId ? formik.errors.userId : null}
            </FormHelperText>
          </FormControl>
        </Grid>
      )}
      {!openAddFields && (
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={<Switch color="secondary" />}
              label={
                <div>
                  <Typography>Add additional (specialized) fields?</Typography>
                  <Typography variant="caption" className={globalClasses.greyTypography}>
                    If on, filed option list will appear below.
                  </Typography>
                </div>
              }
              value="isOpen"
              onChange={() => {
                formik.setFieldValue('addFields', true);
                formik.handleSubmit();
              }}
              checked={formik.values.addFields}
            />
          </FormGroup>
        </Grid>
      )}
    </Grid>
  );
};

IntialForm.propTypes = {
  formik: PropTypes.object.isRequired,
  openAddFields: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  action: PropTypes.string.isRequired,
  actionType: PropTypes.string.isRequired,
  userId: PropTypes.string,
  setUserId: PropTypes.func,
};

IntialForm.defaultProps = {
  userId: null,
  setUserId: () => {},
};

import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { dateRangeSelector, preloadFilterDatasets, setFilterValue, setFilters } from 'slices/teamProjectsReducer';
import { SearchPanelBody, searchPanelSection } from 'pages/Projects/SearchPanel/SearchPanelBody';

export const SearchPanel = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const nameSort = useSelector((state) => state.teamProjects.filters.sort_by.name || '');
  const dateSort = useSelector((state) => state.teamProjects.filters.sort_by.createdAt || '');
  const orgSort = useSelector((state) => state.teamProjects.filters.sort_by.org || '');
  const acctId = useSelector((state) => state.teamProjects.filters.acctId || '');
  const accountSort = useSelector((state) => state.teamProjects.filters.sort_by.account || '');
  const organizationId = useSelector((state) => state.teamProjects.filters.organizationId || '');
  const isOpen = useSelector((state) => state.teamProjects.filters.isOpen);
  const participantLevel = useSelector((state) => state.teamProjects.filters.participantLevel || '');
  const dateRange = useSelector(dateRangeSelector);
  const { teams, accounts, organizations, loaded } = useSelector((state) => state.teamProjects.panels.search);

  useEffect(() => {
    dispatch(preloadFilterDatasets(params.teamId));
  }, []);

  return (
    <SearchPanelBody
      loaded={loaded}
      teams={teams}
      accounts={accounts}
      organizations={organizations}
      filters={{
        sort_by: {
          name: nameSort,
          createdAt: dateSort,
          org: orgSort,
          account: accountSort,
        },
        acctId,
        organizationId,
        participantLevel,
        date_range: dateRange,
        isOpen,
      }}
      setFilterValue={setFilterValue}
      setFilters={setFilters}
      section={searchPanelSection.TEAMPROJECTS}
    />
  );
};

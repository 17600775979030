import { Button } from 'components/Button/Button';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Ahead from '../../images/ahead.png';
import Back from '../../images/back.png';

const StyledButton = styled(Button)`
  background-color: transparent;
  border-color: transparent;
  opacity: 0.5;
  :hover {
    opacity: 1;
  }
`;

export const SkipButton = ({ onClick, type, ...rest }) => (
  <StyledButton onClick={onClick} {...rest}>
    {type === 'forward' ? (
      <img src={Ahead} width="20px" height="20px" alt="F" />
    ) : (
      <img src={Back} width="20px" height="20px" alt="B" />
    )}
  </StyledButton>
);

SkipButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  content: {
    height: '100%',
    width: '100%',
    borderTop: `1px solid ${theme.palette.primary[700]}`,
    '& .confimButtonsContainer': {
      display: 'flex',
      gap: 8,
      justifyContent: 'end',
      '& button': {
        padding: '8px!important',
        color: theme.palette.primary[100],
        width: 30,
        height: 30,
        minHeight: 30,
        minWidth: 30,
      },
    },
    '& .instructionText': {
      color: theme.palette.primary[300],
      minHeight: '30px',
      display: 'flex',
      alignItems: 'normal',
    },
    '& .content-text-button': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 8,
    },
  },
  leftColumn: {
    padding: theme.spacing(1),
    background: theme.palette.primary[1400],
    height: '100%',
  },
  rightColumn: {
    padding: theme.spacing(1),
    background: theme.palette.primary[1200],
    height: '100%',
    overflowY: 'auto',
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.primary[900]}`,
    width: '100%',
    margin: `${theme.spacing(2)} 0`,
  },
  visibilityContent: {
    padding: `0 ${theme.spacing(0.5)}`,
  },
  sharingContent: {
    padding: `${theme.spacing(0.5)} ${theme.spacing(0.5)}`,
    background: theme.palette.primary[1300],
    borderRadius: 8,
  },
  visibilityButton: {
    width: '100%',
    textAlign: 'right',
    marginTop: theme.spacing(0.5),
  },
});

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ProjectService from 'services/ProjectService';
import TeamService from 'services/TeamService';
import UserService from 'services/UserService';

export const fetchTeams = createAsyncThunk('profile-teams/fetchTeams', async (filterOpts, { getState }) => {
  const filtersOpts = {
    ...filterOpts,
    include: ['users', 'org', 'account'],
    userId: getState().auth.userId,
  };
  delete filtersOpts.activated;
  const response = await TeamService.getTeams(filtersOpts);

  return response.data;
});

export const fetchTeam = createAsyncThunk(
  'profile-team/fetchTeam',
  async (teamId) => {
    const [team, users, projects] = await Promise.all([
      await TeamService.getTeam(teamId),
      await UserService.getUsers({ teamId, page_size: 100 }),
      await ProjectService.getProjects({ teamId, page_size: 100 }),
    ]);

    return {
      team: team.data,
      users: users.data,
      projects: projects.data,
    };
  },
  {
    condition: (teamId, { getState }) => getState().profileTeams.panels.teamId !== teamId,
  }
);

export const preloadFilterDatasets = createAsyncThunk(
  'profileTeams/preloadFilterDatasets',
  async (userId) => {
    try {
      const [accounts, organizations, projects] = await Promise.all([
        await TeamService.getTeamAccounts(userId),
        await TeamService.getTeamOrganizations(userId),
        await ProjectService.getProjects({ page_size: 1000, userId }),
      ]);
      return { accounts: accounts.data.data, organizations: organizations.data.data, projects: projects.data.data };
    } catch (err) {
      throw new Error('error loading filter data');
    }
  },
  {
    condition: (_, { getState }) => getState().profileTeams.panels.search.loaded === false,
  }
);

const orderByName = (array) =>
  array.sort((a, b) => {
    const nameA = a.label.toUpperCase(); // ignore upper and lowercase
    const nameB = b.label.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

const initialState = {
  teams: [],
  teamCount: 0,
  loading: true,
  error: null,
  panels: {
    teamId: null,
    loading: true,
    detail: {},
    users: [],
    projects: [],
    search: {
      loaded: false,
      accounts: [],
      organizations: [],
      users: [],
      projects: [],
    },
  },
  filters: {
    page: 1,
    page_size: 10,
    sort_by: {},
    search: '',
    acctId: '',
    organizationId: '',
  },
};

const profileTeamsReducer = createSlice({
  name: 'profile-teams',
  initialState,
  reducers: {
    resetProfileTeams: () => initialState,
    setPageFilter(state, action) {
      state.filters.page = action.payload;
    },
    setSearch(state, action) {
      state.filters.search = action.payload;
      state.filters.page = 1;
    },
    setAcctId(state, action) {
      state.filters.acctId = action.payload.value;
    },
    setOrganizationId(state, action) {
      state.filters.organizationId = action.payload.value;
    },
    setSortBy(state, { payload }) {
      const { label, value } = payload;
      if (value === '' || value === null) {
        delete state.filters.sort_by[label];
      } else {
        state.filters.sort_by[label] = value;
      }
    },
    setParticipantLevel(state, { payload }) {
      const { value } = payload;
      if (value === '' || value === null) {
        delete state.filters.userRole;
      } else {
        state.filters.userRole = value;
      }
    },
    setFilterValue(state, { payload }) {
      const { param, value } = payload;
      if (value === '' || value === null) {
        delete state.filters[param];
        return;
      }
      state.filters[param] = payload.value;
    },
    setFilters(state, action) {
      const filter = { ...state.filters, ...action.payload, page: 1 };
      if (!filter?.userRole) delete filter?.userRole;
      state.filters = filter;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchTeams.fulfilled, (state, { payload }) => {
      state.teams = payload.data;
      state.teamCount = payload.meta.totalCount;
      state.loading = false;
    });

    builder.addCase(fetchTeams.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchTeams.rejected, (state) => {
      state.loading = false;
      state.teams = [];
      state.error = 'unable to load Teams';
    });

    builder.addCase(fetchTeam.pending, (state) => {
      state.panels.loading = true;
      state.panels.teamId = null;
    });

    builder.addCase(fetchTeam.fulfilled, (state, { payload }) => {
      state.panels.teamId = payload.team.data.id;
      state.panels.detail = payload.team.data;
      state.panels.users = payload.users.data;
      state.panels.projects = payload.projects.data;
      state.panels.loading = false;
    });

    builder.addCase(fetchTeam.rejected, (state) => {
      state.panels.detail = {};
      state.panels.error = 'unable to load team data';
      state.panels.users = [];
      state.panels.projects = [];
      state.panels.loading = false;
    });

    builder.addCase(preloadFilterDatasets.pending, (state) => {
      state.panels.search.loaded = false;
    });
    builder.addCase(preloadFilterDatasets.rejected, (state) => {
      state.panels.search.organizations = [];
      state.panels.search.accounts = [];
      state.panels.search.projects = [];
      state.panels.search.loaded = false;
    });
    builder.addCase(preloadFilterDatasets.fulfilled, (state, { payload }) => {
      const { accounts, organizations, projects } = payload;
      state.panels.search.loaded = true;
      let newAccounts = accounts.map((account) => ({
        label: account.name,
        value: account.id,
      }));
      let newOrganizations = organizations.map((org) => ({
        label: org.name,
        value: org.id,
      }));
      let newProjects = projects.map((proj) => ({
        label: proj.name,
        value: proj.id,
      }));
      newAccounts = orderByName(newAccounts);
      newOrganizations = orderByName(newOrganizations);
      newProjects = orderByName(newProjects);
      state.panels.search.accounts = newAccounts;
      state.panels.search.organizations = newOrganizations;
      state.panels.search.projects = newProjects;
    });
  },
});

export const {
  setPageFilter,
  setSortBy,
  setSearch,
  setAcctId,
  setOrganizationId,
  setParticipantLevel,
  resetProfileTeams,
  setFilterValue,
  setFilters,
  // eslint-disable-next-line prettier/prettier
} = profileTeamsReducer.actions;

export default profileTeamsReducer.reducer;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { DialogMessage, dialogMessageAction } from 'components/DialogMessage';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import { ViewRoleValidation } from 'components/ViewRoleValidation/ViewRoleValidation';
import InspectionService from 'services/InspectionService';
import { InfoIcon } from 'components/Icons';
import { setProjectAssetInspections } from 'slices/projectAssetActiveInspectionReducer';
import { inspectionType } from 'components/Inspections/helpers';
import { getProjectActiveInspections } from 'slices/projectActiveInspectionsReducer';

export const DeleteProjectAssetInspection = ({ projectAssetInspection, type, handleClose }) => {
  const dispatch = useDispatch();
  const [openConfirm, setOpenConfirm] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validateRole, setValidateRole] = useState({ open: false, callBack: () => {} });
  const { data } = useSelector((state) => state.projectAssetActiveInspections);
  const { filters } = useSelector((state) => state.projectActiveInspections);

  const deletePoint = async () => {
    setOpenConfirm(false);
    setIsLoading(true);
    try {
      await InspectionService.deleteProjectAssetInspection(
        projectAssetInspection.id,
        projectAssetInspection.projectAsset?.projId
      );
      setIsLoading(false);
      if (type === inspectionType.PROJECT) {
        const newFilters = {
          ...filters,
          projectId: projectAssetInspection.projectAsset?.projId,
          include: ['uploader', 'inspection', 'assignment', 'projectAsset'],
        };
        dispatch(getProjectActiveInspections(newFilters));
      } else {
        const projectAssetInspections = [...data];
        const index = projectAssetInspections.findIndex((item) => item.id === projectAssetInspection.id);
        if (index !== -1) projectAssetInspections.splice(index, 1);
        dispatch(setProjectAssetInspections(projectAssetInspections));
      }
      handleClose();
    } catch (error) {
      setIsLoading(false);
      setOpenDialog('An error occurred while trying to delete the inspection.');
    }
  };

  useEffect(() => {
    setOpenConfirm(true);
  }, []);

  return (
    <>
      <DialogMessage
        title="Remove Inspection & Findings"
        content="This action will remove this inspection and its findings from this project asset."
        isOpen={!!openConfirm}
        onConfirm={deletePoint}
        onCancel={() => handleClose()}
        action={dialogMessageAction.delete}
      />
      <DialogMessage
        title={openDialog}
        isOpen={!!openDialog}
        icon={InfoIcon}
        confirmText="Ok"
        onConfirm={() => handleClose()}
      />
      {validateRole.open && (
        <ViewRoleValidation
          action={validateRole.action}
          data={projectAssetInspection}
          callBack={validateRole.callBack}
          handleClose={() => setValidateRole({ open: false, callBack: () => {} })}
        />
      )}
      <LoadingOverlay loading={isLoading} />
    </>
  );
};

DeleteProjectAssetInspection.propTypes = {
  projectAssetInspection: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  handleClose: PropTypes.func,
};

DeleteProjectAssetInspection.defaultProps = {
  handleClose: () => {},
};

import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';

export const ProjectSharingForm = ({ formik, loading, handleChange }) => (
  <FormGroup>
    <FormControlLabel
      control={<Switch color="secondary" />}
      label="Disable Sharing with other projects."
      value="isSharingNotAllowed"
      onChange={(event) => {
        formik.setFieldValue('isSharingNotAllowed', event.target.checked);
        handleChange(true);
      }}
      checked={formik.values.isSharingNotAllowed}
      disabled={loading}
    />
    <FormControlLabel
      control={<Switch color="secondary" />}
      label="Disable Copying to other projects."
      value="isCopyingNotAllowed"
      onChange={(event) => {
        formik.setFieldValue('isCopyingNotAllowed', event.target.checked);
        handleChange(true);
      }}
      checked={formik.values.isCopyingNotAllowed}
      disabled={loading}
    />
  </FormGroup>
);

ProjectSharingForm.propTypes = {
  formik: PropTypes.object,
  loading: PropTypes.bool,
  handleChange: PropTypes.func,
};

ProjectSharingForm.defaultProps = {
  formik: null,
  loading: false,
  handleChange: () => {},
};

/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Box, LinearProgress, Typography } from '@mui/material';
import { usePagination, useSortBy, useTable } from 'react-table';
import { GeneralTable } from 'components/GeneralTable/GeneralTable';
import { Loader } from 'components/Loader';
import { TablePagination } from 'components/TablePagination/TablePagination';
import { ContextMenu } from 'components/ContextMenu';
import { ContextMenuButton } from 'components/ContextMenuButton';
import { ContextMenuList } from 'components/ContextMenuItems';
import { ContextMenuItem } from 'components/ContextMenuItem';
import { WidgetDivider } from 'components/WidgetDivider/WidgetDivider';
import { SaveInspection } from 'components/Inspections/SaveInspection/SaveInspection';
import { inspectionType, actions as saveActions } from 'components/Inspections/helpers';
import { setProjAssetInspection } from 'slices/findingReducer';
import { getProjectUsers } from 'slices/allProjectReducer';
import { getProjectInspections } from 'slices/projectInspectionReducer';
import { DeleteProjectAssetInspection } from 'components/ProjectDetail/ProjectAssets/ProjectAssetDetail/ProjectAssetInspections/DeleteProjectAssetInspection';
import { ProjectAssetInspectionFindings } from 'components/ProjectDetail/ProjectAssets/ProjectAssetDetail/ProjectAssetInspections/ProjectAssetInspectionFindings/ProjectAssetInspectionFindings';
import {
  setProjectAssetInspection,
  setProjectAssetInspectionPageFilter,
} from 'slices/projectAssetActiveInspectionReducer';
import { setPageFilter, setSearch } from 'slices/projectActiveInspectionsReducer';
import { TopBar } from 'components/TopBar/TopBar';
import { ExportFindings } from 'components/Findings/ExportFindings/ExportFindings';
import { ViewRoleValidation } from 'components/ViewRoleValidation/ViewRoleValidation';
import { useStyles } from './styles';
import { Assignment } from '../Assignment/Assignment';

const LinearProgressWithLabel = (props) => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <Box sx={{ width: '100%', mr: 0.3 }}>
      <LinearProgress color="secondary" variant="determinate" {...props} />
    </Box>
    <Box sx={{ minWidth: 35 }}>
      <Typography sx={{ color: 'white' }}>{`${Math.round(props.value > 100 ? 100 : props.value)}%`}</Typography>
    </Box>
  </Box>
);

const tableColumns = (type) => [
  ...(type === inspectionType.PROJECT
    ? [
        {
          Header: 'Title',
          accessor: (rowData) => {
            const { projectAsset } = rowData;
            return projectAsset?.name;
          },
        },
      ]
    : [
        {
          Header: 'Date',
          id: 'createdAt',
          accessor: (rowData) => {
            const { createdAt } = rowData;
            return createdAt ? moment(createdAt).format('MM/DD/YY') : 'date not found';
          },
        },
      ]),
  {
    Header: 'Inspection Name',
    accessor: (rowData) => {
      const { inspection } = rowData;
      return (
        <>
          <Typography>{inspection?.name}</Typography>
          <Typography>Description: {inspection?.description}</Typography>
        </>
      );
    },
  },
  {
    Header: 'Assigned To',
    accessor: (rowData) => <Assignment projAssetInspection={rowData} type={type} />,
  },
  ...(type === inspectionType.PROJECT
    ? [
        {
          Header: 'Assigned',
          accessor: (rowData) => {
            const { assignment } = rowData;
            return assignment?.assignee?.updatedAt
              ? moment(assignment?.assignee?.updatedAt).format('MM/DD/YY')
              : 'date not found';
          },
        },
      ]
    : []),
  {
    Header: 'Assigned By',
    accessor: (rowData) => {
      const { uploader } = rowData;
      return uploader ? `${uploader?.firstName} ${uploader?.lastName}` : 'user not found';
    },
  },
  {
    Header: '% Complete',
    accessor: (rowData) => {
      const { progress } = rowData;
      return <LinearProgressWithLabel value={progress?.id ? progress.percent : 0} />;
    },
  },
];

const actions = {
  EDIT: 'edit',
  VIEW: 'view',
  DELETE: 'delete',
  EXPORT_FINDINGS: 'export_findings',
};

export const ActiveInspections = ({ type }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const reducer = type === inspectionType.PROJECT ? 'projectActiveInspections' : 'projectAssetActiveInspections';
  const { loading, data, filters, totalCount, detail } = useSelector((state) => state[reducer]);
  const { projectAsset } = useSelector((state) => state.profileProjectAssets);
  const [action, setAction] = useState(null);
  const columns = React.useMemo(() => tableColumns(type), []);
  const search = useSelector((state) => state[reducer].filters.search || '');
  const handleSearch = (e) => dispatch(setSearch(e.target.value));
  const { userId } = useSelector((state) => state.auth);
  const [validateRole, setValidateRole] = useState({ open: false, callBack: () => {} });

  const validateInspection = (row, callBack) => {
    if (userId === row.assignment?.assignee?.id) {
      callBack();
      return;
    }
    setValidateRole({
      open: true,
      data: { id: row.projectAsset?.projId },
      action: [Permissions.PROJ_INSPECTIONS],
      callBack: () => callBack(),
    });
  };

  const table = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: filters.page - 1,
        pageSize: filters.page_size,
      },
      pageCount: Math.ceil(totalCount / filters.page_size),
      totalCount,
      pageLength: data.length,
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  const onCancel = () => {
    setAction(null);
  };

  const handleRowClick = (row) => {
    dispatch(setProjectAssetInspection(row.original));
  };

  const {
    state: { pageIndex, pageSize },
  } = table;

  useEffect(() => {
    dispatch(
      type === inspectionType.PROJECT
        ? setPageFilter(pageIndex + 1)
        : setProjectAssetInspectionPageFilter(pageIndex + 1)
    );
  }, [pageIndex, pageSize]);

  useEffect(() => {
    if (type === inspectionType.PROJECTASSET) {
      const newFilters = {
        projectId: projectAsset?.project?.id,
        page_size: 1000,
        page: 1,
        sort_by: {},
        date_range: null,
        isActive: { eq: 1 },
      };
      dispatch(getProjectInspections(newFilters));
      dispatch(getProjectUsers(projectAsset.project?.id));
    }
  }, []);

  useEffect(() => {
    if (type === inspectionType.PROJECTASSET && data.length > 0) {
      dispatch(setProjectAssetInspection(data[0]));
    }
  }, [data]);

  const ProjAssetInspectionActions = ({ row }) => (
    <ContextMenu>
      <ContextMenuButton />
      <ContextMenuList position="left">
        <ContextMenuItem
          onClick={() => {
            validateInspection(row.original, () => {
              dispatch(
                setProjAssetInspection({
                  ...row.original,
                  ...(type === inspectionType.PROJECTASSET && { projectAsset }),
                })
              );
            });
          }}
        >
          View Inspection Findings
        </ContextMenuItem>
        {type === inspectionType.PROJECTASSET ? (
          <ContextMenuItem
            onClick={() => {
              validateInspection(row.original, () => {
                setAction({
                  action: actions.EDIT,
                  inspection: {
                    ...row.original.inspection,
                    paiId: row.original.id,
                    ...(type === inspectionType.PROJECTASSET && { projectAsset }),
                  },
                });
              });
            }}
          >
            Edit Inspection Parameters
          </ContextMenuItem>
        ) : (
          <ContextMenuItem
            onClick={() => {
              validateInspection(row.original, () => {
                setAction({
                  action: actions.EXPORT_FINDINGS,
                  data: row.original,
                });
              });
            }}
          >
            Export Findings
          </ContextMenuItem>
        )}
        <WidgetDivider />
        <ContextMenuItem
          onClick={() => {
            validateInspection(row.original, () => {
              setAction({
                action: actions.DELETE,
                projectAssetInspection: {
                  ...row.original,
                  ...(type === inspectionType.PROJECTASSET && { projectAsset }),
                },
              });
            });
          }}
        >
          Remove Inspection & Findings
        </ContextMenuItem>
      </ContextMenuList>
    </ContextMenu>
  );

  ProjAssetInspectionActions.propTypes = {
    row: PropTypes.object.isRequired,
  };

  return (
    <>
      {loading && type === inspectionType.PROJECTASSET ? (
        <Loader height={50} loading size={32} />
      ) : (
        <div className={classes.table}>
          {type !== inspectionType.PROJECTASSET && (
            <TopBar searchValue={search} handleSearch={handleSearch} activatedFilter={filters.activated} />
          )}
          <GeneralTable
            table={table}
            rowComponent={ProjAssetInspectionActions}
            selectRow={false}
            actionHeaderText="More"
            autoHeight={type === inspectionType.PROJECTASSET}
            handleRowClick={handleRowClick}
            selectedRow={detail?.id}
          />
          {(type === inspectionType.PROJECT || totalCount > 500) && <TablePagination table={table} />}
          {action?.action === actions.EDIT && (
            <SaveInspection
              onCancel={onCancel}
              onConfirm={onCancel}
              action={saveActions.UPDATE}
              inspection={action.inspection}
              actionType={inspectionType.PROJECTASSET}
            />
          )}
          {action?.action === actions.DELETE && (
            <DeleteProjectAssetInspection
              handleClose={onCancel}
              projectAssetInspection={action.projectAssetInspection}
              type={type}
            />
          )}
          {action?.action === actions.EXPORT_FINDINGS && (
            <ExportFindings handleClose={onCancel} projAssetInspection={action.data} />
          )}
          <ProjectAssetInspectionFindings />
        </div>
      )}
      {validateRole.open && (
        <ViewRoleValidation
          action={validateRole.action}
          data={validateRole.data}
          callBack={validateRole.callBack}
          handleClose={() => setValidateRole({ open: false, callBack: () => {} })}
        />
      )}
    </>
  );
};

ActiveInspections.propTypes = {
  type: PropTypes.string.isRequired,
};

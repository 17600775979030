/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { usePagination, useSortBy, useTable } from 'react-table';
import { Bytes } from 'lib/bytes';
import moment from 'moment';
import { Card } from 'components/Card/Card';
import { useSidebar } from 'components/SidebarProvider/SidebarProvider';
import { TablePagination } from 'components/TablePagination/TablePagination';
import { TeamsSettingsContainer } from 'components/TeamsSettingsContainer/TeamsSettingsContainer';
import { ViewRoleValidation } from 'components/ViewRoleValidation/ViewRoleValidation';
import { WidgetSplash } from 'components/WidgetSplash/WidgetSplash';
import SplashImage from 'images/accounts_splash.png';
import TeamService from 'services/TeamService';
import { getProject, getProjects, setPageFilter, setSearch } from 'slices/teamProjectsReducer';
import { ModalProjectNew } from 'smartComponents/ModalProjectNew';
import { ProjectActions } from 'components/ProjectActions/ProjectActions';
import { DialogProjectInvite, typeInviteData } from 'components/DialogProjectInvite';
import { TopBar } from 'components/TopBar/TopBar';
import { GeneralTable } from 'components/GeneralTable/GeneralTable';
import { useSidebarDetail } from 'components/SidebarProviderDetail/SidebarProviderDetail';
import { ProjectDetail } from 'components/ProjectDetail/ProjectDetail';
import { getProject as getProjectDetail } from 'slices/allProjectReducer';
import { setProject as setProjectActive } from 'slices/projectActiveInspectionsReducer';
import { setProject } from 'slices/projectInspectionReducer';
import { ProjectInspections } from 'components/Inspections/ProjectInspections/ProjectInspections';
import { ProjectActiveInspections } from 'components/Inspections/ProjectInspections/ProjectActiveInspections';
import { SearchPanel } from './SearchPanel';
import { InfoPanel } from './InfoPanel';

let debounceTimeout = null;
const tableColumns = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Created',
    accessor: (rowData) => {
      const { createdAt } = rowData;
      return createdAt ? moment(createdAt).format('MM/DD/YY') : 'date not found';
    },
  },
  {
    Header: 'Organization',
    accessor: (rowData) => {
      const { organization } = rowData;
      return organization.name;
    },
  },
  {
    Header: 'Size',
    accessor: (rowData) => {
      const { fileTotalSize } = rowData;
      return Bytes.dynamicHumanReadable({ bytes: fileTotalSize });
    },
  },
  {
    Header: 'Files',
    accessor: 'fileHostedCount',
  },
];

export const TeamProjects = () => {
  const [open, setOpen] = useState(false);
  const [teamData, setTeamData] = useState('');
  const [openTeamInviteProject, setOpenTeamInviteProject] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  const params = useParams();
  const { projectsCount, projects, filters } = useSelector((state) => state.teamProjects);
  const dispatch = useDispatch();
  const [validateRole, setValidateRole] = useState({ open: false, callBack: () => {} });
  const search = useSelector((state) => state.teamProjects.filters.search || '');
  const handleSearch = (e) => dispatch(setSearch(e.target.value));

  useEffect(() => {
    const getTeamDetails = async () => {
      const responseTeam = await TeamService.getTeam(params.teamId);
      setTeamData(responseTeam.data.data);
    };
    getTeamDetails();
  }, []);

  const handleConfirm = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const columns = React.useMemo(() => tableColumns, []);

  const table = useTable(
    {
      columns,
      data: projects,
      initialState: { pageIndex: filters.page - 1, pageSize: filters.page_size },
      pageCount: Math.ceil(projectsCount / filters.page_size),
      totalCount: projectsCount,
      pageLength: projects.length,
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  const {
    state: { pageIndex, pageSize },
  } = table;

  const { displayPanel, setHeaderPanels } = useSidebar({
    open: false,
    config: {
      onClose: () => {},
    },
  });

  const {
    displayPanel: displayPanelDetail,
    setHeaderPanels: setHeaderPanelsDetail,
    setFullWidth: setFullWidthDetail,
  } = useSidebarDetail({
    open: false,
    visible: false,
    config: {
      onClose: () => {},
      search: {
        show: true,
        component: SearchPanel,
      },
    },
  });

  const projectDetail = (project) => {
    dispatch(
      project.openInspection
        ? setProject(project)
        : project.openActiveInspection
        ? setProjectActive(project)
        : getProjectDetail(project.id)
    );
    const displayHeader = project.openInspection
      ? 'projectInspections'
      : project.openActiveInspection
      ? 'projectActiveInspections'
      : 'projectDetail';
    setHeaderPanelsDetail([
      {
        name: displayHeader,
        component: project.openInspection
          ? ProjectInspections
          : project.openActiveInspection
          ? ProjectActiveInspections
          : ProjectDetail,
      },
    ]);
    setFullWidthDetail(true);
    displayPanelDetail(displayHeader);
  };

  const loadData = () => {
    const sendFilters = {
      ...filters,
      sort_by: Object.values(filters.sort_by),
      teamId: params.teamId,
    };
    dispatch(getProjects(sendFilters));
  };

  useEffect(() => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      loadData();
    }, 150);
  }, [filters]);

  useEffect(() => {
    dispatch(setPageFilter(pageIndex + 1));
  }, [pageIndex, pageSize]);

  const handleRowClick = async (rowData) => {
    // dispatch redux action to load user data
    // set sidebar active panel to user info and open if necessary
    setSelectedRow(rowData.id);
    dispatch(getProject({ projId: rowData.original.id }));
    setHeaderPanels([
      {
        name: 'info',
        component: InfoPanel,
      },
    ]);
    displayPanelDetail('');
    displayPanel('info');
  };

  // close add project to team
  const handleTeamInviteProjectClose = (refreshFlag) => {
    setOpenTeamInviteProject(false);
    if (refreshFlag) loadData();
  };

  const EmptyTeamMembers = () => (
    <>
      <TopBar searchValue={search} handleSearch={handleSearch} activatedFilter={filters.activated} sideBarDetail />
      <WidgetSplash
        alt="Team's projects Splash"
        title="Looks like you can use some help!"
        image={SplashImage}
        cta="Add Team to Project"
        onClick={() => {
          setOpenTeamInviteProject(params.teamId);
        }}
      />
      {openTeamInviteProject && (
        <>
          <DialogProjectInvite
            isOpen={openTeamInviteProject}
            handleClose={handleTeamInviteProjectClose}
            teamId={openTeamInviteProject}
            itemName={teamData.name}
            type={typeInviteData.PROJET}
            textDescription="Team"
          />
        </>
      )}
    </>
  );

  return (
    <TeamsSettingsContainer title={`${teamData.name}'s PROJECTS`} showSidebar>
      {projects.length > 0 ? (
        <Card noPadding>
          <TopBar searchValue={search} handleSearch={handleSearch} activatedFilter={filters.activated} sideBarDetail />
          <GeneralTable
            table={table}
            selectedRow={selectedRow}
            handleRowClick={handleRowClick}
            rowComponent={ProjectActions}
            actionFunction={(project) => (project ? projectDetail(project) : loadData())}
            selectRow={false}
          />
          <TablePagination table={table} />
          <ModalProjectNew isModalOpen={open} onConfirm={handleConfirm} onCancel={handleCancel} />
        </Card>
      ) : (
        <EmptyTeamMembers />
      )}
      {validateRole.open && (
        <ViewRoleValidation
          callBack={validateRole.callBack}
          handleClose={() => setValidateRole({ open: false, callBack: () => {} })}
        />
      )}
    </TeamsSettingsContainer>
  );
};

import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { InfoPanelBody } from 'pages/Projects/InfoPanel/InfoPanelBody';
import { SidebarContext } from 'components/SidebarProvider/SidebarProvider';

export const InfoPanel = () => {
  const ctx = useContext(SidebarContext);
  const history = useHistory();
  const { loading, projDetails, projUsers, projTeams } = useSelector((state) => state.adminProjects.panels);
  const navigateToPermissions = () => {
    if (ctx?.config?.search?.external) {
      history.push(`/admin/external/projects/${projDetails.id}`);
    } else {
      history.push(`/admin/projects/${projDetails.id}`);
    }
  };

  return (
    <InfoPanelBody
      loading={loading}
      detail={projDetails}
      projUsers={projUsers}
      projTeams={projTeams}
      navigateToPermissions={navigateToPermissions}
    />
  );
};

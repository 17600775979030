import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: (props) => ({
    position: 'relative',
    width: '100%',
    height: props.sequenceLength > 1 ? '91%' : '100%',
  }),
  map: {
    height: '100%',
    width: '100%',
    padding: 0,
    margin: 0,
  },
  getExtentBtn: {
    position: 'absolute',
    zIndex: '100',
    bottom: '25px',
    left: '5px',
  },
  proximityButton: {
    position: 'absolute',
    bottom: 25,
    left: 5,
    zIndex: 100,
    padding: '5px 10px',
  },
}));

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';
import { WidgetSplash } from 'components/WidgetSplash/WidgetSplash';
import { ProjectVideoPlayer } from 'pages/Profile/SingleProject/ProjectVideoPlayer/ProjectVideoPlayer';
import { CloseIcon, ExpandIcon, ShrinkIcon } from 'components/Icons';
import SplashImage from 'images/ContentSplash.png';
import { SidebarDetailContext } from 'components/SidebarProviderDetail/SidebarProviderDetail';
import { ProjectAssetCopyShare } from './ProjectAssetCopyShare';
import { ProjectAssetDetail } from './ProjectAssetDetail/ProjectAssetDetail';
import { useProjectAssetContentContext } from './ProjectAssetContentContext';
import { useStyles as projectUseStyles } from '../styles';
import { useStyles } from './styles';

export const ProjectAssetContent = ({
  selectedAsset,
  project,
  fullScreen,
  openFullScreen,
  setOpenFullScreen,
  detail,
  showHeader,
}) => {
  const classes = useStyles();
  const projectClasses = projectUseStyles();
  const { setOpen, handleClose } = useContext(SidebarDetailContext);
  const { unsavedChanges, setOpenUnsavedDialog, setContinueAction } = useProjectAssetContentContext();
  // const [ignoreArchive, setIgnoreArchive] = React.useState(false);

  // React.useEffect(() => {
  //   setIgnoreArchive(false);
  // }, [selectedAsset]);

  const confirmClose = () => {
    setOpen(false);
    handleClose();
  };

  const onCloseInspection = () => {
    if (unsavedChanges) {
      setOpenUnsavedDialog(true);
      setContinueAction(() => confirmClose);
    } else {
      confirmClose();
    }
  };

  return (
    <>
      {showHeader && (
        <div className={classes.title}>
          <div className={classes.titleContent}>
            {!(selectedAsset.hasError || !selectedAsset.isTranscodingComplete || !selectedAsset.uploadComplete) &&
              !fullScreen &&
              !detail && (
                <Button
                  className={classes.fullScreenBtn}
                  color="primary"
                  variant="contained"
                  onClick={() => setOpenFullScreen(true)}
                >
                  <ExpandIcon size={25} />
                </Button>
              )}
            {fullScreen && !detail && (
              <Button
                className={classes.fullScreenBtn}
                color="primary"
                variant="contained"
                onClick={() => setOpenFullScreen(false)}
              >
                <ShrinkIcon size={27} />
              </Button>
            )}
            {detail && (
              <Button className={classes.fullScreenBtn} color="primary" variant="contained" onClick={onCloseInspection}>
                <CloseIcon size={24} />
              </Button>
            )}
            {/* selectedAsset.archived && (
              <Button
                className={classes.fullScreenBtn}
                color="secondary"
                variant="contained"
                onClick={() => {
                  setIgnoreArchive(true);
                }}
              >
                <Typography variant="h4">View archived content</Typography>
              </Button>
              ) */}
            <Typography variant="h1">{selectedAsset?.name || ''}</Typography>
          </div>
          {selectedAsset?.id && selectedAsset?.id !== null && (
            <ProjectAssetCopyShare asset={selectedAsset} projectId={project.id} />
          )}
        </div>
      )}
      {selectedAsset.hasError || !selectedAsset.isTranscodingComplete || !selectedAsset.uploadComplete ? (
        <>
          <div
            className={`${projectClasses.mapDetailContainer} ${
              (openFullScreen && fullScreen) || detail ? classes.openMapFullScreen : ''
            }`}
          >
            <WidgetSplash
              alt="Project Asset Splash"
              title={
                // eslint-disable-next-line no-nested-ternary
                !selectedAsset.uploadComplete
                  ? `This content (${selectedAsset.name}) is uploading.`
                  : selectedAsset.hasError
                  ? 'We encountered an error while transcoding this content.'
                  : `This content (${selectedAsset.name}) is ${selectedAsset.archived ? 'archived' : 'transcoding'}.`
              }
              image={SplashImage}
            />
          </div>
          <ProjectAssetDetail asset={selectedAsset} project={project} />
        </>
      ) : (
        <>
          <div
            className={`${projectClasses.mapDetailContainer} ${
              (openFullScreen && fullScreen) || detail ? classes.openMapFullScreen : ''
            }`}
          >
            {selectedAsset && selectedAsset?.id && selectedAsset?.id !== null && (
              <ProjectVideoPlayer projectId={project.id} selectedAsset={selectedAsset} fullScreen={fullScreen} />
            )}
          </div>
          <ProjectAssetDetail asset={selectedAsset} project={project} />
        </>
      )}
    </>
  );
};

ProjectAssetContent.propTypes = {
  selectedAsset: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool,
  openFullScreen: PropTypes.bool,
  detail: PropTypes.bool,
  setOpenFullScreen: PropTypes.func,
  showHeader: PropTypes.bool,
};

ProjectAssetContent.defaultProps = {
  fullScreen: false,
  openFullScreen: false,
  detail: false,
  setOpenFullScreen: () => {},
  showHeader: true,
};

import { AdminPanelButton } from 'components/AdminPanelButton';
import { DialogMessage } from 'components/DialogMessage';
import { DoubleCheckIcon, SharingIcon } from 'components/Icons';
import { SidebarPanel } from 'components/SidebarPanel';
import { SidebarPanelSection } from 'components/SidebarPanelSection';
import React from 'react';

export const AdminPanel = () => {
  const [openCopyAccess, setOpenCopyAccess] = React.useState(false);
  const [openShareAccess, setOpenShareAccess] = React.useState(false);

  const handleConfirmCopy = () => {
    // Action submitted to backend
    // Close the window
    setOpenCopyAccess(false);
  };

  const handleConfirmShare = () => {
    // Action submitted to backend

    // Close the window
    setOpenShareAccess(false);
  };
  return (
    <SidebarPanel title="Content Details">
      <SidebarPanelSection title="Admin Settings" padding={0}>
        <AdminPanelButton icon={SharingIcon} onClick={() => setOpenShareAccess(true)}>
          Turn Sharing Off
        </AdminPanelButton>
        <AdminPanelButton icon={DoubleCheckIcon} onClick={() => setOpenCopyAccess(true)}>
          Turn Copying On
        </AdminPanelButton>
      </SidebarPanelSection>
      <DialogMessage
        title="Copy this content"
        isOpen={openCopyAccess}
        onConfirm={() => {
          handleConfirmCopy();
        }}
        onCancel={() => {
          setOpenCopyAccess(false);
        }}
      />
      <DialogMessage
        title="Share this content"
        isOpen={openShareAccess}
        onConfirm={() => {
          handleConfirmShare();
        }}
        onCancel={() => {
          setOpenShareAccess(false);
        }}
      />
    </SidebarPanel>
  );
};

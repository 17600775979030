import { Button } from 'components/Button/Button';
import { ContextMenuButton } from 'components/ContextMenuButton';
import { ContextMenuItem } from 'components/ContextMenuItem';
import { SharingIcon } from 'components/Icons';
import styled from 'styled-components';

export const AvatarRowSpacing = styled.div`
  display: flex;
  flex-wrap: wrap;
  float: left;
  justify-content: space-evenly;
  & > * {
    margin-bottom: ${(props) => props.theme.spacing(0.4)};
  }
`;

export const EditButton = styled(Button)`
  padding: 0;
  color: ${(props) => props.theme.palette.white};
  border: none;
  height: ${(props) => props.theme.spacing(0.9)};
  margin-right: ${(props) => props.theme.spacing(0.5)};
  &:hover {
    background-color: transparent;
    color: ${(props) => props.theme.palette.secondary[500]};
  }
`;

export const SharingContextMenu = styled(ContextMenuButton)`
  height: 50px;
  width: 50px;
  background-color: #1c232d;
  color: #0ce722;
  border: none;
  padding: 15px;
  &:hover {
    background-color: #1c232d;
    color: #0ce722;
  }
`;

export const StyledContextMenuItem = styled(ContextMenuItem)`
  background-color: #1c232d;
  &:hover {
    background-color: #1c232d;
  }
`;

export const StylesSharingIcon = styled(SharingIcon)`
  color: #0ce722;
`;

export const ViewDetailsButton = styled(Button)`
  background-color: #303030;
  color: #ffffff;
  width: 208px;
  height: 45px;
  border: 0.25px solid #949494;
  border-radius: 3px 0px 0px 3px;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  justify-content: left;

  &:hover {
    background-color: #303132;
    color: #ffffff;
  }

  button svg {
    size: 24px;
  }
`;

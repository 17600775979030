import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const CameraIcon = ({ size, className }) => (
  <Svg viewBox="0 0 24 24" size={size} className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.202 2L6.058 4.4H2.344C1.054 4.4 0 5.48 0 6.8v14.4c0 1.32 1.055 2.4 2.344 2.4h18.748c1.289 0 2.343-1.08 2.343-2.4V6.8c0-1.32-1.054-2.4-2.343-2.4h-3.715L15.233 2h-7.03zm3.516 18c-3.234 0-5.86-2.688-5.86-6s2.626-6 5.86-6c3.234 0 5.858 2.688 5.858 6s-2.624 6-5.858 6zm0-1.2l1.464-3.3 3.223-1.5-3.223-1.5-1.464-3.3-1.465 3.3L7.031 14l3.222 1.5 1.465 3.3z"
      fill="currentColor"
    />
  </Svg>
);

CameraIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
};

CameraIcon.defaultProps = {
  size: 24,
  className: '',
};

import { createTheme } from '@mui/material/styles';
import { CssBaselineStyles } from 'components/CssBaseline';

const palette = {
  primary: {
    base: '#3b3b3b',
    main: '#3b3b3b',
    light: '#626262',
    dark: '#2b2b2b',
    disabled: '#454646',
    100: '#FCFCFD',
    200: '#F7F7F7',
    300: '#E1E1E1',
    400: '#CFCFCF',
    500: '#B1B1B1',
    600: '#9E9E9E',
    700: '#7E7E7E',
    800: '#626262',
    900: '#515151',
    1000: '#383838',
    1100: '#363636',
    1200: '#3b3b3b',
    1300: '#2b2b2b',
    1400: '#222226',
    1500: '#1A1C1D',
    1600: '#262222', // background modal
  },
  secondary: {
    base: '#FF9100',
    main: '#FF9100',
    light: '#FFA826',
    dark: '#FF8600',
    disabled: '#7a6b4d',
    100: '#FFFCF6',
    200: '#FFF3E0',
    300: '#FFE0B3',
    400: '#FFCC80',
    500: '#FFB84D',
    600: '#FFA826',
    700: '#FF9900',
    800: '#FF9100',
    900: '#FF8600',
    1000: '#FF7C00',
    1100: '#FF6B00',
  },
  warning: {
    main: '#F79009',
    disabled: '#DC6803',
    100: '#FFFCF5',
    200: '#FFFAEB',
    300: '#FEF0C7',
    400: '#FEDF89',
    500: '#FEC84B',
    600: '#FDB022',
    700: '#F79009',
    800: '#DC6803',
    900: '#B54708',
    1000: '#93370D',
    1100: '#7A2E0E',
  },
  danger: {
    base: '#F04438',
    main: '#F04438',
    disabled: '#5f4343',
    100: '#FFFBFA',
    200: '#FEF3F2',
    300: '#FEE4E2',
    400: '#FECDCA',
    500: '#FDA29B',
    600: '#F97066',
    700: '#F04438',
    800: '#D92D20',
    900: '#B42318',
    1000: '#912018',
    1100: '#7A271A',
  },
  success: {
    base: '#12B76A',
    main: '#12B76A',
    disabled: '#57725a',
    100: '#F6FEF9',
    200: '#ECFDF3',
    300: '#D1FADF',
    400: '#A6F4C5',
    500: '#6CE9A6',
    600: '#32D583',
    700: '#12B76A',
    800: '#039855',
    900: '#027A48',
    1000: '#05603A',
    1100: '#054F31',
  },
  white: '#ffffff',
  lightGreen: '#d1fadf',
  modalBackground: 'rgba(50, 59, 73, 0.9)',
};

const ROOT_SIZE = 10;
const ROOT_FONT_SIZE = ROOT_SIZE * 2;
const pxToRem = (px) => px / ROOT_SIZE;
const smallFont = '12px';
const fontSize = '14px';
const fontSize2 = '16px';
const h5 = fontSize2;
const h4 = '18px';
const h3 = '20px';
const h2 = '22px';
const h1 = '24px';
const borderRadius = '8px';

export const theme = createTheme({
  palette,
  typography: {
    fontFamily: 'Inter, sans-serif',
    fontSize: 14,
    color: palette.primary[100],
    fontWeightRegular: 500,
    fontWeightBold: 400,
    fontWeightLight: 400,
    fontWeightMedium: 200,
  },
  spacing: (factor) => `${pxToRem(factor * ROOT_FONT_SIZE)}rem`,
  styleOverrides: {
    root: {
      '.MuiPaper-root.MuiMenu-paper': {
        backgroundColor: palette.primary[1200],
      },
      '.MuiAautocomplete-popper': {
        backgroundColor: palette.primary[1200],
      },
    },
  },
  overrides: {
    '.MuiAutocomplete-popper': {
      backgroundColor: palette.primary[1200],
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        root: CssBaselineStyles,
        body: {
          color: palette.primary[100],
          backgroundColor: palette.primary[1000],
          lineHeight: 1.5,
          fontSize: `${pxToRem(14)}rem`,
        },
        html: {
          backgroundColor: palette.primary[1000],
          fontSize: `${ROOT_SIZE}px`,
          fontFamily: 'Inter, sans-serif',
        },
        a: {
          color: palette.secondary[700],
          textDecoration: 'none',
          '&:hover, &:focus': {
            color: palette.secondary.dark,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize,
        },
        h1: {
          fontSize: h1,
        },
        h2: {
          fontSize: h2,
        },
        h3: {
          fontSize: h3,
        },
        h4: {
          fontSize: h4,
        },
        h5: {
          fontSize: h5,
        },
        body1: {
          fontSize,
          fontWeightRegular: 500,
        },
        body2: {
          fontSize,
          fontWeight: 400,
        },
        caption: {
          fontSize: smallFont,
          fontWeight: 400,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: smallFont,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius,
          minWidth: '45px',
          minHeight: 40,
          textTransform: 'none',
          fontSize,
          padding: '10px 18px',
          fontWeight: 500,
        },
        textPrimary: {
          color: palette.primary[100],
          '&.Mui-disabled': {
            color: palette.primary.light,
          },
        },
        outlinedPrimary: {
          border: `1px solid ${palette.primary.light}`,
        },
        containedPrimary: {
          border: `1px solid ${palette.primary.light}`,
          '&.Mui-disabled': {
            border: 'none!important',
            color: palette.primary.light,
          },
        },
        containedSecondary: {
          '&.Mui-disabled': {
            backgroundColor: palette.secondary[1100],
            color: palette.primary.light,
          },
        },
        sizeSmall: {
          padding: '10px 18px',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiDialog-container': {
            backgroundColor: palette.modalBackground,
          },
          '& .MuiDialogActions-root button': {
            fontSize: fontSize2,
          },
        },
        paper: {
          borderRadius: '12px',
          backgroundColor: palette.primary[1600],
          color: palette.primary[100],
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingTop: '0.8rem!important',
          color: palette.primary[100],
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          color: palette.primary[500],
          marginBottom: '10px',
          fontSize,
          '& svg': {
            color: palette.primary[500],
          },
          '&.Mui-disabled': {
            color: palette.primary[500],
            opacity: 0.6,
            '& svg': {
              color: palette.primary[700],
            },
          },
        },
        '&.Mui-focused': {
          color: palette.primary[100],
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          color: palette.primary[500],
          fontSize,
          '& svg': {
            color: palette.primary[500],
          },
          '&.Mui-disabled': {
            color: palette.primary[500],
            opacity: 0.6,
            '& svg': {
              color: palette.primary[700],
            },
          },
        },
        '&.Mui-focused': {
          color: palette.primary[100],
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          marginTop: '0px',
          border: `1px solid ${palette.primary.light}`,
          color: palette.primary[400],
          backgroundColor: palette.primary.dark,
          borderRadius: '8px!important',
          fontSize: fontSize2,
          fontWeight: 400,
          '& svg': {
            color: palette.primary[100],
          },
          '&.Mui-disabled': {
            border: `1px solid ${palette.primary.disabled}`,
          },
        },
        input: {
          '&.Mui-disabled': {
            color: palette.primary[400],
            WebkitTextFillColor: palette.primary[400],
          },
          fontSize: fontSize2,
          '&::placeholder': {
            fontSize: fontSize2,
            color: palette.primary[400],
          },
        },
        '&.Mui-focused': {
          color: palette.primary[100],
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '& svg': {
            minWidth: fontSize2,
            minHeight: fontSize2,
          },
        },
        colorPrimary: {
          color: palette.secondary[700],
          borderRadius: '4px',
          '&.Mui-checked': {
            color: palette.primary.main,
          },
          '&.Mui-disabled': {
            color: palette.secondary[700],
            opacity: 0.6,
          },
        },
        colorSecondary: {
          color: palette.primary[700],
          borderRadius: '4px',
          '&.Mui-checked': {
            color: palette.secondary.main,
          },
          '&.Mui-disabled': {
            color: palette.primary[700],
            opacity: 0.6,
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 40,
          height: 22,
          padding: 0,
          display: 'flex',
          margin: 12,
          '& .Mui-checked .MuiSwitch-thumb': {
            backgroundColor: palette.primary[100],
          },
          '& .MuiSwitch-switchBase': {
            padding: 2,
            '&.Mui-checked': {
              transform: 'translateX(18px)',
              color: palette.primary[100],
              '& + .MuiSwitch-track': {
                border: `1px solid ${palette.secondary.main}`,
                opacity: '1!important',
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: '0.5!important',
              },
            },
          },
        },
        thumb: {
          backgroundColor: palette.primary.light,
          width: 18,
          height: 18,
        },
        track: {
          backgroundColor: palette.primary.dark,
          borderRadius: 22 / 2,
          opacity: 1,
          border: `1px solid ${palette.primary.light}`,
          boxSizing: 'border-box',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '&.Mui-disabled .MuiTypography-root': {
            color: palette.primary[400],
            opacity: 0.6,
          },
        },
        label: {
          color: `${palette.primary[400]}`,
          fontSize,
          '&': {
            color: `${palette.primary[400]}`,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiChip-root': {
            backgroundColor: palette.secondary.main,
            color: palette.primary[100],
            fontSize: fontSize2,
          },
        },
        endAdornment: {
          color: 'white',
          '& svg': {
            color: 'white',
          },
        },
        paper: {
          backgroundColor: palette.primary.dark,
          fontSize: fontSize2,
        },
        option: {
          color: palette.primary[100],
        },
        loading: {
          color: palette.primary[100],
        },
        noOptions: {
          color: palette.primary[100],
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: palette.primary.dark,
          color: palette.primary[100],
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '& .Mui-disabled': {
            color: palette.primary[500],
          },
        },
        icon: {
          fontSize: fontSize2,
          color: palette.primary[100],
          '&.Mui-disabled': {
            color: palette.primary[500],
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          display: 'inline-flex',
          '& .MuiTabs-scroller': {
            border: `1px solid ${palette.primary[300]}`,
            display: 'inline-block',
            borderRadius,
            overflow: 'hidden',
            padding: '2px',
          },
          '& .MuiTabs-indicator': {
            display: 'none',
            backgroundColor: palette.primary.main,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          backgroundColor: palette.primary.dark,
          color: palette.primary[400],
          fontSize,
          fontWeight: 500,
          height: '100%',
          textTransform: 'none',
          '&.Mui-selected': {
            color: palette.primary[400],
            backgroundColor: palette.primary.main,
            borderRadius,
          },
          '&.MuiTab-textColorInherit, &.MuiTab-textColorInherit > span': {
            opacity: 1,
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          color: palette.primary[100],
          '& .MuiAccordionSummary-root': {
            backgroundColor: palette.primary.dark,
            '& p': {
              fontSize,
            },
            borderBottom: `1px solid${palette.primary.light}`,
          },
          '& .MuiAccordionDetails-root': {
            backgroundColor: palette.primary.main,
          },
          '& .MuiAccordionSummary-expandIconWrapper': {
            color: palette.secondary.main,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: palette.primary[700],
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius,
          height: 12,
          border: `1px solid ${palette.secondary[200]}`,
        },
        colorSecondary: {
          backgroundColor: 'transparent',
        },
        barColorSecondary: {
          backgroundColor: palette.secondary.main,
        },
      },
    },
  },
});

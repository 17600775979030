import React from 'react';

import { CirclePlusIcon } from 'components/Icons';

export const AddMapServiceIcon = () => (
  <>
    <CirclePlusIcon />
    <span style={{ marginLeft: '1rem' }}>Map&#47;Services</span>
  </>
);

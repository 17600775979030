import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  content: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 10,
    marginBottom: theme.spacing(1),
  },
  button: {
    backgroundColor: theme.palette.secondary[600],
    width: 'max-content',
    padding: '2px 8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '16px',
    color: 'black',
  },
});

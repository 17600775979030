import { makeStyles } from '@mui/styles';

const getColor = (theme, error, disabled) => {
  if (disabled) {
    return theme.palette.primary.disabled;
  }

  if (error) {
    return theme.palette.danger.main;
  }

  return theme.palette.primary.light;
};

export const useStyles = makeStyles((theme) => ({
  textArea: (props) => ({
    resize: 'vertical',
    margin: 0,
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    border: '1px solid',
    display: 'block',
    borderColor: getColor(theme, props.error, props.disabled),
    background: theme.palette.primary.dark,
    color: theme.palette.primary[500],
    width: '100%',
    paddingLeft: '12px',
    paddingTop: '12px',
    paddingBottom: '12px',
    paddingRight: props.hasIcon ? '42px' : '12px',
    borderRadius: '5px',
    '&:focus': {
      outline: 'none',
    },
  }),
  helpText: {
    marginLeft: 14,
    marginRight: 14,
    marginTop: 4,
  },
}));

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';
import { CirclePlusIcon, CloseIcon } from 'components/Icons';
import { SidebarDetailContext } from 'components/SidebarProviderDetail/SidebarProviderDetail';
import { useStyles } from './styles';

export const InspectionsHeader = ({ handleCreation, projectName }) => {
  const { setOpen, open, handleClose } = useContext(SidebarDetailContext);
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <div className={classes.title}>
        <div className={classes.text}>
          <Typography variant="h4">{`${projectName} > `}</Typography>
          <Typography variant="h5" className="yellow">
            Inspection templates
          </Typography>
        </div>
        <div className={classes.wrapperButtons}>
          <Button color="secondary" variant="contained" onClick={handleCreation}>
            <CirclePlusIcon size={15} /> <Typography ml={0.2}>Inspection Template</Typography>
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setOpen(!open);
              handleClose();
            }}
            className={classes.headerButton}
          >
            <CloseIcon size={15} />
          </Button>
        </div>
      </div>
    </div>
  );
};

InspectionsHeader.propTypes = {
  handleCreation: PropTypes.func.isRequired,
  projectName: PropTypes.string.isRequired,
};

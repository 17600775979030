import styled from 'styled-components';
import { Typography } from 'components/Typography';

export const ModifiedTd = styled.td`
  vertical-align: middle;
`;

export const Layout = styled.div`
  display: grid;
  height: 800px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 16px;
`;

export const AvatarRow = styled.div`
  display: flex;
  flex-direction: row;
  & > * {
    margin-right: 2px;
  }
`;

export const StyledTable = styled.div`
  padding: 0;
  height: 40vh;
  overflow-y: auto;
  & > table > thead th {
    position: sticky !important;
    top: 0;
    z-index: 2;
    background: ${(props) => props.theme.palette.primary.base};
  }
  & > table > tbody tr {
    background-color: ${(props) => props.theme.palette.primary.disabled};
  }
  & > table > tbody tr:hover {
    background-color: ${(props) => props.theme.palette.primary['900']};
  }
  & > table > tbody tr > td {
    border-bottom: ${(props) => props.theme.palette.primary.white};
  }
`;

export const StyledInput = styled.input`
  background: transparent;
  border: none;
  color: white;
  padding: ${(props) => props.theme.spacing(0.5)};
  width: 100%;
  flex-grow: 1;
  &:disabled {
    color: ${(props) => props.theme.palette.primary[500]};
  }
`;

export const StyledRow = styled.tr`
  background-color: ${(props) => (props.isSelected ? `${props.theme.palette.primary['900']}!important` : null)};
`;

export const InfoContainer = styled.div`
  background-color: ${(props) => props.theme.palette.primary.disabled};
  opacity: 0.9;
  border: 1px solid ${(props) => props.theme.palette.primary[1000]};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.palette.primary.base};
    border: 1px solid ${(props) => props.theme.palette.secondary.base};
  }
  padding: 5px;
  width: 25px;
  height: 25px;
`;

export const PlayContainer = styled.div`
  background-color: ${(props) => props.theme.palette.primary.disabled};
  opacity: 0.9;
  border: 1px solid ${(props) => props.theme.palette.primary[1000]};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.palette.primary.base};
    border: 1px solid ${(props) => props.theme.palette.secondary.base};
  }
  padding: 5px;
  width: 25px;
  height: 25px;
`;

export const ColumnContainer = styled.span`
  margin-top: 2px;
  float: left;
  margin-right: 15px;
  border: 0.1px solid ${(props) => props.theme.palette.primary[700]};
  border-radius: 5px;
  padding: 5px 9px;

  & > svg {
    margin-top: 5px;
  }
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: center;

  height: 100%;

  & > div {
    margin-left: 10px;
  }
`;

export const StyledSubText = styled(Typography)`
  color: ${(props) => props.theme.palette.primary[700]};
`;

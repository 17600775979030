import React from 'react';
import { useSelector } from 'react-redux';
import { InfoPanelBody } from 'pages/Admin/Content/InfoPanel/InfoPanelBody';

export const InfoPanel = () => {
  const { isAssetLoading, projectAsset } = useSelector((state) => state.profileProjectAssets);
  // const { projUsers, projTeams } = useSelector((state) => state.adminProjects.panels);

  return <InfoPanelBody loading={isAssetLoading} detail={projectAsset} />;
};

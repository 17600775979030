import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { CopyIcon, SharingIcon } from 'components/Icons';
import ProjectService from 'services/ProjectService';
import { DialogProjectCopy } from 'components/DialogProjectCopy';
import { DialogProjectShare } from 'components/DialogProjectShare';
import { SidebarDetailContext } from 'components/SidebarProviderDetail/SidebarProviderDetail';
import { useDispatch, useSelector } from 'react-redux';
import { setAssetSharing } from 'slices/profileProjectAssetReducer';
import { ViewRoleValidation } from 'components/ViewRoleValidation/ViewRoleValidation';
import { Permissions } from 'lib/permissions';
import { useStyles } from './styles';

const clickAction = {
  COPY: 'copy',
  SHARE: 'share',
};

export const ProjectAssetCopyShare = ({ asset, projectId }) => {
  const dispatch = useDispatch();
  const ctx = useContext(SidebarDetailContext);
  const external = ctx?.config?.search?.external;
  const classes = useStyles();
  const [action, setAction] = useState('');
  const { isAssetLoading } = useSelector((state) => state.profileProjectAssets);
  const [validateRole, setValidateRole] = useState({ open: false, callBack: () => {} });

  const onClick = (value) => {
    setAction(value);
  };

  // Methods to handle copy project
  const handleProjectCopy = (projId) => {
    const updateProject = async () => {
      await ProjectService.CopyAssetWithProject(asset.project.id, asset.id, projId);
      setAction('');
    };

    updateProject();
  };

  // Methods to handle share project
  const handleProjectShare = (projId) => {
    const updateProject = async () => {
      const share = await ProjectService.ShareAssetWithProject(asset.project.id, asset.id, {
        shareWithProjectId: projId,
        assetId: asset.id,
      });
      setAction('');
      dispatch(setAssetSharing({ data: { ...share.data.data, createdAt: new Date() }, action: 'add' }));
    };
    updateProject();
  };

  return (
    <div>
      {!isAssetLoading && (
        <div className={classes.copyShareButtons}>
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={() =>
              setValidateRole({
                open: true,
                action: [
                  Permissions.PROJ_MODIFY_CONTENT,
                  Permissions.PROJ_MODIFY_CONTENT_SHARE_COPY,
                  Permissions.PROJ_COPY,
                ],
                data: { ...asset.project, asset },
                callBack: () => onClick(clickAction.COPY),
              })
            }
          >
            <CopyIcon size={20} /> {' Copy Asset'}
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={() =>
              setValidateRole({
                open: true,
                data: { ...asset.project, asset },
                action: [
                  Permissions.PROJ_MODIFY_CONTENT,
                  Permissions.PROJ_MODIFY_CONTENT_SHARE_COPY,
                  Permissions.PROJ_SHARE,
                ],
                callBack: () => onClick(clickAction.SHARE),
              })
            }
          >
            <SharingIcon size={20} />
            {' Share Asset'}
          </Button>
        </div>
      )}
      {action === clickAction.SHARE && (
        <DialogProjectShare
          title="Select a project to share this content with."
          isOpen
          onConfirm={handleProjectShare}
          onCancel={() => setAction('')}
          asset={asset}
          external={external}
          projectId={projectId}
        />
      )}
      {action === clickAction.COPY && (
        <DialogProjectCopy
          title="Select a project to copy this content with."
          isOpen
          onConfirm={handleProjectCopy}
          onCancel={() => setAction('')}
          asset={asset}
          projectId={projectId}
        />
      )}
      {validateRole.open && (
        <ViewRoleValidation
          action={validateRole.action}
          data={validateRole?.data}
          callBack={validateRole.callBack}
          handleClose={() => setValidateRole({ open: false, callBack: () => {} })}
        />
      )}
    </div>
  );
};

ProjectAssetCopyShare.propTypes = {
  asset: PropTypes.object,
  projectId: PropTypes.string,
};

ProjectAssetCopyShare.defaultProps = {
  asset: null,
  projectId: null,
};

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& .esri-view': {
      flex: 1,
    },
  },
  buttonActions: {
    '& svg': {
      color: theme.palette.primary[400],
    },
  },
  cardsContainer: {
    flex: 1,
    marginTop: `${theme.spacing(1)}!important`,
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    overflowY: 'auto',
    '& .MuiPaper-root': {
      height: '100%',
    },
  },
}));

import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import InspectionService from 'services/InspectionService';
import { formatDateRange } from './helpers';

export const getProfileInspections = createAsyncThunk('profileInspections/getProfileInspections', async (filters) => {
  const newFilters = {
    strict: true,
    include: ['uploader', 'inspection', 'assignment', 'progress'],
    ...filters,
    ...formatDateRange(filters.date_range),
  };
  delete newFilters.date_range;
  delete newFilters.activated;
  try {
    return InspectionService.getProjectAssetInspections(newFilters)
      .then((res) => res.data)
      .catch(() => {
        throw new Error('Error during request for project asset inspections...');
      });
  } catch (e) {
    throw new Error('Error during request for project asset inspections...');
  }
});

export const preloadFilterDatasets = createAsyncThunk(
  'profileInspections/preloadFilterDatasets',
  async (arg, { getState }) => {
    try {
      const [inspections, assets] = await Promise.all([
        await InspectionService.getAssetInspections({ assigneeId: getState().auth.userId }),
        await InspectionService.getProjAssetInspectionsAssets({ assigneeId: getState().auth.userId }),
      ]);
      return {
        inspections: inspections.data,
        assets: assets.data,
      };
    } catch (err) {
      throw new Error('error loading filter data');
    }
  },
  {
    condition: (_, { getState }) => getState().profileInspections.panels.search.loaded === false,
  }
);

const orderByName = (array) =>
  array.sort((a, b) => {
    const nameA = a.label.toUpperCase(); // ignore upper and lowercase
    const nameB = b.label.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

const initialState = {
  error: null,
  loading: false,
  data: [],
  totalCount: 0,
  panels: {
    projId: null,
    loading: true,
    detail: {},
    search: {
      loaded: false,
      projects: [],
      inspections: [],
      assets: [],
      users: [],
    },
  },
  filters: {
    page: 1,
    page_size: 10,
    sort_by: {},
    search: '',
    activated: false,
    date_range: null,
  },
  detail: {},
};

const profileInspectionSlice = createSlice({
  name: 'profileInspections',
  initialState,
  reducers: {
    resetProfileInspections: () => initialState,
    setPageFilter(state, action) {
      state.filters.page = action.payload;
    },
    setSearch(state, action) {
      state.filters.search = action.payload;
    },
    setSortBy(state, { payload }) {
      const { label, value } = payload;

      if (value === '' || value === null) {
        delete state.filters.sort_by[label];
      } else {
        state.filters.sort_by[label] = value;
      }
    },
    setFilterValue(state, { payload }) {
      const { param, value } = payload;
      if ((value === '' || value === null) && param !== 'date_range') {
        delete state.filters[param];
        return;
      }
      state.filters[param] = value;
    },
    setDateRange(state, { payload }) {
      state.filters.date_range = payload;
    },
    setFilters(state, action) {
      const filter = { ...state.filters, ...action.payload, page: 1 };
      if (!filter?.participantLevel) delete filter?.participantLevel;
      state.filters = filter;
    },
    setProfileInspectionPageFilter(state, action) {
      state.filters.page = action.payload;
    },
    setProfileInspections(state, action) {
      state.data = action.payload;
    },
    setProfileInspection(state, action) {
      state.detail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProfileInspections.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProfileInspections.rejected, (state) => {
      state.data = [];
      state.error = 'There was an error during the request.';
      state.loading = false;
    });
    builder.addCase(getProfileInspections.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.totalCount = action.payload.meta.totalCount;
      state.error = null;
      state.loading = false;
    });
    builder.addCase(preloadFilterDatasets.pending, (state) => {
      state.panels.search.projects = [];
      state.panels.search.inspections = [];
      state.panels.search.assets = [];
      state.panels.search.loaded = false;
    });
    builder.addCase(preloadFilterDatasets.rejected, (state) => {
      state.panels.search.projects = [];
      state.panels.search.inspections = [];
      state.panels.search.assets = [];
      state.panels.search.loaded = false;
    });
    builder.addCase(preloadFilterDatasets.fulfilled, (state, { payload }) => {
      const { inspections, assets } = payload;
      state.panels.search.loaded = true;
      const newInspections = inspections.map((insp) => ({
        label: insp.name,
        value: insp.id,
      }));
      state.panels.search.inspections = orderByName(newInspections);
      const newAassets = assets.map((insp) => ({
        label: insp.name,
        value: insp.id,
      }));
      state.panels.search.assets = orderByName(newAassets);
      const projects = assets.map((item) => item.project);
      const uniqueProjects = [...new Map(projects.map((item) => [item.id, item])).values()];
      const newProjects = uniqueProjects.map((proj) => ({
        label: proj.name,
        value: proj.id,
      }));
      state.panels.search.projects = orderByName(newProjects);
    });
  },
});

export const {
  setSearch,
  setProject,
  setAcctId,
  setOrganizationId,
  setSortBy,
  setPageFilter,
  setFilterValue,
  setDateRange,
  updateProject,
  resetProfileInspections,
  setFilters,
  setProfileInspectionPageFilter,
  setProfileInspections,
  setProfileInspection,
  // eslint-disable-next-line prettier/prettier
} = profileInspectionSlice.actions;

export const dateRangeSelector = createSelector(
  (state) => state.profileInspections.filters,
  (state) => {
    const dates = state.date_range;

    if (!dates) {
      return dates;
    }

    return dates.map((date) => (date ? new Date(date) : null));
  }
);

export default profileInspectionSlice.reducer;

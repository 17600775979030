import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  sidebar: {
    height: '100%',
    width: '80px',
    backgroundColor: theme.palette.primary.dark,
    display: 'flex',
    flexDirection: 'column',
    gridArea: 'nav',
    padding: theme.spacing(0.8),
    boxShadow: `inset -0.5px 0px 0px ${theme.palette.secondary[700]}`,
  },
  open: {
    width: '240px',
  },
  navHeader: {
    display: 'flex',
    flex: '0 0 auto',
    justifyContent: 'center',
    borderBottom: `${theme.palette.primary.light} solid 1px`,
    backgroundColor: theme.palette.primary.dark,
    height: '65px',
  },
  navBody: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    margin: '0',
    alignItems: 'center',
  },
  navButton: {
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '10px',
    border: 'none',
    padding: '1rem',
    cursor: 'pointer',
    width: '100%',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
});

import React from 'react';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useStyles } from './styles';

export const Calendar = ({ ranges, months, direction, onChange }) => {
  const classes = useStyles();

  return (
    <div className={classes.calendarContent}>
      <DateRangePicker
        onChange={(item) => onChange(item)}
        moveRangeOnFirstSelection={false}
        months={months}
        ranges={ranges}
        direction={direction}
        showSelectionPreview={false}
      />
    </div>
  );
};

Calendar.propTypes = {
  ranges: PropTypes.array,
  /**
   * This prop will change how many Calendars months are displayed
   */
  months: PropTypes.number,
  direction: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  onChange: PropTypes.func.isRequired,
};

Calendar.defaultProps = {
  ranges: false,
  months: 1,
  direction: 'horizontal',
};

/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormRadioGroup } from 'components/FormRadioGroup';
import { SidebarPanel, cleanObjectFilters } from 'components/SidebarPanel';
import { useDispatch } from 'react-redux';
import { ClearFilterAction } from 'components/SidebarPanel/ClearFilterAction';
import { FormControl, RadioGroup, FormControlLabel, Radio, FormLabel } from '@mui/material';
import { SearchList } from 'components/SidebarPanel/SearchList/SearchList';

export const searchPanelSection = {
  PROFILETEAMS: 'profile_teams',
  ADMINTEAMS: 'admin_teams',
  EXTERNALTEAMS: 'external_teams',
};

export const SearchPanelBody = ({
  loaded,
  filters,
  users,
  projects,
  accounts,
  organizations,
  // eslint-disable-next-line no-unused-vars
  setFilterValue,
  setFilters,
  section,
}) => {
  const dispatch = useDispatch();
  const [filterActions, setFiltersActions] = useState([]);
  const [localFilters, setLocalFilters] = useState({
    sort_by: {},
    acctId: '',
    organizationId: '',
    userRole: '',
    date_range: null,
    userId: '',
    projectId: '',
  });

  // clear sort
  const clearSort = (param) => {
    const auxFilters = { ...localFilters };
    delete auxFilters.sort_by[param];
    auxFilters.sort_by = cleanObjectFilters(auxFilters.sort_by);
    setLocalFilters(auxFilters);
  };

  // update filters
  const updateFIlter = (auxFilters) => {
    setLocalFilters({ ...auxFilters });
  };

  // update radio buttons
  const updateNormalFilters = (value, type) => {
    const auxFilters = { ...localFilters };
    auxFilters[type] = value;
    setLocalFilters({ ...auxFilters });
  };

  // -------------- clear filters
  const handleClearFilters = () => {
    const auxFilters = { filters };
    if (cleanObjectFilters(auxFilters)?.filters) {
      dispatch(
        setFilters({
          acctId: '',
          organizationId: '',
          sort_by: {},
          userRole: null,
          projectId: '',
          userId: '',
          activated: false,
        })
      );
    }
  };

  // -------------- apply filters
  const handleApplyFilters = () => {
    const auxFilters = { ...localFilters };
    auxFilters.sort_by = cleanObjectFilters(auxFilters.sort_by);
    dispatch(setFilters({ ...auxFilters, activated: filterActions.length > 0 }));
  };

  const createClearFilters = (clearFilters) => {
    const auxFilters = [];
    if (clearFilters.sort_by?.name) auxFilters.push({ text: 'Sort By Name', callBack: () => clearSort('name') });
    if (clearFilters.sort_by?.createdAt)
      auxFilters.push({ text: 'Sort By Created At', callBack: () => clearSort('createdAt') });
    if (clearFilters.sort_by?.org) auxFilters.push({ text: 'Sort By Org', callBack: () => clearSort('org') });
    if (clearFilters?.organizationId?.length > 0)
      auxFilters.push({ text: 'Clear By Organization', callBack: () => updateNormalFilters(null, 'organizationId') });
    if (clearFilters?.acctId?.length > 0)
      auxFilters.push({ text: 'Clear By Account', callBack: () => updateNormalFilters(null, 'acctId') });
    if (clearFilters?.userId?.length > 0 && section !== searchPanelSection.PROFILETEAMS)
      auxFilters.push({ text: 'Clear By User', callBack: () => updateNormalFilters(null, 'userId') });
    if (clearFilters?.projectId?.length > 0)
      auxFilters.push({ text: 'Clear By Project', callBack: () => updateNormalFilters(null, 'projectId') });
    setFiltersActions(auxFilters);
    return auxFilters;
  };

  useEffect(() => {
    createClearFilters(localFilters);
  }, [localFilters]);

  useEffect(() => {
    createClearFilters(filters);
    setLocalFilters(filters);
  }, [filters]);

  return (
    <SidebarPanel
      title={
        section === searchPanelSection.ADMINTEAMS
          ? 'Filters: Account Teams'
          : section === searchPanelSection.EXTERNALTEAMS
          ? 'Filters: External Teams'
          : 'Filters'
      }
      subTitle="Apply filters to table data."
      filters
      handleClearFilters={handleClearFilters}
      handleApplyFilters={handleApplyFilters}
    >
      <div className="searchBody">
        <ClearFilterAction actions={filterActions} />
        <FormControl>
          <FormLabel>Sort</FormLabel>
          <FormRadioGroup
            name="alphabetical"
            value={localFilters.sort_by.name}
            onChecked={(checkedVal) => {
              const sortBy = { ...localFilters.sort_by };
              sortBy.name = checkedVal;
              updateNormalFilters(sortBy, 'sort_by');
            }}
            options={[
              { label: 'A - Z', value: '+name', disabled: false },
              { label: 'Z - A', value: '-name', disabled: false },
              { label: 'None', value: '', disabled: false },
            ]}
            view="horizontal"
          />
          <FormRadioGroup
            name="date-by"
            value={localFilters.sort_by.createdAt}
            onChecked={(checkedVal) => {
              const sortBy = { ...localFilters.sort_by };
              sortBy.createdAt = checkedVal;
              updateNormalFilters(sortBy, 'sort_by');
            }}
            options={[
              { label: 'Newest', value: '-createdAt', disabled: false },
              { label: 'Oldest', value: '+createdAt', disabled: false },
              { label: 'None', value: '', disabled: false },
            ]}
            view="horizontal"
          />
          <FormRadioGroup
            name="org"
            value={localFilters.sort_by.org}
            onChecked={(checkedVal) => {
              const sortBy = { ...localFilters.sort_by };
              sortBy.org = checkedVal;
              updateNormalFilters(sortBy, 'sort_by');
            }}
            options={[
              {
                label:
                  section === searchPanelSection.EXTERNALTEAMS ? 'Sort by Leading Account' : 'Sort by Organization',
                value: 'org',
                columns: 2,
              },
              { label: 'None', value: '', disabled: false },
            ]}
            view="horizontal"
          />
          {section !== searchPanelSection.EXTERNALTEAMS && (
            <FormRadioGroup
              name="account"
              value={localFilters.sort_by.account}
              onChecked={(checkedVal) => {
                const sortBy = { ...localFilters.sort_by };
                sortBy.account = checkedVal;
                updateNormalFilters(sortBy, 'sort_by');
              }}
              options={[
                {
                  label: 'Sort by Account',
                  value: 'account',
                  columns: 2,
                },
                { label: 'None', value: '' },
              ]}
              view="horizontal"
            />
          )}
        </FormControl>
        <SearchList
          title={`Find Teams by ${section === searchPanelSection.EXTERNALTEAMS ? 'External ' : ''}Organization`}
          fullList={organizations}
          filter="organizationId"
          localFilters={localFilters}
          onChangeSelect={updateFIlter}
          loaded={loaded}
        />
        <SearchList
          title={`Find Teams by ${section === searchPanelSection.EXTERNALTEAMS ? 'External ' : ''}Account`}
          fullList={accounts}
          filter="acctId"
          localFilters={localFilters}
          onChangeSelect={updateFIlter}
          loaded={loaded}
        />
        {(section === searchPanelSection.ADMINTEAMS || section === searchPanelSection.EXTERNALTEAMS) && (
          <SearchList
            title="Find Teams by Membership"
            fullList={users}
            filter="userId"
            localFilters={localFilters}
            onChangeSelect={updateFIlter}
            loaded={loaded}
          />
        )}
        <SearchList
          title="Find Teams by Project Membership"
          fullList={projects}
          filter="projectId"
          localFilters={localFilters}
          onChangeSelect={updateFIlter}
          loaded={loaded}
        />
        {section !== searchPanelSection.EXTERNALTEAMS && section !== searchPanelSection.ADMINTEAMS && (
          <FormControl>
            <FormLabel className="marginTop">Your Role</FormLabel>
            <RadioGroup row>
              <FormControlLabel
                value=""
                onClick={() => updateNormalFilters(null, 'userRole')}
                control={<Radio />}
                label="All"
                checked={localFilters.userRole === ''}
              />
              <FormControlLabel
                value="participate"
                onClick={() => updateNormalFilters('participate', 'userRole')}
                control={<Radio />}
                label="Participant"
                checked={localFilters.userRole === 'participate'}
              />
              <FormControlLabel
                value="administer"
                onClick={() => updateNormalFilters('administer', 'userRole')}
                control={<Radio />}
                label="Administrator"
                checked={localFilters.userRole === 'administer'}
              />
            </RadioGroup>
          </FormControl>
        )}
      </div>
    </SidebarPanel>
  );
};

SearchPanelBody.propTypes = {
  loaded: PropTypes.bool,
  filters: PropTypes.object,
  users: PropTypes.array,
  projects: PropTypes.array,
  accounts: PropTypes.array,
  organizations: PropTypes.array,
  setFilterValue: PropTypes.func,
  setFilters: PropTypes.func,
  section: PropTypes.string,
};

SearchPanelBody.defaultProps = {
  loaded: false,
  filters: null,
  users: [],
  projects: [],
  accounts: [],
  organizations: [],
  setFilterValue: () => {},
  setFilters: () => {},
  section: '',
};

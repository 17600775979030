import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const LevelsIcon = ({ size, className }) => (
  <Svg viewBox="0 0 24 24" size={size} className={className}>
    <path
      d="M16 23C16 23.5523 16.4477 24 17 24C17.5523 24 18 23.5523 18 23H16ZM18 9.25C18 8.69772 17.5523 8.25 17 8.25C16.4477 8.25 16 8.69772 16 9.25H18ZM8 23C8 23.5523 8.44772 24 9 24C9.55228 24 10 23.5523 10 23H8ZM10 1C10 0.447715 9.55228 0 9 0C8.44772 0 8 0.447715 8 1H10ZM0 23C0 23.5523 0.447715 24 1 24C1.55228 24 2 23.5523 2 23H0ZM2 14.75C2 14.1977 1.55228 13.75 1 13.75C0.447715 13.75 0 14.1977 0 14.75H2ZM18 23V9.25H16V23H18ZM10 23V1H8V23H10ZM2 23V14.75H0V23H2Z"
      fill="currentColor"
    />
  </Svg>
);

LevelsIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
};

LevelsIcon.defaultProps = {
  size: 24,
  className: null,
};

import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SidebarPanel, cleanObjectFilters } from 'components/SidebarPanel';
import { preloadFilterDatasets, setFilters, typeSearch } from 'slices/projectPermissionsReducer';
import { SidebarContext } from 'components/SidebarProvider/SidebarProvider';
import { ClearFilterAction } from 'components/SidebarPanel/ClearFilterAction';
import { SearchList } from 'components/SidebarPanel/SearchList/SearchList';
import { FormControl, FormLabel } from '@mui/material';
import { FormRadioGroup } from 'components/FormRadioGroup';

export const SearchPanel = () => {
  const dispatch = useDispatch();
  const ctx = useContext(SidebarContext);
  const selectType = ctx?.config?.search?.type;
  const projectId = ctx?.config?.search?.projectId;
  const filters = useSelector((state) => state.projectPermissions.filters);
  const nameSort = useSelector((state) => state.projectPermissions.filters.sort_by.name || '');
  const organizationId = useSelector((state) => state.projectPermissions.filters.organizationId || '');
  const accountId = useSelector((state) => state.projectPermissions.filters.accountId || '');
  const projectRole = useSelector((state) => state.projectPermissions.filters.projectRole || '');
  const { organizations, accounts, loaded } = useSelector((state) => state.projectPermissions.panels.search);
  const [filterActions, setFiltersActions] = useState([]);
  const [localFilters, setLocalFilters] = useState({
    sort_by: {},
    organizationId: '',
    accountId: '',
    projectRole: '',
  });

  // clear sort
  const clearSort = (param) => {
    const auxFilters = { ...localFilters };
    delete auxFilters.sort_by[param];
    auxFilters.sort_by = cleanObjectFilters(auxFilters.sort_by);
    setLocalFilters(auxFilters);
  };

  // update filters
  const updateFIlter = (auxFilters) => {
    setLocalFilters({ ...auxFilters });
  };

  // update radio buttons
  const updateNormalFilters = (value, type) => {
    const auxFilters = { ...localFilters };
    auxFilters[type] = value;
    setLocalFilters({ ...auxFilters });
  };

  // -------------- clear filters
  const handleClearFilters = () => {
    const auxFilters = { filters };
    if (cleanObjectFilters(auxFilters)?.filters) {
      dispatch(
        setFilters({
          sort_by: {},
          organizationId: '',
          accountId: '',
          projectRole: '',
          activated: false,
        })
      );
    }
  };

  // -------------- apply filters
  const handleApplyFilters = () => {
    const auxFilters = { ...localFilters };
    auxFilters.sort_by = cleanObjectFilters(auxFilters.sort_by);
    dispatch(setFilters({ ...auxFilters, activated: filterActions.length > 0 }));
  };

  const createClearFilters = (clearFilters) => {
    const auxFilters = [];
    if (clearFilters.sort_by?.name) auxFilters.push({ text: 'Sort By Name', callBack: () => clearSort('name') });
    if (clearFilters?.organizationId?.length > 0)
      auxFilters.push({ text: 'Clear By Organization', callBack: () => updateNormalFilters(null, 'organizationId') });
    if (clearFilters?.accountId?.length > 0)
      auxFilters.push({ text: 'Clear By Account', callBack: () => updateNormalFilters(null, 'accountId') });
    if (clearFilters?.projectRole)
      auxFilters.push({ text: 'Clear By Role', callBack: () => updateNormalFilters(null, 'projectRole') });
    setFiltersActions(auxFilters);
    return auxFilters;
  };

  useEffect(() => {
    createClearFilters(localFilters);
  }, [localFilters]);

  useEffect(() => {
    createClearFilters(filters);
    setLocalFilters({
      sort_by: {
        name: nameSort,
      },
      organizationId,
      accountId,
      projectRole,
    });
  }, [filters]);

  useEffect(() => {
    dispatch(preloadFilterDatasets({ type: selectType, projectId }));
  }, []);

  return (
    <SidebarPanel
      title="Filters"
      subTitle="Apply filters to table data."
      filters
      handleClearFilters={handleClearFilters}
      handleApplyFilters={handleApplyFilters}
    >
      <div className="searchBody">
        <ClearFilterAction actions={filterActions} />
        <FormControl>
          <FormLabel>Sort</FormLabel>
          <FormRadioGroup
            name="alphabetical"
            value={localFilters.sort_by.name}
            onChecked={(checkedVal) => {
              const sortBy = { ...localFilters.sort_by };
              sortBy.name = checkedVal;
              updateNormalFilters(sortBy, 'sort_by');
            }}
            options={[
              { label: 'A - Z', value: selectType === typeSearch.USER ? '+firstName' : '+name', disabled: false },
              { label: 'Z - A', value: selectType === typeSearch.USER ? '-firstName' : '-name', disabled: false },
              { label: 'None', value: '', disabled: false },
            ]}
            view="horizontal"
          />
        </FormControl>
        {selectType !== typeSearch.ORG && (
          <>
            <SearchList
              title="Organization"
              fullList={organizations}
              filter="organizationId"
              localFilters={localFilters}
              onChangeSelect={updateFIlter}
              loaded={loaded}
            />
            <SearchList
              title="Account"
              fullList={accounts}
              filter="accountId"
              localFilters={localFilters}
              onChangeSelect={updateFIlter}
              loaded={loaded}
            />
          </>
        )}
        <FormControl>
          <FormLabel className="marginTop">Role</FormLabel>
          <FormRadioGroup
            name="open"
            value={localFilters.projectRole}
            onChecked={(checkedVal) => updateNormalFilters(checkedVal, 'projectRole')}
            options={[
              { label: 'All', value: null },
              { label: 'Viewer', value: 15 },
              { label: 'Collaborator', value: 14 },
              { label: 'Contributor', value: 13 },
              { label: 'Participant Coordinator', value: 12 },
              { label: 'Content Coordinator', value: 11 },
              { label: 'Administrator', value: 10 },
            ]}
            view="horizontal"
          />
        </FormControl>
      </div>
    </SidebarPanel>
  );
};

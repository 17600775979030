import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  styledWidgetItem: {
    display: 'grid',
    gridTemplateColumns: '1fr 80px',
    gridTemplateRows: '1fr',
    padding: '0px 0px 0px 0px',
  },
  styledTitle: {
    flex: '1 1 auto',
    paddingRight: '10px',
  },
  styledChildren: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'end',
  },
});

import React, { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Avatar } from 'components/Avatar';
import { SidebarPanel } from 'components/SidebarPanel';
import { Button, Tab, Tabs, Typography } from '@mui/material';
import { resolveUserInitials } from 'lib/resolve-user-initials';
import headerImg from 'images/userDetail.png';
import { InfoPanelBodyHeader } from 'components/InfoPanelBodyHeader/InfoPanelBodyHeader';
import { UuidButton } from 'components/UuidButton/UuidButton';
import { TabPanel } from 'components/TabPanel';
import { getInitials } from 'lib/generalFunctions';
import { useGlobalStyles } from 'styles';
import { SidebarContext } from 'components/SidebarProvider/SidebarProvider';
import { DialogProjectInvite, typeInviteData } from 'components/DialogProjectInvite';
import { DialogTeamInviteUser } from 'components/DialogTeamInviteUser';
import { getUser } from 'slices/userReducer';
import { fetchMember } from 'slices/teamMembersReducer';

const actions = {
  USER_TO_PROJ: 'user_to_proj',
  USER_TO_TEAM: 'user_to_team',
};

export const InfoPanelBody = ({ loading, detail, projects, teams, teamMember }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const ctx = useContext(SidebarContext);
  const globalClasses = useGlobalStyles();
  const [userAction, setUserAction] = useState(null);

  // const history = useHistory();

  // const navigateToPermissions = () => {
  //   history.push(`/admin/users/${detail.id}/permissions`);
  // };

  const reloadData = (shouldReload) => {
    if (shouldReload) dispatch(teamMember ? getUser(detail.id) : fetchMember(detail.id));
    setUserAction(null);
  };

  return (
    <SidebarPanel
      title={detail?.organizations?.length > 0 && !loading ? detail.organizations[0].name : 'User Detail'}
      loading={loading}
    >
      <InfoPanelBodyHeader
        image={headerImg}
        name={resolveUserInitials({
          firstName: detail.firstName,
          lastName: detail.lastName,
          email: detail.email,
        })}
      />
      <div className={globalClasses.infoPanelBody}>
        <Typography mb={1} variant="h1" className={globalClasses.infoPanelName}>
          {detail.firstName} {detail.lastName}
        </Typography>
        <Typography mb={0.5} variant="h5">
          {detail.email}
        </Typography>
        {detail.phone && <Typography mb={0.5}>{detail.phone}</Typography>}
        <UuidButton data={detail} />
        {!ctx?.config?.search?.external && !teamMember && (
          <>
            <Button
              color="primary"
              variant="contained"
              size="large"
              sx={{ marginTop: '2rem' }}
              className={globalClasses.openDetail}
              onClick={() => setUserAction({ ...detail, type: actions.USER_TO_TEAM })}
            >
              Add to Team
            </Button>
            <Button
              color="secondary"
              variant="contained"
              size="large"
              sx={{ marginTop: '2rem', display: 'inline-block', marginLeft: '1rem' }}
              className={globalClasses.openDetail}
              onClick={() => setUserAction({ ...detail, type: actions.USER_TO_PROJ })}
            >
              Add to Project
            </Button>
          </>
        )}
        <Typography mt={1} className={globalClasses.infoPanelAboutText}>
          About
        </Typography>
        <Typography mb={1} variant="body2" className={globalClasses.infoPanelDescription}>
          {detail.description}
        </Typography>
        <Tabs value={value} className={globalClasses.infoPanelTabs} sx={{ width: '100%' }}>
          <Tab label="Teams" onClick={() => setValue(0)} sx={{ width: '50%' }} />
          <Tab label="Projects" onClick={() => setValue(1)} sx={{ width: '50%' }} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <div className={globalClasses.infoTabPanel}>
            {teams.map((team) => (
              <div className={globalClasses.nameContainer} style={{ margin: '10px' }}>
                <Avatar key={team.id} initials={getInitials(team.name)} size={48} />
                <div>
                  <Typography>{team.name}</Typography>
                  {/* <Typography className={globalClasses.greyTypography}>{getNameRole([...user.roles])}</Typography> */}
                </div>
              </div>
            ))}
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className={globalClasses.infoTabPanel}>
            {projects.map((project) => (
              <div className={globalClasses.nameContainer} style={{ margin: '10px' }}>
                <Avatar key={project.id} initials={getInitials(project.name)} size={48} />
                <div>
                  <Typography>{project.name}</Typography>
                  {/* <Typography className={globalClasses.greyTypography}>{getNameRole([...user.roles])}</Typography> */}
                </div>
              </div>
            ))}
          </div>
        </TabPanel>
      </div>
      {userAction?.id && userAction.type === actions.USER_TO_PROJ && (
        <DialogProjectInvite
          isOpen={userAction?.id}
          handleClose={reloadData}
          objectId={userAction.id}
          itemName={`${userAction.firstName} ${userAction.lastName}`}
          type={typeInviteData.PROJET}
          textDescription="User"
        />
      )}
      {userAction?.id && userAction.type === actions.USER_TO_TEAM && (
        <DialogTeamInviteUser isOpen={userAction?.id} onCancel={reloadData} userId={userAction?.id} title="Team" />
      )}
    </SidebarPanel>
  );
};

InfoPanelBody.propTypes = {
  loading: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  detail: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  projects: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  teams: PropTypes.array,
  teamMember: PropTypes.bool,
};

InfoPanelBody.defaultProps = {
  loading: false,
  detail: { id: null, firstName: '', lastName: '' },
  projects: [],
  teams: [],
  teamMember: false,
};

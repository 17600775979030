import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { ModalHeaderIcon } from 'components/ModalHeaderIcon/ModalHeaderIcon';
import { AlertTriangleIcon } from 'components/Icons';

export const DialogConfirmDownload = ({ isOpen, onCancel, onConfirm, credits, openPerms, data }) => {
  const tgAdmin = useSelector((state) => state.auth.tgAdmin);
  const permissions = useSelector((state) => state.auth.permissions);
  const [hasPermissions, setHasPermissions] = useState(false);

  useEffect(() => {
    // validate project permissions
    let found = false;
    if (tgAdmin) {
      found = true;
    } else if (permissions.projects && permissions.projects[data.id] && permissions.projects[data.id].role) {
      found = permissions.projects[data.id].role !== 'proj:view';
    } else if (permissions.projects && permissions.projects[data.id]) {
      Object.keys(permissions.projects[data.id]).forEach((key) => {
        if (key !== 'proj:view' && key !== 'proj:view_content') {
          found = true;
        }
      });
    }
    setHasPermissions(found);
  }, [permissions]);

  const canView = () => openPerms || hasPermissions;

  return (
    <Dialog open={isOpen} maxWidth="xs" onClose={onCancel}>
      <DialogTitle>
        <ModalHeaderIcon icon={AlertTriangleIcon} color="warning" />
        <Typography variant="h4">Restore Archived Content</Typography>
      </DialogTitle>
      {canView() && (
        <DialogContent>
          <Typography align="justify" paragraph>
            You have chosen to restore archived content. This content is in long-term storage and requires additional
            processing to retreive.
          </Typography>
          {(process.env.REACT_APP_HIDE_BILLING === false || process.env.REACT_APP_HIDE_BILLING === 'false') && (
            <Typography paragraph>{credits} credits will be deducted from your account.</Typography>
          )}
          <Typography />
          <Typography>Do you wish to proceed?</Typography>
        </DialogContent>
      )}
      {!canView() && (
        <DialogContent>
          <Typography align="justify" paragraph>
            You don&apos;t have permissions to view an archived file.
          </Typography>
        </DialogContent>
      )}
      <DialogActions>
        <Grid container spacing={1}>
          <Grid item xs={canView() ? 6 : 12}>
            <Button color="primary" variant="contained" onClick={onCancel} size="large" fullWidth>
              {canView() ? 'Cancel' : 'Ok'}
            </Button>
          </Grid>
          {canView() && (
            <Grid item xs={6}>
              <Button color="secondary" variant="contained" onClick={() => onConfirm()} size="large" fullWidth>
                Yes, Restore
              </Button>
            </Grid>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

DialogConfirmDownload.propTypes = {
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  credits: PropTypes.number,
  openPerms: PropTypes.bool,
  data: PropTypes.object,
};

DialogConfirmDownload.defaultProps = {
  isOpen: false,
  onConfirm: null,
  onCancel: null,
  credits: 0,
  openPerms: false,
  data: {},
};

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';
import { useStyles } from './styles';

export const WidgetSplash = ({
  alt = '',
  image = null,
  title = '',
  cta,
  button = true,
  titleAboveImage = true,
  onClick = () => {},
}) => {
  const classes = useStyles();

  return (
    <div className={classes.styledWidgetSplash}>
      {titleAboveImage && (
        <div className={classes.styledTitle}>
          <Typography className={classes.typograhpy}>{title}</Typography>
        </div>
      )}
      <img className={classes.styledImage} alt={alt} src={image} />
      {button && (
        <div className={classes.styledButtonContainer}>
          {cta && (
            <Button
              color="secondary"
              variant="contained"
              size="large"
              sx={{ color: 'white' }}
              type="button"
              onClick={onClick}
            >
              {cta}
            </Button>
          )}
        </div>
      )}
      {!titleAboveImage && (
        <div className={classes.styledTitle}>
          <Typography className={classes.typograhpy}>{title}</Typography>
        </div>
      )}
    </div>
  );
};

WidgetSplash.propTypes = {
  alt: PropTypes.node.isRequired,
  image: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  titleAboveImage: PropTypes.bool,
  cta: PropTypes.node.isRequired,
  button: PropTypes.bool,
  onClick: PropTypes.func,
};

WidgetSplash.defaultProps = {
  button: true,
  titleAboveImage: true,
  onClick: () => {},
};

import { AdminPanelButton } from 'components/AdminPanelButton';
import { ShieldIcon } from 'components/Icons';
import { SidebarPanel } from 'components/SidebarPanel';
import { SidebarPanelSection } from 'components/SidebarPanelSection';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

export const AdminPanel = () => {
  const { detail } = useSelector((state) => state.adminAdmins.panels);
  const history = useHistory();
  return (
    <SidebarPanel title="Account Admins">
      <SidebarPanelSection title="Admin Settings" padding={0}>
        <AdminPanelButton
          icon={ShieldIcon}
          onClick={() => {
            history.push(`/admin/admins/${detail.id}/permissions`);
          }}
        >
          Edit User Permissions.
        </AdminPanelButton>
      </SidebarPanelSection>
    </SidebarPanel>
  );
};

export class Form {
  /**
   * Determines if the form has an error and display the error message if necessary
   *
   * @param {object} formik formik instance from `useFormik()`
   * @param {string} field
   * @param {string} helpText
   * @returns
   */
  static fieldErrorHelper(formik, field, helpText = null) {
    return {
      error: !!(formik.touched[field] && formik.errors[field]),
      helpText: formik.touched[field] && formik.errors[field] ? formik.errors[field] : helpText,
    };
  }

  /**
   * Parse errors returned from axios into a format cmpatible with formik.
   *
   * @param {import("axios").AxiosError} err
   * @param {Record<key, string>} overrides Key value pairs to map api field names to formik field names if necessary.
   * @returns
   */
  static parseApiValidationError(err, overrides = {}) {
    if (err.response.status === 400) {
      const messages = err.response.data.error.extra;
      if (messages) {
        return Object.keys(messages).reduce((acc, key) => {
          acc[overrides[key] || key] = messages[key][0].message;

          return acc;
        }, {});
      }
    }
    return false;
  }
}

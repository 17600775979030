import { GoogleDriveIcon } from 'components/Icons';
import styled from 'styled-components';

export const GoogleButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  position: relative;
  background-color: white;
  font-weight: 700;
  vertical-align: middle;
  margin-top: ${(props) => props.theme.spacing(0.5)};
  padding: ${(props) => props.theme.spacing(0.35)};
  border-color: ${(props) => props.theme.palette.primary.base};
  color: black;
  &:hover {
    background-color: ${(props) => props.theme.palette.primary[500]};
  }
  &:disabled {
    color: ${(props) => props.theme.palette.primary[500]};
    background-color: ${(props) => props.theme.palette.secondary.disabled};
    cursor: default;
    border-color: ${(props) => props.theme.palette.secondary.base};
    pointer-events: none;
  }
`;

export const StyleDriveIcon = styled(GoogleDriveIcon)`
  cursor: pointer;
`;

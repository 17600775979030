import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { preloadFilterDatasets, setFilters, setFilterValue } from 'slices/profileTeamsReducer';
import { SearchPanelBody, searchPanelSection } from 'pages/Admin/Teams/SearchPanel/SearchPanelBody';

export const SearchPanel = () => {
  const nameSort = useSelector((state) => state.profileTeams.filters.sort_by.name || '');
  const dateSort = useSelector((state) => state.profileTeams.filters.sort_by.createdAt || '');
  const orgSort = useSelector((state) => state.profileTeams.filters.sort_by.org || '');
  const accountSort = useSelector((state) => state.profileTeams.filters.sort_by.account || '');
  const acctId = useSelector((state) => state.profileTeams.filters.acctId || '');
  const organizationId = useSelector((state) => state.profileTeams.filters.organizationId || '');
  const userRole = useSelector((state) => state.profileTeams.filters.userRole || '');
  const projectId = useSelector((state) => state.profileTeams.filters.projectId || '');
  const { accounts, organizations, projects, loaded } = useSelector((state) => state.profileTeams.panels.search);
  const dispatch = useDispatch();
  const { userId } = useSelector((state) => state.auth);

  React.useEffect(() => {
    dispatch(preloadFilterDatasets(userId));
  }, []);

  return (
    <SearchPanelBody
      loaded={loaded}
      projects={projects}
      accounts={accounts}
      organizations={organizations}
      filters={{
        sort_by: {
          name: nameSort,
          createdAt: dateSort,
          org: orgSort,
          account: accountSort,
        },
        acctId,
        organizationId,
        userRole,
        userId,
        projectId,
      }}
      setFilterValue={setFilterValue}
      setFilters={setFilters}
      section={searchPanelSection.PROFILETEAMS}
    />
  );
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import {
  UserTeamRoleOptions,
  roles,
  validateOptions,
  validation,
} from 'components/UserTeamRoleOptions/UserTeamRoleOptions';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from 'slices/userReducer';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import TeamService from 'services/TeamService';
import { DialogMessage } from 'components/DialogMessage';
import { InfoIcon } from 'components/Icons';
import { fetchTeams } from 'slices/adminTeamsReducer';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { ModalHeaderIcon } from 'components/ModalHeaderIcon/ModalHeaderIcon';
import { UserPlusIcon } from 'components/Icons/UserPlusIcon';

const validationSchema = validation;

export const DialogTeamInviteUser = ({ title, isOpen, onCancel, teamId, userId }) => {
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => (!userId && teamId ? state.users : state.adminTeams));
  const optionsData = useSelector((state) => (!userId && teamId ? state.users.data : state.adminTeams.teams));
  const [checkBoxValidation, setCheckBoxValidation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  useEffect(() => {
    if (!userId && teamId) {
      const sendFilters = {
        ...filters,
        sort_by: Object.values(filters.sort_by),
        page_size: 500,
      };
      dispatch(getUsers({ ...sendFilters, include: ['orgs'] }));
    } else {
      dispatch(fetchTeams({ page_size: 1000 }));
    }
  }, []);

  const rootOptions = optionsData.map((proj) => ({
    label: !userId && teamId ? `${proj.firstName} ${proj.lastName}` : proj.name,
    value: proj.id,
  }));

  const submitFormData = async (values) => {
    if (validateOptions(values)) {
      setCheckBoxValidation(true);
      return;
    }
    setIsLoading(true);
    try {
      await TeamService.addTeamMember(
        !userId && teamId ? teamId : values.id,
        !userId && teamId ? values.id : userId,
        roles(values)
      );
      setIsLoading(false);
      onCancel(true);
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.status === 400) {
        setOpenDialog(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : 'An error occurred while trying to edit the user.'
        );
      } else {
        setOpenDialog('An error occurred while trying to update the user.');
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      id: '',
      role: 0,
    },
    validationSchema,
    onSubmit(values) {
      submitFormData(values);
    },
  });

  return (
    <>
      <Dialog open={isOpen} maxWidth="xs" onClose={onCancel}>
        <DialogContent>
          <ModalHeaderIcon icon={UserPlusIcon} text={`Add ${title} to ${!userId && teamId ? 'Team' : 'User'}`} />
          <Typography mt={1} mb={1}>
            Select the {title} you would like to add this {!userId && teamId ? 'Team' : 'User'} to and set any special
            permissions
          </Typography>
          <InputLabel>{title} *</InputLabel>
          <FormControl error={formik.touched.id && formik.errors.id} fullWidth>
            <Autocomplete
              blurOnSelect="touch"
              name="id"
              options={rootOptions}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={`Select a ${title}...`}
                  onBlur={formik.handleBlur}
                  required
                  error={formik.touched.id && formik.errors.id}
                />
              )}
              onChange={(e, value) => {
                formik.handleChange('id')(value?.value ? value?.value : '');
              }}
              onBlur={formik.handleBlur}
            />
            <FormHelperText>{formik.touched.id && formik.errors.id ? formik.errors.id : null}</FormHelperText>
          </FormControl>
          <Box sx={{ padding: '5px 24px 0 24px' }}>
            <UserTeamRoleOptions
              formik={formik}
              checkBoxValidation={checkBoxValidation}
              handleChange={(label, value) => formik.handleChange(label)(value)}
            />
          </Box>
          <LoadingOverlay loading={isLoading} />
        </DialogContent>
        <DialogActions>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button color="primary" variant="contained" onClick={onCancel} size="large" fullWidth>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button color="secondary" variant="contained" onClick={formik.handleSubmit} size="large" fullWidth>
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <DialogMessage
        title={openDialog}
        isOpen={openDialog}
        icon={InfoIcon}
        confirmText="Ok"
        onConfirm={() => setOpenDialog(false)}
      />
    </>
  );
};
DialogTeamInviteUser.propTypes = {
  /**
   * The title is the text that is displayed on the screen
   */
  title: PropTypes.string,
  /**
   * is a boolean that checks if the modal is open or not
   */
  isOpen: PropTypes.bool,
  /**
   * this is what action is needed to be handled when clicking confirm
   */
  onCancel: PropTypes.func,
  teamId: PropTypes.string,
  userId: PropTypes.string,
};

DialogTeamInviteUser.defaultProps = {
  title: '',
  isOpen: false,
  onCancel: null,
  teamId: '',
  userId: '',
};

import { getExtension } from 'lib/content-helpers';
import { useDispatch } from 'react-redux'; // , useSelector
import ApiService from 'services/ApiService';
import {
  updateUploadQueue,
  UPLOAD_APPEND_FILE,
  UPLOAD_DELETE_FILE,
  UPLOAD_UPDATE_PERCENT,
} from 'slices/profileProjectAssetReducer';
// import { getFailedContents } from 'slices/failedImportedContent';

export const useTelemetryUploader = ({ projId, assetId, file }) => {
  // const dispatch = useDispatch();
  const telemetryUpload = async (importAssetID, telemetryFile, projectId) => {
    const filename = telemetryFile.split(/(\\|\/)/g).pop();
    const filetype = filename.split('.')[1];
    console.info(
      `importAssetID ${importAssetID} ${`/projects/${projectId}/assets/getTelemetryUploadURL`} ${filename} ${filetype}`
    );
    let hasCompleted = false;
    let percentComplete = 0;
    const telemetrySignedUrl = await ApiService.post(`/projects/${projectId}/assets/getTelemetryUploadURL`, {
      telemetryName: filename,
    });
    if (telemetrySignedUrl.data) {
      const xhrTelemetry = new XMLHttpRequest();
      xhrTelemetry.open('PUT', telemetrySignedUrl.data.data.data);
      xhrTelemetry.setRequestHeader('Content-Type', filetype);
      xhrTelemetry.send(telemetryFile);

      xhrTelemetry.upload.addEventListener(
        'progress',
        (e) => {
          if (e.lengthComputable) {
            percentComplete = Math.round((e.loaded * 100) / e.total);
            // eslint-disable-next-line
            console.info(`Has Completed ${percentComplete}`);
          }
        },
        false
      );

      xhrTelemetry.upload.addEventListener(
        'load',
        () => {
          hasCompleted = true;
          // eslint-disable-next-line
          console.info(`Has Completed ${hasCompleted}`);
        },
        false
      );

      const res = await ApiService.post(`/projects/${projectId}/assets/saveInTranscodingTable`, {
        fieldName: 'TelemetryLocation',
        fieldValue: telemetrySignedUrl.data.data.data.split('?')[0].replace('https://storage.googleapis.com', ''),
        assetId: importAssetID,
      });
      // eslint-disable-next-line
      console.info(`Has saved the value in the table ${JSON.stringify(res)}`);
      // const { filters } = useSelector((state) => state.failedContent);
      // dispatch(
      //   getFailedContents({
      //     ...filters,
      //   })
      // );
    }
  };
  telemetryUpload(assetId, file, projId);
};

export const useProjectAssetUploader = ({ projId }) => {
  const dispatch = useDispatch();

  const generateAccessUrl = `/projects/${projId}/assets/generateUploadSignURL`;
  const completeAssetUploadUrl = `/projects/${projId}/assets/completedUploadSignURL`;

  const processAsset = async (asset, file) => {
    const { signedUrl, id, ext, telemetryExt, projectSequenceId, telemetrySignedUrl } = asset;
    let hasCompleted = false;

    // telemetry file
    if (telemetrySignedUrl) {
      // externalTelemetry
      // start upload
      const xhrTelemetry = new XMLHttpRequest();
      xhrTelemetry.open('PUT', telemetrySignedUrl);
      xhrTelemetry.setRequestHeader('Content-Type', file.externalTelemetry.type);
      xhrTelemetry.send(file.externalTelemetry);
    }

    dispatch(
      updateUploadQueue({
        type: UPLOAD_APPEND_FILE,
        file: { ...asset, uploadPercentComplete: 0, projId },
      })
    );

    // on status update
    const onUploadStatus = async (percent) => {
      dispatch(
        updateUploadQueue({
          type: UPLOAD_UPDATE_PERCENT,
          id,
          uploadPercentComplete: percent,
        })
      );

      // when percent reaches 100 upload is complete
      if (percent !== 100 || hasCompleted) return;
      hasCompleted = true;
      delete window.uploadQueueXHR[id];

      await ApiService.post(completeAssetUploadUrl, {
        assets: [
          {
            id,
            ext,
            telemetryExt,
            projectSequenceId,
          },
        ],
      });

      let timeout;
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        dispatch(
          updateUploadQueue({
            type: UPLOAD_DELETE_FILE,
            id,
            projId: '',
          })
        );
      }, 60 * 1000); // 1 min
    };

    // start upload
    const xhr = new XMLHttpRequest();
    if (!window.uploadQueueXHR) window.uploadQueueXHR = {};
    window.uploadQueueXHR[id] = xhr;

    xhr.upload.addEventListener(
      'progress',
      (e) => {
        if (e.lengthComputable) {
          const percentage = Math.round((e.loaded * 100) / e.total);
          onUploadStatus(percentage);
        }
      },
      false
    );

    xhr.upload.addEventListener(
      'load',
      () => {
        onUploadStatus(100);
      },
      false
    );

    xhr.open('PUT', signedUrl);
    xhr.setRequestHeader('Content-Type', file.data.type);
    xhr.send(file.data);
  };

  const uploadFiles = async ({ files, isSeries, isGroup }) => {
    const fileLookup = {};

    const sendObj = {
      isSeries,
      assets: files.map((file, index) => {
        fileLookup[index] = file;
        return {
          type: file.metaType,
          ext: getExtension(file.data.name),
          name: (isSeries || isGroup) && index > 0 ? `${file.metaName} (${index})` : file.metaName,
          size: file.data.size,
          description: file.description || '',
          deviceType: file.metaType === 'VIDEO' ? file.cameraType : -1,
          ordinal: isSeries ? index : 0,
          acqDate: file.metaAcqDate,
          telemetryExt: file.externalTelemetry ? getExtension(file.externalTelemetry.name) : '',
        };
      }),
    };

    const generateAccessResults = await ApiService.post(generateAccessUrl, sendObj);
    const { assets } = generateAccessResults.data.data;

    // TODO: Verify that this works, or find another way to map fileLookup to the returned assets
    assets.forEach((asset, idx) => {
      processAsset(asset, fileLookup[idx]);
    });
  };

  return uploadFiles;
};

export const useProjectAssetUploaderStorage = ({ projId }) => {
  const generateAccessUrl = `/projects/${projId}/assets/uploadFromService`;

  const processAsset = async (asset, file) => {
    const { telemetrySignedUrl } = asset;

    // telemetry file
    if (telemetrySignedUrl) {
      // externalTelemetry
      // start upload
      const xhrTelemetry = new XMLHttpRequest();
      xhrTelemetry.open('PUT', telemetrySignedUrl);
      xhrTelemetry.setRequestHeader('Content-Type', file.externalTelemetry.type);
      xhrTelemetry.send(file.externalTelemetry);
    }
  };

  const sendFiles = async ({ auth, files, isSeries, isGroup, service }) => {
    const fileLookup = {};
    const sendObj = {
      data: { isSeries },
      service,
      token: auth,
      picked: files.map((file, index) => {
        fileLookup[file.id] = file;
        return {
          id: file.id,
          mimeType: file.mimeType,
          type: file.metaType,
          ext: getExtension(file.data.name),
          name: (isSeries || isGroup) && index > 0 ? `${file.metaName} (${index})` : file.metaName,
          size: file.data.sizeBytes,
          description: file.description || '',
          deviceType: file.metaType === 'VIDEO' ? file.cameraType : -1,
          ordinal: isSeries ? index : 0,
          acqDate: file.metaAcqDate,
          telemetryExt: file.externalTelemetry ? getExtension(file.externalTelemetry.name) : '',
          telemetryService: file.telemetryStorage ? file.telemetryStorage : false,
          telemetryId: file.telemetryStorage ? file.externalTelemetry.id : '',
        };
      }),
    };

    const generateAccessResults = await ApiService.post(generateAccessUrl, sendObj);
    const assets = generateAccessResults.data.data;

    // TODO: Verify that this works, or find another way to map fileLookup to the returned assets
    assets.forEach((asset) => {
      processAsset(asset, fileLookup[asset.id]);
    });

    return generateAccessResults.status;
  };

  return sendFiles;
};

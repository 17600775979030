import * as Sentry from '@sentry/react';
import { AppRouteContainer } from 'components/AppRouteContainer/AppRouteContainer';
import { AcceptInvitation } from 'pages/AcceptInvitation/AcceptInvitation';
import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { theme } from 'theme2';
import { AuthGuard } from './components/Guards/AuthGuard';
import { PrivateRoute } from './components/PrivateRoute';
import Auth from './lib/auth';
/* System User Pages */
import { Login } from './pages/Auth/Login';
import { PasswordReset } from './pages/Auth/PasswordReset';
import { PasswordResetRequest } from './pages/Auth/PasswordResetRequest';
import { Accounts } from './pages/sa/Accounts';
import { CreateAccount } from './pages/sa/CreateAccount';
import { TGAdminAccountCreate } from './pages/sa/TGAdminAccountCreate';

const App = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Router>
      <AuthGuard>
        <div className="App">
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/login" />} />
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/request-reset">
              <PasswordResetRequest />
            </Route>
            <Route path="/reset-password/:userId/:resetToken">
              <PasswordReset />
            </Route>
            <Route path="/accept-invite/:userId/:inviteToken">
              <AcceptInvitation />
            </Route>
            <PrivateRoute path="/sa/accounts" validate={() => Auth.isTgAdmin()}>
              <Accounts />
            </PrivateRoute>
            <PrivateRoute path="/sa/create-account" validate={() => Auth.isTgAdmin()}>
              <CreateAccount />
            </PrivateRoute>
            <PrivateRoute path="/sa/tgadmin-create" validate={() => Auth.isTgAdmin()}>
              <TGAdminAccountCreate />
            </PrivateRoute>
            <Route path="/">
              <AppRouteContainer />
            </Route>
          </Switch>
        </div>
      </AuthGuard>
    </Router>
  </ThemeProvider>
);

export default Sentry.withProfiler(App);

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Bytes } from 'lib/bytes';
import { SidebarPanel } from 'components/SidebarPanel';
import { SidebarContext } from 'components/SidebarProvider/SidebarProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilm, faImage } from '@fortawesome/free-solid-svg-icons';
import { Button, Tab, Tabs, Typography } from '@mui/material';
import { InfoPanelBodyHeader } from 'components/InfoPanelBodyHeader/InfoPanelBodyHeader';
import externalImg from 'images/externalContentDetail.png';
import contentImg from 'images/contentDetail.png';
import { getInitials } from 'lib/generalFunctions';
import { UuidButton } from 'components/UuidButton/UuidButton';
import { TabPanel } from 'components/TabPanel';
import { useGlobalStyles } from 'styles';
import { ViewRoleValidation } from 'components/ViewRoleValidation/ViewRoleValidation';
import { DialogProjectCopy } from 'components/DialogProjectCopy';
import { Permissions } from 'lib/permissions';
import { DialogProjectShare } from 'components/DialogProjectShare';
import ProjectService from 'services/ProjectService';
import { ContentDetail } from 'components/ContentDetail/ContentDetail';
import { useDispatch } from 'react-redux';
import { SidebarDetailContext } from 'components/SidebarProviderDetail/SidebarProviderDetail';
import { setContentDetail } from 'slices/contentReducer';
import { Time } from 'lib/time';

const action = {
  view: 'view',
  copy: 'copy',
  share: 'share',
};

export const InfoPanelBody = ({ loading, detail }) => {
  const globalClasses = useGlobalStyles();
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const [contentAction, setContentAction] = useState('');
  const [validateRole, setValidateRole] = useState({ open: false, callBack: () => {} });
  const ctx = useContext(SidebarContext);
  const { setOpen, handleClose } = useContext(SidebarContext);
  const { setActivePanel, setFullWidth, setHeaderPanels } = useContext(SidebarDetailContext);
  const [observations, setObservations] = useState([]);

  // show content clicking on row
  const handleRowClick = () => {
    dispatch(setContentDetail(detail));
    setOpen(false);
    handleClose();
    setHeaderPanels([
      {
        name: 'contentDetail',
        component: ContentDetail,
      },
    ]);
    setFullWidth(true);
    setActivePanel('contentDetail');
  };

  const SizeCal = () => {
    const numbers = detail.sequences.map((as) => as.asset.assetFiles.reduce((total, file) => total + file.size, 0));
    const summation = numbers.reduce((accumulator, currentValue) => accumulator + currentValue);
    return Bytes.dynamicHumanReadable({ bytes: summation });
  };

  const getType = () => {
    if (detail.sequences?.length > 0 && detail.sequences[0].asset?.type) {
      return detail.sequences[0].asset?.type;
    }
    return '';
  };

  // ----------------------- copy asset to project
  const handleProjectCopy = async (projId) => {
    await ProjectService.CopyAssetWithProject(detail.project.id, detail.id, projId);
    setContentAction('');
  };

  // ----------------------- share asset to project
  const handleProjectShare = async (projId) => {
    await ProjectService.ShareAssetWithProject(detail.project.id, detail.id, {
      shareWithProjectId: projId,
      assetId: detail.id,
    });
    setContentAction('');
  };

  useEffect(() => {
    let auxObservations = [];
    if (detail?.sequences?.length) {
      detail.sequences.forEach((element) => {
        auxObservations = auxObservations.concat(element.observations);
      });
    }
    setObservations(auxObservations);
  }, [detail]);

  return (
    <SidebarPanel title="Content" loading={loading}>
      <InfoPanelBodyHeader
        image={ctx?.config?.search?.external ? externalImg : contentImg}
        name={getInitials(detail?.name)}
      />
      <div className={globalClasses.infoPanelBody}>
        <Typography mb={1} variant="h1" className={globalClasses.infoPanelName}>
          {detail?.name}
        </Typography>
        {getType() && (
          <Typography mb={0.1} variant="h5">
            {getType()}
          </Typography>
        )}
        {getType() === 'VIDEO' && (
          <Typography mb={0.1}>
            {Time.humanReadable({
              seconds: detail.timeLength,
            })}
          </Typography>
        )}
        {getType() === 'PHOTO' && <Typography mb={0.1}>{detail.sequences?.length} Images</Typography>}
        <Typography mb={0.1}>{detail.sequences && SizeCal()}</Typography>
        <Typography mb={0.1}>{observations.length} Observations</Typography>
        <Typography mb={0.1}>
          {detail.uploaders?.length && `${detail.uploaders[0].firstName} ${detail.uploaders[0].lastName}`}
        </Typography>
        <UuidButton data={detail} />
        <div style={{ display: 'flex', gap: 8 }}>
          <Button
            color="secondary"
            variant="contained"
            size="large"
            sx={{ marginTop: '2rem' }}
            className={globalClasses.openDetail}
            onClick={() => handleRowClick()}
          >
            View
          </Button>
          {!ctx?.config?.search?.external && (
            <>
              <Button
                color="primary"
                variant="contained"
                size="large"
                sx={{ marginTop: '2rem' }}
                className={globalClasses.openDetail}
                onClick={() =>
                  setValidateRole({
                    open: true,
                    action: [
                      Permissions.PROJ_MODIFY_CONTENT,
                      Permissions.PROJ_MODIFY_CONTENT_SHARE_COPY,
                      Permissions.PROJ_COPY,
                    ],
                    callBack: () => setContentAction(action.copy),
                  })
                }
              >
                Copy to Project
              </Button>
              <Button
                color="primary"
                variant="contained"
                size="large"
                sx={{ marginTop: '2rem', display: 'inline-block' }}
                className={globalClasses.openDetail}
                onClick={() =>
                  setValidateRole({
                    open: true,
                    action: [
                      Permissions.PROJ_MODIFY_CONTENT,
                      Permissions.PROJ_MODIFY_CONTENT_SHARE_COPY,
                      Permissions.PROJ_SHARE,
                    ],
                    callBack: () => setContentAction(action.share),
                  })
                }
              >
                Share to Project
              </Button>
            </>
          )}
        </div>
        <Typography mt={1} className={globalClasses.infoPanelAboutText}>
          About
        </Typography>
        <Typography mb={1} variant="body2" className={globalClasses.infoPanelDescription}>
          {detail?.description}
        </Typography>
        <Tabs value={value} className={globalClasses.infoPanelTabs} sx={{ width: '100%' }}>
          <Tab label="Series Segments" onClick={() => setValue(0)} sx={{ width: '100%' }} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <div className={globalClasses.infoTabPanel}>
            {detail?.sequences &&
              detail?.sequences.map((asset, index) => {
                const fileSize = asset.asset.assetFiles.reduce((total, file) => total + file.size, 0);
                return (
                  <div className={globalClasses.nameContainer} style={{ margin: '10px' }}>
                    <FontAwesomeIcon icon={getType() === 'PHOTO' ? faImage : faFilm} size="lg" />
                    <div>
                      <Typography>Segment {index + 1}</Typography>
                      <Typography className={globalClasses.greyTypography}>
                        {Bytes.dynamicHumanReadable({ bytes: fileSize })}
                      </Typography>
                    </div>
                  </div>
                );
              })}
          </div>
        </TabPanel>
      </div>
      {contentAction === action.copy && (
        <DialogProjectCopy
          title="Select a project to copy this content with."
          isOpen
          onConfirm={handleProjectCopy}
          onCancel={() => {
            setContentAction('');
          }}
          asset={detail}
        />
      )}
      {contentAction === action.share && (
        <DialogProjectShare
          title="Select a project to share this content with."
          isOpen
          onConfirm={handleProjectShare}
          onCancel={() => {
            setContentAction('');
          }}
          asset={detail}
        />
      )}
      {validateRole.open && (
        <ViewRoleValidation
          action={validateRole.action}
          data={{ ...detail?.project, detail }}
          callBack={validateRole.callBack}
          handleClose={() => setValidateRole({ open: false, callBack: () => {} })}
        />
      )}
    </SidebarPanel>
  );
};

InfoPanelBody.propTypes = {
  loading: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  detail: PropTypes.object,
};

InfoPanelBody.defaultProps = {
  loading: false,
  detail: { id: null, name: '' },
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import libPhone from 'libphonenumber-js';
import { WidgetAvatarUpload } from 'components/WidgetAvatarUpload/WidgetAvatarUpload';
import { WidgetDivider } from 'components/WidgetDivider/WidgetDivider';
import { WidgetItem } from 'components/WidgetItem/WidgetItem';
import { WidgetUser } from 'components/WidgetUser/WidgetUser';
import ProjectService from 'services/ProjectService';
// import UserAssets from 'services/UserAssets';
import { updateUserInfo } from 'slices/authReducer';
import { ModalEditUser } from 'smartComponents/ModalEditUser';
import { LinearProgress, Typography } from '@mui/material';
import { useGlobalStyles } from 'styles';
import { useStyles as useAdminStyles } from '../../Admin/Dashboard/styles';
import { useStyles } from './styles';

export const UserInfoWidget = ({ uploadsData }) => {
  const classes = useStyles();
  const adminClasses = useAdminStyles();
  const globalClasses = useGlobalStyles();
  const [projectData, setProjectData] = useState(0);
  const [contentData, setContentData] = useState(0);
  const [openEditUser, setOpenEditUser] = useState(false);
  const dispatch = useDispatch();

  const { userId, fname, lname, phone, email, description } = useSelector((state) => state.auth);

  const initialLoad = async () => {
    const projectResult = await ProjectService.getProjects({ userId });

    setProjectData(projectResult.data.meta.totalCount);
  };

  useEffect(() => {
    setContentData(uploadsData?.meta?.totalCount || 0);
  }, [uploadsData]);

  useEffect(() => {
    initialLoad();
  }, []);

  const getInitials = (str) => {
    const names = str.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  // Manage the Open Add a New user Modal
  const handleEditUser = async (userData) => {
    dispatch(
      updateUserInfo({
        firstName: userData.firstName,
        lastName: userData.lastName,
        phone: userData.phone,
        description: userData.description,
      })
    );
    setOpenEditUser(false);
  };

  const handleCancelEditUser = () => {
    setOpenEditUser(false);
  };

  return (
    <div className={adminClasses.userInfo}>
      <div className={classes.infoSection}>
        <WidgetUser
          userTitle={`${fname} ${lname}`}
          userSubTitle={description === null || description === '' ? 'No bio found' : description}
          initials={getInitials(`${fname} ${lname}`)}
          border
        />
        <div className={classes.editButton}>
          <WidgetAvatarUpload
            iconClick={() => {
              setOpenEditUser(true);
            }}
          />
        </div>
      </div>
      <WidgetDivider margin />
      {/* Bio & Phones Sections */}

      <div className={classes.container}>
        <Typography variant="body1">
          Primary Phone
          <Typography className={globalClasses.greyTypography} variant="body1">
            {phone === null ? 'No Primary Phone Found' : libPhone(phone, 'US').formatNational()}
          </Typography>
        </Typography>
      </div>

      <WidgetItem title="Email" subHeader={email} />

      <WidgetDivider />

      <WidgetItem title="Your Contributions" subHeader="Your total uploads so far.">
        <Typography variant="body" spacing={0.5}>
          {contentData}
        </Typography>
        <Typography className={globalClasses.greyTypography} variant="body1">
          <LinearProgress variant="determinate" value={0} />
        </Typography>
      </WidgetItem>

      <WidgetItem style={{ width: '80px' }} title="Projects" subHeader="Total projects you’re participating in.">
        <Typography variant="body" spacing={0.5}>
          {projectData}
        </Typography>
        <LinearProgress variant="determinate" value={0} />
      </WidgetItem>
      {openEditUser && (
        <ModalEditUser
          isOpen={openEditUser}
          onConfirm={handleEditUser}
          onCancel={handleCancelEditUser}
          userId={userId}
        />
      )}
    </div>
  );
};

UserInfoWidget.propTypes = {
  uploadsData: PropTypes.array.isRequired,
};

import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const ArrowLeftIcon = ({ size, className, onClick }) => (
  <Svg viewBox="0 0 24 24" size={size} className={className} onClick={onClick}>
    <path
      d="M11.293 23.293a1 1 0 001.414 0l.586-.586a1 1 0 000-1.414L5.5 13.5H23a1 1 0 001-1v-1a1 1 0 00-1-1H5.5l7.793-7.793a1 1 0 000-1.414l-.586-.586a1 1 0 00-1.414 0L.707 11.293a1 1 0 000 1.414l10.586 10.586z"
      fill="currentColor"
    />
  </Svg>
);

ArrowLeftIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

ArrowLeftIcon.defaultProps = {
  size: 24,
  className: '',
  onClick: () => {},
};

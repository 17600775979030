import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const FileIcon = ({ size, className }) => (
  <Svg viewBox="0 0 24 24" size={size} className={className}>
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        d="M2.264 0A2.276 2.276 0 000 2.264v19.472A2.276 2.276 0 002.264 24h19.472A2.276 2.276 0 0024 21.736V2.264A2.276 2.276 0 0021.736 0zm0 1.532h19.472a.72.72 0 01.732.732v14.212H6.792l2.537-3.383a.732.732 0 011.103-.08l1.025 1.026a.766.766 0 001.155-.082l3.296-4.393a.732.732 0 011.17 0l2.667 3.555a.766.766 0 001.073.154.766.766 0 00.152-1.074l-2.665-3.554a2.266 2.266 0 00-3.622 0l-2.765 3.686-.403-.4a2.264 2.264 0 00-3.41.243l-3.228 4.302H1.532V2.264a.72.72 0 01.732-.732zm4.119 2.212a2.651 2.651 0 00-2.639 2.639 2.651 2.651 0 002.639 2.639 2.651 2.651 0 002.639-2.639 2.651 2.651 0 00-2.639-2.639zm0 1.532c.62 0 1.107.487 1.107 1.107S7.003 7.49 6.383 7.49s-1.107-.487-1.107-1.107.487-1.107 1.107-1.107zM1.532 18.008h20.936v3.728a.72.72 0 01-.732.732H2.264a.72.72 0 01-.732-.732z"
        fill="currentColor"
      />
    </svg>
  </Svg>
);

FileIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
};

FileIcon.defaultProps = {
  size: 24,
  className: '',
};

import { Button, Container, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import React, { useState, useEffect, useRef } from 'react';
import { BrochureMapServices } from 'components/Icons/BrochureMapServices';
import { mapGisAction } from 'pages/Admin/MapGis/helpers';
import { ActivityOptions } from './ActivityOptions';
import { ExternalMap } from './ExternalMap';
import { GisServices } from './GisServices';
import { StyledError, toggleButtonStyles } from './styles';
import MapGisService from 'services/MapGisService';
import AccountService from 'services/AccountService';

const ActivityOptionDesc = {
  ExternalMap: {
    Title: 'External Map',
    ItemDesc:
      '<p><br/>Dynamically link external maps to the location of media you are viewing in CartoVid.<br/><br/>' +
      'Once added, you can open this map to the same map extent your media is displaying with only a few clicks.<br/><br/></p>',
  },
  GisService: {
    Title: 'GIS Service',
    ItemDesc:
      '<p><br/>Please paste any URL string or service string for an existing GIS service that you wish to incorporate into your CartoVid configuration.<br/><br/></p>',
  },
  BaseModalForm: {
    Title: 'Base modal form',
  },
};

const PageTitle = ({ page }) => {
  let mainTitle = 'Link to Other Maps or Services';
  let subTitle = 'Which would you like to link to?';

  if (page === ActivityOptionDesc.ExternalMap.Title) {
    mainTitle = 'Link to External Map';
    subTitle = '';
  } else if (page === ActivityOptionDesc.GisService.Title) {
    mainTitle = 'Link to GIS Service';
    subTitle = '';
  }
  return (
    <DialogContent>
      <BrochureMapServices size={45} />
      <Typography style={{ fontWeight: '500' }} variant="h4" as="h4">
        {mainTitle}
      </Typography>
      <Typography variant="small" as="span">
        {subTitle}
      </Typography>
    </DialogContent>
  );
};

export const ModalLinkToMapOrServices = ({ handleOpen, isOpen, mapGis, action }) => {
  const [selectedOptionDesc, setSelectedOptionDesc] = useState('');
  const [selectedActivityOption, setSelectedActivityOption] = useState('');
  const [externalMapPressed, setExternalMapPressed] = useState(false);
  const [gisServicePressed, setGisServicePressed] = useState(false);
  const [nextActionPressed, setNextActionPressed] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [submitError, setSubmitError] = React.useState('');
  const externalMapRef = useRef(null);
  const gisServiceRef = useRef(null);
  const cancel = 'Cancel';
  const back = 'Back';
  const next = 'Next';
  const save = 'Save';

  const [footerLeftButton, setFooterLeftButton] = useState('');
  const [footerRightButton, setFooterRightButton] = useState('');
  const [showUiElement, setShowUiElement] = useState(true);
  const [accountOrg, setAccountOrg] = useState('');
  const regex = new RegExp(
    '^(https?:\\/\\/)?([a-zA-Z0-9-]+\\.)*[a-zA-Z0-9-]+\\.[a-zA-Z]{2,}(\\/[a-zA-Z0-9_\\-\\/\\.]*)?(\\?[^#\\s]*)?$',
    'g'
  );

  useEffect(() => {
    const getAccountDetails = async () => {
      try {
        const orgAccount = await AccountService.getAccount();
        setAccountOrg(orgAccount.data.data);
      } catch (err) {
        console.info('error', err);
      }
    };
    getAccountDetails();
  }, []);

  const saveGisService = async (values) => {
    setLoading(true);
    let data;
    let levelShortDesc = '';
    let levelIdInfo = '';

    if (accountOrg !== null) {
      levelShortDesc = 'ACCT';
      levelIdInfo = accountOrg.id;
    }

    try {
      if (gisServicePressed && !externalMapPressed) {
        data = {
          levelId: levelIdInfo,
          levelDesc: levelShortDesc,
          mapName: values.name,
          mapDescription: values.desc,
          mapLayerOn: true,
          isActive: true,
          arcgisMapId: values.webMapId,
          mapRootUrl: values.url,
          mapType: values.service,
          serviceDesc: selectedActivityOption,
        };
      } else if (externalMapPressed && !gisServicePressed) {
        data = {
          levelId: levelIdInfo,
          levelDesc: levelShortDesc,
          mapName: values.name,
          mapDescription: values.desc,
          isActive: true,
          arcgisMapId: values.webMapId,
          connectionParameter: values.url,
          serviceDesc: selectedActivityOption,
        };
      }

      if (action === mapGisAction.EDIT) {
        await MapGisService.updateMapGisService(mapGis.id, data);
      } else {
        await MapGisService.saveMapGisService(data);
      }

      setLoading(false);
    } catch (err) {
      const errorResponse = err.response.data.message;
      // console.log('error', errorResponse);
      setSubmitError(errorResponse);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: action === mapGisAction.EDIT ? mapGis?.mapName : '',
      url: action === mapGisAction.EDIT ? mapGis?.mapRootUrl : '',
      desc: action === mapGisAction.EDIT ? mapGis?.mapDescription : '',
      service: action === mapGisAction.EDIT ? mapGis?.mapType : '',
      webMapId: action === mapGisAction.EDIT ? mapGis?.webMapId : '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(12, 'Name must be at least 12 characters')
        .max(50, 'Name must be at most 50 characters')
        .required('Name is required'),
      url: Yup.string().matches(regex, 'Enter correct url!').required('URL is required'),
      desc: Yup.string()
        .min(12, 'Description must be at least 12 characters')
        .max(255, 'Description must be at most 255 characters')
        .required('Description is required'),
      service: Yup.string().when('gisServicePressed', {
        is: true,
        then: Yup.string().required('Service is required'),
        otherwise: Yup.string().default('externalmap'),
      }),
      // service: Yup.string().required('Service is required'),
    }),
    onSubmit: async (values, { resetForm }) => {
      console.info('values formik onSubmit', values);
      console.info('2-mapGisAction', mapGisAction);
      saveGisService(values);
      handleOpen(false);
      resetForm();
    },
  });

  const { handleSubmit, setErrors } = formik;

  const activityOptionsDefaultState = () => {
    setSelectedOptionDesc(`${ActivityOptionDesc.ExternalMap.Title}:${ActivityOptionDesc.ExternalMap.ItemDesc}`);
    setSelectedActivityOption(ActivityOptionDesc.BaseModalForm.Title);
    setExternalMapPressed(true);
    setGisServicePressed(false);
    setShowUiElement(true);
    setNextActionPressed(false);
  };

  useEffect(() => {
    setFooterLeftButton(cancel);
    setFooterRightButton(next);

    setExternalMapPressed(false);
    setGisServicePressed(false);

    setShowUiElement(true);
    setNextActionPressed(false);

    activityOptionsDefaultState();
  }, []);

  useEffect(() => {
    if (action === mapGisAction.EDIT && mapGis?.id) {
      formik.setFieldValue('name', mapGis?.mapName || '');
      formik.setFieldValue('url', mapGis?.mapRootUrl || '');
      formik.setFieldValue('desc', mapGis?.mapDescription || '');
      formik.setFieldValue('webMapId', mapGis?.arcgisMapId || '');
      if (mapGis?.serviceDesc === 'GIS Service') {
        formik.setFieldValue('service', mapGis?.mapType || '');
      }
    }
  }, [mapGis]);

  const handleWizardOption = (wizardOption, e) => {
    if (wizardOption === cancel) {
      handleOpen(false);
      formik.resetForm();
    } else if (wizardOption === next) {
      if (externalMapPressed && !gisServicePressed) {
        setSelectedActivityOption(ActivityOptionDesc.ExternalMap.Title);
        setFooterLeftButton(back);
        setFooterRightButton(save);
        setShowUiElement(false);
        setNextActionPressed(true);
      } else if (gisServicePressed && !externalMapPressed) {
        setSelectedActivityOption(ActivityOptionDesc.GisService.Title);
        setFooterLeftButton(back);
        setFooterRightButton(save);
        setShowUiElement(false);
        setNextActionPressed(true);
      }
    } else if (wizardOption === back) {
      setSelectedActivityOption(ActivityOptionDesc.BaseModalForm.Title);
      setFooterLeftButton(cancel);
      setFooterRightButton(next);
      setShowUiElement(true);
      setNextActionPressed(false);
    } else if (wizardOption === save) {
      console.info('externalMapPressed', externalMapPressed);
      console.info('gisServicePressed', gisServicePressed);

      try {
        handleSubmit(e);
      } catch (error) {
        console.info('Form submission error:', error);
        setErrors({ form: 'Submission failed' }); // Set form errors
      }
    }
  };

  const handleActivityOption = (selection) => {
    if (selection === ActivityOptionDesc.ExternalMap.Title) {
      activityOptionsDefaultState();
    } else if (selection === ActivityOptionDesc.GisService.Title) {
      setSelectedOptionDesc(`${ActivityOptionDesc.GisService.Title}:${ActivityOptionDesc.GisService.ItemDesc}`);
      setSelectedActivityOption(ActivityOptionDesc.BaseModalForm.Title);
      setExternalMapPressed(false);
      setGisServicePressed(true);
      setShowUiElement(true);
      setNextActionPressed(false);
    }
  };

  const initialState = !externalMapPressed && !gisServicePressed && showUiElement && !nextActionPressed;
  const activityOptionsStates = (externalMapPressed || gisServicePressed) && showUiElement;

  const onLoadButton = (serviceDesc, button) => {
    if (mapGis?.serviceDesc === serviceDesc && button) button.click();
  };

  useEffect(() => {
    onLoadButton('GIS Service', gisServiceRef.current);
    onLoadButton('External Map', externalMapRef.current);
  }, [gisServiceRef.current, externalMapRef.current]);

  return (
    <Dialog open={isOpen} maxWidth="xs" onClose={() => handleOpen(false)} onSubmit={handleSubmit} noValidate hideClose>
      <PageTitle page={selectedActivityOption} />
      <DialogContent>
        <Grid container alignItems="center" justifyContent="center">
          {initialState || activityOptionsStates ? (
            <div>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <Button
                  id="externalMapBtn"
                  color="primary"
                  variant="contained"
                  fullWidth
                  ref={externalMapRef}
                  style={{
                    ...toggleButtonStyles.toggleButton,
                    ...(externalMapPressed ? toggleButtonStyles.selected : {}),
                  }}
                  onClick={() => handleActivityOption(ActivityOptionDesc.ExternalMap.Title)}
                >
                  {ActivityOptionDesc.ExternalMap.Title}
                </Button>

                <Button
                  id="gisServiceBtn"
                  color="primary"
                  variant="contained"
                  fullWidth
                  ref={gisServiceRef}
                  style={{
                    ...toggleButtonStyles.toggleButton,
                    ...(gisServicePressed ? toggleButtonStyles.selected : {}),
                  }}
                  onClick={() => handleActivityOption(ActivityOptionDesc.GisService.Title)}
                >
                  {ActivityOptionDesc.GisService.Title}
                </Button>
              </Grid>

              {(externalMapPressed || gisServicePressed) && (
                <Container style={{ background: '#383838', marginTop: '10px', borderRadius: '8px', height: '200px' }}>
                  <Grid item xs={12}>
                    <ActivityOptions selectedActivityOption={selectedOptionDesc} />
                  </Grid>
                </Container>
              )}
            </div>
          ) : (
            <div style={{ textAlign: 'left', marginLeft: '-22px' }}>
              <Grid item xs={12}>
                {externalMapPressed && <ExternalMap loading={loading} formik={formik} />}
                {gisServicePressed && <GisServices loading={loading} formik={formik} />}
              </Grid>
            </div>
          )}
        </Grid>

        {submitError && <StyledError>{submitError}.</StyledError>}
      </DialogContent>

      <Grid container alignItems="center" justifyContent="center">
        <div>
          <Grid item xs={12} style={{ display: 'flex', marginTop: '20px' }}>
            <DialogActions border>
              <Button
                color="primary"
                variant="contained"
                style={{ marginTop: '-15px', marginLeft: '5px', width: '189px' }}
                fullWidth
                onClick={(event) => handleWizardOption(footerLeftButton, event)}
              >
                {footerLeftButton}
              </Button>

              <Button
                color="secondary"
                variant="contained"
                style={{ marginTop: '-15px', marginLeft: '12px', width: '189px' }}
                disabled={loading}
                fullWidth
                onClick={(event) => handleWizardOption(footerRightButton, event)}
              >
                {footerRightButton}
              </Button>
            </DialogActions>
          </Grid>
        </div>
      </Grid>
    </Dialog>
  );
};

ModalLinkToMapOrServices.propTypes = {
  isOpen: PropTypes.bool,
  handleOpen: PropTypes.func.isRequired,
  mapGis: PropTypes.object,
  action: PropTypes.string,
};

ModalLinkToMapOrServices.defaultProps = {
  isOpen: false,
  mapGis: { name: '', id: 0 },
  action: mapGisAction.CREATE,
};

PageTitle.propTypes = {
  page: PropTypes.string,
};

PageTitle.defaultProps = {
  page: 'Base modal form',
};

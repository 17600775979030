import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  preloadFilterDatasets,
  setFilters,
  setFilterValue,
  dateRangeSelector,
} from 'slices/profileProjectAssetReducer';
import { SearchPanelBody, searchPanelSection } from './SearchPanelBody';

export const SearchPanel = () => {
  const dispatch = useDispatch();
  const nameSort = useSelector((state) => state.profileProjectAssets.filters.sort_by.name || '');
  const dateSort = useSelector((state) => state.profileProjectAssets.filters.sort_by.createdAt || '');
  const orgSort = useSelector((state) => state.profileProjectAssets.filters.sort_by.org || '');
  const accountSort = useSelector((state) => state.profileProjectAssets.filters.sort_by.account || '');
  const userId = useSelector((state) => state.profileProjectAssets.filters.userId || '');
  const teamId = useSelector((state) => state.profileProjectAssets.filters.teamId || '');
  const pastId = useSelector((state) => state.profileProjectAssets.filters.pastId || '');
  const ownership = useSelector((state) => state.profileProjectAssets.filters.ownership || '');
  const type = useSelector((state) => state.profileProjectAssets.filters.type || '');
  const acctId = useSelector((state) => state.profileProjectAssets.filters.acctId || '');
  const getExtent = useSelector((state) => state.profileProjectAssets.filters.getExtent || false);
  const dateRange = useSelector(dateRangeSelector);
  const { users, teams, accounts, loaded } = useSelector((state) => state.profileProjectAssets.panels.search);
  const { projectAsset } = useSelector((state) => state.profileProjectAssets);
  const { detail } = useSelector((state) => state.allProjects.panels);

  useEffect(() => {
    dispatch(preloadFilterDatasets(detail.id));
  }, []);

  return (
    <SearchPanelBody
      loaded={loaded}
      users={users}
      teams={teams}
      accounts={accounts}
      selectedAsset={projectAsset}
      project={detail}
      filters={{
        sort_by: {
          name: nameSort,
          createdAt: dateSort,
          org: orgSort,
          account: accountSort,
        },
        date_range: dateRange,
        userId,
        teamId,
        pastId,
        acctId,
        ownership,
        type,
        getExtent,
      }}
      setFilterValue={setFilterValue}
      setFilters={setFilters}
      section={searchPanelSection.PROJECTASSETS}
    />
  );
};

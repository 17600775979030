import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const MapFileIcon = ({ size }) => (
  <Svg viewBox="0 0 24 24" size={size}>
    <path
      d="M9 18L2 22V6L9 2M9 18L16 22M9 18V2M16 22L22 18V2L16 6M16 22V6M16 6L9 2"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </Svg>
);

MapFileIcon.propTypes = {
  size: PropTypes.number,
};

MapFileIcon.defaultProps = {
  size: 24,
};

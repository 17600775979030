import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Form } from 'lib/form';
import { useFormik } from 'formik/dist';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { ModalHeaderIcon } from 'components/ModalHeaderIcon/ModalHeaderIcon';
import ProjectService from 'services/ProjectService';
import { CheckIcon, CloseIcon, LayersIcon } from 'components/Icons';
import { useDispatch } from 'react-redux';
import { setProject } from 'slices/allProjectReducer';
import { FormTextArea } from 'components/FormTextArea';
import { WidgetDivider } from 'components/WidgetDivider/WidgetDivider';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';

const validationSchema = Yup.object({
  name: Yup.string()
    .trim()
    .matches(/^[A-Za-z0-9.\s]+$/, 'Name can only contain letters, numbers, and dots')
    .min(1, 'Name must contain at least one character')
    .max(255)
    .required('Name is required'),
  description: Yup.string()
    .trim()
    .matches(/^[A-Za-z0-9.\s]+$/, 'Name can only contain letters, numbers, and dots')
    .min(1, 'Description must contain at least one character')
    .max(255)
    .required('Description is required'),
});

export const ModalEditProject = ({ handleConfirm, handleCancel, isOpen, projId, project, detail }) => {
  const dispatch = useDispatch();
  const [name, setName] = React.useState('');
  const [submitError, setSubmitError] = React.useState('');
  const [loading, setLoading] = React.useState('');
  const [confirmButtons, setConfirmButtons] = React.useState({ name: false, description: false });

  // submit data to API
  const onSubmitProject = (values, field = false) => {
    const updateProject = async () => {
      setLoading(true);
      try {
        await ProjectService.updateProject(detail ? project?.id : projId, {
          name: values.name,
          description: values.description,
        });
        setLoading(false);
        if (detail) {
          dispatch(setProject({ ...project, name: values.name, description: values.description }));
          if (field) {
            const auxConfirm = { ...confirmButtons };
            auxConfirm[field] = false;
            setConfirmButtons({ ...auxConfirm });
          }
        } else {
          // update state with new roles
          handleConfirm();
        }
      } catch (err) {
        setLoading(false);
        const result = err.response.data.message;
        setSubmitError(result);
      }
    };

    updateProject();
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
    },
    validationSchema,
    onSubmit(values) {
      onSubmitProject(values);
    },
  });

  // get focus in input
  const handleFocus = (field, value) => {
    const auxConfirm = { ...confirmButtons };
    auxConfirm[field] = value;
    if (!value) {
      formik.setFieldValue(field, project[field]);
    }
    setConfirmButtons({ ...auxConfirm });
  };

  // event after submit
  const beforeSumbit = (field) => {
    const auxValues = {
      name: project.name,
      description: project.description,
      [field]: formik.values[field],
    };
    onSubmitProject(auxValues, field);
  };

  useEffect(() => {
    if (detail) {
      formik.setValues({ name: project.name, description: project.description });
    } else {
      const getProject = async () => {
        const newResult = await ProjectService.getProject(projId);
        formik.setValues({ name: newResult.data.data.name, description: newResult.data.data.description });
        setName(newResult.data.data.name);
      };
      getProject();
    }
  }, []);

  const { values, handleChange, handleBlur } = formik;

  const ConfimrButtons = (type) =>
    detail && (
      <Grid item xs={12} className="content-text-button">
        <Typography className="instructionText">To make changes, click in the field and type.</Typography>
        {confirmButtons[type] && (
          <div className="confimButtonsContainer">
            <Button
              color="success"
              variant="contained"
              onClick={() => beforeSumbit(type)}
              disabled={formik.errors[type] || loading}
            >
              <CheckIcon size={25} />
            </Button>
            <Button color="error" variant="contained" onClick={() => handleFocus(type, false)}>
              <CloseIcon size={25} />
            </Button>
          </div>
        )}
      </Grid>
    );

  const Content = () => (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <InputLabel>{detail && 'Project '}Name *</InputLabel>
            <TextField
              placeholder="Current Name"
              id="name"
              name="name"
              value={values.name}
              onChange={handleChange}
              {...Form.fieldErrorHelper(formik, 'name')}
              onBlur={handleBlur}
              onFocus={() => handleFocus('name', true)}
              error={formik.touched.name && formik.errors.name}
              helperText={formik.touched.name && formik.errors.name ? formik.errors.name : null}
              disabled={loading}
              required
              fullWidth
            />
          </Grid>
          {ConfimrButtons('name')}
        </Grid>
      </Grid>
      {detail && (
        <Grid item xs={12}>
          <WidgetDivider margin={0.5} />
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <InputLabel>{detail && 'Project '}Description *</InputLabel>
            {detail ? (
              <FormTextArea
                placeholder="Project Description"
                rows="5"
                value={values.description}
                id="description"
                name="description"
                onFocus={() => handleFocus('description', true)}
                {...Form.fieldErrorHelper(formik, 'description')}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={loading}
                required
              />
            ) : (
              <TextField
                placeholder="Project Description"
                id="description"
                name="description"
                value={values.description}
                onChange={handleChange}
                {...Form.fieldErrorHelper(formik, 'description')}
                onBlur={handleBlur}
                error={formik.touched.description && formik.errors.description}
                helperText={formik.touched.description && formik.errors.description ? formik.errors.description : null}
                disabled={loading}
                required
                fullWidth
              />
            )}
          </Grid>
          {ConfimrButtons('description')}
        </Grid>
      </Grid>
      {submitError && (
        <Grid item xs={12}>
          <Typography color="error">{submitError}.</Typography>
        </Grid>
      )}
    </Grid>
  );

  return (
    <>
      {detail ? (
        Content()
      ) : (
        <Dialog open={isOpen} maxWidth="xs" onClose={handleCancel}>
          <DialogTitle>
            <ModalHeaderIcon icon={LayersIcon} text={`Update ${name}`} color="warning" />
          </DialogTitle>
          <DialogContent>{Content()}</DialogContent>
          <DialogActions>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Button color="primary" variant="contained" onClick={handleCancel} disabled={loading} fullWidth>
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={formik.handleSubmit}
                  disabled={loading}
                  fullWidth
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      )}
      <LoadingOverlay loading={loading} />
    </>
  );
};

ModalEditProject.propTypes = {
  handleConfirm: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  projId: PropTypes.string.isRequired,
  project: PropTypes.object,
  detail: PropTypes.bool,
};

ModalEditProject.defaultProps = {
  project: null,
  detail: false,
};

/* eslint-disable no-console */
import React from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { AccountSettingsContainer } from 'components/AccountSettingsContainer/AccountSettingsContainer';
import { AdminHeaderTitle } from 'components/AdminHeaderTitle/AdminHeaderTitle';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Card,
  CardContent,
  Typography,
  CardActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { ComingSoon } from 'components/ComingSoon/ComingSoon';
// eslint-disable-next-line no-unused-vars
import { MapSimplePoint } from 'components/MapSimplePoint/MapSimplePoint';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { ProjectRetentionForm } from 'smartComponents/ModalProjectSettings/ProjectRetentionForm';
import AccountService from 'services/AccountService';
import { useStyles } from './styles';

const retentionValidationSchema = Yup.object({
  retentionMonths: Yup.number(),
  overrideRetention: Yup.boolean(),
});

export const AdminSecurity = () => {
  const history = useHistory();
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [selected, setSelected] = React.useState('none');
  const [loading, setLoading] = React.useState(false);

  const initialValues = {
    securityOptions: [],
  };

  const formik = useFormik({
    initialValues,
    onSubmit() {
      history.push('/admin');
    },
  });

  const retentionFormik = useFormik({
    initialValues: {
      retentionMonths: 0,
      overrideRetention: false,
    },
    retentionValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        await AccountService.updateAccount(values);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    },
  });

  const patchSettings = () => {
    setLoading(true);
    retentionFormik.handleSubmit();
  };
  // eslint-disable-next-line no-unused-vars
  const points = {
    none: [],
    group1: [
      {
        lat: 35.7745,
        long: -78.7689,
      },
      {
        lat: 35.6941382884979,
        long: -83.0455524921417,
      },
    ],
    group2: [
      {
        lat: 21.3932406,
        long: -157.9861567,
      },
      {
        lat: 35.2484075841664,
        long: -77.5307598498993,
      },
    ],
  };

  const handleCheckedChange = ({ value, checked }) => {
    if (!checked) {
      formik.values.securityOptions.push(value);
    } else {
      formik.values.securityOptions = formik.values.securityOptions.filter((option) => option !== value);
    }
  };

  React.useEffect(async () => {
    const acct = await AccountService.getAccount();
    retentionFormik.setFieldValue('overrideRetention', acct.data.data.overrideRetention);
    retentionFormik.setFieldValue('retentionMonths', acct.data.data.retentionMonths);
  }, []);

  const card = (title, subtitle, buttonText, action) => (
    <Card sx={{ minWidth: 345 }} style={{ background: 'rgba(0,0,0,0.3)' }}>
      <CardContent>
        <Typography sx={{ fontSize: 14, color: 'white' }} gutterBottom>
          {title}
        </Typography>
        <Typography variant="body2" style={{ color: 'white' }}>
          {subtitle}
        </Typography>
      </CardContent>
      <CardActions>
        <Button color="primary" variant="contained" onClick={action} size="large" fullWidth>
          {buttonText}
        </Button>
      </CardActions>
    </Card>
  );

  return (
    <AccountSettingsContainer title={<AdminHeaderTitle buttonName="ADMINISTRATION" section="SETTINGS" />}>
      <Card style={{ background: 'transparent' }}>
        {(process.env.REACT_APP_HIDE_SETTINGS === true || process.env.REACT_APP_HIDE_SETTINGS === 'true') && (
          <ComingSoon />
        )}
        {(process.env.REACT_APP_HIDE_SETTINGS === false || process.env.REACT_APP_HIDE_SETTINGS === 'false') && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <>
                  {card('Purchase History', 'View your purchased credit history.', 'View', () =>
                    history.push('/admin/paymentHistory')
                  )}
                </>
                <>{card('Credit Use History', 'View your credit usage history.', 'View', () => {})}</>
                <>
                  {card(
                    'Inspections',
                    'Enabling Inspections will allow Org Admins and Project Managers to create and assign inspections to project content.',
                    'Enable',
                    () => {}
                  )}
                </>
              </Grid>
              <Grid item xs={4}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="panel0-content"
                    id="panel0-header"
                  >
                    <Typography>Security</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <form onSubmit={formik.handleSubmit} noValidate>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={<Checkbox color="secondary" />}
                            disabled
                            label="Force double authentication for admin users."
                            value="doubleAuthAdmin"
                            name="securityOptions"
                            color="secondary"
                            onChange={() => {
                              handleCheckedChange('doubleAuthAdmin');
                            }}
                            checked={false}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={<Checkbox color="secondary" />}
                            label="Force double authentication for general system users."
                            value="doubleAuthUser"
                            name="securityOptions"
                            checked={false}
                            onChange={() => handleCheckedChange('doubleAuthUser')}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={<Checkbox color="secondary" />}
                            label="Force password reset for all users at next login."
                            value="forceReset"
                            name="securityOptions"
                            checked={false}
                            onChange={() => handleCheckedChange('forceReset')}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Button type="submit" disabled variant="contained" color="secondary">
                            Update Settings
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography>Deletion Policy</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet
                      blandit leo lobortis eget.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>Retention Policy</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Select your Account&apos;s default retention policy. When a project reaches term, it will move to
                      &apos;Archived&apos;. This can be overriden at the org and project level. (System default is 3
                      Years.)
                    </Typography>
                    <div className={classes.sharingContent}>
                      <ProjectRetentionForm
                        formik={retentionFormik}
                        loading={loading}
                        handleChange={() => patchSettings()}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                    <Typography>Archived Projects</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet
                      blandit leo lobortis eget.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                    <Typography>External Buckets</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography style={{ paddingBottom: 30 }}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet
                      blandit leo lobortis eget.
                    </Typography>{' '}
                    <Button color="primary" variant="contained" onClick={() => {}} size="large" fullWidth>
                      Open External Buckets Page
                    </Button>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={4}>
                <Card sx={{ minWidth: 345 }} style={{ background: 'rgba(0,0,0,0.3)' }}>
                  <CardContent>
                    <Typography sx={{ fontSize: 14, color: 'white' }} gutterBottom>
                      Auto-Assign Location
                    </Typography>
                    {/* <select
                      onChange={(e) => {
                        setSelected(e.target.value);
                      }}
                    >
                      <option value="none">None</option>
                      <option value="group1">Group 1</option>
                      <option value="group2">Group 2</option>
                    </select>

                    <div>Point Group: {selected}</div>
                    {loading ? (
                      <Loader loading height={32} />
                    ) : (
                      <MapSimplePoint project={project} selectedAsset={selectedAsset} handleRowClick={handleRowClick} />
                    )} */}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </>
        )}
      </Card>
    </AccountSettingsContainer>
  );
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, TextField } from '@mui/material';
import { FilterIcon } from 'components/Icons';
import { setFilters, setProjectObservationsFilter } from 'slices/observationsReducer';
import { ObservationSearch } from 'components/Observation/ObservationSearch/ObservationSearch';
import { useSidebar } from 'components/SidebarProvider/SidebarProvider';
import { useStyles } from '../styles';

export const ProjectObservationsFilters = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { projectObservations } = useSelector((state) => state.assetObservations);
  const { filters } = useSelector((state) => state.assetObservations);

  const { displayPanel, setFullWidth } = useSidebar({
    open: false,
    config: {
      onClose: () => {},
      search: {
        show: true,
        component: ObservationSearch,
        background: true,
      },
    },
  });

  const handleOpenFilters = () => {
    setFullWidth(false);
    displayPanel('search');
  };

  const localSort = (array) =>
    array.sort((a, b) => {
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const key in filters.sort_by) {
        const aValue = key === 'assetName' ? a.asset.name : a[key];
        const bValue = key === 'assetName' ? b.asset.name : b[key];
        if (aValue < bValue) return filters.sort_by[key] === '+' ? -1 : 1;
        if (aValue > bValue) return filters.sort_by[key] === '+' ? 1 : -1;
      }
      return 0;
    });

  // apply filters in array
  const observationFilters = () => {
    let auxObservations = [...projectObservations];
    if (filters?.date_range?.length > 0) {
      const startDate = new Date(filters?.date_range[0]);
      const endDate = new Date(filters?.date_range[1]);
      auxObservations = auxObservations.filter((item) => {
        const itemDate = new Date(item.createdAt);
        if (`${startDate}` === `${endDate}`) {
          const newDate = new Date(startDate);
          newDate.setDate(newDate.getDate() + 1);
          return itemDate >= startDate && itemDate <= newDate;
        }
        return itemDate >= startDate && itemDate <= endDate;
      });
    }
    if (filters?.assetId?.length > 0) {
      auxObservations = auxObservations.filter((item) => filters?.assetId.includes(item.asset.id));
    }
    if (filters?.search) {
      auxObservations = [...auxObservations].filter((item) =>
        item.name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }
    auxObservations = localSort(auxObservations);
    dispatch(setProjectObservationsFilter(auxObservations));
  };

  // functions to search in project observations
  const filterOptions = (value) => {
    dispatch(
      setFilters({
        search: value,
      })
    );
  };

  useEffect(() => {
    observationFilters();
  }, [filters]);

  return (
    <div className={classes.filters}>
      <TextField
        variant="outlined"
        placeholder="Search"
        className={classes.inputFilter}
        InputProps={{
          startAdornment: <FontAwesomeIcon icon={faMagnifyingGlass} size="lg" color="white" />,
        }}
        value={filters.search}
        onChange={(e) => filterOptions(e.target.value)}
      />
      <Button
        size="medium"
        color="primary"
        variant="contained"
        className={`${classes.filterButton} ${filters.activated && classes.activatedFilter}`}
        onClick={() => handleOpenFilters()}
      >
        <FilterIcon size={15} /> Filters {filters.activated && 'On'}
      </Button>
    </div>
  );
};

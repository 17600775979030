import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const ArrowRightIcon = ({ size, className, onClick }) => (
  <Svg viewBox="0 0 24 24" size={size} className={className} onClick={onClick}>
    <path
      d="M12.707.707a1 1 0 00-1.414 0l-.586.586a1 1 0 000 1.414L18.5 10.5H1a1 1 0 00-1 1v1a1 1 0 001 1h17.5l-7.793 7.793a1 1 0 000 1.414l.586.586a1 1 0 001.414 0l10.586-10.586a1 1 0 000-1.414L12.707.707z"
      fill="currentColor"
    />
  </Svg>
);
ArrowRightIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

ArrowRightIcon.defaultProps = {
  size: 24,
  className: '',
  onClick: () => {},
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'components/Avatar/Avatar';
import { ContextMenu } from 'components/ContextMenu/ContextMenu';
import { ContextMenuItem } from 'components/ContextMenuItem/ContextMenuItem';
import { ContextMenuList } from 'components/ContextMenuItems/ContextMenuList';
import { resolveUserInitials } from 'lib/resolve-user-initials';
import { useDispatch, useSelector } from 'react-redux';
import EnumService from 'services/EnumService';
import OrganizationService from 'services/OrganizationService';
import UserService from 'services/UserService';
import { getOrganizations } from 'slices/organizationReducer';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUserXmark } from '@fortawesome/free-solid-svg-icons';
import { ContextMenuButton } from 'components/ContextMenuButton';
import { ModalHeaderIcon } from 'components/ModalHeaderIcon/ModalHeaderIcon';
import { UsersIcon } from 'components/Icons';
import { useStyles } from './styles';

let debounceTimeout = null;
export const ModalOrganizationInviteUsers = ({ onCancel, onConfirm, isOpen, orgId }) => {
  const classes = useStyles();
  const [organizationRoles, setOrganizationRoles] = React.useState([]);
  const [organizationAdmins, setOrganizationAdmins] = React.useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [submitError, setSubmitError] = React.useState('');
  const [searchResults, setSearchResults] = useState([]);
  const dispatch = useDispatch();

  const { filters } = useSelector((state) => state.organizations);

  const loadData = () => {
    const sendFilters = {
      ...filters,
      sort_by: Object.values(filters.sort_by),
    };
    dispatch(getOrganizations(sendFilters));
  };

  React.useEffect(() => {
    // Get main Organizations Roles
    const getRoles = async () => {
      const orgResponse = await EnumService.getOrgRoles();
      // Format the long naming issue to match Figma Mockup
      orgResponse.data.data.map((role) => {
        role.name = role.name.length <= 26 ? role.name : role.name.replace('Organization', '');
        role.displayName = role.name;
        role.roleId = role.id;
        delete role.name;
        delete role.id;
        return role;
      });
      setOrganizationRoles(orgResponse.data.data);
    };
    getRoles();

    // Get Organization Users
    const getUsers = async () => {
      const newResult = await OrganizationService.getOrganizationUsers(orgId, {
        include: 'roles',
        sort_by: ['firstName', 'lastName'],
        page_size: 1000,
      });

      setOrganizationAdmins(newResult.data.data);
    };
    getUsers();
  }, []);

  // Declare this function to handle the search results =>  all users endpoint
  const FilterOrg = async () => {
    const newResult = await UserService.getUsers({
      search: searchTerm,
      page_size: 6,
      assignable: 'org',
    });

    const adminLookup = organizationAdmins.reduce((acc, admin) => {
      acc[admin.id] = true;
      return acc;
    }, {});

    const result = newResult.data.data.filter((item1) => !adminLookup[item1.id]);
    setSearchResults(result);
  };

  const handleChange = (e) => {
    const newSearchTerm = e.target.value || '';
    setSearchTerm(newSearchTerm);
    clearTimeout(debounceTimeout);
    if (newSearchTerm === '') {
      setSearchResults([]);
      return;
    }
    debounceTimeout = setTimeout(() => {
      FilterOrg();
    }, 150);
  };

  // Declare this function to remove an admin
  const removeNewAdmin = (newUser) => {
    setOrganizationAdmins((oldOrgAdminRoles) =>
      oldOrgAdminRoles.map((adm) => {
        const newObj = {
          ...adm,
          roles: [...adm.roles],
        };

        if (newObj.id === newUser.id) {
          newObj.roles = [];
        }

        return newObj;
      })
    );
  };

  // Declare this function to add an admin
  const addOrgAdmin = async (newUser) => {
    newUser.roles = [
      {
        code: 'org:part',
        displayName: 'Organization Participant',
        grantId: '',
        grantObjectId: '',
        ngrantObjectType: '',
        roleId: 9,
        userId: newUser.id,
      },
    ];

    const newAdminsList = [...organizationAdmins, newUser];
    const sortedList = newAdminsList.sort((a, b) => {
      const result = a.firstName.localeCompare(b.firstName);
      return result !== 0 ? result : a.lastName.localeCompare(b.lastName);
    });

    setOrganizationAdmins(sortedList);
    // Reset Search Result
    setSearchTerm('');
    setSearchResults([]);
  };

  // Declare this function to add user's roles
  const addUserRoles = (userId, roleId) => {
    const selectedRole = organizationRoles.find((role) => role.roleId === roleId);
    selectedRole.userId = userId;
    selectedRole.grantId = '';
    selectedRole.grantObjectId = '';
    selectedRole.grantObjectType = '';
    delete selectedRole.description;

    const userExist = organizationAdmins.some((admin) => admin.id === userId);
    if (userExist) {
      setOrganizationAdmins((oldOrgAdminRoles) =>
        oldOrgAdminRoles.map((adm) => {
          const newObj = {
            ...adm,
            roles: [...adm.roles],
          };

          if (newObj.id === userId) {
            newObj.roles = [selectedRole];
          }

          return newObj;
        })
      );
    }
  };

  // Manage Submit Manage Invite Admins Modal
  const onSubmitInvitation = () => {
    const updateRoles = async () => {
      try {
        await Promise.all(
          organizationAdmins.map((user) =>
            OrganizationService.updateUserRoles(
              orgId,
              user.id,
              user.roles.map(({ roleId }) => roleId)
            )
          )
        );
        // update state with new roles
        loadData();
        onConfirm();
      } catch (err) {
        const errorResponse = err.response.data.message;
        setSubmitError(errorResponse);
      }
    };

    updateRoles();
  };

  return (
    <Dialog open={isOpen} maxWidth="sm" onClose={onCancel}>
      <DialogTitle>
        <ModalHeaderIcon icon={UsersIcon} text="Manage Users" />
      </DialogTitle>
      <DialogContent>
        <div className={classes.invitationCard}>
          <TextField
            variant="outlined"
            placeholder="Search"
            type="text"
            value={searchTerm}
            InputProps={{
              startAdornment: <FontAwesomeIcon icon={faPlus} size="2x" style={{ marginRight: '1rem' }} />,
            }}
            onChange={handleChange}
            prepend
            fullWidth
          />

          <div className={classes.searchResults}>
            {searchResults.map((user) => (
              <Button
                className={classes.invitationItem}
                key={user?.id}
                onClick={() => {
                  addOrgAdmin(user);
                }}
              >
                {user.firstName} {user.lastName} ({user.email})
              </Button>
            ))}
          </div>
        </div>

        <div className={classes.itemContainer}>
          {/* Loop throught the Organization Users */}
          {organizationAdmins.map(
            (admin) =>
              admin.roles.length !== 0 && (
                <div className={classes.itemPanel} key={admin.id}>
                  <div className={classes.itemSection}>
                    <IconButton color="error" onClick={() => removeNewAdmin(admin)}>
                      <FontAwesomeIcon icon={faUserXmark} size="2x" />
                    </IconButton>
                    <Avatar
                      image={admin.photo}
                      initials={resolveUserInitials({
                        firstName: admin.firstName,
                        lastName: admin.lastName,
                        email: admin.email,
                      })}
                      size={36}
                    />
                    <Typography variant="body1">
                      {admin.firstName} {admin.lastName}
                    </Typography>
                    <div className={classes.itemTagSection}>
                      {organizationAdmins
                        .find((user) => user.id === admin.id)
                        .roles.map((roleTag) => (
                          <div className={classes.itemTag} key={roleTag.code}>
                            <Typography variant="caption">
                              {roleTag.displayName.length <= 26
                                ? roleTag.displayName
                                : roleTag.displayName.replace('Organization', '')}
                            </Typography>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className={classes.itemRoles}>
                    <ContextMenu>
                      <ContextMenuButton size="small">Choose Role(s)</ContextMenuButton>
                      <ContextMenuList position="bottom-right">
                        {organizationRoles.map((role) => (
                          <ContextMenuItem key={role.roleId} onClick={() => addUserRoles(admin.id, role.roleId)}>
                            {role.displayName}
                          </ContextMenuItem>
                        ))}
                      </ContextMenuList>
                    </ContextMenu>
                  </div>
                </div>
              )
          )}
        </div>
        {submitError && (
          <Typography color="error" mt={1}>
            {submitError}.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button color="primary" variant="contained" onClick={onCancel} size="large" fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button color="secondary" variant="contained" onClick={() => onSubmitInvitation()} size="large" fullWidth>
              Confirm
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

ModalOrganizationInviteUsers.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  isOpen: PropTypes.bool,
  orgId: PropTypes.string.isRequired,
};

ModalOrganizationInviteUsers.defaultProps = {
  isOpen: false,
  onConfirm: null,
  onCancel: null,
};

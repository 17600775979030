import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const DevicesIcon = ({ size, className }) => (
  <Svg viewBox="0 0 24 24" size={size} classname={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.984 13.516V6.484H18v7.032h3.984Zm1.032-9c.28 0 .515.093.703.28A.954.954 0 0 1 24 5.5v9.984c0 .282-.094.532-.281.75a.954.954 0 0 1-.703.282h-6a1.12 1.12 0 0 1-.75-.282 1.12 1.12 0 0 1-.282-.75V5.5c0-.281.094-.516.282-.703a1.12 1.12 0 0 1 .75-.281h6ZM3.984 2.5v11.016h10.032v3H0v-3h2.016V2.5c0-.531.187-1 .562-1.406.406-.407.875-.61 1.406-.61h18V2.5h-18Z"
      fill="currentColor"
    />
  </Svg>
);

DevicesIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
};

DevicesIcon.defaultProps = {
  size: 24,
  className: '',
};

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
// import ArcGISMap from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import WebMap from '@arcgis/core/WebMap';
// import IdentityManager from '@arcgis/core/identity/IdentityManager';

import esriConfig from '@arcgis/core/config';

// export const MapViewComponent = ({ mapData, webMapId }) => {
//   const [mapView, setMapView] = useState(null);
//   const mapDivRef = useRef(null);
//   // const esriId = new IdentityManager();
//   // let mapView;
//   let isMounted = true;

//   useEffect(() => {
//     if (isMounted) {
//       // Generate a token
//       IdentityManager.generateToken({
//         server: 'https://scrates-medina.maps.arcgis.com',
//         username: 'smedina_dh',
//         password: 'ZjzKTpP9mT34dje',
//         expiration: 1440,
//       })
//         .then((response) => {
//           // Use the token to create the WebMap
//           esriConfig.portalUrl = 'https://scrates-medina.maps.arcgis.com';
//           esriConfig.token = response.token;

//           // Check if the token has expired
//           const expirationTime = new Date(response.expires);
//           const currentTime = new Date();
//           if (expirationTime < currentTime) {
//             console.info('Token has expired');
//           } else {
//             console.info('Token is still valid');
//           }

//           const map = new WebMap({ portalItem: { id: webMapId } });

//           // Create a new view
//           const newMapView = new MapView({
//             container: mapDivRef.current,
//             map,
//             center: mapData.center || [-118.244, 34.052], // Default to Los Angeles if not provided
//             zoom: mapData.zoom || 14, // Default zoom level if not provided
//           });

//           // Set the mapView state
//           setMapView(newMapView);
//         })
//         .catch((error) => {
//           console.error('Error generating token:', error);
//         });
//     }

//     return () => {
//       if (mapView) {
//         isMounted = false;
//         mapView.destroy();
//       }
//     };
//   }, []);

//   return <div ref={mapDivRef} style={{ width: '100%', height: '40vh' }} />;
// };

export const MapViewComponent = ({ mapData, webMapId }) => {
  const mapDivRef = useRef(null);
  const [mapView, setMapView] = useState(null);

  useEffect(() => {
    esriConfig.apiKey =
      'AAPK0482d1006a7c42c08a351f1a89e7de61vON37s3-6OqnYwgd0_l1zzQVXNIZDNpPhdG-ha75sZC2mlol_NpRcqouCDsufCXo';
  }, []);

  useEffect(() => {
    if (mapView) {
      mapView.map.basemap = 'hybrid';
      mapView.map.center = mapData.center || [-118.244, 34.052];
      mapView.map.zoom = mapData.zoom || 14;
    }
  }, [mapView, mapData]);

  useEffect(() => {
    const map = new WebMap({ portalItem: { id: webMapId } });
    console.info('mapData', mapData);

    const newMapView = new MapView({
      container: mapDivRef.current,
      map,
      zoom: 14,
      // center: mapData.center, // || [-118.244, 34.052], // Default to Los Angeles if not provided
      // zoom: mapData.zoom, // || 14, // Default zoom level if not provided
    });

    setMapView(newMapView);

    return () => {
      if (mapView) {
        mapView.destroy();
      }
    };
  }, []);

  return <div ref={mapDivRef} style={{ width: '100%', height: '40vh' }} />;
};

// PropTypes for type checking
MapViewComponent.propTypes = {
  mapData: PropTypes.shape({
    basemap: PropTypes.string,
    center: PropTypes.arrayOf(PropTypes.number),
    zoom: PropTypes.number,
  }),
  webMapId: PropTypes.oneOfType([
    PropTypes.string, // ArcGIS Enterprise web map ID
    PropTypes.shape({
      // ArcGIS Online web map with sharing link
      url: PropTypes.string.isRequired,
      itemId: PropTypes.string.isRequired,
    }),
  ]),
};

// DefaultProps for default values
MapViewComponent.defaultProps = {
  mapData: {
    basemap: 'hybrid',
    center: [-118.244, 34.052],
    zoom: 14,
  },
  webMapId: null,
};

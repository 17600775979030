import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const ChevronDownIcon = ({ size, className }) => (
  <Svg viewBox="0 0 24 24" size={size} className={className}>
    <path
      d="M6 9L12 15L18 9"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </Svg>
);

ChevronDownIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
};

ChevronDownIcon.defaultProps = {
  size: 24,
  className: '',
};

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { WidgetHeader } from 'components/WidgetHeader/WidgetHeader';
import { ModalInviteAccountLevelAdmin } from 'smartComponents/ModalInviteAccountLevelAdmin';
import { ModalNewUser } from 'smartComponents/ModalNewUser';
import { ModalOrganizationNew } from 'smartComponents/ModalOrganizationNew';
import { DialogContent, Grid, Dialog, DialogTitle, Typography, DialogActions, Button } from '@mui/material';
import { ModalProjectNew } from 'smartComponents/ModalProjectNew';
import { ModalComingSoon } from 'smartComponents/ModalComingSoon/ModalComingSoon';
import { useSelector, useDispatch } from 'react-redux';
import { getOrganizations } from 'slices/organizationReducer';
import { fetchUsers } from 'slices/adminAdminsReducer';
import { getUsers } from 'slices/userReducer';
import { getProjects } from 'slices/allProjectReducer';
import { fetchTeams } from 'slices/adminTeamsReducer';
import { getAdminContents } from 'slices/contentAdminReducer';
import { ActionButton } from './ActionButton';
import { DoubleCheckIcon } from 'components/Icons';
import { ModalHeaderIcon } from 'components/ModalHeaderIcon/ModalHeaderIcon';
import AccountService from 'services/AccountService';
import { useStyles } from './styles';

export const QuickActionWidget = () => {
  const classes = useStyles();
  const [orgModalOpenState, setOrgModalOpenState] = React.useState(false);
  const [inviteAdminModalState, setInviteAdminModalState] = React.useState(false);
  const [openProjectNew, setOpenProjectNew] = React.useState(false);
  const [openAddUser, setOpenAddUser] = React.useState(false);
  const [openCominSoon, setOpenCominSoon] = React.useState(false);
  const { orgsCount, filters: orgFilters } = useSelector((state) => state.organizations);
  const { usersCount, filters: adminFilters } = useSelector((state) => state.adminAdmins);
  const { userCount, filters: usrFilters } = useSelector((state) => state.users);
  const { totalCount, filters: projFilters } = useSelector((state) => state.allProjects);
  const { teamCount, filters: teamFilters } = useSelector((state) => state.adminTeams);
  const { assetCount: uploadCount, filters: uploadFilters } = useSelector((state) => state.adminContents);
  const [creditsChoosen, setCreditsChoosen] = React.useState('');
  const [openPaymentSuccess, setOpenPaymentSuccess] = React.useState('');
  const [paymentError, setPaymentError] = React.useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  const params = window.location.href?.split('?')[1];

  useEffect(() => {
    if (params?.split('&')[0] === 'paymentSuccess=true') {
      const credits = params?.split('&')[1]?.split('=')[1];
      setCreditsChoosen(credits);

      // Call the api to update the credits
      AccountService.updateCredits(parseFloat(credits));

      setOpenPaymentSuccess(true);
    } else if (params?.split('&')[0] === 'paymentSuccess=false') {
      setPaymentError(true);
    }
  }, []);

  const organizationModalToggle = (bool) => {
    setOrgModalOpenState(bool);
  };

  const handleInviteAdminConfirm = () => {
    setInviteAdminModalState(false);
  };

  const handleInviteAdminCancel = () => {
    setInviteAdminModalState(false);
  };

  const handleAddUser = async () => {
    setOpenAddUser(false);
  };

  const handleCancelAddUser = () => {
    setOpenAddUser(false);
  };

  const loadData = () => {
    const sendFilters = {
      ...orgFilters,
      allOrgByProjectId: true,
      sort_by: Object.values(orgFilters.sort_by),
    };
    dispatch(getOrganizations(sendFilters));
  };

  const fetchData = () => {
    const sendFiltersAdmin = {
      ...adminFilters,
      sort_by: Object.values(adminFilters.sort_by),
    };
    const sendFiltersUsrs = {
      ...usrFilters,
      sort_by: Object.values(usrFilters.sort_by),
    };
    const sendFiltersProjs = {
      ...projFilters,
      sort_by: Object.values(projFilters.sort_by),
    };
    const sendFiltersTeams = {
      ...teamFilters,
      sort_by: Object.values(teamFilters.sort_by),
    };
    const sendFiltersUploads = {
      ...uploadFilters,
      sort_by: Object.values(uploadFilters.sort_by),
    };
    dispatch(fetchUsers(sendFiltersAdmin));
    dispatch(getUsers(sendFiltersUsrs));
    dispatch(getProjects(sendFiltersProjs));
    dispatch(fetchTeams(sendFiltersTeams));
    dispatch(getAdminContents(sendFiltersUploads));
  };

  useEffect(() => {
    loadData();
    fetchData();
  }, []);

  return (
    <div className={`${classes.generalContent} ${classes.fullHeight}`}>
      <WidgetHeader title="Quick Actions" subTitle="Get to the details fast." />
      <Grid container spacing={0.5}>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <ActionButton
            title="Current Orgs"
            subTitle={`${orgsCount ?? 0}`}
            buttonText="Create New"
            onClick={() => organizationModalToggle(true)}
          />
          {orgModalOpenState && (
            <ModalOrganizationNew isOpen={orgModalOpenState} handleOpen={organizationModalToggle} />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <ActionButton
            title="Account Admins"
            subTitle={`${usersCount ?? 0}`}
            buttonText="Add Admin"
            onClick={() => setInviteAdminModalState(true)}
          />
          {inviteAdminModalState && (
            <ModalInviteAccountLevelAdmin
              isOpen={inviteAdminModalState}
              onConfirm={handleInviteAdminConfirm}
              onCancel={handleInviteAdminCancel}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <ActionButton
            title="System Users"
            subTitle={`${userCount ?? 0}`}
            buttonText="Add User"
            onClick={() => setOpenAddUser(true)}
          />
          {openAddUser && (
            <ModalNewUser isOpen={openAddUser} onConfirm={handleAddUser} onCancel={handleCancelAddUser} />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <ActionButton
            title="Projects"
            subTitle={`${totalCount ?? 0}`}
            buttonText="Create New"
            onClick={() => setOpenProjectNew(true)}
          />
          {openProjectNew && (
            <ModalProjectNew
              isModalOpen={openProjectNew}
              onConfirm={() => setOpenProjectNew(false)}
              onCancel={() => setOpenProjectNew(false)}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
          <ActionButton
            title="Teams"
            subTitle={`${teamCount ?? 0}`}
            buttonText="Visit page"
            onClick={() => history.push('/admin/teams')}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
          <ActionButton
            title="Uploads"
            subTitle={`${uploadCount ?? 0}`}
            buttonText="Visit page"
            onClick={() => history.push('/admin/content')}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
          <ActionButton
            title="Payment History"
            subTitle=""
            buttonText="View"
            onClick={() => history.push('/admin/paymentHistory')}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
          <ActionButton
            title="Credit Usage"
            subTitle=""
            buttonText="View"
            onClick={() => history.push('/admin/creditUsage')}
          />
        </Grid>
      </Grid>
      {openCominSoon && <ModalComingSoon onCancel={() => setOpenCominSoon(false)} />}
      {openPaymentSuccess && (
        <Dialog open={openPaymentSuccess} onClose={() => setOpenPaymentSuccess(false)} maxWidth="sm">
          <DialogTitle>
            <ModalHeaderIcon icon={DoubleCheckIcon} text="Success" />
            <Typography>Successfully Added Credits.</Typography>
          </DialogTitle>
          <DialogContent>
            <Typography>
              Congratulations. You have successfully loaded {creditsChoosen} credits to your account.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setOpenPaymentSuccess(false)}
                  size="large"
                  fullWidth
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      )}
      {paymentError && (
        <Dialog open={paymentError} onClose={() => setPaymentError(false)} maxWidth="sm">
          <DialogTitle>
            <Typography>Transaction has been cancelled.</Typography>
          </DialogTitle>
          <DialogContent>
            <Typography>
              The payment has been cancelled or had an error, please check your details in case you want to proceed and
              try again.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button color="primary" variant="contained" onClick={() => setPaymentError(false)} size="small" fullWidth>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Form } from 'lib/form';
import { Switch } from 'components/Switch/Switch';
import { SwitchCase } from 'components/SwitchCase/SwitchCase';
import AuthService from 'services/AuthService';
import { Button, FormControl, InputLabel, TextField, Typography } from '@mui/material';
import { LayoutAuth } from '../../../components/LayoutAuth';

export const PasswordResetRequest = () => {
  const [panel, setPanel] = useState('request');
  const [loading, setLoading] = useState(false);

  const sendRequest = async (email) => {
    try {
      setLoading(true);
      await AuthService.passwordResetRequest({ email });
      setPanel('success');
    } catch (err) {
      const result = Form.parseApiValidationError(err);
      if (result) {
        // eslint-disable-next-line no-use-before-define
        formik.setErrors(result);
      } else {
        setPanel('error');
      }
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(/^\S*$/, 'Email can not contain space')
        .email('Invalid email address')
        .required('Email Required'),
    }),
    onSubmit: (values) => {
      sendRequest(values.email);
    },
  });

  const handleButtonClick = () => {
    const { email } = formik.values;
    const subject = 'Reset Password';

    const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(subject)}}`;

    window.open(mailtoUrl);
  };

  return (
    <Switch value={panel}>
      <SwitchCase name="request">
        <LayoutAuth title="Forgot password?">
          <form onSubmit={formik.handleSubmit} noValidate>
            <InputLabel disabled={loading}>Email</InputLabel>
            <FormControl fullWidth>
              <TextField
                placeholder="Enter your email"
                type="email"
                name="email"
                id="email"
                value={formik.values.email}
                error={!!(formik.touched.email && formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email ? formik.errors.email : null}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={loading}
              />
            </FormControl>
            <Button type="submit" color="secondary" variant="contained" disabled={loading} fullWidth>
              Reset Password
            </Button>
          </form>
        </LayoutAuth>
      </SwitchCase>
      <SwitchCase name="success">
        <LayoutAuth title="Check your email" subTitle={`We sent a password reset link to ${formik.values.email}`}>
          <Button onClick={handleButtonClick} color="secondary" variant="contained" disabled={loading} fullWidth>
            Open email app
          </Button>
          <Typography className="shortText">
            Didn’t receive the email?
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-is-valid */}
            <a onClick={() => setPanel('request')}> Click to resend</a>
          </Typography>
        </LayoutAuth>
      </SwitchCase>
      <SwitchCase name="error">
        <LayoutAuth title="Forgot password?" subTitle="Unable to process the request at this time.">
          <Typography className="shortText">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-is-valid */}
            <a onClick={() => setPanel('request')}> Click to resend</a>
          </Typography>
        </LayoutAuth>
      </SwitchCase>
    </Switch>
  );
};

import { createSelector, createSlice } from '@reduxjs/toolkit';

/**
 * @typedef {
 *   sort_by: Record<string, string>;
 *   search: string,
 *   dateRange: [string, string],
 *   map_extent: Array<{ lag: number, long: number}>
 * } FilterState
 */
const initialState = {
  sort_by: {},
  search: '',
  date_range: [],
  map_extent: [],
  organizationId: [],
  projectId: [],
  accountId: [],
  type: null,
};

export const filterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setSearch(state, action) {
      state.search = action.payload;
    },
    setSortBy(state, { payload }) {
      const { label, value } = payload;

      if (value === '' || value === null) {
        delete state.sort_by[label];
      } else {
        state.sort_by[label] = value;
      }
    },
    setMapExtent(state, { payload }) {
      // copy to prevent accidental mutation
      state.map_extent = payload.map(({ lat, long }) => ({ lat, long }));
    },
    setDateRange(state, { payload }) {
      state.date_range = payload;
    },
    setFilters(state, { payload }) {
      state.organizationId = payload.organizationId;
      state.projectId = payload.projectId;
      state.accountId = payload.accountId;
      state.type = payload.type;
      state.date_range = payload.date_range;
      state.map_extent = payload.map_extent;
    },
  },
});

export const { setSearch, setSortBy, setDateRange, setMapExtent, setFilters } = filterSlice.actions;

export const dateRangeSelector = createSelector(
  (state) => state.search.filters,
  (state) => {
    const dates = state.date_range;

    if (!dates) {
      return dates;
    }

    return dates.map((date) => (date ? new Date(date) : null));
  }
);

export default filterSlice.reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ExternalService from 'services/ExternalService';
import ProjectService from 'services/ProjectService';
import UserService from 'services/UserService';

export const getExternalTeams = createAsyncThunk('external-teams/fetchTeams', async (filterOpts) => {
  const filtersOpts = {
    ...filterOpts,
    include: ['users'],
  };
  delete filtersOpts.activated;
  const response = await ExternalService.getExternalList(filtersOpts, 'teams');
  return response.data;
});

export const preloadExternalFilterDatasets = createAsyncThunk(
  'external-teams/preloadExternalFilterDatasets',
  async () => {
    try {
      const [accounts, organizations, users, projects] = await Promise.all([
        await ExternalService.getTeamAccounts(),
        await ExternalService.getTeamOrganizations(),
        await UserService.getUsers({ page_size: 1000 }),
        await ProjectService.getProjects({ page_size: 1000 }),
      ]);
      return {
        accounts: accounts.data.data,
        organizations: organizations.data.data,
        users: users.data.data,
        projects: projects.data.data,
      };
    } catch (err) {
      throw new Error('error loading filter data');
    }
  },
  {
    condition: (_, { getState }) => getState().externalTeams.panels.search.loaded === false,
  }
);

const orderByName = (array) =>
  array.sort((a, b) => {
    const nameA = a.label.toUpperCase(); // ignore upper and lowercase
    const nameB = b.label.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

const initialState = {
  teams: [],
  teamCount: 0,
  loading: true,
  error: null,
  panels: {
    teamId: null,
    loading: true,
    detail: {},
    users: [],
    search: {
      loaded: false,
      users: [],
      projects: [],
      accounts: [],
      organizations: [],
    },
  },
  filters: {
    page: 1,
    page_size: 10,
    sort_by: {},
    search: '',
  },
};

const externalTeamReducer = createSlice({
  name: 'external-teams',
  initialState,
  reducers: {
    resetExternalTeams: () => initialState,
    setExternalPageFilter(state, action) {
      state.filters.page = action.payload;
    },
    setExternalSearch(state, action) {
      state.filters.search = action.payload;
    },
    setExternalSortBy(state, { payload }) {
      const { label, value } = payload;
      if (value === '' || value === null) {
        delete state.filters.sort_by[label];
      } else {
        state.filters.sort_by[label] = value;
      }
    },
    setExternalFilterValue(state, { payload }) {
      const { param, value } = payload;
      if (value === '' || value === null) {
        delete state.filters[param];
        return;
      }
      state.filters[param] = payload.value;
    },
    setExternalFilters(state, action) {
      const filter = { ...state.filters, ...action.payload, page: 1 };
      if (!filter?.userRole) delete filter?.userRole;
      state.filters = filter;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getExternalTeams.fulfilled, (state, { payload }) => {
      state.teams = payload.data;
      state.teamCount = payload.meta.totalCount;
      state.loading = false;
    });

    builder.addCase(getExternalTeams.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getExternalTeams.rejected, (state) => {
      state.loading = false;
      state.teams = [];
      state.error = 'unable to load Teams';
    });

    builder.addCase(preloadExternalFilterDatasets.pending, (state) => {
      state.panels.search.loaded = false;
    });
    builder.addCase(preloadExternalFilterDatasets.rejected, (state) => {
      state.panels.search.organizations = [];
      state.panels.search.accounts = [];
      state.panels.search.projects = [];
      state.panels.search.users = [];
      state.panels.search.loaded = false;
    });
    builder.addCase(preloadExternalFilterDatasets.fulfilled, (state, { payload }) => {
      const { accounts, organizations, users, projects } = payload;
      state.panels.search.loaded = true;
      let newAccounts = accounts.map((account) => ({
        label: account.name,
        value: account.id,
      }));
      let newOrganizations = organizations.map((org) => ({
        label: org.name,
        value: org.id,
      }));
      let newProjects = projects.map((proj) => ({
        label: proj.name,
        value: proj.id,
      }));
      let newUsers = users.map((user) => ({
        label: `${user.firstName} ${user.lastName}`,
        value: user.id,
      }));
      newAccounts = orderByName(newAccounts);
      newOrganizations = orderByName(newOrganizations);
      newProjects = orderByName(newProjects);
      newUsers = orderByName(newUsers);
      state.panels.search.accounts = newAccounts;
      state.panels.search.organizations = newOrganizations;
      state.panels.search.projects = newProjects;
      state.panels.search.users = newUsers;
    });
  },
});

export const {
  setExternalPageFilter,
  setExternalSortBy,
  setExternalSearch,
  resetExternalTeams,
  setExternalFilterValue,
  setExternalFilters,
  // eslint-disable-next-line prettier/prettier
} = externalTeamReducer.actions;

export default externalTeamReducer.reducer;

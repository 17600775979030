import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const TrackZoomIcon = ({ size, className }) => (
  <Svg viewBox="0 0 24 24" size={size} className={className}>
    <g>
      <g>
        <g>
          <g>
            <path
              d="M5,16c-0.1,0-0.3-0.1-0.4-0.2c-0.4-0.4-3.6-4-3.6-6c0-2.2,1.8-4.1,4-4.1c2.2,0,4,1.8,4,4.1c0,2-3.3,5.6-3.6,6      C5.3,15.9,5.1,16,5,16z M5,6.7c-1.6,0-3,1.4-3,3.1c0,1.2,1.9,3.7,3,4.9c1.1-1.2,3-3.7,3-4.9C8,8.1,6.7,6.7,5,6.7z"
              fill="currentColor"
            />
          </g>
          <g>
            <path
              d="M5,11.3c-1,0-1.8-0.8-1.8-1.7C3.2,8.7,4,7.9,5,7.9s1.8,0.8,1.8,1.7C6.8,10.5,6,11.3,5,11.3z M5,8.9      c-0.4,0-0.8,0.3-0.8,0.7c0,0.4,0.4,0.7,0.8,0.7S5.8,10,5.8,9.6C5.8,9.2,5.4,8.9,5,8.9z"
              fill="currentColor"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M19,16c-0.1,0-0.3-0.1-0.4-0.2c-0.4-0.4-3.6-4-3.6-6c0-2.2,1.8-4,4-4c2.2,0,4,1.8,4,4c0,2-3.3,5.6-3.6,6      C19.3,15.9,19.1,16,19,16z M19,6.8c-1.6,0-3,1.4-3,3c0,1.2,1.9,3.7,3,4.9c1.1-1.2,3-3.7,3-4.9C22,8.1,20.7,6.8,19,6.8z"
              fill="currentColor"
            />
          </g>
          <g>
            <path
              d="M19,11.3c-1,0-1.8-0.8-1.8-1.7c0-0.9,0.8-1.7,1.8-1.7s1.8,0.8,1.8,1.7C20.8,10.5,20,11.3,19,11.3z M19,8.9      c-0.4,0-0.8,0.3-0.8,0.7c0,0.4,0.4,0.7,0.8,0.7s0.8-0.3,0.8-0.7C19.8,9.2,19.4,8.9,19,8.9z"
              fill="currentColor"
            />
          </g>
        </g>
        <g>
          <path
            d="M19,18.3H5c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h14c0.3,0,0.5,0.2,0.5,0.5S19.3,18.3,19,18.3z"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
  </Svg>
);

TrackZoomIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
};

TrackZoomIcon.defaultProps = {
  size: 24,
  className: '',
};

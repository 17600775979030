import { webMercatorToGeographic } from '@arcgis/core/geometry/support/webMercatorUtils';
import Point from '@arcgis/core/geometry/Point';
import Graphic from '@arcgis/core/Graphic';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import ArcGISMap from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import BasemapGallery from '@arcgis/core/widgets/BasemapGallery';
import Expand from '@arcgis/core/widgets/Expand';
import Search from '@arcgis/core/widgets/Search';
import { Button } from 'components/Button';
import { createMapGeoFeatureLayer, fetchMapGeoData } from 'components/MapFeatureLayer/MapFeatureLayer';
import localforage from 'localforage';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { StyledMap } from './styles';
import AssetService from 'services/AssetService';
import markerImage from './marker.png';
import markerImageNoAngle from '../MapFeatureLayer/markerNoAngle_26x26.png';
import './styles.css';

const selectedMarker = {
  type: 'picture-marker',
  url: markerImageNoAngle,
  width: '25px',
  height: '25px',
};

const markerSymbol = {
  type: 'picture-marker',
  url: markerImage,
  width: '20px',
  height: '20px',
};

// eslint-disable-next-line no-unused-vars
const MapExtent = ({ onExtentChange, onClickGetExtent, defaultZoom }) => {
  const mapDiv = useRef(null);
  const [viewRef, setViewRef] = useState(null);
  const [extentFiltered, setExtentFiltered] = useState(false);
  const mapGeoLayer = useMemo(() => new GraphicsLayer({ id: 'mainLine' }), []);
  const observationLayer = useMemo(() => new GraphicsLayer({ id: 'assetsPoints' }), []);
  const [points, setPoints] = useState([]);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const graphicsLayer = useMemo(
    () =>
      new GraphicsLayer({
        graphics: [],
      }),
    []
  );
  const clearExtent = () => {
    setExtentFiltered(false);
    onClickGetExtent([]);
  };

  const getAssets = async (filters) => {
    const filtersData = {
      ...filters,
      strict: true,
      page: 1,
      page_size: 100,
      include: [],
    };
    const result = await AssetService.getAssets(filtersData);
    setPoints([...result.data.data]);
  };

  const generatePoints = () => {
    // This function maps through the spatial points on the backend, and creates a collection of orange dot graphics.
    graphicsLayer.removeAll();
    const pointGraphics = points.map((point, index) => {
      const mapPoint = {
        type: 'point',
        longitude: point.longitude,
        latitude: point.latitude,
      };

      let symbol = markerSymbol;
      if (selectedPoint === point.id) {
        symbol = selectedMarker;
      } else if (!selectedPoint && index === 0) {
        symbol = selectedMarker;
      }

      const graphic = new Graphic({
        geometry: mapPoint,
        symbol,
        attributes: index,
        popupTemplate: {
          title: point.name, // Data attribute names
          content: `<div>${point.description || 'No description'}</div>`,
          actions: [
            {
              id: 'video',
              title: 'View Asset',
            },
            {
              id: 'line',
              title: 'View Telemetry',
            },
          ],
          id: point.id,
        },
      });

      return graphic;
    });

    graphicsLayer.addMany(pointGraphics);
  };

  const initialExtent = (mapViewInstance) => {
    const defaultLatitude = defaultZoom ? defaultZoom.latitude : 37.09024;
    const defaultLongitude = defaultZoom ? defaultZoom.longitude : -95.712891;

    // US geographic Center
    const point = new Point({
      latitude: defaultLatitude,
      longitude: defaultLongitude,
    });
    const target = { target: point, zoom: defaultZoom ? 12 : 4 };

    mapViewInstance.when(
      mapViewInstance
        .goTo(target, { animate: true, easing: 'ease', duration: 200 })
        .then(() => {
          if (defaultZoom) {
            const { xmin, ymin, xmax, ymax } = webMercatorToGeographic(mapViewInstance.extent);
            getAssets({ geo: [`${ymax},${xmin}`, `${ymax},${xmax}`, `${ymin},${xmax}`, `${ymin},${xmin}`] });
            onExtentChange([`${ymax},${xmin}`, `${ymax},${xmax}`, `${ymin},${xmax}`, `${ymin},${xmin}`]);
          }
        })
        .catch((error) => {
          if (error.name !== 'AbortError') {
            // eslint-disable-next-line
            console.error(error);
          }
        })
    );
  };

  // Assigns the spatial points on load to the map extent.
  useEffect(() => {
    generatePoints();
  }, [points, selectedPoint]); // lat, long,

  // eslint-disable-next-line consistent-return
  const createMap = async () => {
    // dispatch(setSelectedSequence(0));
    if (mapDiv.current) {
      graphicsLayer.removeAll();
      /**
       * Initialize application
       */
      const map = new ArcGISMap({
        basemap: 'hybrid',
      });

      const view = new MapView({
        map,
        container: mapDiv.current,
      });

      // create the map selector
      const basemapGallery = new BasemapGallery({
        view,
        container: document.createElement('div'),
      });

      const bgExpand = new Expand({
        view,
        content: basemapGallery.container,
        expandIconClass: 'esri-icon-basemap',
      });

      // ------------------------ add the feature layer to the map
      const mapGeoFeatureLayer = createMapGeoFeatureLayer('point', { id: 'inital-points-1' });
      const mapGeoFeatureLayerLines = createMapGeoFeatureLayer('polyline', { id: 'inital-lines-1' });
      map.add(mapGeoFeatureLayerLines);
      map.add(mapGeoLayer);
      map.add(mapGeoFeatureLayer, 0);

      // create the layer for the observation points
      map.add(observationLayer);

      const searchWidget = new Search({
        view,
      });

      view.ui.add(searchWidget, {
        position: 'top-right',
        index: 2,
      });

      // ----------------------------------------------- zoom in point after searching
      searchWidget.goToOverride = (view2, goToParams) => {
        goToParams.options.duration = 300;
        const target = {
          ...goToParams.target,
          ...(goToParams.target.target?.attributes?.ObjectID &&
            goToParams.target.target?.layer?.uid === mapGeoFeatureLayer.uid && { zoom: 19 }),
        };
        return view.goTo(target, goToParams.options);
      };

      view.ui.add([bgExpand], 'top-left');

      const featureLayerData = {
        view,
        mapGeoFeatureLayer,
        mapGeoFeatureLayerLines,
        mapGeoLayer,
        searchWidget,
        // geometryOrgId: projectData?.orgId,
        includeAccountGeometry: false,
      };

      map.add(graphicsLayer);

      const convertExtentToCoords = (extent) => {
        const { xmin, ymin, xmax, ymax } = webMercatorToGeographic(extent);
        const newExtentCoords = [
          {
            lat: ymax,
            long: xmin,
          },
          {
            lat: ymax,
            long: xmax,
          },
          {
            lat: ymin,
            long: xmax,
          },
          {
            lat: ymin,
            long: xmin,
          },
        ];
        getAssets({ geo: [`${ymax},${xmin}`, `${ymax},${xmax}`, `${ymin},${xmax}`, `${ymin},${xmin}`] });
        localforage.setItem('coords', JSON.stringify({ coords: newExtentCoords }));
        onExtentChange([`${ymax},${xmin}`, `${ymax},${xmax}`, `${ymin},${xmax}`, `${ymin},${xmin}`]);
      };

      view.on('drag', (event) => {
        if (event.action === 'end') {
          convertExtentToCoords(view.extent);
          // -------------------- call map geometry data
          fetchMapGeoData(featureLayerData);
        }
      });

      view.on('mouse-wheel', () => {
        convertExtentToCoords(view.extent);
        // -------------------- call map geometry data
        fetchMapGeoData(featureLayerData);
      });

      view.on('click', (event) => {
        view
          .hitTest(event, [view.extent])
          .then((response) => {
            const second = response.results[0].graphic.attributes;
            if (second !== undefined && typeof second !== 'object') {
              if (response.results[0].graphic.popupTemplate.id !== selectedPoint)
                setSelectedPoint(response.results[0].graphic.popupTemplate.id);
            }
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error);
          });
        // eslint-disable-next-line no-underscore-dangle
      });

      initialExtent(view);
      setViewRef(view);

      return () => {
        graphicsLayer.removeAll();
        observationLayer.removeAll();
        map.remove(graphicsLayer);
        map.remove(mapGeoFeatureLayerLines);
        map.remove(mapGeoLayer);
        map.remove(mapGeoFeatureLayer);
        map.remove(observationLayer);
      };
    }
  };

  // on page load
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    createMap();
  }, []);

  useEffect(() => {
    if (defaultZoom) {
      initialExtent(viewRef);
    }
  }, [defaultZoom]);

  return (
    <>
      {extentFiltered === true && (
        <Button
          onClick={() => {
            clearExtent();
            setExtentFiltered(false);
          }}
          kind="secondary"
          style={{
            position: 'absolute',
            zIndex: '100',
            bottom: '20px',
            right: '145px',
            border: 'none',
          }}
        >
          Clear
        </Button>
      )}
      <StyledMap className="mapDiv" id="mapContainer" ref={mapDiv} />
    </>
  );
};

MapExtent.propTypes = {
  onExtentChange: PropTypes.func,
  onClickGetExtent: PropTypes.func,
  defaultZoom: PropTypes.object,
};

MapExtent.defaultProps = {
  onExtentChange: () => {},
  onClickGetExtent: () => {},
  defaultZoom: null,
};

export default MapExtent;

import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from 'slices/userReducer';
import ProjectService from 'services/ProjectService';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import { DialogMessage } from 'components/DialogMessage';
import {
  roles,
  UserProjectRoleOptions,
  validateOptions,
} from 'components/UserProjectRoleOptions/UserProjectRoleOptions';
import { updateProjectPermissions } from 'slices/authReducer';
import { Permissions } from 'lib/permissions';
import { Roles, StringRoles } from 'lib/roles';
import { getProjects } from 'slices/adminProjectReducer';
import { fetchTeams } from 'slices/adminTeamsReducer';
import { getOrganizations } from 'slices/organizationReducer';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { ModalHeaderIcon } from 'components/ModalHeaderIcon/ModalHeaderIcon';
import { InfoIcon, LayersIcon } from 'components/Icons';

export const typeInviteData = {
  USER: 'user',
  PROJET: 'project',
  TEAM: 'team',
  ORG: 'org',
};

const text = {
  user: 'User',
  project: 'Project',
  team: 'Team',
  org: 'Organization',
};

export const DialogProjectInvite = ({
  isOpen,
  handleClose,
  projectId,
  bySelf,
  type,
  objectId,
  textDescription,
  itemName,
}) => {
  const dispatch = useDispatch();
  const [checkBoxValidation, setCheckBoxValidation] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const auth = useSelector((state) => state.auth);

  const optionsData = useSelector((state) =>
    // eslint-disable-next-line no-nested-ternary
    type === typeInviteData.USER
      ? state.users.data
      : // eslint-disable-next-line no-nested-ternary
      type === typeInviteData.PROJET
      ? state.adminProjects.data
      : type === typeInviteData.ORG
      ? state.organizations.data
      : state.adminTeams.teams
  );
  const { filters } = useSelector((state) =>
    // eslint-disable-next-line no-nested-ternary
    type === typeInviteData.USER
      ? state.users
      : // eslint-disable-next-line no-nested-ternary
      type === typeInviteData.PROJET
      ? state.adminProjects
      : type === typeInviteData.ORG
      ? state.organizations
      : state.adminTeams
  );

  const validationSchema = Yup.object().shape({
    id: Yup.string().required(`${text[type]} is required`),
    role: Yup.number().required('Role is required'),
  });

  useEffect(() => {
    switch (type) {
      case typeInviteData.USER:
        if (!bySelf) dispatch(getUsers({ ...filters, include: ['orgs'], page_size: 500 }));
        break;
      case typeInviteData.PROJET:
        // eslint-disable-next-line no-case-declarations
        const sendFilters = {
          ...filters,
          sort_by: Object.values(filters.sort_by),
          page_size: 500,
        };
        dispatch(getProjects(sendFilters));
        break;
      case typeInviteData.TEAM:
        dispatch(fetchTeams({ page_size: 500 }));
        break;
      case typeInviteData.ORG:
        dispatch(getOrganizations({ page_size: 500 }));
        break;
      default:
        break;
    }
  }, []);

  const rootOptions = !bySelf
    ? optionsData.map((proj) => ({
        label: type === typeInviteData.USER ? `${proj.firstName} ${proj.lastName}` : proj.name,
        value: proj.id,
      }))
    : [{ label: auth.fname, value: auth.userId }];

  const submitFormData = async (values) => {
    if (validateOptions(values)) {
      setCheckBoxValidation(true);
      return;
    }
    setIsLoading(true);
    try {
      const userRoles = roles(values);
      await ProjectService.updateUserOrTeamRoles(
        type === typeInviteData.PROJET ? values.id : projectId,
        type === typeInviteData.PROJET ? objectId : values.id,
        userRoles,
        bySelf
      );
      setIsLoading(false);
      // update current permissions if user added to a project themself
      if (bySelf && type === typeInviteData.USER) {
        const { permissions } = auth;
        const projects = { ...permissions.projects };
        projects[projectId] = {
          role:
            // eslint-disable-next-line no-nested-ternary
            userRoles[0] === Roles.PROJ_CNTRB
              ? StringRoles.PROJ_CNTRB
              : userRoles[0] === Roles.PROJ_COLLAB
              ? StringRoles.PROJ_COLLAB
              : StringRoles.PROJ_VIEW,
          [Permissions.PROJ_VIEW]: true,
          [Permissions.PROJ_VIEW_CONTENT]: true,
          ...(userRoles[0] !== Roles.PROJ_VIEW && { [Permissions.PROJ_OBSERVATION]: true }),
          ...(userRoles[0] === Roles.PROJ_CNTRB && { [Permissions.PROJ_UPLOAD_CONTENT]: true }),
        };
        dispatch(updateProjectPermissions(projects));
      }
      handleClose(true);
    } catch (error) {
      setIsLoading(false);
      setOpenDialog('An error occurred while trying to edit the user.');
    }
  };

  const getProjectRole = () => {
    switch (auth?.permissions?.projects[projectId]?.role) {
      case StringRoles.PROJ_CNTRB:
        return Roles.PROJ_CNTRB;
      case StringRoles.PROJ_COLLAB:
        return Roles.PROJ_COLLAB;
      case StringRoles.PROJ_VIEW:
        return Roles.PROJ_VIEW;
      default:
        return '';
    }
  };

  const formik = useFormik({
    initialValues: {
      id: bySelf && type === typeInviteData.USER ? auth.userId : '',
      role: bySelf && type === typeInviteData.USER ? getProjectRole() : 0,
    },
    validationSchema,
    onSubmit(values) {
      submitFormData(values);
    },
  });

  return (
    <>
      <Dialog open={isOpen} maxWidth="xs" onClose={() => handleClose(false)}>
        <DialogContent>
          <ModalHeaderIcon
            icon={LayersIcon}
            text={`Add ${!bySelf ? text[type] : 'You'} to ${textDescription || 'Project'}`}
          />
          <Typography mb={1} mt={0.5}>
            {`Select the ${type} you would like to add this ${itemName} to and set any special permissions`}
          </Typography>
          <InputLabel>{text[type]} *</InputLabel>
          {!bySelf && (
            <FormControl error={formik.touched.id && formik.errors.id} fullWidth>
              <Autocomplete
                options={rootOptions}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={`Select ${text[type]}`}
                    onBlur={formik.handleBlur}
                    required
                    error={!!(formik.touched.orgId && formik.errors.orgId)}
                  />
                )}
                onChange={(e, value) => {
                  formik.handleChange('id')(value?.value ? value?.value : '');
                }}
                onBlur={formik.handleBlur}
              />
              <FormHelperText>{formik.touched.id && formik.errors.id ? formik.errors.id : null}</FormHelperText>
            </FormControl>
          )}
          <Box sx={{ padding: '5px 24px 0 24px' }}>
            <UserProjectRoleOptions
              formik={formik}
              checkBoxValidation={checkBoxValidation}
              handleChange={(label, value) => formik.handleChange(label)(value)}
              bySelf={bySelf}
            />
          </Box>
          <LoadingOverlay loading={isLoading} />
        </DialogContent>
        <DialogActions>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button color="primary" variant="contained" onClick={() => handleClose(false)} fullWidth>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button color="secondary" variant="contained" onClick={formik.handleSubmit} fullWidth>
                Confirm
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <DialogMessage
        title={openDialog}
        isOpen={openDialog}
        icon={InfoIcon}
        confirmText="Ok"
        onConfirm={() => setOpenDialog(false)}
      />
    </>
  );
};
DialogProjectInvite.propTypes = {
  /**
   * is a boolean that checks if the modal is open or not
   */
  isOpen: PropTypes.bool,
  /**
   * this is what action is needed to be handled when clicking confirm
   */
  handleClose: PropTypes.func,
  projectId: PropTypes.string,
  //  with this variable validate if the user no has permissions to add user but they can add themself in the project
  bySelf: PropTypes.bool,
  // get the specific data to link
  type: PropTypes.string,
  // id of user or team
  objectId: PropTypes.string,
  // specific text in the desscriptio
  textDescription: PropTypes.string,
  itemName: PropTypes.string,
};

DialogProjectInvite.defaultProps = {
  isOpen: false,
  handleClose: null,
  projectId: '',
  bySelf: false,
  type: '',
  objectId: '',
  textDescription: '',
  itemName: '',
};

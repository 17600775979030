import PropTypes from 'prop-types';
import React from 'react';
import { useStyles } from './styles';

export const Avatar = ({ image, size, alt, testId, initials, isButton, ...rest }) => {
  const classes = useStyles({ size, image, isButton, ...rest });

  return (
    <div data-testid={testId} className={classes.avatar} style={{ marginRight: 10 }} {...rest}>
      {image ? <img src={image} alt={alt} className={classes.image} /> : initials}
    </div>
  );
};

Avatar.propTypes = {
  image: PropTypes.string,
  size: PropTypes.any,
  alt: PropTypes.string,
  testId: PropTypes.string,
  initials: PropTypes.string.isRequired,
  isButton: PropTypes.bool,
};

Avatar.defaultProps = {
  image: null,
  size: 150,
  alt: 'avatar',
  testId: null,
  isButton: false,
};

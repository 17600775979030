import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, IconButton, TextField, Typography } from '@mui/material';
import InspectionService from 'services/InspectionService';
import { setProjectAssetInspections } from 'slices/projectAssetActiveInspectionReducer';
import { setProjectActiveInspections } from 'slices/projectActiveInspectionsReducer';
import { ChevronDownIcon, InfoIcon } from 'components/Icons';
import { DialogMessage } from 'components/DialogMessage';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import { useStyles } from './styles';
import { inspectionType } from '../helpers';

export const Assignment = ({ projAssetInspection, type }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState('');
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { users } = useSelector((state) => state.allProjects);
  const reducer = type === inspectionType.PROJECT ? 'projectActiveInspections' : 'projectAssetActiveInspections';
  const { data } = useSelector((state) => state[reducer]);
  const { userId } = useSelector((state) => state.auth);

  const assignUser = async (user) => {
    setIsLoading(true);
    let assignMethod = InspectionService.assignUserProjectAssetInspection;
    if (projAssetInspection.assignment?.id) {
      assignMethod = InspectionService.reAssignUserProjectAssetInspection;
    }

    assignMethod(projAssetInspection?.id, user.id)
      .then(() => {
        const projectAssetInspections = [...data];
        const index = projectAssetInspections.findIndex((item) => item.id === projAssetInspection?.id);
        if (index !== -1) {
          projectAssetInspections[index] = {
            ...projectAssetInspections[index],
            assignment: { id: new Date(), assignee: user },
          };
        }
        setIsLoading(false);
        dispatch(
          type === inspectionType.PROJECTASSET
            ? setProjectAssetInspections(projectAssetInspections)
            : setProjectActiveInspections(projectAssetInspections)
        );
        setOpenDialog('User was assigned to the inspection.');
      })
      .catch(() => {
        setIsLoading(false);
        setOpenDialog('An error occurred while trying to save the user.');
      });
  };

  const compareMethod = (a, b) => {
    if (`${a?.firstName} ${a?.lastName}` > `${b?.firstName} ${b?.lastName}`) return 1;
    if (`${a?.firstName} ${a?.lastName}` < `${b?.firstName} ${b?.lastName}`) return -1;
    return 0;
  };

  return (
    <>
      <div className={classes.selectAssign}>
        <div className="defult-assign">
          <Typography className={projAssetInspection.assignment?.id ? '' : 'danger'}>
            {projAssetInspection.assignment?.id
              ? `${projAssetInspection.assignment?.assignee?.firstName} ${projAssetInspection.assignment?.assignee?.lastName}`
              : 'Unassigned'}
          </Typography>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              if (userId !== projAssetInspection?.uploader?.id) {
                setOpenDialog('You do not have the appropriate permissions for this action');
                return;
              }
              setOpen(!open);
            }}
          >
            <ChevronDownIcon size={20} />
          </IconButton>
        </div>
        {open && (
          <Autocomplete
            open
            className={classes.usersSelect}
            classes={{ listbox: classes.listboxUsers }}
            required
            sx={{ width: 200 }}
            name="users"
            size="small"
            disableClearable
            options={users.toSorted(compareMethod)}
            id="users-select-assignment"
            onChange={(e, value) => {
              setOpen(false);
              assignUser(value);
            }}
            getOptionLabel={(option) => `${option?.firstName} ${option?.lastName}`}
            renderInput={(params) => <TextField key={params.id} {...params} />}
          />
        )}
      </div>
      <DialogMessage
        title={openDialog}
        isOpen={!!openDialog}
        confirmText="Ok"
        icon={InfoIcon}
        onConfirm={() => {
          setOpenDialog(false);
        }}
      />
      <LoadingOverlay loading={isLoading} />
    </>
  );
};

import React, { useState, useEffect } from 'react';
import { SecondaryHeader } from 'components/SecondaryHeader/SecondaryHeader';
import { useDispatch, useSelector } from 'react-redux';
import { setSearch } from 'slices/search/filterReducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { TextField } from '@mui/material';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import FiltersPanel from './FiltersPanel';
import ResultsPanel from './ResultsPanel';
import AssetPanel from './AssetPanel';
import { getProjectAsset } from 'slices/profileProjectAssetReducer';
import { cleanContent, setProximity } from 'slices/search/contentReducer';
import { useLocation } from 'react-router-dom';

export const SearchPage = () => {
  const searchFilter = useSelector((state) => state.search.filters.search);
  const { projectAsset } = useSelector((state) => state.profileProjectAssets);
  const [selectedContent, setSelectedContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showAssetPanel, setShowAssetPanel] = useState(false);
  const { search } = useLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(cleanContent());
  }, []);

  useEffect(() => {
    if (search) {
      const params = new URLSearchParams(search);
      dispatch(
        setProximity({
          proximity: {
            latitude: Number(params.get('latitude')),
            longitude: Number(params.get('longitude')),
          },
        })
      );
    }
  }, [search]);

  useEffect(() => {
    if (selectedContent && projectAsset && projectAsset.id === selectedContent.id) {
      setIsLoading(false);
      setShowAssetPanel(true);
    }
  }, [selectedContent, projectAsset]);

  const handleOnChange = (e) => {
    dispatch(setSearch(e.target.value));
  };

  const onSelect = (data) => {
    if (selectedContent && selectedContent.id === data.id) {
      return;
    }
    setSelectedContent(data);
    dispatch(getProjectAsset({ projId: data.projId, assetId: data.id, lat: data.latitude, lng: data.longitude }));
    setIsLoading(true);
  };

  const onClose = () => {
    setSelectedContent(null);
    setShowAssetPanel(false);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <SecondaryHeader title="Search">
        <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
          <TextField
            variant="outlined"
            placeholder="Search"
            value={searchFilter}
            onChange={handleOnChange}
            InputProps={{
              startAdornment: <FontAwesomeIcon style={{ marginRight: '8px' }} icon={faMagnifyingGlass} size="1x" />,
            }}
          />
        </div>
      </SecondaryHeader>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
        {isLoading && <LoadingOverlay loading={isLoading} />}
        {showAssetPanel && <AssetPanel selectedContent={projectAsset} onClose={onClose} />}
        <FiltersPanel hidden={showAssetPanel} />
        <ResultsPanel onSelect={onSelect} />
      </div>
    </div>
  );
};

import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const InfoCircleIcon = ({ size, className }) => (
  <Svg viewBox="0 0 24 24" size={size} className={className}>
    <path
      d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </Svg>
);

InfoCircleIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
};

InfoCircleIcon.defaultProps = {
  size: 24,
  className: '',
};

import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const ContentIcon = ({ size }) => (
  <Svg viewBox="0 0 24 24" size={size}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.412.586A1.927 1.927 0 0 0 22 0H2C1.45 0 .98.195.587.586A1.92 1.92 0 0 0 0 1.996v15.167c0 .549.196 1.019.587 1.41.392.39.863.586 1.413.586h20c.55 0 1.02-.196 1.412-.586.392-.391.588-.861.588-1.41V1.996c0-.55-.196-1.019-.588-1.41ZM22.4 17.163c0 .108-.04.201-.119.28a.385.385 0 0 1-.281.119H2a.385.385 0 0 1-.281-.118.384.384 0 0 1-.119-.281V1.996c0-.109.04-.202.119-.281A.385.385 0 0 1 2 1.597h20c.108 0 .202.04.281.118.08.079.119.172.119.28v15.168Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.6 7.983c.667 0 1.234-.233 1.7-.699.467-.466.7-1.03.7-1.696 0-.665-.233-1.23-.7-1.697a2.317 2.317 0 0 0-1.7-.698c-.666 0-1.233.233-1.7.698a2.307 2.307 0 0 0-.7 1.697c0 .665.234 1.23.7 1.696a2.317 2.317 0 0 0 1.7.699ZM9.2 11.575l-2-1.996-4 3.992v2.395h17.6v-5.588l-5.2-5.19-6.4 6.387Z"
      fill="currentColor"
    />
  </Svg>
);

ContentIcon.propTypes = {
  size: PropTypes.number,
};

ContentIcon.defaultProps = {
  size: 24,
};

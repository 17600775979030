import React from 'react';
import PropTypes from 'prop-types';
import { PencilIcon } from 'components/Icons/PencilIcon';
import { IconButton } from '@mui/material';
import { useStyles } from './styles';

export const WidgetAvatarUpload = ({ iconClick }) => {
  const classes = useStyles();

  return (
    <IconButton className={classes.button} onClick={iconClick}>
      <PencilIcon size={12} />
    </IconButton>
  );
};

WidgetAvatarUpload.propTypes = {
  iconClick: PropTypes.func,
};

WidgetAvatarUpload.defaultProps = {
  iconClick: null,
};

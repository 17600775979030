import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormControl, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { theme } from 'theme';
/**
 * @param {{
 *  name: string;
 *  value: any;
 *  options: Array<{
 *    label: string;
 *    value: any;
 *    disabled: boolean;
 *  }>
 * }} props
 */
export const FormRadioGroup = ({ name, label, value, options, onChecked, disabled, required, view, width }) => {
  const [val, setVal] = useState(value);

  useEffect(() => {
    setVal(value);
  }, [value, setVal]);

  const handleChecked = (newVal) => {
    // setVal(newVal);
    onChecked(newVal);
  };

  return (
    <FormControl>
      {label && <FormLabel disabled={disabled}>{label}</FormLabel>}
      <RadioGroup row={view === 'horizontal'} width={width}>
        {options.map((option) => (
          <RadioButton
            columns={option.columns}
            name={name}
            key={option.value}
            label={option.label}
            value={option.value}
            onChange={handleChecked}
            disabled={disabled || option.disabled}
            checked={option.value === val || option.checked}
            info={option.info}
            required={required}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
FormRadioGroup.propTypes = {
  /**
   * Name property determines the name
   * of the radio group
   * ex. ```<FormRadioGroup name={name} />```
   */
  name: PropTypes.string,
  /**
   * Label of the field
   */
  label: PropTypes.string,
  /**
   * Value property determines if an
   * individual radio button is selected or not.
   * ex. ```<FormRadioGroup value={val} />```
   */
  value: PropTypes.string,
  view: PropTypes.oneOf(['horizontal', 'vertical']),
  width: PropTypes.string,
  /**
   * Options property is the individual radio buttons and their properties.
   * ex. ```<FormRadioGroup options={[{label: 'Hello', value: 'hello', disabled: false}]} />```
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Label property is the label text passed from the options.
       * ex. ```<FormRadioGroup options={[{label: 'Hello'}]} />```
       */
      label: PropTypes.string,
      /**
       * Value property is the radio button's value.
       * ex. ```<FormRadioGroup options={[{value: 'Hello'}]} />```
       */
      value: PropTypes.string,
      /**
       * Disabled property determines if an
       * individual radio button should be disabled.
       * ex. ```<FormRadioGroup options={[{disabled: false}]} />```
       */
      disabled: PropTypes.bool,
      columns: PropTypes.number,
    })
  ),
  /**
   * Disabled property determines if the
   * radio group should be disabled.
   * ex. ```<FormRadioGroup disabled={disabled} />```
   */
  disabled: PropTypes.bool,
  /**
   * onChecked is a function that is called
   * when the selected radio button changes state.
   */
  onChecked: PropTypes.func,
  /**
   * HTML required
   */
  required: PropTypes.bool,
};
FormRadioGroup.defaultProps = {
  name: '',
  label: null,
  value: null,
  options: [],
  required: false,
  disabled: false,
  onChecked: () => {},
  view: 'vertical',
  width: null,
};
const RadioButton = ({ disabled, value, label, onChange, checked, name, required, info }) => (
  <>
    <FormControlLabel
      value={value}
      control={<Radio color="secondary" />}
      label={
        label && (
          <>
            <FormLabel style={{ color: theme.palette.primary[500], fontSize: '14px' }} disabled={disabled}>
              {label}
            </FormLabel>
            {info && (
              <Tooltip style={{ color: theme.palette.primary[500], fontSize: '14px' }} title={info}>
                <IconButton>
                  <FontAwesomeIcon icon={faCircleInfo} />
                </IconButton>
              </Tooltip>
            )}
          </>
        )
      }
      onClick={() => !disabled && onChange(value)}
      disabled={disabled}
      name={name}
      checked={checked}
      required={required}
    />
  </>
);
RadioButton.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  checked: PropTypes.bool,
  name: PropTypes.string,
  required: PropTypes.bool,
  info: PropTypes.string,
};
RadioButton.defaultProps = {
  disabled: false,
  value: null,
  label: null,
  onChange: () => {},
  checked: false,
  name: '',
  required: false,
  info: '',
};

import React from 'react';
import { useSelector } from 'react-redux';
import { SearchPanelBody, searchPanelSection } from 'pages/Admin/Users/SearchPanel/SearchPanelBody';
import { setFilters } from 'slices/teamMembersReducer';

export const SearchPanel = () => {
  const nameSort = useSelector((state) => state.teamMembers.filters.sort_by.lastName || '');
  const dateSort = useSelector((state) => state.teamMembers.filters.sort_by.createdAt || '');
  const userTeamRole = useSelector((state) => state.teamMembers.filters.userTeamRole);
  // const [teamsRoles, setTeamsRoles] = React.useState([{}]);

  return (
    <SearchPanelBody
      filters={{
        sort_by: {
          firstName: nameSort,
          createdAt: dateSort,
        },
        userTeamRole,
      }}
      setFilters={setFilters}
      section={searchPanelSection.TEAMUSERS}
    />
  );
};

import { ContextMenuButton } from 'components/ContextMenuButton/ContextMenuButton';
import { ContextMenuItem } from 'components/ContextMenuItem/';
import { DoubleCheckIcon, LinkedIcon, SharingIcon, TrashIcon } from 'components/Icons';
import { Typography } from 'components/Typography/';
import styled from 'styled-components';

export const StyledContextMenuList = styled.div`
  border: ${(props) => `0.25px solid ${props.theme.palette.primary[700]}`};
  overflow: hidden;
`;

export const StyledContextMenuItem = styled(ContextMenuItem)`
  border-bottom: ${(props) => `0.25px solid ${props.theme.palette.primary[700]}`};
  margin: 0;
  padding: 1rem inherit;
`;

export const StyledContextMenuButton = styled(ContextMenuButton)`
  min-height: 38px;
  min-width: 38px;
`;

export const AccessIcon = styled(LinkedIcon)`
  color: ${(props) => props.theme.palette.success.base};
`;

export const DeniedIcon = styled(LinkedIcon)`
  color: ${(props) => props.theme.palette.danger.base};
`;

export const AccessCopyIcon = styled(DoubleCheckIcon)`
  color: ${(props) => props.theme.palette.success.base};
`;

export const DeniedCopyIcon = styled(DoubleCheckIcon)`
  color: ${(props) => props.theme.palette.danger.base};
`;

export const AccessLinkedIcon = styled(LinkedIcon)`
  color: ${(props) => props.theme.palette.success.base};
`;

export const DeniedLinkedIcon = styled(LinkedIcon)`
  color: ${(props) => props.theme.palette.danger.base};
`;

export const AccessSharingIcon = styled(SharingIcon)`
  color: ${(props) => props.theme.palette.success.base};
`;

export const DeniedSharingIcon = styled(SharingIcon)`
  color: ${(props) => props.theme.palette.danger.base};
`;

export const AccessTrashIcon = styled(TrashIcon)`
  color: ${(props) => props.theme.palette.success.base};
`;

export const DeniedTrashIcon = styled(TrashIcon)`
  color: ${(props) => props.theme.palette.danger.base};
`;

export const StyledSubText = styled(Typography)`
  color: ${(props) => props.theme.palette.primary[700]};
`;

export const CardHeader = styled.div`
  border-bottom: 0.1px solid ${(props) => props.theme.palette.primary[700]};
  padding-bottom: ${(props) => props.theme.spacing(0.8)};
`;

export const ColumnContainer = styled.span`
  margin-top: 2px;
  float: left;
  margin-right: 15px;
  border: 0.1px solid ${(props) => props.theme.palette.primary[700]};
  border-radius: 5px;
  padding: 5px 9px;
  & > svg {
    margin-top: 5px;
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  & tr {
    border-bottom: solid 1px rgba(128, 128, 128, 0.5);
  }
  & th,
  td {
    padding: ${(props) => props.theme.spacing(0.7)};
    text-align: left;
  }
  & th {
    font-weight: bold;
  }

  & td:last-of-type {
    vertical-align: middle;
  }
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: center;

  height: 100%;

  & > div {
    margin-left: 10px;
  }
`;

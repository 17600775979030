import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const DoubleCheckIcon = ({ size, className }) => (
  <Svg viewBox="0 0 24 24" size={size} className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5155 6.99997L16.1435 5.58997L9.97433 11.93L11.3463 13.34L17.5155 6.99997V6.99997ZM21.6413 5.58997L11.3463 16.17L7.27895 12L5.90693 13.41L11.3463 19L23.0231 6.99997L21.6413 5.58997V5.58997ZM0.399414 13.41L5.83882 19L7.21083 17.59L1.78116 12L0.399414 13.41V13.41Z"
      fill="currentColor"
    />
  </Svg>
);

DoubleCheckIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
};

DoubleCheckIcon.defaultProps = {
  size: 24,
  className: '',
};

import { If } from 'components/If/If';
import { PermissionCard } from 'components/PermissionCard';
import { RoleDialog } from 'components/RoleDialog/RoleDialog';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import EnumService from 'services/EnumService';
import { DescriptionRoles } from 'lib/roles';
import { PermGuard } from 'components/Guards/PermGuard';
import { Permissions } from 'lib/permissions';
import { useStyles } from './styles';

const roleFetchFuncs = {
  account: () => EnumService.getAccountRoles(),
  org: () => EnumService.getOrgRoles(),
  project: () => EnumService.getProjectRoles(),
  team: () => EnumService.getTeamRoles(),
};
export const PermissionsPanel = ({ roles, onEdit, roleType }) => {
  const classes = useStyles();
  const [enumRoles, setEnumRoles] = React.useState([]);
  const [editId, setEditId] = React.useState(null);

  const sortProjectRoles = (arr) => {
    if (arr?.length > 2) {
      if (arr.length <= 1) {
        let k = 1 - arr.length + 1;
        // eslint-disable-next-line no-plusplus
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(1, 0, arr.splice(2, 1)[0]);
    }
    const descriptions = Object.values(DescriptionRoles);
    arr.forEach((element, i) => {
      element.info = descriptions[i] || '';
    });
    return arr;
  };

  useEffect(() => {
    roleFetchFuncs[roleType]().then((result) => {
      setEnumRoles(roleType === 'project' ? sortProjectRoles(result.data.data) : result.data.data);
    });
  }, []);

  const handleEdit = async (entity, newRoles) => {
    await onEdit(
      entity,
      newRoles.map((r) => r.permId)
    );
    setEditId(null);
  };

  const card = (entity, roleMap) => (
    <PermissionCard
      key={entity.id}
      title={entity.name}
      onEdit={() => {
        setEditId(entity.id);
      }}
      permissions={roleMap}
    />
  );

  return (
    <div className={classes.panelLayout}>
      {roles.map((entity) => {
        const roleLookup = entity.roles.reduce((acc, role) => {
          acc[role.roleId] = true;
          return acc;
        }, {});

        const roleMap = enumRoles.map((enumRole) => ({
          permId: enumRole.id,
          name: enumRole.name,
          isChecked: roleLookup[enumRole.id] || false,
          info: enumRole.info,
        }));

        return (
          <>
            {roleType === 'project' || roleType === 'team' ? (
              <PermGuard
                scopes={roleType === 'project' ? [Permissions.PROJ_USER_GRANT] : [Permissions.TEAM_USER_GRANT]}
                itemId={entity.id}
              >
                {card(entity, roleMap)}
              </PermGuard>
            ) : (
              card(entity, roleMap)
            )}
            <If cond={editId === entity.id}>
              <RoleDialog
                isOpen={editId === entity.id}
                title="Edit Roles"
                subTitle={entity.name}
                roles={roleMap}
                onSave={(newRoles) => handleEdit(entity, newRoles)}
                radioButton
              />
            </If>
          </>
        );
      })}
    </div>
  );
};

PermissionsPanel.propTypes = {
  roles: PropTypes.arrayOf([
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      roles: PropTypes.arrayOf([
        PropTypes.shape({
          roleId: PropTypes.string.isRequired,
          displayName: PropTypes.string.isRequired,
        }),
      ]),
    }),
  ]).isRequired,
  onEdit: PropTypes.func,
  roleType: PropTypes.string.isRequired,
};

PermissionsPanel.defaultProps = {
  onEdit: () => {},
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { CloseIcon } from 'components/Icons';
import { SecondaryHeader } from 'components/SecondaryHeader';
import { ProjectAssetContent } from 'components/ProjectDetail/ProjectAssets/ProjectAssetContent';
import { SidebarPanel } from 'components/SidebarPanel';
import { useSidebarDetail } from 'components/SidebarProviderDetail/SidebarProviderDetail';
import { Link } from 'react-router-dom';

const AssetPanel = ({ selectedContent, onClose }) => {
  const [selectedAsset, setSelectedAsset] = useState(null);

  useEffect(() => {
    if (selectedContent && selectedContent.id) {
      setSelectedAsset(selectedContent);
    }
  }, [selectedContent]);

  const { displayPanel } = useSidebarDetail({
    open: false,
    visible: false,
    config: {
      onClose: () => {},
    },
  });

  useEffect(() => {
    displayPanel('assetPanel');
  }, []);

  return (
    <div
      style={{
        width: '45%',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        maxHeight: 'calc(100vh - 126px)',
        position: 'relative',
      }}
    >
      {selectedAsset && selectedAsset.id && (
        <div style={{ overflowY: 'auto', height: '100vh' }}>
          <SidebarPanel noHeader cancelButton={false}>
            <SecondaryHeader title={selectedAsset.name}>
              <Button
                onClick={() => onClose()}
                color="primary"
                variant="contained"
                size="small"
                sx={{ padding: '8px 14px' }}
                style={{ marginLeft: 'auto' }}
              >
                <CloseIcon size={20} />
              </Button>
            </SecondaryHeader>
            <ProjectAssetContent selectedAsset={selectedAsset} project={selectedAsset?.project} showHeader={false} />
          </SidebarPanel>
        </div>
      )}
      <div
        style={{
          bottom: 0,
          width: '100%',
          display: 'flex',
          padding: '10px',
          borderBottom: '1px solid orange',
        }}
      >
        <Link
          to={`/projects?projectId=${selectedAsset?.project?.id}&pastId=${selectedAsset?.id}`}
          target="_blank"
          style={{ marginLeft: 'auto' }}
        >
          <Button type="button" size="medium" color="secondary" variant="contained" title="Open Content">
            Open Content
          </Button>
        </Link>
      </div>
    </div>
  );
};

AssetPanel.prototype = {
  selectedContent: PropTypes.object,
  onClose: PropTypes.func,
};

AssetPanel.defaultProps = {
  selectedContent: {},
  onClose: () => {},
};

export default AssetPanel;

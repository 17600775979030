import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  uuid: {
    color: theme.palette.secondary[500],
    fontWeight: 400,
  },
  uuidIcon: {
    padding: 1,
    color: theme.palette.primary[500],
    '& path': {
      stroke: theme.palette.primary[500],
    },
  },
  text: {
    color: theme.palette.primary[400],
    fontWeight: 400,
  },
  buttonGrapper: {
    padding: 0,
  },
  smallButton: {
    minHeight: 'auto',
    minWidth: 'auto',
    padding: '8px',
    marginRight: '5px',
  },
});

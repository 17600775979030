import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { ComingSoon } from 'components/ComingSoon/ComingSoon';

export const ModalComingSoon = ({ onCancel }) => (
  <Dialog open maxWidth="lg" onClose={onCancel}>
    <DialogContent>
      <ComingSoon />
    </DialogContent>
    <DialogActions>
      <Grid container spacing={1}>
        <Grid item xs={12} align="right">
          <Button color="secondary" variant="contained" onClick={onCancel}>
            Ok
          </Button>
        </Grid>
      </Grid>
    </DialogActions>
  </Dialog>
);

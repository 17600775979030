import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const EyeIcon = ({ size, className }) => (
  <Svg viewBox="0 0 24 24" size={size} className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0C7.414 0 3.256 2.93.187 7.686c-.25.39-.25.935 0 1.324C3.256 13.773 7.414 16.703 12 16.703s8.744-2.93 11.812-7.687c.25-.39.25-.934 0-1.324C20.744 2.93 16.586 0 12 0zm.33 14.23c-3.045.223-5.559-2.706-5.367-6.265.157-2.935 2.194-5.314 4.709-5.497 3.043-.223 5.556 2.705 5.365 6.265-.162 2.928-2.2 5.308-4.708 5.497zm-.153-2.716c-1.64.12-2.995-1.456-2.887-3.37.084-1.582 1.184-2.86 2.538-2.963 1.64-.12 2.995 1.456 2.887 3.37-.088 1.588-1.188 2.867-2.538 2.963z"
      fill="currentColor"
    />
  </Svg>
);

EyeIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
};

EyeIcon.defaultProps = {
  size: 24,
  className: '',
};

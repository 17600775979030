import { makeStyles } from '@mui/styles';

// eslint-disable-next-line no-unused-vars
export const useStyles = makeStyles((theme) => ({
  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'column',
  },
}));

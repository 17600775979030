import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  cardsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(2),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
}));

import * as React from 'react';
import { useSelector } from 'react-redux';
import { useContext } from 'react';
import { Button } from '@mui/material';
import { PencilIcon } from 'components/Icons';
import { SidebarContext } from 'components/SidebarProvider/SidebarProvider';
import { assetType } from 'components/Inspections/helpers';
import { SaveProjectAssetInspectionFinding } from './SaveProjectAssetInspectionFinding/SaveProjectAssetInspectionFinding';

export const ButtonCreateProjectAssetInspectionFinding = ({ time, points, selectedSequence, type }) => {
  const { detail } = useSelector((state) => state.projectAssetActiveInspections);
  const auth = useSelector((state) => state.auth);
  const { setActivePanel, setFullWidth, setHeaderPanels } = useContext(SidebarContext);

  const handleOnInspectionClick = () => {
    setHeaderPanels([
      {
        name: 'inspection',
        component: SaveProjectAssetInspectionFinding,
        data: { time, points, selectedSequence, action: 'creation' },
      },
    ]);
    setFullWidth(true);
    setActivePanel('inspection');
  };

  return (
    <>
      {detail?.id && detail?.assignment?.assignee?.id === auth.userId && (
        <Button
          sx={{ bottom: type === assetType.VIDEO ? '80px' : '5px', left: '5px', position: 'absolute', zIndex: 10 }}
          onClick={handleOnInspectionClick}
          color="secondary"
          variant="contained"
          title="Create an Inspection"
        >
          <PencilIcon size={20} />
        </Button>
      )}
    </>
  );
};

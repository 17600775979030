import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const PencilIcon = ({ size }) => (
  <Svg viewBox="0 0 24 24" size={size}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.9 4.88c.133.174.133-.68 0-1.594L20.715.099c-.914-.132-1.768-.132-1.593 0l-3.187 3.187 4.78 4.78c.307 0 2.79-2.483 3.187-3.187zM0 19.22V24h4.78L19.12 9.66l-4.78-4.78L0 19.22z"
      fill="currentColor"
    />
  </Svg>
);

PencilIcon.propTypes = {
  size: PropTypes.number,
};

PencilIcon.defaultProps = {
  size: 24,
};

import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const NotificationIcon = ({ size, className }) => (
  <Svg viewBox="0 0 24 24" size={size} className={className}>
    <path
      d="M15.016 10.5c0 .281-.11.516-.329.703a.954.954 0 0 1-.703.281H4L.016 15.516V1.5c0-.281.093-.516.28-.703A.954.954 0 0 1 1 .516h12.984c.282 0 .516.093.704.28a.888.888 0 0 1 .328.704v9ZM19 4.5c.281 0 .516.094.703.281a.954.954 0 0 1 .281.703v15L16 16.5H4.984a.954.954 0 0 1-.703-.281.954.954 0 0 1-.281-.703V13.5h12.984v-9H19Z"
      fill="currentColor"
    />
  </Svg>
);

NotificationIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
};

NotificationIcon.defaultProps = {
  size: 24,
  className: '',
};

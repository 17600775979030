import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  header: (props) => ({
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    gridTemplateRows: 'auto 1fr',
    rowGap: '5px',
    // marginTop: '10px',
    marginBottom: theme.spacing(0.5),
    padding: props.border ? '0 0 5px' : 'auto',
    borderBottom: props.border ? `1px solid ${theme.palette.primary.light}` : 'none',
  }),
  title: {
    gridRow: 1,
    '& h5': {
      fontWeight: 400,
    },
  },
  subTitle: {
    gridRow: 2,
    gridColumn: '1 / span 2',
    '& span, & p': {
      fontWeight: 400,
    },
  },
  button: {
    padding: '1px 8px!important',
    minHeight: 'auto',
    minWidth: 'auto',
    width: 'max-content',
    justifySelf: 'end',
    maxHeight: '30px',
    border: 'none!important',
    borderRadius: 4,
  },
});

/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Time } from 'lib/time';
import { Grid, Typography } from '@mui/material';
import { Bytes } from 'lib/bytes';

export const ProjectAssetInfo = ({ asset, project }) => {
  const { observations, isLoading } = useSelector((state) => state.assetObservations);
  const { projectAsset, isAssetLoading } = useSelector((state) => state.profileProjectAssets);

  const SizeCal = () => {
    const numbers = projectAsset.sequences.map((as) =>
      as.asset.assetFiles.reduce((total, file) => total + file.size, 0)
    );
    const summation = numbers.reduce((accumulator, currentValue) => accumulator + currentValue);
    return Bytes.dynamicHumanReadable({ bytes: summation });
  };

  return (
    <>
      <Typography mb={1}>Description: {asset.description || 'No Description Found.'}</Typography>
      <Grid container spacing={0.5}>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Typography mb={0.5}>
            Date Captured: {asset.createdAt ? moment(asset.createdAt).format('MM/DD/YY') : 'Date not found'}
          </Typography>
          <Typography mb={0.5}>
            Date Uploaded: {asset.createdAt ? moment(asset.createdAt).format('MM/DD/YY') : 'Date not found'}
          </Typography>
          <Typography mb={0.5}>
            Uploaded By:{' '}
            {asset.uploaders?.length
              ? `${asset.uploaders[0].firstName} ${asset.uploaders[0].lastName}`
              : 'Uploader not found'}
          </Typography>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Typography mb={0.5}>
            File Type: {asset.type === 'VIDEO' ? '.mp4' : asset.type === 'PHOTO' ? '.jpg' : '.arcgis.online'}
          </Typography>
          <Typography mb={0.5}>
            File Size:{' '}
            {isAssetLoading ? 'Loading...' : projectAsset?.sequences?.length > 0 ? SizeCal() : 'Data not found'}
          </Typography>
          <Typography mb={0.5}>
            Length: {asset.type === 'VIDEO' ? Time.humanReadable({ seconds: asset.timeLength }) : asset.type}
          </Typography>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Typography mb={0.5}>
            Ownership: {asset?.project?.id === project?.id ? 'This Project' : asset?.project?.name}
          </Typography>
          <Typography mb={0.5}>Observations Made: {isLoading ? 'Loading...' : observations?.length}</Typography>
        </Grid>
      </Grid>
    </>
  );
};

ProjectAssetInfo.propTypes = {
  asset: PropTypes.object,
  project: PropTypes.object,
};

ProjectAssetInfo.defaultProps = {
  asset: null,
  project: null,
};

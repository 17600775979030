import React, { useState, useEffect } from 'react';
import { Button, Grid, Typography, Divider, FormControlLabel, Checkbox, FormGroup } from '@mui/material';
import { SearchList } from 'components/SidebarPanel/SearchList/SearchList';
import { useStyles } from './styles';
import { CalendarModal } from 'components/CalendarModal';
import { ClearFilterAction } from 'components/SidebarPanel/ClearFilterAction';
import { SidebarPanel } from 'components/SidebarPanel';
import MapExtent from 'components/MapExtent/MapExtent';
import ProjectService from 'services/ProjectService';
import { useSelector, useDispatch } from 'react-redux';
import { setFilters, setMapExtent } from 'slices/search/filterReducer';
import { fetchContent, cleanContent } from 'slices/search/contentReducer';
import { formatDateRange } from 'slices/helpers';
import PropTypes from 'prop-types';
import { useSidebar } from 'components/SidebarProvider/SidebarProvider';

const cleanFilters = {
  date_range: [],
  type: [],
  accountId: [],
  organizationId: [],
  projectId: [],
  map_extent: [],
  page_size: 100,
  page: 1,
};

const FiltersPanel = ({ hidden }) => {
  const classes = useStyles();
  const [openCalendar, setOpenCalendar] = useState(false);
  const [localFilters, setLocalFilters] = useState(cleanFilters);
  const [filterActions, setFiltersActions] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [projects, setProjects] = useState([]);
  const search = useSelector((state) => state.search.filters.search);
  const proximity = useSelector((state) => state.search.content.proximity);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [dateRange, setDateRange] = useState(null);
  const dispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const { displayPanel } = useSidebar({
    open: false,
    config: {
      onClose: () => {},
    },
  });

  const updateNormalFilters = (value, type) => {
    const auxFilters = { ...localFilters };
    auxFilters[type] = value;
    setLocalFilters({ ...auxFilters });
  };

  const handleClearFilters = () => {
    dispatch(setFilters({ ...cleanFilters }));
    dispatch(setMapExtent([]));
    setLocalFilters(cleanFilters);
    dispatch(cleanContent());
    setDateRange(null);
  };

  const handleApplyFilters = () => {
    const auxFilters = { ...localFilters };
    setTimeout(() => {
      dispatch(
        fetchContent({
          filters: {
            search,
            ...auxFilters,
            ...(auxFilters.map_extent && auxFilters.map_extent.length >= 4 && { map_extent: auxFilters.map_extent }),
          },
        })
      );
      dispatch(setFilters({ ...auxFilters }));
    }, 300);
  };

  const createClearFilters = () => {
    const auxFilters = [];
    if (localFilters.map_extent && localFilters.map_extent.length > 0)
      auxFilters.push({ text: 'Clear By Get Extent', callBack: () => updateNormalFilters([], 'map_extent') });
    if (localFilters.date_range.length > 0)
      auxFilters.push({
        text: 'Clear Date Range',
        callBack: () => {
          updateNormalFilters([], 'date_range');
          setDateRange(null);
        },
      });
    if (localFilters?.type.length > 0)
      auxFilters.push({ text: 'Clear By Type', callBack: () => updateNormalFilters([], 'type') });
    if (localFilters.accountId.length > 0)
      auxFilters.push({ text: 'Clear By Account', callBack: () => updateNormalFilters([], 'accountId') });
    if (localFilters.organizationId.length > 0)
      auxFilters.push({ text: 'Clear By Organization', callBack: () => updateNormalFilters([], 'organizationId') });
    if (localFilters.projectId.length > 0)
      auxFilters.push({ text: 'Clear By Project', callBack: () => updateNormalFilters([], 'projectId') });

    setFiltersActions(auxFilters);
    return auxFilters;
  };

  const getProjects = async () => {
    try {
      const response = await ProjectService.getProjects({
        page_size: 100,
        include: ['organization', 'account'],
      });
      const responseProjects = response.data.data;
      setProjects(responseProjects.map((project) => ({ value: project.id, label: project.name })));
      const accountsResponse = responseProjects.reduce((acc, project) => {
        if (acc[project.account.id]) return acc;
        acc[project.account.id] = { value: project.account.id, label: project.account.name };
        return acc;
      }, []);
      const organizationsResponse = responseProjects.reduce((acc, project) => {
        if (acc[project.organization.id]) return acc;

        acc[project.organization.id] = { value: project.organization.id, label: project.organization.name };
        return acc;
      }, []);

      setAccounts(Object.values(accountsResponse));
      setOrganizations(Object.values(organizationsResponse));
      setLoadingProjects(false);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('error getting projects', e);
    }
  };

  const onChangeDateRange = (dates) => {
    if (dates.length > 0 && dates[0].startDate !== null && dates[0].endDate !== null) {
      updateNormalFilters([dates[0].startDate, dates[0].endDate], 'date_range');
      setOpenCalendar(false);
      setDateRange(formatDateRange([dates[0].startDate, dates[0].endDate]).createdAt);
    }
  };

  useEffect(() => {
    getProjects();
  }, []);

  useEffect(() => {
    createClearFilters(localFilters);
    if (proximity) {
      handleApplyFilters();
    }
  }, [localFilters]);

  const updateFilter = (auxFilters) => {
    setLocalFilters({ ...auxFilters });
  };

  const onGetExtend = (extent) => {
    setLocalFilters((prev) => ({ ...prev, map_extent: extent }));
  };

  const fileTypeChange = (type) => {
    let typeAux = [...localFilters.type];
    if (localFilters.type.includes(type)) {
      typeAux = typeAux.filter((item) => item !== type);
    } else typeAux.push(type);

    updateNormalFilters(typeAux, 'type');
  };

  return (
    <div
      style={{
        width: '45%',
        display: hidden ? 'none' : 'flex',
        flexDirection: 'column',
        height: '100vh',
        maxHeight: 'calc(100vh - 126px)',
      }}
    >
      <SidebarPanel
        filters
        noHeader
        cancelButton={false}
        handleClearFilters={handleClearFilters}
        handleApplyFilters={handleApplyFilters}
      >
        <Grid
          style={{
            padding: '15px',
          }}
          container
          direction="column"
          className="searchBody"
        >
          <Typography style={{ padding: '5px', fontWeight: 'bold' }} variant="h6">
            Zoom and drag into focus the area of interest.
          </Typography>
          <Grid style={{ height: '40vh', width: '100%', borderRadius: '8px', overflow: 'hidden' }}>
            <div
              style={{
                height: '100%',
                marginBottom: '0',
                position: 'sticky',
                zIndex: '79',
                top: '0',
                borderRadius: '5px',
              }}
            >
              <MapExtent onExtentChange={onGetExtend} defaultZoom={proximity} />
            </div>
          </Grid>
          <Grid item>
            <Typography style={{ padding: '15px 0px', fontWeight: 'bold' }} variant="h6">
              Search and Filter Content
            </Typography>
            <ClearFilterAction actions={filterActions} />
          </Grid>
          <Grid item style={{ padding: '15px 0px', display: 'flex', gap: '25px' }}>
            <Button
              type="button"
              size="medium"
              color="secondary"
              variant="contained"
              title="By date Range"
              onClick={() => setOpenCalendar(true)}
            >
              By Date Range
            </Button>
            {dateRange && (
              <Typography style={{ padding: '15px 0px' }}>
                Dates Selection: {dateRange.lte ? `${dateRange.gte} - ${dateRange.lte}` : dateRange.gte}
              </Typography>
            )}
            <Divider flexItem />
          </Grid>
          <Grid style={{ padding: '15px 0px' }} container direction="row" alignItems="flex-start">
            <Grid xs={6} item>
              <Typography> File Type </Typography>
              <FormGroup className={classes.inputPadding}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={localFilters.type.includes('PHOTO')}
                      onChange={() => fileTypeChange('PHOTO')}
                      name="photo"
                      color="secondary"
                    />
                  }
                  label="Image"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={localFilters.type.includes('VIDEO')}
                      onChange={() => fileTypeChange('VIDEO')}
                      name="video"
                      color="secondary"
                    />
                  }
                  label="Video"
                />
              </FormGroup>
            </Grid>
            <Divider flexItem />
          </Grid>
          <Grid container direction="row" alignItems="flex-start" justifyContent="center">
            <Grid item xs={6} padding="0 10px 0 0">
              <SearchList
                title="Find Content by Account"
                fullList={accounts}
                localFilters={localFilters}
                onChangeSelect={updateFilter}
                filter="accountId"
                loaded={!loadingProjects}
              />
            </Grid>
            <Grid item xs={6} padding="0 0 0 10px">
              <SearchList
                title="Find Content by Organization"
                fullList={organizations}
                localFilters={localFilters}
                onChangeSelect={updateFilter}
                filter="organizationId"
                loaded={!loadingProjects}
              />
            </Grid>
          </Grid>
          <Divider flexItem />
          <Grid container direction="row" alignItems="flex-start" padding="10px 0">
            <Grid item xs={6} padding="0 10px 0 0">
              <SearchList
                title="Find Content by Project"
                fullList={projects}
                localFilters={localFilters}
                onChangeSelect={updateFilter}
                filter="projectId"
                loaded={!loadingProjects}
              />
            </Grid>
          </Grid>
          <Divider flexItem />
          {openCalendar && (
            <CalendarModal
              rangeDate={localFilters.date_range}
              handleClose={() => setOpenCalendar(false)}
              handleConfirm={onChangeDateRange}
            />
          )}
        </Grid>
      </SidebarPanel>
    </div>
  );
};

FiltersPanel.propTypes = {
  hidden: PropTypes.bool,
};

FiltersPanel.defaultProps = {
  hidden: false,
};

export default FiltersPanel;

import { Typography } from 'components/Typography/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const getColor = (theme, error, disabled) => {
  if (disabled) {
    return theme.palette.primary[600];
  }

  if (error) {
    return theme.palette.danger.base;
  }

  return theme.palette.white;
};

const StyledLabel = styled(Typography)`
  color: ${(props) => getColor(props.theme, props.error, props.disabled)};
  display: inline-block;
  margin-bottom: ${(props) => (props.noSpacing ? '0' : '6px')};
`;

const StyledRequire = styled.span`
  color: ${(props) => (props.error || props.disabled ? 'inherit' : props.theme.palette.secondary.base)};
`;

export const FormLabel = ({ error, disabled, required, children, ...rest }) => (
  <StyledLabel variant="small" as="label" error={error} disabled={disabled} {...rest}>
    {children}
    {required && (
      <StyledRequire error={error} disabled={disabled}>
        {' '}
        *
      </StyledRequire>
    )}
  </StyledLabel>
);

FormLabel.propTypes = {
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  noSpacing: PropTypes.bool,
  children: PropTypes.node,
};

FormLabel.defaultProps = {
  error: false,
  disabled: false,
  required: false,
  noSpacing: false,
  children: '',
};

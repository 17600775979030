import PropTypes from 'prop-types';
import React from 'react';
import { AttachedFileContainer, StyledTypography } from './styles';

export const AttachedFile = ({ icon: Icon, number, iconColor }) => (
  <AttachedFileContainer iconColor={iconColor}>
    <Icon size={16} />
    <StyledTypography>{number !== 0 && number}</StyledTypography>
  </AttachedFileContainer>
);

AttachedFile.propTypes = {
  icon: PropTypes.func.isRequired,
  number: PropTypes.number,
  iconColor: PropTypes.oneOf(['default', 'allowed', 'disallowed']),
};

AttachedFile.defaultProps = {
  number: 0,
  iconColor: 'default',
};

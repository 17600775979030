import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  greyTypography: {
    color: `${theme.palette.primary[500]} !important`,
  },
  columnContainer: {
    marginTop: '2px',
    float: 'left',
    marginRight: '15px',
    border: `0.1px solid ${theme.palette.light}`,
    borderRadius: '5px',
    padding: '5px 9px',
    '& > svg': {
      marginTop: '5px',
    },
  },
});

import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const InfoIcon = ({ size, className }) => (
  <Svg viewBox="0 0 24 24" size={size} className={className}>
    <path
      d="M15.416 8.15l-6.047.757-.217 1.005 1.191.217c.774.185.928.464.758 1.237L9.152 20.52c-.51 2.367.279 3.48 2.135 3.48 1.438 0 3.107-.665 3.865-1.578l.232-1.098c-.524.465-1.299.651-1.809.651-.727 0-.99-.51-.803-1.409L15.415 8.15zm.183-5.51a2.64 2.64 0 11-5.279 0 2.64 2.64 0 015.279 0z"
      fill="currentColor"
    />
  </Svg>
);

InfoIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
};

InfoIcon.defaultProps = {
  size: 24,
  className: '',
};

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { LayoutArea } from 'components/LayoutArea';
import { useStyles } from 'components/LayoutPageContent';
import { SecondaryHeader } from 'components/SecondaryHeader';
import { SecondaryNav } from 'components/SecondaryNav';
import { SecondaryNavItem } from 'components/SecondaryNavItem';
import { Sidebar } from 'components/Sidebar';
import { ActivityIcon } from 'components/Icons/ActivityIcon';
import { GearIcon, ImageCheckIcon, LayersIcon, PlayIconCircle, UsersIcon } from 'components/Icons';
import { SideBarDetail } from 'components/SideBarDetail/SideBarDetail';
import { useDispatch, useSelector } from 'react-redux';
import { setMenu } from 'slices/authReducer';

const styles = (variant) => ({
  position: 'relative',
  height: '100%',
  ...(variant === 'map' && { display: 'flex', flexDirection: 'column' }),
});

export const ProfileSettingsContainer = ({ title, showSidebar, sidebarConfig, children, actions, variant }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');
  const { profile } = useSelector((state) => state.auth.menu);

  const style = { marginLeft: 10 };
  const iconSize = 20;

  useEffect(
    () => () => {
      dispatch(setMenu({ profile: true }));
    },
    []
  );

  return (
    <div className={classes.content}>
      {profile && (
        <SecondaryNav>
          <SecondaryNavItem to="/profile" active={pathname === '/profile'}>
            <ActivityIcon size={iconSize} />
            <span style={style}>Overview</span>
          </SecondaryNavItem>
          <SecondaryNavItem to="/profile/projects" active={splitLocation[2] === 'projects'}>
            <LayersIcon size={iconSize} />
            <span style={style}>Your Projects</span>
          </SecondaryNavItem>
          <SecondaryNavItem to="/profile/inspections" active={splitLocation[2] === 'inspections'}>
            <ImageCheckIcon size={iconSize} />
            <span style={style}>Your Inspections</span>
          </SecondaryNavItem>
          <SecondaryNavItem to="/profile/teams" active={splitLocation[2] === 'teams'}>
            <UsersIcon size={iconSize} />
            <span style={style}>Your Teams</span>
          </SecondaryNavItem>
          <SecondaryNavItem to="/profile/content" active={splitLocation[2] === 'content'}>
            <PlayIconCircle size={iconSize} />
            <span style={style}>Your Contributions</span>
          </SecondaryNavItem>
          <SecondaryNavItem to="/profile/security" active={splitLocation[2] === 'security'}>
            <GearIcon size={iconSize} />
            <span style={style}>Settings</span>
          </SecondaryNavItem>
        </SecondaryNav>
      )}
      {showSidebar && <Sidebar config={sidebarConfig} />}
      <SideBarDetail config={sidebarConfig} visible />
      <LayoutArea area="content" allowScroll>
        <div style={styles(variant)}>
          <SecondaryHeader title={title} actions={actions} />
          {children}
        </div>
      </LayoutArea>
    </div>
  );
};

ProfileSettingsContainer.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  showSidebar: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  sidebarConfig: PropTypes.object,
  actions: PropTypes.instanceOf(Array),
  variant: PropTypes.string,
};

ProfileSettingsContainer.defaultProps = {
  showSidebar: false,
  sidebarConfig: {},
  actions: [],
  variant: '',
};

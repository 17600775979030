import { ContextMenu } from 'components/ContextMenu';
import { ContextMenuList } from 'components/ContextMenuItems';
import React from 'react';
import { PropTypes } from 'prop-types';
import { ContextMenuItem } from 'components/ContextMenuItem';
import { ContextMenuButton } from 'components/ContextMenuButton';

export const BillingActions = ({ row }) => (
  <>
    <ContextMenu>
      <ContextMenuButton />
      <ContextMenuList position="bottom-right">
        <ContextMenuItem
          onClick={() => {
            window.open(row.original.invoiceLink);
          }}
        >
          Download Invoice
        </ContextMenuItem>
      </ContextMenuList>
    </ContextMenu>
  </>
);

BillingActions.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  row: PropTypes.object,
};

BillingActions.defaultProps = {
  row: null,
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DialogMessage, dialogMessageAction } from 'components/DialogMessage';
import { InfoIcon } from 'components/Icons';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import ExternalService from 'services/ExternalService';
import { externalDataType } from 'lib/external-data';

export const DeleteUuid = ({ externalData, type, handleClose }) => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const deleteExternalData = async () => {
    setOpenConfirm(false);
    setIsLoading(true);
    try {
      await ExternalService.removeExternalData(externalData.id, type);
      setIsLoading(false);
      handleClose(true);
    } catch (error) {
      setIsLoading(false);
      setOpenDialog('An error occurred while trying to delete.');
    }
  };

  useEffect(() => {
    const auxData = { ...externalData };
    if (type === externalDataType.USER) auxData.name = `${auxData.firstName} ${auxData.lastName}`;
    setOpenConfirm(auxData);
  }, [externalData]);

  return (
    <>
      <DialogMessage
        title={`Delete ${openConfirm?.name}`}
        content={`Are you sure you want to delete this ${openConfirm?.name}? This action cannot be undone`}
        action={dialogMessageAction.delete}
        isOpen={openConfirm?.id}
        onConfirm={deleteExternalData}
        onCancel={() => handleClose()}
      />
      <DialogMessage
        content={openDialog}
        isOpen={openDialog}
        icon={InfoIcon}
        confirmText="Ok"
        onConfirm={() => handleClose()}
      />
      <LoadingOverlay loading={isLoading} />
    </>
  );
};

DeleteUuid.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  externalData: PropTypes.object,
  type: PropTypes.string,
  handleClose: PropTypes.func,
};

DeleteUuid.defaultProps = {
  externalData: { id: null, name: '' },
  type: '',
  handleClose: () => {},
};

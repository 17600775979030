import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { useStyles } from './styles';

const colors = {
  warning: { color: 800, background: 300 },
};

export const ModalHeaderIcon = ({ icon: Icon, text, color }) => {
  const classes = useStyles({ color: color ? colors[color] : null });

  return (
    <>
      <div className={classes.iconContainer}>
        <Icon size={20} />
      </div>
      {text && (
        <div style={{ paddingTop: '10px' }}>
          <Typography variant="h4" align="left">
            {text}
          </Typography>
        </div>
      )}
    </>
  );
};

ModalHeaderIcon.propTypes = {
  icon: PropTypes.any,
  text: PropTypes.any,
  color: PropTypes.any,
};

ModalHeaderIcon.defaultProps = {
  icon: null,
  text: '',
  color: '',
};

import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  card: (props) => ({
    padding: props.noPadding ? 0 : '20px',
    border: props.border ? `1px solid ${theme.palette.primary[800]}` : `1px solid transparent`,
    backgroundColor: props.variant === 'default' ? theme.palette.primary[1000] : theme.palette.primary.dark,
    display: 'flex',
    flexDirection: 'column',
    ...(!props.noHeight && { height: 'calc(100vh - 122px)' }),
  }),
});

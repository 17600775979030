import PropTypes from 'prop-types';
import React from 'react';
import { Typography } from '@mui/material';
import { useGlobalStyles } from 'styles';
import { useStyles } from './styles';

export const WidgetItem = ({ title, subHeader, children }) => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  return (
    <div className={classes.styledWidgetItem}>
      <div className={classes.styledTitle}>
        {title && <Typography>{title}</Typography>}
        {subHeader && <Typography className={globalClasses.greyTypography}>{subHeader}</Typography>}
      </div>
      {children && <div className={classes.styledChildren}>{children}</div>}
    </div>
  );
};

WidgetItem.propTypes = {
  title: PropTypes.string,
  subHeader: PropTypes.node,
  children: PropTypes.node,
};

WidgetItem.defaultProps = {
  title: null,
  subHeader: null,
  children: null,
};

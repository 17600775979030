import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useSortBy, useTable } from 'react-table';
import { Grid, Typography } from '@mui/material';
import { GeneralTable } from 'components/GeneralTable/GeneralTable';
import { WidgetSplash } from 'components/WidgetSplash/WidgetSplash';
import SplashImage from 'images/ContentSplash.png';
import { Loader } from 'components/Loader';
import {
  setProjectAssetInspection,
  getProjectAssetInspections,
  resetInspections,
} from 'slices/projectAssetActiveInspectionReducer';
import { getProjectUsers } from 'slices/allProjectReducer';
import { LinearProgressWithLabel, ProjectAssetInspectionDetail } from './ProjectAssetInspectionDetail';
import { ExportMultipleFindings } from 'components/Findings/ExportMultipleFindings/ExportMultipleFindings';
import { ProjectAssetInspectionFilters } from './ProjectAssetInspectionFilters';
import { useProjectAssetContentContext } from 'components/ProjectDetail/ProjectAssets/ProjectAssetContentContext';
import { useStyles } from '../styles';

const tableColumns = () => [
  {
    Header: 'Insp Title',
    accessor: (rowData) => (
      <>
        <Typography>{rowData?.inspection?.name}</Typography>
        <Typography>{rowData?.projectAsset?.name}</Typography>
        <LinearProgressWithLabel value={rowData.progress?.id ? rowData.progress.percent : 0} label="%" />
      </>
    ),
  },
  {
    Header: 'Assignee',
    accessor: (rowData) =>
      rowData?.assignment?.assignee
        ? `${rowData?.assignment?.assignee?.firstName} ${rowData?.assignment?.assignee?.lastName}`
        : 'Unassigned',
  },
];

let debounceTimeout = null;

export const ProjectActiveInspections = ({ project }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const columns = React.useMemo(() => tableColumns(), []);
  const { data, filters, loading } = useSelector((state) => state.projectAssetActiveInspections);
  const [selectedRow, setSelectedRow] = useState({});
  const { userId } = useSelector((state) => state.auth);
  const [openExport, setOpenExport] = useState(false);
  const { unsavedChanges, setOpenUnsavedDialog, setContinueAction } = useProjectAssetContentContext();

  const table = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  const projAssetInspectDetail = (selected) => {
    // activeInspecSection is used to validate in save progress inspection
    if (userId === selected?.assignment?.assignee?.id) {
      dispatch(setProjectAssetInspection({ ...selected, activeInspecSection: true }));
    } else {
      dispatch(setProjectAssetInspection({ ...selected, activeInspecSection: true, id: false }));
    }
  };

  const handleSelect = (row) => {
    setSelectedRow(row.original);
    dispatch(setProjectAssetInspection({}));
    projAssetInspectDetail(row.original);
  };

  const handleRowClick = (row) => {
    if (unsavedChanges) {
      setOpenUnsavedDialog(true);
      setContinueAction(() => () => handleSelect(row));
    } else {
      handleSelect(row);
    }
  };

  useEffect(() => {
    clearTimeout(debounceTimeout);
    const newFilters = {
      ...filters,
      projectId: project?.id,
      sort_by: Object.values(filters.sort_by),
      include: ['uploader', 'inspection', 'assignment', 'projectAsset', 'progress', 'sequence', 'project'],
    };
    debounceTimeout = setTimeout(() => {
      dispatch(getProjectAssetInspections(newFilters));
    }, 300);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [filters]);

  useEffect(() => {
    if (data?.length) {
      const findOne = data.find((item) => item.id === selectedRow?.id);
      const selected = findOne?.id ? findOne : data[0];
      setSelectedRow(selected);
      projAssetInspectDetail(selected);
    }
  }, [data]);

  useEffect(() => {
    dispatch(getProjectUsers(project?.id));
    return () => {
      dispatch(resetInspections());
    };
  }, []);

  if (loading && data.length === 0) {
    return <Loader loading={loading} height={250} />;
  }

  const Content = () => (
    <>
      <ProjectAssetInspectionFilters />
      {loading ? (
        <Loader height={50} loading size={32} />
      ) : (
        <div className={classes.tableContainer}>
          <GeneralTable
            table={table}
            handleRowClick={(row) => handleRowClick(row)}
            selectRow={false}
            autoHeight
            selectedRow={selectedRow?.id}
          />
        </div>
      )}
    </>
  );

  return (
    <Grid container spacing={0} className={classes.fullHeight}>
      {!data?.length ? (
        <>
          <Grid item lg={4} md={4} sm={12} xs={12} className={classes.listContainer}>
            {Content()}
          </Grid>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <WidgetSplash
              alt="Project Inspections Splash"
              title="There are no active inspections"
              image={SplashImage}
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid item lg={4} md={4} sm={12} xs={12} className={classes.listContainer}>
            {Content()}
          </Grid>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            {loading ? (
              <Loader height={50} loading size={32} />
            ) : (
              selectedRow?.id && (
                <ProjectAssetInspectionDetail
                  externalAction={() => setOpenExport(true)}
                  projAssetInspection={selectedRow}
                />
              )
            )}
          </Grid>
        </>
      )}
      {openExport && (
        <ExportMultipleFindings
          handleClose={() => setOpenExport(false)}
          projectName={project?.name}
          projectAssestInspections={data}
        />
      )}
    </Grid>
  );
};

ProjectActiveInspections.propTypes = {
  project: PropTypes.object.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import { FormRadioGroup } from 'components/FormRadioGroup';
import { DescriptionRoles, Roles } from 'lib/roles';
import { Typography } from '@mui/material';

export const roles = (values) => {
  const options = [+values.role];
  return options;
};

export const validateOptions = (values) => {
  if (values?.role < 1) {
    return true;
  }
  return false;
};

const options = (bySelf) => [
  { label: 'Project Administrator', value: Roles.PROJ_ADMIN, info: DescriptionRoles.PROJ_ADMIN, disabled: bySelf },
  {
    label: 'Participant Coordinator',
    value: Roles.PROJ_PART_COORD,
    info: DescriptionRoles.PROJ_PART_COORD,
    disabled: bySelf,
  },
  {
    label: 'Content Coordinator',
    value: Roles.PROJ_CONTENT_COORD,
    info: DescriptionRoles.PROJ_CONTENT_COORD,
    disabled: bySelf,
  },
  { label: 'Contributor', value: Roles.PROJ_CNTRB, info: DescriptionRoles.PROJ_CNTRB },
  { label: 'Collaborator', value: Roles.PROJ_COLLAB, info: DescriptionRoles.PROJ_COLLAB },
  { label: 'View-Only', value: Roles.PROJ_VIEW, info: DescriptionRoles.PROJ_VIEW },
];

export const UserProjectRoleOptions = ({ formik, checkBoxValidation, handleChange, bySelf }) => (
  <>
    <FormRadioGroup
      name="role"
      options={options(bySelf)}
      onChecked={(value) => handleChange('role', `${value}`)}
      value={+formik.values.role}
    />
    {(checkBoxValidation || (!formik.values.role && formik.touched.role)) && (
      <Typography color="error">Role is required</Typography>
    )}
  </>
);

UserProjectRoleOptions.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.any,
  handleChange: PropTypes.func,
  checkBoxValidation: PropTypes.bool,
  bySelf: PropTypes.bool,
};

UserProjectRoleOptions.defaultProps = {
  formik: null,
  handleChange: () => {},
  checkBoxValidation: false,
  bySelf: false,
};

import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { InfoPanelBody } from 'pages/Projects/InfoPanel/InfoPanelBody';

export const InfoPanel = () => {
  const history = useHistory();
  const { loading, detail, projUsers, projTeams } = useSelector((state) => state.profileProjects.panels);
  const navigateToPermissions = () => {
    history.push(`/profile/projects/${detail.id}`);
  };

  return (
    <InfoPanelBody
      loading={loading}
      detail={detail}
      projUsers={projUsers}
      projTeams={projTeams}
      navigateToPermissions={navigateToPermissions}
    />
  );
};

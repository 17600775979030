import PropTypes from 'prop-types';
import { Card } from 'components/Card/Card';
import { ContextMenu } from 'components/ContextMenu';
import { ContextMenuList } from 'components/ContextMenuItems';
import { PermGuard } from 'components/Guards/PermGuard';
import { InfoIcon } from 'components/Icons';
import { Loader } from 'components/Loader';
import { ProfileSettingsContainer } from 'components/ProfileSettingsContainer/ProfileSettingsContainer';
import { useSidebar } from 'components/SidebarProvider/SidebarProvider';
import { TablePagination } from 'components/TablePagination/TablePagination';
import { ViewRoleValidation } from 'components/ViewRoleValidation/ViewRoleValidation';
import { WidgetSplash } from 'components/WidgetSplash/WidgetSplash';
import SplashImage from 'images/TeamsSplash.png';
import { Permissions } from 'lib/permissions';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { usePagination, useSortBy, useTable } from 'react-table';
import { fetchTeam, fetchTeams, setPageFilter, setSearch } from 'slices/profileTeamsReducer';
import { ModalInviteTeam } from 'smartComponents/ModalInviteTeam';
import { ModalNewTeam } from 'smartComponents/ModalNewTeam';
import { GeneralTable } from 'components/GeneralTable/GeneralTable';
import { TopBar } from 'components/TopBar/TopBar';
import { ContextMenuItem } from 'components/ContextMenuItem';
import { ContextMenuButton } from 'components/ContextMenuButton';
import { InfoPanel } from './InfoPanel';
import { SearchPanel } from './SearchPanel';

const tableColumns = () => [
  {
    Header: 'Name',
    accessor: (row) => `${row.name}`, // accessor is the "key" in the data
  },
  {
    Header: 'Account',
    accessor: (rowData) => {
      const { account } = rowData;
      return account ? account.name : '';
    },
  },
  {
    Header: 'Organization',
    accessor: (rowData) => {
      const { org } = rowData;
      return org ? org.name : '';
    },
  },
  {
    Header: 'Description',
    accessor: (row) => `${row.description}`,
  },
  {
    Header: 'Members',
    accessor: (rowData) => {
      const { users } = rowData;
      return users.length || 0;
    },
  },
];

let debounceTimeout = null;
export const ProfileTeams = () => {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const { teams, loading, filters, teamCount } = useSelector((state) => state.profileTeams);
  const [openInviteUsers, setOpenInviteUsers] = useState(false);
  const [teamId, setTeamId] = useState(null);
  const [selectedRow, setSelectedRow] = useState('');
  const [organizationId, setOrganizationId] = useState(null);
  const history = useHistory();
  const [validateRole, setValidateRole] = useState({ open: false, callBack: () => {} });
  const search = filters.search || '';
  const handleSearch = (e) => dispatch(setSearch(e.target.value));

  const handleConfirm = () => {
    setOpen(false);

    const sendFilters = {
      ...filters,
      sort_by: Object.values(filters.sort_by),
    };
    dispatch(fetchTeams(sendFilters));
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const navigateToDashboard = (rowData) => {
    history.push(`/teams/${rowData.original.id}`);
  };

  const navigateToMembers = (rowData) => {
    history.push(`/teams/${rowData.original.id}/members`);
  };

  const teamsScopes = [Permissions.TEAM_MODIFY];

  const columns = React.useMemo(() => tableColumns(), []);

  const table = useTable(
    {
      columns,
      data: teams,
      initialState: {
        pageIndex: filters.page - 1,
        pageSize: filters.page_size,
      },
      pageCount: Math.ceil(teamCount / filters.page_size),
      totalCount: teamCount,
      pageLength: teams.length,
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  const {
    state: { pageIndex, pageSize },
  } = table;

  useEffect(() => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      const sendFilters = {
        ...filters,
        sort_by: Object.values(filters.sort_by),
      };
      dispatch(fetchTeams(sendFilters));
    }, 150);
  }, [filters]);

  useEffect(() => {
    dispatch(setPageFilter(pageIndex + 1));
  }, [pageIndex, pageSize]);

  const { displayPanel, setHeaderPanels } = useSidebar({
    open: false,
    config: {
      search: {
        show: true,
        component: SearchPanel,
      },
      header: [],
    },
  });

  const handleRowClick = (rowData) => {
    // dispatch redux action to load user data
    // set sidebar active panel to user info and open if necessary
    setSelectedRow(rowData.id);
    dispatch(fetchTeam(rowData.original.id));
    setHeaderPanels([
      {
        name: 'info',
        icon: InfoIcon,
        component: InfoPanel,
      },
    ]);
    displayPanel('info');
  };

  const EmptyTeamsContainer = () => (
    <>
      <WidgetSplash
        alt="Teams Splash"
        title="You have no teams yet."
        image={SplashImage}
        cta="Create your first team now."
        onClick={() =>
          setValidateRole({ open: true, action: [Permissions.ORG_TEAM_CREATE], callBack: () => setOpen(true) })
        }
      />
      <ModalNewTeam isOpen={open} onConfirm={handleConfirm} onCancel={handleCancel} />
    </>
  );

  const { fname, lname } = useSelector((state) => state.auth);

  const getHeaderText = () => {
    const header = fname === null || lname === null ? 'Profile Dashboard' : `${fname} ${lname}'s Dashboard`;
    return header.toUpperCase();
  };

  if (loading && teams.length === 0) {
    return (
      <ProfileSettingsContainer title={getHeaderText()} showSidebar>
        <Loader loading={loading} height={250} />
      </ProfileSettingsContainer>
    );
  }

  const loadData = () => {
    const sendFilters = {
      ...filters,
      sort_by: Object.values(filters.sort_by),
    };
    dispatch(fetchTeams(sendFilters));
  };

  // Manage the Open & Close Invite users to an Organization Modal
  const handleOpenInviteUsers = async (id, orgId) => {
    setOpenInviteUsers(true);
    setTeamId(id);
    setOrganizationId(orgId);
  };

  const handleInviteUsers = async () => {
    loadData();
    setOpenInviteUsers(false);
  };

  const handleCancelInviteUsers = () => {
    setOpenInviteUsers(false);
  };

  const TeamActions = ({ row }) => (
    <PermGuard scopes={teamsScopes}>
      <ContextMenu>
        <ContextMenuButton />
        <ContextMenuList position="left">
          <ContextMenuItem onClick={() => navigateToDashboard(row)}>Go to Team Dashboard</ContextMenuItem>
          <ContextMenuItem
            onClick={() =>
              setValidateRole({
                open: true,
                action: [Permissions.TEAM_USER_REMOVE],
                data: { id: teamId },
                callBack: () => navigateToMembers(row),
              })
            }
          >
            Remove Members
          </ContextMenuItem>
          <ContextMenuItem
            onClick={() =>
              setValidateRole({
                open: true,
                action: [Permissions.TEAM_USER_ADD, Permissions.TEAM_USER_GRANT],
                data: { id: teamId },
                callBack: () => handleOpenInviteUsers(row.original.id, row.original.orgId),
              })
            }
          >
            Manage Members
          </ContextMenuItem>
        </ContextMenuList>
      </ContextMenu>
    </PermGuard>
  );

  TeamActions.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    row: PropTypes.object.isRequired,
  };

  return (
    <ProfileSettingsContainer title={getHeaderText()} showSidebar>
      <Card noPadding>
        <TopBar searchValue={search} handleSearch={handleSearch} activatedFilter={filters.activated} />
        {teams.length > 0 ? (
          <>
            <GeneralTable
              table={table}
              selectedRow={selectedRow}
              handleRowClick={handleRowClick}
              rowComponent={TeamActions}
            />
            <TablePagination table={table} />
            {openInviteUsers && (
              <ModalInviteTeam
                isOpen={openInviteUsers}
                onConfirm={handleInviteUsers}
                onCancel={handleCancelInviteUsers}
                teamId={teamId}
                orgId={organizationId}
              />
            )}
          </>
        ) : (
          <EmptyTeamsContainer />
        )}
      </Card>
      {validateRole.open && (
        <ViewRoleValidation
          data={validateRole.data}
          action={validateRole.action}
          callBack={validateRole.callBack}
          handleClose={() => setValidateRole({ open: false, callBack: () => {} })}
        />
      )}
    </ProfileSettingsContainer>
  );
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InfoIcon } from 'components/Icons';
import { DialogMessage, dialogMessageAction } from 'components/DialogMessage';
import ProjectService from 'services/ProjectService';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';

export const DeleteProject = ({ project, handleClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const deleteProj = async () => {
    setIsLoading(true);
    try {
      await ProjectService.deleteProject(project.id);
      handleClose(true);
    } catch (error) {
      setIsLoading(false);
      setOpenDialog('An error occurred while trying to delete the project.');
    }
  };

  return (
    <>
      <DialogMessage
        title={`Delete ${project.name}`}
        content={`Are you sure you want to delete this ${project.name}? This action cannot be undone`}
        isOpen={project?.id}
        onConfirm={deleteProj}
        onCancel={() => handleClose()}
        action={dialogMessageAction.delete}
      />
      <DialogMessage
        content={openDialog}
        isOpen={openDialog}
        icon={InfoIcon}
        confirmText="Ok"
        onConfirm={() => handleClose()}
      />
      <LoadingOverlay loading={isLoading} />
    </>
  );
};

DeleteProject.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  project: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
};

DeleteProject.defaultProps = {
  project: null,
};

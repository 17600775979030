import { Typography } from 'components/Typography';
import styled, { css } from 'styled-components';

export const SidebarPanelButton = styled.button`
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  background-color: #3b3c3d;
  border: none;
  border-bottom: ${(props) => props.theme.palette.primary[900]} solid 1px;
  color: #fff;
  text-align: left;
  padding: ${(props) => props.theme.spacing(1)};
  cursor: pointer;
  line-height: 5px;
`;

export const StyledButtonText = styled(Typography)`
  display: inline-block;
  line-height: 14px;
  ${(props) => {
    if (props.hasIcon) {
      return css`
        margin-left: 10px};
      `;
    }
    return css``;
  }}
`;

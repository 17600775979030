import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { usePagination, useSortBy, useTable } from 'react-table';
import { v4 } from 'uuid';
import { Table } from 'components/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortAlphaAsc, faSortAlphaDesc } from '@fortawesome/free-solid-svg-icons';
import { TablePagination } from 'components/TablePagination/TablePagination';
import { ContextMenu } from 'components/ContextMenu';
import { ContextMenuButton } from 'components/ContextMenuButton';
import { ContextMenuList } from 'components/ContextMenuItems';
import { ContextMenuItem } from 'components/ContextMenuItem';
import { WidgetDivider } from 'components/WidgetDivider/WidgetDivider';
import { TopBar } from 'components/TopBar/TopBar';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import { DialogMessage } from 'components/DialogMessage';
import { InfoIcon } from 'components/Icons';
import InspectionService from 'services/InspectionService';
import { setPageFilter, setSearch } from 'slices/projectInspectionReducer';
import { SaveInspection } from '../SaveInspection/SaveInspection';
import { actions, inspectionType } from '../helpers';
import { DeleteInspection } from '../DeleteInspection/DeleteInspection';
import { useStyles } from './styles';

const capitalizeFirstLetter = (text) => text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

const tableColumns = () => [
  {
    Header: 'Title',
    accessor: 'name', // accessor is the "key" in the data
  },
  {
    Header: 'Description',
    accessor: (rowData) => {
      const { description } = rowData;
      return description || 'No Description Found.';
    },
  },
  {
    Header: 'Type',
    accessor: (rowData) => {
      const { type } = rowData;
      return type?.name || '';
    },
  },
  {
    Header: 'Level',
    accessor: (rowData) => {
      const { objectType } = rowData;
      return objectType
        ? capitalizeFirstLetter(
            Object.keys(inspectionType)
              .find((property) => inspectionType[property] === objectType)
              .toLowerCase()
          )
        : '';
    },
  },
  {
    Header: 'Created By',
    accessor: (rowData) => {
      const { uploader } = rowData;
      return uploader ? `${uploader?.firstName} ${uploader?.lastName}` : 'user not found';
    },
  },
  {
    Header: 'Created',
    accessor: (rowData) => {
      const { createdAt } = rowData;
      return createdAt ? moment(createdAt).format('MM/DD/YY') : 'date not found';
    },
  },
];

export const Inspections = ({ type }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const columns = useMemo(() => tableColumns(), []);
  const [action, setAction] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const reducer = type === inspectionType.PROJECT ? 'projectInspections' : 'orgInspections';
  const { data, filters, totalCount } = useSelector((state) => state[reducer]);
  const search = useSelector((state) => state[reducer].filters.search || '');
  const handleSearch = (e) => dispatch(setSearch(e.target.value));
  const { id: projectId } = useSelector((state) => state.projectInspections.panels.project);

  const assignInspection = async (inspection) => {
    try {
      setIsLoading(true);
      const params = {
        ...(type === inspectionType.PROJECT && { projectId }),
        inspectionIds: [inspection.id],
      };
      await InspectionService.assignProject(params);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setOpenDialog('An error occurred while trying to assign inspection to project.');
    }
  };

  const table = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: filters.page - 1,
        pageSize: filters.page_size,
      },
      pageCount: Math.ceil(totalCount / filters.page_size),
      totalCount,
      pageLength: data.length,
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  const {
    state: { pageIndex, pageSize },
  } = table;

  const onCancel = () => {
    setAction(null);
  };

  useEffect(() => {
    dispatch(setPageFilter(pageIndex + 1));
  }, [pageIndex, pageSize]);

  const InspectionActions = ({ row }) => (
    <ContextMenu>
      <ContextMenuButton />
      <ContextMenuList position="bottom-right">
        <ContextMenuItem onClick={() => assignInspection(row.original)}>Apply to All Project Content</ContextMenuItem>
        <ContextMenuItem onClick={() => setAction({ type: actions.UPDATE, inspection: row.original })}>
          Edit Inspection Parameters
        </ContextMenuItem>
        <WidgetDivider />
        <ContextMenuItem onClick={() => setAction({ type: actions.DELETE, inspection: row.original })}>
          Delete Template
        </ContextMenuItem>
      </ContextMenuList>
    </ContextMenu>
  );

  return (
    <>
      <div className={classes.content}>
        <TopBar searchValue={search} handleSearch={handleSearch} activatedFilter={filters.activated} />
        <Table table={table} height>
          <TableHead>
            {table.headerGroups.map((headerGroup) => (
              <TableRow key={v4()} {...headerGroup.getHeaderGroupProps()}>
                <TableCell>Turn On/Off</TableCell>
                {headerGroup.headers.map((column) => (
                  <TableCell key={v4()} {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span style={{ margin: '2px 4px', position: 'absolute' }}>
                      {(() => {
                        if (column.isSorted) {
                          if (column.isSortedDesc) {
                            return <FontAwesomeIcon icon={faSortAlphaDesc} size="sm" />;
                          }
                          return <FontAwesomeIcon icon={faSortAlphaAsc} size="sm" />;
                        }
                        return <FontAwesomeIcon icon={faSort} size="sm" />;
                      })()}
                    </span>
                  </TableCell>
                ))}
                <TableCell align="center">Advanced</TableCell>
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...table.getTableBodyProps()}>
            {table.rows.map((row) => {
              table.prepareRow(row);
              return (
                <TableRow key={v4()}>
                  <TableCell>
                    <SaveInspection action={actions.DISABLED} inspection={row.original} />
                  </TableCell>
                  {row.cells.map((cell) => (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                    <TableCell key={v4()} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </TableCell>
                  ))}
                  <TableCell align="center">
                    <InspectionActions row={row} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination table={table} />
      </div>
      {(action?.type === actions.CREATE || action?.type === actions.UPDATE) && (
        <SaveInspection
          onCancel={onCancel}
          onConfirm={onCancel}
          action={action?.type}
          inspection={action.inspection}
          actionType={type}
          objectId={projectId}
        />
      )}
      {action?.type === actions.DELETE && (
        <DeleteInspection handleClose={onCancel} inspection={{ ...action.inspection, projectId }} actionType={type} />
      )}
      <DialogMessage
        title={openDialog}
        isOpen={!!openDialog}
        icon={InfoIcon}
        confirmText="Ok"
        onConfirm={() => {
          setOpenDialog(false);
        }}
      />
      <LoadingOverlay loading={isLoading} />
    </>
  );
};

Inspections.propTypes = {
  type: PropTypes.string.isRequired,
};

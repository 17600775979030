export class Roles {
  static ACCT_ADMIN = 2;

  static ACCT_CONTENT_ADMIN = 3;

  static ACCT_BILL_ADMIN = 4;

  static ORG_ADMIN = 5;

  static ORG_PROJ_COORD = 6;

  static ORG_TM_COORD = 7;

  static ORG_PART_COORD = 8;

  static ORG_PART = 9;

  static PROJ_ADMIN = 10;

  static PROJ_CONTENT_COORD = 11;

  static PROJ_PART_COORD = 12;

  static PROJ_CNTRB = 13;

  static PROJ_COLLAB = 14;

  static PROJ_VIEW = 15;

  static TEAM_ADMIN = 16;

  static TEAM_PART = 17;
}

export const StringRoles = {
  PROJ_ADMIN: 'proj:admin',
  PROJ_CONTENT_COORD: 'proj:content_coord',
  PROJ_PART_COORD: 'proj:part_coord',
  PROJ_CNTRB: 'proj:cntrb',
  PROJ_COLLAB: 'proj:collab',
  PROJ_VIEW: 'proj:view',
  TEAM_ADMIN: 'team:admin',
  TEAM_PART: 'team:part',
};

export const DescriptionRoles = {
  PROJ_ADMIN:
    'Can edit project details, set project visibility to the organization, control participant coordination, and share UUID of projects to external accounts; upload, edit, copy/share, view, and delete content; create, email, and export observations.',
  PROJ_PART_COORD:
    'Can control participant coordination, and share UUID of projects to external accounts; upload, edit, copy/share, view, and delete content; create, email, and export observations.',
  PROJ_CONTENT_COORD:
    'Can share UUID of projects to external accounts; upload, edit, copy/share, view, and delete content; create, email, and export observations.',
  PROJ_CNTRB:
    'Can upload and view content, and ONLY edit, copy/share, and delete content that they have uploaded; create, email, and export observations.',
  PROJ_COLLAB: 'Can view content; create, email, and export observations.',
  PROJ_VIEW: 'Can only view content.',
};

export const TextRoles = {
  PROJ_ADMIN: 'Administrator',
  PROJ_CONTENT_COORD: 'Content Coordinator',
  PROJ_PART_COORD: 'Participant Coordinator',
  PROJ_CNTRB: 'Contributor',
  PROJ_COLLAB: 'Collaborator',
  PROJ_VIEW: 'Viewer',
  TEAM_ADMIN: 'Administrator',
  TEAM_PART: 'Participant-Only',
};

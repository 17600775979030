import React from 'react';
import { useHistory } from 'react-router-dom';
import { LayersIcon, LockIcon, PlayIconCircle, UsersIcon } from 'components/Icons';
import { WidgetHeader } from 'components/WidgetHeader/WidgetHeader';
import { Button, Grid, Typography } from '@mui/material';
import { useStyles as useAdminStyles } from '../../Admin/Dashboard/styles';

export const QuickActionsWidget = () => {
  const adminClasses = useAdminStyles();
  const history = useHistory();

  const navigateToProjects = () => {
    history.push(`/profile/projects`);
  };

  const navigateToTeams = () => {
    history.push(`/profile/teams`);
  };

  const navigateToContributions = () => {
    history.push(`/profile/content`);
  };

  const navigateToSecurity = () => {
    history.push(`profile/security`);
  };

  return (
    <div className={adminClasses.generalContent}>
      <WidgetHeader title="Quick Actions" subTitle="Get to the details fast." />
      <Grid container spacing={0.5}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Button size="large" color="primary" type="button" fullWidth onClick={navigateToProjects} variant="contained">
            <LayersIcon size={18} />
            <Typography ml={0.5}>Your Projects</Typography>
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Button
            size="large"
            color="primary"
            icon={UsersIcon}
            type="button"
            fullWidth
            onClick={navigateToTeams}
            variant="contained"
          >
            <UsersIcon size={18} />
            <Typography ml={0.5}>Your Team(s)</Typography>
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Button
            size="large"
            color="primary"
            type="button"
            fullWidth
            onClick={navigateToContributions}
            variant="contained"
          >
            <PlayIconCircle size={18} />
            <Typography ml={0.5}>Your Contributions</Typography>
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Button size="large" color="primary" type="button" fullWidth onClick={navigateToSecurity} variant="contained">
            <LockIcon size={18} />
            <Typography ml={0.5}>Change Password</Typography>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

import ApiService from './ApiService';

export default class AdminService {
  static getAdmins = async (filters) => ApiService.get('/account/admins', filters);

  static createAdmin = ({ fname, lname, email, accountName }) => {
    const mappedData = {
      firstName: fname,
      lastName: lname,
      email,
      accountName,
    };
    return ApiService.post('/admin/create-account', mappedData);
  };

  static createTGAdmin = ({ fname, lname, email }) => {
    const mappedData = {
      firstName: fname,
      lastName: lname,
      email,
    };
    return ApiService.post('/admin/create-admin', mappedData);
  };

  static getAccounts = () => ApiService.get('/admin/accounts');

  static switchAccounts = (data) => ApiService.post('/admin/switch-accounts', data);
}

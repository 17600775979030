import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectAssetContent } from 'components/ProjectDetail/ProjectAssets/ProjectAssetContent';
import { ProjectAssetContentContextProvider } from 'components/ProjectDetail/ProjectAssets/ProjectAssetContentContext';
import { SidebarPanel } from 'components/SidebarPanel';
import { clearProjectAsset } from 'slices/profileProjectAssetReducer';
import { useStyles } from './styles';

export const ContentDetail = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { detail } = useSelector((state) => state.content);
  const { projectAsset, isAssetLoading } = useSelector((state) => state.profileProjectAssets);

  useEffect(
    () => () => {
      dispatch(clearProjectAsset());
    },
    []
  );

  return (
    <ProjectAssetContentContextProvider>
      <div className={classes.content}>
        <SidebarPanel
          title={detail.inspectionSection ? detail.name : ''}
          loading={detail.inspectionSection ? isAssetLoading : false}
          detail
          noHeader={!(detail.inspectionSection && isAssetLoading)}
        >
          <ProjectAssetContent
            selectedAsset={detail.inspectionSection ? projectAsset : detail}
            project={detail.inspectionSection ? projectAsset?.project : detail?.project}
            detail
            fullScreen={detail.inspectionSection}
          />
        </SidebarPanel>
      </div>
    </ProjectAssetContentContextProvider>
  );
};

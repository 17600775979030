/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SidebarPanel } from 'components/SidebarPanel';
import { Button, Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Cropper from 'react-cropper';
import { useScreenshot } from 'use-react-screenshot';
import ProjectService from 'services/ProjectService';
import { SidebarContext } from 'components/SidebarProvider/SidebarProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleLeft, faCircleRight } from '@fortawesome/free-solid-svg-icons';
import { setProjAssetFindings, setSelectedSequence } from 'slices/profileProjectAssetReducer';
import { CropperButtons } from 'components/Observation/ObservationModal/CropperButtons';
import { ImgEditor } from 'components/Observation/ImgEditor/ImgEditor';
import { WidgetDivider } from 'components/WidgetDivider/WidgetDivider';
import { Time } from 'lib/time';
import InspectionService from 'services/InspectionService';
import { DialogMessage } from 'components/DialogMessage';
import { InfoIcon } from 'components/Icons';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import { setFindings } from 'slices/findingReducer';
import { HeaderInspectionFinding } from './HeaderInspectionFinding';
import { DefaultForm } from './DefaultForm';
import { CustomForm } from './CustomForm';
import { useStyles } from './styles';
import { writePNGtext } from 'image-metadata-editor';

// Function to add EXIF metadata to an image
const addExifMetadata = async (imageBlob, exifData, type) => {
  try {
    const imageArrayBuffer = await imageBlob.arrayBuffer();
    const imageBuffer = Buffer.from(imageArrayBuffer);

    let updatedImage = imageBuffer;
    await (async () => {
      // eslint-disable-next-line no-restricted-syntax
      for (const key of Object.keys(exifData)) {
        // eslint-disable-next-line no-await-in-loop
        updatedImage = await writePNGtext(updatedImage, key, String(exifData[key]));
      }
    })();

    return new Blob([updatedImage], { type });
  } catch (error) {
    console.error('Error modifying EXIF metadata:', error);
    throw error;
  }
};

// -------------------------- convert image to a blob
const dataURItoBlob = async (dataURI, exifData) => {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }

  const imageBlob = new Blob([ab], { type: mimeString });

  if (!exifData) return Promise.resolve(imageBlob);

  return addExifMetadata(imageBlob, exifData, mimeString)
    .then((blobWithExif) => blobWithExif)
    .catch((error) => {
      console.error('Error adding EXIF metadata:', error);
    });
};

export const SaveProjectAssetInspectionFinding = () => {
  const classes = useStyles();
  const { config, setOpen, handleClose } = useContext(SidebarContext);
  const dispatch = useDispatch();
  const component = config.header.find((item) => item.name === 'inspection');
  const { points } = component.data;
  const { selectedSequence } = component.data;
  const { time } = component.data;
  const { action } = component.data;
  const { detail } = useSelector((state) =>
    action === 'creation' ? state.projectAssetActiveInspections : state.findings
  );
  const [dataUrl, setDataUrl] = useState(null);
  const [finding, setFinding] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [originalImage, setOriginalImage] = useState(null);
  const [editorImageUrl, setEditorImageUrl] = useState({ enable: false, image: null });
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [sequence, setSequence] = useState(null);
  const [index, setIndex] = useState(selectedSequence);
  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });
  const [point, setPoint] = useState(null);
  const [type, setType] = useState(null);
  const cropperEditRef = useRef();
  const cropperRef = useRef();
  const [error, setError] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [map, setMap] = useState(null);
  const editorRef = useRef();
  // eslint-disable-next-line no-unused-vars
  const [image, takeScreenshot] = useScreenshot();
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    projectAsset: { sequences, projId, name },
  } = useSelector((state) => state.profileProjectAssets);
  let questions = JSON.parse(detail.inspection.questions);
  const responses = detail.responses ? JSON.parse(detail.responses) : [];
  const { data } = useSelector((state) => state.findings);
  const {
    projectAsset: { findings: assetFindings },
  } = useSelector((state) => state.profileProjectAssets);
  const [shapeId, setShapeId] = useState(0);
  const [shapeError, setShapeError] = useState('');

  const imageEditorEvents = [
    {
      name: 'addObjectAfter',
      callback: (e) => setShapeId(e.id),
    },
  ];

  const getShapeCoors = () => {
    const properties = editorRef?.current
      ?.getInstance()
      .getObjectProperties(shapeId, ['left', 'top', 'width', 'height', 'strokeWidth']);

    if (!properties) return null;

    return {
      Ymin: properties.top - properties.height / 2,
      Ymax: properties.top + properties.height / 2,
      Xmin: properties.left - properties.width / 2,
      Xmax: properties.left + properties.width / 2,
    };
  };

  const submitFormData = async (values) => {
    if (isImageLoading) {
      return;
    }
    let auxIndex = 0;
    if (action === 'creation') {
      auxIndex = sequence.asset.type === 'VIDEO' ? index : time;
      auxIndex = auxIndex >= sequence?.spatialPoints.length ? sequence?.spatialPoints.length - 1 : auxIndex;
    }
    const body = {
      ...values,
      pasqId: action !== 'creation' ? detail.pasqId : sequence.id,
      type: action !== 'creation' ? detail.type : sequence.asset.type,
      lat: coordinates.lat,
      lng: coordinates.lng,
      time: action !== 'creation' ? +detail.time : auxIndex + 1,
      paspId:
        action !== 'creation'
          ? detail.paspId
          : sequence.asset.type !== 'VIDEO'
          ? sequence?.spatialPoints[0].id
          : point.spatialPointId,
      responses: [],
      projectId: action === 'creation' ? projId : detail.projectAsset?.projId,
    };
    delete body.coordinates;
    if (!body.lat && !body.lng) {
      setError('Coordinates are required');
      return;
    }

    const coorsShape = getShapeCoors();
    if (action === 'creation') {
      if (coorsShape == null) {
        setShapeError('Bounding box is required');
        return;
      }
      setShapeError('');
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const key in body) {
      // eslint-disable-next-line no-prototype-builtins
      if (body.hasOwnProperty(key) && !Number.isNaN(+key)) {
        body.responses.push({ id: key, response: body[key] });
        delete body[key];
      }
    }
    setIsLoading(true);
    try {
      if (action === 'creation') {
        let auxFinding = await InspectionService.createFinding(detail.id, body);
        auxFinding = { ...auxFinding.data.data };
        const blobImage = await dataURItoBlob(
          editorImageUrl.enable
            ? editorRef?.current?.getInstance().toDataURL()
            : await takeScreenshot(cropperEditRef?.current),
          {
            ...coorsShape,
          }
        );
        await ProjectService.uploadObservationPointAsset(auxFinding.signedUrl, blobImage);
        const url = await InspectionService.getFindingImageUrl(auxFinding.paiId, auxFinding.id);
        auxFinding.signedUrl = url.data.data;
        const auxAssetFindings = [...(assetFindings?.length ? assetFindings : [])];
        auxAssetFindings.push(auxFinding);
        dispatch(setProjAssetFindings(auxAssetFindings));
      } else {
        let auxFinding = await InspectionService.updateFinding(detail.paiId, detail.id, body);
        auxFinding = { ...detail, ...auxFinding.data.data };
        const blobImage = await dataURItoBlob(
          editorImageUrl.enable
            ? editorRef?.current?.getInstance().toDataURL()
            : await takeScreenshot(cropperEditRef?.current),
          {
            ...coorsShape,
          }
        );
        await ProjectService.uploadObservationPointAsset(auxFinding.signedUrl, blobImage);
        const url = await InspectionService.getFindingImageUrl(auxFinding.paiId, auxFinding.id);
        auxFinding.signedUrl = url.data.data;
        let findings = [...data];
        findings = findings.map((item) => {
          if (item.id === detail.id) {
            return auxFinding;
          }
          return item;
        });
        dispatch(setFindings(findings));
        let auxAssetFindings = [...(assetFindings?.length ? assetFindings : [])];
        auxAssetFindings = auxAssetFindings.map((item) => {
          if (item.id === detail.id) {
            return auxFinding;
          }
          return item;
        });
        dispatch(setProjAssetFindings(auxAssetFindings));
      }
      setIsLoading(false);
      setOpen(false);
      handleClose();
    } catch (err) {
      setIsLoading(false);
      setOpenDialog('An error occurred while trying to save the inspection finding.');
    }
  };

  // ------------------------ get original image (original.jpg)
  const getOriginalImage = async (auxSequence, element, aseetType, assetId) => {
    const result = await ProjectService.getProjectAssetZoomFrame(
      projId || detail.projectAsset?.projId,
      assetId || auxSequence.assetId,
      element,
      aseetType || auxSequence.asset.type
    );
    const img = URL.createObjectURL(result.data);
    if (action === 'creation') setDataUrl(img);
    setOriginalImage(img);
    setTimeout(() => {
      setIsImageLoading(false);
    }, 1000);
  };

  // ----------------------- get sequence data
  const fetchImage = async (auxSequence, element) => {
    setIsImageLoading(true);
    try {
      if (action === 'creation') {
        getOriginalImage(auxSequence, element);
      } else {
        const auxFinding = auxSequence;
        const result = await InspectionService.getFindingImage(auxFinding.paiId, auxFinding.id);
        setDataUrl(URL.createObjectURL(result.data));
        setTimeout(() => {
          setIsImageLoading(false);
        }, 1000);
      }
    } catch (err) {
      setIsImageLoading(false);
    }
  };

  // ----------------------- show image editor
  const handleClickEditor = async () => {
    setIsImageLoading(true);
    const imgElement = document.querySelector('.cropper-wrap-box')
      ? document.querySelector('.cropper-wrap-box')
      : cropperEditRef?.current;
    const newImage = await dataURItoBlob(await takeScreenshot(imgElement));
    setEditorImageUrl({ enable: true, image: URL.createObjectURL(newImage) });
    setIsImageLoading(false);
  };

  // ------------------ show point on map
  const getPointSequence = (sequenceObj, auxTime) => {
    const auxFinding = type === 'VIDEO' ? { ...points[auxTime] } : { ...sequenceObj.spatialPoints[auxTime] };
    auxFinding.id = 1;
    setPoint(auxFinding);
    auxFinding.lng = auxFinding.longitude;
    auxFinding.lat = auxFinding.latitude;
    setFinding(auxFinding);
  };

  // ------------------------ previous or next arrows
  const getSequence = (direction) => {
    const auxIndex = direction === 'left' ? index - 1 : index + 1;
    setIndex(auxIndex);
    setDataUrl(null);
    setEditorImageUrl({ enable: false });
    setCoordinates({ lat: 0, lng: 0 });
    if (type === 'VIDEO') {
      fetchImage(sequence, auxIndex);
      getPointSequence(sequence, auxIndex);
    } else {
      let auxSequence = points[auxIndex];
      sequences.forEach((item) => {
        if (auxSequence.sequenceId === item.id) {
          auxSequence = { ...item };
        }
      });
      setSequence(auxSequence);
      fetchImage(auxSequence, time);
      dispatch(setSelectedSequence(auxIndex));
      getPointSequence(auxSequence, time);
    }
  };

  useEffect(() => {
    if (action === 'creation') {
      setCoordinates({ lat: 0, lng: 0 });
      let auxSequence = points[selectedSequence];
      sequences.forEach((item) => {
        if (auxSequence.sequenceId === item.id) {
          auxSequence = { ...item };
        }
      });
      const auxTime = auxSequence.asset.type === 'VIDEO' && time === null ? 0 : time;
      setIndex(auxSequence.asset.type === 'VIDEO' ? auxTime : selectedSequence);
      if (auxSequence.asset.type === 'VIDEO') {
        auxSequence.spatialPoints = [...points];
      }
      getPointSequence(auxSequence, auxTime);
      setSequence(auxSequence);
      fetchImage(auxSequence, auxTime);
      setType(auxSequence.asset.type);
    } else {
      fetchImage(detail);
      setPoint(detail.point);
      setFinding(detail);
      setCoordinates({
        lat: !detail.lat ? 0 : detail.lat,
        lng: !detail.lng ? 0 : detail.lng,
      });
    }
  }, []);

  const checkResponses = (id) => responses.find((item) => item.id === id)?.response;

  const removeIfIsEmptyDeleted = () => {
    questions.map((question) => {
      const auxQuestion = { ...question };
      responses.forEach((response) => {
        if (question.deletedAt && response.id === question.id && response.response) {
          delete auxQuestion.deletedAt;
        }
      });
      return auxQuestion;
    });
    return questions;
  };

  const extraValidations = {
    name: Yup.string().min(1).required('Name is required'),
    coordinates: Yup.string().min(1).required('Coordinates are required'),
  };
  const extraValues = {};
  if (questions.length > 0) {
    questions =
      action === 'creation'
        ? questions.filter((item) => !item.deletedAt)
        : removeIfIsEmptyDeleted().filter((item) => !item.deletedAt);
    questions.forEach((element) => {
      extraValues[`${element.id}`] = responses?.length
        ? checkResponses(element.id)
        : element.code === 'checkbox'
        ? []
        : '';
      if (element.required) {
        extraValidations[`${element.id}`] = Yup.string().min(1).required(`${element.name} is required`);
      }
      if (element.required && element.code === 'checkbox') {
        extraValidations[`${element.id}`] = Yup.array()
          .min(1, `${element.name} field must have at least 1 items`)
          .required(`${element.name} is required`);
      }
    });
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: action === 'creation' ? '' : detail.name,
      description: action === 'creation' ? '' : detail.description,
      coordinates: action === 'creation' ? '' : detail.name,
      ...extraValues,
    },
    validationSchema: Yup.object(extraValidations),
    onSubmit(values) {
      submitFormData(values);
    },
  });

  const { handleSubmit } = formik;

  const FormatNumber = (num) => (!num ? 0 : num.toFixed(5));

  // ---------------------- get coordinates
  const getCoordinates = (lat, lng) => {
    setError('');
    formik.setFieldValue('coordinates', 'coordinates');
    setCoordinates({ lat: !lat ? 0 : lat, lng: !lng ? 0 : lng });
  };

  const LatTimeText = () => (
    <div className={classes.latTimeText}>
      <Typography>
        Latitude/Longitude: {FormatNumber(coordinates.lat)} , {FormatNumber(coordinates.lng)}
      </Typography>
      <Typography>
        Timeline Marker:{' '}
        {action === 'creation'
          ? Time.humanReadable({ seconds: sequence?.asset.type === 'VIDEO' ? index + 1 : time + 1 })
          : Time.humanReadable({ seconds: +detail?.time + 1 })}
      </Typography>
    </div>
  );

  return (
    <div className={classes.panelContent}>
      <SidebarPanel
        title="Create Inspection Finding"
        header={
          <HeaderInspectionFinding
            name={name}
            handleSubmit={handleSubmit}
            isImageLoading={isImageLoading}
            loading={isLoading}
          />
        }
      >
        <Grid container spacing={1} sx={{ padding: '1rem' }}>
          <Grid item md={8} lg={8} xl={8}>
            <div className={classes.container}>
              {index > 0 && (
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.playContainer}
                  sx={{ left: '10px' }}
                  type="left"
                  onClick={() => getSequence('left')}
                >
                  <FontAwesomeIcon icon={faCircleLeft} size="1x" />
                </Button>
              )}
              <div
                ref={cropperEditRef}
                style={{ borderRadius: 8, overflow: 'hidden', display: editorImageUrl.enable ? 'none' : 'inherit' }}
              >
                <Cropper
                  src={dataUrl}
                  initialAspectRatio={16 / 9}
                  guides={false}
                  viewMode={3}
                  ref={cropperRef}
                  disabled={isLoading}
                  dragMode="move"
                  autoCropArea="1"
                  highlight={false}
                  cropBoxMovable={false}
                  cropBoxResizable={false}
                  toggleDragModeOnDblclick={false}
                  style={{ width: '100%', height: '50vh', objectFit: 'contain' }}
                />
              </div>
              {!editorImageUrl.enable && (
                <Grid container>
                  <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                    <CropperButtons
                      cropperRef={cropperRef}
                      isImageLoading={isImageLoading}
                      handleClickEditor={handleClickEditor}
                      buttonEditName="Bounding Box"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                    {LatTimeText()}
                  </Grid>
                </Grid>
              )}
              {editorImageUrl.enable && (
                <>
                  <div className={classes.imageEditor}>
                    <ImgEditor
                      features={['shape']}
                      dataUrl={editorImageUrl.image}
                      editorRef={editorRef}
                      customStyles={`
                          .tui-image-editor-container .tui-image-editor-submenu .tui-image-editor-submenu-item .tui-image-editor-button:not(:nth-child(1)) {
                          display: none;
                        }
                      `}
                      onEvents={imageEditorEvents}
                    />
                  </div>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    className="delete-btn"
                    title="Edit Image"
                    onClick={() => setEditorImageUrl({ enable: false })}
                  >
                    Cancel Edition
                  </Button>
                  {LatTimeText()}
                </>
              )}
              <Typography color="error" mb={0.5}>
                {shapeError}
              </Typography>
              {((type === 'PHOTO' && index < sequences.length - 1) ||
                (type === 'VIDEO' && index < sequence.spatialPoints.length - 1)) && (
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.playContainer}
                  sx={{ right: '10px' }}
                  onClick={() => getSequence('right')}
                >
                  <FontAwesomeIcon icon={faCircleRight} size="1x" />
                </Button>
              )}
            </div>
            <WidgetDivider margin color="secondary" />
            <CustomForm formik={formik} questions={questions} action={action} />
          </Grid>
          <DefaultForm
            point={point}
            formik={formik}
            handleGetCoordinates={getCoordinates}
            imgHandler={(view) => setMap(view)}
            error={error}
            finding={finding}
            action={action}
            questions={questions}
          />
        </Grid>
        <DialogMessage
          title={openDialog}
          isOpen={openDialog}
          icon={InfoIcon}
          confirmText="Ok"
          onConfirm={() => {
            setOpenDialog(false);
          }}
        />
        <LoadingOverlay loading={isLoading} />
      </SidebarPanel>
    </div>
  );
};

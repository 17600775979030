import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'components/Avatar';
import { Card } from 'components/Card/Card';
import { ContextMenu } from 'components/ContextMenu';
import { ContextMenuButton } from 'components/ContextMenuButton';
import { ContextMenuList } from 'components/ContextMenuItems';
import { DialogMessage } from 'components/DialogMessage';
import { DialogTeamInviteUser } from 'components/DialogTeamInviteUser';
import { PermGuard } from 'components/Guards/PermGuard';
import { DoubleCheckIcon, InfoIcon } from 'components/Icons';
import { useSidebar } from 'components/SidebarProvider/SidebarProvider';
import { TablePagination } from 'components/TablePagination/TablePagination';
import { TeamsSettingsContainer } from 'components/TeamsSettingsContainer/TeamsSettingsContainer';
import { ViewRoleValidation } from 'components/ViewRoleValidation/ViewRoleValidation';
import { WidgetSplash } from 'components/WidgetSplash/WidgetSplash';
import SplashImage from 'images/accounts_splash.png';
import { Permissions } from 'lib/permissions';
import libPhone from 'libphonenumber-js';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { usePagination, useSortBy, useTable } from 'react-table';
import TeamService from 'services/TeamService';
import { deleteMembers, fetchMember, fetchMembers, setPageFilter, setSearch } from 'slices/teamMembersReducer';
import { ContextMenuItem } from 'components/ContextMenuItem';
import { TopBar } from 'components/TopBar/TopBar';
import { GeneralTable } from 'components/GeneralTable/GeneralTable';
import { useGlobalStyles } from 'styles';
import { Typography } from '@mui/material';
import { InfoPanel } from './InfoPanel';
import { SearchPanel } from './SearchPanel';

let debounceTimeout = null;

const getInitials = (str) => {
  const names = str.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();
  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

const MemberInfo = ({ firstName, lastName }) => {
  const globalClasses = useGlobalStyles();

  return (
    <div className={globalClasses.nameContainer}>
      <Avatar image="" size={30} initials={getInitials(`${firstName} ${lastName}`)} />
      <Typography>
        {firstName} {lastName}
      </Typography>
    </div>
  );
};

MemberInfo.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};

MemberInfo.defaultProps = {
  firstName: '',
  lastName: '',
};

const tableColumns = [
  {
    Header: 'Name',
    accessor: (rowData) => {
      const { firstName, lastName } = rowData;
      return <MemberInfo firstName={firstName} lastName={lastName} />;
    },
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Phone',
    accessor: (rowData) => rowData.phone && libPhone(rowData.phone, 'US').formatNational(),
  },
  {
    Header: 'Role',
    accessor: (rowData) => rowData.roles.map((role) => role.displayName).join(', '),
  },
];

export const TeamsMembers = () => {
  const params = useParams();
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteMemberId, setDeleteMemberId] = useState(null);
  const [openTeamInviteUser, setOpenTeamInviteUser] = useState(false);
  const [teamData, setTeamData] = useState('');
  const [selectedRow, setSelectedRow] = useState('');
  const { membersCount, members, filters } = useSelector((state) => state.teamMembers);
  const dispatch = useDispatch();
  const teamsScopes = [Permissions.TEAM_MODIFY];
  const [validateRole, setValidateRole] = useState({ open: false, callBack: () => {} });
  const search = useSelector((state) => state.teamMembers.filters.search || '');
  const handleSearch = (e) => dispatch(setSearch(e.target.value));

  useEffect(() => {
    const getTeamDetails = async () => {
      const responseTeam = await TeamService.getTeam(params.teamId);
      setTeamData(responseTeam.data.data);
    };
    getTeamDetails();
  }, []);

  const columns = React.useMemo(() => tableColumns, []);

  const table = useTable(
    {
      columns,
      data: members,
      initialState: { pageIndex: filters.page - 1, pageSize: filters.page_size },
      pageCount: Math.ceil(membersCount / filters.page_size),
      totalCount: membersCount,
      pageLength: members.length,
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  const {
    state: { pageIndex, pageSize },
  } = table;

  const { displayPanel, setHeaderPanels } = useSidebar({
    open: false,
    config: {
      search: {
        show: true,
        component: SearchPanel,
      },
      header: [],
    },
  });

  const loadData = () => {
    const sendFilters = {
      ...filters,
      sort_by: Object.values(filters.sort_by),
      teamId: params.teamId,
    };
    dispatch(fetchMembers(sendFilters));
  };

  useEffect(() => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      loadData();
    }, 150);
  }, [filters]);

  useEffect(() => {
    dispatch(setPageFilter(pageIndex + 1));
  }, [pageIndex, pageSize]);

  const handleRowClick = async (rowData) => {
    setSelectedRow(rowData.id);
    dispatch(fetchMember(rowData.original.id));
    setHeaderPanels([
      {
        name: 'info',
        icon: InfoIcon,
        component: InfoPanel,
      },
    ]);
    displayPanel('info');
  };

  const handleDeleteMember = async () => {
    await dispatch(deleteMembers({ memberId: deleteMemberId, teamId: params.teamId, roles: [] }));
    loadData();
    setOpenDelete(false);
  };

  const handleCancelMember = () => {
    setOpenDelete(false);
  };

  const handleTeamInviteUserClose = (refreshFlag) => {
    setOpenTeamInviteUser(false);
    if (refreshFlag) loadData();
  };

  const EmptyTeamMembers = () => (
    <>
      <WidgetSplash
        alt="Team's members Splash"
        title="Looks like you can use some help!"
        image={SplashImage}
        cta="Add Member to Team"
        onClick={() => {
          setOpenTeamInviteUser(true);
        }}
      />
      {openTeamInviteUser && (
        <DialogTeamInviteUser
          isOpen={openTeamInviteUser}
          onCancel={handleTeamInviteUserClose}
          title="User"
          teamId={params.teamId}
        />
      )}
    </>
  );

  const UserActions = ({ row }) => (
    <PermGuard scopes={teamsScopes}>
      <ContextMenu>
        <ContextMenuButton />
        <ContextMenuList position="bottom-right">
          <ContextMenuItem
            onClick={() =>
              setValidateRole({
                open: true,
                action: [Permissions.TEAM_USER_REMOVE],
                data: { id: params.teamId },
                callBack: () => {
                  setOpenDelete(true);
                  setDeleteMemberId(row.original.id);
                },
              })
            }
          >
            Remove Member
          </ContextMenuItem>
        </ContextMenuList>
      </ContextMenu>
    </PermGuard>
  );

  UserActions.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    row: PropTypes.object.isRequired,
  };

  return (
    <TeamsSettingsContainer title={`${teamData.name}'s MEMBERS`} showSidebar>
      <Card noPadding>
        <TopBar searchValue={search} handleSearch={handleSearch} activatedFilter={filters.activated} />
        {members.length > 0 ? (
          <>
            <GeneralTable
              table={table}
              selectedRow={selectedRow}
              handleRowClick={handleRowClick}
              rowComponent={UserActions}
              actionFunction={() => loadData()}
              selectRow={false}
            />
            <TablePagination table={table} />
            <DialogMessage
              title="Are you sure you want remove this member ?"
              icon={DoubleCheckIcon}
              isOpen={openDelete}
              onConfirm={handleDeleteMember}
              onCancel={handleCancelMember}
            />
          </>
        ) : (
          <EmptyTeamMembers />
        )}
      </Card>
      {validateRole.open && (
        <ViewRoleValidation
          data={validateRole.data}
          action={validateRole.action}
          callBack={validateRole.callBack}
          handleClose={() => setValidateRole({ open: false, callBack: () => {} })}
        />
      )}
    </TeamsSettingsContainer>
  );
};

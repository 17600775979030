import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { Form } from 'lib/form';
import * as Yup from 'yup';
import { Switch } from 'components/Switch/Switch';
import { SwitchCase } from 'components/SwitchCase/SwitchCase';
import AuthService from 'services/AuthService';
import { Button, FormControl, Grid, InputLabel, TextField } from '@mui/material';
import { LayoutAuth } from '../../../components/LayoutAuth';

export const PasswordReset = () => {
  const [panel, setPanel] = useState('reset');
  const [loading, setLoading] = useState(false);
  const { userId, resetToken } = useParams();

  const sendRequest = async (password, passwordConfirm) => {
    try {
      setLoading(true);

      const response = await AuthService.passwordReset({ token: resetToken, userId, password, passwordConfirm });
      if (response.data.data.status === 400) {
        setPanel('reset');
        // eslint-disable-next-line no-use-before-define
        formik.setFieldError('password', 'Your new password connot be the same as your current password');
      } else {
        setPanel('success');
      }
    } catch (err) {
      const result = Form.parseApiValidationError(err);

      if (result) {
        // eslint-disable-next-line no-use-before-define
        formik.setErrors(result);
      } else {
        setPanel('expired');
      }
    }

    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirm: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required('Please enter your password')
        .min(8, 'Password must be at least 8 characters long')
        .matches(/^(?=.*[a-z]).{8,}$/, 'Password must contain lowercase letters')
        .matches(/^(?=.*[A-Z]).{8,}$/, 'Password must contain uppercase letters')
        .matches(/^(?=.*\d).{8,}$/, 'Password must contain numbers')
        .matches(
          /^(?=.*[#$^+=!*()@%&]).{8,}$/,
          'Password must contain at least 1 special character: #, $, ^, +, =, !, *, (, ), @, %, &'
        ),
      passwordConfirm: Yup.string()
        .required('Password confirmation is required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    }),
    onSubmit: (values) => {
      sendRequest(values.password, values.passwordConfirm);
    },
  });

  return (
    <Switch value={panel}>
      <SwitchCase name="reset">
        <LayoutAuth
          title="Set new password"
          subTitle="Your new password must be different to previously used passwords."
        >
          <form onSubmit={formik.handleSubmit} noValidate>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <InputLabel disabled={loading}>Password</InputLabel>
                <FormControl fullWidth>
                  <TextField
                    placeholder="Password"
                    type="password"
                    name="password"
                    id="password"
                    value={formik.values.password}
                    {...Form.fieldErrorHelper(formik, 'password')}
                    helperText={formik.touched.password && formik.errors.password ? formik.errors.password : null}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={loading}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <InputLabel disabled={loading}>Confirm Password</InputLabel>
                <FormControl fullWidth>
                  <TextField
                    placeholder="Confirm Password"
                    type="password"
                    name="passwordConfirm"
                    id="passwordConfirm"
                    value={formik.values.passwordConfirm}
                    {...Form.fieldErrorHelper(formik, 'passwordConfirm')}
                    helperText={
                      formik.touched.passwordConfirm && formik.errors.passwordConfirm
                        ? formik.errors.passwordConfirm
                        : null
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={loading}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Button type="submit" color="secondary" variant="contained" disabled={loading} fullWidth>
              Reset Password
            </Button>
          </form>
        </LayoutAuth>
      </SwitchCase>
      <SwitchCase name="success">
        <LayoutAuth
          title="Password reset"
          subTitle="Your password has been successfully reset. Click below to log in magically."
        />
      </SwitchCase>
      <SwitchCase name="expired">
        <LayoutAuth title="Set new password" subTitle="Invalid or expired token." />
      </SwitchCase>
    </Switch>
  );
};

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import { ContextMenuContext } from 'components/ContextMenu';

import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  button: (props) => ({
    color: props.color === 'primary' ? theme.palette.primary[500] : theme.palette.secondary.main,
    '&:hover': {
      color: props.color === 'primary' ? theme.palette.secondary.main : theme.palette.primary.main,
    },
  }),
  active: (props) => ({
    color: props.color === 'primary' ? theme.palette.secondary.main : theme.palette.primary.main,
  }),
});

export const ContextMenuButton = ({ color, size, icon, variant, children, ...rest }) => {
  const classes = useStyles({ color });
  const { buttonRef, toggleMenu, isOpen } = useContext(ContextMenuContext);

  return (
    <IconButton
      variant={variant}
      onClick={(e) => {
        e.stopPropagation();
        toggleMenu();
      }}
      {...rest}
      ref={buttonRef}
      className={`${classes.button} ${isOpen && classes.active}`}
    >
      {!children ? <FontAwesomeIcon icon={icon || faEllipsisVertical} size={size} /> : children}
    </IconButton>
  );
};

ContextMenuButton.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  icon: PropTypes.func,
  variant: PropTypes.string,
  children: PropTypes.node,
};

ContextMenuButton.defaultProps = {
  color: 'primary',
  size: 'sm',
  variant: '',
  icon: null,
  children: '',
};

import localforage from 'localforage';
import { AccountSettingsContainer } from 'components/AccountSettingsContainer/AccountSettingsContainer';
import path from 'path';
import { Loader } from 'components/Loader';
import { ProfileSettingsContainer } from 'components/ProfileSettingsContainer/ProfileSettingsContainer';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import ProjectService from 'services/ProjectService';
import { getProjectAssets, setMapExtent } from 'slices/profileProjectAssetReducer';
import { If } from 'components/If';
import { ViewRoleValidation } from 'components/ViewRoleValidation/ViewRoleValidation';
import { Permissions } from 'lib/permissions';
import PermFormatter from 'lib/permFormatter';
import { updateProjectPermissions } from 'slices/authReducer';
import { setProjectObservations } from 'slices/observationsReducer';
import { CirclePlusIcon, GridIcon, ListIcon } from 'components/Icons';
import { ProjectContent } from './ProjectContent/ProjectContent';

let debounceTimeout = null;

export const SingleProject = () => {
  const [projectData, setProjectData] = useState({ id: null, orgId: null, name: '' });
  const [selectPath, setSelectPath] = React.useState('');
  const [tableToogle, setTableToogle] = useState(true);
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const { data, loading, filters } = useSelector((state) => state.profileProjectAssets);
  const [selectedAsset, setSelectedAsset] = useState({ name: '', id: null });
  const [validateRole, setValidateRole] = useState({ open: false, callBack: () => {} });
  const auth = useSelector((state) => state.auth);

  const fetchData = (filterData) => {
    dispatch(getProjectAssets({ projId: params.projId, filters: filterData }));
  };

  useEffect(() => {
    setSelectPath(location.pathname.split(path.sep)[1]);
    const getProjectName = async () => {
      const response = await ProjectService.getProject(params.projId);
      const { permissions } = auth;
      const projectPermissions = { ...permissions };
      projectPermissions.projects = {
        ...permissions.projects,
        ...PermFormatter.format(response.data.data.permissions),
      };
      dispatch(updateProjectPermissions(projectPermissions.projects));
      dispatch(setProjectObservations({ observations: response.data.data.observations, projectId: params.projId }));
      setProjectData(response.data.data);
    };

    getProjectName();
  }, []);

  useEffect(() => {
    clearTimeout(debounceTimeout);

    debounceTimeout = setTimeout(() => {
      fetchData(filters);
    }, 300);
  }, [filters]);

  const saveData = () => {
    localforage.setItem('data', JSON.stringify(data));
  };
  saveData();

  // ------------------------------------ button actions for the header
  const headerButtons = () => [
    {
      icon: GridIcon,
      color: !tableToogle ? 'secondary' : 'primary',
      variant: 'buttonLeft',
      onClick: () => {
        setTableToogle(false);
      },
    },
    {
      icon: ListIcon,
      color: tableToogle ? 'secondary' : 'primary',
      variant: 'buttonRight',
      onClick: () => {
        setTableToogle(true);
      },
    },
    {
      icon: CirclePlusIcon,
      onClick: () =>
        setValidateRole({
          open: true,
          action: [Permissions.PROJ_UPLOAD_CONTENT],
          callBack: () =>
            history.push(
              // eslint-disable-next-line no-nested-ternary
              selectPath !== 'projects'
                ? `/${selectPath}/${location.pathname.includes('external') ? 'external/projects' : 'projects'}/${
                    params.projId
                  }/content/create`
                : selectPath === 'projects' && !loading
                ? `/projects/${params.projId}/content/create`
                : `/404`
            ),
        }),
    },
  ];

  const HeaderTitle = (type) => (
    <>
      {loading ? (
        <>
          {location.pathname.includes('admin') && 'ADMINISTRATION > '} {type}
        </>
      ) : (
        <>
          {location.pathname.includes('admin') && (
            <>
              <button type="button" onClick={() => history.push('/admin')}>
                ADMINISTRATION
              </button>
              {' > '}
            </>
          )}
          <button type="button" onClick={() => history.goBack()}>
            {type}
          </button>
          {' > '}
          <button type="button" onClick={() => dispatch(setMapExtent([]))}>
            {projectData.name}
          </button>
          {selectedAsset.id ? ' > ' : null} {selectedAsset.name}
        </>
      )}
    </>
  );

  return (
    <>
      <If cond={selectPath === 'profile'}>
        <ProfileSettingsContainer title={HeaderTitle('PROJECTS')} showSidebar actions={headerButtons()} variant="map">
          {loading ? (
            <Loader loading={loading} height={250} />
          ) : (
            <>
              <ProjectContent
                projectData={projectData}
                selectPath={selectPath}
                tableToogle={tableToogle}
                assetData={selectedAsset}
                assetCallBack={(asset) => setSelectedAsset(asset)}
              />
            </>
          )}
        </ProfileSettingsContainer>
      </If>
      <If cond={selectPath === 'admin'}>
        <AccountSettingsContainer
          title={HeaderTitle(location.pathname.includes('external') ? 'EXTERNAL PROJECTS' : 'PROJECTS')}
          actions={headerButtons()}
          showSidebar
          variant="map"
        >
          {loading ? (
            <Loader loading={loading} height={250} />
          ) : (
            <ProjectContent
              projectData={projectData}
              selectPath={selectPath}
              tableToogle={tableToogle}
              assetCallBack={(asset) => setSelectedAsset(asset)}
            />
          )}
        </AccountSettingsContainer>
      </If>
      <If cond={selectPath === 'projects'}>
        <AccountSettingsContainer
          title={HeaderTitle('ALL PROJECTS')}
          actions={headerButtons()}
          showSidebar
          hideMenu
          variant="map"
        >
          {loading ? (
            <Loader loading={loading} height={250} />
          ) : (
            <ProjectContent
              projectData={projectData}
              selectPath={selectPath}
              tableToogle={tableToogle}
              assetCallBack={(asset) => setSelectedAsset(asset)}
            />
          )}
        </AccountSettingsContainer>
      </If>
      {validateRole.open && (
        <ViewRoleValidation
          action={validateRole.action}
          data={projectData}
          callBack={validateRole.callBack}
          handleClose={() => setValidateRole({ open: false, callBack: () => {} })}
        />
      )}
    </>
  );
};

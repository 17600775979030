import { makeStyles } from '@mui/styles';

// eslint-disable-next-line no-unused-vars
export const useStyles = makeStyles((theme) => ({
  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'column',
  },
  header: {
    width: '100%',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerButton: {
    padding: '10px',
    maxWidth: '40px',
    minWidth: '40px',
    maxHeight: '40px',
    minHeight: '40px',
  },
  wrapperButtons: {
    display: 'flex',
    gap: 8,
    flexWrap: 'wrap',
    alignItems: 'center',
    '& button': {
      flex: 1,
    },
  },
  text: {
    display: 'flex',
    gap: '6px',
    alignItems: 'center',
    '& .yellow': {
      color: theme.palette.secondary[600],
    },
  },
}));

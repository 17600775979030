import React, { useContext, useEffect } from 'react';
import { SidebarPanel } from 'components/SidebarPanel';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectActiveInspections, resetInspections } from 'slices/projectActiveInspectionsReducer';
import { getProjectUsers } from 'slices/allProjectReducer';
import { Button, Typography } from '@mui/material';
import { CloseIcon } from 'components/Icons';
import { SidebarDetailContext } from 'components/SidebarProviderDetail/SidebarProviderDetail';
import { getSingleContent } from 'slices/contentAdminReducer';
import { useSidebar } from 'components/SidebarProvider/SidebarProvider';
import { InfoPanel } from 'pages/Admin/Content/InfoPanel';
import { ActiveInspections } from '../ActiveInspections/ActiveInspections';
import { SearchPanel } from './SearchPanel';
import { inspectionType } from '../helpers';
import { useStyles } from './styles';

let debounceTimeout = null;

export const ProjectActiveInspections = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading, data, filters } = useSelector((state) => state.projectActiveInspections);
  const project = useSelector((state) => state.projectActiveInspections.panels?.project);
  const { setOpen, open, handleClose } = useContext(SidebarDetailContext);

  const { displayPanel, setHeaderPanels } = useSidebar({
    open: false,
    config: {
      search: {
        show: true,
        component: SearchPanel,
        background: true,
      },
      header: [],
    },
  });

  // show content clicking on row
  // eslint-disable-next-line no-unused-vars
  const handleRowClick = (rowData) => {
    dispatch(
      getSingleContent({
        projectId: rowData.original.projectAsset?.projId,
        id: rowData.original.projectAsset?.id,
        user: rowData.original.projectAsset?.asset?.user,
      })
    );
    setHeaderPanels([
      {
        name: 'info',
        component: InfoPanel,
      },
    ]);
    displayPanel('info');
  };

  useEffect(() => {
    clearTimeout(debounceTimeout);
    const newFilters = {
      ...filters,
      projectId: project?.id,
      sort_by: Object.values(filters.sort_by),
      include: ['uploader', 'inspection', 'assignment', 'projectAsset', 'progress'],
    };
    debounceTimeout = setTimeout(() => {
      dispatch(getProjectActiveInspections(newFilters));
    }, 300);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [filters]);

  useEffect(() => {
    dispatch(getProjectUsers(project?.id));

    return () => {
      dispatch(resetInspections());
    };
  }, []);

  const Header = () => (
    <div className={classes.header}>
      <div className={classes.title}>
        <div className={classes.text}>
          <Typography variant="h4">{`${project?.name} > `}</Typography>
          <Typography variant="h5" className="yellow">
            Inspections
          </Typography>
        </div>
        <div className={classes.wrapperButtons}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setOpen(!open);
              handleClose();
            }}
            className={classes.headerButton}
          >
            <CloseIcon size={15} />
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <SidebarPanel title="Project Inspections" header={<Header />} detail loading={loading && !data.length}>
      <div className={classes.content}>
        <ActiveInspections type={inspectionType.PROJECT} />
      </div>
    </SidebarPanel>
  );
};

export const inspectionType = {
  ACCOUNT: 'ACCT',
  ORGANIZATION: 'ORG',
  PROJECT: 'PROJ',
  PROJECTASSET: 'PROJASSET',
};

export const actions = {
  CREATE: 'create',
  DELETE: 'delete',
  UPDATE: 'update',
  DISABLED: 'disabled',
  ASSIGN: 'assign',
  VIEW: 'view',
  EXPORT: 'export',
};

export const assignInspectionType = {
  PROJ: 'project',
  INSPECTION: 'inspection',
};

export const assetType = {
  VIDEO: 'VIDEO',
  IMAGE: 'IMAGE',
};

// import localforage from 'localforage';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import FailedImportedAssetService from 'services/FailedImportedAssetService';

export const getFailedContents = createAsyncThunk('failedContents/getFailedContents', async (filterOpts, acctId) => {
  const filtersOpts = {
    ...filterOpts,
    sort_by: Object.values(filterOpts.sort_by),
    include: ['uploader', 'project'],
  };

  try {
    const response = await FailedImportedAssetService.getFailedAssets(filtersOpts, acctId);
    return response.data.data;
  } catch (e) {
    throw new Error('Error during request for content...');
  }
});

export const updateTelemetryCoordinates = async (coords, assetId) => {
  try {
    return await FailedImportedAssetService.updateCoordinates(coords, assetId);
  } catch (e) {
    throw new Error('Error during request for content');
  }
};

const initialState = {
  error: null,
  loading: true,
  data: [],
  panels: {
    loading: true,
    details: [],
    user: [],
  },
  filters: {
    page: 1,
    page_size: 10,
    sort_by: {},
  },
};

const contentSlice = createSlice({
  name: 'failedContent',
  initialState,
  reducers: {
    resetFailedContents: () => initialState,
    setPageFilter(state, action) {
      state.filters.page = action.payload;
    },
    setSearch(state, action) {
      state.filters.search = action.payload;
      state.filters.page = 1;
    },
    setSortBy(state, { payload }) {
      const { label, value } = payload;
      if (value === '' || value === null) {
        delete state.filters.sort_by[label];
      } else {
        state.filters.sort_by[label] = value;
      }
    },
    shareContent(state, action) {
      const oldItem = action.payload;
      const currentItem = state.data.find((item) => item.id === oldItem.id);
      if (!currentItem) {
        state.data = [];
        state.error = 'Content not found.';
      } else {
        currentItem.sharingEnabled = !currentItem.sharingEnabled;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFailedContents.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFailedContents.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.assetCount = 5000; // action.payload.meta.totalCount;
      state.error = null;
      state.loading = false;
    });
    builder.addCase(getFailedContents.rejected, (state) => {
      state.content = [];
      state.error = 'There was an error during the request.';
      state.loading = false;
    });
  },
});

export const {
  setPageFilter,
  contentActions,
  setSearch,
  setSortBy,
  accessContent,
  shareContent,
  copyContent,
  revokeContent,
  resetAdminContents,
} = contentSlice.actions;

export default contentSlice.reducer;

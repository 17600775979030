import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import ExternalService from 'services/ExternalService';
import TeamService from 'services/TeamService';
import UserService from 'services/UserService';
import { formatDateRange, formatSortBy } from './helpers';

export const getExternalProjects = createAsyncThunk('externalProjects/getExternalProjects', async (filters) => {
  const newFilters = {
    strict: true,
    ...filters,
    ...formatSortBy(filters.sort_by),
    ...formatDateRange(filters.date_range),
    include: ['organization', 'collaborators'],
    participantLevel: 'administer',
  };

  delete newFilters.date_range;
  delete newFilters.activated;
  if (newFilters.isOpen === undefined || newFilters.isOpen === null) delete newFilters.isOpen;

  try {
    const response = await ExternalService.getExternalList(newFilters, 'projects');
    return response.data;
  } catch (e) {
    throw new Error('Error during request for projects...');
  }
});

export const preloadExternalFilterDatasets = createAsyncThunk(
  'externalProjects/preloadFilterDatasets',
  async () => {
    try {
      const response = await Promise.all([
        await UserService.getUsers({ page_size: 500 }),
        await TeamService.getTeams({ page_size: 500 }),
        await ExternalService.getProjectAccounts(),
        await ExternalService.getProjectOrganizations(),
      ]);

      return response.map((r) => r.data.data);
    } catch (err) {
      throw new Error('error loading filter data');
    }
  },
  {
    condition: (_, { getState }) => getState().externalProjects.panels.search.loaded === false,
  }
);

const orderByName = (array) =>
  array.sort((a, b) => {
    const nameA = a.label.toUpperCase(); // ignore upper and lowercase
    const nameB = b.label.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

const initialState = {
  error: null,
  loading: false,
  data: [],
  totalCount: 0,
  panels: {
    projId: null,
    loading: true,
    projDetails: {},
    projUsers: [],
    projTeams: [],
    search: {
      loaded: false,
      users: [],
      teams: [],
      accounts: [],
      organizations: [],
    },
  },
  filters: {
    page: 1,
    page_size: 10,
    sort_by: {},
    search: '',
    date_range: null,
    isOpen: null,
  },
};

const projectSlice = createSlice({
  name: 'externalProjects',
  initialState,
  reducers: {
    resetExternalProjects: () => initialState,
    setExternalPageFilter(state, action) {
      state.filters.page = action.payload;
    },
    setExternalSearch(state, action) {
      state.filters.search = action.payload;
    },
    setExternalSortBy(state, { payload }) {
      const { label, value } = payload;

      if (value === '' || value === null) {
        delete state.filters.sort_by[label];
      } else {
        state.filters.sort_by[label] = value;
      }
    },
    setExternalLoading(state, { payload }) {
      state.loading = payload;
    },
    setExternalFilterValue(state, { payload }) {
      const { param, value } = payload;
      if ((value === '' || value === null) && param !== 'date_range') {
        delete state.filters[param];
        return;
      }
      state.filters[param] = value;
    },
    setExternalDateRange(state, { payload }) {
      state.filters.date_range = payload;
    },
    setExternalFilters(state, action) {
      const filter = { ...state.filters, ...action.payload, page: 1 };
      if (!filter?.participantLevel) delete filter?.participantLevel;
      state.filters = filter;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getExternalProjects.pending, (state) => {
      state.loading = true;
      state.panels.userId = null;
    });
    builder.addCase(getExternalProjects.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.totalCount = action.payload.meta.totalCount;
      state.error = null;
      state.loading = false;
    });

    builder.addCase(getExternalProjects.rejected, (state) => {
      state.data = [];
      state.totalCount = 0;
      state.error = 'There was an error during the request.';
      state.loading = false;
    });

    builder.addCase(preloadExternalFilterDatasets.fulfilled, (state, { payload }) => {
      const [users, teams, accounts, organizations] = payload;

      state.panels.search.loaded = true;
      const newTeams = teams.map((team) => ({
        label: team.name,
        value: team.id,
      }));
      state.panels.search.teams = newTeams;
      const newUsers = users.map((user) => ({
        label: `${user.firstName} ${user.lastName}`,
        value: user.id,
      }));
      state.panels.search.users = newUsers;
      let newAccounts = accounts.map((proj) => ({
        label: proj.name,
        value: proj.id,
      }));
      newAccounts = orderByName(newAccounts);
      state.panels.search.accounts = newAccounts;
      let newOrganizations = organizations.map((proj) => ({
        label: proj.name,
        value: proj.id,
      }));
      newOrganizations = orderByName(newOrganizations);
      state.panels.search.organizations = newOrganizations;
    });
  },
});

export const {
  setExternalSearch,
  setExternalSortBy,
  setExternalPageFilter,
  setExternalFilterValue,
  setExternalDateRange,
  setExternalLoading,
  resetExternalProjects,
  setExternalFilters,
  // eslint-disable-next-line prettier/prettier
} = projectSlice.actions;

export const externalDateRangeSelector = createSelector(
  (state) => state.externalProjects.filters,
  (state) => {
    const dates = state.date_range;

    if (!dates) {
      return dates;
    }

    return dates.map((date) => (date ? new Date(date) : null));
  }
);
export default projectSlice.reducer;

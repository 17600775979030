import { PropTypes } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { v4 } from 'uuid';
import { useStyles } from './styles';

export const TablePagination = ({ table, position }) => {
  const classes = useStyles();
  const [showMorePages, setShowMorePages] = useState([]);
  const morePagesRef = useRef(null);

  const calcPageOptions = (options, idx, numVisible = 7) => {
    const sep = '/';
    let result = [];
    if (options.length < numVisible || numVisible < 1) {
      return options;
    }
    const half = Math.floor(numVisible / 2);
    const even = numVisible % 2 === 0 ? 1 : 0;
    const left = half - 1;
    const right = options.length - half + even;
    if (idx > left && idx < right) {
      const start = idx - half + 2;
      const end = idx + half - 1 - even;
      result = [0, sep, ...options.slice(start, end), sep, ...options.slice(-1)];
    } else if (idx === left) {
      const end = idx + half - even;
      result = [...options.slice(0, end), sep, ...options.slice(-1)];
    } else if (idx === right) {
      const start = idx - half + 1;
      result = [0, sep, ...options.slice(start)];
    } else {
      result = [...options.slice(0, half), sep, ...options.slice(right)];
    }
    return result;
  };

  const paginationOpts = calcPageOptions(table.pageOptions, table.state.pageIndex, 10);

  // ------------------------- show the pages in especific space
  const showPages = (index) => {
    const auxShowMorePages = [...showMorePages];
    auxShowMorePages[index] = !auxShowMorePages[index];
    setShowMorePages(auxShowMorePages);
  };

  // ------------------------- calculate the number of more pages
  const createMorePages = (index) => {
    const firstNumber = +paginationOpts[index - 1] + 1;
    const pages = [firstNumber];
    for (let i = firstNumber + 1; i < paginationOpts[index + 1]; i += 1) {
      pages.push(i);
    }
    return pages;
  };

  useEffect(() => {
    // ------------------- hide popup clicking out side div
    function handleClickOutside(event) {
      if (morePagesRef.current && !morePagesRef.current.contains(event.target)) {
        setShowMorePages([]);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [table]);

  return (
    <div className={classes.paginationContent}>
      <div className={classes.text}>
        Showing {table.state.pageIndex * table.state.pageSize + 1}-
        {table.state.pageSize * table.state.pageIndex + table.pageLength} of {table.totalCount} items
      </div>
      <div className={classes.tablePagination} style={{ justifyContent: position }}>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={() => table.previousPage()}
          disabled={!table.canPreviousPage}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </Button>

        <div className={classes.pages}>
          {paginationOpts.map((opt, i) => {
            if (opt === '/') {
              return (
                <Button
                  key={`${v4()}${new Date().getMilliseconds()}`}
                  size="small"
                  color="primary"
                  variant="contained"
                  className={classes.moreButton}
                  onClick={() => showPages(i)}
                >
                  ...
                  {createMorePages(i) && createMorePages(i).length > 0 && showMorePages[i] && (
                    <div className={classes.morePages} ref={morePagesRef}>
                      {createMorePages(i).map((page) => (
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => table.gotoPage(page)}
                          key={page}
                          size="small"
                        >
                          <span style={{ fontSize: 13 }}>{page + 1}</span>
                        </Button>
                      ))}
                    </div>
                  )}
                </Button>
              );
            }

            return (
              <Button
                size="small"
                color={opt === table.state.pageIndex ? 'secondary' : 'primary'}
                onClick={() => table.gotoPage(opt)}
                key={opt}
                variant="contained"
              >
                <span style={{ fontSize: 13 }}>{opt + 1}</span>
              </Button>
            );
          })}
        </div>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={() => table.nextPage()}
          disabled={!table.canNextPage}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </Button>
      </div>
    </div>
  );
};

TablePagination.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  table: PropTypes.object.isRequired,
  position: PropTypes.string,
};

TablePagination.defaultProps = {
  position: 'end',
};

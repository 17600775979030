import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'tui-image-editor/dist/tui-image-editor.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'tui-color-picker/dist/tui-color-picker.css';
import ImageEditor from '@toast-ui/react-image-editor';
import { useStyles } from './style';

const myTheme = {
  'header.display': 'none',
};

export const ImgEditor = ({ editorRef, dataUrl, features, customStyles, onEvents }) => {
  const classes = useStyles();
  useEffect(() => {
    if (editorRef.current && onEvents.length > 0) {
      const inst = editorRef.current.getInstance();
      onEvents.forEach((ev) => {
        inst.on(ev.name, ev.callback);
      });
    }
  }, [dataUrl]);

  return (
    <div className={classes.content}>
      {dataUrl && (
        <>
          <style>{customStyles}</style>
          <ImageEditor
            ref={editorRef}
            includeUI={{
              loadImage: {
                path: dataUrl,
                name: 'SampleImage',
              },
              theme: myTheme,
              menu: features,
              ...(features.length === 1 ? { initMenu: features[0] } : {}),
              uiSize: {
                width: '100%',
                height: '70vh',
              },
              menuBarPosition: 'bottom',
            }}
            // cssMaxHeight={650}
            cssMaxWidth={1000}
            selectionStyle={{
              cornerSize: 20,
              rotatingPointOffset: 70,
            }}
            usageStatistics
          />
        </>
      )}
    </div>
  );
};

ImgEditor.propTypes = {
  editorRef: PropTypes.node,
  dataUrl: PropTypes.string,
  features: PropTypes.arrayOf(PropTypes.string),
  customStyles: PropTypes.string,
  onEvents: PropTypes.arrayOf(PropTypes.object),
};

ImgEditor.defaultProps = {
  editorRef: null,
  dataUrl: '',
  features: ['draw', 'shape', 'icon'],
  customStyles: '',
  onEvents: [],
};

import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const ArrowTopRight = ({ size }) => (
  <Svg viewBox="0 0 512 512" size={size}>
    <path
      fillRule="nonzero"
      d="M165.43.04c-8.92-.6-16.56 6.15-17.06 15.06s6.33 16.62 15.24 17.21l274.42 18.58L4.7 484.23c-6.31 6.3-6.26 16.59.11 22.96 6.38 6.37 16.66 6.42 22.96.12L461.11 73.97l18.58 274.42c.59 8.91 8.3 15.74 17.21 15.24s15.66-8.14 15.06-17.05L490.98 36.62c-.35-8.2-6.88-15.01-15.25-15.57L165.43.04z"
      fill="currentColor"
    />
  </Svg>
);

ArrowTopRight.propTypes = {
  size: PropTypes.number,
};

ArrowTopRight.defaultProps = {
  size: 24,
};

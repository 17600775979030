import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import jwt from 'jsonwebtoken';
import PermFormatter from 'lib/permFormatter';
import AuthService from 'services/AuthService';

export const authenticateCurrentUser = createAsyncThunk('auth/authenticateCurrentUser', async () => {
  try {
    const response = await AuthService.authMe();
    const token = JSON.parse(localStorage.getItem('user'))?.accessToken;
    const decoded = jwt.decode(token, { complete: true });
    return { tgAdmin: decoded.payload.tgAdmin, ...response.data.data };
  } catch (e) {
    throw new Error('An error occurred while authenticating user with token...');
    // TODO: handle err
  }
});

export const userLogin = createAsyncThunk('auth/userLogin', async ({ email, password }) => {
  try {
    const response = await AuthService.login({ email, password });
    const { token } = response.data.data;
    const decoded = jwt.decode(token, { complete: true });
    localStorage.setItem('user', JSON.stringify({ accessToken: token }));
    await authenticateCurrentUser();
    return { tgAdmin: decoded.payload.tgAdmin };
  } catch (e) {
    throw new Error('Invalid username or password...');
    // TODO: handle err
  }
});

export const userLoginAD = createAsyncThunk('auth/userLogin', async ({ accessToken, account }) => {
  try {
    const response = await AuthService.loginAD(accessToken, account);
    const { token } = response.data;
    const decoded = jwt.decode(token, { complete: true });
    localStorage.setItem('user', JSON.stringify({ accessToken: token }));
    await authenticateCurrentUser();
    return { tgAdmin: decoded.payload.tgAdmin };
  } catch (e) {
    throw new Error('Invalid username or password...');
    // TODO: handle err
  }
});

export const getAllProjectGrants = createAsyncThunk('auth/getAllProjectGrants', async () => {
  try {
    const response = await AuthService.getAllProjectGrants();
    return PermFormatter.format(response.data.data);
  } catch (e) {
    throw new Error('An error occurred while fetching project grants...');
    // TODO: handle err
  }
});

export const getAllOrgGrants = createAsyncThunk('auth/getAllOrgGrants', async () => {
  try {
    const response = await AuthService.getAllOrgGrants();
    return PermFormatter.format(response.data.data);
  } catch (e) {
    throw new Error('An error occurred while fetching org grants...');
    // TODO: handle err
  }
});

export const getAllTeamsGrants = createAsyncThunk('auth/getAllTeamsGrants', async () => {
  try {
    const response = await AuthService.getAllTeamsGrants();
    return PermFormatter.format(response.data.data);
  } catch (e) {
    throw new Error('An error occurred while fetching teams grants...');
    // TODO: handle err
  }
});

export const getAccountGrants = createAsyncThunk('auth/getAccountGrants', async () => {
  try {
    const response = await AuthService.getAccountGrants();
    return response.data.data !== undefined ? PermFormatter.format(response.data.data) : {};
  } catch (e) {
    throw new Error('An error occurred while fetching account grants...');
    // TODO: handle err
  }
});

const initialState = {
  userId: null,
  tgAdmin: false,
  error: null,
  fname: null,
  lname: null,
  email: null,
  isActive: null,
  phone: null,
  photo: null,
  description: null,
  permissions: {},
  loginStatus: 'idle',
  authStatus: 'idle',
  userStatus: 'idle',
  projectStatus: 'idle',
  teamsStatus: 'idle',
  orgStatus: 'idle',
  accountStatus: 'idle',
  userPerms: {},
  viewOnlyRole: false,
  organizations: [],
  menu: {
    admin: true,
    profile: true,
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logOut: () => initialState,
    updateUserInfo(state, { payload }) {
      const { phone, description, firstName, lastName } = payload; // , email

      state.fname = firstName;
      state.lname = lastName;
      state.phone = phone;
      state.description = description;
      // state.email = email;
    },
    buildPermsList(state) {
      const userPerms = {};
      Object.keys(state.permissions).forEach((label) =>
        Object.keys(state.permissions[label]).forEach((id) => {
          Object.keys(state.permissions[label][id]).forEach((perm) => {
            userPerms[perm] = true;
          });
        })
      );
      state.permissions.userPerms = userPerms;
    },
    updateProjectPermissions(state, action) {
      state.permissions.projects = action.payload;
    },
    setMenu(state, action) {
      state.menu = { ...state.menu, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogin.pending, (state) => {
      state.loginStatus = 'loading';
    });
    builder.addCase(userLogin.fulfilled, (state, action) => {
      state.error = null;
      state.tgAdmin = action.payload.tgAdmin;
      state.loginStatus = 'succeeded';
      // authStatus needs to be reset in case of previous failures
      state.authStatus = 'idle';
    });
    builder.addCase(userLogin.rejected, (state) => {
      state.error = 'Invalid username or password';
      state.tgAdmin = false;
      state.loginStatus = 'failed';
    });
    builder.addCase(authenticateCurrentUser.pending, (state) => {
      state.authStatus = 'loading';
    });
    builder.addCase(authenticateCurrentUser.fulfilled, (state, action) => {
      state.userId = action.payload.id;
      state.fname = action.payload.firstName;
      state.lname = action.payload.lastName;
      state.tgAdmin = action.payload.tgAdmin;
      state.email = action.payload.email;
      state.isActive = action.payload.isActive;
      state.phone = action.payload.phone;
      state.photo = action.payload.photo;
      state.description = action.payload.description;
      state.error = null;
      state.authStatus = 'succeeded';
      state.viewOnlyRole = action.payload.viewOnlyRole;
      state.organizations = action.payload.organizations;
    });
    builder.addCase(authenticateCurrentUser.rejected, (state) => {
      state.userId = null;
      state.fname = null;
      state.lname = null;
      state.tgAdmin = null;
      state.email = null;
      state.isActive = null;
      state.phone = null;
      state.photo = null;
      state.description = null;
      state.organizations = [];
      state.error = 'An error occurred while authenticating user with token... Please Login again.';
      state.authStatus = 'failed';
    });
    builder.addCase(getAllProjectGrants.pending, (state) => {
      state.projectStatus = 'loading';
    });
    builder.addCase(getAllProjectGrants.fulfilled, (state, action) => {
      state.permissions.projects = action.payload;
      state.error = null;
      state.projectStatus = 'succeeded';
    });
    builder.addCase(getAllProjectGrants.rejected, (state) => {
      state.permissions.projects = [];
      state.error = 'An error occurred while fetching project grants... Please try again.';
      state.projectStatus = 'failed';
    });
    builder.addCase(getAllOrgGrants.pending, (state) => {
      state.orgStatus = 'loading';
    });
    builder.addCase(getAllOrgGrants.fulfilled, (state, action) => {
      state.permissions.orgs = action.payload;
      state.error = null;
      state.orgStatus = 'succeeded';
    });
    builder.addCase(getAllOrgGrants.rejected, (state) => {
      state.permissions.orgs = [];
      state.error = 'An error occurred while fetching org grants... Please try again.';
      state.orgStatus = 'failed';
    });
    builder.addCase(getAllTeamsGrants.pending, (state) => {
      state.teamsStatus = 'loading';
    });
    builder.addCase(getAllTeamsGrants.fulfilled, (state, action) => {
      state.permissions.teams = action.payload;
      state.error = null;
      state.teamsStatus = 'succeeded';
    });
    builder.addCase(getAllTeamsGrants.rejected, (state) => {
      state.permissions.teams = [];
      state.error = 'An error occurred while fetching teams grants... Please Try Again.';
      state.teamsStatus = 'failed';
    });
    builder.addCase(getAccountGrants.pending, (state) => {
      state.accountStatus = 'loading';
    });
    builder.addCase(getAccountGrants.fulfilled, (state, action) => {
      state.permissions.account = action.payload;
      state.error = null;
      state.accountStatus = 'succeeded';
    });
    builder.addCase(getAccountGrants.rejected, (state) => {
      state.permissions.account = [];
      state.error = 'An error occurred while fetching account grants... Please try again.';
      state.accountStatus = 'failed';
    });
  },
});

export const { logOut, updateUserInfo, buildPermsList, updateProjectPermissions, setMenu, setProfileMenu } =
  authSlice.actions;

export default authSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  observations: [],
  observationsFilter: [],
  projectObservations: [],
  projectObservationsFilter: [],
  isLoading: false,
  showObservationDetailFromUrl: true,
  assetFilters: {
    search: '',
  },
  filters: {
    sort_by: {},
    assetId: '',
    activated: false,
  },
  observation: {},
};

const observationsSlice = createSlice({
  name: 'observations',
  initialState,
  reducers: {
    setObservations(state, action) {
      state.observations = action.payload;
      state.observationsFilter = action.payload;
    },
    setProjectObservations(state, action) {
      const auxObservations = action.payload.observations.map((item) => ({
        ...item,
        sequence: item.projectAssetSequence,
        projectId: action.payload.projectId,
      }));
      state.projectObservations = auxObservations;
      state.projectObservationsFilter = auxObservations;
    },
    setObservationsFilter(state, action) {
      state.observationsFilter = action.payload;
    },
    setAssetFilter(state, action) {
      state.assetFilters = action.payload;
    },
    setProjectObservationsFilter(state, action) {
      state.projectObservationsFilter = action.payload;
    },
    addObservation(state, { payload }) {
      const { observations, observationsFilter, projectObservations, projectObservationsFilter } = state;
      // observations
      observations.push(payload);
      state.observations = observations;
      // observations filters
      observationsFilter.push(payload);
      state.observationsFilter = observationsFilter;
      //  project observations filter
      projectObservations.push(payload);
      state.projectObservations = projectObservations;
      //  project observations
      projectObservationsFilter.push(payload);
      state.projectObservationsFilter = projectObservationsFilter;
    },
    deleteObservation(state, action) {
      const { observations, observationsFilter, projectObservations, projectObservationsFilter } = state;
      // observations
      let index = observations.findIndex((element) => element.id === action.payload.id);
      observations.splice(index, 1);
      state.observations = observations;
      // observations filters
      index = observationsFilter.findIndex((element) => element.id === action.payload.id);
      observationsFilter.splice(index, 1);
      state.observationsFilter = observationsFilter;
      //  project observations
      index = projectObservations.findIndex((element) => element.id === action.payload.id);
      projectObservations.splice(index, 1);
      state.projectObservations = projectObservations;
      //  project observations filter
      index = projectObservationsFilter.findIndex((element) => element.id === action.payload.id);
      projectObservationsFilter.splice(index, 1);
      state.projectObservationsFilter = projectObservationsFilter;
    },
    updateObservation(state, action) {
      const { observations, observationsFilter, projectObservations, projectObservationsFilter } = state;
      // observations
      let index = observations.findIndex((element) => element.id === action.payload.id);
      observations[index] = action.payload;
      state.observations = observations;
      // observations filters
      index = observationsFilter.findIndex((element) => element.id === action.payload.id);
      observationsFilter[index] = action.payload;
      state.observationsFilter = observationsFilter;
      //  project observations
      index = projectObservations.findIndex((element) => element.id === action.payload.id);
      projectObservations[index] = action.payload;
      state.projectObservations = projectObservations;
      //  project observations filter
      index = projectObservationsFilter.findIndex((element) => element.id === action.payload.id);
      projectObservationsFilter[index] = action.payload;
      state.projectObservationsFilter = projectObservationsFilter;
    },
    setIsObservationLoading(state, action) {
      state.isLoading = action.payload;
    },
    setShowObservationDetailFromUrl(state, action) {
      state.showObservationDetailFromUrl = action.payload;
    },
    setFilters(state, action) {
      const filter = { ...state.filters, ...action.payload, page: 1 };
      state.filters = filter;
    },
    setObservation(state, action) {
      state.observation = action.payload;
    },
  },
});

// eslint-disable-next-line prettier/prettier
export const {
  setObservations,
  setProjectObservations,
  setObservationsFilter,
  setProjectObservationsFilter,
  setAssetFilter,
  addObservation,
  deleteObservation,
  updateObservation,
  setIsObservationLoading,
  setShowObservationDetailFromUrl,
  setFilters,
  setObservation,
  // eslint-disable-next-line prettier/prettier
} = observationsSlice.actions;
export default observationsSlice.reducer;

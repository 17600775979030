import React from 'react';
import PropTypes from 'prop-types';
import { TrackZoomIcon } from 'components/Icons';
import { Button } from '@mui/material';
import { useStyles } from './style';

export const TrackZoom = ({ type, handleTrackZoomClick, active }) => {
  const classes = useStyles({ type });

  return (
    <Button
      color={active ? 'secondary' : 'primary'}
      variant="contained"
      className={classes.trackZoom}
      onClick={() => handleTrackZoomClick()}
      title="Track zoom"
      size="small"
    >
      <TrackZoomIcon />
    </Button>
  );
};

TrackZoom.propTypes = {
  type: PropTypes.string,
  active: PropTypes.bool,
  handleTrackZoomClick: PropTypes.func,
};

TrackZoom.defaultProps = {
  type: '',
  active: false,
  handleTrackZoomClick: () => {},
};

import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  nav: {
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    gridArea: 'nav',
    minWidth: '250px',
    padding: `${theme.spacing(1)} ${theme.spacing(0.7)}`,
    borderRight: `1px solid ${theme.palette.secondary.main}`,
    overflowY: 'auto',
  },
});

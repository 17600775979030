/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectAssetInspections, resetInspections } from 'slices/projectAssetActiveInspectionReducer';
import { ActiveInspections } from 'components/Inspections/ActiveInspections/ActiveInspections';
import { inspectionType } from 'components/Inspections/helpers';

let debounceTimeout = null;
// eslint-disable-next-line no-unused-vars
export const ProjectAssetInspections = ({ projectAsset }) => {
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.projectAssetActiveInspections);

  const loadData = () => {
    clearTimeout(debounceTimeout);
    const newFilters = {
      ...filters,
      pastId: projectAsset.id,
      projectId: projectAsset.project?.id,
    };
    debounceTimeout = setTimeout(() => {
      dispatch(getProjectAssetInspections(newFilters));
    }, 300);
  };

  useEffect(
    () => () => {
      dispatch(resetInspections());
    },
    []
  );

  useEffect(() => {
    loadData();
  }, [filters]);

  return <ActiveInspections type={inspectionType.PROJECTASSET} />;
};

ProjectAssetInspections.propTypes = {
  projectAsset: PropTypes.object.isRequired,
};

/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormRadioGroup } from 'components/FormRadioGroup';
import { SidebarPanel, cleanObjectFilters } from 'components/SidebarPanel';
import { useDispatch } from 'react-redux';
import { ClearFilterAction } from 'components/SidebarPanel/ClearFilterAction';
import { Button, FormControl, RadioGroup, FormControlLabel, Radio, FormLabel } from '@mui/material';
import { CalendarModal } from 'components/CalendarModal';
import { SearchList } from 'components/SidebarPanel/SearchList/SearchList';

export const searchPanelSection = {
  PROJECTS: 'all_projects',
  PROFILEPROJECTS: 'profile_projects',
  ADMINPROJECTS: 'admin_projects',
  EXTERNALPROJECTS: 'external_projects',
  TEAMPROJECTS: 'team_projects',
};

export const SearchPanelBody = ({
  loaded,
  filters,
  users,
  teams,
  accounts,
  organizations,
  // eslint-disable-next-line no-unused-vars
  setFilterValue,
  setFilters,
  section,
}) => {
  const dispatch = useDispatch();
  const [openCalendar, setOpenCalendar] = useState(false);
  const [filterActions, setFiltersActions] = useState([]);
  const [localFilters, setLocalFilters] = useState({
    sort_by: {},
    acctId: '',
    organizationId: '',
    participantLevel: '',
    date_range: null,
    userId: '',
    teamId: '',
    isOpen: null,
  });

  // update redux filters
  // eslint-disable-next-line no-unused-vars
  const onSearchChange = (value, param) => {
    const filter = {
      param,
      value,
    };
    dispatch(setFilterValue(filter));
  };

  // clear sort
  const clearSort = (param) => {
    const auxFilters = { ...localFilters };
    delete auxFilters.sort_by[param];
    auxFilters.sort_by = cleanObjectFilters(auxFilters.sort_by);
    setLocalFilters(auxFilters);
  };

  // update filters
  const updateFIlter = (auxFilters) => {
    setLocalFilters({ ...auxFilters });
  };

  // update radio buttons
  const updateNormalFilters = (value, type) => {
    const auxFilters = { ...localFilters };
    auxFilters[type] = value;
    setLocalFilters({ ...auxFilters });
  };

  // -------------- clear filters
  const handleClearFilters = () => {
    const auxFilters = { filters };
    if (cleanObjectFilters(auxFilters)?.filters) {
      dispatch(
        setFilters({
          acctId: '',
          organizationId: '',
          date_range: null,
          isOpen: null,
          sort_by: {},
          participantLevel: null,
          teamId: '',
          userId: '',
          activated: false,
        })
      );
    }
  };

  // -------------- apply filters
  const handleApplyFilters = () => {
    const auxFilters = { ...localFilters };
    auxFilters.sort_by = cleanObjectFilters(auxFilters.sort_by);
    dispatch(setFilters({ ...auxFilters, activated: filterActions.length > 0 }));
  };

  const createClearFilters = (clearFilters) => {
    const auxFilters = [];
    if (clearFilters.sort_by?.name) auxFilters.push({ text: 'Sort By Name', callBack: () => clearSort('name') });
    if (clearFilters.sort_by?.createdAt)
      auxFilters.push({ text: 'Sort By Created At', callBack: () => clearSort('createdAt') });
    if (clearFilters.sort_by?.org) auxFilters.push({ text: 'Sort By Org', callBack: () => clearSort('org') });
    if (clearFilters.sort_by?.account)
      auxFilters.push({ text: 'Sort By Account', callBack: () => clearSort('account') });
    if (clearFilters?.organizationId?.length > 0)
      auxFilters.push({ text: 'Clear By Organization', callBack: () => updateNormalFilters(null, 'organizationId') });
    if (clearFilters?.acctId?.length > 0)
      auxFilters.push({ text: 'Clear By Account', callBack: () => updateNormalFilters(null, 'acctId') });
    if (
      clearFilters?.userId?.length > 0 &&
      section !== searchPanelSection.PROFILEPROJECTS &&
      section !== searchPanelSection.TEAMPROJECTS
    )
      auxFilters.push({ text: 'Clear By User', callBack: () => updateNormalFilters(null, 'userId') });
    if (clearFilters?.teamId?.length > 0 && section !== searchPanelSection.TEAMPROJECTS)
      auxFilters.push({ text: 'Clear By Team', callBack: () => updateNormalFilters(null, 'teamId') });
    if (clearFilters.participantLevel)
      auxFilters.push({ text: 'Clear By Role', callBack: () => updateNormalFilters(null, 'participantLevel') });
    if (clearFilters.date_range !== null && clearFilters.date_range)
      auxFilters.push({ text: 'Clear Date Range', callBack: () => updateNormalFilters(null, 'date_range') });
    if (clearFilters.isOpen !== null && clearFilters.isOpen !== undefined)
      auxFilters.push({
        text: `Clear ${clearFilters.isOpen ? 'Open' : 'Closed'}`,
        callBack: () => updateNormalFilters(null, 'isOpen'),
      });
    setFiltersActions(auxFilters);
    return auxFilters;
  };

  useEffect(() => {
    createClearFilters(localFilters);
  }, [localFilters]);

  useEffect(() => {
    createClearFilters(filters);
    setLocalFilters(filters);
  }, [filters]);

  return (
    <SidebarPanel
      title={
        section === searchPanelSection.ADMINPROJECTS
          ? 'Filters: Account Projects'
          : section === searchPanelSection.EXTERNALPROJECTS
          ? 'Filters: External Projects'
          : 'Filters'
      }
      subTitle="Apply filters to table data."
      filters
      handleClearFilters={handleClearFilters}
      handleApplyFilters={handleApplyFilters}
      detail
    >
      <div className="searchBody">
        <ClearFilterAction actions={filterActions} />
        <FormControl>
          <FormLabel>Sort</FormLabel>
          <FormRadioGroup
            name="alphabetical"
            value={localFilters.sort_by.name}
            onChecked={(checkedVal) => {
              const sortBy = { ...localFilters.sort_by };
              sortBy.name = checkedVal;
              updateNormalFilters(sortBy, 'sort_by');
            }}
            options={[
              { label: 'A - Z', value: '+name', disabled: false },
              { label: 'Z - A', value: '-name', disabled: false },
              { label: 'None', value: '', disabled: false },
            ]}
            view="horizontal"
          />
          <FormRadioGroup
            name="date-by"
            value={localFilters.sort_by.createdAt}
            onChecked={(checkedVal) => {
              const sortBy = { ...localFilters.sort_by };
              sortBy.createdAt = checkedVal;
              updateNormalFilters(sortBy, 'sort_by');
            }}
            options={[
              { label: 'Newest', value: '-createdAt', disabled: false },
              { label: 'Oldest', value: '+createdAt', disabled: false },
              { label: 'None', value: '', disabled: false },
            ]}
            view="horizontal"
          />
          <FormRadioGroup
            name="foo-group"
            value={localFilters.sort_by.org}
            onChecked={(checkedVal) => {
              const sortBy = { ...localFilters.sort_by };
              sortBy.org = checkedVal;
              updateNormalFilters(sortBy, 'sort_by');
            }}
            options={[
              { label: 'Sort by Organization', value: 'org', disabled: false, columns: 2 },
              { label: 'None', value: '', disabled: false },
            ]}
            view="horizontal"
          />
          <FormRadioGroup
            name="foo-group"
            value={localFilters.sort_by.account}
            onChecked={(checkedVal) => {
              const sortBy = { ...localFilters.sort_by };
              sortBy.account = checkedVal;
              updateNormalFilters(sortBy, 'sort_by');
            }}
            options={[
              { label: 'Sort by Account', value: 'account', disabled: false, columns: 2 },
              { label: 'None', value: '', disabled: false },
            ]}
            view="horizontal"
          />
        </FormControl>
        <Button color="secondary" variant="contained" onClick={() => setOpenCalendar(true)} className="marginTop">
          By Date Range
        </Button>
        <SearchList
          title={`${section === searchPanelSection.EXTERNALPROJECTS ? 'External ' : ''}Organization`}
          fullList={organizations}
          filter="organizationId"
          localFilters={localFilters}
          onChangeSelect={updateFIlter}
          loaded={loaded}
        />
        <SearchList
          title={`${section === searchPanelSection.EXTERNALPROJECTS ? 'External ' : ''}Account`}
          fullList={accounts}
          filter="acctId"
          localFilters={localFilters}
          onChangeSelect={updateFIlter}
          loaded={loaded}
        />
        {!(section === searchPanelSection.PROFILEPROJECTS || section === searchPanelSection.TEAMPROJECTS) && (
          <SearchList
            title="User"
            fullList={users}
            filter="userId"
            localFilters={localFilters}
            onChangeSelect={updateFIlter}
            loaded={loaded}
          />
        )}
        {section !== searchPanelSection.TEAMPROJECTS && (
          <SearchList
            title="Team"
            fullList={teams}
            filter="teamId"
            localFilters={localFilters}
            onChangeSelect={updateFIlter}
            loaded={loaded}
          />
        )}
        {section !== searchPanelSection.EXTERNALPROJECTS && section !== searchPanelSection.ADMINPROJECTS && (
          <FormControl>
            <FormLabel className="marginTop">Your Role</FormLabel>
            <RadioGroup row>
              <FormControlLabel
                value=""
                onClick={() => updateNormalFilters(null, 'participantLevel')}
                control={<Radio />}
                label="All"
                checked={localFilters.participantLevel === ''}
              />
              <FormControlLabel
                value="participate"
                onClick={() => updateNormalFilters('participate', 'participantLevel')}
                control={<Radio />}
                label="Participant"
                checked={localFilters.participantLevel === 'participate'}
              />
              <FormControlLabel
                value="administer"
                onClick={() => updateNormalFilters('administer', 'participantLevel')}
                control={<Radio />}
                label="Administrator"
                checked={localFilters.participantLevel === 'administer'}
              />
            </RadioGroup>
          </FormControl>
        )}
        <FormControl>
          <FormLabel className="marginTop">Open/Closed</FormLabel>
          <FormRadioGroup
            name="open"
            value={localFilters.isOpen}
            onChecked={(checkedVal) => updateNormalFilters(checkedVal, 'isOpen')}
            options={[
              { label: 'All', value: null },
              { label: 'Open', value: true },
              { label: 'Closed', value: false },
            ]}
            view="horizontal"
          />
        </FormControl>
        {openCalendar && (
          <CalendarModal
            rangeDate={localFilters.date_range}
            handleClose={() => setOpenCalendar(false)}
            handleConfirm={(rangeDate) => {
              if (rangeDate.length > 0 && rangeDate[0].startDate !== null && rangeDate[0].endDate !== null)
                updateNormalFilters([rangeDate[0].startDate, rangeDate[0].endDate], 'date_range');
              setOpenCalendar(false);
            }}
          />
        )}
      </div>
    </SidebarPanel>
  );
};

SearchPanelBody.propTypes = {
  loaded: PropTypes.bool,
  filters: PropTypes.object,
  users: PropTypes.array,
  teams: PropTypes.array,
  accounts: PropTypes.array,
  organizations: PropTypes.array,
  setFilterValue: PropTypes.func,
  setFilters: PropTypes.func,
  section: PropTypes.string,
};

SearchPanelBody.defaultProps = {
  loaded: false,
  filters: null,
  users: [],
  teams: [],
  accounts: [],
  organizations: [],
  setFilterValue: () => {},
  setFilters: () => {},
  section: '',
};

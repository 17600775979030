import React, { useState } from 'react';
import { Prompt } from 'react-router-dom';
import { DialogUnsaved } from 'components/DialogUnsaved';

const UnsavedPrompt = ({ when, show, onConfirm, onCancel }) => {
  const [path, setPath] = useState(null);
  const [showPrompt, setShowPrompt] = useState(false);
  const [open, setOpen] = useState(false);

  const handleConfirm = () => onConfirm();
  const handleCancel = () => onCancel(path);

  return (
    <>
      <Prompt
        when={when && !showPrompt}
        message={(location) => {
          setPath(location.pathname);
          setShowPrompt(true);
          setOpen(true);
          return false;
        }}
      />
      {(showPrompt || show) && (
        <DialogUnsaved
          isOpen={show || open}
          content="Your Inspection progress has not been saved. Would you like to save now?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </>
  );
};

export default UnsavedPrompt;

import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { BarsIcon, ClockIcon } from 'components/Icons';
import { WidgetContent } from 'components/WidgetContent/WidgetContent';
import { WidgetHeader } from 'components/WidgetHeader/WidgetHeader';
import { Time } from 'lib/time';
import { useGlobalStyles } from 'styles';
import { Button, Typography } from '@mui/material';
import { useStyles as useAdminStyles } from '../../Admin/Dashboard/styles';
import { useStyles } from './styles';

export const LastUploadsWidget = ({ uploadsData }) => {
  const classes = useStyles();
  const adminClasses = useAdminStyles();
  const globalClasses = useGlobalStyles();
  const history = useHistory();

  return (
    <div className={`${adminClasses.generalContent} ${adminClasses.fullHeight}`}>
      <WidgetHeader
        title="Recent Contributions"
        subTitle="Your most recent uploads."
        actionIcon={BarsIcon}
        actionTitle="View All"
        actionClick={() => {
          history.push('/profile/content');
        }}
        border
        link
      />
      <WidgetContent>
        {uploadsData.map((item) => (
          <div className={classes.uploadItem} key={item.id}>
            <Button color="primary" variant="contained" className={classes.clockIcon}>
              <ClockIcon size={24} />
            </Button>
            <div>
              <Typography variant="body1">
                {item.type === 'VIDEO' ? Time.humanReadable({ seconds: item.timeLength }) : item.type}
              </Typography>
              <Typography variant="body1" className={globalClasses.greyTypography}>
                {moment(item.createdAt).format('MM/DD/YY')}
              </Typography>
            </div>
            <div className={classes.shortText}>
              <Typography variant="body1" styles={{ flexWwrap: 'nowrap', overFlow: 'hidden' }}>
                {item.name}
              </Typography>
              <Typography variant="body1" className={globalClasses.greyTypography}>
                {item.user && `${item.user.firstName} ${item.user.lastName}`}
              </Typography>
            </div>
            {/* <Sharable size={36} shared /> */}
          </div>
        ))}
      </WidgetContent>
    </div>
  );
};

LastUploadsWidget.propTypes = {
  uploadsData: PropTypes.array.isRequired,
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ProjectService from 'services/ProjectService';

export const createProject = createAsyncThunk('newProject/createProject', async (data) => {
  try {
    await ProjectService.createProject(data);
  } catch (e) {
    throw new Error('Error during request for users...');
  }
});

const initialState = {
  orgId: '',
  name: '',
  description: '',
  isVisible: true,
  isOpen: true,
  isZoomEnabled: true,
  loading: true,
  participants: [],
};

const newProjectReducer = createSlice({
  name: 'new-project',
  initialState,
  reducers: {
    updateIsVisible(state, { payload }) {
      if (payload === true) {
        state.isOpenProject = false;
      }
      state.isVisible = !payload;
    },
    updateIsOpenProject(state, { payload }) {
      state.isOpenProject = payload;
    },
    updateOrgId(state, { payload }) {
      state.orgId = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createProject.fulfilled, (state) => {
      state.orgId = '';
      state.name = '';
      state.description = '';
      state.isVisible = true;
      state.isOpen = true;
      state.isZoomEnabled = true;
      state.loading = false;
    });

    builder.addCase(createProject.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(createProject.rejected, (state) => {
      state.loading = false;
      state.error = 'there was an error creating the Project';
    });
  },
});

export const { updateProjectDetails, updateIsVisible, updateIsOpenProject, updateOrgId } = newProjectReducer.actions;
export default newProjectReducer.reducer;

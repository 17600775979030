import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DialogMessage, dialogMessageAction } from 'components/DialogMessage';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import ProjectService from 'services/ProjectService';
import { ViewRoleValidation } from 'components/ViewRoleValidation/ViewRoleValidation';
import { InfoIcon } from 'components/Icons';

export const DeleteProjectRelation = ({ asset, project, simpleRemove, handleCallBack, handleCancel }) => {
  const [validateRole, setValidateRole] = useState({ open: false, callBack: () => {} });
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // delte a project relation FROM API
  const deleteProject = async () => {
    setOpenConfirm(false);
    setIsLoading(true);
    try {
      await ProjectService.DeleteShareAssetWithProject(
        simpleRemove ? project.id : asset.project?.id,
        asset.id,
        simpleRemove ? project.id : project?.project?.id
      );
      setIsLoading(false);
      handleCallBack(project);
    } catch (error) {
      setIsLoading(false);
      setOpenDialog('An error occurred while trying to delete the project relation.');
    }
  };

  useEffect(() => {
    setOpenConfirm(true);
  }, []);

  return (
    <>
      <DialogMessage
        title={`Remove ${simpleRemove ? asset.name : project?.project?.name}`}
        content={`Are you sure you want to remove this ${
          simpleRemove ? asset.name : project?.project?.name
        }? This action cannot be undone`}
        isOpen={openConfirm}
        onConfirm={deleteProject}
        onCancel={handleCancel}
        action={dialogMessageAction.delete}
      />
      <DialogMessage
        title={openDialog}
        isOpen={openDialog}
        icon={InfoIcon}
        confirmText="Ok"
        onConfirm={() => {
          handleCancel();
          setOpenDialog(false);
        }}
      />
      {validateRole.open && (
        <ViewRoleValidation
          action={validateRole.action}
          data={{ ...asset.project, asset }}
          callBack={validateRole.callBack}
          handleClose={() => {
            setValidateRole({ open: false, callBack: () => {} });
            setOpenDialog(false);
          }}
        />
      )}
      <LoadingOverlay loading={isLoading} />
    </>
  );
};

DeleteProjectRelation.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  asset: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  project: PropTypes.object,
  simpleRemove: PropTypes.bool,
  handleCallBack: PropTypes.func,
  handleCancel: PropTypes.func,
};

DeleteProjectRelation.defaultProps = {
  asset: null,
  project: null,
  simpleRemove: false,
  handleCallBack: () => {},
  handleCancel: () => {},
};

import { CloseIcon, DragIcon } from 'components/Icons';
import { Typography } from 'components/Typography';
import styled from 'styled-components';
import { Table } from '@mui/material';

export const StyledTable = styled(Table)`
  background-color: ${(props) => props.theme.palette.primary.dark};
  margin-bottom: 50px;
`;
// marginBottom: 50, marginLeft: -20, marginRight: -20
export const InlineDiv = styled.div`
  display: inline-block;
`;

export const FlexDiv = styled.div`
  display: flex;
  width: 100%;
`;
export const FooterButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledDropZone = styled.div`
  border: 1px dashed ${(props) => props.theme.palette.secondary.base};
  width: 900px;
  height: 200px;
  border-radius: 5px;
  display: ${(props) => (props.selected !== '' ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  margin-bottom: ${(props) => props.theme.spacing(5)};
`;

export const StyledStoragePanel = styled.div`
  display: ${(props) => (props.selected !== '' ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: ${(props) => props.theme.spacing(2)};
`;

// export const StyledTable = styled.table`
//   display: ${(props) => (props.files.length >= 1 ? 'table' : 'none')};
//   table-layout: auto;
//   width: 100%;
//   margin-bottom: ${(props) => props.theme.spacing(2)};
// `;

export const StyledTR = styled.tr`
  width: 100%;
`;

export const StyledTH = styled.th`
  text-align: left;
  padding-left: 10px;
  padding-top: 5px;
  border: 1px solid ${(props) => props.theme.palette.primary.light};
  padding-bottom: ${(props) => props.theme.spacing(0.5)};
`;

export const StyledTD = styled.td`
  background-color: ${(props) => props.theme.palette.primary[1000]};
  border: 1px solid ${(props) => props.theme.palette.primary[600]};
  vertical-align: middle;
`;

export const StyledInput = styled.input`
  background: transparent;
  border: none;
  color: white;
  padding: ${(props) => props.theme.spacing(0.5)};
  width: 100%;
  flex-grow: 1;
`;

export const StyledSelect = styled.select`
  background: transparent;
  border: none;
  color: white;
  padding: ${(props) => props.theme.spacing(0.5)};
  width: 100%;
  & > * {
    background: ${(props) => props.theme.palette.primary[1000]};
  }
`;

export const StyledDragIcon = styled(DragIcon)`
  color: ${(props) => props.theme.palette.secondary.base};
  margin-left: ${(props) => props.theme.spacing(0.7)};
`;

export const StyledCloseIcon = styled(CloseIcon)`
  border: 1px solid white;
  border-radius: 50%;
  padding: ${(props) => props.theme.spacing(0.2)};
  vertical-align: middle;
  cursor: pointer;
`;

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const CloseTD = styled.td`
  vertical-align: middle;
  width: 31px;
  text-align: center;
  min-width: 31px;
`;

export const StyledTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.primary[500]};
  margin-left: ${(props) => props.theme.spacing(0.7)};
  width: 100%;
`;

export const StyledDescription = styled(Typography)`
  width: 100%;
  overflow-wrap: break-word;
  hyphens: manual;
`;

export const DragDiv = styled.div`
  width: 32px;
  display: 'block';
`;

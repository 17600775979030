export const addExternalType = {
  ADD_USER_TO_PROJ: 'proj:add_user',
  ADD_TEAM_TO_PROJ: 'proj:add_team',
  ADD_PROJ_TO_TEAM: 'team:add_project',
  ADD_USER_TO_TEAM: 'team:add_user',
};

export const externalDataType = {
  ASSET: 'asset',
  USER: 'user',
  PROJ: 'project',
  TEAM: 'team',
};

export const externalActionType = {
  REMOVE: 'remove',
  ADD: 'add',
  COPY: 'copy',
  COPY_ASSET: 'copy_asset',
  SHARE_ASSET: 'share_asset',
};

export const externalGrants = {
  ASSET: 'ASSET',
  USER: 'USER',
  PROJ: 'PROJ',
  TEAM: 'TEAM',
};

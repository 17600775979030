import React from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { FormRadioGroup } from 'components/FormRadioGroup';
import { Roles } from 'lib/roles';
import { FormControl, FormHelperText } from '@mui/material';

export const validation = Yup.object().shape({
  id: Yup.string().required('Team is required'),
  role: Yup.number().required('Role is required'),
});

export const roles = (values) => [+values.role];

export const validateOptions = (values) => {
  if (values.role < Roles.TEAM_ADMIN || values.role > Roles.TEAM_PART) return true;
  return false;
};

const options = () => [
  { label: 'Team Administrator', value: Roles.TEAM_ADMIN },
  { label: 'Participant-Only', value: Roles.TEAM_PART },
];

export const UserTeamRoleOptions = ({ formik, checkBoxValidation, handleChange }) => (
  <>
    <FormRadioGroup
      name="role"
      options={options()}
      onChecked={(value) => handleChange('role', `${value}`)}
      value={+formik.values.role}
    />
    {checkBoxValidation ||
      (!formik.values.role && formik.touched.role && (
        <FormControl error={checkBoxValidation || (!formik.values.role && formik.touched.role)} fullWidth>
          <FormHelperText>Role is required</FormHelperText>
        </FormControl>
      ))}
  </>
);

UserTeamRoleOptions.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.any,
  handleChange: PropTypes.func,
  checkBoxValidation: PropTypes.bool,
};

UserTeamRoleOptions.defaultProps = {
  formik: null,
  handleChange: () => {},
  checkBoxValidation: false,
};

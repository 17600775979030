import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { BarsIcon } from 'components/Icons';
import { WidgetContent } from 'components/WidgetContent/WidgetContent';
import { WidgetHeader } from 'components/WidgetHeader/WidgetHeader';
import ProjectService from 'services/ProjectService';
import { Typography } from '@mui/material';
import { useGlobalStyles } from 'styles';
import { Avatar } from 'components/Avatar';
import { getInitials } from 'lib/generalFunctions';
import { useStyles as useAdminStyles } from '../../Admin/Dashboard/styles';

export const ActiveProjectsWidget = () => {
  const adminClasses = useAdminStyles();
  const globalClasses = useGlobalStyles();
  const params = useParams();
  const history = useHistory();
  const [teamProjectsData, setTeamProjectsData] = useState([]);
  const navigateToTeamProjects = () => {
    history.push(`/teams/${params.teamId}/projects`);
  };

  useEffect(() => {
    const getTeamDetails = async () => {
      const responseTeamProjects = await ProjectService.getProjects({ teamId: params.teamId, sort_by: '-createdAt' });
      setTeamProjectsData(responseTeamProjects.data.data);
    };
    getTeamDetails();
  }, []);

  return (
    <div className={adminClasses.generalContent}>
      <WidgetHeader
        title="Most Active Projects"
        subTitle="Projects you participate in the most."
        link
        actionIcon={BarsIcon}
        actionTitle="View All"
        border
        actionClick={() => {
          navigateToTeamProjects();
        }}
      />
      <WidgetContent>
        {teamProjectsData.map((project) => (
          <div className={globalClasses.nameContainer} style={{ marginTop: '10px', justifyContent: 'space-between' }}>
            <div className={globalClasses.nameContainer}>
              <Avatar key={project.id} initials={getInitials(project.name)} size={40} />
              <div className={adminClasses.shortText}>
                <Typography>{project.name}</Typography>
                <Typography className={globalClasses.greyTypography}>{project.description}</Typography>
              </div>
            </div>
            <Typography variant="body" as="div" spacing={0.5}>
              {project.fileHostedCount} Uploads
            </Typography>
          </div>
        ))}
      </WidgetContent>
    </div>
  );
};

import { Loader } from 'components/Loader/Loader';
import PropTypes from 'prop-types';
import React from 'react';
import { useStyles } from './styles';

export const LoadingOverlay = ({ loading }) => {
  const classes = useStyles();

  return loading ? (
    <div className={classes.loader}>
      <Loader loading height={50} />
    </div>
  ) : null;
};

LoadingOverlay.propTypes = {
  loading: PropTypes.bool.isRequired,
};

import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const ObservationIcon = ({ size }) => (
  <Svg viewBox="0 0 1200 1200" size={size}>
    <path
      d="m600 0c-78.793 0-156.82 15.52-229.61 45.672s-138.94 74.348-194.65 130.07c-55.719 55.715-99.914 121.86-130.07 194.65s-45.672 150.82-45.672 229.61c0 159.13 63.215 311.74 175.74 424.26 112.52 112.52 265.13 175.73 424.27 175.73 159.13 0 311.74-63.215 424.26-175.74 112.52-112.52 175.74-265.13 175.74-424.26 0-159.13-63.215-311.74-175.73-424.27-112.52-112.52-265.13-175.74-424.26-175.74zm0 67.762c69.121 0 137.56 13.766 201.42 40.516 63.859 26.746 121.88 65.953 170.76 115.38s87.645 108.1 114.09 172.67c26.453 64.574 40.066 133.79 40.066 203.68 0 141.16-55.457 276.53-154.16 376.34-98.707 99.816-232.58 155.89-372.18 155.89-69.121 0-137.57-13.766-201.43-40.516-63.855-26.746-121.88-65.949-170.76-115.37-48.875-49.422-87.645-108.1-114.1-172.67-26.449-64.574-40.066-133.79-40.066-203.68 0-69.898 13.613-139.11 40.066-203.68 26.449-64.574 65.219-123.25 114.1-172.68 48.875-49.422 106.9-88.625 170.76-115.38 63.859-26.746 132.3-40.512 201.43-40.512z"
      fill="currentColor"
    />
    <path
      d="m909.02 853.4c0 53.727-160.23 78.168-309.02 78.168-148.79 0-309.02-24.441-309.02-78.168 0-32.117 57.387-53.844 135.32-66.008 62.582 72.266 135.32 120.56 173.7 120.56s111.11-48.293 173.7-120.56c77.938 12.16 135.32 33.887 135.32 66.004z"
      fill="currentColor"
    />
    <path
      d="m600 366.91c-43.844 0-85.891 17.418-116.89 48.418-31.004 31.004-48.422 73.051-48.422 116.89s17.418 85.891 48.422 116.89c31 31.004 73.047 48.418 116.89 48.418s85.895-17.414 116.89-48.418c31.004-31.004 48.418-73.051 48.418-116.89-0.046875-43.828-17.48-85.848-48.469-116.84-30.992-30.992-73.012-48.422-116.84-48.473zm-7.9102 55.496v0.003906c5.4805 0 10.738 2.1758 14.613 6.0508s6.0508 9.1328 6.0508 14.613-2.1758 10.734-6.0508 14.609-9.1328 6.0547-14.613 6.0547c-5.4805 0-10.734-2.1797-14.609-6.0547-3.8789-3.875-6.0547-9.1289-6.0547-14.609 0.007813-5.4805 2.1836-10.734 6.0586-14.605 3.875-3.875 9.1289-6.0547 14.605-6.0586zm47.23 231.32h-78.641c-6.4727-0.0625-11.688-5.332-11.688-11.805 0-6.4766 5.2148-11.746 11.688-11.809h27.516v-117.84h-19.602c-6.5234 0-11.809-5.2891-11.809-11.809 0-6.5234 5.2852-11.809 11.809-11.809h23.496c5.2266 0.007812 10.238 2.0898 13.934 5.7852 3.6953 3.6953 5.7773 8.707 5.7852 13.934v121.74h27.516c6.4727 0.0625 11.688 5.332 11.688 11.809 0 6.4727-5.2148 11.742-11.688 11.805z"
      fill="currentColor"
    />
    <path
      d="m600 268.43c-69.41-0.13672-136.04 27.305-185.21 76.285-49.18 48.984-76.895 115.5-77.039 184.91 0 182.29 204.09 354.71 262.25 354.71 56.352 0 262.26-170.25 262.26-354.71-0.082031-45.945-12.262-91.059-35.312-130.8-23.051-39.746-56.16-72.719-96-95.609-39.836-22.891-85-34.887-130.95-34.781zm0 452.72c-50.105 0-98.16-19.906-133.59-55.336-35.434-35.43-55.336-83.484-55.336-133.59 0-50.105 19.902-98.16 55.336-133.59 35.43-35.434 83.484-55.336 133.59-55.336 50.109 0 98.164 19.902 133.59 55.336 35.43 35.43 55.336 83.484 55.336 133.59-0.066407 50.086-19.992 98.105-55.41 133.52-35.414 35.418-83.43 55.344-133.52 55.41z"
      fill="currentColor"
    />
  </Svg>
);

ObservationIcon.propTypes = {
  size: PropTypes.number,
};

ObservationIcon.defaultProps = {
  size: 24,
};

import React from 'react';
import PropTypes from 'prop-types';
import { FormRadioGroup } from 'components/FormRadioGroup/FormRadioGroup';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { ModalHeaderIcon } from 'components/ModalHeaderIcon/ModalHeaderIcon';
import { PlayIconCircle } from 'components/Icons';
import { WidgetDivider } from 'components/WidgetDivider/WidgetDivider';
import { Avatar } from 'components/Avatar';
import { useStyles } from './styles';

export const ModalRevokeAccess = ({ onSubmit, onCancel, collaborators, isOpen }) => {
  const classes = useStyles();
  const [val, setVal] = React.useState(true);

  return (
    <Dialog open={isOpen} maxWidth="sm" onClose={onCancel} e onSubmit={onSubmit}>
      <DialogTitle>
        <ModalHeaderIcon icon={PlayIconCircle} />
        <Typography variant="h4" gutterBottom>
          Revoke Access: Asset Name
        </Typography>
        <Typography variant="subtitle1">Added on Aug 20 2020</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h5" mt={0.5} mb={0.5}>
          Projects Sharing this Item
        </Typography>

        <div className={classes.avatarRow}>
          {collaborators.slice(0, 3).map((user) => (
            <Avatar image={user.image} initials={user.initials} size={28} />
          ))}
          {collaborators.length <= 12 ? (
            collaborators.length > 3 && <Avatar initials={`+${collaborators.length - 3}`} size={28} />
          ) : (
            <Avatar initials="+" size={28} />
          )}
        </div>
        <WidgetDivider color="secondary" margin />

        {/* Bottom Container */}
        <Typography variant="h5" mt={1} mb={1}>
          Are you sure you want to turn off sharing for this asset?
        </Typography>
        <Typography variant="subtitle1" mt={1} mb={1}>
          Important!
        </Typography>

        <Typography variant="subtitle1">
          Linked Projects:
          <br />
          There are projects currently linking to this item. Would you like to extend an opportunity to those project
          coordinators to make their own copies? The system will generate a note to alert them to the sharing change.
          You will not be charged for new instances.
        </Typography>
        <div className={classes.radioGroupContainer}>
          <form>
            <FormRadioGroup
              name="val"
              value={val}
              onChecked={(checkedVal) => {
                setVal(checkedVal);
              }}
              options={[
                { label: 'Yes, extend the invitation.', value: true, disabled: false },
                { label: 'No, stop sharing now.', value: false, disabled: false },
              ]}
              view="horizontal"
            />
          </form>
        </div>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button color="primary" variant="contained" onClick={onCancel} size="large" fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button color="secondary" variant="contained" onClick={onSubmit} size="large" fullWidth>
              Save
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

ModalRevokeAccess.propTypes = {
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  collaborators: PropTypes.arrayOf.isRequired,
};

ModalRevokeAccess.defaultProps = {
  isOpen: false,
};

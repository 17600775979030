import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const QuestionIcon = ({ size, className }) => (
  <Svg viewBox="0 0 24 24" size={size} classname={className}>
    <path
      d="M16 6.016V4H7.984v2.016H16zm0 3V7H7.984v2.016H16zm-3 3V10H7.984v2.016H13zm-6.984-6V4H4v2.016h2.016zm0 3V7H4v2.016h2.016zm0 3V10H4v2.016h2.016zm12-12c.53 0 .984.203 1.359.609.406.375.61.828.61 1.36v12c0 .53-.204 1-.61 1.406-.375.406-.828.609-1.36.609H4L.016 19.984v-18c0-.53.187-.984.562-1.359.406-.406.875-.61 1.406-.61h16.032z"
      fill="currentColor"
    />
  </Svg>
);

QuestionIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
};

QuestionIcon.defaultProps = {
  size: 24,
  className: '',
};

import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const ArrowUpIcon = ({ size, className, onClick }) => (
  <Svg viewBox="0 0 24 24" size={size} className={className} onClick={onClick}>
    <path
      d="M.707 11.293a1 1 0 0 0 0 1.414l.586.586a1 1 0 0 0 1.414 0L10.5 5.5V23a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V5.5l7.793 7.793a1 1 0 0 0 1.414 0l.586-.586a1 1 0 0 0 0-1.414L12.707.707a1 1 0 0 0-1.414 0L.707 11.293Z"
      fill="currentColor"
    />
  </Svg>
);

ArrowUpIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

ArrowUpIcon.defaultProps = {
  size: 24,
  className: '',
  onClick: () => {},
};

export const getExtension = (fileName) => fileName.split('.').pop().toLowerCase();
export const getNameNoExtension = (fileName) => fileName.split('.')[0].toLowerCase();

export const checkIsImage = (fileName) => {
  const ext = getExtension(fileName);
  switch (ext.toLowerCase()) {
    case 'jpg':
    case 'jpeg':
    case 'tif':
    case 'raw':
    case 'png':
      return true;
    default:
      return false;
  }
};

export const checkIsVideo = (fileName) => {
  const ext = getExtension(fileName);
  switch (ext.toLowerCase()) {
    case 'mov':
    case 'mp4':
      return true;
    default:
      return false;
  }
};

export const checkIsTelemetry = (fileName) => {
  const ext = getExtension(fileName);
  switch (ext.toLowerCase()) {
    case 'srt':
    case 'gpx':
    case 'csv':
      return true;
    default:
      return false;
  }
};

export const checkTelemetryCamera = (fileName, cameraTypes) => {
  const ext = getExtension(fileName).toUpperCase();
  const cam = cameraTypes.find((camera) => camera.description.includes(ext));
  return cam ? cam.value : 0;
};

export const isTelemetry = (fileName, telemetryName) =>
  getNameNoExtension(fileName) === getNameNoExtension(telemetryName);

import { Button } from 'components/Button/Button';
import { DialogMessage } from 'components/DialogMessage/DialogMessage';
import { ChevronDownIcon, ChevronUpIcon } from 'components/Icons';
import { TrashIcon } from 'components/Icons/TrashIcon';
import { If } from 'components/If/If';
import { Portal } from 'components/Portal/Portal';
import { Typography } from 'components/Typography/Typography';
import { UploadStatusFileItem } from 'components/UploadStatusFileItem/UploadStatusFileItem';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Transition } from 'react-transition-group';
import ProjectService from 'services/ProjectService';
import {
  getProjectAssets,
  updateUploadQueue,
  UPLOAD_DELETE_FILE,
  deleteAsset,
  setFilter,
  setUploadQueue,
} from 'slices/profileProjectAssetReducer';
import {
  StyledButtonContainer,
  StyledUploadFileContainer,
  StyledUploadFileCounter,
  StyledUploadFileScroller,
  StyledUploadHeader,
  StyledUploadStatusContainer,
} from './styles';

const portalRoot = document.getElementById('portalRoot');

export const UploadStatusWidget = () => {
  const [showConfirmCancelAll, setShowConfirmCancelAll] = useState(false);
  const [showConfirmCancelSingle, setShowConfirmCancelSingle] = useState(false);
  const dispatch = useDispatch();
  const { uploadQueue, filters } = useSelector((state) => state.profileProjectAssets);
  const [cancelAssetDetails, setCancelAssetDetails] = useState({});
  const [open, setOpen] = useState(true);
  /**
   * Cancel the upload via redux and in the api
   *
   * @param {string} assetId
   * @param {string} projId
   */
  const cancelUploadById = async (assetId, projId, name) => {
    dispatch(
      updateUploadQueue({
        type: UPLOAD_DELETE_FILE,
        id: assetId,
        projId: `${projId}`,
      })
    );

    const projAssets = await ProjectService.getProjectAssets(`${projId}`, filters);
    projAssets.data.data.map((item) => {
      if (item.name === name) {
        dispatch(deleteAsset({ projectId: projId, assetId: `${item.id}` }));
      }
      return true;
    });
  };

  /**
   * Cancel a single file upload
   *
   * @param {string} projId
   * @param {string} assetId
   * @returns
   */
  const handleCancelSingleUpload = (fileToCancel) => {
    const { projId, id, name } = fileToCancel;

    cancelUploadById(id, projId, name);
    setCancelAssetDetails({ assetId: id, projId, name: `${name}` });
    setShowConfirmCancelSingle(true);

    // no need to confirm if the upload is complete, just clear it from the stack and return early.
  };

  /**
   * Display the confirmation dialog for canelling a single asset upload
   */
  const handleConfirmCancelSingleUpload = async () => {
    const { assetId, projId, name } = cancelAssetDetails;

    cancelUploadById(assetId, projId, name);
    setShowConfirmCancelSingle(false);
    setCancelAssetDetails({});
    dispatch(getProjectAssets({ projId: `${projId}`, filters }));
  };

  /**
   * Delete cancelling all asset uploads
   */
  const handleCancelAll = () => {
    uploadQueue.forEach((file) => cancelUploadById(file.id, file.projId, file.name));
  };

  const allUploadsComplete = uploadQueue.every((file) => file.uploadPercentComplete >= 100);

  return (
    <Portal container={portalRoot}>
      <Transition in={uploadQueue.length > 0} timeout={{ enter: 100, exit: 300 }} unmountOnExit mountOnEnter>
        <StyledUploadStatusContainer>
          <StyledUploadHeader>
            Upload File Status
            <Button kind="secondary" size="small" style={{ float: 'right' }} onClick={() => setOpen(!open)}>
              {open === false ? <ChevronUpIcon size={12} /> : <ChevronDownIcon size={12} />}
            </Button>
          </StyledUploadHeader>
          {open && (
            <>
              <StyledUploadFileContainer>
                <StyledUploadFileScroller>
                  {uploadQueue.map((file) => (
                    <UploadStatusFileItem
                      key={file.id}
                      onCancel={() => handleCancelSingleUpload(file)}
                      progress={file.uploadPercentComplete}
                    >
                      {file.name}
                    </UploadStatusFileItem>
                  ))}
                </StyledUploadFileScroller>
              </StyledUploadFileContainer>
              <StyledUploadFileCounter>
                <Typography variant="small">
                  Uploads Complete ({uploadQueue.filter((file) => file.uploadPercentComplete >= 100).length}/
                  {uploadQueue.length})
                </Typography>
              </StyledUploadFileCounter>
              <StyledButtonContainer>
                <If cond={!allUploadsComplete}>
                  <Button fullWidth onClick={() => setShowConfirmCancelAll(true)}>
                    Cancel All
                  </Button>
                </If>
                <If cond={allUploadsComplete}>
                  <Button
                    onClick={() => {
                      dispatch(setUploadQueue([]));
                      dispatch(setFilter({ ...filters }));
                    }}
                  >
                    Clear Completed
                  </Button>{' '}
                  <Button
                    onClick={() => {
                      setOpen(false);
                      dispatch(setUploadQueue([]));
                      dispatch(setFilter({ ...filters }));
                    }}
                  >
                    Close
                  </Button>
                </If>
              </StyledButtonContainer>
            </>
          )}

          <DialogMessage
            icon={TrashIcon}
            isOpen={showConfirmCancelAll}
            title="Are you sure you want to cancel all uploads?"
            confirmText="Yes, Cancel Upload(s)"
            onCancel={() => {
              setShowConfirmCancelAll(false);
            }}
            onConfirm={() => {
              handleCancelAll();
              setShowConfirmCancelAll(false);
            }}
          />

          <DialogMessage
            icon={TrashIcon}
            isOpen={showConfirmCancelSingle}
            title="Are you sure you want to cancel this upload?"
            confirmText="Yes, Cancel Upload"
            onCancel={() => {
              setShowConfirmCancelSingle(false);
            }}
            onConfirm={() => {
              handleConfirmCancelSingleUpload();
              setShowConfirmCancelSingle(false);
            }}
          />
        </StyledUploadStatusContainer>
      </Transition>
    </Portal>
  );
};

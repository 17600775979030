import moment from 'moment';

export const formatDateRange = (dateRange) => {
  if (dateRange === null || dateRange.length === 0) {
    return {};
  }

  const dateFormatter = (date) => moment(date).format('YYYY-MM-DD');

  const [start, end] = dateRange;

  if (!start && !end) {
    return {};
  }

  if (start && !end) {
    return {
      createdAt: {
        eq: dateFormatter(start),
      },
    };
  }

  return {
    createdAt: {
      gte: dateFormatter(start),
      lte: dateFormatter(end),
    },
  };
};

export const formatSortBy = (sortBy) => {
  const values = Object.values(sortBy);

  if (values.length === 0) {
    return {};
  }

  return {
    sort_by: values,
  };
};

export const formatGeo = (geos) => {
  if (geos.length <= 0) return {};
  let minLat = 999;
  let maxLat = -999;
  let minLon = 999;
  let maxLon = -999;
  geos.map(({ lat, long }) => {
    if (lat < minLat) minLat = lat;
    if (lat > maxLat) maxLat = lat;
    if (long < minLon) minLon = long;
    if (long > maxLon) maxLon = long;
    return `${lat},${long}`;
  });
  return {
    geo: [`${maxLat},${minLon}`, `${maxLat},${minLon}`, `${maxLat},${maxLon}`, `${minLat},${minLon}`],
  };
};

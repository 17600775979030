/* eslint no-undef: 0 */
// disabled no-undef because the code uses a global variable that cannot be assigned
import React from 'react';
import PropTypes from 'prop-types';
import { If } from 'components/If/If';
// import { Button } from 'components/Button';
import { StyleDriveIcon, GoogleButton } from './styles';

import useInjectScript from './injectJSLibrary';

// let scriptLoadingStarted = false;

export const GooglePicker = ({
  viewId,
  scopes,
  // origin,
  onCallback,
  onAuthenticate,
  onAuthFailed,
  multiselect,
  // navHidden,
  disabled,
  mini,
  authExist,
}) => {
  const [loaded, error] = useInjectScript('https://apis.google.com/js/api.js');
  const [loadedGsi, errorGsi] = useInjectScript('https://accounts.google.com/gsi/client');
  const [pickerApiLoaded, setpickerApiLoaded] = React.useState(false);
  // const [openAfterAuth, setOpenAfterAuth] = React.useState(false);
  // const [authWindowVisible, setAuthWindowVisible] = React.useState(false);
  const [authRes, setAuthRes] = React.useState();
  const [expiredIn, setExpiredIn] = React.useState(false);

  // // use effect to open picker after auth
  // React.useEffect(() => {
  //   if (
  //     openAfterAuth &&
  //     config.token &&
  //     loaded &&
  //     !error &&
  //     loadedGsi &&
  //     !errorGsi &&
  //     pickerApiLoaded
  //   ) {
  //     createPicker(config)
  //     setOpenAfterAuth(false)
  //   }
  // }, [
  //   openAfterAuth,
  //   config.token,
  //   loaded,
  //   error,
  //   loadedGsi,
  //   errorGsi,
  //   pickerApiLoaded,
  // ])

  const pickerCallback = (data) => {
    // console.log(data);
    if (data.action === 'picked') {
      onCallback(data, authRes);
    }
  };

  const createPicker = (token) => {
    if (disabled) return false;

    const view = new google.picker.DocsView(google.picker.ViewId[viewId]);
    view.setIncludeFolders(true);
    // if (viewMimeTypes) view.setMimeTypes(viewMimeTypes)
    // if (setIncludeFolders) view.setSelectFolderEnabled(true)
    // if (setSelectFolderEnabled) view.setSelectFolderEnabled(true)

    // const uploadView = new google.picker.DocsUploadView()
    // if (viewMimeTypes) uploadView.setMimeTypes(viewMimeTypes)
    // if (showUploadFolders) uploadView.setIncludeFolders(true)
    // if (setParentFolder) uploadView.setParent(setParentFolder)
    // if (setParentFolder) view.setParent(setParentFolder)
    const picker = new google.picker.PickerBuilder()
      .setAppId('')
      .setOAuthToken(token)
      .setDeveloperKey(process.env.REACT_APP_GCP_DEVELOPER_TOKEN)
      .setLocale('en')
      .setCallback(pickerCallback);

    picker.addView(view);

    // if (customViews) {
    //   customViews.map((view) => picker.addView(view))
    // }

    if (multiselect) {
      picker.enableFeature(google.picker.Feature.MULTISELECT_ENABLED);
    }

    // if (showUploadView) picker.addView(uploadView)

    // if (supportDrives) {
    //   picker.enableFeature(google.picker.Feature.SUPPORT_DRIVES)
    // }

    picker.build().setVisible(true);
    return true;
  };

  const openPicker = () => {
    // global scope given conf
    // setConfig(config)

    // if we didnt get token generate token.
    if (expiredIn && Math.floor(Date.now() / 1000) < expiredIn) {
      createPicker(authRes.access_token);
    } else {
      const client = google.accounts.oauth2.initTokenClient({
        client_id: process.env.REACT_APP_GCP_CLIENT_ID,
        scope: scopes.join(' '),
        callback: (tokenResponse) => {
          if (tokenResponse.error) {
            onAuthFailed(tokenResponse.error);
          } else {
            tokenResponse.expirationDate = Math.floor(Date.now() / 1000) + tokenResponse.expires_in;
            setAuthRes(tokenResponse);
            setExpiredIn(tokenResponse.expirationDate);
            onAuthenticate(tokenResponse);
            createPicker(tokenResponse.access_token);
          }
        },
        error_callback: (err) => {
          onAuthFailed(err);
        },
      });

      client.requestAccessToken();
    }

    // if we have token and everything is loaded open the picker
    // if (config.token && loaded && !error && pickerApiLoaded) {
    //   return createPicker(config)
    // }
  };

  const onPickerApiLoad = () => {
    setpickerApiLoaded(true);
  };

  // load the Drive picker api
  const loadApis = () => {
    window.gapi.load('auth');
    window.gapi.load('picker', { callback: onPickerApiLoad });
  };

  // get the apis from googleapis
  React.useEffect(() => {
    if (authExist) {
      setExpiredIn(authExist.expirationDate);
      setAuthRes(authExist);
    }
    if (loaded && !error && loadedGsi && !errorGsi && !pickerApiLoaded && !mini) {
      loadApis();
    }
  }, [loaded, error, loadedGsi, errorGsi, pickerApiLoaded]);

  return (
    <GoogleButton
      kind="secondary"
      type="button"
      style={mini ? { marginTop: 0, marginLeft: 10 } : {}}
      onClick={() => openPicker()}
    >
      <StyleDriveIcon size={24} />
      <If cond={!mini}> Google Drive </If>
    </GoogleButton>
  );
};

GooglePicker.propTypes = {
  viewId: PropTypes.string,
  scopes: PropTypes.array,
  // origin: PropTypes.string,
  onCallback: PropTypes.func,
  onAuthenticate: PropTypes.func,
  onAuthFailed: PropTypes.func,
  multiselect: PropTypes.bool,
  // navHidden: PropTypes.bool,
  disabled: PropTypes.bool,
  mini: PropTypes.bool,
  authExist: PropTypes.any,
};

GooglePicker.defaultProps = {
  onCallback: () => {},
  onAuthenticate: () => {},
  onAuthFailed: () => {},
  scopes: ['https://www.googleapis.com/auth/drive.file', 'https://www.googleapis.com/auth/drive.readonly'],
  viewId: 'DOCS',
  multiselect: true,
  // navHidden: false,
  disabled: false,
  mini: false,
  authExist: false,
};

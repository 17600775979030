import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { ModalHeaderIcon } from 'components/ModalHeaderIcon/ModalHeaderIcon';
import { LayersIcon } from 'components/Icons';
import { WidgetDivider } from 'components/WidgetDivider/WidgetDivider';
import ProjectService from 'services/ProjectService';
import { ConfirmButton } from './ConfirmButton';
import { ProjectVisibilityForm } from './ProjectVisibilityForm';
import { ProjectSharingForm } from './ProjectSharingForm';

const validationSchema = Yup.object({
  isVisible: Yup.boolean(),
  isOpenProject: Yup.boolean(),
  isSharingNotAllowed: Yup.boolean(),
  isCopyingNotAllowed: Yup.boolean(),
});

export const ModalProjectSettings = ({ handleClose, handleOpen, projectId, isOpenModal }) => {
  const [loading, setLoading] = React.useState(false);
  const [projectLoading, setProjectLoading] = React.useState(true);
  const [project, setProject] = React.useState({});
  const [submitError, setSubmitError] = React.useState('');
  const fetchProject = useCallback(async () => {
    const projectResults = await ProjectService.getProject(projectId);
    setProject(projectResults.data.data);
    setProjectLoading(false);
  }, [projectId]);

  useEffect(() => {
    fetchProject();
  }, []);

  const formik = useFormik({
    initialValues: {
      isVisible: project.isVisible,
      isOpenProject: project.isOpen,
      isSharingNotAllowed: project.isSharingNotAllowed,
      isCopyingNotAllowed: project.isCopyingNotAllowed,
    },
    validationSchema,
    enableReinitialize: true,
  });

  if (projectLoading) {
    return null;
  }

  return (
    <Dialog open={isOpenModal} maxWidth="xs" onClose={handleClose}>
      <DialogTitle>
        <ModalHeaderIcon icon={LayersIcon} />
        <Typography variant="h4">Advanced Project Settings</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography mb={0.5}>Project Visibility</Typography>
        <ProjectVisibilityForm formik={formik} loading={loading} />
        <WidgetDivider color="secondary" margin />

        {/* Content Sharing & Copying Container */}
        <Typography mb={0.5}>Content Sharing &amp; Copying</Typography>
        <ProjectSharingForm formik={formik} loading={loading} />
        {submitError && (
          <Typography color="error" mt={0.5}>
            {submitError}.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button color="primary" variant="contained" onClick={handleClose} disabled={loading} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <ConfirmButton
              values={formik.values}
              projectId={projectId}
              setLoading={(value) => setLoading(value)}
              setSubmitError={(value) => setSubmitError(value)}
              handleOpen={(value) => handleOpen(value)}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

ModalProjectSettings.propTypes = {
  handleOpen: PropTypes.func,
  handleClose: PropTypes.func,
  isOpenModal: PropTypes.bool,
  projectId: PropTypes.string.isRequired,
};

ModalProjectSettings.defaultProps = {
  isOpenModal: false,
  handleOpen: null,
  handleClose: null,
};

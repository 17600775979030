import React from 'react';
import { AccountSettingsContainer } from 'components/AccountSettingsContainer/AccountSettingsContainer';
import { AdminHeaderTitle } from 'components/AdminHeaderTitle/AdminHeaderTitle';
import { Grid } from '@mui/material';
import { AppRoleWidget } from './AppRoleWidget';
import { SSOSettingsWidget } from './SettingsWidget';
import { useStyles } from './styles';

export const SSO = () => {
  const classes = useStyles();

  return (
    <AccountSettingsContainer
      title={<AdminHeaderTitle buttonName="ADMINISTRATION" section="SSO" />}
      actions={[]}
      showSidebar
    >
      <Grid container spacing={0} className={classes.content}>
        <SSOSettingsWidget />
        <AppRoleWidget />
      </Grid>
    </AccountSettingsContainer>
  );
};

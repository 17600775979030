import styled, { css } from 'styled-components';

export const StyledMap = styled.div`
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;

  .esri-view-width-xsmall .esri-expand__mask--expanded {
    background-color: unset;
  }

  .esri-view-width-xsmall .esri-expand__container--expanded {
    position: relative;
  }
`;

export const StyledContainer = styled.div`
  position: relative;
  ${(props) => {
    if (props.videoView) {
      return css`
        height: ${props.sequenceLength > 1 ? '91%' : '100%'};
        padding-left: 10px;
      `;
    }
    return css`
      height: ${props.sequenceLength > 1 ? '91%' : '100%'};
      width: 100%;
    `;
  }}
`;

import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { WidgetDivider } from 'components/WidgetDivider/WidgetDivider';
import { WidgetItem } from 'components/WidgetItem/WidgetItem';
import { EditIcon } from 'components/Icons';
import AccountService from 'services/AccountService';
import { Button, Checkbox, FormControlLabel, Grid, InputLabel, Switch, TextField } from '@mui/material';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStyles } from './styles';

export const SSOSettingsWidget = () => {
  const classes = useStyles({ flex: true });
  // const history = useHistory();
  const [active, setActive] = useState(false);
  const [allowed, setAllowed] = useState(false);
  const [editable, setEditable] = useState(true);
  const [initialValues, setInitialValues] = useState(false);

  const validationSchema = Yup.object({
    appId: Yup.string().required('Application ID is required'),
    objId: Yup.string().required('Object ID is required'),
    secret: Yup.string().required('Secret ID is required'),
    endpoint: Yup.string().required('Tenant ID is required'),
  });

  const formik = useFormik({
    initialValues: {
      appId: initialValues ? initialValues.applicationId : '',
      objId: initialValues ? initialValues.objectId : '',
      secret: initialValues ? initialValues.secretId : '',
      endpoint: initialValues ? initialValues.tenantId : '',
    },
    enableReinitialize: true,
    validationSchema,
  });

  useEffect(() => {
    AccountService.getSettings().then((result) => {
      setInitialValues(result.data);
      setActive(result.data.active);
      setAllowed(result.data.allowedPassword);
      setEditable(false);
    });
  }, []);

  const handleCheckedChange = ({ checked }) => {
    if (editable) {
      setActive(checked);
    }
  };

  const handleAllowChange = ({ checked }) => {
    if (editable) {
      setAllowed(checked);
    }
  };

  const handleCancel = () => {
    setEditable(!editable);
    formik.resetForm();
  };

  const saveValues = () => {
    const settings = {
      active,
      allowedPassword: allowed,
      applicationId: formik.values.appId,
      objectId: formik.values.objId,
      secretId: formik.values.secret,
      tenantId: formik.values.endpoint,
    };
    AccountService.updateSettings(settings).then(() => {
      setInitialValues(settings);
      setEditable(false);
    });
  };

  return (
    <Grid item lg={4} md={4} sm={12} xs={12} className={classes.firstSection}>
      <div className={classes.sectionContent}>
        <div className={classes.formContent}>
          <WidgetItem title="Azure AD SSO" subHeader="" />
          <WidgetDivider margin />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Switch color="secondary" />}
                disabled={!editable}
                label="Active"
                value={1}
                onChange={(event) => handleCheckedChange(event.target)}
                checked={active}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel disabled={!editable}>Application (client) ID *</InputLabel>
              <TextField
                placeholder=""
                value={formik.values.appId}
                error={!!(formik.touched.appId && formik.errors.appId)}
                helperText={formik.touched.appId && formik.errors.appId ? formik.errors.appId : null}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
                name="appId"
                id="appId"
                disabled={!editable}
                required
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel disabled={!editable}>Object ID *</InputLabel>
              <TextField
                placeholder=""
                value={formik.values.objId}
                error={!!(formik.touched.objId && formik.errors.objId)}
                helperText={formik.touched.objId && formik.errors.objId ? formik.errors.objId : null}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
                name="objId"
                id="objId"
                required
                disabled={!editable}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel disabled={!editable}>Tenant ID *</InputLabel>
              <TextField
                placeholder=""
                value={formik.values.endpoint}
                error={!!(formik.touched.endpoint && formik.errors.endpoint)}
                helperText={formik.touched.endpoint && formik.errors.endpoint ? formik.errors.endpoint : null}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
                name="endpoint"
                id="endpoint"
                required
                disabled={!editable}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel disabled={!editable}>Secret ID value *</InputLabel>
              <TextField
                placeholder=""
                value={formik.values.secret}
                error={!!(formik.touched.secret && formik.errors.secret)}
                helperText={formik.touched.secret && formik.errors.secret ? formik.errors.secret : null}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="password"
                name="secret"
                id="secret"
                required
                disabled={!editable}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox color="secondary" />}
                label="Allow password authentication"
                name="allowed"
                value={0}
                onChange={(e) => handleAllowChange(e.target)}
                disabled={!editable}
                checked={allowed}
              />
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={1}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Button
              icon={EditIcon}
              color="primary"
              variant="contained"
              onClick={() => handleCancel()}
              title="Edit SSO"
              size="large"
              fullWidth
            >
              {!editable && <FontAwesomeIcon icon={faPencil} style={{ marginRight: 5 }} size="lg" />}
              {editable ? 'Cancel' : 'Edit SSO'}
            </Button>
          </Grid>
          {editable && (
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Button
                color="secondary"
                variant="contained"
                title="Save"
                onClick={() => saveValues()}
                size="large"
                fullWidth
              >
                Save
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    </Grid>
  );
};

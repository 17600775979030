import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Tab, Tabs, Typography } from '@mui/material';
import { CloseIcon } from 'components/Icons';
import { SidebarDetailContext } from 'components/SidebarProviderDetail/SidebarProviderDetail';
import { useProjectAssetContentContext } from 'components/ProjectDetail/ProjectAssets/ProjectAssetContentContext';
import { useStyles } from './styles';

export const ProjectHeaderDetail = ({ data, tab, loading, setTabValue }) => {
  const { setOpen, open, handleClose } = useContext(SidebarDetailContext);
  const { unsavedChanges, setOpenUnsavedDialog, setContinueAction } = useProjectAssetContentContext();
  const classes = useStyles();

  const handleOpenTab = (index) => {
    if (unsavedChanges) {
      setOpenUnsavedDialog(true);
      setContinueAction(() => () => setTabValue(index));
    } else {
      setTabValue(index);
    }
  };

  const handleCloseAction = () => {
    setOpen(!open);
    handleClose();
  };

  const handleCloseProject = () => {
    if (unsavedChanges) {
      setOpenUnsavedDialog(true);
      setContinueAction(() => handleCloseAction);
    } else {
      handleCloseAction();
    }
  };

  return (
    <div className={classes.header}>
      <div className={classes.title}>
        <Typography variant="h1">{data.name}</Typography>
        <Button color="primary" variant="contained" onClick={handleCloseProject} className={classes.headerButton}>
          <CloseIcon size={15} />
        </Button>
      </div>
      <Tabs value={tab} className={classes.headerTabs}>
        <Tab label="Overview" onClick={() => handleOpenTab(0)} disabled={loading} />
        <Tab label="Content" onClick={() => handleOpenTab(1)} disabled={loading} />
        <Tab label="Observations" onClick={() => handleOpenTab(2)} disabled={loading} />
        <Tab label="Participants" onClick={() => handleOpenTab(3)} disabled={loading} />
        <Tab label="GIS/Maps" onClick={() => handleOpenTab(4)} disabled={loading} />
        <Tab label="Active Inspections" onClick={() => handleOpenTab(5)} disabled={loading} />
        <Tab label="Delete Pending" onClick={() => handleOpenTab(6)} disabled={loading} />
        <Tab label="Action Required" onClick={() => handleOpenTab(7)} disabled={loading} />
        <Tab label="Settings" onClick={() => handleOpenTab(8)} disabled={loading} />
      </Tabs>
    </div>
  );
};

ProjectHeaderDetail.propTypes = {
  data: PropTypes.object,
  tab: PropTypes.number,
  loading: PropTypes.bool,
  setTabValue: PropTypes.func,
};

ProjectHeaderDetail.defaultProps = {
  data: { id: null, name: '', loading: false },
  tab: 0,
  loading: false,
  setTabValue: () => {},
};

/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { AccountSettingsContainer } from 'components/AccountSettingsContainer/AccountSettingsContainer';
import { AddUuid } from 'components/AddUuid/AddUuid';
import { AdminHeaderTitle } from 'components/AdminHeaderTitle/AdminHeaderTitle';
import { Card } from 'components/Card/Card';
import { CirclePlusIcon } from 'components/Icons';
import { Loader } from 'components/Loader';
import { useSidebar } from 'components/SidebarProvider/SidebarProvider';
import { TablePagination } from 'components/TablePagination/TablePagination';
import { WidgetSplash } from 'components/WidgetSplash/WidgetSplash';
import SplashImage from 'images/ContentSplash.png';
import { Bytes } from 'lib/bytes';
import { usePagination, useSortBy, useTable } from 'react-table';
import { getProject, getProjects, setPageFilter, setSearch as setInternalSearch } from 'slices/adminProjectReducer';
import { getProject as getProjectDetail } from 'slices/allProjectReducer';
import { setAssetsData, setMapExtent } from 'slices/profileProjectAssetReducer';
import { ModalProjectNew } from 'smartComponents/ModalProjectNew';
import { getExternalProjects, setExternalPageFilter, setExternalSearch } from 'slices/externalProjectReducer';
import { externalActionType, externalDataType } from 'lib/external-data';
import { ProjectActions } from 'components/ProjectActions/ProjectActions';
import { GeneralTable } from 'components/GeneralTable/GeneralTable';
import { TopBar } from 'components/TopBar/TopBar';
import { externalProjectsOptions, projectOptions } from 'lib/topBarOptions';
import { useSidebarDetail } from 'components/SidebarProviderDetail/SidebarProviderDetail';
import { ProjectDetail } from 'components/ProjectDetail/ProjectDetail';
import { setProject } from 'slices/projectInspectionReducer';
import { ProjectInspections } from 'components/Inspections/ProjectInspections/ProjectInspections';
import { setProject as setProjectActive } from 'slices/projectActiveInspectionsReducer';
import { ProjectActiveInspections } from 'components/Inspections/ProjectInspections/ProjectActiveInspections';
import { AdminPanel } from './AdminPanel/AdminPanel';
import { InfoPanel } from './InfoPanel';
import { SearchPanel } from './SearchPanel';

const tableColumns = (external) => [
  {
    Header: 'Name',
    accessor: (rowData) => (external ? rowData.project.name : rowData.name),
  },
  {
    Header: 'Description',
    accessor: (rowData) => {
      const { description } = external ? rowData.project : rowData;
      return description || 'No Description Found.';
    },
  },
  ...(!external
    ? [
        {
          Header: 'Account',
          accessor: (rowData) => {
            const { account } = rowData;
            return account ? account.name : '';
          },
        },
      ]
    : []),
  {
    Header: external ? 'Lending Account' : 'Organization',
    accessor: (rowData) => {
      if (external) {
        return rowData.project?.account && rowData.project?.account?.name;
      }
      return rowData.organization && rowData.organization.name;
    },
  },
  {
    Header: 'Size',
    accessor: (rowData) => {
      const { fileTotalSize } = external ? rowData.project : rowData;
      return Bytes.dynamicHumanReadable({ bytes: fileTotalSize });
    },
  },
  {
    Header: 'Files',
    accessor: (rowData) => {
      const { fileHostedCount } = external ? rowData.project : rowData;
      return fileHostedCount;
    },
  },
  ...(!external
    ? [
        {
          Header: 'Observations',
          accessor: 'observationsCount',
        },
      ]
    : []),
  {
    Header: 'Collaborators',
    accessor: (rowData) => {
      const { collaborators } = external ? rowData.project : rowData;
      return collaborators?.length || 0;
    },
  },
];
let debounceTimeout = null;

export const Projects = ({ external }) => {
  const dispatch = useDispatch();
  const [openProjectNew, setOpenProjectNew] = React.useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  const { loading, data, filters, totalCount } = useSelector(
    (state) => state[external ? 'externalProjects' : 'adminProjects']
  );
  const [openUuid, setOpenUuid] = useState(false);
  const columns = React.useMemo(() => tableColumns(external), []);

  useEffect(() => {
    dispatch(external ? setExternalSearch() : setInternalSearch());
  }, []);

  const loadData = () => {
    const sendFilters = {
      ...filters,
      sort_by: Object.values(filters.sort_by),
    };
    dispatch(external ? getExternalProjects(sendFilters) : getProjects(sendFilters));
  };

  useEffect(() => {
    clearTimeout(debounceTimeout);

    debounceTimeout = setTimeout(() => {
      dispatch(external ? getExternalProjects(filters) : getProjects(filters));
    }, 300);
    dispatch(setMapExtent([]));
    dispatch(setAssetsData([]));
  }, [filters]);

  const handleConfirmProjectNew = () => {
    loadData();
    setOpenProjectNew(false);
  };

  const handleCancelProjectNew = () => {
    setOpenProjectNew(false);
  };

  // ------------------------------- UUID functions
  const handleCloseUuid = (flag) => {
    setOpenUuid(false);
    if (flag) loadData(filters);
  };

  const table = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: filters.page - 1,
        pageSize: filters.page_size,
      },
      pageCount: Math.ceil(totalCount / filters.page_size),
      totalCount,
      pageLength: data.length,
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  const {
    state: { pageIndex, pageSize },
  } = table;

  useEffect(() => {
    dispatch(external ? setExternalPageFilter(pageIndex + 1) : setPageFilter(pageIndex + 1));
  }, [pageIndex, pageSize]);

  const { displayPanel, setHeaderPanels } = useSidebar({
    open: false,
    config: {
      onClose: () => {},
    },
  });

  const {
    displayPanel: displayPanelDetail,
    setHeaderPanels: setHeaderPanelsDetail,
    setFullWidth: setFullWidthDetail,
  } = useSidebarDetail({
    open: false,
    visible: false,
    config: {
      onClose: () => {},
      search: {
        show: true,
        component: SearchPanel,
        external,
      },
    },
  });

  const handleRowClick = async (rowData) => {
    // dispatch redux action to load user data
    // set sidebar active panel to user info and open if necessary
    setSelectedRow(rowData.id);
    dispatch(getProject(external ? rowData.original.project.id : rowData.original.id));
    setHeaderPanels([
      {
        name: 'info',
        component: InfoPanel,
      },
      {
        name: 'admin',
        component: AdminPanel,
      },
    ]);
    displayPanelDetail('');
    displayPanel('info');
  };

  const projectDetail = (project) => {
    dispatch(
      project.openInspection
        ? setProject(project)
        : project.openActiveInspection
        ? setProjectActive(project)
        : getProjectDetail(project.id)
    );
    const displayHeader = project.openInspection
      ? 'projectInspections'
      : project.openActiveInspection
      ? 'projectActiveInspections'
      : 'projectDetail';
    setHeaderPanelsDetail([
      {
        name: displayHeader,
        component: project.openInspection
          ? ProjectInspections
          : project.openActiveInspection
          ? ProjectActiveInspections
          : ProjectDetail,
      },
    ]);
    setFullWidthDetail(true);
    displayPanelDetail(displayHeader);
  };

  const handleSearch = (e) =>
    dispatch(external ? setExternalSearch(e.target.value) : setInternalSearch(e.target.value));

  const EmptyProjectsContainer = () => (
    <>
      <WidgetSplash
        alt="Projects Splash"
        title="There are currently no projects. Create one to begin uploading content."
        image={SplashImage}
        cta="Add a Project"
        onClick={() => (external ? setOpenUuid({ type: externalActionType.ADD }) : setOpenProjectNew(true))}
      />
      {openProjectNew && (
        <ModalProjectNew
          isModalOpen={openProjectNew}
          onConfirm={handleConfirmProjectNew}
          onCancel={handleCancelProjectNew}
        />
      )}
    </>
  );

  const ProjectsContainer = () => (
    <>
      <GeneralTable
        table={table}
        selectedRow={selectedRow}
        handleRowClick={handleRowClick}
        rowComponent={ProjectActions}
        actionFunction={(project) => (project ? projectDetail(project) : loadData())}
        handleSelectedIds={(ids) => console.info(ids)}
        external={external}
      />
      <TablePagination table={table} />
    </>
  );

  if (loading && data.length === 0) {
    return (
      <AccountSettingsContainer
        title={<AdminHeaderTitle buttonName="ADMINISTRATION" section={external ? 'EXTERNAL PROJECTS' : 'PROJECTS'} />}
        actions={[
          {
            icon: CirclePlusIcon,
            text: external ? 'Add Via UUID' : 'Create New',
            onClick: () => {
              if (external) {
                setOpenUuid({ type: externalActionType.ADD });
              } else {
                setOpenProjectNew(true);
              }
            },
          },
        ]}
        showSidebar
      >
        <Loader loading={loading} height={250} />
        {openProjectNew && (
          <ModalProjectNew
            isModalOpen={openProjectNew}
            onConfirm={handleConfirmProjectNew}
            onCancel={handleCancelProjectNew}
          />
        )}
        {openUuid?.type === externalActionType.ADD && (
          <AddUuid
            handleClose={handleCloseUuid}
            type={externalDataType.PROJ}
            variant={openUuid.variant}
            item={openUuid}
          />
        )}
      </AccountSettingsContainer>
    );
  }

  return (
    <AccountSettingsContainer
      title={<AdminHeaderTitle buttonName="ADMINISTRATION" section={external ? 'EXTERNAL PROJECTS' : 'PROJECTS'} />}
      actions={[
        {
          icon: CirclePlusIcon,
          text: external ? 'Add Via UUID' : 'Create New',
          onClick: () => {
            if (external) {
              setOpenUuid({ type: externalActionType.ADD });
            } else {
              setOpenProjectNew(true);
            }
          },
        },
      ]}
      showSidebar
    >
      <Card noPadding>
        <TopBar
          options={external ? externalProjectsOptions : projectOptions}
          searchValue={filters.search}
          handleSearch={handleSearch}
          activatedFilter={filters.activated}
          sideBarDetail
        />
        {data.length > 0 ? <ProjectsContainer /> : <EmptyProjectsContainer />}
      </Card>
      {openProjectNew && (
        <ModalProjectNew
          isModalOpen={openProjectNew}
          onConfirm={handleConfirmProjectNew}
          onCancel={handleCancelProjectNew}
        />
      )}
      {openUuid?.type === externalActionType.ADD && (
        <AddUuid
          handleClose={handleCloseUuid}
          type={externalDataType.PROJ}
          variant={openUuid.variant}
          item={openUuid}
        />
      )}
    </AccountSettingsContainer>
  );
};

Projects.propTypes = {
  external: PropTypes.bool,
};

Projects.defaultProps = {
  external: false,
};

export class Time {
  static humanReadable = ({ seconds }) => {
    if (seconds <= 0) return '00:00:00';

    let minutes = Math.floor(seconds / 60);
    const sec = seconds % 60;
    const hours = Math.floor(minutes / 60);
    minutes %= 60;
    return `${Time.pad(hours)}:${Time.pad(minutes)}:${Time.pad(sec)}`;
  };

  static pad = (num) => `0${num}`.slice(-2);
}

import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { FileDropZone } from 'components/FileDropZone/FileDropZone';
import { FormSelect } from 'components/FormSelect';
import { If } from 'components/If/If';
import { UploadVideoRow } from 'components/UploadVideoRow/UploadVideoRow';
import { useProjectAssetUploader, useProjectAssetUploaderStorage } from 'hooks/use-project-asset-uploader';
import {
  checkIsImage,
  checkIsVideo,
  checkIsTelemetry,
  checkTelemetryCamera,
  isTelemetry,
  getNameNoExtension,
} from 'lib/content-helpers';
import moment from 'moment';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import EnumService from 'services/EnumService';
import { Card } from 'components/Card';
import { FormCheckbox } from 'components/FormCheckbox';
import { ViewRoleValidation } from 'components/ViewRoleValidation/ViewRoleValidation';
import { Permissions } from 'lib/permissions';
import { Button, TableBody, TableHead, TableRow, Link, Typography } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { SidebarPanel } from 'components/SidebarPanel';
import { GooglePicker } from 'components/StorageServices/GooglePicker';
import { SidebarContext } from 'components/SidebarProvider/SidebarProvider';
import { MessageAlert } from 'components/CreateContent/MessageAlert';
import { services } from 'components/CreateContent/StorageServiceConst';
import { DialogMessage } from 'components/DialogMessage';
import { InfoIcon } from 'components/Icons';
import { FooterButtons, InlineDiv, StyledDescription, StyledStoragePanel, StyledTH, StyledTable } from './styles';

const options = [
  { value: 'series', label: 'Series' },
  { value: 'group', label: 'Related Grouping' },
  { value: 'individual', label: 'Individual/General Bulk' },
];

const descriptions = {
  series:
    'Use this option when your camera segments a single video into multiple segments due to file size limitations.',
  group:
    'To save time, this can be used when you have videos and images that are directly related and can share the same name, description and date. (example: ‘Construction Site: Day 5’) Files are renamed and numbered sequentially.',
  individual: 'Use this option when you have a single file, or would like to upload several items at once.',
};

let fileId = 1;

// eslint-disable-next-line no-unused-vars
export const CreateContent = ({ type, hideMenu }) => {
  const { setOpen, open, handleClose, config } = useContext(SidebarContext);
  const projId = config.header.find((item) => item.name === 'createContent')?.projectId;
  const [selected, setSelected] = React.useState('');
  const [files, setFiles] = React.useState([]);
  const [cameraTypes, setCameraTypes] = React.useState([]);
  const [autOrdering, setAutOrdering] = React.useState(false);
  const uploadAssets = useProjectAssetUploader({ projId });
  const sendFiles = useProjectAssetUploaderStorage({ projId });
  const [validateRole, setValidateRole] = useState({ open: false, callBack: () => {} });
  const [storageService, setStorageService] = React.useState(false);
  const [uploadMessage, setUploadMessage] = React.useState(0);
  const [policyOpen, setPolicyOpen] = React.useState(false);
  const [googleAuth, setGoogleAuth] = React.useState({});
  // const [boxAuth, setBoxAuth] = React.useState({});
  // const [onedriveAuth, setOnedriveAuth] = React.useState({});
  // const [dropboxAuth, setDropboxAuth] = React.useState({});

  React.useEffect(() => {
    const fetchContentCameras = async () => {
      const result = await EnumService.getContentCameras();
      const cameraTypesResults = result.data.data;
      setCameraTypes(cameraTypesResults);
    };

    fetchContentCameras();
  }, []);

  React.useEffect(() => {
    if (files.length > 0 && autOrdering === true) {
      const neww = files.sort((a, b) => new Date(b.date) - new Date(a.date));
      setFiles(neww);
    }
  }, [files]);

  const checkSeriesFileTypes = (file) => {
    if (files[0] && files[0].metaType !== file.metaType) {
      return false;
    }
    return true;
  };

  const processFile = (file) => {
    if (storageService && storageService !== services.LOCAL) {
      return;
    }
    const fileObject = {
      data: file,
    };
    if (checkIsVideo(file.name)) {
      fileObject.metaType = 'VIDEO';
    } else if (checkIsImage(file.name)) {
      fileObject.metaType = 'PHOTO';
    } else {
      fileObject.metaType = 'DOCUMENT';
    }

    if (selected === 'series' && checkSeriesFileTypes(fileObject) === false) {
      return;
    }

    fileObject.metaId = `id_${fileId}`;
    fileObject.metaName = file.name;
    fileObject.ogName = file.name;
    fileObject.cameraType = cameraTypes[0].value;
    fileObject.metaAcqDate = moment(file.lastModified).format('MM/DD/YYYY');
    fileObject.description = '';
    fileObject.telemetryStorage = false;

    if ((selected === 'series' || selected === 'group') && files.length > 0) {
      const firstFile = files[0];
      fileObject.metaName = firstFile.metaName;
    }

    setFiles((oldFiles) => [...oldFiles, fileObject]);
    setStorageService(services.LOCAL);
    fileId += 1;
  };

  const processStorageFile = (file, SService) => {
    if (storageService && storageService !== SService) {
      return;
    }
    const fileObject = {
      service: SService,
      data: file,
    };
    if (checkIsVideo(file.name)) {
      fileObject.metaType = 'VIDEO';
    } else if (checkIsImage(file.name)) {
      fileObject.metaType = 'PHOTO';
    } else {
      fileObject.metaType = 'DOCUMENT';
    }

    if (selected === 'series' && checkSeriesFileTypes(fileObject) === false) {
      return;
    }

    fileObject.metaId = `id_${fileId}`;
    fileObject.metaName = file.name;
    fileObject.ogName = file.name;
    fileObject.cameraType = file.cameraType ? file.cameraType : cameraTypes[0].value;
    fileObject.metaAcqDate = moment(file.lastEditedUtc).format('MM/DD/YYYY');
    fileObject.description = '';
    fileObject.id = file.id;
    fileObject.mimeType = file.mimeType;
    fileObject.telemetryStorage = false;

    if (file.externalTelemetry) {
      fileObject.externalTelemetry = file.externalTelemetry;
      fileObject.telemetryStorage = true;
    }

    if ((selected === 'series' || selected === 'group') && files.length > 0) {
      const firstFile = files[0];
      fileObject.metaName = firstFile.metaName;
    }

    setFiles((oldFiles) => [...oldFiles, fileObject]);
    setStorageService(SService);
    fileId += 1;
  };

  const removeFile = (fileName) => {
    const newFiles = files.filter((file) => file.metaName !== fileName);
    setFiles(newFiles);
    if (newFiles.length < 1) {
      setStorageService(false);
    }
  };

  const handleChange = (idx, updatedFile) => {
    setFiles((oldFiles) => {
      const newFiles = [...oldFiles];
      newFiles[idx] = updatedFile;
      switch (selected) {
        case 'series':
          {
            const { metaName, cameraType, description } = newFiles[0];

            for (let i = 1; i < newFiles.length; i += 1) {
              newFiles[i].metaName = metaName;
              newFiles[i].cameraType = cameraType;
              newFiles[i].description = description;
            }
          }
          break;
        case 'group':
          {
            const { metaName, description } = newFiles[0];

            for (let i = 1; i < newFiles.length; i += 1) {
              newFiles[i].metaName = metaName;
              newFiles[i].description = description;
            }
          }
          break;
        default:
      }

      return newFiles;
    });
  };

  const processStorageTelemetry = (file, SService) => {
    if (storageService && storageService !== SService) {
      return;
    }
    const idx = files.findIndex((f) => isTelemetry(f.ogName, file.name));
    if (idx >= 0) {
      // Add telemetry to object if exist
      const newFile = {
        ...files[idx],
        externalTelemetry: {
          id: file.id,
          name: file.name,
        },
        telemetryStorage: true,
      };
      newFile.cameraType = checkTelemetryCamera(file.name, cameraTypes);
      handleChange(idx, newFile);
    }
  };

  // a little function to help us with reordering the result
  const reorder = (listOfFiles, startIndex, endIndex) => {
    const [removed] = listOfFiles.splice(startIndex, 1);
    listOfFiles.splice(endIndex, 0, removed);

    return listOfFiles;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newFiles = reorder(files, result.source.index, result.destination.index);

    setFiles(newFiles);
  };

  const onClickCancel = () => {
    setOpen(!open);
    handleClose();
  };

  const onUploadClick = async () => {
    const isSeries = selected === 'series';
    const isGroup = selected === 'group';
    let status = 200;
    switch (storageService) {
      case services.LOCAL:
        uploadAssets({ files, isSeries, isGroup });
        onClickCancel();
        break;
      case services.GOOGLE:
        if (files.length > 5000) {
          setUploadMessage(3);
          return;
        }
        status = sendFiles({ auth: googleAuth, files, isSeries, isGroup, service: storageService });
        if (status >= 400) {
          setUploadMessage(2);
        } else {
          setUploadMessage(1);
        }
        break;
      case services.BOX:
        break;
      case services.ONEDRIVE:
        break;
      case services.DROPBOX:
        break;
      default:
        break;
    }
  };

  const onSelectedChange = (selectedValue) => {
    setSelected(selectedValue);
    if (files.length < 1) return;
    const firstFileMetaType = files[0].metaType;
    const newFiles = files.filter((file) => file.metaType === firstFileMetaType);
    setFiles(newFiles);
  };

  const handleCheckedChange = ({ checked }) => {
    setAutOrdering(checked);
  };

  const googlePicked = (action) => {
    const picked = action.docs;
    const telemetry = [];
    const telemetryNames = {};
    // gather if there is telemetry files
    picked.map((pick, idx) => {
      if (checkIsTelemetry(pick.name)) {
        telemetry.push(pick);
        telemetryNames[getNameNoExtension(pick.name)] = idx;
      }
      return true;
    });
    picked.map((pick) => {
      if (!checkIsTelemetry(pick.name)) {
        const tlmIdx = telemetryNames[getNameNoExtension(pick.name)];
        if (tlmIdx !== undefined) {
          pick.cameraType = checkTelemetryCamera(picked[tlmIdx].name, cameraTypes);
          pick.externalTelemetry = {
            id: picked[tlmIdx].id,
            name: picked[tlmIdx].name,
          };
        }
        processStorageFile(pick, services.GOOGLE);
      }
      return true;
    });
    // Wait until having all the files in the array to process the telemetry files selected to avoid ignoring telemetry files
    telemetry.map((pick) => processStorageTelemetry(pick, services.GOOGLE));
  };

  const FormContent = () => (
    <Card border={hideMenu}>
      {uploadMessage === 1 && (
        <MessageAlert
          id={`message-${uploadMessage}`}
          title="Success"
          message="The selected files are being uploaded and processed"
          type={uploadMessage}
          handleClose={() => {
            setUploadMessage(0);
            onClickCancel();
          }}
        />
      )}
      {uploadMessage === 2 && (
        <MessageAlert
          id={`message-${uploadMessage}`}
          title="Error"
          message="There was an error connecting to server"
          type={uploadMessage}
          handleClose={() => setUploadMessage(0)}
        />
      )}
      {uploadMessage === 3 && (
        <MessageAlert
          id={`message-${uploadMessage}`}
          title="Error"
          message="Cannot upload more than 5000 files"
          type={uploadMessage}
          handleClose={() => setUploadMessage(0)}
        />
      )}
      <form noValidate>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <InlineDiv style={{ width: '250px', marginRight: '20px' }}>
            <FormSelect
              options={options}
              label="Upload Type *"
              placeholder="Select your upload type"
              name="uploadType"
              onChange={(e) => onSelectedChange(e.value)}
            />
          </InlineDiv>
          <InlineDiv>
            <StyledDescription>{descriptions[selected] || ''}</StyledDescription>
          </InlineDiv>
        </div>
        <div style={{ display: selected === 'series' ? 'flex' : 'none', alignItems: 'center' }}>
          <FormCheckbox
            label="Automatic ordering by Date?"
            value="automaticOrderingByDate"
            name="autOrdering"
            checked={autOrdering}
            onChecked={(val) => handleCheckedChange(val)}
          />
        </div>
        <If cond={selected !== ''}>
          <FileDropZone
            onAddFile={processFile}
            accept={['video/mp4', 'video/quicktime', 'image/jpeg', 'video/ts', 'image/srt', 'image/SRT', '']}
          />
        </If>
        <If cond={selected !== ''}>
          <StyledStoragePanel>
            <InlineDiv>
              <StyledDescription>Other Storage Locations:</StyledDescription>
              <GooglePicker
                onAuthenticate={(auth) => setGoogleAuth(auth)}
                onCallback={googlePicked}
                disabled={storageService && storageService !== services.GOOGLE}
              />
              <br />
              <Button color="secondary" variant="text" onClick={() => setPolicyOpen(true)}>
                Limited Use Policy
              </Button>
            </InlineDiv>
          </StyledStoragePanel>
        </If>
        {files.length !== 0 && (
          <StyledTable files={files}>
            <TableHead>
              <TableRow>
                <If cond={selected === 'series'}>
                  <StyledTH>Order</StyledTH>
                </If>
                <StyledTH>Click to Name File</StyledTH>
                <StyledTH>Description</StyledTH>
                <StyledTH>Camera Type</StyledTH>
                <StyledTH>Corresponding Telemetry File</StyledTH>
                <StyledTH>
                  Manual <HelpIcon />
                </StyledTH>
                <StyledTH>Acq. Date</StyledTH>
                <StyledTH>Advanced</StyledTH>
              </TableRow>
            </TableHead>
            <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                    {files.map((file, index) => (
                      <UploadVideoRow
                        file={file}
                        key={file.metaId}
                        cameraTypes={cameraTypes}
                        index={index}
                        isDraggable={selected === 'series'}
                        isSeriesChild={index > 0 && selected === 'series'}
                        isGroupChild={index > 0 && selected === 'group'}
                        onDelete={removeFile}
                        onChange={handleChange}
                        storageAuth={{ [services.GOOGLE]: googleAuth }}
                      />
                    ))}
                    {provided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </DragDropContext>
          </StyledTable>
        )}
        <FooterButtons>
          <Button color="primary" variant="contained" onClick={() => onClickCancel()}>
            Cancel
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={() =>
              setValidateRole({ open: true, action: [Permissions.PROJ_UPLOAD_CONTENT], callBack: onUploadClick })
            }
          >
            <CloudUploadIcon style={{ marginRight: 10 }} sx={{ fontSize: 20 }} /> Upload
          </Button>
        </FooterButtons>
      </form>
      {validateRole.open && (
        <ViewRoleValidation
          action={validateRole.action}
          data={{ id: projId }}
          callBack={validateRole.callBack}
          handleClose={() => setValidateRole({ open: false, callBack: () => {} })}
        />
      )}
      <DialogMessage
        title="Google use policy"
        content={
          <>
            <Typography paragraph align="justify">
              Cartovid&apos;s use and transfer of information received from Google APIs to any other app will adhere
              to&nbsp;
              <Link
                href="https://developers.google.com/terms/api-services-user-data-policy"
                underline="hover"
                target="_blank"
                rel="noreferrer"
                color="secondary"
              >
                Google API Services User Data Policy
              </Link>
              , including the Limited Use requirements.
            </Typography>
          </>
        }
        isOpen={policyOpen}
        icon={InfoIcon}
        confirmText="Ok"
        onConfirm={() => setPolicyOpen(false)}
      />
    </Card>
  );

  return <SidebarPanel title=" > Upload">{FormContent()}</SidebarPanel>;
};

CreateContent.propTypes = {
  type: PropTypes.string,
  hideMenu: PropTypes.bool,
};

CreateContent.defaultProps = {
  type: null,
  hideMenu: false,
};

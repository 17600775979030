import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import MapGisService from 'services/MapGisService';
import UserService from 'services/UserService';
import { formatSortBy } from './helpers';

export const getProjectMapGisRows = createAsyncThunk('projectMapGis/geProjectMapGisRows', async (filters) => {
  const newFilters = {
    strict: true,
    ...filters,
    ...formatSortBy(filters.sort_by),
  };

  delete newFilters.date_range;
  delete newFilters.activated;
  delete newFilters.acctId;
  delete newFilters.organizationId;

  try {
    const response = await MapGisService.getMapGisServices(newFilters);
    return response.data;
  } catch (e) {
    throw new Error('Error during request for map/GIS services...');
  }
});

export const getProjecttMapGis = createAsyncThunk(
  'projectMapGis/getProjecttMapGis',
  async (id) => {
    try {
      const [mapGisService] = await Promise.all([await MapGisService.getMapGisService(id)]);

      return { mapGisService };
    } catch (error) {
      throw new Error('Error during request for single Map/GIS Service...');
    }
  },
  {
    condition: (id, { getState }) => getState().adminProjects.panels.id !== id,
  }
);

export const preloadFilterDatasets = createAsyncThunk(
  'adminProjects/preloadFilterDatasets',
  async () => {
    try {
      const response = await Promise.all([
        await UserService.getUsers({ page_size: 500 }),
        await MapGisService.getProjectAccounts({ participantLevel: ['administer'] }),
        await MapGisService.getProjectOrganizations({ participantLevel: ['administer'] }),
      ]);

      return response.map((r) => r.data.data);
    } catch (err) {
      throw new Error('error loading filter data');
    }
  },
  {
    condition: (_, { getState }) => getState().adminProjects.panels.search.loaded === false,
  }
);

const initialState = {
  error: null,
  loading: false,
  data: [],
  totalCount: 0,
  panels: {
    projId: null,
    id: null,
    loading: false,
    projMapGisDetails: {},
    search: {
      loaded: false,
      addedBy: [],
      switched: [],
      type: [],
    },
  },
  filters: {
    page: 1,
    page_size: 10,
    sort_by: {},
    search: '',
    isOpen: null,
  },
};

const mapGisSlice = createSlice({
  name: 'projectMapGis',
  initialState,
  reducers: {
    resetAdminProjects: () => initialState,
    setProjectPageFilter(state, action) {
      state.filters.page = action.payload;
    },
    setSearch(state, action) {
      state.filters.search = action.payload;
    },
    setSortBy(state, { payload }) {
      const { label, value } = payload;

      if (value === '' || value === null) {
        delete state.filters.sort_by[label];
      } else {
        state.filters.sort_by[label] = value;
      }
    },
    setLoading(state, { payload }) {
      state.loading = payload;
    },
    setFilterValue(state, { payload }) {
      const { param, value } = payload;

      if ((value === '' || value === null) && param !== 'date_range') {
        delete state.filters[param];
        return;
      }

      state.filters[param] = payload.value;
    },
    setFilters(state, action) {
      const filter = { ...state.filters, ...action.payload, page: 1 };
      if (!filter?.participantLevel) delete filter?.participantLevel;
      state.filters = filter;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProjectMapGisRows.pending, (state) => {
      state.loading = false;
      state.panels.userId = null;
    });
    builder.addCase(getProjectMapGisRows.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.totalCount = action.payload.data.length;
      state.error = null;
      state.loading = false;
    });

    builder.addCase(getProjectMapGisRows.rejected, (state) => {
      state.data = [];
      state.totalCount = 0;
      state.error = 'There was an error during the request.';
      state.loading = false;
    });
  },
});

export const {
  setSearch,
  setSortBy,
  setProjectPageFilter,
  setFilterValue,
  setLoading,
  resetAdminProjects,
  setFilters,
  // eslint-disable-next-line prettier/prettier
} = mapGisSlice.actions;

export default mapGisSlice.reducer;

import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const CloseIcon = ({ size, className }) => (
  <Svg viewBox="0 0 24 24" size={size} className={className}>
    <path
      d="M20.839 5.282a1 1 0 000-1.414l-.707-.707a1 1 0 00-1.415 0L12 9.88 5.282 3.16a1 1 0 00-1.414 0l-.707.707a1 1 0 000 1.414L9.88 12 3.16 18.718a1 1 0 000 1.414l.707.707a1 1 0 001.414 0L12 14.12l6.718 6.718a1 1 0 001.414 0l.707-.707a1 1 0 000-1.415L14.12 12l6.718-6.718z"
      fill="currentColor"
    />
  </Svg>
);

CloseIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
};

CloseIcon.defaultProps = {
  size: 24,
  className: null,
};

import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, FormGroup, Switch, Typography } from '@mui/material';

export const ProjectVisibilityForm = ({ formik, loading, handleChange }) => (
  <FormGroup>
    <FormControlLabel
      control={<Switch color="secondary" />}
      disabled={loading}
      label={
        <div>
          <Typography variant="body1">Make project visible to organization</Typography>
          <Typography variant="caption">
            If on, organization members can view data in project as if they were &#34;View Only&#34; project member
          </Typography>
        </div>
      }
      value="isVisible"
      onChange={(event) => {
        if (!event.target.checked && formik.values.isOpenProject) {
          formik.setFieldValue('isOpenProject', false);
        }
        formik.setFieldValue('isVisible', event.target.checked);
        handleChange(true);
      }}
      checked={formik.values.isVisible}
    />
    <FormControlLabel
      control={<Switch color="secondary" />}
      disabled={!formik.values.isVisible}
      label={
        <div>
          <Typography variant="body1" mt={0.5}>
            Set project as ‘Open’
          </Typography>
          <Typography variant="caption">Allow others to join the project without directly adding them</Typography>
        </div>
      }
      value="isOpenProject"
      onChange={(event) => {
        formik.setFieldValue('isOpenProject', event.target.checked);
        handleChange(true);
      }}
      checked={formik.values.isOpenProject}
    />
  </FormGroup>
);

ProjectVisibilityForm.propTypes = {
  formik: PropTypes.object,
  loading: PropTypes.bool,
  handleChange: PropTypes.func,
};

ProjectVisibilityForm.defaultProps = {
  formik: null,
  loading: false,
  handleChange: () => {},
};

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  content: (props) => ({
    display: 'grid',
    height: '100%',
    overflow: 'hidden',
    gridTemplate: `"header header sidebar" auto "nav content sidebar" 1fr / auto 1fr auto`,
    ...(props.faqPage && { display: 'block', overflow: 'auto' }),
  }),
});

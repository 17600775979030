import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Typography } from '@mui/material';
import { CloseIcon } from 'components/Icons';
import { v4 } from 'uuid';
import { useStyles } from './styles';

export const ClearFilterAction = ({ actions }) => {
  const classes = useStyles();

  return (
    actions &&
    actions.length > 0 && (
      <div className={classes.content}>
        {actions.map((action) => (
          <div key={`${v4()}${new Date().getMilliseconds()}`} className={classes.button}>
            <Typography variant="caption">{action.text}</Typography>
            <IconButton className={classes.closeButton} onClick={action.callBack}>
              <CloseIcon size={12} />
            </IconButton>
          </div>
        ))}
      </div>
    )
  );
};

ClearFilterAction.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      callBack: PropTypes.func.isRequired,
    })
  ).isRequired,
};

import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  iconContainer: (props) => ({
    borderRadius: '50%',
    backgroundColor: props.color ? theme.palette.warning[props.color.background] : theme.palette.success[300],
    color: props.color ? theme.palette.warning[props.color.color] : theme.palette.success[800],
    width: '48px',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(0.5),
  }),
});

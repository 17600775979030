import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const ActivityIcon = ({ size }) => (
  <Svg viewBox="0 0 24 24" size={size}>
    <path
      d="M22 12H18L15 21L9 3L6 12H2"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </Svg>
);

ActivityIcon.propTypes = {
  size: PropTypes.number,
};

ActivityIcon.defaultProps = {
  size: 24,
};
